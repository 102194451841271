import { Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getMonths } from "./contra-cheque-utils";
import Logo from "../../../images/logo.svg";

// Cabecalho do contracheque
const CabeContraCheque = ({ mes, ano, info_empresa }) => {
  const { t } = useTranslation();
  const DADOS = [
    info_empresa.nome,
    `${t("contra_cheque.cnpj")}: ${info_empresa.cnpj}`,
    `${t("contra_cheque.endereco")}: ${info_empresa.endereco}`,
    `${info_empresa.cidade} - ${info_empresa.estado}`,
  ];

  const MESES = getMonths(t);

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <img src={Logo} alt="LOGO TIBERINA" height={24} />
          {/* <Logo /> */}
        </Grid>
        <Grid xs={3} item direction="column">
          {DADOS.map((ele, idx) => (
            <Typography component="div" key={idx} variant="caption">
              {ele}
            </Typography>
          ))}
        </Grid>
        <Grid xs={7}>
          <Typography variant="h6">
            {t("contra_cheque.reciboSalario")} -{" "}
            {String(mes).search("PLR") !== -1
              ? "PLR"
              : MESES.filter((ele) => ele[0] === mes)[0][1]}
            /{ano}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  );
};

export default CabeContraCheque;
