import {
  Stack,
  Container,
  Grid,
  TextField,
  Paper,
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Body1, Body2, Caption, H6, Icone } from "../../../components";
import Select from "react-select";
import { blue, green, red, yellow } from "@mui/material/colors";
import BackgroundRouter from "../../background-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { ncpAdd } from "./ncp-actions";
import { useToggle } from "react-use";
import { createSelector } from "reselect";
import NcpWrapperSemDados from "./ncp-wrapper-sem-dados";
import { ToastErro } from "../../../utils/utils";
import axios from "axios";
import {
  selectArea,
  selectAreaModelo,
  selectColabs,
  selectOperacao,
  selectPn,
  selectTurno,
  selectUsuario,
} from "./ncp-selectors";
import { useTranslation } from "react-i18next";

//
const seletorUsuarioAtual = createSelector(selectUsuario, (usuario) => {
  const matriculaPlanta = [usuario.matricula, usuario.planta].join("_");
  return [matriculaPlanta, [usuario.matricula, usuario.nome].join(" - ")];
});
//
function formatToSelect(arr, concatRotulo = "") {
  const newArr = arr.map((ele) => ({
    label: `${Array.isArray(ele) ? ele[1] : ele}${concatRotulo}`,
    value: Array.isArray(ele) ? ele[0] : ele,
    key: Array.isArray(ele) ? ele[0] : ele,
  }));
  return newArr;
}
// Uma funcao para formatar os valores do formulario e retorna-los para o preenchimento
// dos valores default
const formatDefaultValue = (
  stateValues,
  colaboradores,
  turnos,
  areas,
  areaModelo,
  pnLista,
  operacaoLista
) => {
  // modelo selecionado
  const modelo = [];
  _.forEach(_.keys(areaModelo), (k) => {
    let foundKey = false;
    //
    _.forEach(areaModelo[k], (itemArr) => {
      if (foundKey) return true;
      if (itemArr[0] === stateValues.id_modelo) {
        modelo.push(itemArr);
        foundKey = true;
      }
    });
    if (foundKey) return true;
  });
  //
  const colaborador = [stateValues.matricula, stateValues.planta].join("_");
  //
  const obj = {
    ncp_numero: stateValues.id,
    colaborador: formatToSelect(
      _.filter(colaboradores, (val) => val[0] === colaborador)
    )[0],
    area: formatToSelect(
      _.filter(areas, (val) => val[0] === stateValues.id_area)
    )[0],
    modelo: formatToSelect(modelo)[0],
    turno: formatToSelect(
      _.filter(turnos, (val) => val[0] === stateValues.turno)
    )[0],
    operacao: formatToSelect(
      _.filter(operacaoLista, (val) => val[1] === stateValues.operacao)
    )[0],
    pn: formatToSelect(
      _.filter(pnLista, (val) => val[0] === stateValues.pn)
    )[0],
    data: stateValues.data,
    hora: stateValues.hora,
    anomalia: stateValues.anomalia,
    imagem_ok: stateValues.imagem_ok,
    imagem_ko: stateValues.imagem_ko,
    imagem_ident: stateValues.imagem_ident,
    what: stateValues.what,
    when: stateValues.when,
    where: stateValues.where,
    how: stateValues.how,
    which: stateValues.which,
    who: stateValues.who,
    fenomenoRevisado: stateValues.fenomeno,
    causaRaiz: stateValues.causa_raiz,
    quantidadeKO: stateValues.qtd_ko,
    quantidadeSuspeita: stateValues.qtd_suspeita,
    rastreabilidade: stateValues.rastreabilidade,
  };
  return obj;
};
//
const colorInputTextField = red[600];

function Form(props) {
  const { t } = useTranslation();
  const areas = useSelector(selectArea);
  const areaModelo = useSelector(selectAreaModelo);
  const colaboradores = useSelector(selectColabs);
  // Para o usuario atual, caso seja adicao será o pré-preenchido no cadastro
  const colaboradorAtual = useSelector(seletorUsuarioAtual);
  const turnos = useSelector(selectTurno);
  const pnLista = useSelector(selectPn);
  const operacaoLista = useSelector(selectOperacao);

  //
  const schemaValidator = yup.object().shape({
    colaborador: yup.object().shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    }),
    operacao: yup.object().shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    }),
    area: yup.object().shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    }),
    pn: yup.object().shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    }),
    modelo: yup.object().shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    }),
    turno: yup.object().shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    }),
    rastreabilidade: yup.string().min(1).required(),
    quantidadeKO: yup.number().min(0).required(),
    quantidadeSuspeita: yup.number().min(0).required(),
    data: yup.date().required(),
    hora: yup
      .string()
      .matches(/^([01]\d|2[0-3]):([0-5]\d)$/)
      .required(),

    anomalia: yup.string().min(1).required(),

    what: yup.string().min(1).required(),
    when: yup.string().min(1).required(),
    where: yup.string().min(1).required(),
    who: yup.string().min(1).required(),
    which: yup.string().min(1).required(),
    how: yup.string().min(1).required(),

    fenomenoRevisado: yup.string().min(1).required(),
    // causaRaiz: yup.string().min(1).required(),
    imagemOK: yup
      .mixed()
      .required()
      .test("name", t("ncp.formErrorImagemOK"), (value) => {
        return value && value[0]?.name.length > 0;
      }),
    imagemKO: yup
      .mixed()
      .required()
      .test("name", t("ncp.formErrorImagemKO"), (value) => {
        return value && value[0]?.name.length > 0;
      }),
  });
  //
  const dispatch = useDispatch();
  const history = useHistory();
  const [aguardar, setAguardar] = useToggle(false);
  const isMobile = useTheme()?.isMobile;
  //
  let isReadOnly = false;
  let defaultValueForm = useMemo(
    () => ({
      colaborador: formatToSelect([colaboradorAtual])[0],
      data: format(new Date(), "yyyy-MM-dd"),
      hora: format(new Date(), "HH:mm"),
    }),
    [colaboradorAtual]
  );
  // Valores retornados em caso de uma visualização do formulário
  const stateDefaultValue = props.dataItem;
  // Se for um caso de visualização preenche os valores
  // Default enviados no estado.
  if (stateDefaultValue) {
    defaultValueForm = formatDefaultValue(
      stateDefaultValue,
      colaboradores,
      turnos,
      areas,
      areaModelo,
      pnLista,
      operacaoLista
    );
    isReadOnly = true;
  }
  //
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValueForm,
    resolver: yupResolver(schemaValidator),
  });

  //
  const onSaveForm = useCallback(
    async (val) => {
      // const formData = new FormData();
      const [mat, planta] = val.colaborador.value.split("_");
      const obj = {
        matricula: mat,
        planta,
        id_area: parseInt(val.area.value),
        id_modelo: parseInt(val.modelo.value),
        turno: val.turno.value,
        id_operacao: parseInt(val.operacao.value),
        pn: val.pn.value,
        data_ncp: format(val.data, "yyyy-MM-dd"),
        hora: val.hora,
        anomalia: val.anomalia,
        what: val.what,
        when: val.when,
        where: val.where,
        who: val.who,
        which: val.which,
        how: val.how,
        fenomeno: val.fenomenoRevisado,
        causaraiz: val.causaRaiz || "",
        rastreabilidade: val.rastreabilidade,
        qtd_suspeita: val.quantidadeSuspeita,
        qtd_ko: val.quantidadeKO,
      };

      // Anexando as imagens (caso seja uma insercao)
      if (!stateDefaultValue) {
        const formDataImages = new FormData();
        formDataImages.append("imagemko", val.imagemKO[0]);
        formDataImages.append("imagemok", val.imagemOK[0]);
        // Imagem opcional, caso enviada anexar no form
        if (val.imagemIdent) {
          formDataImages.append("imagem_ident", val.imagemIdent[0]);
        }

        setAguardar();

        try {
          const response = await axios.post("/bucket", formDataImages, {
            headers: {
              ContentType: "multipart/form-data",
            },
          });
          obj.imagem_ok = response.data.imagemok[0].url;
          obj.imagem_ko = response.data.imagemko[0].url;

          if (val.imagemIdent) {
            obj.imagem_ident = response.data.imagem_ident[0].url;
          }
        } catch (error) {
          if (error?.message) {
            ToastErro(error.message);
          } else {
            ToastErro("Erro ao enviar arquivos");
          }

          return false;
        } finally {
          setAguardar();
        }
        // Enviando para insercao
        dispatch(ncpAdd(obj, setAguardar, () => history.goBack()));
      }
    },
    [dispatch, setAguardar, stateDefaultValue, history]
  );
  //
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        p: 1,
        background: ({ backgroundPage }) => backgroundPage,
      }}
    >
      {!isMobile && !stateDefaultValue && <NcpGoBack />}
      <Container maxWidth="lg">
        <Paper sx={{ p: 1, minHeight: "calc(100vh - 80px)" }}>
          {!isReadOnly && !isMobile && (
            <Container maxWidth="sm">
              <Button
                disabled={aguardar}
                startIcon={aguardar && <CircularProgress size={20} />}
                fullWidth
                variant="contained"
                onClick={handleSubmit(onSaveForm)}
              >
                {t("ncp.rotuloBotaoFormSalvar")}
              </Button>
            </Container>
          )}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <H6>{t("ncp.tituloForm")}</H6>
            </Grid>

            <NcpFormHeader
              isReadOnly={isReadOnly}
              {...props}
              errors={errors}
              control={control}
              watch={watch}
            />

            <NcpFormAnomaly
              control={control}
              isReadOnly={isReadOnly}
              errors={errors}
            />
          </Grid>
          {isMobile && <br />}
          <NcpQuestionAndImages
            control={control}
            isReadOnly={isReadOnly}
            errors={errors}
            setValue={setValue}
            imagemOK={defaultValueForm?.imagem_ok}
            imagemKO={defaultValueForm?.imagem_ko}
            imagemIdent={defaultValueForm?.imagem_ident}
          />
          {!isReadOnly && isMobile && (
            <Button
              sx={{ my: 1 }}
              disabled={aguardar}
              startIcon={aguardar && <CircularProgress size={20} />}
              fullWidth
              variant="contained"
              onClick={handleSubmit(onSaveForm)}
            >
              {t("ncp.rotuloBotaoFormSalvar")}
            </Button>
          )}
        </Paper>
      </Container>
    </Container>
  );
}
//
export const NcpGoBack = () => {
  const { t } = useTranslation();

  const history = useHistory();
  return (
    <Stack direction="row">
      <Button
        color="primary"
        title={t("ncp.tituloBotaoVoltar")}
        onClick={() => history.goBack()}
        startIcon={<Icone icone="Undo" />}
        variant="text"
      >
        {t("ncp.rotuloBotaoVoltar")}
      </Button>
    </Stack>
  );
};
//
const NcpQuestionAndImages = ({
  control,
  isReadOnly,
  errors,
  setValue,
  imagemOK,
  imagemKO,
  imagemIdent,
}) => {
  const { t } = useTranslation();
  const isDark = useTheme()?.palette?.mode === "dark";
  //
  const questionsAndResponses = [
    {
      type: "text",
      grid: 12,
      disabled: isReadOnly,
      name: "what",
      label: t("ncp.formOque"),
      placeholder: t("ncp.formOquePlaceholder"),
      defaultValue: null,
      error: errors?.what ? t("ncp.formErrorOque") : null,
    },
    {
      type: "text",
      grid: 12,
      disabled: isReadOnly,
      name: "when",
      label: t("ncp.formQuando"),
      placeholder: t("ncp.formQuandoPlaceholder"),
      defaultValue: null,
      error: errors?.when ? t("ncp.formErrorQuando") : null,
    },
    {
      type: "text",
      grid: 12,
      disabled: isReadOnly,
      name: "where",
      label: t("ncp.formOnde"),
      placeholder: t("ncp.formOndePlaceholder"),
      defaultValue: null,
      error: errors?.where ? t("ncp.formErrorOnde") : null,
    },
    {
      type: "text",
      grid: 12,
      disabled: isReadOnly,
      name: "who",
      label: t("ncp.formQuem"),
      placeholder: t("ncp.formQuemPlaceholder"),
      defaultValue: null,
      error: errors?.who ? t("ncp.formErrorQuem") : null,
    },
    {
      type: "text",
      grid: 12,
      disabled: isReadOnly,
      name: "which",
      label: t("ncp.formQual"),
      placeholder: t("ncp.formQualPlaceholder"),
      defaultValue: null,
      error: errors?.which ? t("ncp.formErrorQual") : null,
    },
    {
      type: "text",
      grid: 12,
      disabled: isReadOnly,
      name: "how",
      label: t("ncp.formComo"),
      placeholder: t("ncp.formComoPlaceholder"),
      defaultValue: null,
      error: errors?.how ? t("ncp.formErrorComo") : null,
    },
  ];
  //
  const phenomenalForm = {
    type: "textarea",
    label: t("ncp.formFenomenoRevisado"),
    placeholder: t("ncp.formFenomenoRevisadoPlaceholder"),
    name: "fenomenoRevisado",
    error: errors?.fenomenoRevisado ? t("ncp.formErrorFenomenoRevisado") : null,
    defaultValue: null,
    disabled: isReadOnly,
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Stack>
          <BoxContainer
            background={!isDark && blue[300]}
            height={56}
            color={isDark ? "white" : "black"}
          >
            <Body1 align="center">{t("ncp.tituloPergs")}</Body1>
          </BoxContainer>
          {questionsAndResponses.map((ele, idx) => (
            <RowQuestion
              key={idx}
              isPair={idx % 2 === 0}
              {...ele}
              control={control}
            />
          ))}
          <QuestionFhenomenalOrCauseRoot
            {...phenomenalForm}
            control={control}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <ImageFilesOKAndKO
          control={control}
          errors={errors}
          isReadOnly={isReadOnly}
          defaultImageKO={imagemKO}
          defaultImageOK={imagemOK}
          defaultImagemIdent={imagemIdent}
          setValueImage={setValue}
        />
        {/* <QuestionFhenomenalOrCauseRoot {...causeRootForm} control={control} /> */}
      </Grid>
    </Grid>
  );
};
//
const ImageFilesOKAndKO = ({
  errors,
  isReadOnly,
  setValueImage,
  defaultImageKO,
  defaultImageOK,
  defaultImagemIdent,
}) => {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;
  //
  const formOKAndKO = [
    {
      type: "file",
      name: "imagemOK",
      defaultValue: defaultImageOK,
      error: errors?.imagemOK ? t("ncp.formErrorImagemOK") : null,
      label: t("ncp.tituloOK"),
      backgroundColor: green[500],
    },
    {
      type: "file",
      name: "imagemKO",
      defaultValue: defaultImageKO,
      error: errors?.imagemKO ? t("ncp.formErrorImagemOK") : null,
      label: t("ncp.tituloKO"),
      backgroundColor: red[600],
    },
  ];
  //
  const formImagemIdent = [
    {
      type: "file",
      name: "imagemIdent",
      defaultValue: defaultImagemIdent,
      error: errors?.imagemIdent ? t("ncp.formErrorImagemIdent") : null,
      label: t("ncp.tituloImagemIdent"),
      backgroundColor: blue[400],
    },
  ];
  //
  return (
    <Stack>
      <BoxContainer height={56} color="white" background={blue[400]}>
        {t("ncp.tituloNaoConformidade")}
      </BoxContainer>
      <Stack direction={{ xs: "column", md: "row" }} alignItems="stretch">
        {formOKAndKO.map((ele, idx) => (
          <Stack key={idx} sx={{ flex: 1, my: isMobile && 1 }}>
            <ImageForm {...ele} setValueImage={setValueImage} />
          </Stack>
        ))}
      </Stack>
      <BoxContainer height={20} color="white" background={blue[400]} />
      {formImagemIdent.map((ele, idx) => (
        <Stack key={idx} sx={{ flex: 1, my: isMobile && 1 }}>
          <ImageForm {...ele} setValueImage={setValueImage} />
        </Stack>
      ))}
    </Stack>
  );
};
//
const ImageForm = ({ name, setValueImage, error, defaultValue }) => {
  const { t } = useTranslation();
  const [valueLoadImage, setValueLoadImage] = useState(defaultValue);
  const ref = useRef(null);

  const onIntentLoadImage = useCallback(() => {
    ref.current.click();
  }, [ref]);
  // Veja se a imagem foi upada
  const onLoadImage = useCallback(
    (e) => {
      let input = e.target;
      if (input.files && input.files[0]) {
        // Insere o valor da imagem no form
        setValueImage(name, input.files);
        let leitor = new FileReader();

        leitor.onload = function (e) {
          setValueLoadImage(e.target.result);
        };

        leitor.readAsDataURL(input.files[0]);
      }
    },
    [setValueLoadImage, setValueImage, name]
  );

  return (
    <>
      <input
        accept=".png, .jpg"
        ref={ref}
        onChange={onLoadImage}
        type="file"
        style={{ display: "none" }}
      />
      {valueLoadImage ? (
        <div style={{ position: "relative" }}>
          {!defaultValue && (
            <Chip
              title={t("ncp.tituloBotaoRemoverImagem")}
              clickable
              onClick={() => setValueLoadImage(null)}
              variant="filled"
              color="error"
              sx={{
                zIndex: 10,
                position: "absolute",
                top: 4,
                right: 4,
              }}
              label={<Icone sx={{ fontSize: 28 }} icone="Close" />}
            />
          )}

          <ImageNcp src={valueLoadImage} />
        </div>
      ) : (
        <ButtonBase
          onClick={onIntentLoadImage}
          sx={{ border: "1px solid #ccc" }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 196 }}
          >
            <Icone color="primary" icone="FileUpload" sx={{ fontSize: 48 }} />
            <Body1 color="primary">{t("ncp.formRotuloImagem")}</Body1>
            {error && <FormError>{error}</FormError>}
          </Stack>
        </ButtonBase>
      )}
    </>
  );
};
//
const QuestionFhenomenalOrCauseRoot = ({
  label,
  type,
  defaultValue,
  placeholder,
  name,
  control,
  error,
  disabled,
}) => {
  const isDark = useTheme()?.palette?.mode === "dark";

  return (
    <Stack>
      <BoxContainer
        background={!isDark && blue[400]}
        height={48}
        color={isDark ? "white" : "black"}
      >
        <Body1 align="center">{label}</Body1>
      </BoxContainer>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => (
          <TextField
            disabled={disabled}
            error={error}
            fullWidth
            minRows={3}
            type={type}
            multiline
            placeholder={placeholder}
            {...field}
            inputProps={{
              autoComplete: "off",
              style: {
                padding: 0,
                paddingLeft: "8px",
                borderRadius: 0,
                border: "none",
                outline: "none",
              },
            }}
          />
        )}
      />
      {error && <FormError>{error}</FormError>}
    </Stack>
  );
};
//
const ImageNcp = ({ src }) => {
  return (
    <img
      alt="RNCI"
      style={{
        width: "100%",
        minHeight: 194,
      }}
      src={src}
    />
  );
};
//
const RowQuestion = ({
  type,
  isPair,
  grid,
  name,
  label,
  placeholder,
  defaultValue,
  error,
  control,
  disabled,
}) => {
  const isMobile = useTheme()?.isMobile;
  const isDark = useTheme()?.palette?.mode === "dark";
  return (
    <>
      <Stack
        sx={{ my: isMobile && 1 }}
        direction={{ xs: "column", md: "row" }}
        // spacing={isMobile && 1}
      >
        <Box
          sx={{
            minHeight: 56,
            width: isMobile ? "100%" : "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: !isDark ? (isPair ? blue[100] : blue[50]) : null,
            p: !isMobile && 1,
            borderRight: isMobile
              ? "none"
              : `2px solid ${isDark ? "#ccc" : "black"}`,
            borderBottom: isMobile && `1px solid ${isDark ? "#ccc" : "black"}`,
          }}
        >
          <Body1>{label}</Body1>
        </Box>
        <Box
          sx={{
            width: isMobile ? "100%" : "80%",
            display: "flex",
            alignItems: "center",
            p: 0,
            background: !isDark ? (isPair ? blue[100] : blue[50]) : null,
          }}
        >
          <Controller
            name={name}
            defaultValue={defaultValue}
            control={control}
            render={({ field }) => (
              <TextField
                disabled={disabled}
                error={error}
                fullWidth
                variant="standard"
                type={type}
                multiline
                placeholder={placeholder}
                {...field}
                InputProps={{
                  disableUnderline: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  style: {
                    minHeight: 56,
                    padding: 0,
                    paddingLeft: "8px",
                    borderRadius: 0,
                    border: "none",
                    outline: "none",
                  },
                }}
              />
            )}
          />
          {/* <Body1>{textResponse}</Body1> */}
        </Box>
      </Stack>
      {error && <FormError>{error}</FormError>}
    </>
  );
};
//
const BoxContainer = ({ children, color, background, height }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: height,
        background: background,
        color: color,
      }}
    >
      {children}
    </Box>
  );
};
//
const NcpFormAnomaly = ({ control, errors, isReadOnly }) => {
  const { t } = useTranslation();
  const isDark = useTheme()?.palette?.mode === "dark";
  const isMobile = useTheme()?.isMobile;
  //
  return (
    <Grid item xs={12}>
      {errors?.anomalia && <FormError>{t("ncp.formErrorAnomalia")}</FormError>}
      <Paper elevation={0} sx={{ mb: 1, height: 72 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={0}>
          <Box
            sx={{
              height: 56,
              background: !isDark && blue[100],
              p: 1,
              alignItems: "center",
              display: "flex",
            }}
          >
            <Body1>{t("ncp.formAnomalia")}</Body1>
          </Box>
          {!isMobile && (
            <Controller
              name="anomalia"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="text"
                  disabled={isReadOnly}
                  placeholder={t("ncp.formAnomaliaPlaceholder")}
                  {...field}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: colorInputTextField,
                    },
                  }}
                  inputProps={{
                    autoComplete: "off",
                    style: {
                      fontSize: "1.25rem",
                      minHeight: 72,
                      padding: 0,
                      paddingLeft: !isMobile && "8px",
                      borderRadius: 0,
                      border: "none",
                      outline: "none",
                      color: colorInputTextField,
                    },
                  }}
                />
              )}
            />
          )}
        </Stack>
      </Paper>
      {isMobile && (
        <Controller
          name="anomalia"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              type="textarea"
              disabled={isReadOnly}
              placeholder={t("ncp.formAnomaliaPlaceholder")}
              {...field}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: colorInputTextField,
                },
              }}
              inputProps={{
                autoComplete: "off",
                style: {
                  color: colorInputTextField,
                },
              }}
            />
          )}
        />
      )}
    </Grid>
  );
};
//
const NcpFormHeader = ({ isReadOnly, control, watch, errors }) => {
  const { t } = useTranslation();
  // Recupera as areas, modelos e colaboradores
  const areas = useSelector(selectArea);
  const areaModelo = useSelector(selectAreaModelo);
  const colaboradores = useSelector(selectColabs);
  const turnos = useSelector(selectTurno);
  const listaPn = useSelector(selectPn);
  const listaOperacao = useSelector(selectOperacao);

  //
  const isMobile = useTheme()?.isMobile;

  const isAreaSeleted = watch("area")?.value;
  // classe para determinar placeholder, options e values dos campos
  const FieldsForm = {
    colaborador: {
      type: "select",
      grid: 6,
      name: "colaborador",
      label: t("ncp.formAuditor"),
      placeholder: t("ncp.formAuditorPlaceholder"),
      options: colaboradores,
      disabled: isReadOnly,
      error: t("ncp.formErrorAuditor"),
    },
    operacao: {
      type: "select",
      grid: 4,
      name: "operacao",
      label: t("ncp.formOperacao"),
      placeholder: t("ncp.formOperacaoPlaceholder"),
      error: t("ncp.formErrorOperacao"),
      options: listaOperacao,
      autoFormat: true,
      disabled: isReadOnly,
    },
    ncp_numero: {
      type: "text",
      grid: 2,
      name: "ncp_numero",
      label: t("ncp.formNCP"),
      placeholder: t("ncp.formNCPPlaceholder"),
      disabled: true,
      error: t("ncp.formErrorNCP"),
    },

    area: {
      type: "select",
      grid: 6,
      name: "area",
      label: t("ncp.formConjunto"),
      placeholder: t("ncp.formConjuntoPlaceholder"),
      options: areas,
      disabled: isReadOnly,
      error: t("ncp.formErrorConjunto"),
    },
    pn: {
      type: "select",
      grid: 6,
      name: "pn",
      label: t("ncp.formPN"),
      placeholder: t("ncp.formPNPlaceholder"),
      disabled: isReadOnly,
      options: listaPn,
      autoformat: true,
      error: t("ncp.formErrorPN"),
    },

    areaModelo: {
      type: "select",
      grid: 3,
      name: "modelo",
      label: t("ncp.formModelo"),
      placeholder: t("ncp.formModeloPlaceholder"),
      options: isAreaSeleted ? areaModelo[isAreaSeleted] : [],
      disabled: isReadOnly,
      error: t("ncp.formErrorModelo"),
    },
    turno: {
      type: "select",
      grid: 3,
      name: "turno",
      label: t("ncp.formTurno"),
      placeholder: t("ncp.formTurnoPlaceholder"),
      options: turnos,
      disabled: isReadOnly,
      error: t("ncp.formErrorTurno"),
    },
    rastreabilidade: {
      type: "text",
      grid: 6,
      name: "rastreabilidade",
      label: t("ncp.formRastreabilidade"),
      placeholder: t("ncp.formRastreabilidadePlaceholder"),
      disabled: isReadOnly,
      error: t("ncp.formErrorRastreabilidade"),
    },

    quantidadeKO: {
      type: "number",
      grid: 3,
      name: "quantidadeKO",
      label: t("ncp.formQtdKO"),
      placeholder: t("ncp.formQtdKOPlaceholder"),
      error: t("ncp.formErrorQtdKO"),
      disabled: isReadOnly,
      propsTextField: {
        min: 0,
      },
    },
    quantidadeSuspeita: {
      type: "number",
      grid: 3,
      name: "quantidadeSuspeita",
      label: t("ncp.formQtdSuspeita"),
      placeholder: t("ncp.formQtdSuspeitaPlaceholder"),
      error: t("ncp.formErrorQtdSuspeita"),
      disabled: isReadOnly,
      propsTextField: {
        min: 0,
      },
    },
    data: {
      type: "date",
      name: "data",
      grid: 3,
      defaultValue: null,
      label: t("ncp.formData"),
      error: t("ncp.formErrorData"),
      placeholder: t("ncp.formDataPlaceholder"),
      disabled: isReadOnly,
    },
    hora: {
      type: "time",
      name: "hora",
      grid: 3,
      defaultValue: null,
      label: t("ncp.formHora"),
      error: t("ncp.formErrorHora"),
      placeholder: t("ncp.formHoraPlaceholder"),
      disabled: isReadOnly,
    },
  };

  return (
    <Grid sx={{ p: 1 }} container spacing={1}>
      {_.map(_.keys(FieldsForm), (k) => {
        let bodyField;
        switch (FieldsForm[k].type) {
          case "select": // Campo select
            bodyField = (
              <>
                {isMobile && <LabelFormField text={FieldsForm[k].label} />}
                <Row>
                  {!isMobile && <LabelFormField text={FieldsForm[k].label} />}
                  <FieldCustomSelect
                    options={FieldsForm[k].options}
                    control={control}
                    name={FieldsForm[k].name}
                    disabled={FieldsForm[k].disabled}
                  />
                </Row>
                {errors[k] && <FormError>{FieldsForm[k].error}</FormError>}
              </>
            );
            break;
          case "text":
          case "time":
          case "date":
          case "number":
            bodyField = (
              <>
                {isMobile && <LabelFormField text={FieldsForm[k].label} />}
                <Row>
                  {!isMobile && <LabelFormField text={FieldsForm[k].label} />}
                  <FieldCustom
                    name={FieldsForm[k].name}
                    control={control}
                    placeholder={FieldsForm[k].placeholder}
                    type={FieldsForm[k].type}
                    isVertical={FieldsForm[k].isVertical}
                    propsTextField={FieldsForm[k].propsTextField}
                    defaultValue={FieldsForm[k].defaultValue}
                    disabled={FieldsForm[k].disabled}
                  />
                </Row>
                {errors[k] && <FormError>{FieldsForm[k].error}</FormError>}
              </>
            );
            break;
          default:
            break;
        }
        return (
          <Grid key={k} item xs={12} md={FieldsForm[k].grid}>
            {bodyField}
          </Grid>
        );
      })}
    </Grid>
  );
};
//
const Row = ({ children }) => {
  return (
    <Stack
      direction="row"
      sx={{ flex: 1, alignItems: "flex-end" }}
      spacing={0.5}
    >
      {children}
    </Stack>
  );
};
//
const LabelFormField = ({ text }) => {
  return (
    <Body2 fontWeight="bold" align="center" sx={{ whiteSpace: "nowrap" }}>
      {text}
    </Body2>
  );
};
//
const FieldCustom = ({
  control,
  name,
  disabled,
  propsTextField,
  defaultValue,
  sx,
  placeholder,
  type,
}) => {
  const isDark = useTheme()?.palette?.mode === "dark";
  const backgroundFieldCustom = !isDark && yellow[100];

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          fullWidth
          placeholder={placeholder}
          type={type || "text"}
          disabled={disabled}
          sx={{
            p: 0,
            flex: 1,
            ...sx,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: colorInputTextField,
            },
          }}
          {...field}
          {...propsTextField}
          variant="standard"
          inputProps={{
            autoComplete: "off",
            style: {
              fontSize: "16px",
              background: backgroundFieldCustom,
              color: colorInputTextField,
            },
          }}
        />
      )}
    />
  );
};
//
const FieldCustomSelect = ({
  name,
  control,
  options,
  disabled,
  placeholder,
  defaultValue,
}) => {
  const isDark = useTheme()?.palette?.mode === "dark";
  const backgroundColorDark = !isDark && yellow[100];
  const colorText = colorInputTextField;
  // Estilização do componente select
  const selectStyles = {
    input: (styles) => ({
      ...styles,
      color: colorText,
      fontWeight: "bold",
    }),
    valueContainer: (styles) => ({
      ...styles,
      backgroundColor: backgroundColorDark,
      color: colorText,
      fontWeight: "bold",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: backgroundColorDark,
      color: colorText,
      fontWeight: "bold",
      border: "1px solid #000000",
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      backgroundColor: backgroundColorDark,
      color: colorText,
      fontWeight: "bold",
    }),
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 1000,
      color: colorText,
      fontWeight: "bold",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: colorText,
      fontWeight: "bold",
    }),
    multiValue: (styles) => ({
      ...styles,
      color: colorText,
      fontWeight: "bold",
      backgroundColor: backgroundColorDark,
      border: "1px solid white",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: colorText,
      fontWeight: "bold",
      backgroundColor: backgroundColorDark,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: colorText,
      fontWeight: "bold",
      backgroundColor: backgroundColorDark,
      padding: "2px",
      border: "1px solid white",
    }),
    placeholder: (styles) => ({
      ...styles,
      //backgroundColor: backgroundColorDark,
      color: colorText,
      fontWeight: "bold",
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 1000,
      backgroundColor: backgroundColorDark,
      color: colorText,
      fontWeight: "bold",
    }),
    option: (styles) => ({
      ...styles,
      zIndex: 9999,
      backgroundColor: backgroundColorDark,
      color: colorText,
      fontWeight: "bold",
      borderBottom: "1px solid #ccc",
    }),
  };
  return (
    <Stack sx={{ flex: 1 }}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => (
          <Select
            isDisabled={disabled}
            placeholder={placeholder}
            styles={selectStyles}
            options={formatToSelect(options)}
            {...field}
          />
        )}
      />
    </Stack>
  );
};
//
//
const FormError = ({ children }) => {
  return (
    <Caption component="p" align="center" sx={{ color: red[600] }}>
      * {children}
    </Caption>
  );
};

// Wrapper para o ncp assim valida alguns parametros para formar a barra lateral.
const NcpForm = (props) => {
  const areas = useSelector(selectArea);

  return (
    <>
      {!areas ? (
        <NcpWrapperSemDados isBgRouter={!props.dataItem} />
      ) : props.dataItem ? (
        <Box
          sx={{
            background: ({ backgroundPage }) => backgroundPage,
          }}
        >
          <Form {...props} />
        </Box>
      ) : (
        <BackgroundRouter>
          <Form {...props} />
        </BackgroundRouter>
      )}
    </>
  );
};

NcpForm.rota = "/ncp_add";

export default NcpForm;
