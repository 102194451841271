import axios from 'axios';


const ROTAS = [
    '/get_dados_usuario',
]

export const INFO_USUARIO_GET_DADOS = ' INFO_USUARIO_GET_DADOS'

export const infoUsuarioGetDados = ()=> dispatch => {

    axios.get(ROTAS[0]).then(resp=>{
        if(resp.status !== 200){
            alert('ERRO AO TENTAR OBTER DADOS DO USUARIO');
            return false;
        }
        // Veja se retornou algum erro
        if(resp.data.erro){
            alert(resp.data.erro);
            return false;
        }
        // Tudo certo passe os dados para o reducer
        dispatch({
            type: INFO_USUARIO_GET_DADOS,
            data: resp.data
        });
    })
}