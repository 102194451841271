import axios from 'axios';
import { setAguardar, ERRO_STATUS_NAO_200, ERRO_NAO_ENVIADO } from './index';
import { ToastErro } from '../../utils/utils';
import { toast } from 'react-toastify';

//
export const MANUTENCAO_MODELO_INIT = 'MANUTENCAO_MODELO_INIT';
export const MANUTENCAO_MODELO_ADD = 'MANUTENCAO_MODELO_ADD';
export const MANUTENCAO_MODELO_UPD = 'MANUTENCAO_MODELO_UPD';
export const MANUTENCAO_MODELO_UPD_SITUACAO = 'MANUTENCAO_MODELO_UPD_SITUACAO';
export const MANUTENCAO_MODELO_SET_DRAWER  = 'MANUTENCAO_MODELO_SET_DRAWER';
export const MANUTENCAO_MODELO_FECHAR_DRAWER = 'MANUTENCAO_MODELO_FECHAR_DRAWER';

// 
const ROTAS = [
    '/modelo',
];
//
async function conexao(dispatch, fnConexao, rota, ACTION, _formData = null, fnFecharDrawer, desativaMsgSucesso ){
    dispatch(setAguardar(true));

    try {
        const resp = await fnConexao(rota, _formData);
        if(resp.status !== 200){
            ToastErro(ERRO_STATUS_NAO_200);
            return false;
        }
        //
        if(resp.data.erro){
            ToastErro(resp.data.erro);
            return false;
        }
        if(resp.data.sucesso && !desativaMsgSucesso){
            toast.dark(resp.data.sucesso, {
                type: 'success'
            });
        }
        // Tudo certo adiciona no objeto
        dispatch({
            type: ACTION,
            data: resp.data,
        });
        // Fecha o drawer em caso de sucesso
        if(fnFecharDrawer)  dispatch(fnFecharDrawer());
        
    } catch (error) {
        console.log(error);
        ToastErro(ERRO_NAO_ENVIADO);
    } finally {
        dispatch(setAguardar(false));
    }
}


// Obtem os dados iniciais
export const manutencaoModeloInit = ()=> dispatch => {
    conexao(dispatch, axios.get, ROTAS[0], MANUTENCAO_MODELO_INIT, null, null, null);  
}
// Adiciona um novo modelo
export const manutencaoModeloAdd = obj => dispatch => {
    const formData = new FormData();
    formData.append('dados', JSON.stringify(obj));
    const fnFecharDrawer = manutencaoModeloFecharDrawer;

    conexao(dispatch, axios.post, ROTAS[0], MANUTENCAO_MODELO_ADD, formData, fnFecharDrawer, false );

}
// Atualiza o nome do modelo
export const manutencaoModeloUpd = obj => dispatch => {
    const formData = new FormData();
    formData.append('dados', JSON.stringify(obj));
    const fnFecharDrawer = manutencaoModeloFecharDrawer;
    conexao(dispatch, axios.put, ROTAS[0], MANUTENCAO_MODELO_UPD, formData, fnFecharDrawer, false );

}
// Atualiza a situacao do modelo
export const manutencaoModeloUpdSituacao = obj => dispatch => {
    const formData = new FormData();
    formData.append('dados', JSON.stringify(obj));
    //const fnFecharDrawer = manutencaoModeloFecharDrawer;
    conexao(dispatch, axios.patch, ROTAS[0], MANUTENCAO_MODELO_UPD_SITUACAO, formData, null, false );
}
// Action para o drawer
export const manutencaoModeloSetDrawer = (obj)=>({
    type: MANUTENCAO_MODELO_SET_DRAWER,
    data: obj,
});
// Action para fechar o draewr
export const manutencaoModeloFecharDrawer = ()=>({
    type: MANUTENCAO_MODELO_FECHAR_DRAWER
})