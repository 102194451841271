import { Collapse, IconButton, Stack, TextField } from "@mui/material";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { galeriaDeFotosAddUpdComentario } from "./galeria-de-fotos-actions";
import Picker, { SkinTones } from "emoji-picker-react";

import SendIcon from "@mui/icons-material/Send";

// Componente que cria um painel para insercao/edicao de comentario
const PainelComentario = ({
  id_comentario,
  id_foto,
  fnFecharEditar,
  defaultComentario,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [verPainelEmoji, setVerPainelEmoji] = useState(false);
  const [comentario, setComentario] = useState(defaultComentario || "");
  // Cria uma funcao para registrar o emoji no corpo do comentario
  const fnRegistraEmoji = (e, emoji) => {
    //setVerPainelEmoji(false);
    setComentario((state) => state + emoji.emoji);
  };
  // Cria uma funcao que vai registrar o comentario
  const fnEnviarComentario = () => {
    const obj = { comentario };
    // Se for uma edicao de comentario nao precisa enviar o id_foto
    if (fnFecharEditar) {
      obj["id_comentario"] = id_comentario;
    } else {
      obj["id_foto"] = id_foto;
    }

    dispatch(galeriaDeFotosAddUpdComentario(obj, fnFecharEditar));
    // Limpar o comentario e fechar painel de emojis (caso aberto)
    setVerPainelEmoji(false);
    setComentario("");
  };

  return (
    <Stack>
      <Stack direction="row">
        <IconButton onClick={(e) => setVerPainelEmoji(!verPainelEmoji)}>
          <EmojiEmotionsIcon color={verPainelEmoji ? "primary" : "default"} />
        </IconButton>
        <TextField
          variant="standard"
          fullWidth
          type="textarea"
          placeholder={t("galeria_de_fotos.placeholderAddComment")}
          multiline
          value={comentario}
          onChange={(e) => setComentario(e.target.value)}
        />
        <IconButton
          disabled={comentario?.length < 1}
          onClick={fnEnviarComentario}
        >
          <SendIcon color={comentario?.length < 1 ? "default" : "primary"} />
        </IconButton>
      </Stack>
      <Collapse in={verPainelEmoji} timeout="auto" unmountOnExit>
        <Stack direction="row" justifyContent="center">
          <Picker
            pickerStyle={{ zIndex: 1 }}
            disableSearchBar
            onEmojiClick={fnRegistraEmoji}
            disableAutoFocus={true}
            skinTone={SkinTones}
            groupNames={{ smileys_people: "PEOPLE" }}
            native
          />
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default PainelComentario;
