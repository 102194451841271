import React, { useCallback } from "react";
import { ContainerAdaptavel } from "../../../extra-components";
import { useSet } from "react-use";
import { Box, Chip, Grow, Paper, Stack, useTheme } from "@mui/material";
import { EntradaForm, H6, Icone } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as yup from "yup";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ouvidoriaAplicarFiltroAvancado } from "./ouvidoria-actions";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  selectIds,
  selectSituacao,
  selectTemas,
  selectTipos,
} from "./ouvidoria-seletores";

function OuvidoriaFiltroAvancado() {
  const isMobile = useTheme()?.isMobile;
  //
  return (
    <ContainerAdaptavel
      sx={{
        backgroundColor: ({ palette }) => palette.mode !== "dark" && grey[200],
        minHeight: !isMobile ? "50vh" : "100vh",
      }}
    >
      <OpcoesFiltroAvancado />
    </ContainerAdaptavel>
  );
}
OuvidoriaFiltroAvancado.rota = "/ouvidoria_filtro_avancado";
//
const OpcoesFiltroAvancado = () => {
  const { t } = useTranslation();
  const [set, { has, toggle }] = useSet(new Set([]));
  const history = useHistory();
  const isMobile = useTheme().isMobile;
  const dispatch = useDispatch();

  const opcoesFiltro = [
    {
      titulo: t("ouvidoria_view.titleFilterId"),
      chave: "id",
      rotulo: t("ouvidoria_view.id"),
      icone: "VpnKey",
    },
    {
      titulo: t("ouvidoria_view.titleFilterTheme"),
      chave: "tema",
      rotulo: t("ouvidoria_view.theme"),
      icone: "Comment",
    },
    {
      titulo: t("ouvidoria_view.titleFilterType"),
      chave: "tipo",
      rotulo: t("ouvidoria_view.type"),
      icone: "SwapHoriz",
    },
    {
      titulo: t("ouvidoria_view.titleFilterSituation"),
      chave: "status",
      rotulo: t("ouvidoria_view.situacao"),
      icone: "Error",
    },
  ];
  //
  //
  const onSubmit = useCallback(
    (valor) => {
      const itens = [];
      Object.keys(valor).forEach((k) => {
        if (has(k)) {
          itens.push([
            k,
            Array.isArray(valor[k])
              ? _.map(valor[k], (arr) => arr.value).join(",")
              : valor[k].value,
          ]);
        }
      });
      // Se for mobile devemos retornar a pagina
      let funcaoRetornarSucesso;

      if (isMobile) {
        funcaoRetornarSucesso = history.goBack;
      }
      const parametros = new URLSearchParams(itens);

      dispatch(
        ouvidoriaAplicarFiltroAvancado(parametros, funcaoRetornarSucesso)
      );
    },
    [dispatch, has, isMobile, history]
  );

  return (
    <>
      <H6>{t("ouvidoria_view.titleFilter")}</H6>
      <Stack direction="row" sx={{ p: 1, width: "100%", overflow: "auto" }}>
        {opcoesFiltro.map((ele) => (
          <Chip
            title={ele.titulo}
            sx={{ mx: isMobile ? 0.5 : 1 }}
            color="primary"
            variant={has(ele.chave) ? "filled" : "outlined"}
            key={ele.icone}
            clickable
            onClick={() => toggle(ele.chave)}
            label={ele.rotulo}
            icon={<Icone icone={ele.icone} />}
          />
        ))}
      </Stack>

      <Grow in={set.size > 0} unmountOnExit>
        <Box>
          <FormFiltroAvancado filtros={[...set]} onSubmit={onSubmit} />
        </Box>
      </Grow>
    </>
  );
};

//
const FormFiltroAvancado = ({ filtros, onSubmit }) => {
  const { t } = useTranslation();
  const ouvidoriaId = useSelector(selectIds);
  const ouvidoriaTema = useSelector(selectTemas);
  const ouvidoriaTipo = useSelector(selectTipos);
  const ouvidoriaSituacao = useSelector(selectSituacao);

  const schema = [];
  const schemaMessageError = {};
  let schemaValidator = {};
  filtros.forEach((ele) => {
    switch (ele) {
      case "id":
        schema.push({
          label: t("ouvidoria_view.id"),
          type: "select",
          name: "id",
          isMulti: true,
          autoFormat: true,
          itens: ouvidoriaId,
        });
        schemaMessageError.id = t("ouvidoria_view.errorId");
        schemaValidator.id = obterValidador(VALIDADOR_TIPO.selectMultiplo);
        break;
      case "tema":
        schema.push({
          label: t("ouvidoria_view.theme"),
          type: "select",
          name: "tema",
          isMulti: true,
          autoFormat: true,
          itens: ouvidoriaTema,
        });
        schemaMessageError.tema = t("ouvidoria_view.errorTheme");
        schemaValidator.tema = obterValidador(VALIDADOR_TIPO.selectMultiplo);
        break;
      case "tipo":
        schema.push({
          label: t("ouvidoria_view.type"),
          type: "select",
          name: "tipo",
          isMulti: true,
          autoFormat: true,
          itens: ouvidoriaTipo,
        });
        schemaMessageError.tipo = t("ouvidoria_view.errorType");
        schemaValidator.tipo = obterValidador(VALIDADOR_TIPO.selectMultiplo);
        break;
      case "status":
      default:
        schema.push({
          label: t("ouvidoria_view.situation"),
          type: "select",
          name: "status",
          autoFormat: true,
          isMulti: true,
          itens: ouvidoriaSituacao,
        });
        schemaMessageError.status = t("ouvidoria_view.errorSituation");
        schemaValidator.status = obterValidador(VALIDADOR_TIPO.selectMultiplo);
        break;
    }
  });
  //
  schemaValidator = yup.object().shape(schemaValidator);

  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <EntradaForm
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
        onSubmit={onSubmit}
      />
    </Paper>
  );
};

export default OuvidoriaFiltroAvancado;
