import React, { useState, memo, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Icone from "./icone";
//
import { Paper, Stack, Typography } from "@mui/material";
import { toggleMenu } from "../redux/actions/controle-menu-actions";
//
const selectCorBarra = (state) =>
  state?.usuario?.variaveis_globais?.COR_BARRA_SITE;
const selectCorBarraFonte = (state) =>
  state?.usuario?.variaveis_globais?.COR_BARRA_SITE_FONTE;

const FAMILIA_ICONES = {
  Gestor: "Groups",
  RH: "Receipt",
  Admin: "Build",
  "Minha Equipe": "Groups",
  "Gestão Projetos": "AccountTree",
  EHS: "Security",
  "A Feed": "Feed",
  TWTTP: "ModelTraining",
  TI: "LocalCafe",
  Qualidade: "ReportProblem",
  Documentos: "FolderShared",
  "Lições Aprendidas": "MenuBook",
  Manutenção: "PrecisionManufacturing",
  BudGet: "CurrencyExchange",
};

const SideBar = ({ barraIntegrada }) => {
  const valorCorBarra = useSelector(selectCorBarra);
  const valorCorBarraFonte = useSelector(selectCorBarraFonte);
  const ref = useRef(null);

  const [toggled, setToggled] = useState(null);
  //const { usuario, controleMenu } = useSelector(state=> state);
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuario);
  const controleMenu = useSelector((state) => state.controleMenu);

  const MENUS = usuario?.menu;

  const history = useHistory();
  const fnRota = (rota) => {
    history.push(rota);

    if (!controleMenu) {
      dispatch(toggleMenu());
    }
  };
  const style = barraIntegrada
    ? {
        minHeight: "100%",
        overflowY: "auto",
      }
    : { position: "absolute", minHeight: "calc( 100vh - 52px)" };

  const FAMILIA = _.keys(MENUS);
  //
  if (ref.current && valorCorBarra) {
    ref.current.style.backgroundColor = valorCorBarra;
    ref.current.style.color = valorCorBarraFonte;

    ref.current.childNodes[0].style.backgroundColor = valorCorBarra;
    ref.current.childNodes[0].style.color = valorCorBarraFonte;
  }

  return (
    <Paper
      sx={{
        minHeight: "calc(100vh - 52px)",
        background: valorCorBarra,
        color: valorCorBarraFonte,
      }}
    >
      <ProSidebar
        ref={ref}
        collapsed={controleMenu}
        style={style}
        breakPoint="md"
        toggled={toggled}
        onToggle={() => setToggled(false)}
      >
        {FAMILIA?.map((fam, idxx) => (
          <Menu
            key={idxx}
            style={{ background: valorCorBarra, color: valorCorBarraFonte }}
          >
            {/* <MenuItem
            title={fam}
            icon={
              <Stack alignItems="center">
                <Icone icone={FAMILIA_ICONES[fam]} />
              </Stack>
            }
          >
            {fam}
          </MenuItem> */}
            <SubMenu
              title={fam}
              style={{ background: valorCorBarra, color: valorCorBarraFonte }}
              icon={
                <Stack alignItems="center">
                  <Icone
                    icone={FAMILIA_ICONES[fam]}
                    sx={{ color: valorCorBarraFonte }}
                  />
                  {controleMenu && (
                    <Typography
                      sx={{ fontSize: "75%", color: valorCorBarraFonte }}
                      variant="caption"
                    >
                      {fam.split(" ")[0] === "A"
                        ? fam.split(" ")[1]
                        : fam.split(" ")[0]}
                    </Typography>
                  )}
                </Stack>
              }
            >
              {MENUS[fam].map((arr, idx2) => (
                <MenuItem
                  style={{
                    paddingLeft: 8,
                    paddingRight: 8,
                    marginLeft: controleMenu && -24,
                    background: valorCorBarra,
                    color: valorCorBarraFonte,
                  }}
                  title={arr[1]}
                  icon={
                    <Stack alignItems="center">
                      <Icone icone={arr[2]} />
                      {/* {controleMenu && (
                      <Typography sx={{ fontSize: "80%" }} variant="caption">
                        {arr[1].split(" ")[0]}
                      </Typography>
                    )} */}
                    </Stack>
                  }
                  key={idx2}
                  onClick={() => fnRota(arr[0])}
                  prefix={false}
                >
                  <Typography variant="body2">{arr[1]}</Typography>
                </MenuItem>
              ))}
            </SubMenu>
          </Menu>
        ))}
      </ProSidebar>
    </Paper>
  );

  // return (
  //     <ProSidebar collapsed={controleMenu} style={style} breakPoint='md' toggled={toggled} onToggle={()=> setToggled(false)}>
  //         <Menu >
  //       {MENUS && Object.keys(MENUS).map((key)=>{
  //          return MENUS[key].map((arr,idx2)=>(
  //                 <MenuItem title={arr[1]} icon={(
  //                 <Stack alignItems='center'>
  //                     <Icone icone={arr[2]} />
  //                     {controleMenu && (
  //                     <Typography sx={{fontSize: '80%'}} variant='caption'>
  //                         {arr[1].split(' ')[0]}
  //                     </Typography>
  //                     )}

  //                 </Stack>
  //                 )} key={idx2} onClick={()=> fnRota(arr[0])} prefix={false}>
  //                   <Typography variant='body2'>{arr[1]}</Typography>
  //                 </MenuItem>
  //              ))
  //       })}
  //     </Menu>
  //     </ProSidebar>
  // )
};

export default memo(SideBar);
//export default SideBar;
