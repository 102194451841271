/**
 * Seletores para o feed
 */
import { createSelector } from "reselect";

const selectorTamanhoCampos = (state) => state?.tamanhoCampos;
export const selectorPlantUser = (state) => state?.usuario?.usuario?.planta;
export const selectorMatUser = (state) => state?.usuario?.usuario?.matricula;
export const selectorFeedRH = (state) => state?.feedRH;
export const selectorFeedXLike = (state) => state.feedRH?.feed_x_curtida;
export const selectorIsEditorFeed = (state) =>
  state?.usuario?.variaveis?.includes("MANUTENCAO_FEED");

export const selectorViewLogoFeed = (state) =>
  state?.usuario?.variaveis_globais?.LOGO_FEED;
//
export const fnSeletorTamanho = createSelector(selectorTamanhoCampos, (obj) => {
  const USADOS = {};
  Object.keys(obj).forEach((key) => {
    if (key === "feed") {
      USADOS["addFeedTitulo"] = obj[key].titulo;
      USADOS["addFeedAssunto"] = obj[key].assunto;
    }
  });
  return USADOS;
});
