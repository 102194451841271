import {
  Avatar,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import axios from "axios";
import useSWR from "swr";
import { Caption, H6, TabelaV2 } from "../../../components";
import { optTabela } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Seletores
const selectUsuarioPlanta = (state) => state?.usuario?.usuario?.planta;

// Fetch para determinar as datas
const fetchDados = async (url) => {
  const resp = await axios.get(url);
  if (resp.status !== 200) {
    alert("error");
    return { error: "Error internal", data: [] };
  }
  if (resp.data.erro) {
    return { error: resp.data.erro, data: [] };
  }
  // Tudo certo retorne os dados
  return resp.data;
};

// Banco de horas geral
const BancoDeHorasGeral = () => {
  const { t } = useTranslation();
  const { data } = useSWR("/minha_equipe_banco_de_horas_geral", fetchDados);
  const isMobile = useTheme()?.isMobile;

  return (
    <Container maxWidth="md">
      <H6>{t("minha_equipe.myEquipBankOfHoursGeneralPageTitle")}</H6>
      {!data ? (
        <Stack sx={{ my: 2 }} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : isMobile ? (
        <BancoDeHorasGeralMobile corpo={data.corpo} />
      ) : (
        <BancoDeHorasGeralTabela cabe={data.cabe} corpo={data.corpo} />
      )}
    </Container>
  );
};
//
const BancoDeHorasGeralTabela = ({ cabe, corpo }) => {
  const optTabelaT = {
    ...optTabela,
  };

  return <TabelaV2 cabe={cabe} corpo={corpo} {...optTabelaT} />;
};
//
const BancoDeHorasGeralMobile = ({ corpo }) => {
  const { t } = useTranslation();
  const planta = useSelector(selectUsuarioPlanta);

  return (
    <List disablePadding>
      {corpo.map((ele) => {
        const [matricula, nome, debito, credito, saldo] = ele;
        const isPositivo = saldo.substring(0, 1) === "+";

        return (
          <ListItem key={matricula} divider>
            <ListItemAvatar>
              <Avatar
                src={`/static/imagens/avatar/${planta}/${matricula}.jpg`}
                alt={nome}
              />
            </ListItemAvatar>
            <ListItemText
              primary={nome}
              secondary={
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="flex-end"
                  sx={{ mt: 1 }}
                >
                  <Caption fontWeight={500} color="error">
                    {t("minha_equipe.myEquipBankOfHoursGeneralDebit")} {debito}
                  </Caption>
                  <Caption
                    fontWeight={500}
                    sx={{ color: (theme) => theme.palette.info.main }}
                  >
                    {t("minha_equipe.myEquipBankOfHoursGeneralCredit")}{" "}
                    {credito}
                  </Caption>
                  <Caption
                    fontWeight={500}
                    sx={{
                      color: (theme) =>
                        theme.palette[isPositivo ? "info" : "error"].main,
                    }}
                  >
                    {t("minha_equipe.myEquipBankOfHoursGeneralBalance")} {saldo}
                  </Caption>
                </Stack>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default BancoDeHorasGeral;
