import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  Container,
  Stack,
  Typography,
  Divider,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
  Hidden,
  Button,
  CircularProgress,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { useForm, Controller } from "react-hook-form";
import { cadastroProjetosResponderChecklist } from "./kaizen-actions";
import { formatarParaSelect } from "../../../utils/utils";
import { Icone } from "../../../components";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

// Este componente e usado para gerar o formulario de checklist para determinar o aproveitamento
const FormularioChecklist = ({ checklist }) => {
  const { t } = useTranslation();
  // Estado para determinar qual o checklist escolhido
  const [checklistEscolhido, setChecklistEscolhido] = useState(null);
  // Separa a lista de checklists
  const listaChecklists = formatarParaSelect(
    Object.keys(checklist).map((key) => [key, key.split("_")[1]])
  );
  // ChecklistSelecionado
  const checklistPerguntas = checklistEscolhido
    ? checklist[checklistEscolhido].perguntas
    : null;

  // Passa sobre as perguntas para montar o questionario
  return (
    <Container maxWidth="md">
      <Stack sx={{ minHeight: "30vh", maxHeight: "85vh" }}>
        <Stack sx={{ mb: 1 }}>
          <Typography align="center" variant="subtitle2">
            {t("cadastro_projetos.chooseChecklist")}
          </Typography>
          <Select
            options={listaChecklists}
            defaultValue={null}
            placeholder={t("cadastro_projetos.chooseChecklistPlaceholder")}
            onChange={(e) => {
              setChecklistEscolhido(e.value);
            }}
          />
          <Divider sx={{ mt: 1 }} />
        </Stack>
        <AnimatePresence>
          {checklistEscolhido && (
            <FormularioChecklistEscolhido
              key={checklistEscolhido}
              checklistPerguntas={checklistPerguntas}
              checklist={checklist}
              checklistEscolhido={checklistEscolhido}
            />
          )}
        </AnimatePresence>
      </Stack>
    </Container>
  );
};

// Formulario do ckecklist separado
const FormularioChecklistEscolhido = ({
  checklistEscolhido,
  checklist,
  checklistPerguntas,
}) => {
  // Pegar o aguarde
  const { aguardar } = useSelector((state) => state);
  const dispatch = useDispatch();
  //
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // Funcao de callback para pegar as opçoes escolhidas
  const fn = (val) => {
    // Pega cada resposta
    const obj_comentarios = {};
    const obj_respostas = {};
    Object.keys(val).forEach((key) => {
      if (key.search("_comentario") !== -1) {
        // E um comentario, chave é o idPergunta
        obj_comentarios[key.split("_")[0]] = val[key]; // Resultado do comentario
      } else {
        // E a pergunta com a opcao como resposta
        obj_respostas[key] = parseInt(val[key]);
      }
    });
    // Depois disto termos um objeto comentarios e um com respostas, unifique os dois
    const obj_resp = [];
    Object.keys(obj_comentarios).forEach((key) => {
      obj_resp.push({
        id_pergunta: parseInt(key),
        id_opcao: obj_respostas[key],
        comentario: obj_comentarios[key],
      });
    });
    // Despachando os dados para submeter as respostas escolhidas para qualificar o checklist
    dispatch(
      cadastroProjetosResponderChecklist({
        id_checklist: checklist[checklistEscolhido].id_checklist,
        id_projeto: parseInt(checklist[checklistEscolhido].id_projeto),
        respostas: obj_resp,
      })
    );
  };

  return (
    <Stack>
      {checklistPerguntas.map((ele, idx) => (
        <motion.div
          key={`${idx}_${ele.titulo}`}
          initial={{ scale: 0.01 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.01 }}
        >
          <Paper sx={{ p: 1, m: 1 }} elevation={3} key={idx}>
            <Stack>
              <Typography variant="h6" align="center">
                {ele.titulo}
              </Typography>
              <Typography variant="body2" align="center">
                {ele.descricao}
              </Typography>
              {/* Montar um radiobutton para escolher */}
              <Controller
                name={ele.id_pergunta.toString()}
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack>
                    <RadioGroup aria-label={ele.id_pergunta} {...field}>
                      <Stack
                        sx={{ mb: 1 }}
                        direction="row"
                        justifyContent="space-evenly"
                      >
                        {ele.opcoes.map((ele2, idx) => (
                          <FormControlLabel
                            key={idx}
                            value={ele2.id_opcao}
                            label={
                              <Typography variant="caption">
                                {ele2.titulo}
                              </Typography>
                            }
                            control={<Radio size="small" />}
                          />
                        ))}
                      </Stack>
                    </RadioGroup>
                    {!!errors[ele.id_pergunta] && (
                      <Typography
                        align="center"
                        color="error"
                        variant="caption"
                      >
                        {t("cadastro_projetos.optionError")}
                      </Typography>
                    )}
                  </Stack>
                )}
              />
              <Controller
                name={`${ele.id_pergunta.toString()}_comentario`}
                control={control}
                defaultValue=""
                //rules={{required: true}}
                render={({ field }) => (
                  <Stack>
                    <TextField
                      type="textarea"
                      multiline
                      size="small"
                      placeholder={t("cadastro_projetos.commentPlaceholder")}
                      minRows={1}
                      {...field}
                      fullWidth
                    />
                    {!!errors[`${ele.id_pergunta}_comentario`] && (
                      <Typography
                        align="center"
                        color="error"
                        variant="caption"
                      >
                        {t("cadastro_projetos.commentError")}
                      </Typography>
                    )}
                  </Stack>
                )}
              />
            </Stack>
          </Paper>
        </motion.div>
      ))}
      <Grid container>
        <Hidden mdDown>
          <Grid item md={4} />
        </Hidden>
        <Grid item xs={12} md={4}>
          <Button
            disabled={aguardar}
            fullWidth
            startIcon={
              aguardar ? (
                <CircularProgress size={20} />
              ) : (
                <Icone icone="FactCheck" />
              )
            }
            sx={{ m: 1 }}
            onClick={handleSubmit(fn)}
            variant="contained"
          >
            {t("cadastro_projetos.responseChecklistInfo")}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default FormularioChecklist;
