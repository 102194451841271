import {
    CARTAO_SEGURANCA_GET_CARTAO,
    CARTAO_SEGURANCA_GET_DADOS,
    CARTAO_SEGURANCA_SET_DRAWER,
    CARTAO_SEGURANCA_FECHAR_DRAWER,
    CARTAO_SEGURANCA_ADD,
    CARTAO_SEGURANCA_UPD_EM_ANALISE,
    CARTAO_SEGURANCA_ADD_JUSTIFICATIVA,
    CARTAO_SEGURANCA_ADD_VALIDACAO,
    CARTAO_SEGURANCA_SET_FILTRO_STATUS,
    CARTAO_SEGURANCA_SET_FILTRO_TIPO_CARTAO,

} from '../actions';

export default function cartaoSegurancaReducer(state = null, action){
    switch(action.type){
        case CARTAO_SEGURANCA_GET_DADOS:
            return {
                ...state,
                ...action.data,
            }
        case CARTAO_SEGURANCA_GET_CARTAO:
            return {
                ...state,
                ...action.data,
            }
        case CARTAO_SEGURANCA_SET_DRAWER: // Para setar o drawer
            return {
                ...state,
                drawer: action.data
            }
        case CARTAO_SEGURANCA_FECHAR_DRAWER: // Para fechar o drawer
            return {
                ...state,
                drawer: null,
            }
        case CARTAO_SEGURANCA_ADD: // Para quando um cartao de seguranca e adicionado
            return {
                ...state,
                cartoes: state.cartoes.concat(action.data.cartao),
                cartao_x_anexo: {
                    ...state.cartao_x_anexo,
                    ...action.data.cartao_x_anexo,
                }
            }
        case CARTAO_SEGURANCA_UPD_EM_ANALISE: // Para mover o cartao para o status em_analise
            return (()=>{
                const { cartoes, cartao_x_justificativa } = state;
                const ID = action.data.id;

                // Procura o cartao e altera a sua situacao
                cartoes.forEach(ele=>{
                    if(String(ele.id) === String(ID)){
                        ele.situacao = 'S';
                        return true;
                    }
                });
                // Retira o item do cartao_x_justificativa
                if(cartao_x_justificativa){
                    delete cartao_x_justificativa[ID];
                }

                // Passa agora para mesclar cartao_x_solucao com action.data.obj;
                return {
                    ...state,
                    ...cartoes,
                    cartao_x_justificativa: {
                        ...cartao_x_justificativa
                    },
                    cartao_x_solucao: {
                        ...state.cartao_x_solucao,
                        ...action.data.obj
                    }
                }
            })();
        case CARTAO_SEGURANCA_ADD_JUSTIFICATIVA: // Para adicionar uma justificativa ao cartao e atualizar seu status
            return (()=>{
                const { data_cartao, data } = action.data;
                const { cartoes, cartao_x_solucao } = state;
                // Primeiro passo e ataulizar o status do cartao
                cartoes.forEach(ele=>{
                    if(String(ele.id) === String(data_cartao.id_cartao) ){
                        ele.situacao = data_cartao.status;
                        return;
                    }
                });
                // Se o status for reprovado, preciso retirar a solucao de la
                if(data_cartao.status === 'A'){
                    delete cartao_x_solucao[data_cartao.id_cartao];
                }
                // Agora inclua esta justificativa na lista de justificativas
                return {
                    ...state,
                    cartoes: cartoes,
                    cartao_x_solucao,
                    cartao_x_justificativa: {
                        ...state.cartao_x_justificativa,
                        ...data,
                    }
                }

            })();
        case CARTAO_SEGURANCA_ADD_VALIDACAO:
            return (()=>{
                const { data_cartao, data } = action.data;
                const { cartoes } = state;
                // Primeiro passo e ataulizar o status do cartao
                cartoes.forEach(ele=>{
                    if(String(ele.id) === String(data_cartao.id_cartao) ){
                        ele.situacao = data_cartao.status;
                        return;
                    }
                });
                // Agora inclua esta validacao na lista de validacao
                return {
                    ...state,
                    cartoes: cartoes,
                    cartao_x_validacao: {
                        ...state.cartao_x_validacao,
                        ...data,
                    }
                }
            })();
        case CARTAO_SEGURANCA_SET_FILTRO_STATUS: // Define o status sendo filtrado
            return {
                ...state,
                cartao_filtro_status: state?.cartao_filtro_status !== action.data ? action.data : null,
            }
        case CARTAO_SEGURANCA_SET_FILTRO_TIPO_CARTAO: // Define um filtro por tipo de cartao
            return (()=>{
                const cartao_filtro_tipo = state.cartao_filtro_tipo || [];
                // Copia a quantidade por status (ela será sobrescrita se tiver um filtro por tipo aplicado)
                const cartao_filtro_tipo_qtd_status = {...state.cartao_status};
                // Verifica se o tipo a ser filtrado existe no array, caso exista remova-o senao insira-o
                if(cartao_filtro_tipo.includes(action.data)){
                    const idx = cartao_filtro_tipo.indexOf(action.data);
                    cartao_filtro_tipo.splice(idx, 1);
                } else {
                    cartao_filtro_tipo.push(action.data);
                }
                // Se existir algo no cartao_filtro_tipo faca a contagem para ver quantos tem para cada status
                if(cartao_filtro_tipo.length > 0){
                    Object.keys(cartao_filtro_tipo_qtd_status).forEach(key=> cartao_filtro_tipo_qtd_status[key] = 0 );

                    state.cartoes.forEach(ele=>{
                        if(cartao_filtro_tipo.includes(ele.cartao_tipo)){
                            cartao_filtro_tipo_qtd_status[ele.situacao] += 1;
                        }
                    });
                }

                return {
                    ...state,
                    cartao_filtro_tipo,
                    cartao_filtro_tipo_qtd_status,
                }

            })();
        default:
            return state;
    }
}