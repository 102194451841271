import { useSelector } from "react-redux";
import {
  selectorFeedXLike,
  selectorMatUser,
  selectorPlantUser,
} from "../selectors";
import {
  Avatar,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { RetornoEmBranco } from "../../../../components";
import { format, parseISO } from "date-fns";
import { memo } from "react";
import { getAnimations } from "..";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";

//
const defaultOptions = {
  loop: true,
  autoplay: true,
  delay: 1000,
  animationData: "",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
// Objeto com todos os tipos representados por emojis
const TIPOS = {
  1: "😀", // Ok
  2: "😌", // OK
  3: "😍", // OK
  4: "🙄", // OK
  5: "😟", // OK
  6: "😡", // OK
  7: "👍",
};

// Componente para exibir as curtidas de um post
const ListCurtidas = ({ curtidas, id_feed }) => {
  const feed_x_curtida = useSelector(selectorFeedXLike);
  const matricula = useSelector(selectorMatUser);
  const planta = useSelector(selectorPlantUser);
  const { t } = useTranslation();

  return (
    <Container
      maxWidth="md"
      sx={{ py: 1, minHeight: "50vh", maxHeight: "80vh" }}
    >
      <Paper
        elevation={2}
        sx={{
          p: 1,
          zIndex: 100,
          //background: "white",
          position: "sticky",
          top: 0,
        }}
      >
        <Typography
          component="p"
          fontWeight="bold"
          align="center"
          variant="caption"
        >
          {t("feed_rh.titleFeedLikes")}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={1}>
          {Object.keys(feed_x_curtida[id_feed]?.tipos).map((key) => (
            <Typography key={key} variant="caption">
              {TIPOS[key]} ({feed_x_curtida[id_feed].tipos[key]})
            </Typography>
          ))}
        </Stack>
      </Paper>

      {curtidas?.length > 0 ? (
        <List>
          {curtidas.map((ele) => (
            <ListaItemCurtidas
              key={ele.id_curtida}
              item={ele}
              minhaCurtida={ele.mat === matricula && planta === ele.planta}
            />
          ))}
        </List>
      ) : (
        <RetornoEmBranco />
      )}
    </Container>
  );
};

// Componente para exibir os itens das curtidas
const ListaItemCurtidas = memo(({ item, minhaCurtida }) => {
  const { t } = useTranslation();
  const ANIMACOES = getAnimations(t);
  const { nome, avatar, data, tipo } = item;
  const copia = { ...defaultOptions };
  copia.animationData = tipo
    ? ANIMACOES.filter((ele) => parseInt(ele.tipo) === parseInt(tipo))[0]
        .animacao
    : null;

  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar src={avatar}>{nome.substring(0, 1)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={nome}
        secondary={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ opacity: 0.7 }} align="right" variant="caption">
              {format(parseISO(data), "dd/MM/yy HH:mm")}
            </Typography>
            <Lottie options={copia} height={36} width={36} />
          </Stack>
        }
      />
    </ListItem>
  );
});

export default ListCurtidas;
