import { useDispatch } from "react-redux";
import { twttpAtualizaStatus, twttpFecharDrawer } from "./twttp-actions";
import { useCallback } from "react";
import { Confirmar } from "../../../components";
import { useTranslation } from "react-i18next";

//
const ConfirmarMudancaStatus = ({ status, id_twttp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fnCancelar = useCallback(
    () => dispatch(twttpFecharDrawer()),
    [dispatch]
  );
  const fnConfirmar = useCallback(
    () => dispatch(twttpAtualizaStatus({ id_twttp, status })),
    [dispatch, id_twttp, status]
  );

  return (
    <Confirmar
      titulo={t("twttp.titleTwttpConfirm")}
      subtitulo={t("twttp.subtitleTwttpConfirm")}
      fnCancelar={fnCancelar}
      fnConfirmar={fnConfirmar}
    />
  );
};

export default ConfirmarMudancaStatus;
