import {SET_AGUARDAR} from '../actions';


const aguardarReducer = (state = false, action)=>{
    switch (action.type) {
        case SET_AGUARDAR:
            return action.hasOwnProperty('valor') ? action.valor : !state;
        default:
            return state;
    }
}

export default aguardarReducer;