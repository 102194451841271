import { ContainerAdaptavel } from "../../../extra-components";
import FormAddVariavel from "./manutencao-variaveis-form-add";
import FormEditVariavelGlobal from "./manutencao-variaveis-form-edit-var-global";
import ListDeVariaveisColaborador from "./manutencao-variaveis-lis-var-colab";
import FormDelVariavelGlobal from "./manutencao-variaveis-form-del-var-global";
import FormAddVariavelGlobal from "./manutencao-variaveis-form-add-var-global";

const ManutencaoVariaveisModal = ({ modal, fecharModal }) => {
  let corpo;
  if (modal) {
    switch (modal.tipo) {
      case ManutencaoVariaveisModal.modal.VER_VARIAVEL_USUARIO: // Ver variaveis do usuario
        corpo = <ListDeVariaveisColaborador fecharDrawer={fecharModal} />;
        break;
      case ManutencaoVariaveisModal.modal.EDITAR_VARIAVEL: // Para editar uma variavel
        corpo = <FormAddVariavel />;
        break;
      case ManutencaoVariaveisModal.modal.ADD_VARIAVEL: // Para adicionar uma variavel
        corpo = <FormAddVariavel />;
        break;
      case ManutencaoVariaveisModal.modal.EDITAR_VARIAVEL_GLOBAL: // Para editar uma variavel global
        corpo = <FormEditVariavelGlobal />;
        break;
      case ManutencaoVariaveisModal.modal.DEL_VARIAVEL_GLOBAL: // Para excluir uma variavel global
        corpo = <FormDelVariavelGlobal />;
        break;
      case ManutencaoVariaveisModal.modal.ADD_VARIAVEL_GLOBAL: // Para exibir formulario de adicao de uma variavel glboal
        corpo = <FormAddVariavelGlobal />;
        break;
      default:
        break;
    }
  }

  return <ContainerAdaptavel>{corpo}</ContainerAdaptavel>;
};

ManutencaoVariaveisModal.modal = {
  VER_VARIAVEL_USUARIO: "VER_VARIAVEL_USUARIO",
  EDITAR_VARIAVEL: "EDITAR_VARIAVEL",
  ADD_VARIAVEL: "ADD_VARIAVEL",
  EDITAR_VARIAVEL_GLOBAL: "EDITAR_VARIAVEL_GLOBAL",
  DEL_VARIAVEL_GLOBAL: "DEL_VARIAVEL_GLOBAL",
  ADD_VARIAVEL_GLOBAL: "ADD_VARIAVEL_GLOBAL",
};

export default ManutencaoVariaveisModal;
