import React, { useState, useMemo, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Switch,
  FormControlLabel,
  Button,
  TextField,
  Container,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
//
import SearchIcon from "@mui/icons-material/Search";
//
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { format, subDays } from "date-fns";

import { useFetch } from "../../../hooks";
import { registrarAcesso } from "../../../utils/utils";
import { SideBar, TabelaV2 } from "../../../components";
import { useTranslation } from "react-i18next";

//
const schema = yup.object().shape({
  de: yup.date().required(),
  ate: yup.date().required(),
});

const Absenteismo = () => {
  const { t } = useTranslation();
  const { wait, setFetch, data, error } = useFetch(
    window.location.pathname,
    "POST"
  );
  // Executar a primeira consulta para buscar dados da data atual
  useEffect(() => {
    setFetch({
      de: format(new Date(), "yyyy-MM-dd"),
      ate: format(new Date(), "yyyy-MM-dd"),
    });
  }, [setFetch]);
  // UseEffect para registrar acesso a pagina
  useEffect(() => registrarAcesso(window.location.pathname), []);
  // Sempre que tiver error deve chamar o toast para exibi-lo
  useEffect(() => toast.dark(error, { type: "error" }), [error]);

  return (
    <Stack sx={{}} direction="row">
      <SideBar barraIntegrada />
      <Container maxWidth={false}>
        <ToastContainer position="bottom-center" />
        <Typography variant="h6" align="center">
          {t("absenteismo.title")}
        </Typography>
        <FormConsulta aguardar={wait} onSubmit={setFetch} />
        {data && <Corpo data={data} />}
      </Container>
    </Stack>
  );
};
// Componente que executa a consulta dos dados
const FormConsulta = ({ aguardar, onSubmit }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  // Funcao para receber os dados para a consulta
  const fn = (val) => {
    const { de, ate } = val;
    onSubmit({
      de: format(de, "yyyy-MM-dd"),
      ate: format(ate, "yyyy-MM-dd"),
    });
  };

  return (
    <Stack
      spacing={1}
      direction={{ xs: "column", md: "row" }}
      alignItems="center"
      justifyContent="center"
    >
      <Controller
        name="de"
        control={control}
        defaultValue={format(subDays(new Date(), 1), "yyyy-MM-dd")}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            type="date"
            {...field}
            fullWidth
            size="small"
            label={t("absenteismo.labelDateFrom")}
            disabled={aguardar}
            InputLabelProps={{ shrink: true }}
            error={!!errors.de}
            helperText={!!errors.de && t("absenteismo.errorDateFormat")}
          />
        )}
      />
      <Controller
        control={control}
        name="ate"
        defaultValue={format(subDays(new Date(), 1), "yyyy-MM-dd")}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            type="date"
            {...field}
            fullWidth
            disabled={aguardar}
            size="small"
            label={t("absenteismo.labelDateTo")}
            InputLabelProps={{ shrink: true }}
            error={!!errors.ate}
            helperText={!!errors.ate && t("absenteismo.errorDateFormat")}
          />
        )}
      />
      <Button
        onClick={handleSubmit(fn)}
        fullWidth
        variant="contained"
        disabled={aguardar}
        startIcon={aguardar ? <CircularProgress size={20} /> : <SearchIcon />}
      >
        {t("absenteismo.labelBtnSearch")}
      </Button>
    </Stack>
  );
};
// Componente para exibir a tabela com os dados
const Corpo = ({ data }) => {
  const [filtro, setFiltro] = useState(false);

  const optTabela = {
    data: [9],
    baixar_em_excel: "/baixar_em_excel",
  };
  // Indexa a coluna do abono
  const indice = 6;
  //
  const corpo = useMemo(
    () =>
      data.corpo.filter((ele) =>
        !filtro ? ele[indice] === "SEM JUSTIFICATIVA" : true
      ),
    [filtro, data.corpo]
  );
  console.log(corpo);

  return (
    <Stack sx={{ mt: 1 }}>
      <TabelaV2
        ocultarColuna
        cabe={data?.cabe}
        corpo={corpo}
        tamanho="72vh"
        {...optTabela}
        styleCorpo={{ whiteSpace: "nowrap", fontSize: "75%" }}
        styleCabe={{ fontSize: "80%" }}
        render={() => <PainelTabela filtro={filtro} setFiltro={setFiltro} />}
      />
    </Stack>
  );
};
// Componente que exibe um painel para que o usuario determine os eventos que tem justificativa e nao tem justificativa
const PainelTabela = ({ filtro, setFiltro }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row">
      <FormControlLabel
        control={
          <Switch
            value={filtro}
            onChange={(e) => setFiltro(e.target.checked)}
          />
        }
        label={t("absenteismo.labelViewAll")}
      />
    </Stack>
  );
};

Absenteismo.rota = "/absenteismo";

export default Absenteismo;
