import React, { useEffect } from "react";
import { Container } from "@mui/material";
import BackgroundRouter from "../../background-router";
import { H6, Pesquisando } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { manutencaoComponentesInit } from "./manutencao-componentes-actions";
import { useToggle } from "react-use";
import { selectDados } from "./manutencao-components-selectors";
import ManutencaoComponentesCorpo from "./manutencao-componentes-body";
import { useTranslation } from "react-i18next";

function ManutencaoComponentes() {
  const { t } = useTranslation();
  const [aguardar, setAguardar] = useToggle();
  const dispatch = useDispatch();
  //
  const dados = useSelector(selectDados);

  useEffect(() => {
    const formData = new URLSearchParams();
    formData.append("tipo", "componente");
    dispatch(manutencaoComponentesInit(formData, setAguardar));
  }, [dispatch, setAguardar]);

  return (
    <BackgroundRouter>
      <Container maxWidth={false}>
        <H6>{t("manutencao_componentes.titlePage")}</H6>
        {aguardar ? (
          <Pesquisando />
        ) : dados?.componentes ? (
          <ManutencaoComponentesCorpo {...dados} />
        ) : null}
      </Container>
    </BackgroundRouter>
  );
}

ManutencaoComponentes.rota = "/manutencao_componentes";

export default ManutencaoComponentes;
