import { useDispatch, useSelector } from "react-redux";
import { seletorCardSecurityWait } from "./cartao-seguranca-selectors";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cartaoSegurancaJustificativa } from "../../redux/actions";
import {
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Icone } from "../../components";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

// Schema para validar a justificativa
const schemaFormJustificativa = yup.object().shape({
  justificativa: yup.string().min(3).required(),
});

// Componnente para gerar justificativa
const FormJustificativa = ({ id_cartao }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aguardar = useSelector(seletorCardSecurityWait);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schemaFormJustificativa),
  });
  //
  const fn = (val, status) => {
    // Status (A volta para o status aberto, necessario gerar causa-raiz novamente, E encerrado, cartão foi encerrado)
    console.log(val, " ", status);
    dispatch(
      cartaoSegurancaJustificativa({
        justificativa: val.justificativa,
        status,
        id_cartao,
      })
    );
  };

  return (
    <Container maxWidth="sm">
      <Stack spacing={2}>
        <Typography variant="h6" align="center">
          {t("cartao_seguranca.titleFormJustify")}
        </Typography>
        <Controller
          control={control}
          name="justificativa"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              fullWidth
              type="textarea"
              multiline
              minRows={3}
              {...field}
              label={t("cartao_seguranca.labelJustify")}
              InputLabelProps={{ shrink: true }}
              placeholder={t("cartao_seguranca.placeholderJustify")}
              error={!!errors.justificativa}
              helperText={
                !!errors.justificativa && t("cartao_seguranca.errorMinChar")
              }
            />
          )}
        />
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent={{ xs: "stretch", md: "center" }}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ flex: { xs: 1 } }}
            startIcon={
              aguardar ? (
                <CircularProgress size={20} />
              ) : (
                <Icone icone="ThumbDown" />
              )
            }
            disabled={aguardar}
            onClick={handleSubmit((val) => fn(val, "A"))}
          >
            {t("cartao_seguranca.textBtnReprove")}
          </Button>
          <Button
            variant="contained"
            sx={{ flex: { xs: 1 } }}
            startIcon={
              aguardar ? (
                <CircularProgress size={20} />
              ) : (
                <Icone icone="ThumbUp" />
              )
            }
            disabled={aguardar}
            onClick={handleSubmit((val) => fn(val, "E"))}
          >
            {t("cartao_seguranca.textBtnApprove")}
          </Button>
        </Stack>
        <br />
      </Stack>
    </Container>
  );
};

export default FormJustificativa;
