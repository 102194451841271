import { Paper, Typography } from "@mui/material";
import FormatarDados from "./estatisticas-format-data";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";

// Retorna o grafico de acesso por dias
const RenderGrafAcessoDia = ({ isMobile, data }) => {
  const { t } = useTranslation();
  const novosDados = FormatarDados(5, data);
  // Agora nomear cada dia dando o seu valor
  const dadosParaGrafico = Object.keys(novosDados).map((key) => ({
    nome: key,
    valor: novosDados[key],
  }));

  return (
    <Paper sx={{ p: 2, m: 1 }} elevation={2}>
      <Typography align="center" variant="subtitle2">
        {t("estatisticas.titleAccessByDay")}
      </Typography>

      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          margin={{ right: 10, top: 10, left: 20, bottom: 0 }}
          width="100%"
          height={300}
          data={dadosParaGrafico}
        >
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#005387" stopOpacity={1}></stop>
              <stop offset="90%" stopColor="#005387" stopOpacity={0.5}></stop>
            </linearGradient>
          </defs>
          <XAxis
            angle={isMobile ? 45 : 0}
            tickLine={false}
            axisLine={false}
            dataKey="nome"
            tickFormatter={(str) => {
              return format(parseISO(str), "dd/MM", { locale: ptBR });
            }}
          />
          {!isMobile && (
            <YAxis tickLine={false} axisLine={false} tickCount={8} />
          )}
          <Tooltip
            labelFormatter={(str) => {
              return (
                <Typography variant="caption">
                  {format(parseISO(str), "EEEE dd/MM", { locale: ptBR })}
                </Typography>
              );
            }}
          />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Area
            dot={{ stroke: "#005387", strokeWidth: 4 }}
            type="monotone"
            dataKey="valor"
            fill="url('#color')"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default RenderGrafAcessoDia;
