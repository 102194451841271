import { useDispatch, useSelector } from "react-redux";
import { selectRegistradosAVaga } from "./oportunidades-seletores";
import { useEffect } from "react";
import {
  oportunidadesCandidatosVaga,
  oportunidadesCandidatosVagaLimpar,
} from "./oportunidades_actions";
import {
  Avatar,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { Body1, Subtitle1 } from "../../../components";
import { blue } from "@mui/material/colors";
import InfoColaboradorVaga from "./oportunidades-info-colab-vaga";
import { useTranslation } from "react-i18next";

const ListaDeCandidatosAVaga = ({ vaga }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cadastrados = useSelector(selectRegistradosAVaga);
  useEffect(() => {
    // Lê os candidatos a vaga
    dispatch(oportunidadesCandidatosVaga(vaga, () => {}));
    // Limpa os candidatos que foram recuperados para informacao a registro da vaga
    return () => dispatch(oportunidadesCandidatosVagaLimpar());
  }, [dispatch, vaga]);
  return (
    <Paper elevation={0} sx={{ color: "black", backgroundColor: blue[100] }}>
      <Subtitle1>{t("oportunidades.titleVacationPage")}</Subtitle1>
      <Divider />
      <List>
        {cadastrados?.map((ele) => (
          <ListItem key={ele.matricula} divider>
            <ListItemAvatar>
              <Avatar src={ele.avatar} alt={ele.nome} />
            </ListItemAvatar>
            <ListItemText
              secondaryTypographyProps={{
                color: "#141414",
              }}
              primary={
                <Stack>
                  <Chip
                    color="success"
                    size="small"
                    label={`${t("oportunidades.phase")}: ${ele.fase}`}
                  />
                  <Body1>{ele.funcao}</Body1>
                </Stack>
              }
              secondary={<InfoColaboradorVaga dados={ele} />}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default ListaDeCandidatosAVaga;
