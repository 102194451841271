import {
    MANUTENCAO_COMPONENTES_FECHAR_MODAL,
    MANUTENCAO_COMPONENTES_INIT,
    MANUTENCAO_COMPONENTES_LIMPAR_DADOS,
    MANUTENCAO_COMPONENTES_SET_MODAL,
} from './manutencao-componentes-actions';

const INIT = {

}

export default function manutencaoComponentesReducer(state = INIT, action){
    switch(action.type){
        case MANUTENCAO_COMPONENTES_INIT:
            return {
                ...state,
                ...action.data,
            }
        case MANUTENCAO_COMPONENTES_LIMPAR_DADOS:
            return INIT;
        case MANUTENCAO_COMPONENTES_SET_MODAL:
            return {
                ...state,
                modal: action.data,
            }
        case MANUTENCAO_COMPONENTES_FECHAR_MODAL:
            return {
                ...state,
                modal: null
            }
        default:
            return state;
    }
}