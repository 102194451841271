import axios from "axios";
import {
  Stack,
  Container,
  useMediaQuery,
  useTheme,
  Grid,
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Paper,
  ButtonBase,
  Button,
  Divider,
  Box,
  Chip,
  Badge,
} from "@mui/material";
import { format } from "date-fns";
import { parseISO } from "date-fns/esm";
import React, { useCallback, memo, useState, useEffect } from "react";
import {
  AnimacaoSemDados,
  Body2,
  Caption,
  Comentario,
  FabExtend,
  H6,
  Icone,
  Pesquisando,
} from "../../components";
import Filtro from "../../components/input-busca";
import { DrawerDialog } from "../../extra-components";
import { useFetch } from "../../hooks";
import { sxStyleContainer, ToastErro } from "../../utils/utils";
import { useList } from "react-use";
import _ from "lodash";
import { blue, grey, lightBlue, lightGreen } from "@mui/material/colors";
import { useRef } from "react";
import BackgroundRouter from "../background-router";
import { ptBR } from "date-fns/locale";
import useSWR from "swr";
import fetchGetMensagem from "../../api/fetch_mensagem";

//
const ROTAS = ["/mensagem_consulta_colaboradores", "/mensagem"];

// // Esta funcao trata de recuperar as ultimas mensagens do chat
// async function recuperarMensagensChat(chatId, idMensagem) {
//   try {
//     const resp = await axios.get(`${ROTAS[1]}/${chatId}?apos=${idMensagem}`);
//     if (resp.status !== 200) {
//       console.log("Erro interno do servidor");
//       return [];
//     }
//     if (resp.data?.hasOwnProperty("erro")) {
//       console.log(resp.data.erro);
//       return [];
//     }
//     return resp.data;
//   } catch (error) {
//     console.log(error);
//     return [];
//   }
// }
// Esta funcao cria um novo chat e retorna o chat_id
async function criarChat(matricula, planta) {
  const formData = new FormData();
  formData.append("dados", JSON.stringify({ matricula, planta }));
  try {
    const resp = await axios.put(ROTAS[1], formData);
    if (resp.status !== 200) {
      console.log("Erro interno do servidor");
      return { erro: "Erro interno do servidor" };
    }
    if (resp.data?.hasOwnProperty("erro")) {
      console.log(resp.data.erro);
      return resp.data;
    }
    return resp.data.data;
  } catch (error) {
    console.log(error);
    return { erro: "Erro desconhecido" };
  }
}
// Funcao para registrar a mensagem no chat
async function registrarMensagemChat(chat_id, mensagem) {
  const formData = new FormData();
  formData.append("dados", JSON.stringify({ chat_id, mensagem }));
  try {
    const resp = await axios.post(ROTAS[1], formData);
    if (resp.status !== 200) {
      console.log("Erro interno do servidor");
      return { erro: "Erro interno do servidor" };
    }
    if (resp.data?.hasOwnProperty("erro")) {
      console.log(resp.data.erro);
      return resp.data;
    }
    return resp.data.data;
  } catch (error) {
    console.log(error);
    return { erro: "Erro desconhecido" };
  }
}
// // Funcao que recebe o chat_id e verifica se as mensagens foram lidas
// async function verificarMensagemLida(chat_id) {
//   try {
//     const resp = await axios.patch(`${ROTAS[1]}/${chat_id}`);
//     if (resp.status !== 200) {
//       console.log("Erro interno do servidor");
//       return [];
//     }
//     if (resp.data?.hasOwnProperty("erro")) {
//       console.log(resp.data.erro);
//       return [];
//     }
//     return resp.data;
//   } catch (error) {
//     console.log(error);
//     return [];
//   }
// }

function CaixaDeMensagem() {
  const [drawer, setDrawer] = useState(null);
  const [conversa, setConversa] = useState(null);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  return (
    <BackgroundRouter>
      <Container disableGutters maxWidth={false}>
        {drawer && (
          <DrawerDialog
            fecharModal={() => setDrawer(null)}
            fnGetCorpo={() => (
              <ColaboradorConversa
                fnFecharDrawer={() => setDrawer(null)}
                fnIniciarConversa={setConversa}
              />
            )}
          />
        )}
        {isMobile ? (
          <TelaMobile
            setDrawer={setDrawer}
            setConversa={setConversa}
            isMobile={isMobile}
            conversa={conversa}
          />
        ) : (
          <TelaDesktop
            setDrawer={setDrawer}
            setConversa={setConversa}
            isMobile={isMobile}
            conversa={conversa}
          />
        )}
      </Container>
    </BackgroundRouter>
  );
}
// Tela mobile
const TelaMobile = ({ setDrawer, setConversa, isMobile, conversa }) => {
  return (
    <>
      <Grid container>
        {conversa ? null : (
          <Grid item xs={12} md={4}>
            <ListaChats
              conversa={conversa}
              fnIniciarConversa={setConversa}
              fnProcurar={() => {
                setDrawer(true);
              }}
            />
          </Grid>
        )}
        {conversa ? (
          <Grid
            item
            xs={12}
            md={8}
            sx={{ height: "calc(100vh - 105px)", p: 0 }}
          >
            <ConversaEntreColaboradores
              key={conversa?.chat_id}
              isMobile={isMobile}
              fnFechar={() => setConversa(null)}
              {...conversa}
            />
          </Grid>
        ) : null}
      </Grid>
      {!conversa && (
        <FabExtend
          title="Iniciar conversa"
          onClick={() => {
            setDrawer(true);
          }}
          icone="Comment"
          text="Conversar"
        />
      )}
    </>
  );
};
// Tela para desktop
const TelaDesktop = ({ conversa, setConversa, setDrawer }) => {
  const isDark = useTheme()?.palette.mode === "dark";
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={0}
            sx={{
              height: "calc(100vh - 48px)",
              overflowY: "auto",
              borderRadius: 0,
              background: isDark
                ? (theme) => theme.palette.background.paper
                : lightBlue[50],
            }}
          >
            <Stack sx={{ mx: 2 }}>
              <FabExtend
                title="Iniciar conversa"
                onClick={() => {
                  setDrawer(true);
                  setConversa(false);
                }}
                icone="Comment"
                text="Novo chat"
              />
              <Divider />
              <ListaChats
                conversa={conversa}
                fnIniciarConversa={setConversa}
                fnProcurar={() => {
                  setDrawer(true);
                }}
              />
            </Stack>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          {conversa ? (
            <ConversaEntreColaboradores
              key={conversa?.chat_id}
              fnFechar={() => setConversa(null)}
              {...conversa}
            />
          ) : (
            <Paper elevation={0} sx={{ height: "100%" }}>
              <AnimacaoSemDados titulo="Nenhuma conversa selecionada. Clique no botão novo chat para iniciar" />
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  );
};

// Inicia busca para conversa com usuarios
const ColaboradorConversa = memo(({ fnIniciarConversa, fnFecharDrawer }) => {
  const { wait, setFetch, error, data } = useFetch(ROTAS[0], "GET");
  const fnProcuraColaborador = useCallback(
    (valor) => {
      if (valor) {
        setFetch({ termo: valor, tipo: "nome" });
      }
    },
    [setFetch]
  );
  //
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  return (
    <Container sx={{ ...sxStyleContainer, p: 1 }}>
      <Stack>
        <Filtro setFiltro={fnProcuraColaborador} />
        {wait ? (
          <Pesquisando />
        ) : data === null ? (
          <Body2 align="center">Digite algum nome para pesquisar.</Body2>
        ) : data.length > 0 ? (
          <>
            <H6>Escolha com quem conversar</H6>
            <List>
              {data.map((ele, idx) => (
                <ColaboradorListChat
                  key={idx}
                  onClick={() => {
                    fnFecharDrawer();
                    fnIniciarConversa(ele);
                  }}
                  {...ele}
                />
              ))}
            </List>
          </>
        ) : (
          <>
            <AnimacaoSemDados titulo="Nenhum dado encontrado na pesquisa" />
          </>
        )}
      </Stack>
    </Container>
  );
});
// Representa um item da lista como colaborador do chat
const ColaboradorListChat = memo(
  ({
    onClick,
    avatar,
    nome,
    matricula,
    setor,
    lida,
    minha_mensagem,
    data,
    ultima_mensagem,
  }) => {
    return (
      <ListItemButton
        sx={{
          zIndex: 2,
          //background: (theme) => theme.palette.background.paper,
        }}
        divider
        onClick={onClick}
      >
        <ListItemAvatar>
          <Avatar src={avatar} alt={nome} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          primary={`${matricula} - ${nome}`}
          secondary={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              {ultima_mensagem ? (
                <Stack
                  direction="row-reverse"
                  spacing={1}
                  sx={{ width: "100%" }}
                  alignItems="flex-end"
                >
                  {!lida && !minha_mensagem && (
                    <Badge color="success" max={9} badgeContent={1} />
                  )}

                  <Icone
                    icone="DoneAll"
                    sx={{ color: lida ? blue[500] : "gray" }}
                  />
                  <Caption>{format(parseISO(data), "dd/MM HH:mm")}</Caption>
                  <Stack
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    flex={1}
                  >
                    <Body2>{ultima_mensagem}</Body2>
                  </Stack>
                </Stack>
              ) : (
                <Body2>{setor}</Body2>
              )}
            </Stack>
          }
        />
      </ListItemButton>
    );
  }
);
// Conversa entre colaboradores
const ConversaEntreColaboradores = ({
  fnFechar,
  avatar,
  matricula,
  planta,
  nome,
  lida,
  setor,
  chat_id,
  isMobile,
}) => {
  const [chatId, setChatId] = useState(chat_id);
  const [list, { push, set }] = useList([]);
  // Para ter uma referencia ao chat e rolar ele quando registrar nova mensagem
  const ref = useRef(null);

  return (
    <Stack sx={{ height: "100%", maxHeight: "calc(100vh - 49px)" }}>
      <DetalhesContatoChat
        avatar={avatar}
        nome={nome}
        setor={setor}
        fnFechar={fnFechar}
      />
      {chatId ? (
        <Conversa
          refChat={ref}
          chat_id={chatId}
          set={set}
          list={list}
          push={push}
          lida={lida}
        />
      ) : (
        <Stack flex={isMobile ? 1 : 1}>
          <AnimacaoSemDados titulo="Ainda não há mensagens entre vocês" />
        </Stack>
      )}
      <WrapperComentario
        refChat={ref}
        chat_id={chatId}
        addMensagem={push}
        matricula={matricula}
        planta={planta}
        registraChatId={setChatId}
      />
    </Stack>
  );
};
// Componente que exibe os detalhes do contato no chat
const DetalhesContatoChat = memo(({ fnFechar, avatar, nome, setor }) => {
  return (
    <Paper elevation={2} sx={{ borderRadius: 0, p: 1, mb: 0.5, zIndex: 2 }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <ButtonBase onClick={fnFechar}>
          <Icone sx={{ fontSize: "16px" }} icone="ArrowBack" />
        </ButtonBase>
        <Avatar src={avatar} alt={nome} />
        <Stack
          alignItems="flex-start"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          <Body2 noWrap>{nome}</Body2>
          <Caption>Setor: {setor}</Caption>
        </Stack>
      </Stack>
    </Paper>
  );
});
// Componente que cuida da recuperacao das mensagens
const Conversa = ({ refChat, chat_id, set, list }) => {
  const { data } = useSWR(
    `${ROTAS[1]}${chat_id ? `/${chat_id}` : ""}`,
    fetchGetMensagem,
    { refreshInterval: 1e3 }
  );

  // Atualiza a lista principal das mensagens
  useEffect(() => {
    if (data) set(data);
    // if (list?.length < data?.length) {
    //   refChat.current.scroll({ top: 10000000000, behavior: "smooth" });
    // }
  }, [set, data]);

  // Rola o scroll para a ultima mensagem quando a pagina for aberta
  useEffect(() => {
    if (refChat) {
      setTimeout(() => {
        refChat.current.scroll({ top: 1000000, behavior: "smooth" });
      }, 200);
    }
  }, [refChat]);

  let ultimaData;

  return (
    <Paper ref={refChat} sx={{ overflowY: "auto", flex: 1 }} elevation={0}>
      {list?.map((ele) => {
        const dataFormatada = _.toUpper(
          format(parseISO(ele.data), "dd MMMM ", {
            locale: ptBR,
          })
        );
        if (dataFormatada !== ultimaData) {
          ultimaData = dataFormatada;
          return (
            <span key={ele.id_mensagem}>
              <Stack direction="row" justifyContent="center">
                <Chip label={ultimaData} />
              </Stack>
              <BalaoConversa {...ele} />
            </span>
          );
        }
        return <BalaoConversa {...ele} key={ele.id_mensagem} />;
      })}
    </Paper>
  );
};
//
const WrapperComentario = ({
  refChat,
  chat_id,
  matricula,
  planta,
  addMensagem,
  registraChatId,
}) => {
  const [chatId, setChatId] = useState(chat_id);
  const isDark = useTheme()?.palette.mode === "dark";
  //
  const fnRegistraComentario = useCallback(
    async (comentario) => {
      // Registra um chat entre os particpantes
      if (!chatId) {
        // Registra o chat
        (async () => {
          const idChat = await criarChat(matricula, planta);
          const data = await registrarMensagemChat(idChat.chat_id, comentario);
          // Nao teve erro para receber a mensagem
          if (!data.hasOwnProperty("erro")) {
            addMensagem(data);
          }
          setChatId(idChat.chat_id);
          registraChatId(idChat.chat_id);
        })();
      } else {
        // Salva a mensagem
        const data = await registrarMensagemChat(chatId, comentario);
        // Nao teve erro para receber a mensagem
        if (!data.hasOwnProperty("erro")) {
          addMensagem(data);
        }
      }
      // Faz com que o chat seja movido para baixo.
      setTimeout(() => {
        refChat?.current?.scroll({ top: 1000000, behavior: "smooth" });
      }, 200);
    },
    [chatId, matricula, planta, refChat, addMensagem, registraChatId]
  );

  return (
    <Paper
      sx={{
        borderRadius: 0,
        backgroundColor: !isDark
          ? grey[300]
          : (theme) => theme.palette.background.paper,

        mt: 1,
        p: 1,
        zIndex: 2,
      }}
      elevation={3}
    >
      <Stack direction="row" alignItems="center" sx={{ p: 0.2 }}>
        <Box sx={{ flex: 1 }}>
          <Comentario
            enviarComEnter
            onEnviarComentario={fnRegistraComentario}
          />
        </Box>
      </Stack>
    </Paper>
  );
};
// Balao de conversa com registros
const BalaoConversa = memo(({ mensagem, data, minha_mensagem, lida }) => {
  return (
    <Stack
      sx={{ px: 0.5, py: 0.2 }}
      direction={minha_mensagem ? "row-reverse" : "row"}
    >
      <Paper
        elevation={1}
        sx={{
          zIndex: 2,
          p: 0.5,
          maxWidth: "80%",
          background: minha_mensagem ? lightGreen[300] : lightBlue[200],
          color: "black",
        }}
      >
        <Stack>
          <Body2>{mensagem}</Body2>
          <Stack spacing={1} direction="row-reverse" alignItems="flex-end">
            {minha_mensagem && (
              <Icone
                icone="DoneAll"
                sx={{ color: lida ? blue[700] : "gray" }}
              />
            )}
            <Caption>{format(parseISO(data), "HH:mm")}</Caption>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
});

// Componente que lista os chats trocados entre os usuarios
const ListaChats = memo(({ conversa, fnProcurar, fnIniciarConversa }) => {
  const { data } = useSWR(ROTAS[1], fetchGetMensagem, { refreshInterval: 1e3 });

  return (
    <Stack>
      {!conversa && data?.length < 1 ? (
        <Stack>
          <AnimacaoSemDados titulo="Nenhuma conversa iniciada" />

          <Button
            onClick={fnProcurar}
            variant="outlined"
            startIcon={<Icone icone="Add" />}
          >
            Iniciar uma conversa
          </Button>
        </Stack>
      ) : (
        <List disablePadding>
          {data?.map((ele, idx) => (
            <ColaboradorListChat
              key={idx}
              {...ele}
              onClick={() => {
                fnIniciarConversa(ele);
              }}
            />
          ))}
        </List>
      )}
    </Stack>
  );
});

export default CaixaDeMensagem;
