import React, { useEffect } from "react";
import { Container, useMediaQuery, useTheme, Stack } from "@mui/material";
import { Body2, Icone, SideBar, Tab } from "../../../components";
import { useFetch } from "../../../hooks";
import { ToastErro } from "../../../utils/utils";
import AtualizacaoSenha from "./config-upd-passwd";
import FormDadosCadastrais from "./config-form-data-cad";
import VerDadosPessoais from "./config-view-personal";
import Dependentes from "./config-depend";
import { useTranslation } from "react-i18next";

//
const ICONES = ["Person", "Groups", "Build"];
//

const Configuracoes = () => {
  const { t } = useTranslation();
  const isXl = useMediaQuery(useTheme()?.breakpoints?.up("xl"));
  const { wait, setFetch, data, error } = useFetch("/dados_pessoais", "GET");
  // Recuperar os dados do usuario
  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);
  // Recupera os dados
  useEffect(() => {
    setFetch();
  }, [setFetch]);

  const cabe = [
    t("dados_cadastrais.tab1"),
    t("dados_cadastrais.tab2"),
    t("dados_cadastrais.tab3"),
  ].map((ele, idx) => (
    <Stack key={idx} direction="row" alignItems="center" spacing={1}>
      <Icone icone={ICONES[idx]} />
      <Body2>{ele}</Body2>
    </Stack>
  ));
  const corpo = [
    data ? (
      data?.pessoais?.situacao !== "B" ? (
        <VerDadosPessoais data={data} />
      ) : (
        <FormDadosCadastrais data={data} wait={wait} />
      )
    ) : null,
    data && <Dependentes {...data} />,
    <AtualizacaoSenha />,
  ];
  //
  return (
    <Stack direction="row">
      <SideBar barraIntegrada />

      <Container maxWidth={isXl ? "xl" : "lg"}>
        <Tab cabe={cabe} corpo={corpo} />
      </Container>
    </Stack>
  );
};

Configuracoes.rota = "/configuracoes";

export default Configuracoes;
