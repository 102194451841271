import { memo } from "react";
import { useSelector } from "react-redux";
import {
  selectMatPlantUser,
  selectUploadDocumentos,
} from "./documentos-seletores";
import { ACTIONS } from "./documentos-utils";
import DocumentosModal from "./documentos-modal";
import { MenuItem, Stack } from "@mui/material";
import { Body2, Icone } from "../../../components";
import { useTranslation } from "react-i18next";

// Menu com mais opcoes para o mobile
const ItemArquivoMenu = memo(
  ({
    colaboradores,
    id_arquivo,
    ext,
    nome,
    tipo,
    data,
    dispatch,
    matricula,
    planta,
    avatar,
    visivel_por,
    permissao_visualizacao,
    fecharMenu,
    nome_download,
    nome_colaborador,
    onRecortar,
  }) => {
    const { t } = useTranslation();
    const isSendFiles = useSelector(selectUploadDocumentos);
    const matPlantUserLogged = useSelector(selectMatPlantUser);

    const matPlantFile = `${matricula}_${planta}`;
    //
    const itens = [
      {
        icone: "Info",
        nome: t("documentos.labelItemMenuDetails"),
        onClick: () => {
          dispatch({
            type: ACTIONS.VER_MODAL,
            data: {
              tipo: DocumentosModal.modal.DETALHES,
              dados: {
                ext,
                nome,
                data,
                tipo,
                id_arquivo,
                matricula,
                planta,
                avatar,
                nome_download,
                nome_colaborador,
                colaboradores,
                permissao_visualizacao,
              },
            },
          });
          fecharMenu();
        },
      },
    ];
    if (isSendFiles && matPlantUserLogged === matPlantFile) {
      itens.push({
        icone: "Edit",
        nome: t("documentos.labelItemMenuRename"),
        onClick: () => {
          dispatch({
            type: ACTIONS.VER_MODAL,
            data: {
              tipo: DocumentosModal.modal.RENOMEAR,
              dados: {
                visivel_por,
                nome,
                tipo,
                id_arquivo,
                data,
                matricula,
                planta,
                ext,
                permissao_visualizacao,
              },
            },
          });
          fecharMenu();
        },
      });
      itens.push({
        icone: "ContentCut",
        nome: t("documentos.labelItemMenuCut"),
        onClick: () => {
          onRecortar(id_arquivo);
          fecharMenu();
        },
      });
      itens.push({
        icone: "Visibility",
        nome: t("documentos.labelItemMenuAlterVisibility"),
        onClick: () => {
          dispatch({
            type: ACTIONS.VER_MODAL,
            data: {
              tipo: DocumentosModal.modal.VISIBILIDADE,
              dados: {
                visivel_por,
                nome,
                tipo,
                id_arquivo,
                data,
                ext,
                matricula,
                planta,
                permissao_visualizacao,
                colaboradores,
              },
            },
          });
          fecharMenu();
        },
      });

      itens.push({
        icone: "Delete",
        nome: t("documentos.labelItemMenuDelete"),
        onClick: () => {
          dispatch({
            type: ACTIONS.VER_MODAL,
            data: {
              tipo: DocumentosModal.modal.EXCLUIR,
              dados: { nome, tipo, id_arquivo },
            },
          });
          fecharMenu();
        },
      });
    }

    return (
      <>
        {itens.map((ele, idx) => (
          <MenuItem
            divider={idx < itens.length - 1}
            key={idx}
            onClick={ele.onClick}
          >
            <Stack direction="row" spacing={1}>
              <Icone icone={ele.icone} />
              <Body2>{ele.nome}</Body2>
            </Stack>
          </MenuItem>
        ))}
      </>
    );
  }
);

export default ItemArquivoMenu;
