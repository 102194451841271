import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Stack,
  IconButton,
  Collapse,
  Button,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {
  galeriaDeFotosDelComentario,
  galeriaDeFotosGetComentarios,
  galeriaDeFotosLimparListaComentarios,
} from "./galeria-de-fotos-actions";
import { HeaderDefault, RetornoEmBranco } from "../../../components";
import PainelComentario from "./galeria-de-fotos-painel-comentario";

// Lista de comentarios
const ListaDeComentarios = ({ id_foto }) => {
  const dispatch = useDispatch();
  const comentarios = useSelector(
    (state) => state?.galeriaDeFotos?.comentarios
  );
  const usuario = useSelector((state) => state?.usuario);
  const matricula = usuario?.usuario?.matricula;
  const planta = usuario?.usuario?.planta;
  const { t } = useTranslation();

  useEffect(() => {
    // Obtem a lista de comentarios
    dispatch(galeriaDeFotosGetComentarios(id_foto));

    return () => dispatch(galeriaDeFotosLimparListaComentarios());
  }, [id_foto, dispatch]);

  return (
    <Container
      disableGutters
      sx={{ py: 1, maxHeight: "87vh", width: "100vw" }}
      maxWidth="sm"
    >
      <HeaderDefault>
        <Typography variant="subtitle1" fontWeight="bold" align="center">
          {t("galeria_de_fotos.listOfComments")}
        </Typography>
      </HeaderDefault>
      <List
        disablePadding
        sx={{
          mb: 1,
          overflowY: "auto",
          maxHeight: comentarios?.length > 0 ? "50vh" : "auto",
        }}
      >
        {comentarios?.length < 1 ? (
          <RetornoEmBranco />
        ) : (
          comentarios?.map((ele) => (
            <ListaDeComentariosItem
              key={ele.id_comentario}
              item={ele}
              meuComentario={ele.mat === matricula && planta === ele.planta}
            />
          ))
        )}
      </List>
      <PainelComentario id_foto={id_foto} />
    </Container>
  );
};

//
const ListaDeComentariosItem = memo(({ meuComentario, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //
  const { id_comentario, id_foto, nome, comentario, data, avatar } = item;
  const [editar, setEditar] = useState(false);
  const [excluir, setExcluir] = useState(false);

  // Funcao de callback para editar um comentario
  const fnEditar = () => {
    setExcluir(false);
    setEditar(!editar);
  };
  // Funcao que controla a exibicao das opcoes de exclusao
  const fnExcluir = () => {
    setEditar(false);
    setExcluir(!excluir);
  };
  // Funcao para enviar o comentario para exclusao
  const fnEnviarParaExclusao = () => {
    dispatch(galeriaDeFotosDelComentario({ id_comentario, id_foto }));
  };

  return (
    <>
      <ListItem divider disablePadding>
        <ListItemAvatar>
          <Avatar src={avatar}>{nome.substring(0, 1)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={nome}
          secondary={
            <Stack>
              <Typography variant="body2">{comentario}</Typography>
              {meuComentario ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton onClick={fnEditar}>
                    <EditIcon />
                    <Typography variant="caption"></Typography>
                  </IconButton>
                  <IconButton onClick={fnExcluir}>
                    <DeleteIcon color="error" />
                    <Typography variant="caption"></Typography>
                  </IconButton>
                  <Typography sx={{ flex: 1 }} align="right" variant="caption">
                    {format(parseISO(data), "dd/MM/yy HH:mm")}
                  </Typography>
                </Stack>
              ) : (
                <Typography align="right" variant="caption">
                  {format(parseISO(data), "dd/MM/yy HH:mm")}
                </Typography>
              )}
            </Stack>
          }
        />
      </ListItem>
      <Collapse in={excluir || editar} unmountOnExit>
        {editar ? (
          <PainelComentario
            id_comentario={id_comentario}
            defaultComentario={comentario}
            fnFecharEditar={() => setEditar(false)}
          />
        ) : excluir ? (
          <Stack sx={{ px: 2 }} spacing={1}>
            <Typography variant="subtitle2" align="center">
              {t("galeria_de_fotos.confirmationDelComment")}
            </Typography>
            <Stack spacing={1} direction="row-reverse">
              <Button
                size="small"
                variant="contained"
                onClick={fnEnviarParaExclusao}
              >
                {t("galeria_de_fotos.labelBtnYes")}
              </Button>
              <Button size="small" onClick={() => setExcluir(false)}>
                {t("galeria_de_fotos.labelBtnNo")}
              </Button>
            </Stack>
          </Stack>
        ) : null}
      </Collapse>
    </>
  );
});

export default ListaDeComentarios;
