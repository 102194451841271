import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { formatarParaSelect } from "../../../utils/utils";
import { createSelector } from "reselect";
import { galeriaDeFotosAddUpdAlbum } from "./galeria-de-fotos-actions";
import {
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Caption } from "../../../components";

import SaveIcon from "@mui/icons-material/Save";

const animetedComponents = makeAnimated();
// Estilização do componente select
const selectStyles = {
  menu: (styles) => ({
    ...styles,
    zIndex: 10,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "black",
  }),
  option: (styles) => ({
    ...styles,
    color: "black",
    fontSize: 14,
  }),
};

// Selector para obter o tamanho dos campos usados neste relatorio
const selectorTamanhoCampos = (state) => state?.tamanhoCampos;
const fnSeletorTamanho = createSelector(selectorTamanhoCampos, (obj) => {
  const USADOS = {};
  Object.keys(obj).forEach((key) => {
    if (key === "album") {
      USADOS["albumTitulo"] = obj[key].titulo; // Tamanho maximo do campo
      USADOS["albumAssunto"] = obj[key].assunto; // Tamanho maximo do campo
    }
  });
  return USADOS;
});

// Componente para adicionar/editar um album
const FormAddUpdAlbum = ({ id_album, titulo, assunto, planta_visivel }) => {
  //
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aguardar = useSelector((state) => state?.aguardar);
  const tamanhoCampos = useSelector(fnSeletorTamanho);

  // Schema para validar os dados enviados no formulario
  const schema = yup.object().shape({
    titulo: yup.string().min(3).max(tamanhoCampos?.albumTitulo).required(),
    assunto: yup.string().min(3).max(tamanhoCampos?.albumAssunto).required(),
    planta: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().min(1).required(),
          value: yup.string().min(1).required(),
        })
      )
      .min(1)
      .required(),
  });
  // Plantas que podem ser validadas para insercao do feed
  const planta = ["TAPE", "TAMG"];

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: id_album
      ? {
          titulo: titulo,
          assunto: assunto,
          planta: formatarParaSelect(planta_visivel),
        }
      : {},
  });
  // Funcao para validar o envio na criacao de um novo album
  const fn = (val) => {
    const { assunto, planta, titulo } = val;
    const obj = {
      assunto,
      titulo,
      visivel_para: planta.map((ele) => ele.value),
    };
    // Se ID_album ele precisa ser enviado, senao o tipo
    if (id_album) {
      obj["id_album"] = id_album;
    } else {
      obj["tipo"] = "1";
    }

    dispatch(galeriaDeFotosAddUpdAlbum(obj, id_album));
  };
  //
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  return (
    <Container sx={{ p: 1, maxHeight: "85vh" }} maxWidth="md">
      <Stack spacing={1}>
        <Typography variant="h6" align="center">
          {t("galeria_de_fotos.titleFormAddAlbum")}
        </Typography>
        <Typography variant="caption" sx={{ opacity: 0.7 }} align="center">
          {t("galeria_de_fotos.descriptionFormAddAlbum")}
        </Typography>

        <Controller
          name="titulo"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              type="text"
              {...field}
              size="small"
              label={t("galeria_de_fotos.labelFormTitleAlbum")}
              placeholder={t("galeria_de_fotos.placeholderTitleAlbum")}
              fullWidth
              inputProps={{ maxLength: tamanhoCampos?.albumTitulo }}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.titulo}
              helperText={
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Caption>
                    {!!errors.titulo && t("galeria_de_fotos.errorTitleAlbum")}
                  </Caption>
                  <Caption>
                    {" "}
                    {watch("titulo")?.length} / {tamanhoCampos?.albumTitulo}
                  </Caption>
                </Stack>
              }
            />
          )}
        />
        <Controller
          name="assunto"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              type="text"
              {...field}
              size="small"
              label={t("galeria_de_fotos.labelFormSubjectAlbum")}
              placeholder={t("galeria_de_fotos.placeholderSubjectAlbum")}
              fullWidth
              inputProps={{ maxLength: tamanhoCampos?.albumAssunto }}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.assunto}
              helperText={
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Caption>
                    {!!errors.assunto &&
                      t("galeria_de_fotos.errorSubjectAlbum")}
                  </Caption>
                  <Caption>
                    {" "}
                    {watch("assunto")?.length} / {tamanhoCampos?.albumAssunto}
                  </Caption>
                </Stack>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="planta"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Stack sx={{ mb: 1 }}>
              <Typography variant="subtitle2" align="center">
                {t("galeria_de_fotos.labelPlantAlbum")}
              </Typography>
              <Select
                {...field}
                options={formatarParaSelect(planta)}
                isMulti
                components={animetedComponents}
                styles={selectStyles}
              />
              {!!errors.planta && (
                <Typography align="center" color="error" variant="caption">
                  {t("galeria_de_fotos.errorPlantAlbum")}
                </Typography>
              )}
            </Stack>
          )}
        />
        <Stack direction="row" justifyContent={{ md: "center", xs: "stretch" }}>
          <Button
            disabled={aguardar}
            size="small"
            variant="contained"
            onClick={handleSubmit(fn)}
            fullWidth={isMobile}
            startIcon={aguardar ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            {t("galeria_de_fotos.labelBtnSendAlbum")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default FormAddUpdAlbum;
