import { useSelector } from "react-redux";
import { ACTIONS, fnRecuperarDaLixeira } from "./documentos-utils";
import DocumentosModal from "./documentos-modal";
import { MenuItem, Stack } from "@mui/material";
import { Body2, Icone } from "../../../components";
import { memo } from "react";
import { selectUploadDocumentos } from "./documentos-seletores";
import { useTranslation } from "react-i18next";
// Menu com opcoes mobile para a lixeira
const ItemArquivoMenuLixeira = memo(
  ({
    id_arquivo,
    ext,
    nome,
    tipo,
    data,
    dispatch,
    matricula,
    planta,
    avatar,
    fecharMenu,
    nome_download,
    nome_colaborador,
    navegarPara,
    permissao_visualizacao,
    colaboradores,
  }) => {
    const { t } = useTranslation();
    const isSendFiles = useSelector(selectUploadDocumentos);
    //
    const itens = [
      {
        icone: "Info",
        nome: t("documentos.labelItemTrashDetails"),
        onClick: () => {
          dispatch({
            type: ACTIONS.VER_MODAL,
            data: {
              tipo: DocumentosModal.modal.DETALHES,
              dados: {
                ext,
                nome,
                data,
                tipo,
                id_arquivo,
                matricula,
                planta,
                avatar,
                nome_download,
                nome_colaborador,
                colaboradores,
                permissao_visualizacao,
              },
            },
          });
          fecharMenu();
        },
      },
    ];
    if (isSendFiles) {
      itens.push({
        icone: "Restore",
        nome: t("documentos.labelItemTrashRecover"),
        onClick: () => {
          // const formData = new FormData();
          // formData.append("dados", JSON.stringify({ id_arquivo }));
          const formData = { id_arquivo };

          fnRecuperarDaLixeira(formData, navegarPara, fecharMenu);
        },
      });
      itens.push({
        icone: "DeleteForever",
        nome: t("documentos.labelItemTrashDeleteForever"),
        onClick: () => {
          dispatch({
            type: ACTIONS.VER_MODAL,
            data: {
              tipo: DocumentosModal.modal.EXCLUIR_PERMANENTEMENTE,
              dados: { nome, tipo, id_arquivo },
            },
          });
          fecharMenu();
        },
      });
    }

    return (
      <>
        {itens.map((ele, idx) => (
          <MenuItem
            divider={idx < itens.length - 1}
            key={idx}
            onClick={ele.onClick}
          >
            <Stack direction="row" spacing={1}>
              <Icone icone={ele.icone} />
              <Body2>{ele.nome}</Body2>
            </Stack>
          </MenuItem>
        ))}
      </>
    );
  }
);

export default ItemArquivoMenuLixeira;
