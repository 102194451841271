import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { Confirmar } from "../../../components";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { helpdeskDelAssunto, helpdeskFecharModal } from "./helpdesk-actions";
import { useTranslation } from "react-i18next";

function HelpdeskAssuntoDel({ id }) {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  //
  const fnCancel = useCallback(() => {
    dispatch(helpdeskFecharModal());
  }, [dispatch]);
  //
  const fnConfirm = useCallback(() => {
    dispatch(helpdeskDelAssunto({ id_assunto: id }, setWait));
  }, [dispatch, id, setWait]);
  return (
    <Stack>
      <Confirmar
        aguardar={wait}
        fnCancelar={fnCancel}
        fnConfirmar={fnConfirm}
        titulo={t("helpdesk.titleSubjectDel")}
        subtitulo={t("helpdesk.subtitleSubjectDel")}
      />
    </Stack>
  );
}

export default HelpdeskAssuntoDel;
