import React from "react";
import OuvidoriaAdd from "./ouvidoria-add";
import OuvidoriaBuscaPorToken from "./ouvidoria-busca-por-token";
import OuvidoriaTokenCopia from "./ouvidoria-token-copia";
import OuvidoriaFiltroAvancado from "./ouvidoria-filtro-avancado";

function OuvidoriaModal({ modal }) {
  let corpo;
  switch (modal.tipo) {
    case OuvidoriaModal.MODAL.ADD_OUVIDORIA: // Adicionar nova ouvidoria
      corpo = <OuvidoriaAdd isModal={true} />;
      break;
    case OuvidoriaModal.MODAL.BUSCA_POR_TOKEN:
      corpo = <OuvidoriaBuscaPorToken />;
      break;
    case OuvidoriaModal.MODAL.COPIAR_TOKEN:
      corpo = <OuvidoriaTokenCopia token={modal.dados} />;
      break;
    case OuvidoriaModal.MODAL.FILTRO_AVANCADO:
      corpo = <OuvidoriaFiltroAvancado />;
      break;
    default:
      break;
  }
  return corpo;
}

OuvidoriaModal.MODAL = {
  ADD_OUVIDORIA: "ADD_OUVIDORIA",
  BUSCA_POR_TOKEN: "BUSCA_POR_TOKEN",
  COPIAR_TOKEN: "COPIAR_TOKEN",
  FILTRO_AVANCADO: "FILTRO_AVANCADO",
};

export default OuvidoriaModal;
