import { combineReducers } from "redux";
import menuReducer from "./menu-reducer";
import trSelecionadoReducer from "./tr-selecionado-reducer"; // Lida com o trSelecionado
import alertaReducer from "./alerta-reducer"; // Lida com todos os alertas
import erroReducer from "./erro-reducer"; // Lida com mensagens de erro
import aguardarReducer from "./aguardar-reducer"; // Lida com o aguardar
import optTabelaReducer from "./opt-tabela-reducer"; // Lida com valores a serem configurados nas tabelas
import areaDoGestorReducer from "./area-do-gestor-reducer";
import avaliacaoReducer from "./avaliacao-reducer";
import modalReducer from "./modal-reducer";
import usuarioReducer from "./usuario-reducer";
import colaboradoresReducer from "../../routers/admin/colaboradores/colaboradores-reducer";
import controleMenuReducer from "./controle-menu-reducer";
import ouvidoriaReducer from "../../routers/rh/ouvidoria/ouvidoria-reducer"; // Para pagina da ouvidoria
import cadastroProjetosReducer from "../../routers/projetos/kaizen/kaizen-reducer"; // Para a pagina de cadastro dos projetos
import estatisticasReducer from "../../routers/admin/estatisticas/estatisticas-reducer"; // Para a pagina relacionada as estatisticas
import manutencaoVariaveisReducer from "../../routers/admin/manutencao-variaveis/manutencao-variaveis-reducer";
import cartaoSegurancaReducer from "./cartao-seguranca-reducer";
import feedRHReducer from "../../routers/rh/feed_rh/feed-reducer";
import galeriaDeFotosReducer from "../../routers/rh/galeria-de-fotos/galeria-de-fotos-reducer";
import tamanhoCamposReducer from "./tamanho-campos-reducer";
import twttpReducer from "../../routers/projetos/twttp/twttp-reducer";
import manutencaoAreaReducer from "./manutencao-area-reducer";
import manutencaoModeloReducer from "./manutencao-modelo-reducer";
//
import oportunidadesReducer from "../../routers/rh/oportunidades/oportunidades_reducer";
import manutencaoComponentesReducer from "../../routers/estruturas/manutencao-componentes/manutencao-componentes-reducer";
import helpdeskReducer from "../../routers/ti/helpdesk/helpdesk-reducer";
import rnciNcpReducer from "../../routers/qualidade/rnci-ncp/rnci-ncp-reducer";
import ncpReducer from "../../routers/qualidade/ncp/ncp-reducer";
import lessonReducer from "../../routers/lesson/lesson/lesson_reducer";
import budgetReducer from "../../routers/budget/budget/budget-reducer";
import treinamentoReducer from "../../routers/rh/treinamento/treinamento-reducer";

export default combineReducers({
  treinamento: treinamentoReducer,
  budget: budgetReducer,
  lesson: lessonReducer,
  rnciNcp: rnciNcpReducer,
  ncp: ncpReducer,
  idxMenu: menuReducer,
  helpdesk: helpdeskReducer,
  manutencaoComponentes: manutencaoComponentesReducer,
  oportunidades: oportunidadesReducer,
  manutencaoModelo: manutencaoModeloReducer,
  manutencaoArea: manutencaoAreaReducer,
  twttp: twttpReducer,
  tamanhoCampos: tamanhoCamposReducer,
  galeriaDeFotos: galeriaDeFotosReducer,
  cartaoSeguranca: cartaoSegurancaReducer,
  manutencaoVariaveis: manutencaoVariaveisReducer,
  cadastroProjetos: cadastroProjetosReducer,
  ouvidoria: ouvidoriaReducer,
  controleMenu: controleMenuReducer,
  colaboradores: colaboradoresReducer,
  usuario: usuarioReducer,
  avaliacao: avaliacaoReducer,
  areaDoGestor: areaDoGestorReducer,
  erro: erroReducer,
  trSelecionado: trSelecionadoReducer,
  alerta: alertaReducer,
  aguardar: aguardarReducer,
  modal: modalReducer,
  optTabela: optTabelaReducer,
  feedRH: feedRHReducer,
  estatisticas: estatisticasReducer,
});
