import React, { useEffect } from "react";
import { useFetch } from "../hooks";
import { toast } from "react-toastify";
import { ToastErro } from "../utils/utils";
import { CircularProgress, Fab } from "@mui/material";
import { Icone } from "../components";

// Componente para atualizar o cache
const AtualizadorCache = ({ titulo }) => {
  const { wait, error, data, setFetch } = useFetch(
    window.location.pathname,
    "PUT"
  );
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  useEffect(() => {
    if (data)
      toast.dark(data.sucesso, {
        type: "success",
      });
  }, [data]);

  return (
    <Fab
      title={titulo}
      color="primary"
      size="medium"
      sx={{ position: "fixed", bottom: 72, right: 16 }}
      disabled={wait}
      onClick={() => {
        setFetch({});
      }}
    >
      {wait ? (
        <CircularProgress sx={{ color: "white" }} />
      ) : (
        <Icone icone="Update" />
      )}
    </Fab>
  );
};

export default AtualizadorCache;
