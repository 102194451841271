import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { Body1, Body2, H6, Icone } from "../../../components";
import { ContainerAdaptavel } from "../../../extra-components";
import { getInfoColabs, validaExtensao } from "./documentos-utils";
import { format, formatDistance, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";
//
const VerDetalhesArquivo = ({
  tipo,
  ext,
  nome,
  data,
  avatar,
  matricula,
  planta,
  nome_colaborador,
  nome_download,
  permissao_visualizacao,
  colaboradores,
}) => {
  const { t } = useTranslation();
  const listColabs = getInfoColabs(colaboradores);
  const permView = [];
  permissao_visualizacao.forEach((ele) => {
    const colab = listColabs.filter(
      (func) =>
        func.mat_planta === ele && func.mat_planta !== `${matricula}_${planta}`
    );
    if (colab.length > 0) {
      permView.push(colab[0]);
    }
  });

  //
  return (
    <ContainerAdaptavel sx={{ minHeight: "50vh" }}>
      <H6>{t("documentos.titleViewFile")}</H6>
      <List disablePadding>
        <ListItem divider>
          <ListItemAvatar>
            <img
              width={32}
              heigth={32}
              src={validaExtensao(tipo === "F" ? ext : "")}
              alt={`${t("documentos.file")} ${nome}`}
            />
          </ListItemAvatar>
          <ListItemText
            primary={nome}
            secondary={`Nome ${
              tipo === "F"
                ? t("documentos.fromFile")
                : t("documentos.fromFolder")
            }`}
          />
        </ListItem>
        <ListItem divider>
          <ListItemIcon>
            <Icone icone="CalendarMonth" />
          </ListItemIcon>
          <ListItemText
            primary={format(parseISO(data), "dd/MM/yy HH:mm")}
            secondary={`${t("documentos.created")} ${formatDistance(
              parseISO(data),
              new Date(),
              {
                locale: ptBR,
                addSuffix: true,
              }
            )}`}
          />
        </ListItem>
        <ListItem divider>
          <ListItemAvatar>
            <Avatar sx={{ width: 32, height: 32 }} src={avatar} alt={nome} />
          </ListItemAvatar>
          <ListItemText
            primary={t("documentos.createdBy")}
            secondary={`${matricula} - ${nome_colaborador}`}
          />
        </ListItem>
        {nome_download?.length > 0 && (
          <ListItemButton onClick={() => window.open(nome_download)} divider>
            <ListItemIcon>
              <Icone icone="Download" />
            </ListItemIcon>
            <ListItemText
              primary={t("documentos.linkToDownload")}
              secondary={t("documentos.secondaryLinkToDownload")}
            />
          </ListItemButton>
        )}
        <Stack gap={1}>
          {permView?.length > 0 && (
            <H6>{t("documentos.titleAccessRestricted")}</H6>
          )}
          {permView?.length > 0 ? (
            <Stack
              direction="row"
              gap={1}
              sx={{ width: "100%" }}
              flexWrap="wrap"
            >
              {permView.map((colab, key) => (
                <Paper key={key} elevation={1} sx={{ p: 1 }}>
                  <Stack gap={1} alignItems="center">
                    <Avatar
                      title={`${t("documentos.titleAccessPermitted")} ${
                        colab.nome
                      }`}
                      alt={colab.nome}
                      src={colab.avatar}
                    />
                    <Body2>{colab.nome}</Body2>
                  </Stack>
                </Paper>
              ))}
            </Stack>
          ) : (
            <Body1 align="center">{t("documentos.labelAllPlantsView")}</Body1>
          )}
        </Stack>
      </List>
    </ContainerAdaptavel>
  );
};

export default VerDetalhesArquivo;
