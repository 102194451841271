import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { cadastroProjetosConfirmarChecklist } from "./kaizen-actions";
import {
  Container,
  Typography,
  Stack,
  Button,
  CircularProgress,
} from "@mui/material";
import { Icone } from "../../../components";
import { useTranslation } from "react-i18next";

const selectWait = (state) => state;

// Este componente vai permitir ao usuario considerar se a nota foi satisfatoria ou nao notaChecklist
const AprovarReprovarChecklist = ({ dados }) => {
  const { t } = useTranslation();
  const { aguardar } = useSelector(selectWait);
  const { nota, id_checklist_projeto } = dados;
  const dispatch = useDispatch();
  // Funcao de callback que recebe o status do ckecklist
  const fn = (situacao) => {
    dispatch(
      cadastroProjetosConfirmarChecklist({
        id_checklist_projeto,
        situacao,
      })
    );
  };

  return (
    <Container sx={{ p: 1 }} maxWidth="md">
      <Typography variant="body2" align="center">
        {t("cadastro_projetos.pointTitle")} {nota} / 100
      </Typography>
      <Typography
        component="p"
        variant="caption"
        sx={{ opacity: 0.7 }}
        align="center"
      >
        {t("cadastro_projetos.considerAcceptCd")}
      </Typography>
      <Stack
        sx={{ my: 1 }}
        spacing={1}
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
      >
        <Button
          disabled={aguardar}
          variant="outlined"
          onClick={() => fn("C")}
          startIcon={
            aguardar ? (
              <CircularProgress size={20} />
            ) : (
              <Icone icone="WatchLater" />
            )
          }
        >
          {t("cadastro_projetos.btnResponseAfter")}
        </Button>
        <Button
          disabled={aguardar}
          color="error"
          variant="outlined"
          onClick={() => fn("R")}
          startIcon={
            aguardar ? (
              <CircularProgress size={20} />
            ) : (
              <Icone icone="ThumbDown" />
            )
          }
        >
          {t("cadastro_projetos.btnReproved")}
        </Button>
        <Button
          disabled={aguardar}
          variant="contained"
          onClick={() => fn("A")}
          startIcon={
            aguardar ? (
              <CircularProgress size={20} />
            ) : (
              <Icone icone="ThumbUp" />
            )
          }
        >
          {t("cadastro_projetos.btnApproved")}
        </Button>
      </Stack>
    </Container>
  );
};

export default AprovarReprovarChecklist;
