import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PaperCustom } from "./configuracoes-utils";
import * as yup from "yup";
import axios from "axios";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  senha: yup.string().min(3).required(),
  confirmar_senha: yup.string().min(3).required(),
});

// Componente para exibir a atualizacao de senha
const AtualizacaoSenha = () => {
  const { t } = useTranslation();
  const [erro, setErro] = useState(null);
  const [aguardar, setAguardar] = useState(null);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  //
  // Funcao para atualizar a senha
  const fn = async (val) => {
    setErro(null);
    const { senha, confirmar_senha } = val;
    if (senha !== confirmar_senha) {
      setErro(t("dados_cadastrais.msgError"));
      return false;
    }
    setAguardar(true);
    // Passou só enviar
    const formData = { senha, confirmar_senha };
    try {
      const resp = await axios.post("/configuracoes", formData);
      setErro(resp.data.sucesso);

      setTimeout(() => {
        setErro(null);
      }, 2000);

      reset({ senha: "", confirmar_senha: "" });
    } catch (error) {
      if (error?.response?.data?.message) {
        setErro(error?.response?.data?.message);
      } else {
        setErro(error.toString());
      }
    } finally {
      setAguardar(false);
    }
  };

  return (
    <>
      <Typography align="center" variant="h5">
        {t("dados_cadastrais.titleUpdPasswd")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <PaperCustom elevation={3}>
            <Typography align="center" variant="subtitle1">
              {t("dados_cadastrais.titleForm")}
            </Typography>
            <Controller
              control={control}
              name="senha"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  type="password"
                  margin="dense"
                  id="senha1"
                  fullWidth
                  size="small"
                  {...field}
                  label={t("dados_cadastrais.titleField1")}
                  variant="outlined"
                  error={!!errors.senha}
                  helperText={
                    !!errors.senha && t("dados_cadastrais.msgFieldError")
                  }
                  disabled={aguardar}
                />
              )}
            />

            <Controller
              control={control}
              name="confirmar_senha"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  margin="normal"
                  type="password"
                  fullWidth
                  size="small"
                  {...field}
                  label={t("dados_cadastrais.titleField2")}
                  variant="outlined"
                  error={!!errors.confirmar_senha}
                  helperText={
                    !!errors.confirmar_senha &&
                    t("dados_cadastrais.msgFieldError")
                  }
                  disabled={aguardar}
                />
              )}
            />
            <Typography align="center" variant="body2" color="error">
              {erro}
            </Typography>
            <Button
              disabled={aguardar}
              fullWidth
              onClick={handleSubmit(fn)}
              type="onSubmit"
              variant="contained"
              color="primary"
            >
              {t("dados_cadastrais.btnUpdate")}
            </Button>
          </PaperCustom>
        </Grid>
      </Grid>
    </>
  );
};

export default AtualizacaoSenha;
