import React, { useCallback, useEffect } from "react";
import BackgroundRouter from "../../background-router";
import {
  Button,
  Chip,
  Container,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { Body1, Body2, H6, Icone } from "../../../components";
import Utils, { ToastErro } from "../../../utils/utils";
import { blue } from "@mui/material/colors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FeriasAssinar from "./ferias-assinar";
import useSWR from "swr";
import _ from "lodash";
import fetchGet from "../../../api/fetch_mensagem";
import { useToggle } from "react-use";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ROUTES = ["/recibo_de_ferias_comprovante"];

//
function download(fileUrl, fileName) {
  var a = document.createElement("a");
  a.href = fileUrl;
  const name = _.last(fileName.split("/"));
  a.setAttribute("download", name);
  a.click();
}

function Ferias() {
  const { t } = useTranslation();
  const { data, error } = useSWR("/recibo_de_ferias", fetchGet);
  //
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);
  return (
    <BackgroundRouter>
      <Container maxWidth="sm">
        <H6>{t("ferias.title")}</H6>
        <ListItems rows={data || []} />
      </Container>
    </BackgroundRouter>
  );
}
//
const ListItems = ({ rows }) => {
  const history = useHistory();
  //
  const goToSignature = useCallback(
    (row) => {
      history.push(FeriasAssinar.rota, { item: row });
    },
    [history]
  );
  //
  return (
    <List>
      {rows.map((ele, idx) => (
        <ListItemVacationReceipt
          onClick={() => goToSignature(ele)}
          key={idx}
          {...ele}
        />
      ))}
    </List>
  );
};
//
const ListItemVacationReceipt = ({
  ferias,
  retorno,
  assinatura,
  inicio,
  fim,
  pagamento,
  matricula,
  planta,
  abono,
  onClick,
}) => {
  const { t } = useTranslation();
  const keyDocument = `${matricula}_${planta}_${pagamento}`;

  const [wait, setWait] = useToggle();
  const onDownloadComprovant = useCallback(
    async (type) => {
      const params = new URLSearchParams();
      params.append("tipo", type);
      params.append("id", keyDocument);

      setWait();

      try {
        const response = await axios.get(`${ROUTES[0]}?${params.toString()}`);
        download(response.data.link, "comprovante.pdf");
      } catch (err) {
        if (err?.response?.data?.message) {
          ToastErro(err.response.data.message);
        } else {
          ToastErro(err.toString());
        }
      } finally {
        setWait();
      }
    },
    [setWait, keyDocument]
  );
  //
  const options = [
    {
      label: t("ferias.labelBtnVacation"),
      onClick: () => onDownloadComprovant("F"),
    },
  ];

  if (abono > 0) {
    options.push({
      label: t("ferias.labelBtnSalaryBonus"),
      onClick: () => onDownloadComprovant("A"),
    });
  }

  return (
    <Paper sx={{ p: 1, my: 0.5 }}>
      <ListItemButton onClick={onClick} dense disableGutters disablePadding>
        <ListItemText
          primary={
            <PrimaryItemVacationReceipt
              initialVacation={inicio}
              finalVacation={fim}
              signature={assinatura}
              returnOfVacation={retorno}
              quantityOfDays={ferias}
            />
          }
          secondary={<SecondaryItemVacationReceipt dateOfPayment={pagamento} />}
        />
      </ListItemButton>
      {assinatura && (
        <Stack
          gap={1}
          sx={{ my: 1 }}
          direction="row"
          justifyContent="space-between"
        >
          {options.map((ele, idx) => (
            <Button
              fullWidth
              disabled={wait}
              onClick={ele.onClick}
              key={idx}
              variant="outlined"
              startIcon={<Icone icone="FileDownload" />}
            >
              {ele.label}
            </Button>
          ))}
        </Stack>
      )}
    </Paper>
  );
};

const PrimaryItemVacationReceipt = ({
  initialVacation,
  finalVacation,
  returnOfVacation,
  signature,
  quantityOfDays,
}) => {
  const { t } = useTranslation();
  return (
    <Stack gap={0.5}>
      <Chip
        sx={{ mb: 1, alignSelf: "flex-end" }}
        size="small"
        label={signature ? t("ferias.signature") : t("ferias.notSignature")}
        variant="filled"
        color={signature ? "success" : "error"}
      />
      <Stack direction="row" justifyContent="space-between">
        <Body2>
          {t("ferias.initialVacation")} {Utils.converterData(initialVacation)}
        </Body2>
        <Body2>
          {t("ferias.finalVacation")} {Utils.converterData(finalVacation)}
        </Body2>
      </Stack>
      <Body2>
        {t("ferias.quantityOfDays")} <b>{quantityOfDays}</b>
      </Body2>
      <Body2>
        {t("ferias.returnOfVacation")}{" "}
        <b>{Utils.converterData(returnOfVacation)}</b>
      </Body2>
    </Stack>
  );
};

const SecondaryItemVacationReceipt = ({ dateOfPayment }) => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ mt: 1 }} direction="row">
      <Body1>
        {t("ferias.dateOfPayment")}
        <b style={{ color: blue[700] }}>{Utils.converterData(dateOfPayment)}</b>
      </Body1>
    </Stack>
  );
};

Ferias.rota = "/ferias";

export default Ferias;
