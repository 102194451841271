import { IconButton, Stack } from "@mui/material";
import React, { useCallback, useState } from "react";
import Icone from "./icone";
import OptionsMenu from "./options-menu";
import { useTranslation } from "react-i18next";
//
import FlagPT from "../images/flag_brazil.png";
import FlagEN from "../images/flag_eng.png";
import FlagITA from "../images/flag_ita.png";
import FlagESP from "../images/flag_esp.png";
import { Body2 } from "./tipografia";
import _ from "lodash";

const mapperFlags = {
  PT: FlagPT,
  EN: FlagEN,
  IT: FlagITA,
  ES: FlagESP,
};

const STR = {
  titleOptionsMenu: "Ver opções de legenda",
};

function TranslateSwitch() {
  const { i18n } = useTranslation();
  const [optionSelected, setOptionSelected] = useState(
    _.toUpper(i18n?.resolvedLanguage) || "PT"
  );

  //
  const toggleTranslate = useCallback(
    (lng, label) => {
      i18n.changeLanguage(lng);
      setOptionSelected(_.toUpper(lng));
    },
    [i18n, setOptionSelected]
  );
  //
  const languages = [
    {
      onClick: () => toggleTranslate("pt", "PT"),

      label: (
        <Stack direction="row" gap={0.5} alignItems="center">
          <img src={mapperFlags["PT"]} alt="flag" /> <Body2>- PT-BR</Body2>
        </Stack>
      ),
    },
    {
      onClick: () => toggleTranslate("en", "EN"),

      label: (
        <Stack direction="row" gap={0.5} alignItems="center">
          <img src={mapperFlags["EN"]} alt="flag" /> <Body2>- ENG</Body2>
        </Stack>
      ),
    },
    {
      onClick: () => toggleTranslate("it", "IT"),

      label: (
        <Stack direction="row" gap={0.5} alignItems="center">
          <img src={mapperFlags["IT"]} alt="flag" /> <Body2>- ITA</Body2>
        </Stack>
      ),
    },
    {
      onClick: () => toggleTranslate("es", "ES"),

      label: (
        <Stack direction="row" gap={0.5} alignItems="center">
          <img src={mapperFlags["ES"]} alt="flag" /> <Body2>- ESP</Body2>
        </Stack>
      ),
    },
  ];

  //
  return (
    <OptionsMenu
      noIcon
      component={
        <IconButton>
          {optionSelected ? (
            <img
              src={mapperFlags[optionSelected]}
              alt={`Translate to ${optionSelected}`}
            />
          ) : (
            <Icone icone="Translate" />
          )}
        </IconButton>
      }
      title={STR.titleOptionsMenu}
      options={languages}
    />
  );
}

export default TranslateSwitch;
