import { useEffect, useState } from "react";
import _ from "lodash";
import { ToastErro, formatarParaSelect } from "../../../utils/utils";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { EntradaForm, H6, Pesquisando } from "../../../components";
import { Paper } from "@mui/material";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

// Criando formulario para atualizacao de dados cadastrais
const FormDadosCadastrais = ({ data, wait }) => {
  const { t } = useTranslation();
  const [aguardar, setAguardar] = useState(null);
  const [antigoCep, setAntigoCep] = useState(data.pessoais.cep);

  const schema = [
    {
      grid: { xs: 12, md: 6 },
      name: "nome",
      type: "text",
      label: t("dados_cadastrais.labeName"),
      placeholder: t("dados_cadastrais.placeholderName"),
    },
    {
      grid: { xs: 12, md: 3 },
      name: "cpf",
      type: "text",
      label: t("dados_cadastrais.labelCpf"),
      placeholder: t("dados_cadastrais.placeholderCpf"),
      mask: [
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ],
    },
    {
      grid: { xs: 12, md: 3 },
      name: "telefone",
      type: "text",
      placeholder: t("dados_cadastrais.placeholderPhone"),
      label: t("dados_cadastrais.labelPhone"),
      mask: [
        "(",
        /\d/,
        /\d/,
        ")",
        /[9]/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
    },

    {
      grid: { xs: 12, md: 4 },
      name: "rg",
      type: "text",
      placeholder: t("dados_cadastrais.placeholderRg"),
      label: t("dados_cadastrais.labelRg"),
    },
    {
      grid: { xs: 12, md: 4 },
      name: "email",
      type: "email",
      label: t("dados_cadastrais.labelEmail"),
      placeholder: t("dados_cadastrais.placeholderEmail"),
    },
    {
      grid: { xs: 12, md: 4 },
      name: "nascimento",
      type: "date",
      label: t("dados_cadastrais.labelBirth"),
      placeholder: t("dados_cadastrais.placeholderBirth"),
    },

    {
      grid: { xs: 12, md: 6 },
      name: "curso",
      type: "text",
      label: t("dados_cadastrais.labelCourse"),
      placeholder: t("dados_cadastrais.placeholderCourse"),
    },
    {
      grid: { xs: 12, md: 6 },
      name: "data_curso",
      type: "date",
      label: t("dados_cadastrais.labelCourseDate"),
    },

    {
      grid: { xs: 12, md: 3 },
      name: "estado_civil",
      type: "select",
      itens: [],
      autoFormat: true,
      label: t("dados_cadastrais.labelMaritalStatus"),
    },
    {
      grid: { xs: 12, md: 4 },
      name: "escolaridade",
      type: "select",
      itens: [],
      autoFormat: true,
      label: t("dados_cadastrais.labelSchooling"),
    },
    {
      grid: { xs: 12, md: 2 },
      name: "deficiencia",
      type: "select",
      itens: [],
      autoFormat: true,
      label: t("dados_cadastrais.labelDisability"),
    },
    {
      grid: { xs: 12, md: 3 },
      name: "desc_deficiencia",
      type: "select",
      itens: [],
      autoFormat: true,
      label: t("dados_cadastrais.labelDisabilityType"),
    },

    {
      grid: { xs: 12, md: 2 },
      name: "cep",
      type: "text",
      label: t("dados_cadastrais.labelCep"),
      placeholder: t("dados_cadastrais.placeholderCep"),
      mask: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
    },
    {
      grid: { xs: 12, md: 6 },
      name: "logradouro",
      type: "text",
      label: t("dados_cadastrais.labelAddress"),
      placeholder: t("dados_cadastrais.placeholderAddress"),
    },
    {
      grid: { xs: 12, md: 2 },
      name: "numero",
      type: "text",
      label: t("dados_cadastrais.labelNumber"),
      placeholder: t("dados_cadastrais.placeholderNumber"),
      mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    },
    {
      grid: { xs: 12, md: 2 },
      name: "complemento",
      type: "text",
      label: t("dados_cadastrais.labelComplement"),
      placeholder: t("dados_cadastrais.placeholderComplement"),
    },

    {
      grid: { xs: 12, md: 2 },
      name: "codigo_municipio",
      type: "text",
      label: t("dados_cadastrais.labelCityCode"),
      placeholder: t("dados_cadastrais.placeholderCityCode"),
    },
    {
      grid: { xs: 12, md: 3 },
      name: "bairro",
      type: "text",
      label: t("dados_cadastrais.labelDistrict"),
      placeholder: t("dados_cadastrais.placeholderDistrict"),
    },
    {
      grid: { xs: 12, md: 3 },
      name: "cidade",
      type: "text",
      label: t("dados_cadastrais.labelCity"),
      placeholder: t("dados_cadastrais.placeholderCity"),
    },
    {
      grid: { xs: 12, md: 1 },
      name: "estado",
      type: "text",
      label: t("dados_cadastrais.labelState"),
      placeholder: t("dados_cadastrais.placeholderState"),
      mask: [/[a-zA-Z]/, /[a-zA-Z]/],
    },
    {
      grid: { xs: 12, md: 3 },
      name: "ponto_de_referencia",
      type: "text",
      label: t("dados_cadastrais.labelReferencePoint"),
      placeholder: t("dados_cadastrais.placeholderReferencePoint"),
    },

    {
      grid: { xs: 12, md: 3 },
      name: "nome_emergencia",
      type: "text",
      label: t("dados_cadastrais.labelEmergencyName"),
      placeholder: t("dados_cadastrais.placeholderEmergencyName"),
    },
    {
      grid: { xs: 12, md: 3 },
      name: "contato_emergencia",
      type: "text",
      label: t("dados_cadastrais.labelEmergencyContact"),
      placeholder: t("dados_cadastrais.placeholderEmergencyContact"),
      mask: [
        "(",
        /\d/,
        /\d/,
        ")",
        /[9]/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
    },
    {
      grid: { xs: 12, md: 6 },
      name: "arquivo",
      type: "file",
      label: t("dados_cadastrais.labelAttachments"),
      multiple: true,
    },
  ];

  if (data?.dados_auxiliares) {
    // Preenchendo as opcoes dos campos
    schema[8].itens = data.dados_auxiliares.estado_civil;
    schema[9].itens = data.dados_auxiliares.escolaridade;
    schema[10].itens = data.dados_auxiliares.deficiencia;
    schema[11].itens = data.dados_auxiliares.desc_deficiencia;
  }
  // Carregue ja os valores pessoais
  if (data?.pessoais) {
    schema[0].defaultValue = _.trim(data.pessoais.nome);
    schema[1].defaultValue = _.trim(data.pessoais.cpf);
    schema[2].defaultValue = _.trim(data.pessoais.telefone);
    schema[3].defaultValue = _.trim(data.pessoais.rg);
    schema[4].defaultValue = _.trim(data.pessoais.email);
    schema[5].defaultValue = _.trim(data.pessoais.nascimento);
    schema[6].defaultValue = _.trim(data.pessoais.curso);
    schema[7].defaultValue = _.trim(data.pessoais.data_curso);
    schema[8].defaultValue = formatarParaSelect(
      _.filter(
        data.dados_auxiliares.estado_civil,
        (val) => val[0] === _.trim(data.pessoais.estado_civil)
      )
    )[0]; //_.trim(data.pessoais.estado_civil);
    schema[9].defaultValue = formatarParaSelect(
      _.filter(
        data.dados_auxiliares.escolaridade,
        (val) => val[0] === _.trim(data.pessoais.escolaridade)
      )
    )[0];
    schema[10].defaultValue = formatarParaSelect(
      _.filter(
        data.dados_auxiliares.deficiencia,
        (val) => val[0] === _.trim(data.pessoais.deficiencia)
      )
    )[0]; //_.trim(data.pessoais.deficiencia);
    schema[11].defaultValue = formatarParaSelect(
      _.filter(
        data.dados_auxiliares.desc_deficiencia,
        (val) => val[0] === _.trim(data.pessoais.descricao_deficiencia)
      )
    )[0]; //_.trim(data.pessoais.descricao_deficiencia);
    schema[12].defaultValue = _.trim(data.pessoais.cep);
    schema[13].defaultValue = _.trim(data.pessoais.logradouro);
    schema[14].defaultValue = _.trim(data.pessoais.numero);
    schema[15].defaultValue = _.trim(data.pessoais.complemento);
    schema[16].defaultValue = _.trim(data.pessoais.codigo_municipio);

    schema[17].defaultValue = _.trim(data.pessoais.bairro);
    schema[18].defaultValue = _.trim(data.pessoais.cidade);
    schema[19].defaultValue = _.trim(data.pessoais.estado);
    schema[21].defaultValue = _.trim(data.pessoais.ponto_de_referencia);
    schema[21].defaultValue = _.trim(data.pessoais.nome_emergencia);
    schema[22].defaultValue = _.trim(data.pessoais.telefone_emergencia);
  }
  const schemaValidator = yup.object().shape({
    nome: yup.string().min(3).required(),
    cpf: yup
      .string()
      .matches(/[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/)
      .required(),
    telefone: yup
      .string()
      .matches(/^\([0-9]{2}\)[0-9]{5}-[0-9]{4}$/)
      .required(),
    rg: yup.string().min(3).required(),
    email: yup.string().email().required(),
    nascimento: yup.date().required(),
    estado_civil: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    escolaridade: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    deficiencia: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    desc_deficiencia: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    cep: yup
      .string()
      .matches(/^[0-9]{5}-[0-9]{3}$/)
      .required(),
    logradouro: yup.string().min(3).required(),
    numero: yup.string().min(1).required(),
    codigo_municipio: yup.string().min(5).required(),
    bairro: yup.string().min(3).required(),
    cidade: yup.string().min(3).required(),
    estado: yup.string().min(2).max(2).required(),
    nome_emergencia: yup.string().min(3).required(),
    contato_emergencia: yup
      .string()
      .matches(/^\([0-9]{2}\)[0-9]{5}-[0-9]{4}$/)
      .required(),
  });
  const schemaMessageError = {
    nome: t("dados_cadastrais.errorName"),
    cpf: t("dados_cadastrais.errorCpf"),
    telefone: t("dados_cadastrais.errorPhone"),
    rg: t("dados_cadastrais.errorRg"),
    email: t("dados_cadastrais.errorEmail"),
    nascimento: t("dados_cadastrais.errorBirth"),
    estado_civil: t("dados_cadastrais.errorMaritalStatus"),
    escolaridade: t("dados_cadastrais.errorSchooling"),
    deficiencia: t("dados_cadastrais.errorDisability"),
    desc_deficiencia: t("dados_cadastrais.errorDisabilityType"),
    cep: t("dados_cadastrais.errorCep"),
    logradouro: t("dados_cadastrais.errorAddress"),
    numero: t("dados_cadastrais.errorNumber"),
    codigo_municipio: t("dados_cadastrais.errorCityCode"),
    bairro: t("dados_cadastrais.errorDistrict"),
    cidade: t("dados_cadastrais.errorCity"),
    estado: t("dados_cadastrais.errorState"),
    nome_emergencia: t("dados_cadastrais.errorEmergencyName"),
    contato_emergencia: t("dados_cadastrais.errorEmergencyContact"),
  };
  //
  const _useForm = useForm({
    resolver: yupResolver(schemaValidator),
  });
  const { watch, setValue } = _useForm;
  const CAMPO_CEP = watch("cep");

  // Funcao que consulta um CEP e substitui os campos
  useEffect(() => {
    // Se o campo do cep tiver todos os caracteres lançar uma busca
    if (antigoCep !== CAMPO_CEP && CAMPO_CEP?.length === 9) {
      setAntigoCep(null);

      setAguardar(true);
      setValue("bairro", "");
      setValue("logradouro", "");
      setValue("complemento", "");
      setValue("cidade", "");
      setValue("codigo_municipio", "");
      setValue("estado", "");

      (async function () {
        const resp = await axios.get(
          `https://viacep.com.br/ws/${CAMPO_CEP.replace(/-/, "")}/json`
        );
        setAguardar(false);
        if (resp.status !== 200) {
          ToastErro(t("dados_cadastrais.errorCepNot200"));
          return false;
        }
        if (resp.data?.erro) {
          ToastErro(t("dados_cadastrais.errorCepNotFound"));
          return false;
        }
        // Defina os novos valores
        const { ibge, logradouro, complemento, bairro, localidade, uf } =
          resp.data;
        //
        setValue("bairro", bairro);
        setValue("logradouro", logradouro);
        setValue("complemento", complemento);
        setValue("cidade", _.toUpper(localidade));
        setValue(
          "codigo_municipio",
          ibge ? ibge.substring(2, ibge.length) : ""
        );
        setValue("estado", _.toUpper(uf));
      })();
    }
  }, [setValue, t, setAntigoCep, CAMPO_CEP, antigoCep]);
  //
  const fn = async (val) => {
    const dados = {
      nome: val.nome,
      cpf: val.cpf.replace(/\./g, "").replace("-", ""),
      rg: val.rg,
      telefone: val.telefone.replace("(", "").replace(")", "").replace("-", ""),
      email: val.email,
      nascimento: format(val.nascimento, "yyyy-MM-dd"),
      estado_civil: val.estado_civil.value,
      escolaridade: val.escolaridade.value,
      curso: val?.curso || "",
      data_curso: val?.data_curso
        ? format(parseISO(val.data_curso), "yyyy-MM-dd")
        : "",
      deficiencia: val.deficiencia.value,
      descricao_deficiencia: val.desc_deficiencia.value,
      logradouro: val.logradouro,
      complemento: val?.complemento || "NENHUM",
      numero: val.numero,
      bairro: val.bairro,
      codigo_municipio: val.codigo_municipio,
      cep: val.cep.replace("-", ""),
      cidade: val.cidade,
      estado: val.estado,
      ponto_de_referencia: val?.ponto_de_referencia || "",
      nome_emergencia: val.nome_emergencia,
      telefone_emergencia: val.contato_emergencia
        .replace("(", "")
        .replace(")", "")
        .replace("-", ""),
    };

    const formData = {
      ...dados,
    };
    //
    if (val.arquivo) {
      setAguardar(true);
      const formAttach = new FormData();
      [...val.arquivo].forEach((val) => {
        formAttach.append("arquivo", val);
      });
      try {
        const response = await axios.post("/bucket", formAttach);
        formData.anexos = response.data.arquivo.map((ele) => ele.url);
      } catch (error) {
        if (error?.response?.data?.message) {
          ToastErro(error?.response?.data?.message);
        } else if (error?.message) {
          ToastErro(error?.message);
        } else {
          ToastErro(String(error));
        }
        return false;
      } finally {
        setAguardar(false);
      }
    }
    //
    //formData.append("dados", JSON.stringify(dados));
    // Agora faça o envio para o backend
    setAguardar(true);
    //
    try {
      const resp = await axios.put("/dados_pessoais", formData);

      // Deu certo exiba a mensagem de sucesso
      toast.dark(resp.data.sucesso, {
        type: "success",
      });

      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (error) {
      if (error?.response?.data?.message) {
        ToastErro(error?.response?.data?.message);
      } else if (error?.message) {
        ToastErro(error?.message);
      } else {
        ToastErro(String(error));
      }
    } finally {
      setAguardar(false);
    }
  };

  return (
    <>
      <H6>{t("dados_cadastrais.titleFormUpd")}</H6>

      <Paper elevation={0} sx={{ p: 1 }}>
        {data ? (
          <EntradaForm
            schema={schema}
            schemaValidator={schemaValidator}
            schemaMessageError={schemaMessageError}
            onSubmit={fn}
            useForm={_useForm}
            wait={wait || aguardar}
          />
        ) : wait ? (
          <Pesquisando />
        ) : null}
      </Paper>
    </>
  );
};

export default FormDadosCadastrais;
