import { Avatar, Stack } from "@mui/material";
import { Body1, Caption, Subtitle2 } from "../../../components";

const AvatarEntrevistado = ({
  nome_entrevistado,
  planta,
  mat_entrevistado,
  titulo,
  sx,
}) => {
  return (
    <Stack sx={sx ? sx : {}}>
      {titulo && <Subtitle2 align="left">{titulo}</Subtitle2>}
      <Stack spacing={1} direction="row" alignItems="center">
        <Avatar
          alt={nome_entrevistado.trim()}
          src={`/static/imagens/avatar/${planta}/${mat_entrevistado.trim()}.jpg`}
        />
        <Stack>
          <Body1>{mat_entrevistado}</Body1>
          <Caption>{nome_entrevistado}</Caption>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AvatarEntrevistado;
