import React, { useEffect } from "react";
import BackgroundRouter from "../../background-router";
import { Container, Stack } from "@mui/material";
import { LessonFormViewer, LessonGoBack } from "./lesson_form";
import { useSearchParam } from "react-use";
import axios from "axios";
import { ToastErro } from "../../../utils/utils";
import useSWR from "swr";

const fetcher = (url) => axios.get(url).then((resp) => resp.data);

function LessonView() {
  const viewPrint = useSearchParam("viewPrint");
  const codigo = useSearchParam("id");
  const { data, error } = useSWR(`/lesson_api/lesson/${codigo}`, fetcher);
  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);

  //
  return (
    <BackgroundRouter>
      <Container maxWidth={viewPrint ? "lg" : false}>
        {data && (
          <Stack>
            <LessonGoBack downloadPDF={codigo} />
            <LessonFormViewer viewData={data} />
          </Stack>
        )}
      </Container>
    </BackgroundRouter>
  );
}

LessonView.rota = "/lesson_view";

export default LessonView;
