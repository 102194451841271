import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
//import Fundo from "../../images/fundo.jpg";
import {
  //Box,
  useMediaQuery,
  Grid,
  Divider,
  useTheme,
  Container,
  Paper,
  Stack,
  ButtonBase,
} from "@mui/material";
import { SideBar, H6, Caption, Icone, Subtitle1 } from "../../../components";
import { registrarAcesso } from "../../../utils/utils";
import ManutencaoArea from "./manutencao-area";
import ManutencaoModelo from "./manutencao-modelo";
import ManutencaoPerda from "./manutencao-perda";
import ManutencaoPilar from "./manutencao-pilar";
import { useTranslation } from "react-i18next";

const sxPaper2 = {
  minHeight: "75px",
};
//
const sxFont = {
  color: "white",
};

function ManutencaoAtivos() {
  const { t } = useTranslation();
  const history = useHistory();
  // Registrando acesso a pagina
  useEffect(() => registrarAcesso(window.location.pathname), []);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const opcoes = [
    {
      icone: "PrecisionManufacturing",
      nome: t("manutencao_ativos.labelArea"),
      titulo: t("manutencao_ativos.titleLabelArea"),
      onClick: () => history.push(ManutencaoArea.rota),
    },
    {
      icone: "CarRental",
      nome: t("manutencao_ativos.labelModelo"),
      titulo: t("manutencao_ativos.titleLabelModelo"),
      onClick: () => history.push(ManutencaoModelo.rota),
    },
    {
      icone: "CancelPresentation",
      nome: t("manutencao_ativos.labelPerda"),
      titulo: t("manutencao_ativos.titleLabelPerda"),
      onClick: () => history.push(ManutencaoPerda.rota),
    },
    {
      icone: "ViewWeek",
      nome: t("manutencao_ativos.labelPilar"),
      titulo: t("manutencao_ativos.titleLabelPilar"),
      onClick: () => history.push(ManutencaoPilar.rota),
    },
  ];

  return (
    <Stack direction="row">
      <SideBar barraIntegrada />
      <Container sx={{ mt: 0.5, mb: isMobile ? 7 : 2 }} maxWidth="lg">
        <H6>{t("manutencao_ativos.titlePage")}</H6>
        <Caption component="p" textAlign="center">
          {t("manutencao_ativos.subtitlePage")}
        </Caption>
        <Divider sx={{ my: 1 }} />
        <Grid container spacing={2}>
          {opcoes.map((ele, idx) => (
            <Grid key={idx} item xs={4} md={1}>
              <CardOpcaoDois {...ele} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Stack>
  );
}

// Card com opcoes sem curva no modelo de exibicao)
const CardOpcaoDois = ({ icone, nome, titulo, onClick }) => {
  return (
    <Paper sx={sxPaper2} elevation={4} title={titulo}>
      <ButtonBase onClick={onClick} sx={{ width: "100%", height: "100%" }}>
        <Stack
          sx={{ py: 0.5, height: "100%", width: "100%" }}
          alignItems="center"
          justifyContent="space-around"
          spacing={1}
        >
          <Icone
            icone={icone}
            sx={{ color: (theme) => theme.palette.info.dark, fontSize: 36 }}
          />
          <Subtitle1
            sx={{ ...sxFont, color: (theme) => theme.palette.info.dark }}
          >
            {nome}
          </Subtitle1>
        </Stack>
      </ButtonBase>
    </Paper>
  );
};

ManutencaoAtivos.rota = "/manutencao_ativos";

export default ManutencaoAtivos;
