import { addMonths, format, parseISO, subMonths } from "date-fns";
import _ from "lodash";
import getLanguage from "../../../utils/language";
import { memo, useCallback, useEffect, useState } from "react";
import { useFetch } from "../../../hooks";
import { ToastErro, optTabela } from "../../../utils/utils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import { H6, Icone, Subtitle1, Subtitle2, TabelaV2 } from "../../../components";
import { PainelSelecaoAvatar } from ".";
import { DrawerDialog } from "../../../extra-components";
import Select from "../../../components/select-old";
import { useTranslation } from "react-i18next";

// Modal
const MODAL = {
  PONTO_VER_DETALHES: "PONTO_VER_DETALHES",
};
// Obtendo o idioma
const IDIOMA_PONTO = getLanguage("pt_br", "/ponto_eletronico");
// Gerar a estrutura de periodos
let periodoAtual = {};
const periodos = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((ele) => {
  const periodo1 = format(subMonths(new Date(), ele), "yyyyMM21");
  const periodo2 = format(addMonths(parseISO(periodo1), 1), "yyyyMM20");
  // Verifica se a data atual esta no range
  const filtro = format(new Date(), "yyyyMMdd");

  if (periodo1 <= filtro && periodo2 >= filtro) {
    periodoAtual = `${periodo1}-${periodo2}`;
  }

  return [
    `${periodo1}-${periodo2}`,
    `${format(parseISO(periodo1), "dd/MM/yyyy")} - ${format(
      parseISO(periodo2),
      "dd/MM/yyyy"
    )}`,
  ];
});

// Dados relacionados ao ponto eletronico
const PontoEletronico = memo(({ selecionado, setSelecionado }) => {
  const { t } = useTranslation();
  const [datas, setDatas] = useState({
    de: format(parseISO(periodoAtual.split("-")[0]), "yyyy-MM-dd"),
    ate: format(parseISO(periodoAtual.split("-")[1]), "yyyy-MM-dd"),
  });

  const { data, error, wait, setFetch } = useFetch(
    "/minha_equipe_ponto_eletronico",
    "GET"
  );

  useEffect(() => {
    if (selecionado) {
      setFetch({
        de: datas.de,
        ate: datas.ate,
        matricula: selecionado,
      });
    }
  }, [setFetch, datas, selecionado]);

  //
  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);

  const fnOnClick = useCallback(
    (e) => {
      const [de, ate] = e.target.value.split("-");
      setDatas({
        de,
        ate,
      });
    },
    [setDatas]
  );

  return (
    <Container maxWidth={false}>
      <H6>{t("minha_equipe.myEquipPointPageTitle")}</H6>

      <Select
        onChange={fnOnClick}
        defaultValue={periodoAtual}
        options={periodos}
        label={t("minha_equipe.myEquipPointLabelPeriod")}
      />

      <PainelSelecaoAvatar
        selecionado={selecionado}
        setSelecionado={setSelecionado}
      />
      <br />
      {wait ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        selecionado && <PontoEletronicoDados data={data} />
      )}
    </Container>
  );
});

const CorpoModal = ({ modal }) => {
  switch (modal.tipo) {
    case MODAL.PONTO_VER_DETALHES:
      return <PontoDetalhes {...modal.dados} />;
    default:
      return;
  }
};

// Renderiza a lista de detalhes
const PontoDetalhes = ({ cabe, corpo }) => {
  //  Se nao tiver encontrado nada, retorne null
  if (corpo.length < 1) return null;
  console.log(corpo);
  // Pega somente os campos que não estao sendo exibidos ali
  const filtro = [...corpo].splice(6, 5);

  return (
    <List
      disablePadding
      sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 1 }}
    >
      {filtro.map((ele, idx) => (
        <ListItem divider key={idx}>
          <ListItemText>
            <Subtitle1 align="left">{cabe[idx + 6]}</Subtitle1>
          </ListItemText>
          <ListItemText>
            <Subtitle2 align="right">{ele}</Subtitle2>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

// Corpo para o ponto eletronico do usuario selecionado
const PontoEletronicoDados = ({ data }) => {
  const [modal, setModal] = useState(null);
  const isMobile = useTheme()?.isMobile;

  let corpo, cabe, acumulado;
  if (data) {
    corpo = data.corpo;
    acumulado = data.acumulado;
    cabe = data.cabe;
  }

  return (
    <>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => <CorpoModal modal={modal} />}
          fecharModal={() => setModal(null)}
        />
      )}
      {data ? (
        isMobile ? (
          <PontoEletronicoMobile
            setModal={setModal}
            corpo={corpo}
            cabe={cabe}
            acumulado={acumulado}
          />
        ) : (
          <PontoEletronicoRenderTabela
            acumulado={acumulado}
            cabe={cabe}
            corpo={corpo}
          />
        )
      ) : null}
    </>
  );
};
// Tabela do estilo desktop para ver os dados
const PontoEletronicoRenderTabela = ({ cabe, corpo }) => {
  // verifica quais campos sao data e os insere no array dtas
  const datas = [];
  if (corpo && corpo.length > 0) {
    corpo[0].forEach((ele, idx) => {
      if (ele.toString().search(/^[0-9]{8}$/g) !== -1) {
        datas.push(idx);
      }
    });
  }
  const optTabelaT = {
    ...optTabela,
    baixar_em_excel: "/baixar_em_excel",
    ocultarColuna: true,
    data: datas,
    styleCabe: { fontSize: "90%" },
    styleCorpo: { fontSize: "85%", whiteSpace: "nowrap" },
  };

  return (
    <>
      <TabelaV2 cabe={cabe} corpo={corpo} {...optTabelaT} />
      <br />
    </>
  );
};
// Para visualização mobile do ponto eletronico
const PontoEletronicoMobile = ({ setModal, cabe, corpo, acumulado }) => {
  // Retorna um objeto com itens {'data': '20210901', e1: 08:50, e2: 16:00 }
  const dados = corpo.map((ele) => ({
    dia: ele[0].substr(0, 3),
    data: ele[1],
    e1: ele[2],
    s2: ele[5] ? ele[5] : ele[3], // Caso o 2s esteja vazio usar o 1s
  }));
  //
  return (
    <>
      {acumulado && <Totalizador acumulado={acumulado} />}
      <List>
        <ListItem>
          <ListItemText align="left">
            {IDIOMA_PONTO.mobileCampoDia}
          </ListItemText>
          <ListItemText align="center">
            {IDIOMA_PONTO.mobileCampoData} &nbsp;&nbsp;&nbsp;
          </ListItemText>
          <ListItemText align="center">
            {IDIOMA_PONTO.mobileCampoEntrada}
          </ListItemText>
          <ListItemText align="center">
            {IDIOMA_PONTO.mobileCampoSaida}
          </ListItemText>
          <ListItemText align="right">
            {IDIOMA_PONTO.mobileCampoVer}
          </ListItemText>
        </ListItem>
        {dados.map((ele, idx) => {
          const { dia, data, e1, s2 } = ele;

          return (
            <ListItem key={idx} divider>
              <ListItemText align="left">{dia}</ListItemText>
              <ListItemText align="center">
                {format(parseISO(data), "dd/MM/yy")}
              </ListItemText>
              <ListItemText align="center">{e1}</ListItemText>
              <ListItemText align="center">{s2}</ListItemText>
              <ListItemText
                onClick={() =>
                  setModal({
                    tipo: MODAL.PONTO_VER_DETALHES,
                    dados: {
                      corpo: _.filter(
                        corpo,
                        (val) => String(val[1]) === String(data)
                      )[0],
                      cabe,
                    },
                  })
                }
                align="right"
              >
                <Icone icone="MoreHoriz" />
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
// Renderiza o  totalizador
const Totalizador = ({ acumulado }) => {
  const { t } = useTranslation();
  const sxStyle = { my: 1, backgroundColor: "#005387", color: "white" };
  return (
    <Accordion elevation={1} sx={sxStyle}>
      <AccordionSummary
        expandIcon={<Icone icone="ExpandMore" sx={{ color: "white" }} />}
      >
        <Icone icone="QueryBuilder" />
        &nbsp;&nbsp;
        <Subtitle1>
          {t("minha_equipe.myEquipPointTotalResultPartial")}
        </Subtitle1>
      </AccordionSummary>
      <AccordionDetails>
        <List dense>
          {acumulado.map((ele, idx) => (
            <ListItemText
              key={idx}
              primary={<Subtitle2>{`${ele[0]} - ${ele[1]} `}</Subtitle2>}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default PontoEletronico;
