import { useCallback, useEffect } from "react";
import VerPDF from "./documentos-view-pdf";
import { ACTIONS } from "./documentos-utils";
import { Modal, Stack } from "@mui/material";

//
const SuperModal = ({ modal, dispatch }) => {
  //
  const onCloseModal = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        dispatch({
          type: ACTIONS.FECHAR_MODAL,
        });
      }
    },
    [dispatch]
  );
  let body;
  switch (modal.tipo) {
    case "VER_PDF":
      body = (
        <VerPDF
          pdf={modal.dados}
          onCloseModal={() =>
            dispatch({
              type: ACTIONS.FECHAR_MODAL,
            })
          }
        />
      );
      break;
    default:
      break;
  }
  //
  useEffect(() => {
    window.addEventListener("keyup", onCloseModal);
    return () => {
      window.removeEventListener("keyup", onCloseModal);
    };
  }, [onCloseModal]);

  return (
    <Modal
      open
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack alignItems="center" justifyContent="center">
        {body}
      </Stack>
    </Modal>
  );
};

export default SuperModal;
