import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Container,
  Stack,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  manutencaoVariaveisAddVariavel,
  manutencaoVariaveisUpdDelVariavel,
} from "./manutencao-variaveis-actions";
import {
  selectVariaveis,
  selectDrawer,
} from "./manutencao-variaveis-selectors";
import { H6 } from "../../../components";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

// Schema para validar variavel
const schema = yup.object().shape({
  nome: yup.string().min(3).required(),
  descricao: yup.string().min(3).required(),
});

const FormAddVariavel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //const { aguardar, manutencaoVariaveis } = useSelector(state=> state);
  const aguardar = useSelector((state) => state.aguardar);
  const variaveis = useSelector(selectVariaveis);
  const idVariavel = useSelector(selectDrawer).dados;

  // Se update for um numero devemos pegar a variavel a ser editada
  let objDados;
  if (!isNaN(idVariavel) && variaveis) {
    objDados = variaveis.filter(
      (ele) => String(ele.id) === String(idVariavel)
    )[0];
  }
  // if(!isNaN(update) && manutencaoVariaveis ){
  //     objDados = manutencaoVariaveis.variaveis.filter(ele=> String(ele.id) === String(update) )[0];

  // }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // Funcao de callback para enviar os dados
  const fn = (val) => {
    const { nome, descricao } = val;
    if (objDados) {
      // É uma atualizacao
      dispatch(
        manutencaoVariaveisUpdDelVariavel(
          { descricao, id_variavel: idVariavel },
          false
        )
      );
    } else {
      // É uma adicao
      dispatch(manutencaoVariaveisAddVariavel({ nome, descricao }));
    }
  };

  return (
    <Container maxWidth="md" sx={{ maxHeight: "75vh", p: 2 }}>
      <Stack spacing={2}>
        <H6>{t("manutencao_variaveis.titleFormAddVarUser")}</H6>
        <Controller
          name="nome"
          control={control}
          defaultValue={objDados ? objDados.nome : ""}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              type="text"
              label={t("manutencao_variaveis.labelVarUserName")}
              size="small"
              placeholder={t("manutencao_variaveis.placeholderVarUserName")}
              {...field}
              disabled={objDados || aguardar ? true : false}
              fullWidth
              InputLabelProps={{ shrink: true }}
              helperText={
                !!errors.nome && t("manutencao_variaveis.errorVarUserName")
              }
              error={!!errors.nome}
            />
          )}
        />
        <Controller
          name="descricao"
          defaultValue={objDados ? objDados.descricao : ""}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              type="textarea"
              label={t("manutencao_variaveis.labelVarUserDescription")}
              fullWidth
              {...field}
              multiline
              minRows={3}
              disabled={aguardar}
              InputLabelProps={{ shrink: true }}
              placeholder={t(
                "manutencao_variaveis.placeholderVarUserDescription"
              )}
              helperText={
                !!errors.descricao &&
                t("manutencao_variaveis.errorVarUserDescription")
              }
              error={!!errors.descricao}
            />
          )}
        />
        <Button
          variant="contained"
          fullWidth
          disabled={aguardar}
          onClick={handleSubmit(fn)}
          startIcon={aguardar ? <CircularProgress size={20} /> : <SaveIcon />}
        >
          {t("manutencao_variaveis.labelBtnVarUserSend")}
        </Button>
      </Stack>
    </Container>
  );
};

export default FormAddVariavel;
