import React from "react";
import {
  Container,
  Stack,
  Paper,
  Typography,
  Chip,
  Avatar,
  Divider,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

// Componente que exibe o historico dos checklists respondidos do projeto
const VerHistoricoChecklistProjeto = ({ lista }) => {
  const { t } = useTranslation();
  // Funcao que vai determinar a situacao do checklist
  const fnSituacaoChecklist = (situ) => {
    switch (situ) {
      case "A":
        return t("cadastro_projetos.approved");
      case "R":
        return t("cadastro_projetos.reproved");
      default:
        return t("cadastro_projetos.pending");
    }
  };
  // Funcao para determinar a cor
  const fnSituacaoChecklistCor = (situ) => {
    switch (situ) {
      case "A":
        return "primary";
      case "R":
        return "error";
      default:
        return "default";
    }
  };
  return (
    <Stack sx={{ maxHeight: "85vh" }}>
      <Container maxWidth="md">
        {lista.map((ele, idx) => {
          const {
            lider,
            descricao_projeto,
            data_avaliacao,
            descricao_checklist,
            mat_avaliador,
            nota_geral,
            planta,
            situacao,
            respostas,
          } = ele;
          return (
            <Paper sx={{ p: 1, m: 1 }} key={idx} elevation={4}>
              <Stack
                sx={{ my: 1 }}
                flexWrap="wrap"
                justifyContent="space-between"
                direction="row"
              >
                <HeaderHistoricoChecklist
                  titulo={t("cadastro_projetos.plant")}
                  valor={planta}
                />
                <HeaderHistoricoChecklist
                  titulo={t("cadastro_projetos.leaderProject")}
                  valor={lider}
                />
                <HeaderHistoricoChecklist
                  titulo={t("cadastro_projetos.descriptionChecklist")}
                  valor={descricao_checklist}
                />
              </Stack>
              <Stack
                sx={{ my: 1 }}
                justifyContent="space-between"
                direction="row"
              >
                <HeaderHistoricoChecklist
                  titulo={t("cadastro_projetos.matEvaluator")}
                  valor={mat_avaliador}
                />
                <HeaderHistoricoChecklist
                  titulo={t("cadastro_projetos.situation")}
                  valor={
                    <Chip
                      color={fnSituacaoChecklistCor(situacao)}
                      size="small"
                      label={fnSituacaoChecklist(situacao)}
                    />
                  }
                />
              </Stack>
              <Stack
                sx={{ my: 1 }}
                justifyContent="space-between"
                direction="row"
              >
                <HeaderHistoricoChecklist
                  titulo={t("cadastro_projetos.descriptionProject")}
                  valor={descricao_projeto}
                />
              </Stack>
              <Stack
                sx={{ my: 1 }}
                justifyContent="space-between"
                direction="row"
              >
                <HeaderHistoricoChecklist
                  titulo={t("cadastro_projetos.dateAvailable")}
                  valor={format(parseISO(data_avaliacao), "dd/MM/yy HH:mm:ss")}
                />

                <HeaderHistoricoChecklist
                  titulo={t("cadastro_projetos.overvallGrade")}
                  valor={
                    <Avatar sx={{ backgroundColor: "#005387" }}>
                      {nota_geral}
                    </Avatar>
                  }
                />
              </Stack>
              <Divider />
              <Typography variant="h6" align="center">
                {t("cadastro_projetos.responseChecklist")}
              </Typography>
              {respostas.map((resp, idx2) => {
                return (
                  <Stack sx={{ my: 2 }} key={`${idx}_${idx2}`}>
                    <HeaderHistoricoChecklist
                      titulo={t("cadastro_projetos.titleChecklist")}
                      valor={resp.titulo}
                    />
                    <HeaderHistoricoChecklist
                      titulo={t("cadastro_projetos.description")}
                      valor={resp.descricao}
                    />
                    <HeaderHistoricoChecklist
                      titulo={t("cadastro_projetos.response")}
                      valor={resp.resposta}
                    />

                    {resp.comentario.length > 0 && (
                      <HeaderHistoricoChecklist
                        titulo={t("cadastro_projetos.comment")}
                        valor={resp.comentario}
                      />
                    )}
                    <Divider
                      sx={{ backgroundColor: "#005387" }}
                      variant="middle"
                    />
                  </Stack>
                );
              })}
            </Paper>
          );
        })}
      </Container>
    </Stack>
  );
};
// Componente auxiliar para criar as linhas do header do historico de checklistist
const HeaderHistoricoChecklist = ({ titulo, valor }) => {
  return (
    <Stack>
      <Typography color="primary" variant="subtitle2">
        {titulo}
      </Typography>
      <Typography variant="caption">{valor}</Typography>
    </Stack>
  );
};
export default VerHistoricoChecklistProjeto;
