/*
Autor:  Marcos Felipe da Silva Jardim
versao: 1.1
data:   16-10-2020
Descricao: Um utilitario pra atender algumas necessidades da aplicação
----------------------------------------------------------------------
v1.0: 17-10-2019 Um utilitario pra atender algumas necessidades da aplicação
v1.1: 16-10-2020 Funcao para validar a variavel do usuario 

*/

import axios from "axios";
import { format, isDate, parseISO } from "date-fns";
import { enUS, es, it, ptBR } from "date-fns/locale";
import { toast } from "react-toastify";

class Utils {
  static enviarImagem(url, formData, callBack) {
    // Recebe a url, o campo do formulario e uma funcao de callback
    // recebendo os 3 campos (progress, oQuantoJaFoi, tamanhoTotal)
    // e retorna uma Promise
    return new Promise((resolve, reject) => {
      axios
        .post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let progresso = progressEvent.loaded;
            let total = progressEvent.total;
            callBack(progressEvent, progresso, total); // Callback passando os valores
          },
        })
        .then((resp) => {
          resolve(resp); // Resolvido
        })
        .catch((err) => {
          reject(err); // Deu ruim :|
        });
    });
  }
  // Metodo para converter os valores em monetario
  static converter(valor) {
    if (isNaN(valor)) return "R$ 0,00";
    return `R$ ${parseFloat(valor)
      .toFixed(2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`;
    // valor = valor.toString().replace(".", ","); // Substituindo ponto por virgula
    // let valorReverso = valor.split("").reverse(); // Reverte a string
    // let recebeConvertido = "";
    // let x = 0; // Contado a cada 3 vai incluir ponto
    // for (let i = 0; i < valorReverso.length; i++) {
    //   // Se o x for inferior a 4 entao vamos incrementar x e colocar o caractere
    //   if (x < 4) {
    //     x += 1;
    //     recebeConvertido += valorReverso[i];
    //   } else if (x % 3 === 0) {
    //     // X nao tem resto na divisao por tres, entao incluiremos o ponto e incrementamos x
    //     recebeConvertido += "." + valorReverso[i];
    //     x += 1;
    //     // X já e maior que 4 e nao e divisivel por 3, entao vamos incrementar x e adicionar o caractere a d
    //   } else {
    //     recebeConvertido += valorReverso[i];
    //     x += 1;
    //   }
    // }
    // //# Reverte novamente a string para o formato de ordem original
    // let valor2 = recebeConvertido.split("").reverse();
    // if (valor2[0] === ".") {
    //   valor2[0] = "";
    // } else if (valor2[0] === "-" && valor2[1] === ".") {
    //   valor2[1] = "";
    // }

    // valor2 = "R$ " + valor2.join("");
    // return valor2;
  }
  // Metodo para desconverter um valor de real para float
  static desconverter(valor) {
    valor = valor.replace(/\./g, ""); // Substituindo ponto por nada
    valor = valor.replace(",", "."); // Substitui a virgula por ponto
    valor = valor.replace("R$", "").trim();

    return valor;
  }
  // Metodo para validar o valor esperado de uma variavel
  static validarVariavel(variaveis, rota, variavel, valorEsperado) {
    // Nao existe a propriedade variaveis ou nao tem a rota
    if (!variaveis || !variaveis.hasOwnProperty(rota)) return false;

    // A rota nao tem a variavel definida
    if (!variaveis[rota].hasOwnProperty(variavel)) return false;

    // A variavel nao tem o valor esperado
    if (variaveis[rota][variavel] !== valorEsperado.toString()) return false;

    // O valor da variavel é o esperado
    return true;
  }
  // Metodo para converter data/hora
  static converterDataHora(valor) {
    // const [data, hora] = valor.trim().split(' ')
    // const dataRetorno =  new Date(`${data} 00:00:00`).toLocaleDateString();
    // return [dataRetorno, hora].join(' ')

    return valor
      ? format(isDate(valor) ? valor : parseISO(valor), "dd/MM/yy HH:mm")
      : valor;
  }
  // Metodo para converter o valor percentual
  static converterPercentual(valor) {
    let newValor = (valor * 100).toFixed(2);
    return newValor + " %";
  }
  static converterData(valor) {
    return isDate(valor)
      ? format(valor, "dd/MM/yyyy")
      : format(parseISO(valor), "dd/MM/yyyy");
    // let reg = /^[1-2][0-9][0-9][0-9]([0][1-9]|[1][0-2])([0-2][0-9]|[3][0-1])$/g;
    // let reg2 =
    //   /^[1-2][0-9][0-9][0-9]-([0][1-9]|[1][0-2])-([0-2][0-9]|[3][0-1])$/g;
    // if (reg.test(valor)) {
    //   // Regex para AAAAMMDD
    //   valor = valor.split("");
    //   let ano, mes, dia;
    //   ano = valor.splice(0, 4).join("");
    //   mes = valor.splice(0, 2).join("");
    //   dia = valor.join("");
    //   return `${dia}/${mes}/${ano}`;
    // } else if (reg2.test(valor)) {
    //   // Regex para AAAA-MM-DD
    //   valor = valor.split("-");
    //   let ano, mes, dia;
    //   ano = valor.splice(0, 1).join("");
    //   mes = valor.splice(0, 1).join("");
    //   dia = valor.join("");
    //   return `${dia}/${mes}/${ano}`;
    // }
    // return valor;
  }
  // Recurso para truncar texto
  static truncar(texto, tamanho = 99) {
    return `${texto.substr(0, tamanho)}...`;
  }
}
// Funcao para formatar ao select
export const formatarParaSelect = (arr, concatRotulo = "") => {
  const newArr = arr.map((ele) => ({
    label: `${Array.isArray(ele) ? ele[1] : ele}${concatRotulo}`,
    value: Array.isArray(ele) ? ele[0] : ele,
    key: Array.isArray(ele) ? ele[0] : ele,
  }));
  return newArr;
};
// Opcoes do toast
export const OPCOES_TOAST = {
  style: { backgroundColor: "#141414", color: "white" },
  autoClose: 5000,
};
// Funcao para registrar o acesso a uma pagina
export const registrarAcesso = async (rota) => {
  //{"data_acesso":"17/11/2021","hora_acesso":"17:39:01","pagina":"/atendimento_chamado","ip":"186.206.146.24"}
  const respIP = await axios.get("https://httpbin.org/ip");

  // const formData = new FormData();
  // formData.append(
  //   "dados",
  //   JSON.stringify({
  //     pagina: rota,
  //     ip: respIP?.data?.origin || "localhost",
  //   })
  // );
  const formData = { pagina: rota, ip: respIP?.data?.origin || "localhost" };

  const resp = await axios.post("/grava_acesso", formData);
  if (resp.status !== 200) {
    console.log("ERRO INTERNO NA GRAVAÇÃO DO ACESSO");
    return false;
  }
  // Veja se tem mensagem de erro
  if (resp.data.erro) {
    console.log(resp.data.erro);
    return false;
  }
  // Tudo certo nada a se fazer
  return true;
};

export const ToastErro = (erro) => {
  toast.dark(erro, {
    type: "error",
    toastId: "ERRO",
  });
};

export const sxStyleContainer = {
  minHeight: "60vh",
  maxHeight: "95vh",
  overflow: "auto",
  py: 1,
};

//
export const optTabela = {
  sxCabecalho: {
    p: 1,
    borderRadius: 0,
    backgroundColor: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.primary.contrastText,
  },
  styleCabe: { fontSize: "70%" },
  styleCorpo: { fontSize: "70%", whiteSpace: "nowrap" },
  styleRodape: { fontSize: "70%", whiteSpace: "nowrap" },
  calcularRodape: true,
  baixar_em_excel: "/baixar_em_excel",
};

// Recupera o objeto correto de acordo com o idioma
export const getLanguageToDate = (language) => {
  let obj = {};

  switch (language) {
    case "pt-BR":
      obj = {
        lang: ptBR,
      };
      break;
    case "en":
      obj = {
        lang: enUS,
      };
      break;
    case "it":
      obj = {
        lang: it,
      };
      break;
    default:
      obj = {
        lang: es,
      };
      break;
  }

  return obj;
};

export default Utils;
