import {
  TWTTP_GET_DADOS,
  TWTTP_GET_ITENS,
  TWTTP_LIMPAR_DADOS,
  TWTTP_SET_DRAWER,
  TWTTP_FECHAR_DRAWER,
  TWTTP_ADD_ITEM,
  TWTTP_GET_ITENS_FILTRO,
  TWTTP_ITENS_LIMPAR_FILTRO,
  TWTTP_GET_FORM_ENTREVISTA,
  TWTTP_LIMPAR_FORM_ENTREVISTA,
  TWTTP_FORM_ENTREVISTA_VALIDAR,
  TWTTP_ATUALIZA_STATUS,
} from "../../../redux/actions";

const INIT_STATE = {
  pagina: 1,
  dados: null,
  itens: [],
};

export default function twttpReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case TWTTP_GET_DADOS: // Recuperando os dados
      return {
        ...state,
        dados: action.data,
      };
    case TWTTP_GET_ITENS: // Recuperando os itens do formulario
      return {
        ...state,
        pagina: action.data.pagina,
        itens: action.data.data,
      };
    case TWTTP_LIMPAR_DADOS: // Limpa todos os dados na saida
      return INIT_STATE;
    case TWTTP_SET_DRAWER: // para setar o drawer
      return {
        ...state,
        drawer: action.data,
      };
    case TWTTP_FECHAR_DRAWER: // Para fechar o drawer
      return {
        ...state,
        drawer: null,
      };
    case TWTTP_ADD_ITEM: // Para adicionar um item
      return (() => {
        const { totalPorStatus } = state.dados;
        // Incrementa no status A
        totalPorStatus["A"] += 1;

        return {
          ...state,
          dados: {
            ...state.dados,
            totalPorStatus: totalPorStatus,
          },
          itens: [action.data, ...state.itens],
        };
      })();
    case TWTTP_GET_ITENS_FILTRO: // Para colocar os itens no filtro
      return {
        ...state,
        filtro: action.data.data,
        isFiltroAvancado: action.data.filtroAvancado,
      };
    case TWTTP_ITENS_LIMPAR_FILTRO: // Para limpar o filtro
      return {
        ...state,
        filtro: null,
        isFiltroAvancado: null,
      };
    case TWTTP_GET_FORM_ENTREVISTA: // Para obter a entrevista
      return {
        ...state,
        formEntrevista: action.data,
      };
    case TWTTP_LIMPAR_FORM_ENTREVISTA: // Para limpar o formulario de entrevista
      return {
        ...state,
        formEntrevista: null,
      };
    case TWTTP_FORM_ENTREVISTA_VALIDAR: // Para validar a inclusao da entrevista
      return (() => {
        const { dados, itens } = state;
        // // Decrementa o status aberto se o status retornado for P
        // if(action.data.status === 'P'){
        //     dados.totalPorStatus['A'] -= 1;
        // }
        // // Se o status retornado for Em Exeucao o Em entrevista deve ser decrementado
        // if(action.data.status === 'E'){
        //     dados.totalPorStatus['P'] -= 1;
        // }
        // Incrementa o status recebido
        dados.totalPorStatus[action.data.status] += 1;
        // Procurar o item pelo codigo do id_twttp e substitui-lo
        const copiaItens = itens.map((ele) => {
          if (String(ele.id_twttp) === String(action.data.id_twttp)) {
            // Pega o status do twttp (status atual)
            // e decrementa
            dados.totalPorStatus[ele.status] -= 1;
            return action.data;
          }
          return ele;
        });

        return {
          ...state,
          dados: {
            ...state.dados,
            totalPorStatus: dados.totalPorStatus,
          },
          itens: copiaItens,
        };
      })();
    case TWTTP_ATUALIZA_STATUS: // Para atualizar o status do twttp
      return (() => {
        const { dados, itens } = state;
        // // Decrementa o status caso o novo seja um dos dois
        // if(['E','R'].includes(action.data.status) ){
        //     dados.totalPorStatus['V'] -= 1;
        // }
        // // Decrementa o status caso o novo seja Validacao
        // if(action.data.status === 'V' ){
        //     dados.totalPorStatus['S'] -= 1; // Decrementa em execucao
        // }

        // Incrementa o status atual
        dados.totalPorStatus[action.data.status] += 1;

        // Procurar o item pelo codigo do id_twttp e substitui-lo
        const copiaItens = itens.map((ele) => {
          if (String(ele.id_twttp) === String(action.data.id_twttp)) {
            // Pega o status do twttp (status atual)
            // e decrementa
            dados.totalPorStatus[ele.status] -= 1;
            return action.data;
          }
          return ele;
        });

        return {
          ...state,
          dados: {
            ...state.dados,
            totalPorStatus: dados.totalPorStatus,
          },
          itens: [...copiaItens],
        };
      })();
    default:
      return state;
  }
}
