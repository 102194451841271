import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import React from "react";
import _ from "lodash";
import { Body1, Icone } from "../../../components";
import Utils from "../../../utils/utils";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const OPTION = ["PROVENTO", "DESCONTO"];

function ValuesVacation({ valuesVacation }) {
  const { t } = useTranslation();
  const totalProvent = _.sumBy(
    _.filter(valuesVacation, (ele) => ele.tipo?.search(OPTION[0]) !== -1),
    (ele) => ele.valor
  );
  const totalDiscount = _.sumBy(
    _.filter(valuesVacation, (ele) => ele.tipo?.search(OPTION[1]) !== -1),
    (ele) => ele.valor
  );

  const total = totalProvent - totalDiscount;

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<Icone icone="ExpandMore" />}
        aria-controls="panel1a-content"
      >
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Body1>{t("ferias.titleVacation")}</Body1>
          <Body1
            fontWeight="bold"
            sx={{ color: ({ palette }) => palette.primary.main }}
          >
            {Utils.converter(total)}
          </Body1>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <List dense disablePadding>
          {valuesVacation?.map((ele, idx) => (
            <ValuesVacationItem
              {...ele}
              key={idx}
              isLast={idx === valuesVacation?.length - 1}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

const ValuesVacationItem = ({
  descricao,
  mat,
  pagamento,
  valor,
  verba,
  tipo,
  isLast,
}) => {
  return (
    <ListItem disableGutters disablePadding divider={!isLast}>
      <ListItemText
        primary={
          <ValuesVacationPrimary
            value={valor}
            verb={verba}
            isDiscount={tipo?.search(OPTION[1]) !== -1}
          />
        }
        secondary={
          <ValuesVacationSecondary
            description={descricao}
            dateOfPayment={pagamento}
          />
        }
      />
    </ListItem>
  );
};

const ValuesVacationPrimary = ({ isDiscount, value, verb }) => {
  const sxValue = {};
  if (isDiscount) {
    sxValue["color"] = red[800];
  }
  const { t } = useTranslation();

  return (
    <Stack sx={{ mb: 1 }} direction="row" justifyContent="space-between">
      <Body1 sx={sxValue}>{Utils.converter(value)}</Body1>

      <Chip
        size="small"
        color="info"
        variant="filled"
        label={
          <Body1>
            {t("ferias.verb")} {verb}
          </Body1>
        }
      />
    </Stack>
  );
};

const ValuesVacationSecondary = ({ description, dateOfPayment }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Body1>{description}</Body1>
      <Body1>{Utils.converterData(dateOfPayment)}</Body1>
    </Stack>
  );
};

export default ValuesVacation;
