import { Stack, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { EntradaForm, H6 } from "../../../components";
import * as yup from "yup";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { useToggle } from "react-use";
import { useDispatch } from "react-redux";
import { rnciNcpQualidade } from "./rnci-ncp-actions";
import { useFetch } from "../../../hooks";
import { ToastErro } from "../../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

//
const campos = {
  projeto: "projeto",
  codigoProjeto: "codigo_projeto",
  acoes: "acoes",
};

function RnciFormQualidade({ id }) {
  const { data, error, setFetch } = useFetch("/rnci_ncp", "GET");
  const history = useHistory();
  const { t } = useTranslation();
  let rnci = id;
  if (history?.location?.state) {
    rnci = history.location.state;
  }

  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);
  //
  useEffect(() => {
    setFetch({ codigos_projeto: true });
  }, [setFetch]);

  //
  return (
    <Stack sx={{ p: 1 }}>
      <H6>{t("rnci_ncp.tituloFormQualidade")}</H6>
      <Form id={rnci} codigos={data?.codigos_projeto} />
    </Stack>
  );
}
//
const Form = ({ id, codigos }) => {
  const isMobile = useTheme()?.isMobile;
  const history = useHistory();
  const [viewCodProj, setViewCodProj] = useState(false);
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // Funcao que exibe novos dados somente se
  // a opção de carregamento de projeto for
  // igual a S (Sim)
  const fnViewIf = useCallback(
    (val) => {
      if (val === "S") {
        setViewCodProj(true);
        return codigos;
      }
      setViewCodProj(false);
      return [];
    },
    [codigos, setViewCodProj]
  );

  //

  const schema = [
    {
      type: "radio",
      orientation: "horizontal",
      name: campos.projeto,
      placeholder: t("rnci_ncp.formPlaceholderProjeto"),
      label: t("rnci_ncp.formProjeto"),
      itens: [
        ["S", t("rnci_ncp.formSim")],
        ["N", t("rnci_ncp.formNao")],
      ],
      icon: "SwapHoriz",
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "select",
      name: campos.codigoProjeto,
      placeholder: t("rnci_ncp.formPlaceholderCodigoProjeto"),
      label: t("rnci_ncp.formCodigoProjeto"),
      autoFormat: true,
      exibirSe: fnViewIf,
      itens: [],
      icon: "AccountTree",
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "textarea",
      extraProps: {
        multiline: true,
        minRows: 3,
      },
      name: campos.acoes,
      placeholder: t("rnci_ncp.formPlaceholderAcoes"),
      label: t("rnci_ncp.formAcoes"),
    },
  ];
  //
  const schemaMessageError = {
    [campos.projeto]: t("rnci_ncp.formErrorProjeto"),
    [campos.acoes]: t("rnci_ncp.formErrorAcoes"),
  };

  let schemaValidator = {
    [campos.projeto]: yup.string().min(1).required(),
    [campos.acoes]: obterValidador(VALIDADOR_TIPO.texto, 3),
  };
  // Se tem codigo de projeto a enviar
  if (viewCodProj) {
    schemaValidator[campos.codigoProjeto] = obterValidador(
      VALIDADOR_TIPO.selectUnico
    );
    schemaMessageError[campos.codigoProjeto] = t(
      "rnci_ncp.formErrorCodigoProjeto"
    );
  }
  schemaValidator = yup.object().shape(schemaValidator);
  //
  const onSubmit = useCallback(
    (val) => {
      // const formData = new FormData();
      const obj = {
        codigo_rnci: id,
        projeto: val[campos.projeto],
        acoes: val[campos.acoes],
      };
      if (obj.projeto === "S") {
        obj.codigo_projeto = val[campos.codigoProjeto]?.value || "";
      }
      // formData.append("dados", JSON.stringify(obj));
      //
      let fn = () => {};
      if (isMobile) {
        fn = () => history.goBack();
      }
      dispatch(rnciNcpQualidade(obj, setWait, fn));
    },
    [dispatch, setWait, id, isMobile, history]
  );

  return (
    <EntradaForm
      exibirSe={{
        ouvir: campos.projeto,
        atualizar: campos.codigoProjeto,
      }}
      schema={schema}
      schemaMessageError={schemaMessageError}
      schemaValidator={schemaValidator}
      onSubmit={onSubmit}
      wait={wait}
    />
  );
};

RnciFormQualidade.rota = "/rnci_form_qualidade";

export default RnciFormQualidade;
