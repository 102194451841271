import { motion } from "framer-motion";
import { useFetch } from "../../../hooks";
import { ToastErro } from "../../../utils/utils";
import { useEffect } from "react";
import { Paper, Stack, Button, Chip } from "@mui/material";
import { EntradaForm, Icone } from "../../../components";
import { useCallback } from "react";
import * as yup from "yup";
import _ from "lodash";
import { toast } from "react-toastify";
import { URL } from "./painel-de-notificacao-utils";
import { useTranslation } from "react-i18next";

const EnviarNotificacaoPorSetor = motion(
  ({
    fecharModal,
    setor,
    dados,
    setoresSelecionados,
    resetSelecionados,
    toggle,
  }) => {
    const { t } = useTranslation();
    const { wait, data, error, setFetch } = useFetch(URL[1], "POST");

    useEffect(() => {
      if (error) ToastErro(error);
    }, [error]);
    //
    useEffect(() => {
      if (data && data.sucesso) {
        toast.dark(data.sucesso, {
          type: "success",
        });
        if (fecharModal) fecharModal();
      }
    }, [data, fecharModal]);

    const schema = [];
    let schemaValidator;
    let schemaMessageError = {
      titulo: t("painel_de_notificacao.errorTitle"),
      descricao: t("painel_de_notificacao.errorDescription"),
    };

    if (setor) {
      schemaMessageError.setor = t("painel_de_notificacao.errorSector");
      schema.push({
        name: "setor",
        type: "select",
        label: t("painel_de_notificacao.labelSector"),
        itens: setor,
        isMulti: true,
        autoFormat: true,
      });

      schemaValidator = yup
        .object()
        .shape({
          setor: yup
            .array()
            .of(
              yup.object().shape({
                label: yup.string().min(1).required(),
                value: yup.string().min(1).required(),
              })
            )
            .min(1)
            .required(),
          titulo: yup.string().min(3).required(),
          descricao: yup.string().min(3).required(),
        })
        .required();
    } else {
      schemaValidator = yup
        .object()
        .shape({
          titulo: yup.string().min(3).required(),
          descricao: yup.string().min(3).required(),
        })
        .required();
    }

    schema.push({
      name: "titulo",
      label: t("painel_de_notificacao.labelTitle"),
      placeholder: t("painel_de_notificacao.placeholderTitle"),
      type: "text",
    });
    schema.push({
      name: "descricao",
      label: t("painel_de_notificacao.labelDescription"),
      placeholder: t("painel_de_notificacao.placeholderDescription"),
      type: "textarea",
      extraProps: {
        multiline: true,
        minRows: 2,
        sx: { mt: 1 },
      },
      counter: true,
    });

    const fnEnviar = useCallback(
      (val) => {
        const _setor = setoresSelecionados
          ? setoresSelecionados
          : _.map(val.setor, (item) => item.value);

        const _mat_planta = _.map(
          _.filter(dados, (vl) => _setor.includes(vl.setor)),
          (item) => `${item.matricula}_${item.planta}`
        );
        const _dados = {
          titulo: val.titulo,
          descricao: val.descricao,
          matricula_planta: _mat_planta,
        };
        setFetch(_dados);
      },
      [dados, setFetch, setoresSelecionados]
    );

    return (
      <Paper elevation={5} sx={{ p: 1 }}>
        <Stack>
          {resetSelecionados && (
            <Button
              sx={{ mb: 1 }}
              variant="outlined"
              onClick={resetSelecionados}
              startIcon={<Icone icone="Delete" />}
            >
              {t("painel_de_notificacao.labelBtnRemoveSector")}
            </Button>
          )}
          <Stack sx={{ mb: 1 }} spacing={1} direction="row" flexWrap="wrap">
            {setoresSelecionados?.map((ele) => (
              <Chip
                label={ele}
                key={ele}
                color="success"
                onDelete={() => toggle(ele)}
              />
            ))}
          </Stack>

          <EntradaForm
            schema={schema}
            schemaMessageError={schemaMessageError}
            schemaValidator={schemaValidator}
            onSubmit={fnEnviar}
            wait={wait}
          />
        </Stack>
      </Paper>
    );
  }
);

export default EnviarNotificacaoPorSetor;
