import { useToggle } from "react-use";
import { ACTIONS, ROTA, formatColabForSelect } from "./documentos-utils";
import { PLANTAS } from "../../../config";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { useCallback } from "react";
import axios from "axios";
import * as yup from "yup";
import { ToastErro } from "../../../utils/utils";
import { EntradaForm, H6 } from "../../../components";
import { useTranslation } from "react-i18next";

//
const EditarVisibilidade = ({
  visivel_por,
  nome,
  id_arquivo,
  colaboradores,
  permissao_visualizacao,
  dispatch,
  matricula,
  planta,
}) => {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const listColabs = formatColabForSelect(colaboradores);
  const defaultColabs = listColabs.filter(
    (colab) =>
      permissao_visualizacao.includes(colab[0]) &&
      colab[0] !== `${matricula}_${planta}`
  );

  //
  const schema = [
    {
      type: "select",
      name: "visivel_por",
      label: t("documentos.labelEditVisibilityBy"),
      defaultValue: visivel_por,
      isMulti: true,
      autoFormat: true,
      itens: PLANTAS,
    },
    {
      type: "select",
      name: "mat_planta_view",
      label: t("documentos.labelEditFuncPermissionView"),
      defaultValue: defaultColabs,
      isMulti: true,
      autoFormat: true,
      itens: listColabs,
    },
  ];
  const schemaValidator = yup.object().shape({
    visivel_por: obterValidador(VALIDADOR_TIPO.selectMultiplo),
  });

  const schemaMessageError = {
    visivel_por: t("documentos.errorVisibilityBy"),
  };
  //
  const onSubmit = useCallback(
    async (val) => {
      const visivelPor = val.visivel_por.map((ele) => ele.value).join(",");
      const mat_planta_view = val?.mat_planta_view
        ? val.mat_planta_view.map((ele) => ele.value)
        : [];
      // Atualizando a visibilidade do item
      try {
        setWait();
        const resp = await axios.put(ROTA, {
          id_arquivo,
          nome: nome,
          visivel_por: visivelPor,
          mat_planta_view: mat_planta_view,
        });
        dispatch({ data: resp.data.data[0], type: ACTIONS.UPD });
        dispatch({ type: ACTIONS.FECHAR_MODAL });
      } catch (error) {
        if (error.response?.data?.message) {
          ToastErro(error.response.data.message);
        } else {
          ToastErro(error);
        }
      } finally {
        setWait();
      }
    },
    [id_arquivo, nome, setWait, dispatch]
  );

  return (
    <>
      <H6>{t("documentos.titleEditVisibility")}</H6>
      <EntradaForm
        schema={schema}
        schemaValidator={schemaValidator}
        schemaMessageError={schemaMessageError}
        wait={wait}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default EditarVisibilidade;
