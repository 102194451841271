import { ButtonBase, Grow, Paper, Stack } from "@mui/material";
import { Body1, Caption, Icone } from "../../../components";
import _ from "lodash";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const PainelSuperior = ({ data, onClick, isValidator }) => {
  const { t } = useTranslation();
  const opcoes = [
    {
      titulo: t("painel_de_notificacao.titleCanReceiveNotifications"),
      descricao: _.filter(data, (val) => val.is_token_mobile === "S").length,
      icone: "NotificationsActive",
      onClick: () => {
        onClick("S");
      },
      sx: {
        backgroundColor: (theme) => theme.palette.info.light,
      },
      isSelecionado: "S" === isValidator,
    },
    {
      titulo: t("painel_de_notificacao.titleCannotReceiveNotifications"),
      descricao: _.filter(data, (val) => val.is_token_mobile === "N").length,
      icone: "NotificationsOff",
      onClick: () => {
        onClick("N");
      },
      sx: {
        backgroundColor: (theme) => theme.palette.error.light,
      },
      isSelecionado: "N" === isValidator,
    },
  ];

  return (
    <Stack spacing={1}>
      <Stack spacing={1} direction="row">
        {opcoes.map((ele, idx) => (
          <PainelSuperiorItem {...ele} key={idx} />
        ))}
      </Stack>
      <Grow in={isValidator} unmountOnExit>
        <ButtonBase sx={{ width: "100%" }} onClick={() => onClick(null)}>
          <Paper
            sx={{
              width: "100%",
              p: 1,
              mb: 1,
              backgroundColor: "#b71c1c",
              color: "white",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Icone icone="Delete" />
              <Body1>{t("painel_de_notificacao.labelClearFilter")}</Body1>
              <div />
            </Stack>
          </Paper>
        </ButtonBase>
      </Grow>
    </Stack>
  );
};

const PainelSuperiorItem = memo(
  ({ isSelecionado, titulo, sx, onClick, icone, descricao }) => {
    return (
      <ButtonBase sx={{ flex: 1 }} onClick={onClick}>
        <Paper
          sx={{ p: 1, ...sx, width: "100%" }}
          elevation={isSelecionado ? 8 : 1}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ height: "100%" }}
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Body1 align="center">{titulo}</Body1>
            <Icone icone={icone} />
            <Caption>{descricao}</Caption>
          </Stack>
        </Paper>
      </ButtonBase>
    );
  }
);

export default PainelSuperior;
