/**
 * Centralização dos seletores do NCP
 */
import { createSelector } from "reselect";
import _ from "lodash";

export const selectColabs = (state) => state?.ncp?.colaboradores;
export const selectArea = (state) => state?.ncp?.area;
export const selectAreaModelo = (state) => state?.ncp?.area_modelo;
export const selectTurno = (state) => state?.ncp?.turnos;
export const selectPn = (state) => state?.ncp?.pn;
export const selectOperacao = (state) => state?.ncp?.operacao;

//
const selectNcpGetFilterData = (state) => state?.ncp?.filtroData;

export const selectNcpPermissionCancelStatus = (state) =>
  state?.usuario?.variaveis?.includes("EXCLUIR_NCP");

export const selectNcp = (state) => state?.ncp;
export const selectNcpDadosPagina = (state) => state?.ncp?.dados;
export const selectModal = (state) => state?.ncp?.modal;
export const selectUsuario = (state) => state?.usuario;
export const selectNcpGetFilter = (state) => state?.ncp?.filtro;
export const selectNcpTotalPerStatus = (state) => state?.ncp?.total_por_status;
export const selectNcpTotalPerPageFilter = (state) =>
  state?.ncp?.total_pagina_filtro;
export const selectNcpPageActualyFilter = (state) =>
  state?.ncp?.pagina_atual_filtro;

export const seletorIsArvore = createSelector(selectUsuario, (usuarioInfo) => {
  if (usuarioInfo) {
    const { variaveis_globais, usuario } = usuarioInfo;
    return variaveis_globais?.RNCI_MOVER_QUALIDADE?.split(",").includes(
      usuario?.arvore
    );
  }
  return false;
});

// Seletor de filtro da pagina
export const seletorPageFilter = createSelector(
  selectNcpTotalPerPageFilter,
  selectNcpPageActualyFilter,
  (totalPage, pageActualy) => {
    return {
      totalPaginaFiltro: totalPage,
      paginaAtualFiltro: pageActualy,
    };
  }
);
// Retorna os dados do filtro, com paginação
export const seletorNcpGetFilterData = createSelector(
  seletorPageFilter,
  selectNcpGetFilterData,
  (pageInfo, data) => {
    const { paginaAtualFiltro } = pageInfo;
    if (!paginaAtualFiltro) {
      return null;
    }
    const pageInitial = (paginaAtualFiltro - 1) * 10;
    const newData = _.clone(data).splice(pageInitial, 10);

    return newData;
  }
);

/// Retorna todos os tipos de filtro que podem ser utilizados no filtro avancado
export const seletorNcpOptionsFilter = createSelector(selectNcp, (obj) => {
  const { area, colaboradores, operacao, pn, turnos, codigo_ncp, status } = obj;

  if (!colaboradores) return null;

  const matriculas = _.map(colaboradores, (row) => [
    row[0].split("_")[0],
    row[1],
  ]);

  return {
    area,
    matriculas,
    operacao,
    pn,
    turnos,
    codigo_ncp,
    status,
  };
});
