import React, { useMemo, useState, useEffect, memo, useCallback } from "react";
import _ from "lodash";
import { registrarAcesso } from "../../utils/utils";
import {
  useMediaQuery,
  Typography,
  Container,
  Paper,
  List,
  ListItemText,
  ListItemButton,
  Button,
  Hidden,
  Box,
} from "@mui/material";
import { Menu, MenuItem, useTheme, Chip, Stack } from "@mui/material";
//
import PostAddIcon from "@mui/icons-material/PostAdd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BuildIcon from "@mui/icons-material/Build";
import PageviewIcon from "@mui/icons-material/Pageview";
import EditIcon from "@mui/icons-material/Edit";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import EngineeringIcon from "@mui/icons-material/Engineering"; // Icone seguranca
import LocalHospitalIcon from "@mui/icons-material/LocalHospital"; // Icone hospital
import LocalFloristIcon from "@mui/icons-material/LocalFlorist"; // Icone seguranca
import DoDisturbIcon from "@mui/icons-material/DoDisturb"; // Icone status C (cancelado)
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver"; // Icone aberto (A)
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Em analise (S)
import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // Encerrado (E)
import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

//
import {
  cartaoSegurancaGetCartao,
  cartaoSegurancaGetDados,
  cartaoSegurancaSetDrawer,
  cartaoSegurancaFecharDrawer,
  cartaoSegurancaSetFiltroPorStatus,
  cartaoSegurancaSetFiltroPorTipoCartao,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { format, parseISO } from "date-fns";
import { SideBar, FabExtend, Icone, SearchSelect } from "../../components";
import { motion, AnimatePresence } from "framer-motion";
import { yellow, green, red, orange } from "@mui/material/colors";

import {
  seletorCardSecurity,
  seletorCardSecurityCardStatus,
  seletorCardSecurityFilterStatus,
  seletorCardSecurityTypeFilter,
  seletorCardSecurityTypeFilterQtdStatus,
} from "./cartao-seguranca-selectors";
import { DrawerDialog } from "../../extra-components";
import CardSecurityModal from "./card-security-modal";
import { useTranslation } from "react-i18next";

// Funcao para obter os status
const getStatus = (st, t) => {
  switch (st) {
    case "I":
      return t("cartao_seguranca.statusInclude");
    case "C":
      return t("cartao_seguranca.statusCancel");
    case "A":
      return t("cartao_seguranca.statusOpen");
    case "S":
      return t("cartao_seguranca.statusAnalisys");
    default: // Caso E (encerrado)
      return t("cartao_seguranca.statusClosed");
  }
};
const getCorStatus = (st) => {
  switch (st) {
    case "A":
      return { backgroundColor: green[900], color: "white" };
    case "C":
      return { backgroundColor: red["A700"], color: "white" };
    case "I":
      return { backgroundColor: yellow[500], color: "black" };
    case "S":
      return { backgroundColor: orange[900], color: "white" };
    default: // Caso E (encerrado)
      return { backgroundColor: red[900], color: "white" };
  }
};
// Funcao para obter a dica sobre o filtro
const getTitulo = (st, t) => {
  switch (st) {
    case "A":
      return t("cartao_seguranca.statusOpenTitle");
    case "I":
      return t("cartao_seguranca.statusIncludeTitle");
    case "C":
      return t("cartao_seguranca.statusCancelTitle");
    case "S":
      return t("cartao_seguranca.statusAnalisysTitle");
    default: // E
      return t("cartao_seguranca.statusClosedTitle");
  }
};
// Funcao que recupera o icone que representa o status
const getIcone = (st) => {
  switch (st) {
    case "A":
      return <RecordVoiceOverIcon />;
    case "I":
      return <PostAddIcon />;
    case "C":
      return <DoDisturbIcon />;
    case "S":
      return <AccessTimeIcon />;
    case "SEG": // Cartao seguranca
      return <EngineeringIcon />;
    case "AMB": // Cartao ambienttal
      return <LocalFloristIcon />;
    case "SAU": // Cartao saude
      return <LocalHospitalIcon />;
    default: // E
      return <ThumbUpIcon />;
  }
};

function CartaoSeguranca() {
  const { t } = useTranslation();
  const [filtroCartao, setFiltroCartao] = useState(null);

  const dispatch = useDispatch();
  const cartaoSeguranca = useSelector(seletorCardSecurity);
  // Verifica se e mobile
  const isXl = useMediaQuery(useTheme()?.breakpoints?.up("xl"));
  // Registrando acesso a pagina
  useEffect(() => registrarAcesso(window.location.pathname), []);

  // UseEffect para obter os dados dos cartoes
  useEffect(() => {
    dispatch(cartaoSegurancaGetCartao());
    dispatch(cartaoSegurancaGetDados());
  }, [dispatch]);
  // Funcao para fechar o drawer
  const fnFecharDrawer = () => dispatch(cartaoSegurancaFecharDrawer());

  // Cria um objeto de selecao para os campos (isto é repassado ao painel superior para os filtros)
  const objectSelect = {
    mat_lider: cartaoSeguranca?.cartoes
      ? _.uniq(_.map(cartaoSeguranca.cartoes, "mat_lider"))
      : [],
    id: cartaoSeguranca?.cartoes ? _.map(cartaoSeguranca.cartoes, "id") : [],
    turno: cartaoSeguranca?.cartoes
      ? _.uniq(_.map(cartaoSeguranca.cartoes, "turno"))
      : [],
    area: cartaoSeguranca?.cartoes
      ? _.uniq(_.map(cartaoSeguranca.cartoes, "area"))
      : [],
  };
  // Uma funcao de callback para ativar o filtro por tipo de selecao docartao
  const setBuscaRefinada = useCallback(
    (tipoCampo, valorCampo) => {
      const filtroAplicado = _.filter(
        cartaoSeguranca?.cartoes,
        (val) => String(val[tipoCampo]) === String(valorCampo)
      );

      setFiltroCartao(filtroAplicado);
    },
    [cartaoSeguranca?.cartoes]
  );

  //
  return (
    <Stack direction="row">
      <SideBar barraIntegrada />

      <Container maxWidth={isXl ? "xl" : "lg"}>
        {cartaoSeguranca?.drawer && (
          <DrawerDialog
            fnGetCorpo={() => (
              <CardSecurityModal
                modal={cartaoSeguranca.drawer}
                closeModal={fnFecharDrawer}
              />
            )}
            fecharModal={fnFecharDrawer}
          />
        )}

        <ToastContainer position="bottom-center" />
        <Typography align="center" variant="h6">
          {t("cartao_seguranca.titlePage")}
        </Typography>
        <PainelSuperior
          objectSelect={objectSelect}
          setBuscaRefinada={setBuscaRefinada}
        />
        {cartaoSeguranca?.cartoes && (
          <Lista
            cartoes={
              filtroCartao?.length > 0 ? filtroCartao : cartaoSeguranca.cartoes
            }
            cartao_filtro_status={cartaoSeguranca.cartao_filtro_status}
            cartao_filtro_tipo={cartaoSeguranca.cartao_filtro_tipo}
          />
        )}
      </Container>
    </Stack>
  );
}

// Componente que exibe o painel superior
const PainelSuperior = memo(({ setBuscaRefinada, objectSelect }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fnOnClick = () =>
    dispatch(
      cartaoSegurancaSetDrawer({ tipo: CardSecurityModal.modal.ADD_CARTAO })
    );
  // Obtendo os status dos cartoes
  const cartao_status = useSelector(seletorCardSecurityCardStatus);
  // Status caso o filtro de tipo tenha sido aplicado (isto refaz o calculo de filtros por status)
  const cartao_filtro_tipo_qtd_status = useSelector(
    seletorCardSecurityTypeFilterQtdStatus
  );
  // Unico objeto que vai determinar as quantidades por status
  const qtdPorStatus = cartao_filtro_tipo_qtd_status || cartao_status;

  // Filtro aplicado
  const cartao_filtro_status = useSelector(seletorCardSecurityFilterStatus);
  const cartao_filtro_tipo = useSelector(seletorCardSecurityTypeFilter);
  // Cria objeto com propriedades para montar o painel
  const objPainel = useMemo(
    () =>
      qtdPorStatus
        ? _.reverse(
            Object.keys(qtdPorStatus).map((key) => ({
              ...getCorStatus(key),
              texto: getStatus(key, t),
              titulo: getTitulo(key, t),
              icone: getIcone(key),
              idx: key, // Indice
              total: qtdPorStatus[key],
              onClick: () => dispatch(cartaoSegurancaSetFiltroPorStatus(key)),
            }))
          )
        : [],
    [qtdPorStatus, t, dispatch]
  );
  // Separando o painel em 2 partes
  const [p1, p2] = _.chunk(objPainel, 3);

  // Cria um objeto para filtro do painel por tipo de cartao
  const objPainelFiltroTipo = [
    {
      filtroValor: "Segurança",
      texto: t("cartao_seguranca.textCardTypeSecurity"),
      icone: getIcone("SEG"),
      filtroAtivo: cartao_filtro_tipo?.includes("Segurança"),
    },
    {
      filtroValor: "Ambiental",
      texto: t("cartao_seguranca.textCardTypeEnvironmental"),
      icone: getIcone("AMB"),
      filtroAtivo: cartao_filtro_tipo?.includes("Ambiental"),
    },
    {
      filtroValor: "Saúde",
      texto: t("cartao_seguranca.textCardTypeHealth"),
      icone: getIcone("SAU"),
      filtroAtivo: cartao_filtro_tipo?.includes("Saúde"),
    },
  ];

  // Objeto que determina os tipos de consultas que podem ser feitas pela SearchSelect
  const typesQueys = [
    {
      id: "mat_lider",
      title: t("cartao_seguranca.searchTitleLeader"),
      node: <Icone icone="Person" />,
    },
    {
      id: "id",
      title: t("cartao_seguranca.searchTitleCode"),
      node: <Icone icone="Search" />,
    },
    {
      id: "turno",
      title: t("cartao_seguranca.searchTitleTurn"),
      node: <Icone icone="Search" />,
    },
    {
      id: "area",
      title: t("cartao_seguranca.searchTitleArea"),
      node: <Icone icone="Search" />,
    },
  ];

  return (
    <Stack>
      <Stack sx={{ my: 1 }} spacing={1} direction={{ xs: "column", md: "row" }}>
        <Stack flex={1} direction="row" spacing={1} alignItems="center">
          {_.reverse(p2)?.map((ele, idx) => (
            <PainelItem
              key={idx}
              {...ele}
              cartao_filtro_status={cartao_filtro_status}
            />
          ))}
        </Stack>
        <Stack flex={1} direction="row" spacing={1} alignItems="center">
          {_.reverse(p1)?.map((ele, idx) => (
            <PainelItem
              key={idx}
              {...ele}
              cartao_filtro_status={cartao_filtro_status}
            />
          ))}
        </Stack>
      </Stack>

      <AnimatePresence>
        {cartao_filtro_status && (
          <PainelItemBotaoLimparFiltro key="botao_limpa_status" />
        )}
      </AnimatePresence>
      <Stack sx={{ width: "100%", overflow: "auto" }}>
        <Stack
          alignItems="center"
          direction={{ xs: "row", md: "row-reverse" }}
          spacing={1}
        >
          <FabExtend
            title={t("cartao_seguranca.titleFabAdd")}
            color="primary"
            icone="PostAdd"
            text={t("cartao_seguranca.textFabAdd")}
            onClick={fnOnClick}
          />
          <Hidden smDown>
            <Box flex={1}>
              <SearchSelect
                types={typesQueys}
                onChange={setBuscaRefinada}
                objectSelect={objectSelect}
              />
            </Box>
          </Hidden>
          {objPainelFiltroTipo.map((ele, idx) => (
            <Chip
              key={idx}
              variant={ele.filtroAtivo ? "filled" : "outlined"}
              color={ele.filtroAtivo ? "primary" : "default"}
              label={ele.texto}
              onClick={() =>
                dispatch(cartaoSegurancaSetFiltroPorTipoCartao(ele.filtroValor))
              }
              icon={ele.icone}
              deleteIcon={
                ele.filtroAtivo ? (
                  <CheckIcon />
                ) : (
                  <CircleIcon sx={{ visibility: "hidden" }} />
                )
              }
              onDelete={() => {}}
            />
          ))}
        </Stack>
        <Hidden mdUp>
          <Stack sx={{ mt: 1 }}>
            <SearchSelect
              types={typesQueys}
              onChange={setBuscaRefinada}
              objectSelect={objectSelect}
            />
          </Stack>
        </Hidden>
      </Stack>
    </Stack>
  );
});
// Compoente do botao de limpesa de filtro por status
const PainelItemBotaoLimparFiltro = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Verifica se e mobile
  const isMobile = useTheme()?.breakpoints?.values["sm"] > window.innerWidth;

  return (
    <motion.div
      style={{ display: "flex", justifyContent: "center" }}
      initial={{ scale: 0.01 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0.01 }}
    >
      <Paper
        title={t("cartao_seguranca.titleButtonCleanFilter")}
        sx={{
          p: 1,
          width: isMobile ? "calc(100% - 16px)" : "calc(60% - 16px)",
          cursor: "pointer",
          mb: 1,
          backgroundColor: "#b71c1c",
          color: "white",
        }}
        onClick={() => {
          dispatch(cartaoSegurancaSetFiltroPorStatus(null));
        }}
      >
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <FilterAltOffIcon sx={{ color: "white" }} />
          <Typography variant="subtitle2" fontWeight="bold">
            {t("cartao_seguranca.textButtonCleanFilter")}
          </Typography>
          <Typography variant="subtitle2" fontWeight="bold"></Typography>
        </Stack>
      </Paper>
    </motion.div>
  );
};
// Item do painel superior
const PainelItem = memo((props) => {
  const {
    titulo,
    backgroundColor,
    color,
    idx,
    cartao_filtro_status,
    onClick,
    icone,
    texto,
    total,
  } = props;

  return (
    <Paper
      title={titulo}
      sx={{
        p: 1,
        flex: 1,
        cursor: "pointer",
        backgroundColor: backgroundColor,
        color: color,
        //my: isMobile ? 1 : 0,
      }}
      elevation={cartao_filtro_status === idx ? 10 : 1}
      onClick={onClick}
    >
      <Stack
        justifyContent="space-between"
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
      >
        {icone}
        <Typography variant="subtitle2" fontWeight="bold">
          {texto}
        </Typography>
        <Typography variant="subtitle2" fontWeight="bold">
          {total}
        </Typography>
      </Stack>
    </Paper>
  );
});

// Lista dos cartoes
const Lista = ({ cartao_filtro_tipo, cartao_filtro_status, cartoes }) => {
  let filtro = useMemo(
    () =>
      cartao_filtro_status
        ? cartoes?.filter(
            (ele) => String(ele.situacao) === cartao_filtro_status
          )
        : cartoes,
    [cartao_filtro_status, cartoes]
  );
  // Se existir, aplica filtro adicional sobreo tipo do cartao
  if (Array.isArray(cartao_filtro_tipo) && cartao_filtro_tipo?.length > 0) {
    filtro = filtro.filter((ele) =>
      cartao_filtro_tipo.includes(ele.cartao_tipo)
    );
  }

  return (
    <List sx={{ overflowY: "auto", height: "70vh" }}>
      {filtro.map((ele) => (
        <ItemLista key={ele.id} {...ele} />
      ))}
    </List>
  );
};
// Item da lista dos cartoes
const ItemLista = memo((props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const fnVerDetalhes = () => {
    dispatch(
      cartaoSegurancaSetDrawer({
        tipo: CardSecurityModal.modal.VER_DETALHES,
        dados: props.id,
      })
    );
  };
  // Funcao para fechar o menu
  const fnFecharMenu = () => setAnchorEl(null);
  // Opcoes
  const opcoes = [
    {
      texto: t("cartao_seguranca.textOptionViewCard"),
      onClick: () => {
        dispatch(
          cartaoSegurancaSetDrawer({
            tipo: CardSecurityModal.modal.VER_DETALHES,
            dados: props.id,
          })
        );
        fnFecharMenu();
      },
      icone: <PageviewIcon />,
    },
  ];
  switch (props.situacao) {
    case "I": // Isto e quando o cartao esta no status Incluso (a validar)
      opcoes.push({
        texto: t("cartao_seguranca.textOptionValidateReject"),
        onClick: () => {
          dispatch(
            cartaoSegurancaSetDrawer({
              tipo: CardSecurityModal.modal.VALIDAR_CARTAO,
              dados: props.id,
            })
          );
          fnFecharMenu();
        },
        icone: <ThumbsUpDownIcon />,
      });
      break;
    case "A": // Quando esta em aberto
      opcoes.push({
        texto: t("cartao_seguranca.textOptionSendAnalysis"),
        onClick: () => {
          dispatch(
            cartaoSegurancaSetDrawer({
              tipo: CardSecurityModal.modal.MOVER_ANALISE,
              dados: {
                id_cartao: props.id,
                matricula: props.matricula,
              },
            })
          );
          fnFecharMenu();
        },
        icone: <BuildIcon />,
      });
      break;
    case "S": // Quando esta no status Em analise
      opcoes.push({
        texto: t("cartao_seguranca.textOptionJustify"),
        onClick: () => {
          dispatch(
            cartaoSegurancaSetDrawer({
              tipo: CardSecurityModal.modal.JUSTIFICATIVA,
              dados: props.id,
            })
          );
          fnFecharMenu();
        },
        icone: <EditIcon />,
      });
      break;
    default:
      break;
  }

  // Esta funcao vai definir que o menu deve ser exibido
  const fnManipularMenu = (evt) => {
    setAnchorEl(evt.currentTarget);
  };

  return (
    <Paper
      elevation={2}
      sx={{ p: 1, my: 1, color: "black", backgroundColor: props.cor_fundo }}
    >
      <ListItemButton onClick={fnVerDetalhes}>
        <ListItemText
          primary={
            <ItemListaCabecalho cartao_tipo={props.cartao_tipo} id={props.id} />
          }
          secondary={
            <ItemListaCorpo
              descricao={props.descricao}
              data_abertura={props.data_abertura}
              mat_lider={props.mat_lider}
              situacao={props.situacao}
            />
          }
        />
      </ListItemButton>
      <Button
        onClick={fnManipularMenu}
        endIcon={<KeyboardArrowDownIcon />}
        size="small"
        variant="contained"
      >
        {t("cartao_seguranca.textOptions")}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={fnFecharMenu}>
        {opcoes.map((ele, idx) => (
          <MenuItem key={idx} onClick={ele.onClick} divider>
            {ele.icone}&nbsp;&nbsp;
            <Typography variant="body2">{ele.texto}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Paper>
  );
});
// Cabecalho do itemCartao
const ItemListaCabecalho = ({ cartao_tipo, id }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="flex-end">
      <Chip color="secondary" label={`Código: ${id}`} sx={{ mx: 1 }} />
      <Typography variant="subtitle1" fontWeight="bold">
        {t("cartao_seguranca.textType")}: {cartao_tipo}
      </Typography>
    </Stack>
  );
};
// Corpo para o item do cartao
const ItemListaCorpo = ({ situacao, mat_lider, descricao, data_abertura }) => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ my: 1, color: "black" }}>
      <Typography variant="subtitle2" fontWeight="bold">
        {t("cartao_seguranca.textLeader")}: {mat_lider}
      </Typography>
      <Typography sx={{ mb: 1 }} variant="body2">
        {t("cartao_seguranca.textDescription")}: {descricao}
      </Typography>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Typography variant="caption">
          {t("cartao_seguranca.textOpenDate")}:{" "}
          {format(parseISO(data_abertura), "dd/MM/yy")}
        </Typography>
        <Chip
          sx={getCorStatus(situacao)}
          size="small"
          label={getStatus(situacao, t)}
        />
      </Stack>
    </Stack>
  );
};

CartaoSeguranca.rota = "/cartao_seguranca";

export default CartaoSeguranca;
