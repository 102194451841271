import { useDispatch } from "react-redux";
import { twttpAtualizaStatusValidacaoProd } from "./twttp-actions";
import { useCallback } from "react";
import { Container, Divider } from "@mui/material";
import { EntradaForm, H6, Body1 } from "../../../components";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const MoverTwttpValidacaoProducao = ({ id_twttp, status }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //

  const schema = [
    {
      type: "file",
      multiple: true,
      name: "arquivo",
      icon: "AttachFile",
    },
  ];
  const schemaValidator = yup.object().shape({
    arquivo: yup
      .mixed()
      .required()
      .test(
        "name",
        t("twttp.errorAttachmentRequiredFormMoveValidation"),
        (value) => {
          return value && value[0]?.name.length > 0;
        }
      ),
  });
  //
  const schemaMessageError = {
    arquivo: t("twttp.errorAttachmentRequiredFormMoveValidation"),
  };

  const fn = useCallback(
    (val) => {
      dispatch(
        twttpAtualizaStatusValidacaoProd({
          id_twttp,
          arquivo: val.arquivo,
          status: status,
        })
      );
    },
    [id_twttp, status, dispatch]
  );

  return (
    <Container
      sx={{ pb: 1, maxHeight: "90vh", overflow: "auto" }}
      maxWidth="md"
    >
      <H6>{t("twttp.titleFormMoveValidation")}</H6>
      <Body1>{t("twttp.subtitleFormMoveValidation")}</Body1>
      <Divider sx={{ my: 2 }} />
      <EntradaForm
        schema={schema}
        onSubmit={fn}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
      />
    </Container>
  );
};

export default MoverTwttpValidacaoProducao;
