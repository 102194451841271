import { useFetch } from "../../../hooks";
import { ToastErro } from "../../../utils/utils";
import { useEffect } from "react";
import { Stack } from "@mui/material";
import { Caption, EntradaForm, H6 } from "../../../components";
import { useCallback } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import { URL } from "./painel-de-notificacao-utils";
import { useTranslation } from "react-i18next";

const EnviarNotificacaoPorPlanta = ({ fecharModal, planta }) => {
  const { t } = useTranslation();
  const { wait, data, error, setFetch } = useFetch(URL[1], "PUT");

  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);
  //
  useEffect(() => {
    if (data && data.sucesso) {
      toast.dark(data.sucesso, {
        type: "success",
      });
      if (fecharModal) fecharModal();
    }
  }, [data, fecharModal]);

  const schema = [
    {
      name: "titulo",
      label: t("painel_de_notificacao.labelTitle"),
      placeholder: t("painel_de_notificacao.placeholderTitle"),
      type: "text",
    },
    {
      name: "descricao",
      label: t("painel_de_notificacao.labelDescription"),
      placeholder: t("painel_de_notificacao.placeholderDescription"),
      type: "textarea",
      extraProps: {
        multiline: true,
        minRows: 2,
        sx: { mt: 1 },
      },
      counter: true,
    },
  ];

  const schemaValidator = yup
    .object()
    .shape({
      titulo: yup.string().min(3).required(),
      descricao: yup.string().min(3).required(),
    })
    .required();

  const schemaMessageError = {
    titulo: t("painel_de_notificacao.errorTitle"),
    descricao: t("painel_de_notificacao.errorDescription"),
  };

  const fnEnviar = useCallback(
    (val) => {
      const _dados = {
        titulo: val.titulo,
        descricao: val.descricao,
        salvar_notificacao: val.salvar_notificacao ? "S" : "N",
        planta: [planta],
      };
      setFetch(_dados);
    },
    [planta, setFetch]
  );

  return (
    <Stack>
      <H6>{t("painel_de_notificacao.titlePageSendPlantNotification")}</H6>
      <Caption>
        {" "}
        {t("painel_de_notificacao.subtitlePageSendPlantNotification")} {planta}
      </Caption>
      <EntradaForm
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
        onSubmit={fnEnviar}
        wait={wait}
      />
    </Stack>
  );
};

export default EnviarNotificacaoPorPlanta;
