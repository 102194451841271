import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { fecharAlerta, areaDoGestorEnviarResposta, areaDoGestorInit, areaDoGestorFecharQuestionario } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import useQuestionario from '../../hooks/use-questionario';

// Componentes
import {Box, Typography, Container, Grid } from '@mui/material';
import { Divider, Hidden, Button, Stack, } from '@mui/material';
import { useMediaQuery, useTheme, TextField, RadioGroup, Radio, FormControlLabel } from '@mui/material';

// Icones
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// Componentes personalizados
import { Alerta, CardColaborador, SideBar } from '../../components';
import LinearProgressWithLabel from '../../components/barra-de-progresso';
//
import Utils from '../../utils/utils'; 

const AreaDoGestor = props => {
    const dispatch = useDispatch();
    const { alerta, areaDoGestor } = useSelector(state=> state);
    // Usando o efeito colateral Solicitando os colaboradores que o gestor tem acesso
    useEffect(()=>{ dispatch(areaDoGestorInit()); }, [dispatch]);
    const isXl = useMediaQuery( useTheme()?.breakpoints?.up('xl') );
        
    return (
        <Stack direction='row'>
            <SideBar barraIntegrada />
        
        <Container maxWidth={isXl ? 'xl' : 'lg'} >
          {alerta && <Alerta visivel={alerta.visivel} onFechar={()=> dispatch(fecharAlerta()) }>{alerta.mensagem}</Alerta>}
          {areaDoGestor && areaDoGestor.questionario ? <ResponderQuestionario /> : (
          <Grid container spacing={2}>
            {areaDoGestor && areaDoGestor.colaboradores.map((ele,idx)=>(
                <Grid xs={12} sm={6} md={4} lg={3} item key={idx} >
                      <CardColaborador {...ele} />                     
                </Grid>
            ))}
          </Grid>
          )}
        </Container>
        </Stack>
    )
}
// Area que irá exibir o questionario a ser respondido pelo funcionario
const ResponderQuestionario = props=>{
    const dispatch = useDispatch();

    const { areaDoGestor: { questionario }} = useSelector(state=> state);
    
    // Usar o hook useQuestionario para controlar as respostas e o fluxo
    const { contador, resposta, avancar, recuar } = useQuestionario();
    
    // Quando o contador de respostas for igual ao tamanho do questionario mais um
    // nao devemos mais considerar a exibicao de perguntas. Devemos exibir o resumo das respostas
    // E uma opção para confirmar ou cancelar
    if( contador === (questionario.pergunta.length + 1)) {
        
        const fn = ()=>{
            // Extrai o ID da avaliacao e a matricula do avaliado
            const {id_avaliacao, mat_avaliado} = resposta.splice(0, 1)[0];
            // Criando objeto que será enviado
            const obj = { id_avaliacao, mat_avaliado};
            // Extrai cada resposta para montar o array respostas do objeto
            obj.respostas = resposta.filter(ele=> ele !== null );
            // Envia o objeto para validação
            dispatch(areaDoGestorEnviarResposta(obj));
        }
        return (
            <RevisaoQuestionario pergunta={questionario.pergunta} resposta={resposta} 
                recuar={recuar} concluir={fn} 
                matricula={questionario.matricula} nomeAvaliado={questionario.nomeAvaliado}
            
            />
        )
    }
    
    // Gera um número para controlar a barra de progresso para controle de progresso
    const valorProgresso = parseInt(contador / questionario.pergunta.length * 100);
    
    /// Funcao de callback que vai iniciar a interatividade do questionario
    // Sua funcao também é armazenar dados importantes na primeira interação como id_avaliacao e matricula do avaliado
    const fnIniciarQuestionario = ()=> avancar({id_avaliacao: questionario.avaliacao.codigo, mat_avaliado: questionario.matricula });

    return (
        <>
        {/* AQUI FICA A BARRA DE PROGRESSO */}
        <Box display="flex" flexDirection='column'>
            <LinearProgressWithLabel value={valorProgresso} />
            <Divider />
        </Box>
        {contador > 0 ? ( 
            <MontarQuestao key={contador} resposta={resposta[contador]} 
                avancar={avancar} recuar={recuar} pergunta={questionario.pergunta[contador-1]} 
            /> 
        ) : (
          <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography align='center' variant='h5'>
                        Questionário: {questionario.avaliacao.nome}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography align='center' variant='h6'>
                        AVALIADO: {`${questionario.matricula} - ${questionario.nomeAvaliado}`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider component='br' />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Hidden smDown>
                    <Grid item xs={3} />
                </Hidden>
                <Grid item xs={4} sm={2}>
                    <Typography variant='body1'>
                        Inicio: {Utils.converterData(questionario.avaliacao.data_inicio)}
                    </Typography>            
                </Grid>           
                <Grid item xs={4} sm={2}>
                    <Typography variant='body1'>
                        Término: {Utils.converterData(questionario.avaliacao.data_fim)}
                    </Typography>            
                </Grid>
                <Grid item xs={4} sm={2}>
                    <Typography variant='body1'>
                        Total de perguntas: {questionario.pergunta.length}
                    </Typography>            
                </Grid>
                <Hidden smDown>
                    <Grid item xs={3} />
                </Hidden>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Hidden smDown>
                    <Grid item xs={3} />
                </Hidden>
                <Grid item xs={6} sm={3}>
                    <Button onClick={()=> dispatch(areaDoGestorFecharQuestionario()) } 
                        fullWidth color='primary' variant='outlined' startIcon={<ArrowBackIosIcon />}
                    >FECHAR</Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Button fullWidth color='primary' variant='contained' onClick={fnIniciarQuestionario}
                        endIcon={<DoubleArrowIcon />}
                    >INICIAR</Button>
                </Grid>
            </Grid>
        </>
        )}
       </>
    )
}
// Componente que monta o formulario das questoes
const MontarQuestao = ({ resposta, pergunta, avancar, recuar })=> {
    // Determina o nome da pergunta, nomes diferentes sao usados para o controle do useForm
    const nomePergunta = `questao_${pergunta.id_pergunta}`;
    // Pegar o valor padrao de resposta da pergunta
    let valorPadrao;
    // Se for do tipo escolha unica e tiver resposta ja selecionada
    if(resposta && resposta.id_opcao && pergunta.tipo === 1){
        valorPadrao = resposta.id_opcao.toString();
    // Se for dissertativa verificar se tem um valor, senao coloca valor em branco
    } else if(resposta && pergunta.tipo === 2){
        valorPadrao = resposta.descricao || '';
    } else {
        valorPadrao = '';
    }
    
    // Hook useForm para manipular o formulario
    const { watch, handleSubmit, control } = useForm({
        defaultValues: { [nomePergunta]: valorPadrao }
    });
    // Botao que salva a resposta e avança para a proxima pergunta
    const fnAvancar = (val)=> {       
        avancar({
            'id_pergunta': pergunta.id_pergunta,
            'id_opcao': pergunta.tipo === 1 ? parseInt(val[nomePergunta]) : 0,
            'descricao': pergunta.tipo === 2 ? val[nomePergunta] : '',
        });
    }
    // 1 - ESCOLHA ÚNICA
    // 2 - DISSERTATIVA 
    
    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <Typography align='center' variant='h4' fontWeight='bold'>
                    {pergunta.titulo}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align='center' variant='body1'>
                    {pergunta.descricao}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {pergunta.tipo === 1 ? ( // Tipo é escolha unica entao montamos com as opções disponiveis
                    <Controller 
                        control={control}
                        name={nomePergunta}
                        rules={{required: true}}
                        render={({ field })=>(
                            <RadioGroup {...field}>
                                {pergunta.opcoes.map((ele,idx)=>(
                                    <FormControlLabel key={idx} value={ele[0].toString()} control={<Radio />} label={ele[1]} />
                                ))}           
                            </RadioGroup>
                        )}
                    />
                ) : pergunta.tipo === 2 ? ( // Se tipo for dissertativa entoa devemos exibir um textarea para o usuario descrever a resposta como desejar
                     <Controller 
                        control={control}
                        name={nomePergunta}
                        rules={{required: true}}
                        render={({ field })=>(
                            <TextField {...field} margin='dense' variant='outlined' fullWidth 
                                label='DESCRIÇÃO' multiline minRows={3} 
                                placeholder='Escreva uma descrição' InputLabelProps={{ shrink: true }} 
                            />
                        )}
                    />
                ) : null }
                <Grid item xs={12}> <Divider component='br' /> </Grid>     
            </Grid>
        </Grid>
        <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Hidden smDown>
                    <Grid item xs={3} />
                </Hidden>
                <Grid item xs={6} sm={3}>
                    <Button onClick={()=> recuar() } 
                        fullWidth color='primary' variant='outlined' startIcon={<ArrowBackIosIcon />}
                    >RETORNAR</Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Button fullWidth color='primary' variant='contained' onClick={handleSubmit(fnAvancar) }
                        endIcon={<ArrowForwardIosIcon />} disabled={!watch(nomePergunta)}
                    >PRÓXIMA</Button>
                </Grid>
            </Grid>
        </>
    )

}
// Componente que faz uma revisão sobre todas as perguntas
const RevisaoQuestionario = ({ matricula, nomeAvaliado, resposta, pergunta, concluir, recuar }) =>{
    // Isto é um hack para impedir a pergunta de tentar ser renderizada
    // Existia um problema que a revisao do questionario tentava ser renderizada antes mesmo
    // de ter todas as respostas. Este pergunta.length + 2 é para resolver isto e impedir que a tela quebre
    if(resposta.length < pergunta.length + 2) return false;
    
    return (
        <Container maxWidth='lg'>
            <Typography align='center' variant='h5'> REVISÃO DAS RESPOSTAS</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography align='center' variant='h6'>
                        AVALIADO: {`${matricula} - ${nomeAvaliado}`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider component='br' />
                </Grid>
            </Grid>

            {pergunta.map((ele,idx)=>(
                <div key={idx}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='h6' fontWeight='bold'>
                                {ele.titulo}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                {ele.descricao}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Box component='span' fontWeight='bold' color='#b00022' >
                            <Typography variant='body1'>
                                Resposta: { resposta[idx + 1] && resposta[idx + 1].id_pergunta && resposta[idx + 1].descricao.length > 0 ? (
                                    resposta[idx + 1].descricao ) :  (
                                        ele.opcoes.filter(opcao=> opcao[0].toString() === resposta[idx + 1].id_opcao.toString())[0][1] 
                                    )}
                            </Typography>
                        </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                </div>
            ))}
            <Grid container>
                <Grid item xs={12}>
                    <Divider component='br' />
                </Grid>
                <Hidden smDown>
                    <Grid item xs={4} />
                </Hidden>
                <Grid item xs={6} sm={2}>
                    <Button onClick={()=> recuar()} variant='outlined' color='primary'>RETORNAR</Button>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Button onClick={()=> concluir() } variant='contained' color='primary'>CONCLUÍDO</Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AreaDoGestor;