import {
  Checkbox,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { memo } from "react";

const ListaItemSetor = memo(({ toggle, has, setor }) => (
  <ListItemButton divider onClick={() => toggle(setor)}>
    <ListItemIcon>
      <Checkbox checked={has(setor)} />
    </ListItemIcon>
    <ListItemText primary={setor} />
  </ListItemButton>
));

export default ListaItemSetor;
