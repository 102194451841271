import { Box, Button, IconButton, Paper, Stack, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePinchZoom } from "react-use";
import { Body1, Icone } from "../../../components";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useTranslation } from "react-i18next";

//
const VerPDF = ({ pdf, onCloseModal }) => {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomScale, setZoomScale] = useState(1);
  const scaleRef = useRef();
  const { zoomingState } = usePinchZoom(scaleRef);

  useEffect(() => {
    if (zoomingState === "ZOOM_IN") {
      // perform zoom in scaling
      setZoomScale(zoomScale + 0.1);
    } else if (zoomingState === "ZOOM_OUT") {
      // perform zoom out in scaling
      setZoomScale(zoomScale - 0.1);
    }
  }, [zoomingState, zoomScale]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  //
  const onZoomIn = useCallback(() => {
    setZoomScale((val) => val + 0.5);
  }, [setZoomScale]);
  //
  const onZoomOut = useCallback(() => {
    setZoomScale((val) => val - 0.5);
  }, [setZoomScale]);
  //
  const options = [
    {
      disabled: pageNumber === 1,
      icon: "ArrowBack",
      onClick: () => setPageNumber((val) => val - 1),
      text: isMobile ? "" : t("documentos.labelBtnGoBack"),
    },
    {
      icon: "ZoomOut",
      onClick: onZoomOut,
      text: isMobile ? "" : t("documentos.labelBtnZoomOut"),
    },
    {
      icon: "ZoomIn",
      onClick: onZoomIn,
      text: isMobile ? "" : t("documentos.labelBtnZoomIn"),
    },

    {
      disabled: pageNumber === numPages,
      icon: "ArrowForward",
      onClick: () => setPageNumber((val) => val + 1),
      text: isMobile ? "" : t("documentos.labelBtnNext"),
    },
  ];

  return (
    <Stack sx={{ mt: 0, px: 1 }} gap={0}>
      {isMobile ? (
        <Button
          variant="contained"
          color="error"
          onClick={onCloseModal}
          startIcon={<Icone icone="Close" />}
        >
          {t("documentos.labelBtnClose")}
        </Button>
      ) : (
        <IconButton
          color="error"
          onClick={onCloseModal}
          disableRipple
          sx={{
            background: ({ palette }) => palette.error.main,
            color: "white",
            position: "fixed",
            top: 8,
            right: 16,
          }}
        >
          <Icone icone="Close" />
        </IconButton>
      )}
      <Paper
        sx={{
          p: 1,
          width: "100vw",
          overflow: "auto",
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{ mb: 1 }}
        >
          {options.map((ele, idx) => (
            <Button
              disabled={ele.disabled}
              size="small"
              variant="outlined"
              endIcon={idx >= 2 && <Icone icone={ele.icon} />}
              startIcon={idx < 2 && <Icone icone={ele.icon} />}
              onClick={ele.onClick}
              key={ele.icon}
            >
              {ele.text}
            </Button>
          ))}
        </Stack>

        <Body1 align="center">
          {t("documentos.descPageOf1")} <strong>{pageNumber}</strong>{" "}
          {t("documentos.descPageOf2")} <strong>{numPages}</strong>
        </Body1>
      </Paper>

      <Stack
        sx={{
          height: "100vh",
          overflow: "auto",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
          <Box sx={{ maxWidth: "100vw" }}>
            <Page scale={zoomScale} ref={scaleRef} pageNumber={pageNumber} />
          </Box>
        </Document>
      </Stack>
    </Stack>
  );
};

export default VerPDF;
