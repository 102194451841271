import React from "react";
import { Container, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Desenvolvimento, SideBar } from "../../../components";
import { useTranslation } from "react-i18next";

function ManutencaoPerda() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  return (
    <Stack direction="row">
      <SideBar barraIntegrada />
      <Container sx={{ mt: 0.5, mb: isMobile ? 7 : 2 }} maxWidth="lg">
        <Desenvolvimento titulo={t("manutencao_perda.labelWaitDesenv")} />
      </Container>
    </Stack>
  );
}

ManutencaoPerda.rota = "/manutencao_perda";

export default ManutencaoPerda;
