import axios from "axios";
import { toast } from "react-toastify";
import { ToastErro } from "../../../utils/utils";
import { setAguardar } from "../../../redux/actions/aguardar-actions";
import {
  ERRO_NAO_ENVIADO,
  ERRO_STATUS_NAO_200,
} from "../../../redux/actions/erro-actions";

export const TWTTP_GET_ITENS_FILTRO = "TWTTP_GET_ITENS_FILTRO"; // Recupera itens e insere em um filtro
export const TWTTP_ITENS_LIMPAR_FILTRO = "TWTTP_ITENS_LIMPAR_FILTRO"; // Para limpar os itens do filtro
export const TWTTP_GET_DADOS = "TWTTP_GET_DADOS"; // Recupera os dados para o twttp
export const TWTTP_GET_ITENS = "TWTTP_GET_ITENS"; // Recupera os formularios criados
export const TWTTP_LIMPAR_DADOS = "TWTTP_LIMPAR_DADOS"; // Para limpar todos os dados na saida
export const TWTTP_SET_DRAWER = "TWTTP_SET_DRAWER"; // Para setar o drawer
export const TWTTP_FECHAR_DRAWER = "TWTTP_FECHAR_DRAWER"; // Para fechar o drawer
export const TWTTP_ADD_ITEM = "TWTTP_ADD_ITEM"; // Para adicionar um formulario
export const TWTTP_GET_FORM_ENTREVISTA = "TWTTP_GET_FORM_ENTREVISTA"; // Para chamar o formulario de entrevista
export const TWTTP_LIMPAR_FORM_ENTREVISTA = "TWTTP_LIMPAR_FORM_ENTREVISTA"; // Para limpar o formulario de entrevista
export const TWTTP_FORM_ENTREVISTA_VALIDAR = "TWTTP_FORM_ENTREVISTA_VALIDAR"; // Para confirmar a validacao da entevista
export const TWTTP_ATUALIZA_STATUS = "TWTTP_ATUALIZA_STATUS"; // Para atualizar o status do twttp

const ROTAS = ["/get_twttp", "/twttp", "/twttp_entrevistas"];

export const twttpGetDados = () => (dispatch) => {
  dispatch(setAguardar(true));
  const formData = new URLSearchParams();
  formData.append("dados", true);

  axios
    .get(`${ROTAS[0]}?${formData.toString()}`)
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se tem erro da aplicacao
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      // Tudo certo vamos adicionar os dados
      dispatch({
        type: TWTTP_GET_DADOS,
        data: resp.data,
      });
    })
    .catch((err) => {
      ToastErro(ERRO_NAO_ENVIADO);
      return false;
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Recupera os itens por pagina
export const twttpGetItens = (pagina) => (dispatch) => {
  dispatch(setAguardar(true));
  const formData = new URLSearchParams();
  formData.append("pagina", pagina);

  axios
    .get(`${ROTAS[0]}?${formData.toString()}`)
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se tem erro da aplicacao
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      // Tudo certo vamos adicionar os itens
      dispatch({
        type: TWTTP_GET_ITENS,
        data: { data: resp.data, pagina: pagina },
      });
    })
    .catch((err) => {
      ToastErro(ERRO_NAO_ENVIADO);
      return false;
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Recupera os itens por status e define eles no filtro
export const twttpGetItensFiltro =
  (filtro, filtroAvancado = false) =>
  async (dispatch) => {
    dispatch(setAguardar(true));
    const formData = new URLSearchParams(filtro);
    //formData.append('status', status);
    try {
      const resp = await axios.get(`${ROTAS[0]}?${formData.toString()}`);
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se tem erro da aplicacao
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      // Se for o filtro avancado fecha o modal
      if (filtroAvancado) dispatch(twttpFecharDrawer());

      // Tudo certo vamos atualizar os itens do filtro
      dispatch({
        type: TWTTP_GET_ITENS_FILTRO,
        data: { data: resp.data, filtroAvancado: filtroAvancado },
      });
    } catch (error) {
      ToastErro(ERRO_NAO_ENVIADO);
      return false;
    } finally {
      dispatch(setAguardar(false));
    }
  };
// Action para limpar os itens do filtro
export const twttpItensLimparFiltro = () => ({
  type: TWTTP_ITENS_LIMPAR_FILTRO,
});

// Action para limpar os dados
export const twttpLimparDados = () => ({
  type: TWTTP_LIMPAR_DADOS,
});
// Action para inserir um twttp
export const twttpAddForm = (obj) => (dispatch) => {
  dispatch(setAguardar(true));
  const formData = new FormData();
  if (obj.arquivo) {
    [...obj.arquivo].forEach((ele) => {
      formData.append("arquivo", ele);
    });
    delete obj.arquivo;
  }
  formData.append("dados", JSON.stringify(obj));

  axios
    .post(ROTAS[1], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se tem erro da aplicacao
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Tudo certo vamos adicionar os itens
      dispatch({
        type: TWTTP_ADD_ITEM,
        data: resp.data.data,
      });
      dispatch(twttpFecharDrawer());
    })
    .catch((err) => {
      ToastErro(ERRO_NAO_ENVIADO);
      return false;
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Para setar o sreawer
export const twttpSetDrawer = (dados) => ({
  type: TWTTP_SET_DRAWER,
  data: dados,
});
// pARA FECHAR O DRAWER
export const twttpFecharDrawer = () => ({
  type: TWTTP_FECHAR_DRAWER,
});
// Para recuperar o formulario de entrevista
export const twttpGetFormEntrevista = (id_twttp) => (dispatch) => {
  dispatch(setAguardar(true));
  const formData = new URLSearchParams();
  formData.append("id_twttp", id_twttp);

  axios
    .get(`${ROTAS[2]}?${formData.toString()}`)
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se tem erro da aplicacao
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      // Tudo certo vamos atualizar os itens do filtro
      dispatch({
        type: TWTTP_GET_FORM_ENTREVISTA,
        data: resp.data,
      });
    })
    .catch((err) => {
      ToastErro(ERRO_NAO_ENVIADO);
      return false;
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Para limpar o formulario de entrevista
export const twttpLimparFormEntrevista = () => ({
  type: TWTTP_LIMPAR_FORM_ENTREVISTA,
});
// Envia respostas para validação do formulario
export const twttpFormEntrevistaValidar = (obj) => (dispatch) => {
  dispatch(setAguardar(true));
  const formData = new FormData();

  formData.append("dados", JSON.stringify(obj));

  axios
    .post(ROTAS[2], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se tem erro da aplicacao
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Tudo certo vamos adicionar os itens
      dispatch({
        type: TWTTP_FORM_ENTREVISTA_VALIDAR,
        data: resp.data.data,
      });
      dispatch(twttpFecharDrawer());
    })
    .catch((err) => {
      ToastErro(ERRO_NAO_ENVIADO);
      return false;
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Atualiza o status do twttp
export const twttpAtualizaStatus = (obj) => (dispatch) => {
  dispatch(setAguardar(true));
  const formData = new FormData();

  formData.append("dados", JSON.stringify(obj));

  axios
    .put(ROTAS[1], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se tem erro da aplicacao
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Tudo certo vamos atualizar o status
      dispatch({
        type: TWTTP_ATUALIZA_STATUS,
        data: resp.data.data,
      });
      dispatch(twttpFecharDrawer());
    })
    .catch((err) => {
      ToastErro(ERRO_NAO_ENVIADO);
      return false;
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Atualiza para o status Validacao com envio do anexo
export const twttpAtualizaStatusValidacao = (obj, setWait) => (dispatch) => {
  setWait();
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  axios
    .patch(ROTAS[1], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se tem erro da aplicacao
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Tudo certo vamos atualizar o status
      dispatch({
        type: TWTTP_ATUALIZA_STATUS,
        data: resp.data.data,
      });
      dispatch(twttpFecharDrawer());
    })
    .catch((err) => {
      ToastErro(ERRO_NAO_ENVIADO);
      return false;
    })
    .finally(() => {
      setWait();
    });
};

export const twttpAtualizaStatusValidacaoProd = (obj) => (dispatch) => {
  dispatch(setAguardar(true));
  const formData = new FormData();
  if (obj.arquivo) {
    [...obj.arquivo].forEach((ele) => {
      formData.append("arquivo", ele);
    });
    delete obj.arquivo;
  }
  formData.append("dados", JSON.stringify(obj));

  axios
    .patch(ROTAS[1], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se tem erro da aplicacao
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Tudo certo vamos atualizar o status
      dispatch({
        type: TWTTP_ATUALIZA_STATUS,
        data: resp.data.data,
      });
      dispatch(twttpFecharDrawer());
    })
    .catch((err) => {
      ToastErro(ERRO_NAO_ENVIADO);
      return false;
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
