import { Container, Divider, Grid } from "@mui/material";
import CabeContraCheque from "./contra-cheque-cabe";
import CabeValoresContraCheque from "./contracheque-cabecalho";
import ValoresContraCheque from "./contra-cheque-valores";
import InformacoesPessoais from "./contra-cheque-info-pessoais";
import BasesContraCheque from "./contra-cheque-base";

// Cria o componente que será o contracheque
const ViewContraCheque = (props) => {
  const { mes, ano, info_pessoais, valores, bases, info_empresa } = props;
  // Gera a soma do total de vencimentos e descontos
  const fnSomaToVencimentos = (arr) => {
    let totVencimentos = 0,
      totDescontos = 0;

    arr.forEach((row) => {
      row.forEach((data, idx) => {
        switch (idx) {
          case 3: // Vencimentos
            totVencimentos += data;
            break;
          case 4: // Descontos
            totDescontos += data;
            break;
          default:
            break;
        }
      });
    });
    return [totVencimentos, totDescontos];
  };
  const [totVencimentos, totDescontos] = fnSomaToVencimentos(valores);

  return (
    <Container maxWidth="md">
      <Grid container>
        <CabeContraCheque mes={mes} ano={ano} info_empresa={info_empresa} />
        <InformacoesPessoais info_pessoais={info_pessoais} />
      </Grid>
      <Divider />
      <div style={{ flex: 1 }}>
        <CabeValoresContraCheque />
        <ValoresContraCheque valores={valores} />
      </div>
      <Divider />
      <BasesContraCheque
        bases={bases}
        totDescontos={totDescontos}
        totVencimentos={totVencimentos}
      />
    </Container>
  );
};

export default ViewContraCheque;
