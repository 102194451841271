import { useDispatch } from "react-redux";
import { feedRHDelFeed, feedRHFecharDrawer } from "../feed-actions";
import { Confirmar } from "../../../../components";
import { useToggle } from "react-use";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

// Componente criado para uma interface de escolha de exclusao de feed
const FeedExcluir = ({ id_feed }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [wait, setWait] = useToggle();

  const fnCancelar = useCallback(() => {
    dispatch(feedRHFecharDrawer());
  }, [dispatch]);

  const fnConfirmar = useCallback(() => {
    dispatch(feedRHDelFeed({ id_feed }, setWait));
  }, [dispatch, id_feed, setWait]);

  return (
    <Confirmar
      aguardar={wait}
      titulo={t("feed_rh.formDelTitleFeedDel")}
      subtitulo={t("feed_rh.formDelSubtitleFeedDel")}
      fnCancelar={fnCancelar}
      fnConfirmar={fnConfirmar}
    />
  );
};

export default FeedExcluir;
