import React, { useEffect, useCallback, memo, useState } from "react";
import axios from "axios";
import {
  Menu,
  MenuItem,
  Button,
  List,
  Fab,
  ListItemText,
  Stack,
  Container,
  ListItemAvatar,
  Paper,
  Avatar,
  useMediaQuery,
  useTheme,
  ListItemButton,
  Chip,
  Zoom,
  Hidden,
} from "@mui/material";
import {
  Paginacao,
  Icone,
  H6,
  SideBar,
  Body2,
  Caption,
  Subtitle2,
  AnimacaoSemDados,
} from "../../../components";

import { format, parseISO } from "date-fns";
import {
  twttpGetDados,
  twttpGetItens,
  twttpSetDrawer,
  twttpFecharDrawer,
  twttpLimparDados,
  twttpGetItensFiltro,
  twttpItensLimparFiltro,
  twttpAtualizaStatus,
} from "./twttp-actions";
import { useDispatch, useSelector } from "react-redux";
import { registrarAcesso, ToastErro } from "../../../utils/utils";
import { toast } from "react-toastify";
import {
  selectDados,
  selectDrawer,
  selectIsFiltroAvancado,
  selectIsSuperProduction,
  selectItensComCor,
  selectItensFiltroComCor,
  selectPaginaAtual,
  selectQtdPagina,
  seletorTotalPorStatus,
} from "./twttp-selectors";
import { DrawerDialog } from "../../../extra-components";
import TwttpModal from "./twttp-modal";
import { getStatusCor, getStatusIcone, getStatusText } from "./twttp-utils";
import { useTranslation } from "react-i18next";

// Funcao de callback para baixar o PDF
const fnBaixarPDF = async (idTwttp, t) => {
  const ID = toast.dark(t("twttp.labelWaitToPdf"), {
    type: "info",
    theme: "dark",
  });
  try {
    const resp = await axios.get(
      `/twttp_entrevistas_respostas_pdf?id_twttp=${idTwttp}`
    );
    toast.dismiss(ID);
    if (resp.status !== 200) {
      ToastErro(t("twttp.errorInternal"));
      return false;
    }
    //
    if (resp.data.hasOwnProperty("erro")) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Tudo certo cria o download
    const link = document.createElement("a");
    link.href = resp.data.data;
    link.target = "_blank";
    link.click();
  } catch (error) {
    ToastErro(t("twttp.errorUnknown"));
  }
};

function Twttp() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dados = useSelector(selectDados);

  const drawer = useSelector(selectDrawer);
  // Useffect para recuperar os itens
  useEffect(() => {
    dispatch(twttpGetItens(1));
    return () => dispatch(twttpLimparDados());
  }, [dispatch]);

  // useEffect para recuperar os dados
  useEffect(() => {
    dispatch(twttpGetDados());
  }, [dispatch]);
  // Registrar o acesso a pagina
  // Registrando acesso a pagina
  useEffect(() => registrarAcesso(window.location.pathname), []);

  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  //
  const fnFecharDrawer = useCallback(
    () => dispatch(twttpFecharDrawer()),
    [dispatch]
  );

  return (
    <Stack direction="row">
      <SideBar barraIntegrada />
      <Container
        disableGutters={isMobile}
        sx={{ mt: 0.5, mb: isMobile ? 7 : 2 }}
        maxWidth="xl"
      >
        {drawer && (
          <DrawerDialog
            fnGetCorpo={() => <TwttpModal modal={drawer} />}
            fecharModal={fnFecharDrawer}
          />
        )}

        <H6>{t("twttp.titleTwttp")}</H6>
        {dados && <PainelStatus />}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ mb: 1 }}
        >
          {dados && <BotaoAcaoPost />}
          <Hidden mdDown>
            <BotaoFiltroAvancado />
          </Hidden>
        </Stack>
        {dados && <RenderItens />}
      </Container>
    </Stack>
  );
}
// Painel superior de status
const PainelStatus = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selecionado, setSelecionado] = useState(null);
  const ST = useSelector(seletorTotalPorStatus);
  //
  const isFiltroAvancado = useSelector(selectIsFiltroAvancado);

  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  // funcao de callback para controlar o filtro por status
  const fnFiltro = useCallback(
    (filtro) => {
      // Se for diferente executar o filtro
      if (filtro !== selecionado) {
        dispatch(twttpGetItensFiltro({ status: filtro }));
        setSelecionado(filtro);
      } else {
        // Como e igual entao devemos limpar o filtro
        dispatch(twttpItensLimparFiltro());
        setSelecionado(null);
      }
    },
    [selecionado, dispatch, setSelecionado]
  );

  const sxBase = {
    p: 1,
  };
  const styleBase = { cursor: "pointer" }; // Para props que o sx não cobre
  const sxLimparFiltro = {
    p: isMobile ? 2 : 1,
    mx: 0.5,

    width: isMobile ? "100%" : "auto",
  };
  if (isMobile) sxBase.mb = 1;
  //

  // Pega os 3 primeiros status A, P, V
  const itens1 = ["A", "P", "S"].map((ele, idx) => (
    <PainelStatusBotao
      isSelected={selecionado === ele}
      status={ele}
      count={ST[ele]}
      fnFilter={() => fnFiltro(ele)}
      key={ele}
      styleBase={styleBase}
    />
  ));
  // Pega o S, E, R
  const itens2 = ["Y", "V", "C"].map((ele, idx) => (
    <PainelStatusBotao
      isSelected={selecionado === ele}
      status={ele}
      count={ST[ele]}
      fnFilter={() => fnFiltro(ele)}
      key={ele}
      styleBase={styleBase}
    />
  ));
  // Pega os 2 ultimos E, R
  const itens3 = ["E", "R"].map((ele, idx) => (
    <PainelStatusBotao
      isSelected={selecionado === ele}
      status={ele}
      count={ST[ele]}
      fnFilter={() => fnFiltro(ele)}
      key={ele}
      styleBase={styleBase}
    />
  ));

  return (
    <>
      <Stack direction={{ xs: "column", md: "row" }} sx={{ mb: 1 }} spacing={1}>
        <Stack alignItems="center" flex={1} spacing={1} direction="row">
          {itens1}
        </Stack>

        <Stack alignItems="center" flex={1} spacing={1} direction="row">
          {itens2}
        </Stack>
        <Stack alignItems="center" flex={1} spacing={1} direction="row">
          {itens3}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <div />
        <Zoom
          in={Boolean(selecionado || isFiltroAvancado)}
          mountOnEnter
          unmountOnExit
        >
          <Paper
            style={styleBase}
            onClick={() => fnFiltro(selecionado)}
            sx={{
              flexBasis: isMobile ? "100%" : "100%",
              mb: isMobile ? "auto" : 1,
              backgroundColor: "error.dark",
              color: "error.contrastText",
              ...sxBase,
              ...sxLimparFiltro,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <Icone icone="Delete" />
              <Subtitle2>{t("twttp.labelCleanFilter")}</Subtitle2>
              <Body2></Body2>
            </Stack>
          </Paper>
        </Zoom>
        <div />
      </Stack>
      <Hidden mdUp>
        <Stack direction="row-reverse">
          <BotaoFiltroAvancado />
        </Stack>
      </Hidden>
    </>
  );
};
// Componente que representa um botao do painel de status
const PainelStatusBotao = memo(
  ({ styleBase, fnFilter, isSelected, status, count }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
    //
    const sxInterno = {
      width: isMobile ? "calc(33.33% - 8px)" : "100%",
    };

    return (
      <Paper
        style={styleBase}
        onClick={fnFilter}
        elevation={isSelected ? 16 : 2}
        sx={{ p: isMobile ? 0.2 : 1, ...getStatusCor(status), ...sxInterno }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-evenly"
          alignItems="center"
          spacing={0}
        >
          <Icone icone={getStatusIcone(status)} />
          <Subtitle2 whiteSpace="nowrap">{getStatusText(status, t)}</Subtitle2>
          <Body2>{count}</Body2>
        </Stack>
      </Paper>
    );
  }
);
// Componente para renderizar o itens
const RenderItens = memo(() => {
  const { t } = useTranslation();
  const itens = useSelector(selectItensComCor);
  const filtro = useSelector(selectItensFiltroComCor); // O filtro tem preferencia sobre os itens convencionais
  // Obtem o total de paginas e a pagina atual
  const qtdPagina = useSelector(selectQtdPagina);
  const paginaAtual = useSelector(selectPaginaAtual);

  const dispatch = useDispatch();
  // Funcao de callback para alternar entre as paginas
  const fnAlteraPaginaAtual = useCallback(
    (paginaAtual, proximaPagina) => dispatch(twttpGetItens(proximaPagina)),
    [dispatch]
  );

  return filtro ? (
    filtro?.length < 1 ? (
      <AnimacaoSemDados titulo={t("twttp.titleNoData")} /> // Modify this line
    ) : (
      <List sx={{ maxHeight: "75vh", overflowY: "auto" }}>
        {filtro.map((ele) => {
          const {
            id_twttp,
            status,
            planta,
            data_abertura,
            area,
            entrevistado,
            problema,
            cores: { cor_fundo },
            setor,
          } = ele;
          return (
            <ListaItens
              key={`${id_twttp}_${status}`}
              id_twttp={id_twttp}
              data_abertura={data_abertura}
              area={area}
              entrevistado={entrevistado}
              problema={problema}
              planta={planta}
              cor={cor_fundo}
              setor={setor}
              status={status}
            />
          );
        })}
      </List>
    )
  ) : itens ? (
    itens?.length < 1 ? (
      <AnimacaoSemDados titulo={t("twttp.titleNoData")} /> // Modify this line
    ) : (
      <Paginacao
        callBackPagina={fnAlteraPaginaAtual}
        corpo={itens}
        paginaAtual={paginaAtual}
        quantidadeDePaginas={qtdPagina}
        componentePai={(filho) => (
          <List
            sx={{ mt: 2, maxHeight: "75vh", overflowY: "auto" }}
            disablePadding
          >
            {filho}
          </List>
        )}
        component={(props) => {
          const {
            id_twttp,
            status,
            planta,
            data_abertura,
            area,
            entrevistado,
            problema,
            cores: { cor_fundo },
            setor,
            data_prazo_acao,
          } = props;
          return (
            <ListaItens
              key={`${id_twttp}_${status}`}
              id_twttp={id_twttp}
              data_abertura={data_abertura}
              area={area}
              entrevistado={entrevistado}
              problema={problema}
              planta={planta}
              cor={cor_fundo}
              setor={setor}
              status={status}
              data_prazo_acao={data_prazo_acao}
            />
          );
        }}
      />
    )
  ) : (
    <AnimacaoSemDados titulo={t("twttp.titleNoData")} /> // Modify this line
  );
});
// Compoente para os itens
const ListaItens = memo(
  ({
    status,
    cor,
    id_twttp,
    planta,
    area,
    problema,
    data_abertura,
    entrevistado,
    setor,
    data_prazo_acao,
  }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isSuperProd = useSelector(selectIsSuperProduction);

    const dispatch = useDispatch();
    let mat, nome;
    if (entrevistado) [mat, nome] = entrevistado?.split("-");

    // Para ver detalhes de um twttp
    const fnVerDetalhes = useCallback(
      () =>
        dispatch(
          twttpSetDrawer({
            tipo: TwttpModal.modal.VER_DETALHE_TWTTP,
            dados: id_twttp,
          })
        ),
      [id_twttp, dispatch]
    );
    const fnEntrevistar = useCallback(() => {
      dispatch(
        twttpSetDrawer({
          tipo: TwttpModal.modal.ENTREVISTAR,
          dados: { id_twttp, status },
        })
      );
      setAnchorEl(null);
    }, [setAnchorEl, id_twttp, status, dispatch]);
    const fnEmExecucao = useCallback(() => {
      dispatch(
        twttpSetDrawer({
          tipo: TwttpModal.modal.MOVER_VALIDACAO_SUPERIOR,
          dados: id_twttp,
        })
      );
      setAnchorEl(null);
    }, [setAnchorEl, id_twttp, dispatch]);

    const fnEmExecucaoSuperior = useCallback(() => {
      dispatch(
        twttpSetDrawer({
          tipo: TwttpModal.modal.MOVER_VALIDACAO,
          dados: id_twttp,
        })
      );
      setAnchorEl(null);
    }, [setAnchorEl, id_twttp, dispatch]);
    const fnReprovado = useCallback(() => {
      dispatch(
        twttpSetDrawer({
          tipo: TwttpModal.modal.MOVER_REPROVADO,
          dados: id_twttp,
        })
      );
      setAnchorEl(null);
    }, [setAnchorEl, id_twttp, dispatch]);
    // Funcao para aprovar twttp (encerrar)
    const fnEncerrar = useCallback(() => {
      dispatch(
        twttpSetDrawer({
          tipo: TwttpModal.modal.MOVER_APROVADO,
          dados: id_twttp,
        })
      );
      setAnchorEl(null);
    }, [setAnchorEl, id_twttp, dispatch]);
    // Funcoes para manipulacao do estado do menu
    const fnExibeMenu = useCallback(
      (event) => setAnchorEl(event.currentTarget),
      [setAnchorEl]
    );
    // Funcao de callback para ver as respostas
    const fnVerRespostas = useCallback(() => {
      dispatch(
        twttpSetDrawer({
          tipo: TwttpModal.modal.VER_RESPOSTAS,
          dados: id_twttp,
        })
      );
      setAnchorEl(null);
    }, [id_twttp, dispatch]);
    //
    const fnFechaMenu = useCallback(() => setAnchorEl(null), [setAnchorEl]);
    // Controla o estado do menu
    const menuAberto = Boolean(anchorEl);
    // itens do menu baseado no status
    const itensMenu = [];
    if (status === "A") {
      itensMenu.push({
        titulo: t("twttp.labelToInterview"),
        icone: getStatusIcone("V"),
        fn: fnEntrevistar,
        cor: getStatusCor("V"),
      });
    }
    // Se o status e P (pre-validacao) pode-se prosseguir
    if (status === "P") {
      itensMenu.push({
        titulo: t("twttp.labelFinishInterview"),
        icone: getStatusIcone("E"),
        fn: fnEntrevistar,
        cor: getStatusCor("E"),
      });
    }
    // Se o status é S (Em Execucao) pode-se definir as opções do twttp
    if (status === "S") {
      itensMenu.push({
        titulo: t("twttp.labelToValidationHigher"),
        icone: getStatusIcone("Y"),
        fn: fnEmExecucao,
        cor: getStatusCor("Y"),
      });
    }
    // Quando esta no status Y (validacao producao) e precisa ser enviado para validacao (somente o supervisor pode)
    if (status === "Y" && isSuperProd) {
      itensMenu.push({
        titulo: t("twttp.labelToValidation"),
        icone: getStatusIcone("V"),
        fn: fnEmExecucaoSuperior,
        cor: getStatusCor("V"),
      });
      itensMenu.push({
        titulo: t("twttp.labelCancel"),
        icone: getStatusIcone("C"),
        //fn: ()=> dispatch(twttpAtualizaStatus({id_twttp, status: 'C'})),
        fn: () => {
          dispatch(
            twttpSetDrawer({
              tipo: TwttpModal.modal.CONFIRMAR_MOVER_CANCELADO,
              dados: { id_twttp, status: "C" },
            })
          );
          setAnchorEl(null);
        },
        cor: getStatusCor("C"),
      });
    }
    // Se o status for V validação entao devemos das as 2 opcoes, encerrar ou reprovar
    if (status === "V") {
      itensMenu.push({
        titulo: t("twttp.labelValidation"),
        icone: getStatusIcone("E"),
        fn: fnEncerrar,
        cor: getStatusCor("E"),
      });
      itensMenu.push({
        titulo: t("twttp.labelReprove"),
        icone: getStatusIcone("R"),
        fn: fnReprovado,
        cor: getStatusCor("R"),
      });
    }

    // Se o status for diferente de A Quer dizer que ja tem entrevista
    if (status !== "A") {
      itensMenu.push({
        titulo: t("twttp.labelViewAnswers"),
        icone: getStatusIcone("VR"),
        fn: fnVerRespostas,
        cor: getStatusCor("VR"),
      });
    }
    // Se for diferente de A e P (pre-entrevista)
    if (status !== "A" && status !== "P") {
      itensMenu.push({
        titulo: getStatusText("BPDF", t),
        icone: getStatusIcone("BPDF"),
        fn: () => fnBaixarPDF(id_twttp, t),
        cor: getStatusCor("BPDF"),
      });
    }
    // Se o status for reprovado
    if (status === "R") {
      itensMenu.push({
        titulo: t("twttp.labelMoveToExecution"),
        icone: getStatusIcone("S"),
        fn: () => dispatch(twttpAtualizaStatus({ id_twttp, status: "S" })),
        cor: getStatusCor("S"),
      });
    }
    // Pode ser cancelado se o status não for encerrado, reprovado e se já estiver em cancelar
    if (status !== "E" && status !== "C" && status !== "R" && status !== "Y") {
      itensMenu.push({
        titulo: t("twttp.labelCancel"),
        icone: getStatusIcone("C"),
        //fn: ()=> dispatch(twttpAtualizaStatus({id_twttp, status: 'C'})),
        fn: () => {
          dispatch(
            twttpSetDrawer({
              tipo: TwttpModal.modal.CONFIRMAR_MOVER_CANCELADO,
              dados: { id_twttp, status: "C" },
            })
          );
          setAnchorEl(null);
        },
        cor: getStatusCor("C"),
      });
    }

    return (
      <Paper
        elevation={3}
        sx={{ p: 1, my: 1, backgroundColor: cor, color: "black" }}
      >
        <ListItemButton onClick={fnVerDetalhes}>
          {mat && (
            <ListItemAvatar>
              <Avatar
                alt={nome.trim()}
                src={`/static/imagens/avatar/${planta}/${mat.trim()}.jpg`}
              />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={`${t("twttp.labelCode")}: ${id_twttp}`}
            primaryTypographyProps={{
              fontSize: (theme) => theme.typography.caption.fontSize,
            }}
            secondary={
              <Paper
                elevation={0}
                sx={{ backgroundColor: "transparent", color: "black" }}
              >
                <Stack>
                  <Body2 align="left">
                    {t("twttp.labelSector")}: {setor}
                  </Body2>
                  <Caption align="left">
                    {t("twttp.labelArea")}: {area}
                  </Caption>
                  <Body2 sx={{ mt: 0 }}>
                    {t("twttp.labelProblem")}:&nbsp;{problema}
                  </Body2>
                  <Hidden mdUp>
                    <Stack
                      flex={1}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Chip
                        size="small"
                        label={getStatusText(status, t)}
                        sx={{ ...getStatusCor(status) }}
                      />
                    </Stack>
                  </Hidden>
                </Stack>
              </Paper>
            }
          />
        </ListItemButton>
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
          spacing={1}
        >
          {itensMenu?.length > 0 && (
            <Button
              size="small"
              variant="contained"
              onClick={fnExibeMenu}
              endIcon={
                <Icone icone={menuAberto ? "ArrowDropUp" : "ArrowDropDown"} />
              }
              aria-controls={menuAberto ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuAberto ? "true" : undefined}
            >
              {t("twttp.labelOptions")}
            </Button>
          )}
          <Hidden mdDown>
            <Stack flex={1} direction="row" justifyContent="space-between">
              <Chip
                size="small"
                label={getStatusText(status, t)}
                sx={{ ...getStatusCor(status) }}
              />
            </Stack>
          </Hidden>
          <ListItensInfoDatas
            data_abertura={data_abertura}
            data_prazo_acao={data_prazo_acao}
          />
        </Stack>

        <Menu
          anchorEl={anchorEl}
          open={menuAberto}
          onClose={fnFechaMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {itensMenu.map((ele, idx) => (
            <MenuItem key={idx} onClick={ele.fn} divider>
              <Icone
                icone={ele.icone}
                sx={{ color: ele.cor.backgroundColor }}
              />
              &nbsp;&nbsp;
              <Body2 fontWeight={500}>{ele.titulo}</Body2>
            </MenuItem>
          ))}
        </Menu>
      </Paper>
    );
  }
);
// Exibe informacoes de datas dos itens twttp
const ListItensInfoDatas = memo(({ data_abertura, data_prazo_acao }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
      <Chip
        title={t("twttp.titleOpenDateTwttp")}
        sx={{ alignSelf: "flex-end" }}
        color="primary"
        size="small"
        icon={<Icone icone="CalendarMonth" />}
        label={`${t("twttp.titleOpenDate")}: ${format(
          parseISO(data_abertura),
          "dd/MM/yy"
        )}`}
      />
      {data_prazo_acao && (
        <Chip
          color="error"
          variant="outlined"
          size="small"
          title={t("twttp.titlePrazActionTwttp")}
          sx={{ alignSelf: "flex-end" }}
          icon={<Icone icone="Event" />}
          label={`${t("twttp.titlePrazAction")}: ${format(
            parseISO(data_prazo_acao),
            "dd/MM/yy"
          )}`}
        />
      )}
    </Stack>
  );
});
//
const BotaoFiltroAvancado = memo(() => {
  const { t } = useTranslation();
  const isFiltroAvancado = useSelector(selectIsFiltroAvancado);
  const dispatch = useDispatch();
  // Callback para chamar filtro avancado
  const fnVerFiltroAvancado = useCallback(() => {
    dispatch(twttpSetDrawer({ tipo: TwttpModal.modal.VER_FILTRO_AVANCADO }));
  }, [dispatch]);

  return (
    <Chip
      label={t("twttp.labelFilterAdvanced")}
      icon={<Icone icone="Search" />}
      onClick={fnVerFiltroAvancado}
      color={isFiltroAvancado ? "primary" : "default"}
    />
  );
});
// Componente que exibe o botao de acoes
const BotaoAcaoPost = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  const fnAdd = useCallback(
    () =>
      dispatch(
        twttpSetDrawer({
          tipo: TwttpModal.modal.ADD_TWTTP,
        })
      ),
    [dispatch]
  );

  return (
    <Zoom in={true} unmountOnExit mountOnEnter>
      <Fab
        color="primary"
        title={t("twttp.titleAddNewTwttp")}
        size="medium"
        onClick={fnAdd}
        variant={isMobile ? "circular" : "extended"}
        sx={{
          position: { xs: "fixed", md: "static" },
          zIndex: 24,
          right: { xs: 16, md: 0 },
          bottom: { xs: 72, md: 0 },
        }}
      >
        <Icone icone="PostAdd" />
        {!isMobile && t("twttp.labelBtnAddTwttp")}
      </Fab>
    </Zoom>
  );
});

Twttp.rota = "/twttp";

export default Twttp;
