import React, { memo, useCallback, useEffect } from "react";

import { ChipSituacao } from ".";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Grow,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import {
  Body1,
  Body2,
  Caption,
  EditorTextRich,
  EntradaForm,
  H6,
  Icone,
  Pesquisando,
  Tab,
} from "../../../components";
import Utils, { ToastErro } from "../../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BackgroundRouter from "../../background-router";
import ScrollInfinito from "../../../components/scroll-infinito";
import { blue, grey, red } from "@mui/material/colors";
import { useFetch } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { useToggle } from "react-use";
import * as yup from "yup";
import { ouvidoriaUpd } from "./ouvidoria-actions";
import { bucketSend } from "../../../utils/bucket_send";
import { useTranslation } from "react-i18next";
import { selectIsRH, selectSituacao } from "./ouvidoria-seletores";
//

function OuvidoriaDetalhes() {
  const isDark = useTheme().palette.mode === "dark";
  const history = useHistory();
  const params = history?.location?.state;
  return (
    <BackgroundRouter>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ background: !isDark && grey[200] }}
      >
        <Corpo {...params} />
      </Container>
    </BackgroundRouter>
  );
}

OuvidoriaDetalhes.rota = "/ouvidoria_detalhes";
//
const Corpo = (props) => {
  const isMobile = useTheme()?.isMobile;

  return isMobile ? <CorpoMobile {...props} /> : <CorpoDesktop {...props} />;
};
//
const CorpoMobile = (props) => {
  const { t } = useTranslation();
  const { idOuvidoria, solicitante } = props;
  const cabe = [
    {
      icone: "SwapHoriz",
      texto: t("ouvidoria_view.titleTab1"),
    },
    {
      icone: "ConfirmationNumber",
      texto: t("ouvidoria_view.titleTab2"),
    },
  ].map((ele) => (
    <Stack key={ele.icone} direction="row" spacing={1} alignItems="center">
      <Icone icone={ele.icone} />
      <Body2>{ele.texto}</Body2>
    </Stack>
  ));
  const corpo = [
    <DetalhesInteracao {...props} />,
    <Detalhes id={idOuvidoria} {...props} nome={solicitante} />,
  ];

  return <Tab cabe={cabe} corpo={corpo} />;
};
//
const CorpoDesktop = (props) => {
  const { idOuvidoria, solicitante } = props;

  return (
    <Grid container>
      <Grid item xs={9}>
        <DetalhesInteracao {...props} />
      </Grid>
      <Grid item xs={3}>
        <Detalhes id={idOuvidoria} {...props} nome={solicitante} />
      </Grid>
    </Grid>
  );
};
//
const DetalhesInteracao = ({ assunto, idOuvidoria }) => {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;
  const history = useHistory();
  const retornarPagina = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Stack>
      <Stack direction="row">
        {!isMobile && (
          <Button
            startIcon={<Icone icone="Undo" />}
            variant="text"
            onClick={retornarPagina}
            title={t("ouvidoria_view.titleBtnGoBack")}
          >
            {t("ouvidoria_view.labelBtnGoBack")}
          </Button>
        )}
        <H6 sx={{ flex: 1 }} align="center">
          {t("ouvidoria_view.title")}: {assunto}
        </H6>
      </Stack>
      <Container maxWidth="lg">
        <Historico idOuvidoria={idOuvidoria} />
      </Container>
    </Stack>
  );
};
//
const Historico = ({ idOuvidoria }) => {
  const { t } = useTranslation();
  const isRH = useSelector(selectIsRH);
  const [verInteracao, setVerInteracao] = useToggle();
  const { data, error, wait, setFetch } = useFetch(
    `/ouvidoria?id=${idOuvidoria}`,
    "GET"
  );
  //
  useEffect(() => setFetch({}), [setFetch]);
  //
  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);

  return (
    <Container sx={{ px: 0.5 }} maxWidth={false} disableGutters>
      {wait ? (
        <Pesquisando />
      ) : data ? (
        <>
          {isRH && (
            <Stack direction="row" justifyContent="center">
              <Button
                variant="contained"
                onClick={setVerInteracao}
                startIcon={<Icone icone="Edit" />}
              >
                {t("ouvidoria_view.labelBtnInteraction")}
              </Button>
            </Stack>
          )}
          <Grow in={verInteracao} unmountOnExit>
            <Box>
              <OuvidoriaInteragir
                idOuvidoria={idOuvidoria}
                fecharInteracao={() => {
                  setFetch();
                  setVerInteracao();
                }}
              />
            </Box>
          </Grow>
          <ScrollInfinito
            itens={data}
            render={(ele, idx) => (
              <InteracaoItem {...ele} isExpandido={idx === 0} />
            )}
            itensPorPagina={3}
            tamanho="calc(100vh - 64px)"
          />
        </>
      ) : null}
      <br /> <br />
    </Container>
  );
};
//
export const InteracaoItem = memo(
  ({ data, texto, avatar, nome, is_rh, situacao, anexo }) => {
    const isMobile = useTheme()?.isMobile;
    const sxPaper = {};
    const sxIcone = {
      position: "absolute",
      top: 0,
      width: 48,
      height: 48,
    };

    if (is_rh) {
      sxIcone.right = -28;
      sxIcone.color = red[500];
    } else {
      sxIcone.left = -28;
      sxIcone.color = blue[500];
    }
    //
    sxPaper.border = `1px solid ${sxIcone.color}`;

    return (
      <Stack>
        <Stack
          spacing={2}
          direction={is_rh ? "row-reverse" : "row"}
          sx={{ m: 1 }}
        >
          {!isMobile && <Avatar alt={nome} src={avatar} />}

          <Paper
            sx={{
              flex: 1,
              position: "relative",
              ...sxPaper,
            }}
            elevation={0}
          >
            <Icone icone={is_rh ? "ArrowRight" : "ArrowLeft"} sx={sxIcone} />
            <ListItem divider dense disableGutters disablePadding>
              <ListItemText
                primary={
                  <InteracaoItemPrimario
                    nome={nome}
                    dataInteracao={data}
                    situacao={situacao}
                  />
                }
              />
            </ListItem>
            <InteracaoItemSecundario descricao={texto} anexo={anexo} />
          </Paper>
          {!isMobile && <Box sx={{ width: 40, height: 40 }} />}
        </Stack>
      </Stack>
    );
  }
);
//
const InteracaoItemPrimario = ({ nome, dataInteracao, situacao }) => {
  const isMobile = useTheme()?.isMobile;
  const dataFormatada = Utils.converterDataHora(dataInteracao);

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={1}
      sx={{ p: 1 }}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", md: "center" }}
    >
      <Stack
        direction="row"
        justifyContent={{ xs: "space-between", md: "flex-start" }}
        sx={{ width: "100%" }}
        alignItems="center"
        spacing={0.5}
      >
        {isMobile ? <Caption>{nome}</Caption> : <Body2>{nome}</Body2>}

        {isMobile ? (
          <Caption fontWeight="bold">{dataFormatada}</Caption>
        ) : (
          <Body2 fontWeight="bold">{dataFormatada}</Body2>
        )}
      </Stack>
      <ChipSituacao situacao={situacao} minimal />
    </Stack>
  );
};
//
const InteracaoItemSecundario = ({ descricao, anexo }) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <EditorTextRich
        sx={{ minHeight: "auto" }}
        isReadOnly
        elevation={0}
        defaultValue={descricao}
      />
      {anexo?.length > 0 && <Divider sx={{ my: 1 }} />}
      <Stack direction="row" flexWrap="wrap">
        {anexo?.map((ele, idx) => (
          <Button
            size="small"
            sx={{ m: 1 }}
            key={idx}
            variant="outlined"
            href={ele}
            target="_blank"
          >
            {idx + 1}° {t("ouvidoria.attach")}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};
// Painel lateral de detalhes das solicitacoes
const Detalhes = ({
  id,
  dataCriacao,
  situacao,
  nome,
  avatar,
  tipo,
  tema,
  ultimaInteracao,
}) => {
  const { t } = useTranslation();
  //
  const isMobile = useTheme()?.isMobile;

  const opcoes = [
    {
      titulo: t("ouvidoria_view.ombudsmanStatus"),
      valor: <ChipSituacao situacao={situacao} />,
    },
    {
      titulo: `${t("ouvidoria_view.ombudsmanNumber")} `,
      valor: id,
      icone: "ConfirmationNumber",
    },
    {
      titulo: t("ouvidoria_view.createdAt"),
      valor: Utils.converterDataHora(dataCriacao),
      icone: "MoreTime",
    },
    {
      titulo: t("ouvidoria_view.lastInteraction"),
      valor: Utils.converterDataHora(ultimaInteracao),
      icone: "Today",
    },
    { titulo: t("ouvidoria_view.type"), valor: tipo, icone: "SwapHoriz" },
    { titulo: t("ouvidoria_view.theme"), valor: tema, icone: "Category" },
  ];

  return (
    <Stack sx={{ mt: 1 }}>
      {!isMobile && <H6>{t("ouvidoria_view.detailsOmbudsman")}</H6>}
      <Divider sx={{ my: 1 }} />
      <Paper sx={{ p: 2 }}>
        <ListItem dense divider disableGutters disablePadding>
          <ListItemAvatar>
            <Avatar alt={nome} src={avatar} />
          </ListItemAvatar>
          <ListItemText primary={nome} secondary={t("ouvidoria_view.owner")} />
        </ListItem>
        {opcoes.map((ele, idx) => (
          <DetalhesItemDestaque
            key={idx}
            titulo={ele.titulo}
            valor={ele.valor}
            icone={ele.icone}
            noDivider={ele.noDivider}
          />
        ))}
      </Paper>
    </Stack>
  );
};
//
const DetalhesItemDestaque = ({ noDivider, icone, titulo, valor }) => (
  <ListItem divider={noDivider ? false : true} dense>
    {icone && (
      <ListItemIcon>
        <Icone icone={icone} />
      </ListItemIcon>
    )}
    <ListItemText
      primary={
        <Stack direction="row" alignItems="center">
          <Body1 style={{ fontWeight: "bold" }}>{titulo}</Body1>&nbsp;&nbsp;{" "}
          {valor}
        </Stack>
      }
    />
  </ListItem>
);
// Interatividade na ouvidoria
const OuvidoriaInteragir = ({ idOuvidoria, fecharInteracao }) => {
  const { t } = useTranslation();
  const [aguardar, setAguardar] = useToggle();
  const dispatch = useDispatch();
  const situacao = useSelector(selectSituacao);
  const schema = [
    {
      name: "situacao",
      type: "select",
      autoFormat: true,
      itens: situacao,
      label: t("ouvidoria_view.situation"),
    },
    {
      name: "texto",
      type: "textrich",
      label: t("ouvidoria_view.situation"),
      placeholder: t("ouvidoria_view.placeholderText"),
    },
    {
      name: "arquivo",
      type: "file",
      label: t("ouvidoria_view.attach"),
      multiple: true,
    },
  ];
  const schemaMessageError = {
    texto: t("ouvidoria_view.errorDescription"),
    situacao: t("ouvidoria_view.errorSituation"),
  };
  const schemaValidator = yup.object().shape({
    texto: yup.array().min(1).required(),
    situacao: obterValidador(VALIDADOR_TIPO.selectUnico),
  });
  //
  const onSubmit = useCallback(
    async (val) => {
      const obj = {
        texto: val.texto,
        situacao: val.situacao.value,
        id_ouvidoria: idOuvidoria,
      };

      let resultFiles;

      try {
        resultFiles = await bucketSend(val.arquivo);
      } catch (e) {
        ToastErro(e);
        return;
      }

      obj.anexo = resultFiles;

      const formData = obj;
      dispatch(ouvidoriaUpd(formData, setAguardar, fecharInteracao));
    },
    [fecharInteracao, idOuvidoria, dispatch, setAguardar]
  );
  //
  return (
    <Paper elevation={1} sx={{ my: 1, p: 1 }}>
      <Container maxWidth={false}>
        <H6>{t("ouvidoria_view.titleInteraction")}</H6>
        <EntradaForm
          wait={aguardar}
          schema={schema}
          schemaMessageError={schemaMessageError}
          schemaValidator={schemaValidator}
          onSubmit={onSubmit}
        />
      </Container>
    </Paper>
  );
};

export default OuvidoriaDetalhes;
