import { format } from "date-fns";

// Gere a lista de anos
export const obterAnos = (anoInicial) => {
  const ANOS = [];
  const anoAtual = parseInt(format(new Date(), "yyyy"));
  while (anoInicial <= anoAtual) {
    ANOS.push(anoInicial);
    anoInicial++;
  }
  // Reverter os anos
  ANOS.reverse();
  return ANOS;
};

export const getMonths = (t) => {
  return [
    [1, t("contra_cheque.oneMonth")],
    [2, t("contra_cheque.twoMonth")],
    [3, t("contra_cheque.threeMonth")],
    [4, t("contra_cheque.fourMonth")],
    [5, t("contra_cheque.fiveMonth")],
    [6, t("contra_cheque.sixMonth")],
    [7, t("contra_cheque.sevenMonth")],
    [8, t("contra_cheque.eightMonth")],
    [9, t("contra_cheque.nineMonth")],
    [10, t("contra_cheque.tenMonth")],
    [11, t("contra_cheque.elevenMonth")],
    [12, t("contra_cheque.twelveMonth")],
    [13, t("contra_cheque.thirteenMonth")],
  ];
};
