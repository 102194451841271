import React, { useCallback } from "react";
import { ContainerAdaptavel } from "../../../extra-components";
import { EntradaForm, H6 } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "react-use";
import * as yup from "yup";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { ouvidoriaAdd } from "./ouvidoria-actions";

import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material";
import { bucketSend } from "../../../utils/bucket_send";
import { ToastErro } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { selectTemas, selectTipos } from "./ouvidoria-seletores";

function OuvidoriaAdd() {
  const { t } = useTranslation();
  return (
    <ContainerAdaptavel sx={{ minHeight: "50vh" }}>
      <H6>{t("ouvidoria_view.titleAdd")}</H6>
      <Formulario />
    </ContainerAdaptavel>
  );
}

const Formulario = () => {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;
  const history = useHistory();

  const [aguardar, setAguardar] = useToggle();
  const dispatch = useDispatch();
  const tipos = useSelector(selectTipos);
  const temas = useSelector(selectTemas);
  const schema = [
    {
      name: "tipo",
      autoFormat: true,
      type: "select",
      itens: tipos,
      label: t("ouvidoria_view.type"),
    },
    {
      name: "tema",
      autoFormat: true,
      type: "select",
      itens: temas,
      label: t("ouvidoria_view.theme"),
    },
    {
      name: "assunto",
      type: "text",
      label: t("ouvidoria_view.subject"),
      placeholder: t("ouvidoria_view.placeholderSubject"),
    },
    {
      name: "texto",
      type: "textrich",
      label: t("ouvidoria_view.description"),
      placeholder: t("ouvidoria_view.placeholderText"),
    },
    {
      name: "arquivo",
      type: "file",
      label: t("ouvidoria_view.attach"),
      multiple: true,
    },
  ];
  const schemaMessageError = {
    tipo: t("ouvidoria_view.errorType"),
    tema: t("ouvidoria_view.errorTheme"),
    assunto: t("ouvidoria_view.errorSubject"),
    texto: t("ouvidoria_view.errorDescription"),
  };

  const schemaValidator = yup.object().shape({
    tipo: obterValidador(VALIDADOR_TIPO.selectUnico),
    tema: obterValidador(VALIDADOR_TIPO.selectUnico),
    assunto: obterValidador(VALIDADOR_TIPO.texto, 3),
    texto: yup.array().min(1).required(),
  });
  //
  const onSubmit = useCallback(
    async (val) => {
      const obj = {
        tipo: parseInt(val.tipo.value),
        tema: parseInt(val.tema.value),
        assunto: val.assunto,
        texto: val.texto,
      };

      let resultFiles;

      try {
        resultFiles = await bucketSend(val.arquivo);
      } catch (e) {
        ToastErro(e);
        return;
      }

      obj.anexo = resultFiles.map((ele) => ele.url);
      //
      dispatch(
        ouvidoriaAdd(
          obj,
          setAguardar,
          isMobile ? () => history.goBack() : () => {}
        )
      );
    },
    [dispatch, setAguardar, isMobile, history]
  );

  return (
    <EntradaForm
      wait={aguardar}
      schema={schema}
      schemaMessageError={schemaMessageError}
      schemaValidator={schemaValidator}
      onSubmit={onSubmit}
    />
  );
};

OuvidoriaAdd.rota = "/ouvidoria_add";

export default OuvidoriaAdd;
