import { useSelector } from "react-redux";
import { seletorCardSecurity } from "./cartao-seguranca-selectors";
import VerDetalhesCartao from "./card-security-view-details-card";
import FormMoverAnalise from "./card-security-form-move-analitycs";
import FormValidarCartao from "./card-security-form-validate-card";
import FormJustificativa from "./card-security-form-justify";
import AddCartao from "./card-security-add-card";
import { ContainerAdaptavel } from "../../extra-components";

const CardSecurityModal = ({ modal }) => {
  const cardSecurity = useSelector(seletorCardSecurity);
  let body;

  const dados = modal.dados;
  switch (modal.tipo) {
    case CardSecurityModal.modal.VER_DETALHES: // Para ver detalhes de um cartao
      body = (
        <VerDetalhesCartao
          cartao={
            cardSecurity.cartoes.filter(
              (ele) => String(ele.id) === String(dados)
            )[0]
          }
          cartao_tipo_x_categoria={cardSecurity.cartao_tipo_x_categoria}
          cartao_tipo={cardSecurity.cartao_tipo}
          cartao_x_anexo={cardSecurity.cartao_x_anexo}
          cartao_x_solucao={cardSecurity.cartao_x_solucao}
          cartao_x_justificativa={cardSecurity.cartao_x_justificativa}
          cartao_x_validacao={cardSecurity.cartao_x_validacao}
          cartao_x_modelo={cardSecurity.cartao_x_modelo}
          id_cartao={dados}
        />
      );
      break;
    case CardSecurityModal.modal.ADD_CARTAO: // Para adicionar um cartao (intencao)
      body = <AddCartao {...cardSecurity} />;
      break;
    case CardSecurityModal.modal.MOVER_ANALISE: // Para chamar o componente de movimentação do cartao seguranca
      body = <FormMoverAnalise {...dados} />;
      break;
    case CardSecurityModal.modal.JUSTIFICATIVA: // Para ver a justificativa
      body = <FormJustificativa id_cartao={dados} />;
      break;
    case CardSecurityModal.modal.VALIDAR_CARTAO: // Para o usuario validar o cartao
      body = <FormValidarCartao id_cartao={dados} />;
      break;
    default:
      break;
  }

  return <ContainerAdaptavel>{body} </ContainerAdaptavel>;
};

// Setando o drawer
CardSecurityModal.modal = {
  VER_DETALHES: "VER_DETALHES",
  VALIDAR_CARTAO: "VALIDAR_CARTAO",
  ADD_CARTAO: "ADD_CARTAO",
  MOVER_ANALISE: "MOVER_ANALISE",
  JUSTIFICATIVA: "JUSTIFICATIVA",
};

export default CardSecurityModal;
