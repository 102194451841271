import React from "react";
import { H4, TabelaV2 } from "../../../components";

import BackgroundRouter from "../../background-router";
import {
  CircularProgress,
  Stack,
  Container,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
import { useFetch } from "../../../hooks";
import { useEffect } from "react";
import { optTabela, ToastErro } from "../../../utils/utils";
import { format, parseISO } from "date-fns";
import { useSelector } from "react-redux";
import { AtualizadorCache } from "../../../extra-components";
import { selectIsRH } from "./banco-de-horas-seletores";
import { useTranslation } from "react-i18next";

function BancoDeHoras() {
  const { t } = useTranslation();
  const isRH = useSelector(selectIsRH);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const usuario = useSelector((state) => state?.usuario);
  //
  const { error, data, wait, setFetch } = useFetch(window.location.pathname);

  useEffect(() => {
    setFetch({});
  }, [setFetch]);

  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);

  return (
    <BackgroundRouter>
      {isRH && (
        <AtualizadorCache titulo={t("banco_de_horas.titleUpdateCache")} />
      )}
      <Container maxWidth="md">
        <H4>{t("banco_de_horas.titlePage")}</H4>
        {usuario && (
          <Typography variant={isMobile ? "caption" : "body2"}>
            {usuario.usuario.matricula} - {usuario.usuario.nome}
          </Typography>
        )}
        <Divider />

        {wait ? (
          <Stack sx={{ my: 2 }} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : data ? (
          <Resultado {...data} />
        ) : null}
      </Container>
    </BackgroundRouter>
  );
}
//
const Resultado = ({ cabe, corpo, saldo_final }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  const optTabelaT = {
    ...optTabela,
    baixar_em_excel: false,
    ocultarFiltro: true,
    //calcularRodape: false,
    data: [0],
    alteraRodape: {
      0: "-",
      1: "-",
      2: t("banco_de_horas.saldoFinal"),
      3: saldo_final,
    },
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 2 }} disableGutters>
      {isMobile ? (
        <RenderMobile cabe={cabe} corpo={corpo} saldo_final={saldo_final} />
      ) : (
        <TabelaV2 cabe={cabe} corpo={corpo} {...optTabelaT} />
      )}
    </Container>
  );
};

// Renderiza a visualizacao mobile
const RenderMobile = ({ cabe, saldo_final, corpo }) => {
  const { t } = useTranslation();
  return (
    <List disablePadding disableGutters>
      <ListItem divider>
        <ListItemText align="left">{cabe[0]}</ListItemText>
        <ListItemText align="center">{cabe[1]} &nbsp;&nbsp;&nbsp;</ListItemText>
        <ListItemText align="right">{cabe[3]}</ListItemText>
      </ListItem>
      {corpo.map((ele, idx) => {
        const [data, descricao, tipo, hora] = ele;
        const sinal = tipo?.toLowerCase() === "negativo" ? "-" : "+";

        return (
          <ListItem key={idx} divider>
            <ListItemText align="left">
              {format(parseISO(data), "dd/MM/yy")}
            </ListItemText>
            <ListItemText
              primaryTypographyProps={{ fontSize: "12px" }}
              align="center"
            >
              {descricao}
            </ListItemText>
            <ListItemText align="right">{`${sinal}${hora}`}</ListItemText>
          </ListItem>
        );
      })}
      <ListItem divider>
        <ListItemText
          primaryTypographyProps={{
            color: (theme) => theme.palette.primary.main,
            fontWeight: "bold",
          }}
          align="left"
        >
          {t("banco_de_horas.saldoFinal")}
        </ListItemText>
        <ListItemText align="right">{saldo_final}</ListItemText>
      </ListItem>
    </List>
  );
};

BancoDeHoras.rota = "/banco_de_horas";

export default BancoDeHoras;
