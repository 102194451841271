import {
  CADASTRO_PROJETOS_GET_DADOS,
  CADASTRO_PROJETOS_DRAWER,
  CADASTRO_PROJETOS_GET_PROJETOS,
  CADASTRO_PROJETOS_ATUALIZAR_STATUS,
  CADASTRO_PROJETOS_GET_HISTORICO,
  CADASTRO_PROJETOS_ADD_JUSTIFICATIVA,
  CADASTRO_PROJETOS_PROXIMA_PAGINA,
  CADASTRO_PROJETOS_FILTRO_AVANCADO,
  CADASTRO_PROJETOS_FILTRO_AVANCADO_FECHAR,
  CADASTRO_PROJETOS_GET_DADOS_GRAFICO,
  CADASTRO_PROJETOS_UPD_MEMBROS,
} from "./kaizen-actions";

export default function cadastroProjetosReducer(state = null, action) {
  switch (action.type) {
    case CADASTRO_PROJETOS_GET_DADOS:
      return {
        ...state,
        dadosForm: action.data,
      };
    case CADASTRO_PROJETOS_GET_PROJETOS:
      return {
        ...state,
        filtro: action.data,
      };
    case CADASTRO_PROJETOS_DRAWER: // Para determinar o Drawer
      return {
        ...state,
        drawer: action.data,
      };
    case CADASTRO_PROJETOS_PROXIMA_PAGINA: // Para dados que devem ser acumulados no state
      return {
        ...state,
        ...action.data,
        filtro: null,
      };
    case CADASTRO_PROJETOS_ATUALIZAR_STATUS: // Para atualizar o status de um projeto
      return (() => {
        const { id_projeto, status } = action.data;
        let newState = state.projetos.slice();
        newState.forEach((ele) => {
          //
          if (String(ele.id) === String(id_projeto)) {
            // Decrementa do status atual
            if (state.qtd_por_status.hasOwnProperty(ele.situacao)) {
              state.qtd_por_status[ele.situacao] -= 1;
            }
            ele.situacao = status;
            // Incrementa no status que foi atualizado
            if (state.qtd_por_status.hasOwnProperty(status)) {
              state.qtd_por_status[status] += 1;
            }
          }
        });

        return {
          ...state,
          projetos: newState,
        };
      })();
    case CADASTRO_PROJETOS_GET_HISTORICO: // Para exibir um historico
      return {
        ...state,
        historico: action.data,
      };
    case CADASTRO_PROJETOS_ADD_JUSTIFICATIVA: // Para quando um projeto e justificado
      return (() => {
        const { projetos } = state;
        const { nova_data_prevista, id_projeto, justificativa_x_projeto } =
          action.data;
        // Filtre o projeto a ser atualizado como nao atrasado
        projetos.forEach((ele) => {
          if (String(ele.id) === String(id_projeto)) {
            ele.atrasado = false;
            ele.nova_data_prevista = nova_data_prevista;
          }
        });
        // Retorne o novo objeto
        return {
          ...state,
          projetos: projetos,
          justificativa_x_projeto: {
            ...state.justificativa_x_projeto,
            ...justificativa_x_projeto,
          },
        };
      })();
    case CADASTRO_PROJETOS_FILTRO_AVANCADO: // Para aplicacao de um filtro avancado
      return {
        ...state,
        filtroAvancado: action.data,
      };
    case CADASTRO_PROJETOS_FILTRO_AVANCADO_FECHAR: // Para retirar o filtro avancado
      return {
        ...state,
        filtroAvancado: null,
      };
    case CADASTRO_PROJETOS_UPD_MEMBROS: // Para atualizar os membros dos projetos
      return (() => {
        const copia = { ...state };
        // Se filtro esta ativo deve ser atualizado seu valor interno
        if (copia?.filtro) {
          copia.filtro = {
            ...copia.filtro,
            projeto_membros: {
              ...copia.filtro?.projeto_membros,
              ...action.data,
            },
          };
        }
        // Se filtroAvancado esta ativo deve ser atualizado seu valor interno
        if (copia?.filtroAvancado) {
          copia.filtroAvancado = {
            ...copia.filtroAvancado,
            projeto_membros: {
              ...copia.filtroAvancado?.projeto_membros,
              ...action.data,
            },
          };
        }

        return {
          ...copia,
          projeto_membros: {
            ...copia?.projeto_membros,
            ...action.data,
          },
        };
      })();

    case CADASTRO_PROJETOS_GET_DADOS_GRAFICO: // Para os dados do grafico
      return {
        ...state,
        grafico: action.data,
      };
    default:
      return state;
  }
}
