import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { EntradaForm, H6 } from "../../../components";
import { useDispatch } from "react-redux";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { useToggle } from "react-use";
import * as yup from "yup";
import { helpdeskAddUpdCriticality } from "./helpdesk-actions";
import { useTranslation } from "react-i18next";

const FIELDS = {
  name: "descricao",
  icon: "icone",
  situation: "situacao",
};
//

function HelpdeskCriticidadeAddUpd({ id, descricao, icone, situacao }) {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const situationOptions = [
    ["A", t("helpdesk.labelActive")],
    ["B", t("helpdesk.labelDeactiveAddCritical")],
  ];
  //
  const schema = [
    {
      type: "text",
      name: FIELDS.name,
      label: t("helpdesk.labelNameAddCritical"),
      defaultValue: descricao || "",
      placeholder: t("helpdesk.placeholderNameAddCritical"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "text",
      label: t("helpdesk.labelIcon"),
      name: FIELDS.icon,
      min: 1,
      defaultValue: icone || "",
      placeholder: t("helpdesk.placeholderIcon"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "radio",
      name: FIELDS.situation,
      itens: situationOptions,
      defaultValue: situacao || "",
      placeholder: t("helpdesk.placeholderSituationAddCritical"),
    },
  ];
  //
  const onSubmit = useCallback(
    (val) => {
      const obj = {
        [FIELDS.name]: val[FIELDS.name],
        [FIELDS.icon]: val[FIELDS.icon],
        [FIELDS.situation]: val[FIELDS.situation],
      };
      if (id) {
        obj.id = id;
      }
      //
      dispatch(helpdeskAddUpdCriticality(obj, setWait));
    },
    [dispatch, setWait, id]
  );
  //
  const schemaValidator = yup.object().shape({
    [FIELDS.name]: obterValidador(VALIDADOR_TIPO.texto, 3),
    [FIELDS.icon]: obterValidador(VALIDADOR_TIPO.texto, 1),
    [FIELDS.situation]: obterValidador(VALIDADOR_TIPO.texto, 1),
  });

  const schemaMessageError = {
    [FIELDS.name]: t("helpdesk.errorNameAddCritical"),
    [FIELDS.icon]: t("helpdesk.errorIcon"),
    [FIELDS.situation]: t("helpdesk.errorSituationAddCritical"),
  };
  return (
    <Stack>
      <H6>
        {id
          ? t("helpdesk.titleAddCriticalUpd")
          : t("helpdesk.titleAddCritical")}
      </H6>
      <EntradaForm
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
        wait={wait}
        onSubmit={onSubmit}
      />
    </Stack>
  );
}

export default HelpdeskCriticidadeAddUpd;
