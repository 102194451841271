import { ButtonBase, Paper, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { AnimacaoCadeado, Body1, H4, H6 } from "../../../components";
import { ContainerAdaptavel } from "../../../extra-components";
import { useCopyToClipboard } from "react-use";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function OuvidoriaTokenCopia({ token }) {
  const { t } = useTranslation();
  const [state, copyToClipboard] = useCopyToClipboard();
  //
  useEffect(() => {
    if (state.value) {
      toast.dark(t("ouvidoria_view.tokenCopySuccess"), {
        type: "success",
      });
    }
  }, [state, t]);
  return (
    <ContainerAdaptavel>
      <H6>{t("ouvidoria_view.titleOmbudsmanCopyToken")}</H6>
      <Body1 align="center">{t("ouvidoria_view.descriptionToken")}</Body1>
      <br />
      <Stack alignItems="center">
        <Paper>
          <ButtonBase sx={{ p: 1 }} onClick={() => copyToClipboard(token)}>
            <H4>{token}</H4>
          </ButtonBase>
        </Paper>
      </Stack>

      <AnimacaoCadeado />
    </ContainerAdaptavel>
  );
}

export default OuvidoriaTokenCopia;
