export const SET_MODAL = 'SET_MODAL';
export const FECHAR_MODAL = 'FECHAR_MODAL';

// Action para definição de modal
export const setModal = (obj)=>({
    type: SET_MODAL,
    obj
});

// Action para limpeza de modal
export const fecharModal = ()=>({
    type: FECHAR_MODAL,
});