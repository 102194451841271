import { ContainerAdaptavel } from "../../../extra-components";
import EnviarNotificacaoPorSetor from "./painel-de-notificacao-envio-por-setor";
import EnviarNotificacaoAvulsa from "./painel-de-notificacao-send-notification-one";
import EnviarNotificacaoPorPlanta from "./painel-de-notificacao-send-notification-plant";

const PainelDeNotificacaoModal = ({ modal, fecharModal }) => {
  let body;
  switch (modal.tipo) {
    case PainelDeNotificacaoModal.modal.SETOR:
    default:
      body = (
        <EnviarNotificacaoPorSetor {...modal.dados} fecharModal={fecharModal} />
      );
      break;
    case PainelDeNotificacaoModal.modal.PLANTA: // Envia notificação para a planta
      body = (
        <EnviarNotificacaoPorPlanta
          {...modal.dados}
          fecharModal={fecharModal}
        />
      );
      break;
    case PainelDeNotificacaoModal.modal.AVULSA: // Envio de notificacao avulsa
      body = (
        <EnviarNotificacaoAvulsa {...modal.dados} fecharModal={fecharModal} />
      );
      break;
  }

  return <ContainerAdaptavel>{body}</ContainerAdaptavel>;
};

PainelDeNotificacaoModal.modal = {
  SETOR: "SETOR",
  PLANTA: "PLANTA",
  AVULSA: "AVULSA",
};

export default PainelDeNotificacaoModal;
