import React, { useEffect } from "react";
import BackgroundRouter from "../../background-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useFetch } from "../../../hooks";
import { ToastErro } from "../../../utils/utils";
import { Pesquisando } from "../../../components";
import { Container, useTheme } from "@mui/material";
import NcpForm, { NcpGoBack } from "./ncp-form";

function NcpDetalhes() {
  const { data, wait, setFetch, error } = useFetch("/ncp", "GET");
  const history = useHistory();

  // Valores retornados em caso de uma visualização do formulário
  const ncpCodigo = history?.location?.state?.id;
  //
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  //
  useEffect(() => {
    setFetch({
      ncp: ncpCodigo,
    });
  }, [ncpCodigo, setFetch]);

  return (
    <BackgroundRouter>
      {wait ? <Pesquisando /> : data ? <Corpo {...data} /> : null}
    </BackgroundRouter>
  );
}
//
const Corpo = ({ ncp_info }) => {
  const isMobile = useTheme()?.isMobile;
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ pt: 1, background: ({ backgroundPage }) => backgroundPage }}
    >
      {!isMobile && <NcpGoBack />}
      <NcpForm dataItem={ncp_info} />
    </Container>
  );
};
//

NcpDetalhes.rota = "/ncp_detalhes";

export default NcpDetalhes;
