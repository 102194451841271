import React from "react";
import BackgroundRouter from "../../background-router";
import { Container } from "@mui/material";
import { H6 } from "../../../components";
import InformeRendimentosCorpo from "./informe-rendimentos-body";
import { useTranslation } from "react-i18next";

function InformeRendimentos() {
  const { t } = useTranslation();
  return (
    <BackgroundRouter>
      <Container maxWidth="lg">
        <H6>{t("informe_rendimentos.titlePage")}</H6>
        <InformeRendimentosCorpo />
      </Container>
    </BackgroundRouter>
  );
}

InformeRendimentos.rota = "/informe_rendimentos";

export default InformeRendimentos;
