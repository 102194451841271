import React from 'react';
import Logo from '../images/logo.svg';
import LogoBranca from '../images/logo_branca.svg';
import { Paper, Box, useTheme } from '@mui/material';

const SX = {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    //background: '#012258',
    //pt: .5,
}
const SXBOX = {
    backgroundColor: 'transparent',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    px: {xs: 0, sm: 3},
    borderTop: '4px solid #012258',
    
    
}

export default function HeaderDefault({ children }) {
    const isDarkMode = useTheme()?.palette?.mode === "dark";
  return (
      <Paper sx={SX}>
        <Paper elevation={4} sx={{
            flex: 1,
            alignSelf: 'stretch',
            borderRadius: '0 0px 30px 0',
            backgroundColor: '#012258',
        }} 
        />
        <Box sx={SXBOX}>
            <img style={{marginRight: '4px'}} src={isDarkMode ? LogoBranca : Logo} height={20} alt="LOGO TIBERINA" />
            { children }
        </Box>
    </Paper>
  )
}
