import axios from "axios";
import { toast } from "react-toastify";
import { setAguardar } from "../../../redux/actions/aguardar-actions";
import {
  ERRO_NAO_ENVIADO,
  ERRO_STATUS_NAO_200,
} from "../../../redux/actions/erro-actions";
import { ToastErro } from "../../../utils/utils";

export const MANUTENCAO_VARIAVEIS_GET_DADOS = "MANUTENCAO_VARIAVEIS_GET_DADOS";
export const MANUTENCAO_VARIAVEIS_ATIVA_VAR_USUARIO =
  "MANUTENCAO_VARIAVEIS_ATIVA_VAR_USUARIO";
export const MANUTENCAO_VARIAVEIS_DESATIVA_VAR_USUARIO =
  "MANUTENCAO_VARIAVEIS_DESATIVA_VAR_USUARIO";
export const MANUTENCAO_VARIAVEIS_ADD_VARIAVEL =
  "MANUTENCAO_VARIAVEIS_ADD_VARIAVEL";
export const MANUTENCAO_VARIAVEIS_DEL_VARIAVEL =
  "MANUTENCAO_VARIAVEIS_DEL_VARIAVEL";
export const MANUTENCAO_VARIAVEIS_UPD_VARIAVEL =
  "MANUTENCAO_VARIAVEIS_UPD_VARIAVEL";
export const MANUTENCAO_VARIAVEIS_FILTRO_COLABORADOR =
  "MANUTENCAO_VARIAVEIS_FILTRO_COLABORADOR"; // Para implementar o filtro de colaborador
export const MANUTENCAO_VARIAVEIS_FILTRO_PLANTA =
  "MANUTENCAO_VARIAVEIS_FILTRO_PLANTA"; // Para filtro de planta
export const MANUTENCAO_VARIAVEIS_SET_DRAWER =
  "MANUTENCAO_VARIAVEIS_SET_DRAWER"; // Para exibir o drawer
export const MANUTENCAO_VARIAVEIS_FECHAR_DRAWER =
  "MANUTENCAO_VARIAVEIS_FECHAR_DRAWER"; // Para fechar o drawer
export const MANUTENCAO_VARIAVEIS_GLOBAIS_GET_DADOS =
  "MANUTENCAO_VARIAVEIS_GLOBAIS_GET_DADOS"; // Para obter a lista de variaveis globais
export const MANUTENCAO_VARIAVEIS_GLOBAL_UPD =
  "MANUTENCAO_VARIAVEIS_GLOBAL_UPD"; // Para atualizar tanto o valor quanto a descricao da variavel global
export const MANUTENCAO_VARIAVEIS_GLOBAL_DEL =
  "MANUTENCAO_VARIAVEIS_GLOBAL_DEL"; // Para excluir uma variavel global
export const MANUTENCAO_VARIAVEIS_GLOBAL_ADD =
  "MANUTENCAO_VARIAVEIS_GLOBAL_ADD"; // Para adicionar uma nova variavel global

const ROTAS = [
  "/colaborador_x_variavel",
  "/manutencao_variaveis",
  "/manutencao_x_variavel_global",
];

export const manutencaoVariaveisGetDados = () => (dispatch) => {
  dispatch(setAguardar(true));
  // Cria o form dos parametros e solicita novos dados da pagina
  const formData = new FormData();
  formData.append("tipo", "todos");

  const params = new URLSearchParams(formData);

  axios
    .get(`${ROTAS[0]}?${params.toString()}`)
    .then((resp) => {
      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200);

        return false;
      }
      // Verifica se retornou erro
      if (resp.data.erro) {
        toast(resp.data.erro);
        return false;
      }
      // Dados atualizados
      dispatch({
        type: MANUTENCAO_VARIAVEIS_GET_DADOS,
        data: resp.data, // colaboradores, variaveis, colaborador_x_variavel
      });
    })
    .catch((err) => {
      toast(ERRO_NAO_ENVIADO);
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para ativar ou desativar a variavel
export const manutencaoVariaveisAtivaDesativaVariavel =
  (obj, ativar) => (dispatch) => {
    dispatch(setAguardar(true));
    // Cria o form dos parametros e solicita novos dados da pagina
    const formData = new FormData();
    formData.append("dados", JSON.stringify(obj));
    //
    const fn = ativar ? axios.post : axios.delete;

    let data = formData;

    if (!ativar) {
      // E um delete
      data = { data: formData };
    }

    fn(ROTAS[0], data)
      .then((resp) => {
        if (resp.status !== 200) {
          toast(ERRO_STATUS_NAO_200);

          return false;
        }
        // Verifica se retornou erro
        if (resp.data.erro) {
          ToastErro(resp.data.erro);

          return false;
        }
        // Dados atualizados
        dispatch({
          type: ativar
            ? MANUTENCAO_VARIAVEIS_ATIVA_VAR_USUARIO
            : MANUTENCAO_VARIAVEIS_DESATIVA_VAR_USUARIO,
          data: ativar ? resp.data.data : obj, // {id_variavel, matricula}
        });
      })
      .catch((err) => {
        console.log(err);
        toast(ERRO_NAO_ENVIADO);
      })
      .finally(() => {
        dispatch(setAguardar(false));
      });
  };
// Action para criar uma variavel
export const manutencaoVariaveisAddVariavel = (obj) => (dispatch) => {
  dispatch(setAguardar(true));
  // Cria o form dos parametros e solicita novos dados da pagina
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));
  //

  axios
    .post(ROTAS[1], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200);
        return false;
      }
      // Verifica se retornou erro
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      // Dados atualizados
      dispatch({
        type: MANUTENCAO_VARIAVEIS_ADD_VARIAVEL,
        data: resp.data.data, // {id, nome, descricao}
      });
      dispatch(manutencaoVariaveisFecharDrawer());
    })
    .catch((err) => {
      toast(ERRO_NAO_ENVIADO);
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para editar e excluir variavel
export const manutencaoVariaveisUpdDelVariavel =
  (obj, excluir) => (dispatch) => {
    if (excluir) {
      // Se o usuario desejar excluir
      if (!window.confirm("Deseja realmetne excluir ?")) {
        return false;
      }
    }
    dispatch(setAguardar(true));
    // Cria o form dos parametros e solicita novos dados da pagina
    const formData = new FormData();
    formData.append("dados", JSON.stringify(obj));
    //
    const fn = excluir ? axios.delete : axios.put;

    let data = formData;
    if (excluir) data = { data: formData };

    fn(ROTAS[1], data)
      .then((resp) => {
        if (resp.status !== 200) {
          toast(ERRO_STATUS_NAO_200);
          return false;
        }
        // Verifica se retornou erro
        if (resp.data.erro) {
          toast(resp.data.erro);
          return false;
        }
        // Dados atualizados
        dispatch({
          type: excluir
            ? MANUTENCAO_VARIAVEIS_DEL_VARIAVEL
            : MANUTENCAO_VARIAVEIS_UPD_VARIAVEL,
          data: excluir ? obj : resp.data.data, // {id, nome, descricao}
        });
        //if(excluir) dispatch(manutencaoVariaveisFecharDrawer());
        dispatch(manutencaoVariaveisFecharDrawer());
      })
      .catch((err) => {
        toast(ERRO_NAO_ENVIADO);
      })
      .finally(() => {
        dispatch(setAguardar(false));
      });
  };
// Action para o filtro de colaborador
export const manutencaoVariaveisSetFiltroColaborador = (val) => ({
  type: MANUTENCAO_VARIAVEIS_FILTRO_COLABORADOR,
  data: val,
});
//
export const manutencaoVariaveisSetFiltroPlanta = (val) => ({
  type: MANUTENCAO_VARIAVEIS_FILTRO_PLANTA,
  data: val,
});
// Action para cuidar do drawer
export const manutencaoVariaveisSetDrawer = (val) => ({
  type: MANUTENCAO_VARIAVEIS_SET_DRAWER,
  data: val,
});

// Action para cuidar do drawer
export const manutencaoVariaveisFecharDrawer = (val) => ({
  type: MANUTENCAO_VARIAVEIS_FECHAR_DRAWER,
});
// Action para obter a lista de variaveis globais
export const manutencaoVariaveisGlobaisGetDados = () => (dispatch) => {
  dispatch(setAguardar(true));

  axios
    .get(ROTAS[2])
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);

        return false;
      }
      // Verifica se retornou erro
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      // Dados atualizados
      dispatch({
        type: MANUTENCAO_VARIAVEIS_GLOBAIS_GET_DADOS,
        data: resp.data, // array variaveis globais
      });
    })
    .catch((err) => {
      toast(ERRO_NAO_ENVIADO);
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para atualizar a descricao da varaivel
export const manutencaoVariaveisUpdDescricaoValorVariavelGlobal =
  (obj, editValor) => (dispatch) => {
    dispatch(setAguardar(true));
    // Cria o form dos parametros e solicita novos dados da pagina
    const formData = new FormData();
    formData.append("dados", JSON.stringify(obj));
    // Se patch é valor, put e a descricao
    const fn = editValor ? axios.patch : axios.put;

    const data = formData;

    fn(ROTAS[2], data)
      .then((resp) => {
        if (resp.status !== 200) {
          ToastErro(ERRO_STATUS_NAO_200);
          return false;
        }
        // Verifica se retornou erro
        if (resp.data.erro) {
          ToastErro(resp.data.erro);
          return false;
        }
        //
        toast.dark(resp.data.sucesso, {
          type: "success",
        });
        // Dados atualizados
        dispatch({
          type: MANUTENCAO_VARIAVEIS_GLOBAL_UPD, // Tanto descricao quanto valor lidam com a mesma mudança das variaveis
          data: resp.data.data, // {id_variavel, nome , descricao, valor }
        });
      })
      .catch((err) => {
        toast(ERRO_NAO_ENVIADO);
      })
      .finally(() => {
        dispatch(setAguardar(false));
      });
  };
// Action para excluir uma variavel global
export const manutencaoVariaveisDelVariavelGlobal = (obj) => (dispatch) => {
  dispatch(setAguardar(true));
  // Cria o form dos parametros e solicita novos dados da pagina
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));
  //
  const fn = axios.delete;

  const data = { data: formData };

  fn(ROTAS[2], data)
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Verifica se retornou erro
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Dados atualizados
      dispatch({
        type: MANUTENCAO_VARIAVEIS_GLOBAL_DEL,
        data: obj.id_variavel,
      });
    })
    .catch((err) => {
      ToastErro(ERRO_NAO_ENVIADO);
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para inserir variavel
export const manutencaoVariaveisAddVariavelGlobal = (obj) => (dispatch) => {
  dispatch(setAguardar(true));
  // Cria o form dos parametros e solicita novos dados da pagina
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  axios
    .post(ROTAS[2], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Verifica se retornou erro
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      //
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Dados atualizados
      dispatch({
        type: MANUTENCAO_VARIAVEIS_GLOBAL_ADD, // Insercao de uma nova variavel global
        data: resp.data.data,
      });
    })
    .catch((err) => {
      ToastErro(ERRO_NAO_ENVIADO);
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
