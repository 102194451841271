import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectAtivoGestor } from "./colaboradores-select";
import { useCallback } from "react";
import {
  colaboradoresUpdElegivel,
  colaboradoresUpdGestorArvoreSituacao,
} from "./colaboradores-actions";
import {
  Checkbox,
  Container,
  FormControlLabel,
  ListItem,
  Stack,
} from "@mui/material";
import { Body1, Caption, H6, Icone } from "../../../components";

//
const sxMinMaxHeight = {
  minHeight: "50vh",
  maxHeight: "80vh",
  overflow: "auto",
};

// Renderiza o componente responsavel por atribuir gestor, elegivel e ativo
const RenderFormAtivoGestor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { matricula, isGestor, isAtivo, isElegivel } =
    useSelector(selectAtivoGestor);
  // Funcao de callback para atualizar tanto o gestor quanto a situacao
  const fnAtualizaDados = useCallback(
    (alterarGestor, alterarAtivo) => {
      dispatch(
        colaboradoresUpdGestorArvoreSituacao(
          {
            gestor: alterarGestor ? "S" : "N",
            situacao: alterarAtivo ? "A" : "B",
            matricula,
          },
          true
        )
      );
    },
    [dispatch, matricula]
  );
  //Callback para casos que o usuario é elegivel ou nao
  const fnAtualizaDadosElegivel = useCallback(
    (alterarElegivel) => {
      dispatch(
        colaboradoresUpdElegivel({
          elegivel: alterarElegivel ? "S" : "N",
          matricula,
        })
      );
    },
    [dispatch, matricula]
  );
  const iconeColor = "info";
  const textoColor = "info.main";

  return (
    <Container maxWidth="md" sx={sxMinMaxHeight}>
      <H6>{t("colaboradores.managerActiveEligibleTitle")}</H6>
      <Caption>{t("colaboradores.managerActiveEligibleDescription")}</Caption>
      <Stack direction="column" alignItems="flex-start">
        <ListItem divider>
          <FormControlLabel
            onChange={(e) => fnAtualizaDados(e.target.checked, isAtivo)}
            control={<Checkbox checked={isGestor} />}
            label={
              <Stack direction="row" spacing={1}>
                <Icone color={isGestor ? iconeColor : ""} icone="Engineering" />
                <Body1 color={isGestor ? textoColor : ""}>
                  {t("colaboradores.filterLabelManager")}
                </Body1>
              </Stack>
            }
          />
        </ListItem>
        <ListItem divider>
          <FormControlLabel
            onChange={(e) => fnAtualizaDados(isGestor, e.target.checked)}
            control={<Checkbox checked={isAtivo} />}
            label={
              <Stack direction="row" spacing={1}>
                <Icone color={isAtivo ? iconeColor : ""} icone="ThumbsUpDown" />
                <Body1 color={isAtivo ? textoColor : ""}>
                  {t("colaboradores.labelEnableDisable")}
                </Body1>
              </Stack>
            }
          />
        </ListItem>
        <ListItem divider>
          <FormControlLabel
            onChange={(e) => fnAtualizaDadosElegivel(e.target.checked)}
            control={<Checkbox checked={isElegivel} />}
            label={
              <Stack direction="row" spacing={1}>
                <Icone color={isElegivel ? iconeColor : ""} icone="Build" />
                <Body1 color={isElegivel ? textoColor : ""}>
                  {t("colaboradores.filterLabelEligible")}
                </Body1>
              </Stack>
            }
          />
        </ListItem>
      </Stack>
    </Container>
  );
};

export default RenderFormAtivoGestor;
