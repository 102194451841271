import { Paper, Typography } from "@mui/material";
import FormatarDados from "./estatisticas-format-data";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";

// Renderiza um acesso por usuario
const RenderGrafTopSeteUsuario = ({ isMobile, data }) => {
  const { t } = useTranslation();
  const novosDados = FormatarDados(0, data);
  // Agora nomear cada dia dando o seu valor
  let dadosParaGrafico = Object.keys(novosDados).map((key) => ({
    nome: key,
    valor: novosDados[key],
  }));
  // Ordena e fatia
  dadosParaGrafico.sort((a, b) => (a.valor < b.valor ? 1 : -1));
  // Fatia para os 7
  dadosParaGrafico = dadosParaGrafico.splice(0, 7);

  return (
    <Paper sx={{ p: 2, m: 1 }} elevation={2}>
      <Typography align="center" variant="subtitle2">
        {t("estatisticas.titleTopSevenUser")}
      </Typography>

      <ResponsiveContainer width="100%" height={350}>
        <BarChart margin={{ top: 30, bottom: 10 }} data={dadosParaGrafico}>
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#005387" stopOpacity={1}></stop>
              <stop offset="90%" stopColor="#005387" stopOpacity={0.5}></stop>
            </linearGradient>
          </defs>

          <XAxis
            hide={isMobile}
            angle={0}
            height={45}
            tickLine={false}
            axisLine={false}
            dataKey="nome"
            tickFormatter={(str) => str.split("-")[0]}
          />

          {!isMobile && (
            <YAxis tickLine={false} axisLine={false} tickCount={8} />
          )}
          <Tooltip />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Bar dataKey="valor" fill="url('#color')">
            <LabelList dataKey="valor" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default RenderGrafTopSeteUsuario;
