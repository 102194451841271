import axios from 'axios';
import { fecharAlerta, setAlerta } from './alerta-actions';
import { ERRO_NAO_ENVIADO, ERRO_STATUS_NAO_200 } from './erro-actions';

export const AREA_DO_GESTOR_INIT = 'AREA_DO_GESTOR_INIT'; // Buscar os dados iniciais 
export const AREA_DO_GESTOR_OBTER_QUESTIONARIO = 'AREA_DO_GESTOR_OBTER_QUESTIONARIO'; // Faz a solicitação de um questionari
export const AREA_DO_GESTOR_FECHAR_QUESTIONARIO = 'AREA_DO_GESTOR_FECHAR_QUESTIONARIO'; // Determina que o questionario pode ser fechado
export const AREA_DO_GESTOR_ENVIAR_RESPOSTA = 'AREA_DO_GESTOR_ENVIAR_RESPOSTA'; // Quando o formulario for respondido

const ROTAS = [
    '/area_do_gestor',
    '/questionario',
]

export const areaDoGestorInit = ()=> dispatch => {

    axios.post(ROTAS[0]).then((resp)=>{
        if(resp.status !== 200){
            alert('ERRO');
            return false;
        }
        if(resp.data.erro){
            alert(resp.data.erro);
            return false;
        }
        // Deu tudo certo  
        dispatch({
            type: AREA_DO_GESTOR_INIT,
            data: resp.data,
        })


    }).catch(err=>{
        console.log(err);
    });

}
// Solicita o questionario
export const areaDoGestorObterQuestionario = (cod_questionario, matricula, nomeAvaliado) => dispatch => {

    const ROTA = `${ROTAS[1]}?cod_avaliacao=${cod_questionario}`;

    axios.get(ROTA).then((resp)=>{
        if(resp.status !== 200){
            alert('ERRO');
            return false;
        }
        if(resp.data.erro){
            alert(resp.data.erro);
            return false;
        }
        // Deu tudo certo 
        dispatch({
            type: AREA_DO_GESTOR_OBTER_QUESTIONARIO,
            data: {...resp.data, 'matricula': matricula, 'nomeAvaliado': nomeAvaliado },
        });
        
    }).catch(err=>{
        console.log(err);
    });

}
// Action creator para fechar o questionario
export const areaDoGestorFecharQuestionario = ()=>({
    type: AREA_DO_GESTOR_FECHAR_QUESTIONARIO,
});
// Action para a conclusao da resposta de um questionario
export const areaDoGestorEnviarResposta = (obj)=> dispatch => {
    // Informando ao usuario que estamos enviando os dados
    dispatch(setAlerta('AGUARDE, ESTAMOS CONFIRMANDO O ENVIO...'));

    // AREA_DO_GESTOR_ENVIAR_RESPOSTA
    const formData = new FormData();
    formData.append('dados', JSON.stringify(obj));
    
    axios.post(ROTAS[1], formData).then((resp)=>{
        if(resp.status !== 200){
            dispatch(setAlerta(ERRO_STATUS_NAO_200))
            return false;
        }
        if(resp.data.erro){
            dispatch(setAlerta(resp.data.erro));
            return false;
        }
        // Deu tudo certo
        dispatch(setAlerta(resp.data.sucesso))

        dispatch({
            type: AREA_DO_GESTOR_ENVIAR_RESPOSTA,
            data: {id_avaliacao: obj.id_avaliacao, matricula: obj.mat_avaliado}
        });
        setTimeout(()=> dispatch(fecharAlerta()) , 2000);

    }).catch(err=>{
        dispatch(setAlerta(ERRO_NAO_ENVIADO))
        console.log(err);
    });
}