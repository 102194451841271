// funcao de apoio para obter os status
export const getStatusText = (st, t) => {
  switch (st) {
    case "A":
      return t("twttp.twttpStatusA");
    case "V":
      return t("twttp.twttpStatusV");
    case "Y":
      return t("twttp.twttpStatusY");
    case "P":
      return t("twttp.twttpStatusP");
    case "S":
      return t("twttp.twttpStatusS");
    case "E":
      return t("twttp.twttpStatusE");
    case "VR":
      return t("twttp.twttpStatusVR");
    case "C":
      return t("twttp.twttpStatusC");
    case "BPDF":
      return t("twttp.twttpStatusBPDF");
    default: // caso R
      return t("twttp.twttpStatusR");
  }
};
// Funcao para obter as cores dos status
export const getStatusCor = (st) => {
  switch (st) {
    case "A":
      return {
        backgroundColor: (theme) => theme.palette.success.light,
        color: "black",
      };
    case "V": // Validacao Anexo de em execucao
    case "Y": // Validacao Superior
      return {
        backgroundColor: (theme) => theme.palette.info.main,
        color: "black",
      };
    case "P": // Em entrevista (formulario 1 respondido)
      return {
        backgroundColor: (theme) => theme.palette.info.light,
        color: "black",
      };
    case "S":
      return {
        backgroundColor: (theme) => theme.palette.warning.main,
        color: "black",
      };
    case "E":
      return {
        backgroundColor: (theme) => theme.palette.success.main,
        color: "white",
      };
    case "C": // Cancelado
      return {
        backgroundColor: (theme) => theme.palette.error.light,
        color: "black",
      };
    case "BPDF":
      return {
        backgroundColor: (theme) => theme.palette.info.light,
        color: "black",
      };
    default: // caso R
      return {
        backgroundColor: (theme) => theme.palette.error.dark,
        color: "white",
      };
  }
};
// Funcao para obter o icone
export const getStatusIcone = (st) => {
  switch (st) {
    case "A": // Aberto
      return "Person";
    case "P": // Em entrevista
    case "V": // Validação
    case "Y": // Validação Superior
      return "Rule";
    case "S": // Em execução
      return "SwapHoriz";
    case "E": // Encerrado
      return "ThumbUp";
    case "VR": // Ver respostas
      return "Check";
    case "C": // Cancelado
      return "DoDisturb";
    case "BPDF": // Baixar PDF
      return "PictureAsPdf";
    default: // caso R (Reprovado)
      return "ThumbDown";
  }
};
