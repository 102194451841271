import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./normalize.css";

import "./i18n";
//import * as serviceWorker from './serviceWorker';

// Provider e connect e também a loja (store)
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

// Importar o redux-thunk
import reduxThunk from "redux-thunk";

// O devTools
//import { composeWithDevTools } from 'remote-redux-devtools';

// Importando o rootReducer
import rootReducer from "./redux/reducers";

// --> PROD
const store = createStore(rootReducer, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />{" "}
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
