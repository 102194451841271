import fetchRedux from "../../../api/fetch_redux";
import { toast } from "react-toastify";
import { ToastErro } from "../../../utils/utils";
import FeedModal from "./feed-modal";
//
export const FEED_RH_GET_FEED = "FEED_RH_GET_FEED"; // Para recuperar mais feeds
export const FEED_RH_ADD_FEED = "FEED_RH_ADD_FEED"; // Para inserir mais feeds
export const FEED_RH_UPD_FEED = "FEED_RH_UPD_FEED"; // Para atualizar um feed
export const FEED_RH_DEL_FEED = "FEED_RH_DEL_FEED"; // Para excluir um feed
export const FEED_RH_ADD_COMENTARIO = "FEED_RH_ADD_COMENTARIO"; // Para inserir um novo comentario
export const FEED_RH_UPD_COMENTARIO = "FEED_RH_UPD_COMENTARIO"; // Para editar um comentario
export const FEED_RH_DEL_COMENTARIO = "FEED_RH_DEL_COMENTARIO"; // Para excluir um comentario
export const FEED_RH_ADD_CURTIDA = "FEED_RH_ADD_CURTIDA"; // Para adicionar uma curtida
export const FEED_RH_UPD_CURTIDA = "FEED_RH_UPD_CURTIDA"; // Para editar uma curtida
export const FEED_RH_DEL_CURTIDA = "FEED_RH_DEL_CURTIDA"; // Para excluir uma curtida
export const FEED_RH_SET_DRAWER = "FEED_RH_SET_DRAWER"; // Para o drawer do feed
export const FEED_RH_FECHAR_DRAWER = "FEED_RH_FECHAR_DRAWER"; // Para fechar o drawer
export const FEED_RH_LIMPAR_DADOS = "FEED_RH_LIMPAR_DADOS"; // Para limpar dados do feed

const ROTAS = ["/feed", "/feed_comentarios", "/feed_curtidas"];

export const feedRHGetFeed = (pagina, setWait) => (dispatch) => {
  fetchRedux(
    `${ROTAS[0]}?pagina=${pagina}`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: FEED_RH_GET_FEED,
        data: resposta,
      });
    },
    setWait,
    (err) => ToastErro(err)
  );
};
// Action para inserir ou atualizar feed
export const feedRHAddUpdFeed = (obj, setWait, isUpdate) => (dispatch) => {
  fetchRedux(
    ROTAS[0],
    isUpdate ? "PUT" : "POST",
    obj,
    (resposta) => {
      dispatch(feedRHFecharDrawer());
      dispatch({
        type: isUpdate ? FEED_RH_UPD_FEED : FEED_RH_ADD_FEED,
        data: {
          feed: resposta.data,
          data_anexo: resposta.data_anexo,
        },
      });
    },
    setWait,
    (err) => ToastErro(err)
  );
};
// Action para excluir feed
export const feedRHDelFeed = (obj, setWait) => (dispatch) => {
  fetchRedux(
    ROTAS[0],
    "DELETE",
    obj,
    (resposta) => {
      toast.dark(resposta.sucesso, {
        type: "success",
      });
      dispatch(feedRHFecharDrawer());
      dispatch({
        type: FEED_RH_DEL_FEED,
        data: obj.id_feed,
      });
    },
    setWait,
    (err) => ToastErro(err)
  );
};
// Action para listar os comentarios sobre um feed
export const feedRHGetComentarios = (id_feed) => (dispatch) => {
  fetchRedux(
    `${ROTAS[1]}/${id_feed}`,
    "GET",
    null,
    (resposta) => {
      dispatch(
        feedRHSetDrawer({
          tipo: FeedModal.modal.LISTAR_COMENTARIOS,
          dados: resposta,
        })
      );
    },
    () => {},
    (err) => ToastErro(err)
  );
};
// Action para confirmar a insercao de um comentario
export const feedRHAddUpdComentario = (obj, isUpdate) => (dispatch) => {
  fetchRedux(
    ROTAS[1],
    isUpdate ? "PUT" : "POST",
    obj,
    (resposta) => {
      dispatch({
        type: isUpdate ? FEED_RH_UPD_COMENTARIO : FEED_RH_ADD_COMENTARIO,
        data: isUpdate
          ? resposta.data
          : {
              data: resposta.data,
              id_feed: obj.id_feed,
            },
      });
    },
    () => {},
    (err) => ToastErro(err)
  );
};
// Action para excluir um comentario
export const feedRHDelComentario = (obj) => (dispatch) => {
  fetchRedux(
    ROTAS[1],
    "DELETE",
    { id_comentario: obj.id_comentario },
    (resposta) => {
      dispatch({
        type: FEED_RH_DEL_COMENTARIO,
        data: {
          id_comentario: obj.id_comentario,
          id_feed: obj.id_feed,
        },
      });
    },
    () => {},
    (err) => ToastErro(err)
  );
};
// Action para adicionar uma curtida
export const feedRHAddUpdCurtida = (obj, isUpdate) => (dispatch) => {
  const paramSend = obj;
  const { id_feed } = obj;
  if (isUpdate) delete paramSend.id_feed;

  fetchRedux(
    ROTAS[2],
    isUpdate ? "PUT" : "POST",
    paramSend,
    (resposta) => {
      dispatch({
        type: isUpdate ? FEED_RH_UPD_CURTIDA : FEED_RH_ADD_CURTIDA,
        data: {
          data: resposta.data,
          id_feed,
        },
      });
    },
    () => {},
    (err) => ToastErro(err)
  );
};
// Action para excluir uma curtida
export const feedRHDelCurtida = (obj) => (dispatch) => {
  fetchRedux(
    ROTAS[2],
    "DELETE",
    obj,
    (resposta) => {
      dispatch({
        type: FEED_RH_DEL_CURTIDA,
        data: {
          id_feed: obj.id_feed.toString(),
        },
      });
    },
    () => {},
    (err) => ToastErro(err)
  );
};
// Action para listar os comentarios sobre um feed
export const feedRHGetCurtidas = (id_feed) => (dispatch) => {
  fetchRedux(
    `${ROTAS[2]}/${id_feed}`,
    "GET",
    null,
    (resposta) => {
      dispatch(
        feedRHSetDrawer({
          tipo: FeedModal.modal.LISTAR_CURTIDAS,
          dados: {
            data: resposta,
            id_feed,
          },
        })
      );
    },
    () => {},
    (err) => ToastErro(err)
  );
};
// Action para definir o drawer
export const feedRHSetDrawer = (obj) => ({
  type: FEED_RH_SET_DRAWER,
  data: obj,
});
// Action para fechar um drawer
export const feedRHFecharDrawer = () => ({
  type: FEED_RH_FECHAR_DRAWER,
});
// Action para limpar os dados do feed
export const feedRHLimparDados = () => ({
  type: FEED_RH_LIMPAR_DADOS,
});
