import {
  Divider,
  Container,
  Stack,
  useMediaQuery,
  useTheme,
  Grow,
  ButtonBase,
  Chip,
  Badge,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import _ from "lodash";
import axios from "axios";
import React, { useCallback, useState, useEffect, memo } from "react";
import { toast } from "react-toastify";
import {
  Pesquisando,
  SideBar,
  H6,
  TabelaV2,
  Icone,
  DrawerExibicao,
  Dialog,
  Caption,
} from "../../../components";
import { useFetch } from "../../../hooks";
import { optTabela, registrarAcesso, ToastErro } from "../../../utils/utils";
import {
  getSituacao,
  getSituacaoCor,
  getSituacaoIcone,
  getSituacaoTitulo,
} from "../../raiz/configuracoes/configuracoes-utils";
import { useToggle } from "react-use";
import { useTranslation } from "react-i18next";

const MODAL = {
  VER_DEPENDENTES: "VER_DEPENDENTES",
  VER_ANEXOS: "VER_ANEXOS",
};

function DadosCadastrais() {
  const { t } = useTranslation();
  const [dependentes, setDependentes] = useState(null);
  const [modal, setModal] = useState(null);

  const { wait, data, error, setFetch } = useFetch(
    "/manutencao_dados_cadastrais",
    "GET"
  );
  // Carrega os dados cadastrais dos colaboradores
  useEffect(() => setFetch({}), [setFetch]);
  //
  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);
  useEffect(() => registrarAcesso(window.location.pathname), []);
  // Assim que obtivermos dados atualizados vamos atualizar os depenentes
  useEffect(() => {
    setDependentes(data?.dependentes);
  }, [data]);
  //const isMobile = useTheme()?.breakpoints?.values.sm > window.innerWidth;
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  // Se for maior que XL  vamos usar o container xl para aproveitar todo o espaco
  const isXl = useMediaQuery(useTheme()?.breakpoints?.up("xl"));
  // Funcao de callback para fechar modal
  const fnFecharModal = () => setModal(null);

  return (
    <Stack sx={{}} direction="row">
      {modal ? (
        <ModalDependentes
          modal={modal}
          fecharModal={fnFecharModal}
          dependentes={dependentes}
          cabeDependentes={data.cabeDependentes}
        />
      ) : null}
      <SideBar barraIntegrada />
      <Container
        disableGutters={isMobile}
        sx={{ mt: 0.5 }}
        maxWidth={isXl ? "xl" : "lg"}
      >
        <H6>{t("dados_cadastrais_page.titlePage")}</H6>
        <Caption component="p">
          {t("dados_cadastrais_page.subtitlePage")}
        </Caption>
        <Divider sx={{ my: 2 }} />
        {wait ? (
          <Pesquisando />
        ) : data ? (
          <Corpo
            setModal={setModal}
            {...data}
            dependentes={dependentes}
            setDependentes={setDependentes}
          />
        ) : null}
      </Container>
    </Stack>
  );
}
//
const ModalDependentes = ({
  modal,
  fecharModal,
  dependentes,
  cabeDependentes,
}) => {
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  let corpo;

  switch (modal.tipo) {
    case MODAL.VER_DEPENDENTES:
      corpo = (
        <RenderDependentes
          cabeDependentes={cabeDependentes}
          dependentes={
            dependentes.hasOwnProperty(modal.dados)
              ? dependentes[modal.dados]
              : []
          }
        />
      );
      break;
    case MODAL.VER_ANEXOS:
      corpo = <VerAnexos anexos={modal.dados} />;
      break;
    default:
      break;
  }

  return (
    <>
      {isMobile ? (
        <DrawerExibicao corpo={corpo} fecharDrawer={fecharModal} />
      ) : (
        <Dialog corpo={corpo} comoSlide fecharDialogo={fecharModal} />
      )}
    </>
  );
};
//
const VerAnexos = ({ anexos }) => {
  const { t } = useTranslation();
  return (
    <Container maxWidth="md">
      <H6>{t("dados_cadastrais_page.titleViewAttachments")}</H6>
      <Caption component="p">
        {t("dados_cadastrais_page.subtitleViewAttachments")}
      </Caption>
      <Divider sx={{ my: 1 }} />
      <Stack spacing={1}>
        {anexos.map((ele, idx) => (
          <Button
            fullWidth
            href={ele}
            target="_blank"
            variant="outlined"
            key={idx}
          >
            {idx + 1}° {t("dados_cadastrais_page.attachment")}
          </Button>
        ))}
      </Stack>
    </Container>
  );
};
//
const Corpo = memo(
  ({ setModal, cabe, corpo, dependentes, setDependentes, anexos }) => {
    const { t } = useTranslation();
    const [corpoTabela, setCorpoTabela] = useState(corpo || []);
    const [filtroPendente, setFiltroPendente] = useToggle(true); // Para exibir/ocultar os pendentes
    const [filtroConfirmado, setFiltroConfirmado] = useToggle(true); // Para exibir/ocultar os confirmados

    const optTabelaT = {
      ...optTabela,
      calcularRodape: false,
      data: [8],
      envolver: {
        0: (val) =>
          val === "--" || val?.lenght === 0 ? null : (
            <Chip
              size="small"
              title={getSituacaoTitulo(val, t)}
              label={getSituacao(val, t)}
              color={getSituacaoCor(val)}
              icon={getSituacaoIcone(val)}
            />
          ),
        1: (val) =>
          val === "--" || val?.length === 0 ? null : (
            <ButtonBase
              title={`${t("dados_cadastrais_page.titleBtnAttach1")} ${
                anexos[val]?.length
              } ${t("dados_cadastrais_page.titleBtnAttach2")}`}
              sx={{ p: 0.5 }}
              onClick={(e) => {
                e.stopPropagation();
                setModal({ tipo: MODAL.VER_ANEXOS, dados: anexos[val] });
              }}
            >
              <Badge badgeContent={anexos[val]?.length} color="primary">
                <Icone icone="AttachFile" />,
              </Badge>
            </ButtonBase>
          ),
      },
      styleTrSelecionado: {
        backgroundColor: grey[400],
        color: "black",
      },
    };
    // Faz um filtro sobre a tabela
    const corpoFiltro = _.filter(corpoTabela, (val) => {
      const valor = val.data[0]; // Status
      if (filtroPendente && ["R", "B"].includes(valor)) {
        return true;
      }
      if (filtroConfirmado && valor === "A") {
        return true;
      }
      return false;
    });
    // FUncao para controlar a atualizacao de um registro na tabela
    const fnAtualizaStatusRegistro = useCallback(
      (matSelecionado, objAtualizacao) => {
        //
        const copia = _.clone(corpoTabela);
        //
        _.forEach(copia, (val) => {
          if (String(val.id) === String(matSelecionado)) {
            val.data[0] = objAtualizacao.situacao;
          }
        });
        // Atualiza os dependentes também (se tiver)
        const copiaDependentes = { ...dependentes };
        if (copiaDependentes?.hasOwnProperty(matSelecionado)) {
          copiaDependentes[matSelecionado].forEach((ele) => {
            ele[0] = objAtualizacao.situacao;
          });
          setDependentes(copiaDependentes);
        }
        //
        setCorpoTabela(copia);
      },
      [corpoTabela, setCorpoTabela, dependentes, setDependentes]
    );

    return (
      <TabelaV2
        cabe={cabe}
        corpo={corpoFiltro}
        {...optTabelaT}
        render={({ trSelecionado }) => (
          <RenderOpcoesTabela
            trSelecionado={trSelecionado}
            setModal={setModal}
            setDadosTabela={fnAtualizaStatusRegistro}
            fnFiltroPendente={setFiltroPendente}
            fnFiltroConfirmado={setFiltroConfirmado}
            isPendente={filtroPendente}
            isConfirmado={filtroConfirmado}
          />
        )}
      />
    );
  }
);
// Renderiza os itens da tabela
const RenderOpcoesTabela = ({
  trSelecionado,
  setModal,
  setDadosTabela,
  fnFiltroPendente,
  fnFiltroConfirmado,
  isPendente,
  isConfirmado,
}) => {
  const { t } = useTranslation();
  const [aguardar, setAguardar] = useState(null);
  //
  const fnAceitaRejeta = useCallback(
    (situacao) => {
      aprovarReprovar({ matricula: trSelecionado, situacao }, setAguardar, t)
        .then((resp) => {
          setDadosTabela(trSelecionado, resp);
        })
        .catch((err) => {
          ToastErro(err);
        });
    },
    [t, setDadosTabela, trSelecionado, setAguardar]
  );

  const itens = [
    {
      icone: "ThumbUp",
      titulo: t("dados_cadastrais_page.titleConfirm"),
      sx: {
        borderRadius: "100%",
        p: 1,
        backgroundColor: "success.dark",
        color: "white",
      },

      onClick: () => fnAceitaRejeta("A"),
    },
    {
      icone: "ThumbDown",
      titulo: t("dados_cadastrais_page.titleReject"),
      sx: {
        borderRadius: "100%",
        p: 1,
        backgroundColor: "error.dark",
        color: "white",
      },

      onClick: () => fnAceitaRejeta("B"),
    },
  ];
  // Opcoes para filtro por dependente/confirmado
  const botoesFiltro = [
    {
      titulo: t("dados_cadastrais_page.titleActiveDeactivePend"),
      rotulo: t("dados_cadastrais_page.labelFilterPendent"),
      valor: isPendente,
      onClick: fnFiltroPendente,
    },
    {
      titulo: t("dados_cadastrais_page.titleActiveDeactiveOk"),
      rotulo: t("dados_cadastrais_page.labelFilterOK"),
      valor: isConfirmado,
      onClick: fnFiltroConfirmado,
    },
  ];

  return (
    <Stack direction="row" spacing={1}>
      {botoesFiltro.map((ele, idx) => (
        <FormControlLabel
          key={idx}
          label={ele.rotulo}
          title={ele.titulo}
          checked={ele.valor}
          onChange={() => ele.onClick()}
          control={<Switch />}
        />
      ))}
      <Grow in={Boolean(trSelecionado)} unmountOnExit={true}>
        <ButtonBase
          title={t("dados_cadastrais_page.titleViewDependents")}
          sx={{
            borderRadius: "100%",
            p: 1,
            backgroundColor: "info.dark",
            color: "white",
          }}
          disabled={aguardar}
          onClick={() =>
            setModal({ tipo: MODAL.VER_DEPENDENTES, dados: trSelecionado })
          }
        >
          <Icone icone="Groups" />
        </ButtonBase>
      </Grow>
      {itens.map((ele, idx) => (
        <Grow
          title={ele.titulo}
          key={idx}
          sx={ele.sx}
          in={Boolean(trSelecionado)}
          unmountOnExit={true}
        >
          <ButtonBase onClick={ele.onClick} disabled={aguardar}>
            <Icone icone={ele.icone} />
          </ButtonBase>
        </Grow>
      ))}
    </Stack>
  );
};
// Renderiza os dependentes
const RenderDependentes = ({ cabeDependentes, dependentes }) => {
  const { t } = useTranslation();
  const optTabelaT = {
    ...optTabela,
    envolver: {
      0: (val) => (
        <Chip
          size="small"
          title={getSituacaoTitulo(val, t)}
          label={getSituacao(val, t)}
          color={getSituacaoCor(val)}
          icon={getSituacaoIcone(val)}
        />
      ),
    },
    styleCorpo: {
      ...optTabela.styleCorpo,
      //whiteSpace: "pre-wrap",
      textOverflow: "ellipsis",
    },
    data: [3],
  };

  return (
    <>
      <H6>{t("dados_cadastrais_page.titleDependents")}</H6>
      <TabelaV2
        {...optTabelaT}
        cabe={t("dados_cadastrais_page.cabeDependentes")}
        corpo={dependentes}
      />
    </>
  );
};
//
function aprovarReprovar(obj, setAguardar, t) {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));
  //
  return new Promise(async (resolve, reject) => {
    setAguardar(true);
    try {
      const resp = await axios.put("/manutencao_dados_cadastrais", formData);
      if (resp.status !== 200) {
        reject(t("dados_cadastrais_page.errorInternal"));
        return false;
      }
      if (resp.data.erro) {
        reject(resp.data.erro);
        return false;
      }
      //
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      //
      resolve(resp.data.data);
    } catch (error) {
      reject(t("dados_cadastrais_page.errorUnknown"));
    } finally {
      setAguardar(false);
    }
  });
}

DadosCadastrais.rota = "/dados_cadastrais";

export default DadosCadastrais;
