import { ButtonBase, Paper, Stack } from "@mui/material";
import React from "react";
import { Body1, H6 } from "../../../../components";
import { getStatusColor } from "../treinamento-utils";

function TrainningFilterStatus({
  statusName,
  status,
  total,
  onClick,
  isSelected,
}) {
  return (
    <ButtonBase onClick={onClick}>
      <Paper
        sx={{
          backgroundColor: getStatusColor(status, true),
          height: 64,
          width: 180,
          p: 1,
        }}
        elevation={isSelected ? 16 : 1}
      >
        <Stack
          gap={1}
          sx={{ height: "100%", width: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Body1>{statusName}</Body1>
          <H6>{total}</H6>
        </Stack>
      </Paper>
    </ButtonBase>
  );
}

export default TrainningFilterStatus;
