import { useEffect, useState } from "react";
import { ToastErro } from "../../../utils/utils";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { ERRO_STATUS_NAO_200 } from "../../../redux/actions";
import { useTranslation } from "react-i18next";

const HocRespostaQuestionario = ({ render, id_twttp }) => {
  const { t } = useTranslation();
  const [respostas, setRespostas] = useState(null);
  const [aguardar, setAguardar] = useState(false);
  // Faz a obtenção das respostas do twttp para o usuario saber se vai aprovar /reprovar etc.
  useEffect(() => {
    (async function () {
      setAguardar(true);
      try {
        const resp = await axios.get(
          `/twttp_entrevistas_respostas?id_twttp=${id_twttp}`
        );
        if (resp.status !== 200) {
          ToastErro(ERRO_STATUS_NAO_200);
          return false;
        }
        if (resp.data.erro) {
          ToastErro(resp.data.erro);
          return false;
        }
        // Deu tudo certo vamos exibir as respostas
        setRespostas(resp.data);
      } catch (err) {
        ToastErro(t("twttp.errorUnknown"));
      } finally {
        setAguardar(false);
      }
    })();
  }, [id_twttp, t, setRespostas, setAguardar]);

  return (
    <>
      {aguardar ? <CircularProgress /> : respostas ? render(respostas) : null}
    </>
  );
};

export default HocRespostaQuestionario;
