import axios from "axios";
import { toast } from "react-toastify";
import { setAguardar } from "../../../redux/actions/aguardar-actions";
import {
  ERRO_NAO_ENVIADO,
  ERRO_STATUS_NAO_200,
} from "../../../redux/actions/erro-actions";

// Actions para controlarmos os dados
export const ESTATISTICAS_LIMPAR_DADOS = "ESTATISTICAS_LIMPAR_DADOS";
export const ESTATISTICAS_DEFINIR_DADOS = "ESTATISTICAS_DEFINIR_DADOS";

const ROTAS = ["/get_estatisticas"];

export const estatisticasGetDados = (de, ate, fecharFiltro) => (dispatch) => {
  dispatch({
    type: ESTATISTICAS_LIMPAR_DADOS,
  });
  dispatch(setAguardar(true));
  // Cria o form dos parametros e solicita novos dados da pagina
  const formData = new FormData();
  formData.append("de", de);
  formData.append("ate", ate);

  const params = new URLSearchParams(formData);

  axios
    .get(`${ROTAS[0]}?${params.toString()}`)
    .then((resp) => {
      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200);
        dispatch({
          type: ESTATISTICAS_DEFINIR_DADOS,
          data: [],
        });
        return false;
      }
      // No mais sempre e retornado um array (sendo vazio ou nao) entao pode inserir os dados
      dispatch({
        type: ESTATISTICAS_DEFINIR_DADOS,
        data: resp.data,
      });
      // Executa a funcao para fechar o drawer do filtro
      if (fecharFiltro) fecharFiltro();
    })
    .catch((err) => {
      toast(ERRO_NAO_ENVIADO);
      dispatch({
        type: ESTATISTICAS_DEFINIR_DADOS,
        data: [],
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
