import React from 'react';
import { Hidden, Container, Button, Grid, Typography,
  TextField, Checkbox, FormControlLabel
} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SaveIcon from '@mui/icons-material/Save';

import { useQuestionario } from '../hooks'; // Hook para controlar dados do formulario
import { Controller, useForm } from 'react-hook-form';
import { Select } from '../components';


const HeaderQuestion = ({ defaultValue, avancar })=>{
  const { control, handleSubmit, formState: { errors } } = useForm({defaultValues: defaultValue});

  const { situacao, questionario, dataInicio, dataFim } = errors;
  //
  const fnAvancar = val=>{
    // Dados validados, vamos inclui-los
    avancar(val);
  }
  const options = [
    ['', ''],
    ['A', 'A'],
    ['B', 'B'],
  ];
  const STRINGS = {
    questionario: {
      label: '* Questionário',
      placeholder: '* Titulo do questionário'
    },
    dataInicio: {
      label: '* Data de ínicio',
    },
    dataFim: {
      label: '* Data de término',
    },
    situacao: {
      label: '* Situação',
    }
  }
  
  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
          <Controller 
            name='questionario'
            control={control}
            rules={{required: true}}
            render={({ field })=> (
                <TextField {...field} variant='outlined' label={STRINGS.questionario.label} fullWidth 
                error={!!questionario} />
            )}
            />
      </Grid>
      <Grid item xs={6} lg={4} >
          <Controller
            name="dataInicio"
            control={control}
            rules={{required: true}}
            render={({ field })=> (
              <TextField {...field} type="date" variant='outlined' label={STRINGS.dataInicio.label} InputLabelProps={{ shrink: true }}
                  error={!!dataInicio} />
            )}
          />
      </Grid>
      <Grid item xs={6} md={4} lg={4} >
          <Controller
            name="dataFim"
            control={control}
            rules={{required: true}}
            render={({ field })=>(
              <TextField {...field} type="date" variant='outlined' label={STRINGS.dataFim.label} InputLabelProps={{ shrink: true }}
                error={!!dataFim} 
              />
            )}
          />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          name="situacao"
          control={control}
          rules={{required: true}}
          render={({ field })=>(
            <Select {...field} fullWidth options={options} label={STRINGS.situacao.label}  
              error={!!situacao} />
            )}
        />
      </Grid>
    </Grid>
    <br/>    
    <Grid container spacing={1}>
      <Hidden smDown>
        <Grid item md={8} />
      </Hidden>
      <Grid item xs={12} md={4}>
        <Button fullWidth size='small' endIcon={<DoubleArrowIcon />} 
          onClick={handleSubmit(fnAvancar)} variant="contained" color="primary"
        >
          <Typography variant='body2'>PRÓXIMO</Typography>  
        </Button>
      </Grid>
    </Grid>
    </>
  )
}
// Agora a area das perguntas
const Question = ({opcoesDaPergunta, resposta, defaultValue, finalizar, avancar, recuar})=>{
  /* Fazendo uso do hook useForm extraimos 
   - watch (para observar alterações nos campos)
   - control (para incluir os campos no useForm)
   - handleSubmit (para manipular o formulario, ele faz validação dos campos e repassa os dados formatados)
   - formState : errors (usado para pegar as possiveis mensagens de erro)
  */

  const { watch, control, handleSubmit, formState: { errors } } = useForm({defaultValues: defaultValue});
  // Erros para os campos
  const { topico, descricao, tipo } = errors;
  // Funcao para validar e avançar a proxima pergunta
  const fnAvancar = (val)=>{
    avancar(val);
  }
  // Opcoes para o tipo da Pergunta
  const options = [
    ['', ''],
    ['1', '1 - ESCOLHA ÚNICA'],
    ['2', '2 - DISSERTATIVA'],
  ];
  // Strings dos campos para uma melhor organizacao
  const STRINGS = {
    topico : {
      label: '* Tópico:',
      placeholder: 'Tópico Relacionado a pergunta',
    },
    descricao: {
      label: '* Descrição:',
      placeholder: 'Uma descrição mais detalhada sobre a questão.',
    },
    tipo: {
      label: '* Tipo da pergunta:',
      placeholder: 'Escolha um Tipo'
    }
  }

  return(
    <>
      <Grid container spacing={1}>
          <Grid item xs={6} md={4}>
            <Button size='small' fullWidth startIcon={<ChevronLeftIcon /> } onClick={recuar} 
              color="primary" variant="outlined"
              >
                <Typography variant="body2">ANTERIOR</Typography>
              </Button>
          </Grid>
          <Grid item xs={6} md={4}>
            <Button size='small' fullWidth endIcon={<ChevronRightIcon />} 
              onClick={handleSubmit(fnAvancar)} variant="outlined" color="primary"
            >
              <Typography variant="body2">PROXIMO</Typography>
            </Button>
          </Grid>
          {resposta && resposta.length > 1 && (
            <Grid item xs={12} md={4}>
              <Button size='small' fullWidth endIcon={<SaveIcon />} color="primary" variant="contained"
                onClick={handleSubmit(finalizar)}
              >
                <Typography variant="body2">FINALIZAR</Typography>
              </Button>              
            </Grid>
          )}
      </Grid>
      <br/> 
      <Grid container>
        <Grid item xs={12}>
          <Controller
            name="topico"
            control={control}
            rules={{required: true}}
            render={({ field })=> (
              <TextField {...field} InputLabelProps={{ shrink: true }}  
                variant='outlined' margin='dense' fullWidth label={STRINGS.topico.label} error={!!topico} 
              />
            )}
          />
        </Grid>
        <hr/>
        <Grid item xs={12}>
          <Controller 
            name="descricao"
            control={control}
            rules={{required: true}}
            render={({ field })=>(
              <TextField margin='dense' variant='outlined' {...field} fullWidth label={STRINGS.descricao.label} error={!!descricao} 
                multiline minRows={3} placeholder={STRINGS.descricao.placeholder}
                InputLabelProps={{ shrink: true }}
              />
            )}
            />
        </Grid>
        <hr/>
        <Grid item xs={12}>
          <Controller
            name="tipo"
            control={control}
            rules={{required: true}}
            render={({ field })=>(
                <Select margin='dense' {...field} fullWidth error={!!tipo} label={STRINGS.tipo.label} options={options} />
            )}
          />
        </Grid>
        <hr/>
        {watch('tipo') === '1' && (
          <Grid container>
            {opcoesDaPergunta.map((ele,idx)=>(
              <Grid item xs={12} key={idx}>
                <Controller
                  name={`check_${ele[0]}`}
                  control={control}
                  defaultValue={ele[0]}
                  render={({ field })=>(
                      <FormControlLabel fullWidth
                        control={
                          <Checkbox {...field} defaultChecked={defaultValue && defaultValue[`check_${ele[0]}`]} 
                          />
                        }
                        label={ele[1]}
                      />
                  )}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </>
  )

}

export default function CriadorDeAvaliacao({salvarAvaliacao, opcoesDaPergunta}){
  // Contador para controlarmos onde estamos no formulario
  // resposta é um array, ele é consultado para enviar os valores default e usado para gerar os botoes dentro da question
  // avancar é uma funcao que recebe o valor a ser inserido e avança o contador em 1
  // recuar é uma função que retorna o contador em 1
  const { contador, resposta, avancar, recuar } = useQuestionario();
  
  const finalizar = ultimoValor => {
    // Pega o array de resposta e cria uma copia
    const copia = [...resposta];
    // Coloca este ultimoValor na posicao do contador
    copia[contador] = ultimoValor;
    // Se o ultimo item do array resposta for null, deve-se remove-lo
    if(!copia[copia.length - 1] ) copia.pop();
    // Funcao de callback enviada capturar o formulario
    salvarAvaliacao(copia);

  }
      
  return (
    <Container>
      <h3 className='text-center'>QUESTÃO {contador} DE {resposta.length - 1}</h3>
      {/* O cabecalho do questionario. O que identifica ele, esta parte deve ser chamada somente uma vez */}
      {contador < 1 && <HeaderQuestion avancar={avancar} defaultValue={resposta[contador]} />}
      { /* As perguntas que são criadas, esta parte é dinamica e vai avançando a medida que se inclui codigo novo */}
      {contador > 0 && (
        <Question key={contador} defaultValue={resposta[contador]} 
            opcoesDaPergunta={opcoesDaPergunta} resposta={resposta} 
            finalizar={finalizar} recuar={recuar} avancar={avancar} 
        />
      )}
    </Container>
  )
}

