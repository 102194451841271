import { memo, useCallback } from "react";
import { useToggle } from "react-use";
import * as yup from "yup";
import _ from "lodash";
import axios from "axios";
import { ACTIONS, ROTA } from "./documentos-utils";
import { ToastErro } from "../../../utils/utils";
import { Container, Stack } from "@mui/material";
import { EntradaForm, H6 } from "../../../components";
import { useTranslation } from "react-i18next";

// Componente para editar nome do item
const EditarNomeArquivo = memo(
  ({
    id_arquivo,
    visivel_por,
    tipo,
    nome,
    ext,
    matricula,
    planta,
    dispatch,
    permissao_visualizacao,
  }) => {
    const { t } = useTranslation();

    const [wait, setWait] = useToggle();

    // Schema simples que so exibe um campo de entrada de texto
    const schema = [
      {
        name: "nome",
        label: t("documentos.editNameFileNewName"),
        placeholder: t("documentos.editNameFileNewNamePlaceholder"),
        type: "text",
        defaultValue:
          tipo === "F" ? _.join(_.split(nome, `.${ext}`, 1), "") : nome,
        icon: "Edit",
      },
    ];

    // Schema de erro e validacao
    const schemaMessageError = {
      nome: t("documentos.editNameFileNewNameError"),
    };
    const schemaValidator = yup.object().shape({
      nome: yup.string().min(3).required(),
    });

    // Funcao de callback para registrar a alteração do nome do campo
    const onSubmit = useCallback(
      async (e) => {
        const novoNome = tipo === "F" ? `${e.nome}.${ext}` : e.nome;
        //setFetch({ id_arquivo, nome: novoNome });
        try {
          setWait();
          const resp = await axios.put(ROTA, {
            id_arquivo,
            nome: novoNome,
            visivel_por: visivel_por.join(","),
            mat_planta_view: permissao_visualizacao,
          });
          dispatch({ data: resp.data.data[0], type: ACTIONS.UPD });
          dispatch({ type: ACTIONS.FECHAR_MODAL });
        } catch (error) {
          if (error.response?.data?.message) {
            ToastErro(error.response.data.message);
          } else {
            ToastErro(error);
          }
        } finally {
          setWait();
        }
      },
      [
        visivel_por,
        permissao_visualizacao,
        dispatch,
        ext,
        id_arquivo,
        setWait,
        tipo,
      ]
    );

    return (
      <Container maxWidth="md" sx={{ minHeight: "40vh" }}>
        <Stack>
          <H6>{t("documentos.editNameFileTitle")}</H6>
          <EntradaForm
            wait={wait}
            schema={schema}
            onSubmit={onSubmit}
            schemaValidator={schemaValidator}
            schemaMessageError={schemaMessageError}
          />
        </Stack>
      </Container>
    );
  }
);

export default EditarNomeArquivo;
