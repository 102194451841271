import React from "react";
import {
  ContainerAdaptavel,
  FormSearchAdvanced,
} from "../../../extra-components";
import { Body1, H6 } from "../../../components";
import { useTranslation } from "react-i18next";

function NcpFiltroAvancado({ filters, onFilter }) {
  const { t } = useTranslation();
  const titulo = t("ncp.tituloBuscaAvancada");
  const descricao = t("ncp.descricaoBuscaAvancada");

  return (
    <ContainerAdaptavel sx={{ minHeight: "50vh" }}>
      <H6>{titulo}</H6>
      <Body1 align="center">{descricao}</Body1>
      <br />
      <FormSearchAdvanced filters={filters} onFilter={onFilter} />
    </ContainerAdaptavel>
  );
}

export default NcpFiltroAvancado;
