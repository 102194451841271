import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectArvores,
  selectCargos,
  selectPlantas,
} from "./colaboradores-select";
import * as yup from "yup";
import { Container, Divider } from "@mui/material";
import { Caption, EntradaForm, H6 } from "../../../components";
import { colaboradoresAddUsuarioManual } from "./colaboradores-actions";
import { useCallback } from "react";
import { format } from "date-fns";

const sxMinMaxHeight = {
  minHeight: "50vh",
  maxHeight: "80vh",
  overflow: "auto",
};

// Renderiza formulario de insercao de colaborador
const FormAddColaboradorManual = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const arvores = useSelector(selectArvores);
  const plantas = useSelector(selectPlantas);
  const cargos = useSelector(selectCargos);
  //
  const schema = [
    {
      name: "matricula",
      type: "text",
      label: t("colaboradores.labelMat"),
      placeholder: t("colaboradores.placeholderMat"),
      mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
      defaultValue: "",
    },
    {
      name: "nome",
      type: "text",
      label: t("colaboradores.labelName"),
      placeholder: t("colaboradores.placeholderName"),
      defaultValue: "",
    },
    {
      name: "arvore",
      type: "select",
      autoFormat: true,
      itens: arvores,
      label: t("colaboradores.labelThree"),
      placeholder: t("colaboradores.placeholderThree"),
    },
    {
      name: "cargo",
      type: "select",
      autoFormat: true,
      itens: cargos,
      label: t("colaboradores.labelFunction"),
      placeholder: t("colaboradores.placeholderFunction"),
    },
    {
      name: "planta",
      type: "select",
      autoFormat: true,
      itens: plantas,
      label: t("colaboradores.labelPlant"),
      placeholder: t("colaboradores.placeholderPlant"),
    },
    {
      name: "data_admissao",
      icon: "CalendarToday",
      type: "date",
      label: t("colaboradores.labelDateAdmission"),
      placeholder: t("colaboradores.placeholderDateAdmission"),
    },
    {
      name: "senha",
      icon: "VpnKey",
      type: "password",
      label: t("colaboradores.labelPassword"),
      placeholder: t("colaboradores.placeholderPassword"),
    },

    {
      name: "gestor",
      icon: "Engineering",
      type: "switch",
      label: t("colaboradores.labelIsManager"),
      defaultChecked: false,
    },
  ];
  //
  const schemaValidator = yup.object().shape({
    nome: yup.string().min(6).required(),
    matricula: yup.string().matches(/\d{6}/).required(),
    senha: yup.string().min(6).required(),
    data_admissao: yup.date().required(),
    gestor: yup.boolean(),
    cargo: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    planta: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    arvore: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
  });
  //
  const schemaMessageError = {
    matricula: t("colaboradores.errorMat"),
    nome: t("colaboradores.errorName"),
    arvore: t("colaboradores.errorThree"),
    planta: t("colaboradores.errorPlant"),
    cargo: t("colaboradores.errorFunction"),
    gestor: t("colaboradores.errorIsManager"),
    data_admissao: t("colaboradores.errorDateAdmission"),
    senha: t("colaboradores.errorPassword"),
  };
  //
  const fn = useCallback(
    (val) => {
      const {
        nome,
        matricula,
        arvore,
        planta,
        cargo,
        gestor,
        data_admissao,
        senha,
      } = val;
      const obj = {
        nome: nome,
        matricula: matricula,
        arvore: arvore.value,
        planta: planta.value,
        cargo: cargo.value,
        gestor: gestor ? "S" : "N",
        data_admissao: format(data_admissao, "yyyy-MM-dd HH:mm:ss"),
        senha: senha,
      };

      dispatch(colaboradoresAddUsuarioManual(obj));
    },
    [dispatch]
  );

  return (
    <Container sx={sxMinMaxHeight} maxWidth="md">
      <H6>{t("colaboradores.formAddTitleManual")}</H6>
      <Caption>{t("colaboradores.formAddDescriptionManual")}</Caption>
      <Divider sx={{ my: 1 }} />
      <EntradaForm
        schema={schema}
        onSubmit={fn}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
      />
    </Container>
  );
};

export default FormAddColaboradorManual;
