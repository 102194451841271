import { createSelector } from "reselect";
import Utilitarios from "../../../utils/utils";
import _ from "lodash";

export const selectPlant = (state) => state?.usuario?.usuario?.planta;
export const selectMat = (state) => state?.usuario?.usuario?.matricula;

export const selectLessonItems = (state) => state?.lesson?.dadosLicao;
export const selectLessonPage = (state) => state?.lesson?.pagina;
export const selectLessonTotalPage = (state) => state?.lesson?.total;
//
const selectLessonArea = (state) => state?.lesson?.area;
const selectLessonThree = (state) => state?.lesson?.arvore;
const selectLessonColab = (state) => state?.lesson?.colaborador;
const selectLessonImpacto = (state) => state?.lesson?.impacto;
const selectLessonPN = (state) => state?.lesson?.pn;
const selectLessonProcess = (state) => state?.lesson?.processo;
const selectLessonTypeUpdate = (state) => state?.lesson?.tipo_atualizacao;

// Seletor para os items da listagem de paginação.
export const seletorItems = createSelector(selectLessonItems, (items) => {
  return _.map(items, (val) => {
    return {
      ...val,
      data: Utilitarios.converterData(val.data),
    };
    // return {
    //   avatar: val.avatar,
    //   name: val.nome,
    //   primary: val.area_descricao,
    //   secondary: val.unidadeprocesso_descricao,
    //   id: `Código: ${val.codigo}`,
    //   status: "Aberto",
    //   date: Utilitarios.converterData(val.data),
    //   codigo: val.codigo,
    // };
  });
});
// Seletor formatado area
export const seletorArea = createSelector(selectLessonArea, (items) => {
  return _.map(items, (val) => [val.id, val.descricao]);
});
// Seletor formatado para arvore
export const seletorThree = createSelector(selectLessonThree, (items) => {
  return _.map(items, (val) => [val.id_arvore, val.descricao]);
});
// Seletor formatado para colaborador
export const seletorColab = createSelector(selectLessonColab, (items) => {
  return _.map(items, (val) => [`${val.mat}_${val.planta}`, val.nome]);
});
// Seletor de impactos
export const seletorImpact = createSelector(selectLessonImpacto, (items) => {
  return _.map(items, (val) => [val.id, val.descricao]);
});
// Seletor de pn
export const seletorPN = createSelector(selectLessonPN, (items) => {
  return _.map(items, (val) => [val.pn, val.descricao]);
});
// Seletor de processos
export const seletorProcess = createSelector(selectLessonProcess, (items) => {
  return _.map(items, (val) => [val.id, val.descricao]);
});
// Seletor de tipo de atualizacao
export const seletorTypeUpdate = createSelector(
  selectLessonTypeUpdate,
  (items) => {
    return _.map(items, (val) => [val.id, val.descricao]);
  }
);
