// Seletores utilizados no budget
import _ from "lodash";
import { createSelector } from "reselect";

const bodyBudgetSyntheticSelector = (state) => state?.budget?.sintetico;
const headerBudgetSyntheticSelector = (state) => state?.budget?.cabe_sintetico;
const bodyBudgetAnalyticSelector = (state) => state?.budget?.analitico;
const headerBudgetAnalyticSelector = (state) => state?.budget?.cabe_analitico;
const headerMonths = (state) => state?.budget?.cabe_meses;

export const selectorMonths = headerMonths;

//
export const budgetModalSelector = (state) => state?.budget?.modal;
// Sintetico
export const budgetTableSyntheticSelector = createSelector(
  bodyBudgetSyntheticSelector,
  headerBudgetSyntheticSelector,
  (body, header) => {
    return {
      header,
      body,
    };
  }
);

// Criar um header sintetico customizado
export const budgetHeaderSyntheticSelector = createSelector(
  headerBudgetSyntheticSelector,
  (header) => {
    let headerExample = [];
    _.forEach(header, (val) => {
      headerExample = [...headerExample, ...val.colunas];
    });

    return headerExample;
  }
);

// Analitico
export const budgetTableAnalyticSelector = createSelector(
  bodyBudgetAnalyticSelector,
  headerBudgetAnalyticSelector,
  (body, header) => {
    return {
      header,
      body,
    };
  }
);
// Recebe um valor e faz um filtro retornando um novo selector
export const filterBudgetAnalyticSelector = (value) =>
  createSelector(
    () => value,
    bodyBudgetAnalyticSelector,
    (valueFilter, body) => {
      const endNumberCC = valueFilter.length - 6;
      // Separar o mes e o numero da conta
      const month = valueFilter.substring([valueFilter.length - 2]);
      const numberCC = valueFilter.substring(0, endNumberCC);

      if (month === "13") {
        return _.filter(
          body,
          (val) => val.id.substring(0, endNumberCC) === numberCC
        );
      }
      return _.filter(body, (val) => val.id === valueFilter);
    }
  );

// Recebe o mes que deve-se abordar o budget e retorna o body e header da tabela
// no sintetico
export const splitbudgetTableSyntheticSelector = (start, final, idxMonth) =>
  createSelector(
    () => start,
    () => final,
    () => idxMonth,
    headerMonths,
    bodyBudgetSyntheticSelector,
    budgetHeaderSyntheticSelector,
    (st, fn, idxMonth, months, bodySelector, headerSelector) => {
      // const header = [
      //   ...headerSelector.slice(0, 3),
      //   ...headerSelector.slice(st, fn + 1),
      // ];
      const body = _.map(bodySelector, (subArray) => [
        ..._.slice(subArray, 0, 2),
        ..._.slice(subArray, st, fn + 1),
      ]);

      return {
        header: [
          {
            cabecalho: "DESCRIÇÃO",
            colunas: headerSelector.slice(0, 2),
          },
          {
            cabecalho: months[idxMonth],
            colunas: headerSelector.slice(st, fn + 1),
          },
        ],
        body,
      };
    }
  );
