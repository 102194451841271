import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import Picker, { SkinTones } from "emoji-picker-react";
import { feedRHAddUpdComentario } from "../feed-actions";
import { Collapse, IconButton, Stack, TextField } from "@mui/material";
import { Caption, Icone } from "../../../../components";

const STR = {
  placeholderComment: "Digite seu comentário",
};

// Componente que cria um painel para insercao/edicao de comentario
const PainelComentario = ({
  id_feed,
  editar,
  defaultComentario,
  maxLength,
}) => {
  const dispatch = useDispatch();
  const [verPainelEmoji, setVerPainelEmoji] = useState(false);
  const [comentario, setComentario] = useState(defaultComentario || "");
  // Cria uma funcao para registrar o emoji no corpo do comentario
  const fnRegistraEmoji = (e) => {
    //setVerPainelEmoji(false);
    setComentario((state) => state + e.emoji);
  };
  // Cria uma funcao que vai registrar o comentario do feed do usuario
  const fnEnviarComentario = useCallback(() => {
    const obj = { comentario };
    if (editar) {
      obj["id_comentario"] = id_feed;
    } else {
      obj["id_feed"] = id_feed;
    }
    dispatch(feedRHAddUpdComentario(obj, editar));
    // Limpar o comentario e fechar painel de emojis (caso aberto)
    setVerPainelEmoji(false);
    setComentario("");
  }, [setVerPainelEmoji, dispatch, id_feed, setComentario, editar, comentario]);

  return (
    <Stack>
      <Stack direction="row">
        <IconButton
          //onClick={(e)=> setVerPainelEmoji(e.currentTarget)}
          onClick={(e) => setVerPainelEmoji(!verPainelEmoji)}
        >
          <Icone
            icone="EmojiEmotions"
            color={verPainelEmoji ? "primary" : "default"}
          />
        </IconButton>
        <TextField
          autoFocus={editar}
          variant="standard"
          fullWidth
          type="textarea"
          placeholder={STR.placeholderComment}
          multiline
          value={comentario}
          onChange={(e) => setComentario(e.target.value)}
          inputProps={{
            maxLength: maxLength ? maxLength : null,
          }}
          helperText={
            <Stack direction="row-reverse" justifyContent="space-between">
              {maxLength ? (
                <Caption>
                  {comentario?.length} / {maxLength}
                </Caption>
              ) : null}
            </Stack>
          }
        />
        <IconButton
          disabled={comentario?.length < 1}
          onClick={fnEnviarComentario}
        >
          <Icone
            icone="Send"
            color={comentario?.length < 1 ? "default" : "primary"}
          />
        </IconButton>
      </Stack>
      <Collapse in={verPainelEmoji} timeout="auto" unmountOnExit>
        <Stack direction="row" justifyContent="center">
          <Picker
            disableSearchBar
            onEmojiClick={fnRegistraEmoji}
            disableAutoFocus={true}
            skinTone={SkinTones}
            groupNames={{ smileys_people: "PEOPLE" }}
            native
          />
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default PainelComentario;
