import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Typography, useTheme } from "@mui/material";
import PainelDeStatus from "./kaizen-panel-status";
import ListaProjetos from "./kaizen-list-projects";
import { useToggle } from "react-use";
import { useTranslation } from "react-i18next";

const selectAdvancedFilter = (state) => state?.cadastroProjetos?.filtroAvancado;

//
const DadosFiltroAvancado = () => {
  const { t } = useTranslation();
  const [aguardar, setAguardar] = useToggle(false);
  const [pagina, setPagina] = useState(1);
  const [filtroStatus, setFiltroStatus] = useState(null);

  const filtroAvancado = useSelector(selectAdvancedFilter);

  let corpo = [];
  if (filtroAvancado) {
    corpo = filtroStatus
      ? filtroAvancado.projetos.filter((ele) => ele.situacao === filtroStatus)
      : filtroAvancado.projetos;
  }
  const totalItems = corpo.length;
  let pages = 1;
  pages = Math.ceil(totalItems / 10);

  // Hack para forçar a atualização da página
  useEffect(() => {
    setAguardar();
    setPagina(1);
    setTimeout(() => {
      setAguardar();
    }, 150);
  }, [filtroStatus, setAguardar, setPagina]);

  //
  corpo = corpo.slice().splice(10 * (pagina - 1), 10);

  const {
    breakpoints: { values },
  } = useTheme();
  const isMobile = values["sm"] > window.innerWidth;

  return (
    <Container sx={{ maxHeight: { xs: "85vh", md: "100vh" } }} maxWidth="lg">
      <Typography variant="h6" align="center">
        {t("cadastro_projetos.titleFilterAdvanced")}
      </Typography>
      <PainelDeStatus
        isMobile={isMobile}
        onClick={setFiltroStatus}
        statusSelecionado={filtroStatus}
        qtd_por_status={filtroAvancado?.qtd_por_status}
      />
      {filtroAvancado && !aguardar && (
        <ListaProjetos
          projetos={corpo}
          membros={filtroAvancado.projeto_membros}
          justificativa_x_projeto={filtroAvancado.justificativa_x_projeto}
          isMobile={isMobile}
          isFiltroAvancado
          filtroAvancadoQtdPagina={pages}
          filtroAvancadoPaginaAtual={pagina}
          filtroAvancadoSetPagina={setPagina}
        />
      )}
    </Container>
  );
};

export default DadosFiltroAvancado;
