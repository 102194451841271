import { toast } from "react-toastify";
import fetchRedux from "../../../api/fetch_redux";
import { ToastErro } from "../../../utils/utils";
import _ from "lodash";

export const NCP_DADOS = "NCP_DADOS";
export const NCP_PAGINA = "NCP_PAGINA";
export const NCP_LIMPAR_DADOS = "NCP_LIMPAR_DADOS";
export const NCP_FECHAR_MODAL = "NCP_FECHAR_MODAL";
export const NCP_SET_MODAL = "NCP_SET_MODAL";
export const NCP_ADD = "NCP_ADD";
export const NCP_SUBIDA_LINHA = "NCP_SUBIDA_LINHA";
export const NCP_QUALIDADE = "NCP_QUALIDADE";
export const NCP_SET_FILTER = "NCP_SET_FILTER";
export const NCP_CLEAR_FILTER = "NCP_CLEAR_FILTER";
export const NCP_SET_PAGE_FILTER = "NCP_SET_PAGE_FILTER";
export const NCP_CANCEL_STATUS = "NCP_CANCEL_STATUS";

//
export const ROTAS = ["/ncp", "/ncp_filtro"];

export const ncpInit = (setAguardar) => (dispatch) => {
  // A listagem de dados para construir o chamado
  fetchRedux(
    `${ROTAS[0]}?dados=true`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: NCP_DADOS,
        data: resposta,
      });
    },
    () => {},
    (err) => ToastErro(err)
  );
  // Recupera os dados da primeira pagina
  dispatch(ncpPagina(1, setAguardar));
};

// Salta entre as paginas
export const ncpPagina = (idPagina, setAguardar) => (dispatch) => {
  // Obtem os chamados do usuário verificando de tem ordenacao
  //
  const params = new URLSearchParams();
  params.append("pagina", idPagina);

  fetchRedux(
    `${ROTAS[0]}?${params.toString()}`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: NCP_PAGINA,
        data: {
          idPagina,
          dados: resposta,
        },
      });
    },
    setAguardar,
    (err) => ToastErro(err)
  );
};
// Limpar os dados para sair
export const ncpLimparDados = () => ({
  type: NCP_LIMPAR_DADOS,
});
// Ativar modal
export const ncpSetModal = (obj) => ({
  type: NCP_SET_MODAL,
  data: obj,
});
// FecharModal
export const ncpFecharModal = () => ({
  type: NCP_FECHAR_MODAL,
});

// Criar ncp
export const ncpAdd =
  (formData, setAguardar, fnRetornoSucesso) => (dispatch) => {
    //  Cria um novo ncp
    fetchRedux(
      ROTAS[0],
      "POST",
      formData,
      (resposta) => {
        toast.dark(resposta.sucesso, {
          type: "success",
        });
        dispatch(ncpFecharModal());
        // Funcao executada quando sucesso na insercao
        if (fnRetornoSucesso) {
          fnRetornoSucesso();
        }
        //
        dispatch({
          type: NCP_ADD,
          data: resposta.data,
        });
      },
      setAguardar,
      (err) => ToastErro(err)
    );
  };
export const ncpSetFilter = (filter, setAguardar) => (dispatch) => {
  if (!filter || _.keys(filter).length === 0) {
    dispatch({
      type: NCP_CLEAR_FILTER,
    });
    return;
  }
  const formData = new URLSearchParams();
  _.forEach(_.keys(filter), (k) => {
    formData.append(k, filter[k].join(","));
  });
  //  Aplicar filtro RNCI
  fetchRedux(
    `${ROTAS[1]}?${formData.toString()}`,
    "GET",
    null,
    (response) => {
      //
      dispatch({
        type: NCP_SET_FILTER,
        data: {
          data: response,
          filter,
        },
      });
    },
    setAguardar,
    (err) => ToastErro(err)
  );
};

export const ncpClearFilter = () => ({
  type: NCP_CLEAR_FILTER,
});

export const ncpPaginaFiltro = (pageId) => ({
  type: NCP_SET_PAGE_FILTER,
  data: pageId,
});

export const ncpCancelStatus = (objData, setWait) => (dispatch) => {
  //  Cria um novo ncp
  fetchRedux(
    ROTAS[0],
    "DELETE",
    objData,
    (resposta) => {
      toast.dark(resposta.sucesso, {
        type: "success",
      });
      dispatch(ncpFecharModal());

      //
      dispatch({
        type: NCP_CANCEL_STATUS,
        data: {
          data: resposta?.data,
          id: objData.codigo_ncp,
        },
      });
    },
    setWait,
    (err) => ToastErro(err)
  );
};
