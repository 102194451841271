import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { parseISO, subMonths, format } from "date-fns";
import {
  Stack,
  Paper,
  Container,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  useMediaQuery,
  useTheme,
  Chip,
  Drawer,
  Grid,
  Divider,
} from "@mui/material";

import { ToastContainer } from "react-toastify";
import { SideBar } from "../../../components";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

// Importar as actions usadas
import { estatisticasGetDados } from "./estatisticas-actions";
import FiltroDataDeEAte from "./estatisticas-filter-from-to";
import RenderGrafMobile from "./estatisticas-render-graph-mobile";
import RenderGrafAcessoDia from "./estatisticas-graph-access-day";
import RenderGrafAcessoPorMenu from "./estatisticas-render-graph-access-by-menu";
import RenderGrafTopSeteUsuario from "./estatisticas-graph-to-seven-user";
import { useTranslation } from "react-i18next";

const Estatisticas = () => {
  const { t } = useTranslation();
  const [verFiltro, setVerFiltro] = useState(null);
  const [opcao, setOpcao] = useState(2); // Estado para a opção de filtro escolhida
  const [verNumeros, setVerNumeros] = useState(false); // Parar alternar entre exibir numero ou percentual

  const [deAte, setDeAte] = useState({
    de: format(new Date(), "yyyy-MM-01"),
    ate: format(new Date(), "yyyy-MM-dd"),
  });
  const dispatch = useDispatch();
  const { estatisticas } = useSelector((state) => state);
  // Usando tema para saber se estamos no mobile
  const {
    breakpoints: { values },
  } = useTheme();
  const isMobile = values["md"] > window.innerWidth;
  const isXl = useMediaQuery(useTheme()?.breakpoints?.up("xl"));

  // Buscando os dados
  useEffect(() => {
    const [de, ate] = [
      format(new Date(), "yyyy-MM-01"),
      format(new Date(), "yyyy-MM-dd"),
    ];

    dispatch(estatisticasGetDados(de, ate));
  }, [dispatch]);

  // Array com os valores dos chips para definir os filtros
  const chips = [
    {
      label: t("estatisticas.labelToday"),
      onClick: function () {
        setOpcao(1);
        //const de = format( subMonths( parseISO(new Date()), 1), 'yyyy-MM-dd');
        const de = format(new Date(), "yyyy-MM-dd");
        const ate = format(new Date(), "yyyy-MM-dd");
        dispatch(estatisticasGetDados(de, ate));
        setDeAte({ de, ate });
      },
    },
    {
      label: t("estatisticas.labelThisMonth"),
      onClick: function () {
        setOpcao(2);
        //const de = format( subMonths( parseISO(new Date()), 1), 'yyyy-MM-dd');
        const de = format(new Date(), "yyyy-MM-01");
        const ate = format(new Date(), "yyyy-MM-dd");
        dispatch(estatisticasGetDados(de, ate));
        setDeAte({ de, ate });
      },
    },
    {
      label: t("estatisticas.labelLastSixMonths"),
      onClick: function () {
        const de = format(subMonths(new Date(), 6), "yyyy-MM-dd");
        const ate = format(new Date(), "yyyy-MM-dd");
        dispatch(estatisticasGetDados(de, ate));
        setDeAte({ de, ate });
        setOpcao(3);
      },
    },
    {
      label: t("estatisticas.labelByPeriod"),
      onClick: function () {
        setOpcao(4);
        setVerFiltro(true);
      },
    },
  ];
  // Array com as colunas a serem filtradas
  const arrCamposFiltros = [
    { coluna: 3, titulo: t("estatisticas.labelAccessBySO") },
    { coluna: 4, titulo: t("estatisticas.labelAccessByBrowser") },
    { coluna: 1, titulo: t("estatisticas.labelAccessByRoute") },
  ];

  return (
    <>
      <Drawer
        anchor={!isMobile ? "top" : "bottom"}
        onClose={() => setVerFiltro(false)}
        open={verFiltro}
      >
        <FiltroDataDeEAte
          deAte={deAte}
          setDeAte={setDeAte}
          fecharFiltro={() => setVerFiltro(false)}
        />
      </Drawer>
      <ToastContainer position="bottom-center" />
      <Stack direction="row">
        <SideBar barraIntegrada />
        <Container maxWidth={isXl ? "xl" : "lg"}>
          <Typography variant="h6" align="center">
            {t("estatisticas.title")}
          </Typography>
          <Paper
            sx={{ zIndex: 10, position: "sticky", top: 0, p: 1, my: 1 }}
            elevation={2}
          >
            <Stack
              sx={{
                alignItems: "center",
                flexWrap: "nowrap",
                overflowX: "auto",
              }}
              direction="row"
            >
              {chips.map((ele, idx) => (
                <Chip
                  icon={idx + 1 === opcao && <EventAvailableIcon />}
                  color={idx + 1 === opcao ? "primary" : "default"}
                  onClick={() => ele.onClick()}
                  label={ele.label}
                  size="small"
                  sx={{ mx: 1 }}
                />
              ))}
              {!isMobile && (
                <FormControlLabel
                  sx={{ mx: 1 }}
                  control={
                    <Switch
                      value={verNumeros}
                      onChange={() => setVerNumeros((state) => !state)}
                    />
                  }
                  label={t("estatisticas.labelQuantity")}
                />
              )}
            </Stack>
          </Paper>
          <Divider />
          {deAte && (
            <Typography color="error" align="center" variant="subtitle1">
              {t("estatisticas.periodOf")}{" "}
              {format(parseISO(deAte.de), "dd/MM/yy")}{" "}
              {t("estatisticas.periodTo")}
              {format(parseISO(deAte.ate), "dd/MM/yy")}
            </Typography>
          )}
          {isMobile && (
            <FormControlLabel
              sx={{ my: 1 }}
              control={
                <Switch
                  value={verNumeros}
                  onChange={() => setVerNumeros((state) => !state)}
                />
              }
              label={t("estatisticas.labelQuantity")}
            />
          )}

          <Grid container spacing={2}>
            {arrCamposFiltros.map((ele, idx) => (
              <RenderGrafMobile
                verNumeros={verNumeros}
                key={idx}
                coluna={ele.coluna}
                titulo={ele.titulo}
                data={estatisticas}
              />
            ))}
          </Grid>
          <Stack sx={{ my: 1 }}>
            {estatisticas && (
              <RenderGrafAcessoDia isMobile={isMobile} data={estatisticas} />
            )}
            {estatisticas && (
              <RenderGrafAcessoPorMenu
                isMobile={isMobile}
                data={estatisticas}
              />
            )}
            {estatisticas && (
              <RenderGrafTopSeteUsuario
                isMobile={isMobile}
                data={estatisticas}
              />
            )}
          </Stack>
          <br />
          <br />
          <br />
        </Container>
      </Stack>
    </>
  );
};

Estatisticas.rota = "/estatisticas";

export default Estatisticas;
