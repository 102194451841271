import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { oportunidadesSetModal } from "./oportunidades_actions";
import OportunidadesModal from "./oportunidades-modal";
import { Button, Stack } from "@mui/material";
import { Body2 } from "../../../components";
import { useTranslation } from "react-i18next";

//
const InfoColaboradorVaga = ({ dados }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fnVerResposta = useCallback(() => {
    dispatch(
      oportunidadesSetModal({
        tipo: OportunidadesModal.modal.VER_RESPOSTA_CANDIDATO,
        dados: dados,
      })
    );
  }, [dispatch, dados]);
  // Funcao para altear a fase
  const fnAlterarFase = useCallback(() => {
    dispatch(
      oportunidadesSetModal({
        tipo: OportunidadesModal.modal.ALTERAR_FASE,
        dados: {
          id_vaga: dados.id_vaga,
          fases: [],
        },
      })
    );
  }, [dados, dispatch]);
  // Para ver o historico da fase
  const fnVerHistorico = useCallback(() => {
    dispatch(
      oportunidadesSetModal({
        tipo: OportunidadesModal.modal.VER_HISTORICO,
        dados: {
          id_vaga: dados.id_vaga,
        },
      })
    );
  }, [dispatch, dados]);

  return (
    <Stack sx={{ mt: 0.5 }}>
      <Body2>{dados?.nome}</Body2>
      <Stack
        sx={{ mt: 1 }}
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <Button
          title={t("oportunidades.titleBtnViewHistory")}
          fullWidth
          size="small"
          variant="outlined"
          onClick={fnVerHistorico}
        >
          {t("oportunidades.labelBtnViewHistory")}
        </Button>
        <Button
          title={t("oportunidades.titleBtnViewAnswers")}
          fullWidth
          size="small"
          variant="outlined"
          onClick={fnVerResposta}
        >
          {t("oportunidades.labelBtnViewAnswers")}
        </Button>
        <Button
          title={t("oportunidades.titleBtnChangePhase")}
          fullWidth
          size="small"
          variant="contained"
          onClick={fnAlterarFase}
        >
          {t("oportunidades.labelBtnChangePhase")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default InfoColaboradorVaga;
