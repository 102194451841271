import { useDispatch, useSelector } from "react-redux";
import { seletorCardSecurityWait } from "./cartao-seguranca-selectors";
import { cartaoSegurancaValidacao } from "../../redux/actions";
import { Controller, useForm } from "react-hook-form";
import { Container } from "@mui/system";
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Icone } from "../../components";
import { useTranslation } from "react-i18next";

// Componente para exibir um form de um cartão incluso á validar
const FormValidarCartao = ({ id_cartao }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aguardar = useSelector(seletorCardSecurityWait);

  const { control, handleSubmit } = useForm();
  //
  const fn = (val, status) => {
    // Status (A volta para o status aberto, necessario gerar causa-raiz novamente, E encerrado, cartão foi encerrado)
    console.log(val, " ", status);
    dispatch(
      cartaoSegurancaValidacao({
        justificativa: val.justificativa,
        status,
        id_cartao,
      })
    );
  };

  return (
    <Container maxWidth="sm">
      <Stack spacing={2}>
        <Typography variant="h6" align="center">
          {t("cartao_seguranca.titleJustifyAndObservation")}
        </Typography>
        <Typography sx={{ opacity: 0.7 }} variant="caption" align="center">
          {t("cartao_seguranca.subtitleJustifyAndObservation")}
        </Typography>
        <Controller
          control={control}
          name="justificativa"
          defaultValue=""
          //rules={{required: true}}
          render={({ field }) => (
            <TextField
              fullWidth
              type="textarea"
              multiline
              minRows={3}
              {...field}
              label={t("cartao_seguranca.justificativaLabel")}
              InputLabelProps={{ shrink: true }}
              placeholder={t("cartao_seguranca.justificativaPlaceholder")}
            />
          )}
        />
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent={{ xs: "stretch", md: "center" }}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ flex: { xs: 1 } }}
            startIcon={
              aguardar ? (
                <CircularProgress size={20} />
              ) : (
                <Icone icone="ThumbDown" />
              )
            }
            disabled={aguardar}
            onClick={handleSubmit((val) => fn(val, "C"))}
          >
            {t("cartao_seguranca.textBtnCancel")}
          </Button>
          <Button
            variant="contained"
            sx={{ flex: { xs: 1 } }}
            startIcon={
              aguardar ? (
                <CircularProgress size={20} />
              ) : (
                <Icone icone="ThumbUp" />
              )
            }
            disabled={aguardar}
            onClick={handleSubmit((val) => fn(val, "A"))}
          >
            {t("cartao_seguranca.textBtnValidate")}
          </Button>
        </Stack>
        <br />
      </Stack>
    </Container>
  );
};

export default FormValidarCartao;
