import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";
import {
  Stack,
  Container,
  useMediaQuery,
  useTheme,
  CircularProgress,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Fab,
  Grid,
  ListItem,
  Chip,
  Menu,
  Paper,
  MenuItem,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  colaboradoresFecharDrawer,
  colaboradoresSetDrawer,
  colaboradoresGetColaboradores,
} from "./colaboradores-actions";
import {
  SideBar,
  Icone,
  BotaoIcone,
  H6,
  ScrollInfinito,
  Body2,
  Body1,
  Caption,
  InputBusca,
} from "../../../components";
import { registrarAcesso } from "../../../utils/utils";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  selectColaboradores,
  selectAguardar,
  selectDrawer,
  selectPlanta,
  selectColaboradorXMenu,
} from "./colaboradores-select";
import { DrawerDialog } from "../../../extra-components";
import ColaboradoresModal from "./colaboradores-modal";
// Path base para avatar
const PATH_AVATAR = "/static/imagens/avatar/";
//

//
const MODAL = {
  ADD_COLABORADOR: "ADD_COLABORADOR",
  VER_MENU_COLABORADOR: "VER_MENU_COLABORADOR",
  ALTERAR_GESTOR_SITUACAO: "ALTERAR_GESTOR_SITUACAO",
  ALTERAR_INFO_PESSOAIS: "ALTERAR_INFO_PESSOAIS",
};

function Colaboradores() {
  const aguardar = useSelector(selectAguardar);
  const dados = useSelector(selectColaboradores);
  const { t } = useTranslation();
  const drawer = useSelector(selectDrawer);

  const dispatch = useDispatch();
  // UseEffect para registrar acesso a pagina
  useEffect(() => registrarAcesso(window.location.pathname), []);
  // UseEffect para carregar os dados iniciais
  useEffect(() => dispatch(colaboradoresGetColaboradores()), [dispatch]);

  const isXl = useMediaQuery(useTheme()?.breakpoints?.up("xl"));
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  const fnFecharDrawer = useCallback(
    () => dispatch(colaboradoresFecharDrawer()),
    [dispatch]
  );

  //
  return (
    <Stack direction="row">
      {drawer && (
        <DrawerDialog
          fnGetCorpo={() => <ColaboradoresModal modal={drawer} />}
          fecharModal={fnFecharDrawer}
        />
      )}

      <SideBar barraIntegrada />
      <Container disableGutters={isMobile} maxWidth={isXl ? "xl" : "lg"}>
        <H6>{t("colaboradores.pageTitle")}</H6>
        <Stack sx={{ my: 1 }} direction="row-reverse">
          <FAB />
        </Stack>
        {aguardar ? (
          <CircularProgress />
        ) : dados ? (
          <RenderCorpo corpo={dados} />
        ) : null}
      </Container>
    </Stack>
  );
}
//
const FAB = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const fnAdd = () => {
    dispatch(colaboradoresSetDrawer({ tipo: MODAL.ADD_COLABORADOR }));
  };
  return (
    <Fab
      color="primary"
      title={t("colaboradores.fabTitle")}
      size="medium"
      onClick={fnAdd}
      variant={isMobile ? "circular" : "extended"}
      sx={{
        position: { xs: "fixed", md: "static" },
        right: { xs: 16, md: 0 },
        bottom: { xs: 72, md: 0 },
      }}
    >
      <Icone icone="Add" />
      {!isMobile && t("colaboradores.fabLabel")}
    </Fab>
  );
};

// Renderiza o corpo
const RenderCorpo = ({ corpo }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const [filtro, setFiltro] = useState("");
  const [verGestor, setVerGestor] = useState(false);
  const [verElegivel, setVerElegivel] = useState(false);
  const [verAtivo, setVerAtivo] = useState(false);
  const [verInativo, setVerInativo] = useState(false);

  const subFiltros = [
    {
      nome: t("colaboradores.filterLabelManager"),
      icone: "Engineering",
      ativo: verGestor,
      onClick: () => setVerGestor((state) => !state),
    },
    {
      nome: t("colaboradores.filterLabelEligible"),
      icone: "Build",
      ativo: verElegivel,
      onClick: () => setVerElegivel((state) => !state),
    },
    {
      nome: t("colaboradores.filterLabelActive"),
      icone: "Person",
      ativo: verAtivo,
      onClick: () => setVerAtivo((state) => !state),
    },
    {
      nome: t("colaboradores.filterLabelInactive"),
      icone: "PersonOff",
      ativo: verInativo,
      onClick: () => setVerInativo((state) => !state),
    },
  ];
  // Filtra sobre o corpo criando um novo corpo
  let _filtroCorpo = _.filter(
    corpo,
    (val) =>
      // Filtros por nome, matricula, cargo ou email
      _.toLower(val.data[0])?.search(_.toLower(filtro)) !== -1 ||
      _.toLower(val.data[1])?.search(_.toLower(filtro)) !== -1 ||
      _.toLower(val.data[2])?.search(_.toLower(filtro)) !== -1 ||
      _.toLower(val.data[4])?.search(_.toLower(filtro)) !== -1
  );
  // Veja se tem o filtro gestor ativo
  if (verGestor) {
    _filtroCorpo = _.filter(_filtroCorpo, (val) => val.data[8] === "S");
  }
  // Veja se tem o filtro do elegivel ativo
  if (verElegivel) {
    _filtroCorpo = _.filter(_filtroCorpo, (val) => val.data[9] === "S");
  }
  // Se o filtro ativo estiver marcado
  if (verAtivo) {
    _filtroCorpo = _.filter(_filtroCorpo, (val) => val.data[7] === "A");
  }
  // Se o filtro inativo estiver marcado
  if (verInativo) {
    _filtroCorpo = _.filter(_filtroCorpo, (val) => val.data[7] !== "A");
  }

  return (
    <Stack>
      <Grid sx={{ px: 1 }} container spacing={!isMobile ? 1 : 0}>
        <Grid item xs={12} md={6}>
          <InputBusca
            fullWidth
            desativarPesquisaLenta
            sx={{ mb: 0 }}
            filtro={filtro}
            setFiltro={setFiltro}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {!isMobile && (
            <Stack sx={{ my: 1, mx: 1 }} direction="row" spacing={1}>
              {subFiltros.map((ele, idx) => (
                <Chip
                  onClick={ele.onClick}
                  key={idx}
                  label={ele.nome}
                  color={ele.ativo ? "primary" : "default"}
                  icon={<Icone icone={ele.icone} />}
                />
              ))}
            </Stack>
          )}
        </Grid>
      </Grid>

      {isMobile && (
        <Stack sx={{ my: 1, mx: 1 }} direction="row" spacing={1}>
          {subFiltros.map((ele, idx) => (
            <Chip
              onClick={ele.onClick}
              key={idx}
              label={ele.nome}
              color={ele.ativo ? "primary" : "default"}
              icon={<Icone icone={ele.icone} />}
            />
          ))}
        </Stack>
      )}
      <Body2 sx={{ ml: 1, mb: 0.5 }} fontWeight="bold">
        {t("colaboradores.labelTotal")}: {_filtroCorpo?.length}
      </Body2>
      <ScrollInfinito
        itens={_filtroCorpo}
        itensPorPagina={20}
        tamanho="80vh"
        render={({ data, id }) => <RenderColaborador key={id} data={data} />}
      />
    </Stack>
  );
};
// Renderiza um colaborador
const RenderColaborador = ({ data }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const planta = useSelector(selectPlanta);
  // Os espacos entre os valores de virgula sao necessarios para desconsiderar campos
  // favor nao eliminar
  const [
    matricula,
    nome,
    email,
    admissao,
    cargo,
    arvore,
    gestor_arvore,
    situacao,
    gestor,
    elegivel,
  ] = data;
  // Arrray de opcoes para se fazer com o colaborador
  const itens = [
    {
      titulo: t("colaboradores.optionLabelMenu"),
      icone: "ListAlt",
      onClick: useCallback(() => {
        dispatch(
          colaboradoresSetDrawer({
            tipo: MODAL.VER_MENU_COLABORADOR,
            dados: matricula,
          })
        );
        fnFecharMenu();
      }, [matricula, dispatch]),
    },
    {
      titulo: t("colaboradores.optionLabelManager"),
      icone: "SwapVert",
      onClick: useCallback(() => {
        dispatch(
          colaboradoresSetDrawer({
            tipo: MODAL.ALTERAR_GESTOR_SITUACAO,
            dados: matricula,
          })
        );
        fnFecharMenu();
      }, [matricula, dispatch]),
    },
    {
      titulo: t("colaboradores.optionLabelInfoPerson"),
      icone: "Edit",
      onClick: useCallback(() => {
        dispatch(
          colaboradoresSetDrawer({
            tipo: MODAL.ALTERAR_INFO_PESSOAIS,
            dados: matricula,
          })
        );
        fnFecharMenu();
      }, [matricula, dispatch]),
    },
  ];
  // Exibe menu
  const fnVerMenu = (evt) => setAnchorEl(evt.currentTarget);
  // Fecha menu
  const fnFecharMenu = () => setAnchorEl(null);

  return (
    <Paper
      sx={{ mx: isMobile ? 0 : 1, my: isMobile ? 0 : 1 }}
      elevation={isMobile ? 0 : 3}
    >
      <ListItem
        divider
        secondaryAction={
          isMobile && (
            <BotaoIcone icone="MoreVert" color="primary" onClick={fnVerMenu} />
          )
        }
      >
        <ListItemAvatar>
          <Avatar
            src={`${PATH_AVATAR}/${planta}/${matricula}.jpg`}
            alt={nome}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            isMobile
              ? nome
              : `${matricula} - ${nome} - ${t(
                  "colaboradores.dateOfContract"
                )}: ${format(parseISO(admissao), "dd/MM/yy")}`
          }
          secondary={
            <Stack alignItems="flex-start" spacing={1}>
              <RenderColaboradorItems
                matricula={matricula}
                cargo={cargo}
                email={email}
                situacao={situacao}
                gestor={gestor}
                elegivel={elegivel}
                arvore={arvore}
                gestor_arvore={gestor_arvore}
              />
              {!isMobile && (
                <Button
                  size="small"
                  endIcon={<Icone icone="ArrowDropDown" />}
                  onClick={fnVerMenu}
                  variant="contained"
                >
                  {t("colaboradores.optionsLabel")}
                </Button>
              )}
            </Stack>
          }
        />
      </ListItem>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={fnFecharMenu}>
        {itens.map((ele, idx) => (
          <MenuItem key={idx} onClick={() => ele.onClick()}>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Icone icone={ele.icone} />
              <Caption>{ele.titulo}</Caption>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Paper>
  );
};
// Renderiza itens visuais abaixo do colaborador
const RenderColaboradorItems = ({
  gestor,
  elegivel,
  situacao,
  matricula,
  cargo,
  email,
  arvore,
  gestor_arvore,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const colaboradorXMenu = useSelector(selectColaboradorXMenu);
  const totMenus = colaboradorXMenu.hasOwnProperty(matricula)
    ? Object.keys(colaboradorXMenu[matricula]).length
    : 0;

  return (
    <Stack spacing={0.5}>
      <Stack direction="row" spacing={1}>
        <Body2>{cargo}</Body2>
        {!isMobile && (
          <Body1 fontWeight="bold" color="info.main">
            {" "}
            - {arvore}
          </Body1>
        )}
      </Stack>
      {!isMobile && (
        <Stack direction="row" spacing={1}>
          <Caption>{t("colaboradores.labelManagerOfThree")}</Caption>
          <Caption fontWeight="bold">{gestor_arvore}</Caption>
        </Stack>
      )}

      <Caption align="left">{email}</Caption>
      <Stack direction="row" alignItems="center" spacing={1}>
        <RenderColaboradorItemView
          texto={totMenus}
          titulo={t("colaboradores.colabItemTitle")}
          sx={{ color: "info.main" }}
        />
        <Chip
          size="small"
          icon={<Icone icone={situacao === "A" ? "ThumbUp" : "ThumbDown"} />}
          label={
            situacao === "A"
              ? t("colaboradores.filterLabelActive")
              : t("colaboradores.filterLabelInactive")
          }
          color={situacao === "A" ? "success" : "error"}
        />
        {gestor === "S" && (
          <Chip
            title={t("colaboradores.filterLabelManager")}
            size="small"
            color="info"
            label={t("colaboradores.filterLabelManager")}
            icon={<Icone icone="Engineering" />}
          />
        )}
        {elegivel === "S" && (
          <Chip
            title={t("colaboradores.filterLabelEligible")}
            size="small"
            color="secondary"
            label={t("colaboradores.filterLabelEligible")}
            icon={<Icone icone="Build" />}
          />
        )}
      </Stack>
    </Stack>
  );
};
//
const RenderColaboradorItemView = ({ texto, titulo, sx = {} }) => (
  <Stack title={titulo} direction="row" alignItems="center" spacing={0.2}>
    <Icone icone="ListAlt" sx={{ fontSize: "20px", ...sx }} />
    <Caption sx={{ ...sx }} fontWeight="bold">
      ({texto})
    </Caption>
  </Stack>
);

Colaboradores.rota = "/colaboradores";

export default Colaboradores;
