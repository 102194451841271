import React from "react";
import { selectTrainningItems } from "./treinamento-selectors";
import { Grid, Paper, Stack } from "@mui/material";
import { AnimacaoSemDados, Body1, H6 } from "../../../components";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import TrainningStatusChip from "./components/trainning-status-chip";
import { useTranslation } from "react-i18next";

function TreinamentoVerHistorico({ id }) {
  const { t } = useTranslation();
  const trainnings = useSelector(selectTrainningItems);
  const trainning = trainnings.find((item) => item.id === id);
  return (
    <Stack>
      <H6>{t("treinamento.trainningViewHistoryTitle")}</H6>

      {trainning?.historico?.length === 0 ? (
        <AnimacaoSemDados />
      ) : (
        trainning?.historico?.map((item, index) => (
          <Grid container spacing={1} key={index} sx={{ width: "100%", my: 1 }}>
            <Paper sx={{ p: 1, width: "100%" }}>
              <Grid item xs={12} key={index}>
                <Stack direction="row" gap={2}>
                  <RowAlign
                    label={t("treinamento.trainningViewHistoryLabelMat")}
                    value={item.matricula}
                  />
                  <RowAlign
                    label={t("treinamento.trainningViewHistoryLabelName")}
                    value={item.nome}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <RowAlign
                    label={t("treinamento.trainningViewHistoryLabelFromStatus")}
                    value={<TrainningStatusChip status={item.de_situacao} />}
                  />
                  <RowAlign
                    label={t("treinamento.trainningViewHistoryLabelToStatus")}
                    value={<TrainningStatusChip status={item.para_situacao} />}
                  />
                </Stack>
                <RowAlign
                  label={t("treinamento.trainningViewHistoryLabelDate")}
                  value={format(
                    parseISO(item.data_movimento),
                    "dd/MM/yyyy HH:mm"
                  )}
                />
                <RowAlign
                  label={t("treinamento.trainningViewHistoryLabelObservation")}
                  value={item.observacao}
                />
              </Grid>
            </Paper>
          </Grid>
        ))
      )}
    </Stack>
  );
}

const RowAlign = ({ label, value }) => {
  return (
    <Stack direction="row" alignItems="baseline" spacing={1}>
      <H6>{label}:</H6>
      <Body1 sx={{ whiteSpace: "pre-wrap" }}>{value}</Body1>
    </Stack>
  );
};

export default TreinamentoVerHistorico;
