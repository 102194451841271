import { toast } from "react-toastify";
import fetchRedux from "../../../api/fetch_redux";
import { ToastErro } from "../../../utils/utils";
import _ from "lodash";

export const RNCINCP_DADOS = "RNCINCP_DADOS";
export const RNCINCP_PAGINA = "RNCINCP_PAGINA";
export const RNCINCP_LIMPAR_DADOS = "RNCINCP_LIMPAR_DADOS";
export const RNCINCP_FECHAR_MODAL = "RNCINCP_FECHAR_MODAL";
export const RNCINCP_SET_MODAL = "RNCINCP_SET_MODAL";
export const RNCINCP_ADD = "RNCINCP_ADD";
export const RNCINCP_SUBIDA_LINHA = "RNCINCP_SUBIDA_LINHA";
export const RNCINCP_QUALIDADE = "RNCINCP_QUALIDADE";
export const RNCINCP_SET_FILTER = "RNCINCP_SET_FILTER";
export const RNCINCP_CLEAR_FILTER = "RNCINCP_CLEAR_FILTER";
export const RNCINCP_SET_PAGE_FILTER = "RNCINCP_SET_PAGE_FILTER";
export const RNCINCP_CANCEL_STATUS = "RNCINCP_CANCEL_STATUS";

//
export const ROTAS = ["/rnci_ncp", "/rnci_ncp_filtro"];

export const rnciNcpInit = (setAguardar) => (dispatch) => {
  // A listagem de dados para construir o chamado
  fetchRedux(
    `${ROTAS[0]}?dados=true`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: RNCINCP_DADOS,
        data: resposta,
      });
    },
    () => {},
    (err) => ToastErro(err)
  );
  // Recupera os dados da primeira pagina
  dispatch(rnciNcpPagina(1, setAguardar));
};

// Salta entre as paginas
export const rnciNcpPagina = (idPagina, setAguardar) => (dispatch) => {
  // Obtem os chamados do usuário verificando de tem ordenacao
  //
  const params = new URLSearchParams();
  params.append("pagina", idPagina);

  fetchRedux(
    `${ROTAS[0]}?${params.toString()}`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: RNCINCP_PAGINA,
        data: {
          idPagina,
          dados: resposta,
        },
      });
    },
    setAguardar,
    (err) => ToastErro(err)
  );
};
// Limpar os dados para sair
export const rnciNcpLimparDados = () => ({
  type: RNCINCP_LIMPAR_DADOS,
});
// Ativar modal
export const rnciNcpSetModal = (obj) => ({
  type: RNCINCP_SET_MODAL,
  data: obj,
});
// FecharModal
export const rnciNcpFecharModal = () => ({
  type: RNCINCP_FECHAR_MODAL,
});

// Criar rnciNcp
export const rnciNcpAdd =
  (formData, setAguardar, fnRetornoSucesso) => (dispatch) => {
    //  Cria um novo rnci
    fetchRedux(
      ROTAS[0],
      "POST",
      formData,
      (resposta) => {
        toast.dark(resposta.sucesso, {
          type: "success",
        });
        dispatch(rnciNcpFecharModal());
        // Funcao executada quando sucesso na insercao
        if (fnRetornoSucesso) {
          fnRetornoSucesso();
        }
        //
        dispatch({
          type: RNCINCP_ADD,
          data: resposta.data,
        });
      },
      setAguardar,
      (err) => ToastErro(err)
    );
  };
// Subida de linha rnci
export const rnciNcpSubidaDeLinha =
  (formData, setAguardar, fnRetornoSucesso) => (dispatch) => {
    //  Cria um novo rnci
    fetchRedux(
      ROTAS[0],
      "PUT",
      formData,
      (resposta) => {
        toast.dark(resposta.sucesso, {
          type: "success",
        });
        dispatch(rnciNcpFecharModal());
        // Funcao executada quando sucesso na insercao
        if (fnRetornoSucesso) {
          fnRetornoSucesso();
        }
        //
        dispatch({
          type: RNCINCP_SUBIDA_LINHA,
          data: resposta.data,
        });
      },
      setAguardar,
      (err) => ToastErro(err)
    );
  };
// Subida de linha rnci
export const rnciNcpQualidade =
  (formData, setAguardar, fnRetornoSucesso) => (dispatch) => {
    //  Cria um novo rnci
    fetchRedux(
      ROTAS[0],
      "PATCH",
      formData,
      (resposta) => {
        toast.dark(resposta.sucesso, {
          type: "success",
        });
        dispatch(rnciNcpFecharModal());
        // Funcao executada quando sucesso na insercao
        if (fnRetornoSucesso) {
          fnRetornoSucesso();
        }
        //
        dispatch({
          type: RNCINCP_QUALIDADE,
          data: resposta.data,
        });
      },
      setAguardar,
      (err) => ToastErro(err)
    );
  };

export const rnciNcpSetFilter = (filter, setAguardar) => (dispatch) => {
  if (!filter || _.keys(filter).length === 0) {
    dispatch({
      type: RNCINCP_CLEAR_FILTER,
    });
    return;
  }
  const formData = new URLSearchParams();
  _.forEach(_.keys(filter), (k) => {
    formData.append(k, filter[k].join(","));
  });
  //  Aplicar filtro RNCI
  fetchRedux(
    `${ROTAS[1]}?${formData.toString()}`,
    "GET",
    null,
    (response) => {
      //
      dispatch({
        type: RNCINCP_SET_FILTER,
        data: {
          data: response,
          filter,
        },
      });
    },
    setAguardar,
    (err) => ToastErro(err)
  );
};

export const rnciNcpClearFilter = () => ({
  type: RNCINCP_CLEAR_FILTER,
});

export const rnciNcpPaginaFiltro = (pageId) => ({
  type: RNCINCP_SET_PAGE_FILTER,
  data: pageId,
});

export const rnciNcpCancelStatus = (objData, setWait) => (dispatch) => {
  //  Cria um novo rnci
  fetchRedux(
    ROTAS[0],
    "DELETE",
    objData,
    (resposta) => {
      toast.dark(resposta.sucesso, {
        type: "success",
      });
      dispatch(rnciNcpFecharModal());

      //
      dispatch({
        type: RNCINCP_CANCEL_STATUS,
        data: {
          data: resposta?.data,
          id: objData.codigo_rnci,
        },
      });
    },
    setWait,
    (err) => ToastErro(err)
  );
};
