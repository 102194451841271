import React from 'react';
import PropTypes from 'prop-types';
import styled, {css } from 'styled-components';
import { motion } from 'framer-motion';
/**
Um componente que exibe um icone usando os icones disponíveis no material design.
*/

const Wrapper = motion.custom(styled.i`
    cursor: ${props=> props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props=> props.disabled ? '.3' : 1};
    background: ${props=> props.bgColor ? props.bgColor : '#fff'};
    color: ${props=> props.textColor ? props.textColor : '#000'};

    ${ props=> props.circle && css`
        padding: 4px;
        margin: 6px;
        border-radius: 100%;
        box-shadow: 0 1px 4px rgba(0,0,0,.4);
        transition: all .05s;

        ${this}:hover {
            transform: scale(1.1);
            box-shadow: 0 1px 8px rgba(0,0,0,.4);
        }
    `};
`);

// Animacoes disponiveis
const animations = {
    fade : {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {
                duration: .8,
            }
        },
        exit: {
            opacity: 0,
        }
    },
    scale: {
        hidden: {
            scale: 0.01,
        },
        visible: {
            scale: 1,
            transition: {
                duration: .3,
            }
        },
        exit: {
            scale: 0.01,
            transition: {
                duration: .1,
            }
        }
    },
    leftToPosition: {
        hidden: {
            x: '-150vw',
        },
        visible: {
            x: 0,
            transition: {
                duration: .8,
            }
        },
        exit: {
            x: '-150vw'
        }
    },
    rightToPosition : {
        hidden: {
            x: '150vw',
        },
        visible: {
            x: 0,
            transition: {
                duration: .8,
            }
        },
        exit: {
            x: '150vw'
        }
    },
    topToPosition: {
        hidden: {
            y: '-50vh',
        },
        visible: {
            y: 0,
            transition: {
                duration: .8,
            }
        },
        exit: {
            y: '-50vh'
        }
    },
    bottomToPosition : {
        hidden: {
            y: '150vh',
        },
        visible: {
            y: 0,
            transition: {
                duration: .8,
            }
        },
        exit: {
            y: '150vh'
        }
    }
}

const Icone = (props)=> {

    const className = `material-icons Icone ${props.className}`;
    // Determina se o icone deve ser clicado ou nao
    const fn = props.disabled ? ()=> {} : props.onClick;
    const fnDbl = props.disabled ? ()=> {} : props.onDoubleClick;

    // Determina o titulo, se tiver disabled determina o titulo como desativado
    const title = props.disabled ? 'DESATIVADO' : props.title;
    // Veja se tem efeito a ser inserido
    const effect = props.effect && animations.hasOwnProperty(props.effect) ? animations[props.effect] : null;
    
    return (
        <Wrapper className={className} circle={props.circle} 
            onClick={fn} disabled={props.disabled} title={title}
            onDoubleClick={fnDbl}
            bgColor={props.bgColor} textColor={props.textColor}
            variants={effect} initial='hidden' animate='visible' exit='exit'
            style={props.style}
        >{props.icon}</Wrapper>
    )
}

Icone.defaultProps = {
    onClick: ()=> {},
    onDoubleClick: ()=> {},
    title: 'Clique aqui',
    className: '',
    style: {},
}

Icone.propTypes = {
    /** Nome do icone que será renderizado. Os icons são os disponiveis no Material Design */
    icon: PropTypes.string.isRequired,
    /** Determina qual efeito será aplicado ao icone. (Se tiver saída deve ser envolvido por AnimatePresence) */
    effect: PropTypes.oneOf(['scale', 'fade', 'topToPosition', 'leftToPosition', 'rightToPosition', 'bottomToPosition']),
    /** Esta props recebe uma função para responder a algum evento quando o ícone é clicado */
    onClick: PropTypes.func,
    /** Esta props recebe uma função de callback para responder a eventos de clique duplo no icone */
    onDoubleClick: PropTypes.func,
    /** Esta props determina que o botão deve ser arredondado */
    circle: PropTypes.bool,
    /** Esta props desativa o icone (nao permite clique) */
    disabled: PropTypes.bool,
    /** Esta props recebe uma cor hexadecimal que pode ser usada para o fundo do icone */
    bgColor: PropTypes.string,
    /** Esta props recebe uma cor hexadecimal que pode ser usada para a cor do ícone em si */
    textColor: PropTypes.string,
    /** Um titulo que pode ser enviado para o botao */
    title: PropTypes.string,
    /** Uma classe para ser aplicada ao icone */
    className: PropTypes.string,
    /** Um objeto que pode ser aplicado como estilo ao componente */
    style: PropTypes.object,

}


export default Icone;