import React from 'react'
import { Container } from '@mui/material';
import BackgroundRouter from '../background-router';

export default function Saving() {
  const _html = '<iframe title="Saving por Setor 2.0 - Saving Geral" style="width:100%; height:calc(100vh - 80px)" src="https://app.powerbi.com/view?r=eyJrIjoiZTE4YTRlOTUtZjBkZi00MWJlLTg4MWMtNmRmNTRjYTdmYmI0IiwidCI6Ijk4NmIwMzBlLWJmMzEtNDdlNy1hZTk0LWYyNzQ4MTA1ZTU0NiJ9" frameborder="0" allowFullScreen="true"></iframe>';
  return (
    <BackgroundRouter>
      <Container sx={{ mt: 1 }} maxWidth={false}>
        <div dangerouslySetInnerHTML={{ __html: _html }} />
      </Container>

    </BackgroundRouter>
  )
}
