import { useDispatch, useSelector } from "react-redux";
import { selectAguardar, selectDados } from "./twttp-selectors";
import _ from "lodash";
import { Container } from "@mui/material";
import { twttpGetItensFiltro } from "./twttp-actions";
import { ToastErro } from "../../../utils/utils";
import { MultipleSearchFilter } from "../../../components";
import { useTranslation } from "react-i18next";

// Componente que retorna opcoes para um filtro avancado
const VerFiltroAvancado = () => {
  const { t } = useTranslation();
  const dados = useSelector(selectDados);
  const aguardar = useSelector(selectAguardar);
  const dispatch = useDispatch();
  //
  const schema = [
    {
      type: "select",
      itens: dados?.codigos,
      autoFormat: true,
      isMulti: true,
      label: t("twttp.labelCodeFilterAdvanced"),
      icon: "Search",
      name: "codigo",
    },
    {
      type: "select",
      itens: dados.twttp_tipos,
      label: t("twttp.labelTypeFilterAdvanced"),
      isMulti: true,
      autoFormat: true,
      icon: "MergeType",
      name: "tipo",
    },
    {
      type: "select",
      isMulti: true,
      itens: dados.origens,
      label: t("twttp.labelOriginFilterAdvanced"),
      autoFormat: true,
      icon: "AirlineStops",
      name: "origem",
    },
    {
      type: "select",
      isMulti: true,
      itens: dados.colaboradores,
      label: t("twttp.labelInterviewerFilterAdvanced"),
      autoFormat: true,
      icon: "Engineering",
      name: "entrevistador",
    },
    {
      type: "select",
      itens: dados.colaboradores,
      isMulti: true,
      autoFormat: true,
      label: t("twttp.labelInterviewedFilterAdvanced"),
      icon: "Person",
      name: "entrevistado",
    },
    {
      type: "select",
      itens: dados.setores,
      label: t("twttp.labelSectorFilterAdvanced"),
      isMulti: true,
      autoFormat: true,
      icon: "AccountTree",
      name: "setor",
    },
    {
      type: "select",
      itens: dados.areas,
      autoFormat: true,
      label: t("twttp.labelAreaFilterAdvanced"),
      icon: "PrecisionManufacturing",
      isMulti: true,
      name: "area",
    },
    {
      type: "switch",
      label: t("twttp.labelLateFilterAdvanced"),
      icon: "EventBusy",
      name: "atrasado",
    },
  ];
  //
  const onSubmit = async (val) => {
    // verifica se tem os campos e cria o filtro
    // para enviar ao dispatch
    if (val && Object.keys(val).length > 0) {
      const obj = {};
      _.keys(val).forEach((k) => {
        if (val[k]) {
          obj[k] = Array.isArray(val[k]) ? val[k].join(",") : val[k];
          dispatch(twttpGetItensFiltro(obj, true));
        } else {
          ToastErro(
            `${t("twttp.errorParamFilterAdvanced1")} ${k} ${t(
              "twttp.errorParamFilterAdvanced2"
            )}`
          );
        }
      });
    }
  };

  return (
    <Container maxWidth="md" sx={{ minHeight: "50vh" }}>
      <MultipleSearchFilter
        schema={schema}
        onSubmit={onSubmit}
        wait={aguardar}
      />
    </Container>
  );
};

export default VerFiltroAvancado;
