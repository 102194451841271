import {
  Breadcrumbs,
  Button,
  Chip,
  Fab,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import HomeIcon from "@mui/icons-material/Home";
import GaleriaDeFotosModal from "./galeria-de-fotos-modal";
import { galeriaDeFotosSetDrawer } from "./galeria-de-fotos-actions";

// Componente que exibe um painel para acoes comuns, como insercao de um novo album
const PainelSuperior = ({ areaAtual, setAreaAtual, isEditorFeed }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  // Funcao para chamar drawer para adicionar novo album
  const fnAddAlbum = () =>
    dispatch(
      galeriaDeFotosSetDrawer({ tipo: GaleriaDeFotosModal.modal.ADD_ALBUM })
    );
  // Funcao para chamar drawer de inclusao de fotos
  const fnAddFoto = () =>
    dispatch(
      galeriaDeFotosSetDrawer({
        tipo: GaleriaDeFotosModal.modal.ADD_FOTO,
        dados: areaAtual,
      })
    );
  //
  const fnAdd = areaAtual?.nome === "home" ? fnAddAlbum : fnAddFoto;

  //
  return (
    <Stack direction="row" spacing={1} sx={{ my: 1 }}>
      <Breadcrumbs sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <Chip
          size="small"
          color="primary"
          variant={areaAtual?.nome === "home" ? "filled" : "outlined"}
          title="Home"
          label="Home"
          onClick={() => setAreaAtual({ id: 0, nome: "home" })}
          icon={<HomeIcon />}
        />
        {areaAtual?.nome !== "home" && (
          <Chip
            size="small"
            color="primary"
            variant="filled"
            title={areaAtual.nome}
            label={areaAtual.nome}
            //icon={<HomeIcon />}
          />
        )}
      </Breadcrumbs>
      {isEditorFeed ? (
        isMobile ? (
          <Fab
            size="medium"
            color="primary"
            aria-label="add"
            onClick={fnAdd}
            sx={{ zIndex: 1000, position: "fixed", right: 16, bottom: 72 }}
          >
            {areaAtual?.nome === "home" ? <AddIcon /> : <AddAPhotoIcon />}
          </Fab>
        ) : (
          <Button
            title={t("galeria_de_fotos.titleBtnAdd")}
            variant="contained"
            onClick={fnAdd}
            startIcon={
              areaAtual?.nome === "home" ? <AddIcon /> : <AddAPhotoIcon />
            }
          >
            {areaAtual?.nome === "home"
              ? t("galeria_de_fotos.labelBtnAddAlbum")
              : t("galeria_de_fotos.labelBtnAddPhoto")}
          </Button>
        )
      ) : null}
    </Stack>
  );
};

export default PainelSuperior;
