import React, { useCallback } from "react";
import { Confirmar } from "../../../components";
import { useDispatch } from "react-redux";
import { trainningCloseModal, trainningCourseDel } from "./treinamento-actions";
import { useToggle } from "react-use";
import { useTranslation } from "react-i18next";

export default function TreinamentoDelCurso({ id }) {
  const [wait, setWait] = useToggle(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onConfirm = useCallback(() => {
    dispatch(trainningCourseDel({ id: id }, setWait));
  }, [dispatch, setWait, id]);

  const onCancel = useCallback(() => {
    dispatch(trainningCloseModal());
  }, [dispatch]);

  return (
    <Confirmar
      titulo={t("treinamento.titleDelCourse")}
      subtitulo={t("treinamento.subtitleDelCourse")}
      aguardar={wait}
      fnCancelar={onCancel}
      fnConfirmar={onConfirm}
    />
  );
}
