import { Grid, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

// Componente para lidar com as informacoes pessoais
const InformacoesPessoais = ({ info_pessoais }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={6}>
        <Typography variant="body2">
          {info_pessoais.matricula} - {info_pessoais.nome}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography component="span" variant="subtitle2">
          {t("contra_cheque.contraChequeFuncao")}:{" "}
        </Typography>
        <Typography component="span" variant="body2">
          {info_pessoais.cargo}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography component="span" variant="subtitle2">
          {t("contra_cheque.contraChequeAdmissao")}:{" "}
        </Typography>
        <Typography component="span" variant="body2">
          {format(parseISO(info_pessoais.admissao), "dd/MM/yyyy")}
        </Typography>
      </Grid>
    </>
  );
};

export default InformacoesPessoais;
