import React, { useCallback } from "react";
import { Confirmar } from "../../../components";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { ncpCancelStatus, ncpFecharModal } from "./ncp-actions";
import { useTranslation } from "react-i18next";

const getSubTitle = (status, t) => {
  switch (status) {
    case "A":
    default:
      return t("ncp.subtitleCancel");
  }
};

function NcpCancelStatus({ id, statusActualy }) {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const onConfirm = useCallback(() => {
    dispatch(ncpCancelStatus({ codigo_ncp: id }, setWait));
  }, [dispatch, id, setWait]);
  //
  const onCancel = useCallback(() => {
    dispatch(ncpFecharModal());
  }, [dispatch]);
  return (
    <Confirmar
      aguardar={wait}
      titulo={t("ncp.titlePageCancel")}
      subtitulo={getSubTitle(statusActualy, t)}
      fnCancelar={onCancel}
      fnConfirmar={onConfirm}
    />
  );
}

export default NcpCancelStatus;
