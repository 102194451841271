import axios from  'axios';
import {toast} from 'react-toastify';
import { ToastErro } from '../../utils/utils';
import { setAguardar } from './aguardar-actions';
import { ERRO_NAO_ENVIADO, ERRO_STATUS_NAO_200 } from './erro-actions';
//
export const MANUTENCAO_AREA_INIT_AREA = 'MANUTENCAO_AREA_INIT_AREA';
export const MANUTENCAO_AREA_INIT_MODELOS = 'MANUTENCAO_AREA_INIT_MODELOS';
export const MANUTENCAO_AREA_INIT_AREA_X_MODELO = 'MANUTENCAO_AREA_INIT_AREA_X_MODELO';
export const MANUTENCAO_AREA_SET_DRAWER = 'MANUTENCAO_AREA_SET_DRAWER';
export const MANUTENCAO_AREA_FECHAR_DRAWER = 'MANUTENCAO_AREA_FECHAR_DRAWER';
export const MANUTENCAO_AREA_ALTERAR_AREA = 'MANUTENCAO_AREA_ALTERAR_AREA';
export const MANUTENCAO_AREA_ALTERAR_ATUALIZA_AREA_X_MODELO = 'MANUTENCAO_AREA_ALTERAR_ATUALIZA_AREA_X_MODELO';
export const MANUTENCAO_AREA_ADD_AREA = 'MANUTENCAO_AREA_ADD_AREA';
//
const ROTAS = [
    '/area',
    '/area_x_modelo',
    '/modelo',
]
//
async function conexao(dispatch, fnConexao, rota, ACTION, _formData = null, fnFecharDrawer, desativaMsgSucesso ){
    dispatch(setAguardar(true));

    try {
        const resp = await fnConexao(rota, _formData);
        if(resp.status !== 200){
            ToastErro(ERRO_STATUS_NAO_200);
            return false;
        }
        //
        if(resp.data.erro){
            ToastErro(resp.data.erro);
            return false;
        }
        if(resp.data.sucesso && !desativaMsgSucesso){
            toast.dark(resp.data.sucesso, {
                type: 'success'
            });
        }
        // Tudo certo adiciona no objeto
        dispatch({
            type: ACTION,
            data: resp.data,
        });
        // Fecha o drawer em caso de sucesso
        if(fnFecharDrawer)  dispatch(fnFecharDrawer());
        
    } catch (error) {
        console.log(error);
        ToastErro(ERRO_NAO_ENVIADO);
    } finally {
        dispatch(setAguardar(false));
    }
}

// Inicializa a recuperacao dos dados 
export const manutencaoAreaInit = () => async dispatch =>{

    const fn = axios.get;
    const formData = new URLSearchParams();
    // Para obter as areas
    await conexao(dispatch, fn, ROTAS[0], MANUTENCAO_AREA_INIT_AREA, formData);
    // Para obter os modelos
    await conexao(dispatch, fn, ROTAS[2], MANUTENCAO_AREA_INIT_MODELOS, formData);
    // Para obter as areas X modelos
    await conexao(dispatch, fn, ROTAS[1], MANUTENCAO_AREA_INIT_AREA_X_MODELO, formData);
}
// Exibe o drawer
export const manutencaoAreaSetDrawer = obj=>({
    type: MANUTENCAO_AREA_SET_DRAWER,
    data: obj,
});
// Fechar o drawer
export const manutencaoAreaFecharDrawer = ()=> ({
    type: MANUTENCAO_AREA_FECHAR_DRAWER,
});
// Acao para editar uma area (situacao ou nome)
export const manutencaoAreaUpdArea = (obj, isAlterarSituacao) => dispatch =>{
    const fn = isAlterarSituacao ? axios.patch : axios.put;
    const formData = new FormData();
    formData.append('dados', JSON.stringify(obj));
    const fnFechar = null;
    const isOcultarMensagem = true;

    conexao(dispatch, fn, ROTAS[0], MANUTENCAO_AREA_ALTERAR_AREA, formData, fnFechar, isOcultarMensagem);

}
// Acao para ativar/desativar um modelo de uma area
export const manutencaoAreaAtivaDesativaModelo = (obj, isAtivo)=> dispatch =>{
    const fn = isAtivo ? axios.delete : axios.post;
    let formData = new FormData();
    formData.append('dados', JSON.stringify(obj));
    if(isAtivo){
        formData = {'data': formData};
    }

    conexao(dispatch, fn, ROTAS[1], MANUTENCAO_AREA_ALTERAR_ATUALIZA_AREA_X_MODELO, formData, null, true);
}
// Acao para adicionar uma nova area a planta
export const manutencaoAreaAddArea = (obj)=> dispatch => {
    const formData = new FormData();
    formData.append('dados', JSON.stringify(obj));
    
    conexao(dispatch, axios.post, ROTAS[0], MANUTENCAO_AREA_ADD_AREA, formData, manutencaoAreaFecharDrawer, true);
}