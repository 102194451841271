import { Grid, Paper } from "@mui/material";
import FormatarDados from "./estatisticas-format-data";
import {
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { RetornoEmBranco } from "../../../components";
import { ICONES } from "./estatisticas-utils";
import { useTranslation } from "react-i18next";

// Retorna  paineis de estatistica de acessos
const RenderGrafMobile = ({ verNumeros, titulo, data, coluna }) => {
  const { t } = useTranslation();
  // Extrai os dados que se deseja formatando-os
  const newData = !data
    ? null
    : data.length === 0
    ? data
    : FormatarDados(coluna, data);

  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={2} sx={{ p: 1 }}>
        <Stack
          sx={{ height: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          {!newData ? (
            <CircularProgress />
          ) : newData.length === 0 ? (
            <RetornoEmBranco />
          ) : (
            <Paper elevation={0} sx={{ height: "auto", width: "100%" }}>
              <Typography align="center" variant="h6">
                {titulo}
              </Typography>
              {Object.keys(newData).map((ele, idx) => (
                <Stack key={idx} sx={{ my: 2 }}>
                  <LinearProgress
                    variant="determinate"
                    value={Math.round((newData[ele] / data.length) * 100, 2)}
                  />
                  <Stack sx={{ mt: 1 }} direction="row" alignItems="center">
                    {ICONES.hasOwnProperty(ele) && (
                      <img
                        alt="ICONE"
                        src={ICONES[ele]}
                        width={24}
                        height={24}
                      />
                    )}
                    &nbsp;&nbsp;
                    <Typography variant="body2">
                      {ele} -{" "}
                      {verNumeros
                        ? `${newData[ele]} ${t("estatisticas.access")}`
                        : `${Math.round(
                            (newData[ele] / data.length) * 100,
                            2
                          )} %`}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Paper>
          )}
        </Stack>
      </Paper>
    </Grid>
  );
};

export default RenderGrafMobile;
