import React from "react";
import { ContainerAdaptavel } from "../../../extra-components";
import RnciFormSubida from "./rnci-form-subida";
import RnciFormQualidade from "./rnci-form-qualidade";
import RnciFiltroAvancado from "./rnci-filtro-avancado";
import RnciCancelStatus from "./rnci-cancel-status";

function RnciModal({ modal }) {
  let corpo;
  switch (modal.tipo) {
    case RnciModal.modal.FORM_SUBIDA_LINHA:
      corpo = <RnciFormSubida {...modal.dados} />;
      break;
    case RnciModal.modal.FORM_QUALIDADE:
      corpo = <RnciFormQualidade {...modal.dados} />;
      break;
    case RnciModal.modal.FILTRO_AVANCADO:
      corpo = <RnciFiltroAvancado {...modal.dados} />;
      break;
    case RnciModal.modal.RNCI_CANCEL_STATUS:
      corpo = <RnciCancelStatus {...modal.dados} />;
      break;
    default:
      break;
  }
  return (
    <ContainerAdaptavel sx={{ minHeight: "50vh" }}>{corpo}</ContainerAdaptavel>
  );
}

RnciModal.modal = {
  FORM_SUBIDA_LINHA: "FORM_SUBIDA_LINHA",
  FORM_QUALIDADE: "FORM_QUALIDADE",
  FILTRO_AVANCADO: "FILTRO_AVANCADO",
  RNCI_CANCEL_STATUS: "RNCI_CANCEL_STATUS",
};

export default RnciModal;
