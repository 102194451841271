import axios from "axios";
import { toast } from "react-toastify";
import { ToastErro } from "../../../utils/utils";

export const ROTAS = ["/oportunidades_questionario"];
//
export const escolaridade = (t) => {
  return [
    ["ANALFABETO", t("oportunidades.illiterate")],
    [
      "ATE 4ª SERIE INCOMPLETA (PRIMARIO INCOMPLETO)",
      t("oportunidades.incompletePrimary"),
    ],
    [
      "COM 4ª SERIE COMPLETA DO 1º GRAU (PRIMARIO COMPLETO)",
      t("oportunidades.completePrimary"),
    ],
    [
      "PRIMEIRO GRAU (GINASIO) INCOMPLETO",
      t("oportunidades.incompleteSecondary"),
    ],
    ["PRIMEIRO GRAU (GINASIO) COMPLETO", t("oportunidades.completeSecondary")],
    [
      "SEGUNDO GRAU (COLEGIAL) INCOMPLETO",
      t("oportunidades.incompleteHighSchool"),
    ],
    ["SEGUNDO GRAU (COLEGIAL) COMPLETO", t("oportunidades.completeHighSchool")],
    ["SUPERIOR INCOMPLETO", t("oportunidades.incompleteCollege")],
    ["SUPERIOR COMPLETO", t("oportunidades.completeCollege")],
    ["MESTRADO COMPLETO", t("oportunidades.completeMaster")],
    ["DOUTORADO COMPLETO", t("oportunidades.completeDoctorate")],
    ["POS-GRADUACAO/ESPECIALIZACAO", t("oportunidades.specialization")],
    ["POS-DOUTORADO", t("oportunidades.postDoctorate")],
  ];
};
// Obrem a questão a ser carregada para registrar-se no preenchimento da vaga
export const fetchQuestao = async (url) => {
  const resp = await axios.get(url);
  if (resp.status !== 200) throw Error("INTERNAL ERROR");

  if (resp.data.erro) throw resp.data.erro;

  return resp.data;
};

// Funcao de callback para baixar o PDF
export const baixarPDF = async (vaga, matricula, t) => {
  const ID = toast.dark(t("oportunidades.labelWaitGeneratePDF"), {
    type: "info",
    theme: "dark",
  });
  try {
    const resp = await axios.get(
      `/oportunidades_baixar_questionario_resposta/${vaga}/${matricula}`
    );
    toast.dismiss(ID);
    if (resp.status !== 200) {
      ToastErro(t("oportunidades.errorInternal"));
      return false;
    }
    //
    if (resp.data.hasOwnProperty("erro")) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Tudo certo cria o download
    const link = document.createElement("a");
    link.href = resp.data.data;
    link.target = "_blank";
    link.click();
  } catch (error) {
    ToastErro(t("oportunidades.errorUnknown"));
  }
};
