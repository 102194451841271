import {SET_OPT_TABELA, SET_DEFAULT_OPT_TABELA} from '../actions';

const INITAL_STATE = {
    classNameCabe: "success",
    classNameRodape: "success",
    tamanho: 370,
    style: {fontSize: '90%'},    
    fixaColuna: null,
    monetario: [],
    somar: [],
    datas: [],
    telefones: [],
    dataHora: [],
    objCalculaRodape: null,
    percentual: [],
    calculaRodape:true,
    baixar: '/baixar_em_excel',
    pesquisando: false
};

const optTabelaReducer = (state = INITAL_STATE, action)=>{
    switch (action.type) {
        case SET_OPT_TABELA: // Configurando objetos da tabela
            return {...state, ...action.obj }
        case SET_DEFAULT_OPT_TABELA: // Tabela indo ao padrão
            return INITAL_STATE;    
        default:
            return state;
    }
};

export default optTabelaReducer;