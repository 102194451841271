// Importando imagens para os icones do projeto
import LinuxSVG from "../../../images/linux.svg";
import AndroidSVG from "../../../images/android.svg";
import WindowsSVG from "../../../images/windows.svg";
import IphoneSVG from "../../../images/iphone.svg";
import ChromeSVG from "../../../images/chrome.svg";
import EdgeSVG from "../../../images/edge.svg";
import SafariSVG from "../../../images/safari.svg";
import FirefoxSVG from "../../../images/firefox.svg";

// Objeto que mapeia as imagens
export const ICONES = {
  Android: AndroidSVG,
  Linux: LinuxSVG,
  Windows: WindowsSVG,
  IPhone: IphoneSVG,
  Chrome: ChromeSVG,
  Safari: SafariSVG,
  Edge: EdgeSVG,
  Firefox: FirefoxSVG,
};
