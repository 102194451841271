// Centralização dos seletores usados no helpdesk
import _ from "lodash";
import { createSelector } from "reselect";

export const selectUsuario = (state) => state?.usuario?.usuario;
export const selectColaboradores = (state) => state?.helpdesk?.colaboradores;
export const selectSolicitante = (state) => state?.helpdesk?.solicitantes;
export const selectDados = (state) => state?.helpdesk;
export const selectModal = (state) => state?.helpdesk?.modal;
export const selectStatus = (state) => state?.helpdesk?.status;
export const selectAgentes = (state) => state?.helpdesk?.agentes;

export const selectIsAgente = (state) =>
  state?.usuario?.variaveis?.includes("HELPDESK_AGENTE");
//
export const selectIdPagina = (state) => state?.helpdesk?.idPagina;
//
export const selectAssuntos = createSelector(selectDados, (dados) => {
  const { assunto } = dados;
  return _.map(assunto, (val) => [val.id, val.descricao]);
});
//
export const selectStatusFormatado = createSelector(selectStatus, (status) => {
  return _.map(status, (val) => [val.id, val.descricao]);
});
export const selectCriticidade = createSelector(selectDados, (dados) => {
  const { criticidade } = dados;
  return _.map(criticidade, (val) => [val.id, val.descricao]);
});
//
export const selectColaboradoresFormatados = createSelector(
  selectColaboradores,
  (val) => {
    return _.map(val, (item) => [
      `${item.matricula}_${item.planta}`,
      item.nome,
    ]);
  }
);
//
export const selectAgentesFormatado = createSelector(
  selectAgentes,
  (agentes) => {
    return _.map(agentes, (val) => [
      `${val.matricula}_${val.planta}`,
      `${val.planta} - ${val.nome}`,
    ]);
  }
);

export const selectSubjects = (state) => state?.helpdesk?.helpdesk_assunto;
export const selectManuStatus = (state) => state?.helpdesk?.helpdesk_status;

export const selectIsSuperAgente = (state) =>
  state?.usuario?.variaveis?.includes("HELPDESK_SUPER_AGENTE");

export const selectCrticality = (state) =>
  state?.helpdesk?.helpdesk_criticidade;
