// Acoes para a tabela, configuracoes, cores e parametros alterados para refletir em
// todas as tabelas
export const SET_OPT_TABELA = 'SET_OPT_TABELA';
export const SET_DEFAULT_OPT_TABELA = 'SET_DEFAULT_OPT_TABELA';


export const setOptTabela = (obj)=>({
    type: SET_OPT_TABELA,
    obj
});

export const setDefaultOptTabela = ()=>({
    type: SET_DEFAULT_OPT_TABELA
})