import { createSelector } from "reselect";

// Seletores
export const selectDados = (state) => state.twttp?.dados;
export const selectIsFiltroAvancado = (state) => state.twttp?.isFiltroAvancado;
export const selectItens = (state) => state.twttp?.itens;
export const selectItensFiltros = (state) => state.twttp?.filtro; // Itens que foram filtrados no servidor
export const selectDrawer = (state) => state.twttp?.drawer;
export const selectAguardar = (state) => state.aguardar;
export const selectQtdPagina = (state) => state.twttp?.dados?.totalDePaginas;
export const selectPaginaAtual = (state) => state.twttp?.pagina;
export const selectFormEntrevista = (state) => state.twttp?.formEntrevista;
export const selectPlantaUsuario = (state) => state?.usuario?.usuario?.planta;
export const selectIsSuperProduction = (state) =>
  state?.usuario?.variaveis.includes("TWTTP_SUPERVISOR_PRODUCAO");
// Selector para obter o tamanho dos campos usados neste relatorio
export const selectorTamanhoCampos = (state) => state?.tamanhoCampos;
export const fnSeletorTamanho = createSelector(selectorTamanhoCampos, (obj) => {
  const USADOS = {};
  Object.keys(obj).forEach((key) => {
    if (key === "twttp") {
      USADOS["addFormTwttp"] = obj[key].detalhe; // Pegando total do campo descricao da tabela projetos
    }
  });
  return USADOS;
});
// Seletor para contagem dos status
export const seletorTotalPorStatus = createSelector(selectDados, (obj) => {
  return obj.totalPorStatus;
});
// Seletor para colocar mais um item nos cartoes
export const selectItensComCor = createSelector(
  selectItens,
  selectDados,
  (itens, dados) => {
    if (dados && itens) {
      const { twttp_tipos_x_cores } = dados;
      // Retornar os itens
      return itens?.map((ele) => {
        ele.cores = twttp_tipos_x_cores[ele.tipo_twttp];
        return ele;
      });
    } else {
      return null;
    }
  }
);
// Seletor de cores para os itens do filtro
export const selectItensFiltroComCor = createSelector(
  selectItensFiltros,
  selectDados,
  (itens, dados) => {
    if (dados && itens) {
      const { twttp_tipos_x_cores } = dados;
      // Retornar os itens
      return itens?.map((ele) => {
        ele.cores = twttp_tipos_x_cores[ele.tipo_twttp];
        return ele;
      });
    } else {
      return null;
    }
  }
);
