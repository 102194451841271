import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import {
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { galeriaDeFotosEditLegendaFoto } from "./galeria-de-fotos-actions";
import { useMediaQuery, useTheme } from "@mui/material";

// Componente para exibir o campo para edicao da legenda da foto
const FormUpdLegendaFoto = ({ id_foto, legenda }) => {
  //
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aguardar = useSelector((state) => state?.aguardar);

  // Schema para validar os dados enviados no formulario
  const schema = yup.object().shape({
    legenda: yup.string().min(3).required(),
  });
  //
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      legenda: legenda,
    },
  });
  // Funcao para enviar os dados para edicao da legenda
  const fn = (val) => {
    dispatch(
      galeriaDeFotosEditLegendaFoto({
        id_foto,
        legenda: val.legenda,
      })
    );
  };
  //
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  return (
    <Container sx={{ p: 1, maxHeight: "85vh" }} maxWidth="md">
      <Stack spacing={1}>
        <Typography variant="h6" align="center">
          {t("galeria_de_fotos.titleFormUpdLegend")}
        </Typography>
        <Typography variant="caption" sx={{ opacity: 0.7 }} align="center">
          {t("galeria_de_fotos.writeNewLegendPhoto")}
        </Typography>

        <Controller
          name="legenda"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              type="text"
              {...field}
              size="small"
              label={t("galeria_de_fotos.labelFormLegend")}
              placeholder={t("galeria_de_fotos.placeholderFormLegend")}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.legenda}
              helperText={!!errors.legenda && t("galeria_de_fotos.errorLegend")}
            />
          )}
        />

        <Stack direction="row" justifyContent={{ md: "center", xs: "stretch" }}>
          <Button
            disabled={aguardar}
            size="small"
            variant="contained"
            onClick={handleSubmit(fn)}
            fullWidth={isMobile}
            startIcon={
              aguardar ? (
                <CircularProgress sx={{ color: "white" }} size={20} />
              ) : (
                <SaveIcon />
              )
            }
          >
            {t("galeria_de_fotos.labelBtnSendLegend")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default FormUpdLegendaFoto;
