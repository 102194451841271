import React from "react";
import VerProjeto from "./kaizen-view-project";
import FormAddProjeto from "./kaizen-add-project";
import VerHistoricoProjeto from "./kaizen-view-history-project";

import FormularioAlteracaoStatus from "./kaizen-form-upd-status";
import FormAddJustificativa from "./kaizen-add-justify";
import VerHistoricoJustificativa from "./kaizen-view-hist-justify";
import FormularioChecklist from "./kaizen-form-checklist";
import AprovarReprovarChecklist from "./kaizen-accept-reject-checklist";
import VerHistoricoChecklistProjeto from "./kaizen-view-hist-checklist-project";
import FormSavingProjeto from "./kaizen-form-saving-project";
import ComponenteParaFiltroAvancado from "./kaizen-advanced-filter";
import ComponenteAlteracaoMembros from "./kaizen-comp-alter-member";
import { useSelector } from "react-redux";
import { ContainerAdaptavel } from "../../../extra-components";
import DadosFiltroAvancado from "./kaizen-filter-advanced";

const seletorProjects = (state) => state.cadastroProjetos;

const KaizenModal = ({ drawer, fecharDrawer }) => {
  const cadastroProjetos = useSelector(seletorProjects);

  // Montando pilar da lista de projetos
  const objListaDeProjetos = cadastroProjetos?.filtro
    ? cadastroProjetos.filtro
    : cadastroProjetos?.filtroAvancado
    ? cadastroProjetos.filtroAvancado
    : cadastroProjetos;

  let conteudoDrawerDialog;
  const valor = drawer?.valor;
  switch (drawer.tipo) {
    case KaizenModal.modal.VER_DETALHES: // Detalhes do projeto
      conteudoDrawerDialog = (
        <VerProjeto
          fecharVisualizacao={fecharDrawer}
          projeto={
            objListaDeProjetos?.projetos.filter(
              (ele) => String(ele.id) === String(valor)
            )[0]
          }
          anexos={objListaDeProjetos?.projeto_anexo[valor]}
          membros={objListaDeProjetos?.projeto_membros[valor]}
          modelos={objListaDeProjetos?.projeto_modelo[valor]}
        />
      );
      break;
    case KaizenModal.modal.ADD_FORM: // Intencao de adicionar um porjeto
      conteudoDrawerDialog = (
        <FormAddProjeto
          dadosForm={cadastroProjetos?.dadosForm}
          fecharForm={fecharDrawer}
        />
      );
      break;
    case KaizenModal.modal.VER_HISTORICO: // Ver o historico do projeto
      conteudoDrawerDialog = <VerHistoricoProjeto verHistorico={valor} />;
      break;
    case KaizenModal.modal.ALTERAR_STATUS: // Para ver o formulario de alteracao de status
      conteudoDrawerDialog = <FormularioAlteracaoStatus acao={valor} />;
      break;
    case KaizenModal.modal.DEFINR_JUSTIFICATIVA_ATRASO: // Para a definicao de uma justificativa de atraso
      conteudoDrawerDialog = (
        <FormAddJustificativa
          id={valor}
          fatores_atrasos={cadastroProjetos?.dadosForm?.fatores_atrasos}
          fatores_atrasos_x_acoes={
            cadastroProjetos?.dadosForm?.fatores_atrasos_x_acoes
          }
        />
      );
      break;
    case KaizenModal.modal.VER_JUSTIFICATIVA_ATRASO: // Para ver a definicao de uma justificativa de atraso
      conteudoDrawerDialog = (
        <VerHistoricoJustificativa
          listaHistorico={objListaDeProjetos?.justificativa_x_projeto[valor]}
        />
      );
      break;
    case KaizenModal.modal.RESPONDER_CHECKLIST: // Quando ha checklist a responder. Chama o primeiro passo que é a exibição das perguntas
      conteudoDrawerDialog = <FormularioChecklist checklist={valor} />;
      break;
    case KaizenModal.modal.CHECKLIST_RESPONDIDO: // O checklist foi respondido, devemos exibir a nota e definr se confirma ou nao
      conteudoDrawerDialog = <AprovarReprovarChecklist dados={valor} />;
      break;
    case KaizenModal.modal.VER_CHECKLIST_RESPONDIDO: // Para ver as respostas dos checklists que foram respondidos
      conteudoDrawerDialog = <VerHistoricoChecklistProjeto lista={valor} />;
      break;
    case KaizenModal.modal.FORM_SAVING: // Para exibir o formulario de saving do projeto
      conteudoDrawerDialog = (
        <FormSavingProjeto
          saving={valor}
          dadosForm={cadastroProjetos.dadosForm}
        />
      );
      break;
    case KaizenModal.modal.FILTRO_AVANCADO: // Para exibir o componente de filtro avancado
      conteudoDrawerDialog = <ComponenteParaFiltroAvancado />;
      break;
    case KaizenModal.modal.ALTERAR_MEMBROS: // Para chamar formulario de alteracao de membros
      conteudoDrawerDialog = <ComponenteAlteracaoMembros {...valor} />;
      break;
    case KaizenModal.modal.VER_FILTRO_AVANCADO_APLICADO: // Para ver o filtro avancado aplicado
      conteudoDrawerDialog = <DadosFiltroAvancado />;
      break;
    default:
      break;
  }
  return KaizenModal.modal.VER_FILTRO_AVANCADO_APLICADO ? (
    conteudoDrawerDialog
  ) : (
    <ContainerAdaptavel sx={{ minHeight: "50vh" }}>
      {conteudoDrawerDialog}
    </ContainerAdaptavel>
  );
};
KaizenModal.modal = {
  VER_DETALHES: "VER_DETALHES", // Para exibir os detalhes
  VER_FORM: "VER_FORM", // Para ver o formulario
  VER_HISTORICO: "VER_HISTORICO", // Para ver o historico
  ALTERAR_STATUS: "ALTERAR_STATUS", // Para alteração do status de um projeto
  DEFINR_JUSTIFICATIVA_ATRASO: "DEFINR_JUSTIFICATIVA_ATRASO", // Para definir a justificativa de atraso
  VER_JUSTIFICATIVA_ATRASO: "VER_JUSTIFICATIVA_ATRASO", // Para visualizar a justificativa de atraso
  RESPONDER_CHECKLIST: "RESPONDER_CHECKLIST", // Para solicitar as pergunts do checklist
  CHECKLIST_RESPONDIDO: "CHECKLIST_RESPONDIDO", // Para quando o checklist é respondido
  VER_CHECKLIST_RESPONDIDO: "VER_CHECKLIST_RESPONDIDO", // Para ver o checklist respondido
  FORM_SAVING: "FORM_SAVING", // Para ver o formulario de saving do projeto
  FILTRO_AVANCADO: "FILTRO_AVANCADO", // Para ver o componente de filtro avançado
  ALTERAR_MEMBROS: "ALTERAR_MEMBROS", // Para fazer a alteracao do drawer
  VER_FILTRO_AVANCADO_APLICADO: "VER_FILTRO_AVANCADO_APLICADO", // Para ver o filtro avancado aplicado
};

export default KaizenModal;
