import React from 'react'
import styled from 'styled-components';
import { Box, Paper, Typography,  Button } from '@mui/material';
//
import { useDispatch } from 'react-redux';
import { areaDoGestorObterQuestionario } from '../redux/actions';

// // Aplicando estilização ao Paper que armazena o conteudo de cada colaborador
// const PaperStyle = withStyles({
//     root: {
//         minHeight: '200px',
//         //background: '#005387',
//         padding: '12px',
//     }
// })(Paper);
// // Aplicando estilização ao texto
// const TypographyStyle = withStyles({
//     root: {
//         color: '#005387',
//         margin: '8px auto',
//     }
// })(Typography);
// Estilizacao de uma imagem
const Img = styled.img`
    padding: 4px;
    border-radius: 100%;
    box-shadow: 1px 0 2px rgba(0,0,0,.2);
`


const CardColaborador = ({ foto, colaborador, matricula, cargo, avaliacoes })=>{

    const dispatch = useDispatch();
    
    const fn = avaliacoes.length > 0 ? (aval)=> dispatch(areaDoGestorObterQuestionario(aval, matricula, colaborador)) : ()=> {};
    
    return (
        <Paper elevation={8} sx={{  minHeight: '200px', p: 1.5 }}>
            <Box align='center'>
                <Img src={foto} alt={`FOTO ${colaborador}`} />
            </Box>
            <Typography sx={{color: '#005387', my: 1}} align="center" variant='subtitle2'>{colaborador}</Typography>
            <Typography sx={{color: '#005387', my: 1}} align="center" variant='subtitle2'>{matricula}</Typography>
            <Typography sx={{color: '#005387', my: 1}} align="center" variant='subtitle2'>{cargo}</Typography>
            <Typography sx={{color: '#005387', my: 1}} align="center" variant="body2">STATUS: {avaliacoes.length > 0 ? 'Pendente' : 'OK'} </Typography>
            {avaliacoes.map((ele,idx)=>(
                <Box key={idx} margin={1}>
                    <Button fullWidth color="primary" 
                        variant='contained' onClick={()=> fn(ele)}                
                    > INICIAR AVALIAÇÃO {ele}
                    </Button>
                </Box>
            ))}

        </Paper>
    )
}

export default CardColaborador;