import React, { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../../hooks";
import {
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import MaskedInput from "react-text-mask";
import {
  AnimacaoSemDados,
  H5,
  H6,
  Icone,
  Pesquisando,
  ScrollInfinito,
} from "../../../components";
import { ContainerAdaptavel } from "../../../extra-components";
import { grey } from "@mui/material/colors";
import { InteracaoItem } from "./ouvidoria-detalhes";
import { ToastErro } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

function OuvidoriaBuscaPorToken() {
  const isMobile = useTheme()?.isMobile;
  return (
    <ContainerAdaptavel
      sx={{
        backgroundColor: ({ palette }) => palette.mode !== "dark" && grey[200],
        minHeight: isMobile ? "100vh" : "50vh",
      }}
    >
      <Corpo />
    </ContainerAdaptavel>
  );
}
OuvidoriaBuscaPorToken.rota = "/ouvidoria_busca_por_token";
//
const Corpo = () => {
  const { t } = useTranslation();
  const { data, wait, setFetch, error } = useFetch("/ouvidoria", "GET");
  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);

  return (
    <Stack>
      <H6>{t("ouvidoria_view.titleSearchToken")}</H6>
      <br />
      <CampoBusca aguardar={wait} buscar={setFetch} />
      {wait ? (
        <Pesquisando />
      ) : data && data?.historico?.length > 0 ? (
        <VerDados dados={data} />
      ) : (
        <AnimacaoSemDados titulo={t("ouvidoria_view.noDataInteraction")} />
      )}
    </Stack>
  );
};
//
const VerDados = ({ dados }) => {
  return (
    <>
      <br />
      <H5>{dados?.assunto}</H5>
      <br />
      <ScrollInfinito
        itens={dados?.historico}
        render={(ele, idx) => (
          <InteracaoItem {...ele} isExpandido={idx === 0} />
        )}
        itensPorPagina={3}
      />
    </>
  );
};
//
const CampoBusca = ({ aguardar, buscar }) => {
  const { t } = useTranslation();
  const backgroundForm = useTheme().palette.background.paper;
  const [valor, setValor] = useState("");
  const mask = [
    /./,
    /./,
    /./,
    /./,
    /./,
    /./,
    /./,
    /./,
    "-",
    /./,
    /./,
    /./,
    /./,
    "-",
    /4/,
    /./,
    /./,
    /./,
    "-",
    /./,
    /./,
    /./,
    /./,
    "-",
    /./,
    /./,
    /./,
    /./,
    /./,
    /./,
    /./,
    /./,
    /./,
    /./,
    /./,
    /./,
  ];

  const onBuscarOuvidoria = useCallback(
    (e) => {
      const inValor = e.target.value;
      setValor(inValor);

      if (inValor.length === 36) {
        buscar({ token: inValor });
      }
    },
    [buscar, setValor]
  );

  return (
    <Stack sx={{ width: "100%", overflow: "auto" }}>
      <MaskedInput
        value={valor}
        mask={mask}
        guide={false}
        onChange={onBuscarOuvidoria}
        render={(ref, props) => (
          <TextField
            {...props}
            inputRef={ref}
            variant="standard"
            InputProps={{
              endAdornment: aguardar ? (
                <CircularProgress size={24} />
              ) : (
                <IconButton onClick={() => setValor("")}>
                  <Icone icone="Close" />
                </IconButton>
              ),
              disableUnderline: true,
            }}
            inputProps={{
              autoComplete: "off",
              style: {
                fontSize: "36px",
                textAlign: "center",
                borderRadius: "8px",
                backgroundColor: backgroundForm,
              },
            }}
            placeholder={t("ouvidoria_view.placeholderSearchToken")}
            disabled={aguardar}
          />
        )}
      />
    </Stack>
  );
};

export default OuvidoriaBuscaPorToken;
