import {
    INFO_TAMANHO_CAMPO
} from '../actions';

export default function tamanhoCamposReducer(state = {}, action){

    switch(action.type){
        case INFO_TAMANHO_CAMPO:
            return action.data;
        default:
            return state;
    }
}