import { createSelector } from "reselect";
import _ from "lodash";

export const selectAguardar = (state) => state?.aguardar;
export const selectPlanta = (state) => state?.usuario?.usuario?.planta;
export const selectPlantas = (state) => state?.colaboradores?.plantas;
export const selectCargos = (state) => state?.colaboradores?.cargos;

export const selectColaboradores = (state) => state?.colaboradores?.corpo;
export const selectDrawer = (state) => state?.colaboradores?.drawer;
export const selectArvores = (state) => state?.colaboradores?.arvore;
export const selectColaboradorXMenu = (state) =>
  state?.colaboradores?.colaborador_x_menu;
const selectMenu = (state) => state?.colaboradores.menu;
// Cria o seletor para filtrar todos os menus do usuario e todos os disponiveis
export const selectMenuXMenuColaborador = createSelector(
  selectDrawer,
  selectColaboradorXMenu,
  selectMenu,
  (drawer, colaboradorXMenu, menus) => {
    const { dados } = drawer;
    // Obtem a lista de ids dos menus que o usuario tem acesso
    const menusUsuario = colaboradorXMenu?.hasOwnProperty(dados)
      ? Object.keys(colaboradorXMenu[dados])
      : [];
    // Retorna a lista de menus do usuario e a lista de menus que podem ser selecionados
    const menusDisponiveis = Object.keys(menus).map((k) => ({
      id_menu: k,
      nome: menus[k],
    }));

    return {
      menusUsuario,
      menusDisponiveis,
      matricula: dados,
    };
  }
);
// Cria um seletor para saber se o usuario esta ativo e se o mesmo e gestor
export const selectAtivoGestor = createSelector(
  selectDrawer,
  selectColaboradores,
  (drawer, colaboradores) => {
    const { dados } = drawer;
    const _colab = _.filter(
      colaboradores,
      (val) => String(val.id) === String(dados)
    )[0].data;
    // Retorna a matricula e se o colaborador e gestor ou esta ativo
    return {
      matricula: dados,
      isAtivo: _colab[7] === "A" ? true : false,
      isGestor: _colab[8] === "S" ? true : false,
      isElegivel: _colab[9] === "S" ? true : false,
    };
  }
);
//
export const selectColaboradorUnico = createSelector(
  selectDrawer,
  selectColaboradores,
  selectArvores,
  (drawer, colaboradores, arvores) => {
    const { dados } = drawer;
    const _colab = _.filter(
      colaboradores,
      (val) => String(val.id) === String(dados)
    )[0].data;
    // Retorna a matricula e se o colaborador e gestor ou esta ativo
    return {
      matricula: dados,
      nome: _colab[1],
      cargo: _colab[4],
      email: _colab[2],
      arvore: _.filter(arvores, (val) => val[1] === _colab[5]), // ID da arvore
    };
  }
);
