import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { EntradaForm } from "../../../components";
import * as yup from "yup";
import { trainningCouserAddOrEdit } from "./treinamento-actions";
import { useTranslation } from "react-i18next";

const trainningSchemaNames = {
  id: "id",
  description: "descricao",
  duration: "duracao",
  model: "modelo",
};

function TreinamentoAddUpdCurso({ id, description, model, duration }) {
  const dispatch = useDispatch();
  const [wait, setWait] = useToggle();
  const { t } = useTranslation();
  //
  const schema = [
    {
      type: "text",
      name: trainningSchemaNames.description,
      label: t("treinamento.labelDescription"),
      defaultValue: description,
      placeholder: t("treinamento.placeholderDescription"),
      required: true,
    },
    {
      type: "time",
      name: trainningSchemaNames.duration,
      label: t("treinamento.labelDuration"),
      defaultValue: duration,
      placeholder: t("treinamento.placeholderDuration"),
      required: true,
    },
    {
      type: "number",
      name: trainningSchemaNames.model,
      label: t("treinamento.labelModel"),
      defaultValue: model,
      placeholder: t("treinamento.placeholderModel"),
    },
  ];

  const schemaMessageError = {
    [trainningSchemaNames.description]: t("treinamento.errorDescription"),
    [trainningSchemaNames.duration]: t("treinamento.errorDuration"),
  };

  const schemaValidator = yup.object().shape({
    [trainningSchemaNames.description]: yup.string().min(1).max(150).required(),
    [trainningSchemaNames.duration]: yup
      .string()
      .matches(/^([01]\d|2[0-3]):([0-5]\d)$/)
      .required(),
  });

  const fnCallback = useCallback(
    (val) => {
      const obj = {
        [trainningSchemaNames.description]:
          val[trainningSchemaNames.description],
        [trainningSchemaNames.duration]: val[trainningSchemaNames.duration],
        [trainningSchemaNames.model]: val[trainningSchemaNames.model],
      };

      if (id) {
        obj[trainningSchemaNames.id] = id;
      }

      dispatch(trainningCouserAddOrEdit(obj, setWait));
    },
    [dispatch, setWait, id]
  );

  return (
    <EntradaForm
      schema={schema}
      schemaMessageError={schemaMessageError}
      schemaValidator={schemaValidator}
      onSubmit={fnCallback}
      wait={wait}
    />
  );
}

export default TreinamentoAddUpdCurso;
