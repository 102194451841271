import {
  ESTATISTICAS_LIMPAR_DADOS,
  ESTATISTICAS_DEFINIR_DADOS,
} from "./estatisticas-actions";

const estatisticasReducer = (state = null, action) => {
  switch (action.type) {
    case ESTATISTICAS_LIMPAR_DADOS: // Para limpar os dados
      return null;
    case ESTATISTICAS_DEFINIR_DADOS: // Para definir os dados
      return action.data;
    default:
      return state;
  }
};

export default estatisticasReducer;
