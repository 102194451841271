import { Avatar, Chip, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
//
const DetalhesCabecalhoArquivo = ({ visivel_por, nome, avatar }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack gap={0.5} direction="row">
        {visivel_por.map((planta) => (
          <Chip
            sx={{
              borderBottomLeftRadius: 32,
              borderTopRightRadius: 32,
            }}
            title={t("documentos.detailsHeaderFileVisibleForPlant", { planta })}
            size="small"
            key={planta}
            label={planta}
            color="primary"
            variant="outlined"
          />
        ))}
      </Stack>
      <Avatar
        sx={{ height: 24, width: 24 }}
        title={t("documentos.detailsHeaderFileOwner", { nome })}
        alt={nome}
        src={avatar}
      />
    </Stack>
  );
};

export default DetalhesCabecalhoArquivo;
