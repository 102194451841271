import React, { useCallback, useEffect } from "react";
import BackgroundRouter from "../../background-router";
import { useSelector, useDispatch } from "react-redux";

import { lessonInit, lessonClearData, lessonSetPage } from "./lesson_action";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import LessonForm from "./lesson_form";
import {
  selectLessonPage,
  selectLessonTotalPage,
  seletorItems,
} from "./lesson_selectors";
import LessonView from "./lesson_view";
import RowPaginateTemplate from "../../../templates/row_paginate_template";
import { Body2, H4, Logo } from "../../../components";
import { Chip, Paper, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

function Lesson() {
  const dispatch = useDispatch();
  const history = useHistory();
  const items = useSelector(seletorItems);
  const totalPage = useSelector(selectLessonTotalPage);
  const page = useSelector(selectLessonPage);
  const { t } = useTranslation();

  useEffect(() => {
    if (!page) {
      dispatch(lessonInit(() => {}));
    }
    return () => {
      if (history.action === "POP") {
        dispatch(lessonClearData());
      }
    };
  }, [history, page, dispatch]);
  // Funcao para alteração entre as paginas
  const onSetPage = useCallback(
    (idPage) => {
      dispatch(lessonSetPage(idPage, () => {}));
    },
    [dispatch]
  );
  // Funcao para ir a pagina do formulario para fazer o cadastro
  const onAddForm = useCallback(() => {
    history.push(LessonForm.rota);
  }, [history]);
  // Funcao para ir até a pagina de visualizacao das licoes
  const onViewLesson = useCallback(
    (id) => {
      history.push(`${LessonView.rota}?id=${id}`);
    },
    [history]
  );
  // Passa sobre os itens e implementa a funcao de callback
  _.forEach(items, (val) => {
    val.onClick = () => onViewLesson(val.codigo);
  });
  //
  const header = [
    t("lesson.nameAndEmail"),
    t("lesson.dateCreated"),
    t("lesson.plant"),
    t("lesson.pn"),
    t("lesson.descriptionPiece"),
    t("lesson.categoryAnomaly"),
    t("lesson.anomalyFound"),
    t("lesson.identifyLL"),
  ];
  const mapKeyToTitle = {
    [t("lesson.nameAndEmail")]: ["nome", "email"],
    [t("lesson.dateCreated")]: "data",
    [t("lesson.plant")]: "planta",
    [t("lesson.pn")]: "pn",
    [t("lesson.descriptionPiece")]: "pn_descricao",
    [t("lesson.categoryAnomaly")]: "unidadeprocesso_descricao",
    [t("lesson.anomalyFound")]: "descricao_anomalia",
    [t("lesson.identifyLL")]: "codigo",
  };

  const rows = _.map(items, (val) => {
    const row = {
      onClick: () => onViewLesson(val.codigo),
    };
    _.forEach(header, (k) => {
      // Quando codigo aplica o chip no campo
      if (k === t("lesson.identifyLL")) {
        row[k] = <Chip color="info" label={val[mapKeyToTitle[k]]} />;
      } else if (mapKeyToTitle[k].length === 0) {
        row[k] = "";
      } else if (Array.isArray(mapKeyToTitle[k])) {
        row[k] = `${mapKeyToTitle[k]
          .map((e, i) => val[mapKeyToTitle[k][i]])
          .join("\n")}`;
      } else {
        row[k] = val[mapKeyToTitle[k]];
      }
    });

    return row;
  });

  return (
    <BackgroundRouter>
      <RowPaginateTemplate
        pageCurrent={page}
        totalPages={totalPage}
        onSetPage={onSetPage}
        titlePage=""
        onClickAdd={onAddForm}
        headerAboveTable={<HeaderTable />}
        titleButtonAdd="Incluir"
        header={header}
        sxHeader={{
          background: ({ palette }) =>
            palette.mode === "dark" ? palette.background.paper : "#d9d9d9",
          color: ({ palette }) =>
            palette.mode === "dark"
              ? palette.background.paper.contrastText
              : "black",
        }}
        rows={rows}
      />
    </BackgroundRouter>
  );
}
//
const HeaderTable = () => {
  const isMobile = useTheme()?.isMobile;
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="stretch">
      {!isMobile && (
        <Paper
          elevation={0}
          sx={{
            borderRadius: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Logo />
        </Paper>
      )}
      <Paper
        elevation={1}
        sx={{
          flex: 1,
          p: 1,
          color: "white",
          background: "#0f2a5f",
          borderRadius: 0,
        }}
      >
        {isMobile ? (
          <Body2 align="center">{t("lesson.titleHeaderTable")}</Body2>
        ) : (
          <H4 align="center">{t("lesson.titleHeaderTable")}</H4>
        )}
      </Paper>
    </Stack>
  );
};

Lesson.rota = "/lesson";

export default Lesson;
