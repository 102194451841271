import useSWR from "swr";
import { ToastErro } from "../../../utils/utils";
import { CircularProgress, Stack } from "@mui/material";
import { ROTAS, fetchQuestao } from "./oportunidades-utils";
import FormRegistroAVaga from "./oportunidades-form-reg-vaga";

// Questionario para ser respondido na condicao de se candidatar a vaga
const QuestionarioCandidato = ({ setToggle, fecharModal, vaga }) => {
  const { data, error } = useSWR(`${ROTAS[0]}?vaga=${vaga}`, fetchQuestao);
  // Veja se tem erro ou se os dados foram carregados.
  if (error || data) {
    if (error) {
      ToastErro(error);
      setToggle();
    }
  }
  return (
    <>
      {!data ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : data ? (
        <FormRegistroAVaga
          perguntas={data.perguntas}
          vaga={vaga}
          id_questao={data.id_questao}
          fecharModal={fecharModal}
        />
      ) : null}
    </>
  );
};

export default QuestionarioCandidato;
