import {
  Box,
  Button,
  CircularProgress,
  Container,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { galeriaDeFotosUploadFotos } from "./galeria-de-fotos-actions";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const IMAGENS = ["jpg", "jpeg", "png", "gif", "webp"];

// Componente que exibe o formulario para insercao de uma foto
const FormAddFoto = ({ id, nome }) => {
  const dispatch = useDispatch();
  const aguardar = useSelector((state) => state?.aguardar);
  const { t } = useTranslation();

  const [arquivos, setArquivos] = useState([]);
  const [texto, setTexto] = useState(null);

  const fn = (e) => {
    setArquivos(e.target.files);
  };
  //
  const fnEnviar = () => {
    // Veja a extensao do tipo do anexo
    let erro = false;
    [...arquivos].forEach((ele) => {
      const ext = ele.name.split(".").pop();
      if (!IMAGENS.includes(ext?.toLowerCase())) {
        toast.dark(
          `${t("galeria_de_fotos.thisFile")} ${ele.name} ${t(
            "galeria_de_fotos.notAccept"
          )}`,
          {
            type: "error",
            toastId: "erro_envio_arquivo",
          }
        );
        erro = true;
        return false;
      }
    });
    if (erro) {
      return false;
    }
    // Tudo certo vamos enviar os dados
    dispatch(
      galeriaDeFotosUploadFotos(
        {
          id_album: id,
          arquivos,
        },
        setTexto
      )
    );
  };

  return (
    <Container maxWidth="md" sx={{ p: 1 }}>
      <Typography variant="h6" align="center">
        {nome}
      </Typography>
      <Typography variant="caption" align="center" sx={{ opacity: 0.7 }}>
        {t("galeria_de_fotos.labelFormFilesPhoto")}
      </Typography>
      <Stack spacing={1}>
        <TextField
          type="file"
          size="small"
          onChange={fn}
          inputProps={{ multiple: true }}
          InputLabelProps={{ shrink: true }}
          label={t("galeria_de_fotos.labelFormFile")}
          fullWidth
        />
        <Button
          disabled={aguardar}
          startIcon={
            aguardar ? (
              <CircularProgress sx={{ color: "white" }} size={20} />
            ) : (
              <AddAPhotoIcon />
            )
          }
          onClick={fnEnviar}
          variant="contained"
        >
          {t("galeria_de_fotos.labelBtnSendPhoto")}
        </Button>
        {!!texto && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "98%", mr: 0.5 }}>
              <LinearProgress variant="determinate" value={texto} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography
                variant="body2"
                color="text.secondary"
              >{`${texto} %`}</Typography>
            </Box>
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default FormAddFoto;
