import { toast } from "react-toastify";
import fetchRedux from "../../../api/fetch_redux";
import { ToastErro } from "../../../utils/utils";

export const LESSON_INIT = "LESSON_INIT";
export const LESSON_PAGE = "LESSON_PAGE";
export const LESSON_CLEAR_DATA = "LESSON_CLEAR_DATA";
export const LESSON_SET_MODAL = "LESSON_SET_MODAL";
export const LESSON_FECHAR_MODAL = "LESSON_FECHAR_MODAL";
export const LESSON_ADD = "LESSON_ADD";
//
export const ROTAS = ["/lesson_api"];

export const lessonInit = (setAguardar) => (dispatch) => {
  // A listagem de dados para construir o chamado
  fetchRedux(
    `${ROTAS[0]}/dados`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: LESSON_INIT,
        data: resposta,
      });
    },
    () => {},
    (err) => ToastErro(err)
  );
  // Recupera os dados da primeira pagina
  dispatch(lessonSetPage(1, setAguardar));
};

// Salta entre as paginas
export const lessonSetPage = (idPagina, setAguardar) => (dispatch) => {
  // Passa sobre as paginas
  fetchRedux(
    `${ROTAS[0]}/pagina/${idPagina}`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: LESSON_PAGE,
        data: {
          idPage: idPagina,
          data: resposta,
        },
      });
    },
    setAguardar,
    (err) => ToastErro(err)
  );
};

// Limpar os dados para sair
export const lessonClearData = () => ({
  type: LESSON_CLEAR_DATA,
});
// Ativar modal
export const lessonSetModal = (obj) => ({
  type: LESSON_SET_MODAL,
  data: obj,
});
// FecharModal
export const lessonFecharModal = () => ({
  type: LESSON_FECHAR_MODAL,
});
//
export const lessonAdd = (objData, setWait, fnSuccess) => (dispatch) => {
  // Passa sobre as paginas
  fetchRedux(
    ROTAS[0],
    "POST",
    objData,
    (resposta) => {
      toast.dark("Lição criada com sucesso", {
        type: "success",
      });
      // Executa funcao de suceso
      if (fnSuccess) {
        fnSuccess();
      }

      dispatch({
        type: LESSON_ADD,
        data: resposta,
      });
    },
    setWait,
    (err) => ToastErro(err)
  );
};
