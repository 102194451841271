import {
  Avatar,
  ButtonBase,
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import axios from "axios";
import React, { useState, memo } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import Highlighter from "react-highlight-words";
import {
  AnimacaoSemDados,
  Body2,
  Caption,
  Icone,
  InputBusca,
} from "../../../components";
import BackgroundRouter from "../../background-router";
import { lightBlue } from "@mui/material/colors";
import QuestionarioComportamental from "./minha-equipe-questionario";
import PontoEletronico from "./minha-equipe-ponto-eletronico";
import BancoDeHorasGeral from "./minha-equipe-banco-de-horas-geral";
import BancoDeHoras from "./minha-equipe-banco-de-horas";
import { useTranslation } from "react-i18next";
// Seletores
const selectUsuarioPlanta = (state) => state?.usuario?.usuario?.planta;

// Fetch para recuperar usuarios da equipe
const fetchMinhaEquipe = async (url) => {
  const resp = await axios.post(url);
  if (resp.status !== 200) {
    throw new Error("Erro interno do servidor");
  }
  if (resp.data.erro) {
    throw new Error(resp.data.erro);
  }
  // Tudo certo retorne os dados
  return resp.data;
};

function MinhaEquipe() {
  const { t } = useTranslation();
  const [selecionado, setSelecionado] = useState(null);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const backgroundPage = useTheme()?.backgroundPage;
  //
  return (
    <BackgroundRouter>
      <Grid
        container
        sx={{
          background: backgroundPage,
          maxWidth: !isMobile ? "calc(100% - 80px)" : "auto",
        }}
      >
        <Grid item xs={12} md={2}>
          <ListaOpcoes
            selecionado={selecionado}
            isMobile={isMobile}
            onClick={setSelecionado}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          {selecionado !== null ? (
            <CorpoPagina selecionado={selecionado} />
          ) : (
            <Paper elevation={0} sx={{ height: "100%" }}>
              <AnimacaoSemDados
                titulo={`${t("minha_equipe.noDataTitle1")} ${
                  isMobile ? "" : t("minha_equipe.noDataTitle2")
                } ${t("minha_equipe.noDataTitle3")}`}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </BackgroundRouter>
  );
}
// Corpo para exibir as paginas
const CorpoPagina = ({ selecionado }) => {
  const [seleMat, setSeleMat] = useState(null);
  const paginas = [
    <PontoEletronico selecionado={seleMat} setSelecionado={setSeleMat} />,
    <BancoDeHoras selecionado={seleMat} setSelecionado={setSeleMat} />,
    <BancoDeHorasGeral />,
    <QuestionarioComportamental
      selecionado={seleMat}
      setSelecionado={setSeleMat}
    />,
  ];

  return paginas[selecionado];
};
// Cria um painel de selecao de avatares para determinar os dados de quem devem ser exibidos
export const PainelSelecaoAvatar = memo(({ setSelecionado, selecionado }) => {
  const { t } = useTranslation();
  const planta = useSelector(selectUsuarioPlanta);
  const { data } = useSWR("/minha_equipe", fetchMinhaEquipe);
  let users = [];
  const [filterWord, setFilterWord] = useState(null);

  if (filterWord) {
    const usersFiltered = data
      ? data.filter(
          (ele) =>
            _.toLower(ele.nome).search(_.toLower(filterWord)) !== -1 ||
            _.toLower(ele.cargo).search(_.toLower(filterWord)) !== -1
        )
      : [];
    users = usersFiltered;
  } else {
    users = data;
  }

  return (
    <Stack sx={{ my: 1 }}>
      <Container disableGutters maxWidth="md">
        <Paper sx={{ mb: 1 }}>
          <InputBusca
            placeholder={t("minha_equipe.placeholderSearchEmployee")}
            desativarPesquisaLenta
            fullWidth
            sx={{ mb: 0 }}
            setFiltro={setFilterWord}
            filtro={filterWord}
          />
        </Paper>
      </Container>
      <Stack
        spacing={1}
        direction="row"
        overflow="auto"
        sx={{
          overflowX: "auto",
        }}
      >
        {users?.map((ele) => (
          <AvatarUsuario
            key={ele.matricula}
            {...ele}
            selecionado={selecionado}
            setSelecionado={setSelecionado}
            planta={planta}
            wordFilter={filterWord}
          />
        ))}
      </Stack>
    </Stack>
  );
});
//
const AvatarUsuario = memo(
  ({
    matricula,
    selecionado,
    setSelecionado,
    nome,
    cargo,
    planta,
    wordFilter,
  }) => (
    <Paper
      sx={{
        cursor: "pointer",
        p: 0.5,
        backgroundColor:
          selecionado === matricula
            ? (theme) => theme.palette.info.light
            : null,
      }}
      title={nome}
    >
      <ButtonBase onClick={() => setSelecionado(matricula)}>
        <Stack alignItems="center">
          <Avatar
            src={`/static/imagens/avatar/${planta}/${matricula}.jpg`}
            alt={nome}
          />
          <Body2 sx={{ whiteSpace: "nowrap" }}>
            {wordFilter ? (
              <Highlighter
                highlightClassName="bg-yellow"
                textToHighlight={nome}
                searchWords={[wordFilter]}
              />
            ) : (
              nome
            )}
          </Body2>
          <Caption>
            {wordFilter ? (
              <Highlighter
                highlightClassName="bg-yellow"
                textToHighlight={cargo}
                searchWords={[wordFilter]}
              />
            ) : (
              cargo
            )}
          </Caption>
        </Stack>
      </ButtonBase>
    </Paper>
  )
);
// Lista de opcoes a serem escolhidas
const ListaOpcoes = ({ isMobile, onClick, selecionado }) => {
  const { t } = useTranslation();
  const isDark = useTheme()?.palette.mode === "dark";
  const opcoes = [
    {
      onClick: () => onClick(0),
      titulo: t("minha_equipe.optionsPanelPointTitle"),
      icone: "Schedule",
      descricao: t("minha_equipe.optionsPanelPointDescription"),
    },
    {
      onClick: () => onClick(1),
      titulo: t("minha_equipe.optionsPanelBankOfHoursTitle"),
      icone: "Schedule",
      descricao: t("minha_equipe.optionsPanelBankOfHoursDescription"),
    },
    {
      onClick: () => onClick(2),
      titulo: t("minha_equipe.optionsPanelBankOfHoursGeneralTitle"),
      icone: "Schedule",
      descricao: t("minha_equipe.optionsPanelBankOfHoursGeneralDescription"),
    },
    {
      onClick: () => onClick(3),
      titulo: t("minha_equipe.optionsPanelPerformanceEvaluationTitle"),
      icone: "Schedule",
      descricao: t("minha_equipe.optionsPanelPerformanceEvaluationDescription"),
    },
  ];
  //
  return isMobile ? (
    <Stack
      direction="row"
      sx={{ my: 1, p: 1, width: "calc(100% - 16px) ", overflow: "auto" }}
      spacing={1}
    >
      {opcoes.map((ele, idx) => (
        <Paper
          sx={{
            p: 0.5,
            minWidth: opcoes.length > 2 ? "40%" : "calc(50% - 16px )",
            backgroundColor:
              selecionado === idx ? (theme) => theme.palette.info.light : null,
          }}
          title={ele.titulo}
          key={idx}
          elevation={3}
        >
          <ButtonBase onClick={ele.onClick}>
            <Stack direction="row" alignItems="center">
              <Icone icone={ele.icone} />
              <Body2>{ele.titulo}</Body2>
            </Stack>
          </ButtonBase>
        </Paper>
      ))}
    </Stack>
  ) : (
    <Paper
      sx={{
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        borderRadius: 0,
        background: isDark
          ? (theme) => theme.palette.background.paper
          : lightBlue[50],
      }}
    >
      <List dense>
        {opcoes.map((ele, idx) => (
          <ListItemButton
            divider={idx < opcoes.length - 1}
            key={idx}
            onClick={ele.onClick}
          >
            <ListItemIcon>
              <Icone icone={ele.icone} />
            </ListItemIcon>
            <ListItemText primary={ele.titulo} secondary={ele.descricao} />
          </ListItemButton>
        ))}
      </List>
    </Paper>
  );
};

MinhaEquipe.rota = "/minha_equipe";

export default MinhaEquipe;
