import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import React from "react";
import _ from "lodash";
import { Body1, Icone } from "../../../components";
import Utils from "../../../utils/utils";
import { useTranslation } from "react-i18next";

function ValuesSalaryBonus({ valuesSalaryBonus }) {
  const { t } = useTranslation();
  const total = _.sumBy(valuesSalaryBonus, (ele) => ele.valor);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<Icone icone="ExpandMore" />}
        aria-controls="abono-salarial"
      >
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Body1>{t("ferias.titleSalaryBonus")}</Body1>
          <Body1
            fontWeight="bold"
            sx={{ color: ({ palette }) => palette.primary.main }}
          >
            {Utils.converter(total)}
          </Body1>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <List dense disablePadding>
          {valuesSalaryBonus?.map((ele, idx) => (
            <ValuesSalaryBonusItem
              {...ele}
              key={idx}
              isLast={idx === valuesSalaryBonus?.length - 1}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

const ValuesSalaryBonusItem = ({
  descricao,
  mat,
  pagamento,
  valor,
  verba,
  isLast,
}) => {
  return (
    <ListItem disableGutters disablePadding divider={!isLast}>
      <ListItemText
        primary={<ValuesSalaryBonusPrimary value={valor} verb={verba} />}
        secondary={
          <ValuesSalaryBonusSecondary
            description={descricao}
            dateOfPayment={pagamento}
          />
        }
      />
    </ListItem>
  );
};

const ValuesSalaryBonusPrimary = ({ value, verb }) => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ mb: 1 }} direction="row" justifyContent="space-between">
      <Body1>{Utils.converter(value)}</Body1>

      <Chip
        size="small"
        color="info"
        variant="filled"
        label={
          <Body1>
            {t("ferias.verb")} {verb}
          </Body1>
        }
      />
    </Stack>
  );
};

const ValuesSalaryBonusSecondary = ({ description, dateOfPayment }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Body1>{description}</Body1>
      <Body1>{Utils.converterData(dateOfPayment)}</Body1>
    </Stack>
  );
};

export default ValuesSalaryBonus;
