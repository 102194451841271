import { memo } from "react";
import { useSelector } from "react-redux";
import { selectItens } from "./twttp-selectors";
import { Button, Chip, Container, Divider, Stack } from "@mui/material";
import {
  Body1,
  Body2,
  Caption,
  HeaderDefault,
  Icone,
  Subtitle2,
} from "../../../components";
import { getStatusText } from "./twttp-utils";
import AvatarEntrevistado from "./twttp-avatar-to-interview";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

// Visualiza detalhes do twttp
const VerDetalheTwttp = memo(({ id_twttp }) => {
  const { t } = useTranslation();
  const item = useSelector(selectItens)?.filter(
    (ele) => parseInt(ele.id_twttp) === parseInt(id_twttp)
  )[0];
  const {
    planta,
    tipo_twttp,
    area,
    entrevistador,
    entrevistado,
    setor,
    caracteristica,
    problema,
    origem,
    status,
    times,
    detalhe_erro,
    anexo,
    anexos,
    modelos,
    data_prevista,
    data_entrevista,
    data_prazo_acao,
    fator,
    turno,
    justificativa,
  } = item;
  // Separando nome e matricula do entrevistador e entrevistado
  //const [ mat_ent, nome_ent] = entrevistador.split('-');
  let mat_entrevistado, nome_entrevistado;
  if (entrevistado)
    [mat_entrevistado, nome_entrevistado] = entrevistado.split("-");

  return (
    <Container
      disableGutters
      sx={{ px: 0.5, py: 1, maxHeight: "85vh" }}
      maxWidth="lg"
    >
      <HeaderDefault children={"Detalhes"} />
      <Divider component="br" />
      <Stack direction="row" spacing={1} alignItems="center">
        <Subtitle2>{t("twttp.labelSituationViewDetails")}: </Subtitle2>
        <Chip color="success" size="small" label={getStatusText(status, t)} />
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Stack direction="row" spacing={1} alignItems="center">
        <Subtitle2>{t("twttp.labelSectorViewDetails")}: </Subtitle2>
        <Caption>{setor}</Caption>
      </Stack>
      <Divider sx={{ my: 1 }} />

      {mat_entrevistado && (
        <>
          <Subtitle2
            sx={{
              borderRadius: 8,
              mb: 1,
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
            }}
          >
            {t("twttp.labelInterviewedViewDetails")}:{" "}
          </Subtitle2>

          <AvatarEntrevistado
            nome_entrevistado={nome_entrevistado}
            planta={planta}
            mat_entrevistado={mat_entrevistado}
          />

          <Divider sx={{ my: 1 }} />
        </>
      )}
      <Stack direction="row" spacing={1}>
        <Subtitle2>{t("twttp.labelInterviewerViewDetails")}: </Subtitle2>
        <Caption>{entrevistador}</Caption>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Stack>
        <Stack direction="row" spacing={1}>
          <Subtitle2>{t("twttp.labelInterviewDateViewDetails")}: </Subtitle2>
          <Body2 color="error">
            {data_entrevista && format(parseISO(data_entrevista), "dd/MM/yy")}
          </Body2>
        </Stack>
        <Divider sx={{ my: 1 }} />

        <Stack direction="row" spacing={1}>
          <Subtitle2>{t("twttp.labelPrevDateViewDetails")}: </Subtitle2>
          <Body2 fontWeight="bold">
            {format(parseISO(data_prevista), "dd/MM/yy")}
          </Body2>
        </Stack>
        <Divider sx={{ my: 1 }} />
        {data_prazo_acao && (
          <>
            <Stack direction="row" spacing={1}>
              <Subtitle2>{t("twttp.labelActionDateViewDetails")}: </Subtitle2>
              <Body2 fontWeight="bold">
                {format(parseISO(data_prazo_acao), "dd/MM/yy")}
              </Body2>
            </Stack>
            <Divider sx={{ my: 1 }} />
          </>
        )}

        <Stack direction="row" spacing={1}>
          <Subtitle2>{t("twttp.labelTypeViewDetails")} : </Subtitle2>
          <Caption>{tipo_twttp}</Caption>
        </Stack>
        <Divider sx={{ my: 1 }} />

        <Stack direction="row" spacing={1}>
          <Subtitle2>{t("twttp.labelProblemViewDetails")}: </Subtitle2>
          <Caption>{problema}</Caption>
        </Stack>
        <Divider sx={{ my: 1 }} />

        <Stack direction="row" spacing={1}>
          <Subtitle2>{t("twttp.labelProcessViewDetails")} : </Subtitle2>
          <Caption>{area}</Caption>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Subtitle2>{t("twttp.labelModelViewDetails")}</Subtitle2>
        <Stack spacing={1} direction="row">
          {modelos.map((ele) => (
            <Chip size="small" key={ele} label={ele} color="primary" />
          ))}
        </Stack>
        <Divider sx={{ my: 1 }} />

        <Stack direction="row" spacing={1}>
          <Subtitle2>{t("twttp.labelErrorFeatureViewDetails")}: </Subtitle2>
          <Caption>{caracteristica}</Caption>
        </Stack>
        <Divider sx={{ my: 1 }} />

        <Stack direction="row" spacing={1}>
          <Subtitle2>{t("twttp.labelErrorDetailViewDetails")} : </Subtitle2>
          <Caption>{detalhe_erro}</Caption>
        </Stack>
        <Divider sx={{ my: 1 }} />

        <Stack direction="row" spacing={1}>
          <Subtitle2>{t("twttp.labelProblemOriginViewDetails")}: </Subtitle2>
          <Caption>{origem}</Caption>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row" spacing={1}>
          <Subtitle2>{t("twttp.labelFactorViewDetails")}: </Subtitle2>
          <Caption>{fator?.toUpperCase()}</Caption>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row" spacing={1}>
          <Subtitle2>{t("twttp.labelShiftViewDetails")}: </Subtitle2>
          <Caption>{turno}</Caption>
        </Stack>
        <Divider sx={{ my: 1 }} />
        {justificativa?.length > 0 && (
          <>
            <Stack spacing={1}>
              <Subtitle2>
                {t("twttp.labelRejectionJustifyViewDetails")}:{" "}
              </Subtitle2>
              <Body1>{justificativa}</Body1>
            </Stack>
            <Divider sx={{ my: 1 }} />
          </>
        )}

        <Subtitle2>{t("twttp.labelTimeViewDetails")}</Subtitle2>
        <Stack spacing={2} direction="row">
          {times.map((ele) => (
            <Chip size="small" key={ele} label={ele} color="primary" />
          ))}
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Subtitle2>{t("twttp.labelAttachmentViewDetails")}</Subtitle2>
        <Stack direction="row">
          <Button
            variant="outlined"
            startIcon={<Icone icone="FileDownload" />}
            href={anexo}
            target="_blank"
          >
            {t("twttp.labelAttachmentViewDetails")}
          </Button>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Subtitle2>{t("twttp.labelAttachmentValidationViewDetails")}</Subtitle2>
        <Stack direction="row" sx={{ mt: 1 }} spacing={1} flexWrap="wrap">
          {anexos?.map((ele, idx) => (
            <Button
              key={idx}
              size="small"
              variant="outlined"
              startIcon={<Icone icone="FileDownload" />}
              href={ele}
              target="_blank"
            >
              {idx + 1}° {t("twttp.labelAttachmentViewDetails")}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
});

export default VerDetalheTwttp;
