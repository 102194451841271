import React, { memo, useEffect, useCallback } from "react";
import {
  FormControlLabel,
  Divider,
  Button,
  List,
  ListItem,
  Chip,
  Avatar,
  Stack,
  Container,
  useMediaQuery,
  useTheme,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Switch,
} from "@mui/material";
import {
  Tab,
  SideBar,
  InputBusca,
  Body2,
  Subtitle2,
  ScrollInfinito,
} from "../../../components";
import { H6 } from "../../../components";
import { useDispatch, useSelector } from "react-redux";

import { registrarAcesso } from "../../../utils/utils";

import {
  manutencaoVariaveisGetDados,
  manutencaoVariaveisSetFiltroColaborador,
  manutencaoVariaveisSetFiltroPlanta,
  manutencaoVariaveisSetDrawer,
  manutencaoVariaveisFecharDrawer,
  manutencaoVariaveisUpdDelVariavel,
  manutencaoVariaveisGlobaisGetDados,
} from "../../../redux/actions";
//
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import {
  selectColaboradoresFiltro,
  selectDrawer,
  selectFiltroPlanta,
  selectVariaveis,
  selectVariaveisGlobais,
} from "./manutencao-variaveis-selectors";
import { DrawerDialog } from "../../../extra-components";
import ManutencaoVariaveisModal from "./manutencao-variaveis-modal";
import { useTranslation } from "react-i18next";

function ManutencaoVariaveis() {
  const { t } = useTranslation();
  // Registrando acesso a pagina
  useEffect(() => registrarAcesso(window.location.pathname), []);
  //
  const dispatch = useDispatch();

  useEffect(() => {
    // Obtem os colaboradores e variaveis
    dispatch(manutencaoVariaveisGetDados());
    // Obtem as variaveis globais
    dispatch(manutencaoVariaveisGlobaisGetDados());
  }, [dispatch]);
  // Veja se e mobile
  const isXl = useMediaQuery(useTheme()?.breakpoints?.up("xl"));
  //const dados = useSelector(state=> state.manutencaoVariaveis);
  const drawer = useSelector(selectDrawer);

  const fnFecharDrawer = useCallback(
    () => dispatch(manutencaoVariaveisFecharDrawer()),
    [dispatch]
  );

  return (
    <>
      <Stack direction="row">
        <SideBar barraIntegrada />
        <Container maxWidth={isXl ? "xl" : "lg"}>
          {drawer && (
            <DrawerDialog
              fnGetCorpo={() => (
                <ManutencaoVariaveisModal
                  modal={drawer}
                  fecharModal={fnFecharDrawer}
                />
              )}
              fecharModal={fnFecharDrawer}
            />
          )}

          <H6>{t("manutencao_variaveis.titlePage")}</H6>
          <RenderCorpo />
        </Container>
      </Stack>
    </>
  );
}

const RenderCorpo = memo(() => {
  const { t } = useTranslation();
  const cabe = [
    t("manutencao_variaveis.tabOne"),
    t("manutencao_variaveis.tabTwo"),
    t("manutencao_variaveis.tabThree"),
  ];

  return (
    <Tab
      cabe={cabe}
      corpo={[<AbaColaboradores />, <AbaVariaveis />, <AbaVariaveisGlobais />]}
    />
  );
});
// Componente que renderiza o corpo dos colaboradores
const AbaColaboradores = () => {
  const colaboradores = useSelector(selectColaboradoresFiltro);

  return (
    <Stack>
      <PainelBusca />
      {colaboradores && (
        <ScrollInfinito
          itens={colaboradores}
          render={(ele) => <ItemColaborador {...ele} />}
        />
      )}
    </Stack>
  );
};
// Item de colaboradore
const ItemColaborador = memo(({ mat, planta, nome, cargo }) => {
  const dispatch = useDispatch();
  const fnVerVariavelColaborador = useCallback(() => {
    dispatch(
      manutencaoVariaveisSetDrawer({
        tipo: ManutencaoVariaveisModal.modal.VER_VARIAVEL_USUARIO,
        dados: `${mat}_${planta}`,
      })
    );
  }, [dispatch, mat, planta]);

  return (
    <ListItemButton divider onClick={fnVerVariavelColaborador}>
      <ListItemAvatar>
        <Avatar
          src={`/static/imagens/avatar/${planta}/${mat}.jpg`}
          alt={nome}
          sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack sx={{ my: 0.5 }}>
            <Stack direction="row" alignItems="center">
              <Subtitle2>{mat}</Subtitle2> &nbsp;&nbsp;&nbsp;{" "}
              <Chip size="small" label={planta} color="primary" />
            </Stack>
            <Body2>{nome}</Body2>
          </Stack>
        }
        secondary={cargo}
      />
    </ListItemButton>
  );
});
// Componente para o painel de busca
const PainelBusca = memo(() => {
  const plantsVisibles = ["TAPE", "TAMG", "TAA"];
  //
  const dispatch = useDispatch();
  const plantas = useSelector(selectFiltroPlanta);
  //
  const fnAtualizaPlanta = (checado, valor) => {
    let listaPlanta = plantas.slice();

    if (checado) {
      listaPlanta = [...listaPlanta, valor];
    } else {
      // Remove
      const idx = listaPlanta.indexOf(valor);
      if (idx > -1) {
        listaPlanta.splice(idx, 1);
      }
    }

    dispatch(manutencaoVariaveisSetFiltroPlanta([...listaPlanta]));
  };

  return (
    <Stack
      sx={{ mt: 1 }}
      direction={{ xs: "column", md: "row" }}
      spacing={1}
      alignItems="center"
    >
      <InputBusca
        sx={{ flex: 3 }}
        fullWidth
        setFiltro={(val) =>
          dispatch(manutencaoVariaveisSetFiltroColaborador(val))
        }
      />
      <Stack direction="row" sx={{ flex: 1 }} spacing={2} alignItems="center">
        {plantsVisibles.map((ele) => (
          <FormControlLabel
            sx={{ flex: 1 }}
            onChange={(e) => fnAtualizaPlanta(e.target.checked, ele)}
            checked={plantas?.includes(ele) || false}
            control={<Switch />}
            label={ele}
          />
        ))}
      </Stack>
    </Stack>
  );
});

// Componente para a aba de variaveis
const AbaVariaveis = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const dispatch = useDispatch();
  const itens = useSelector(selectVariaveis);
  // FN para adicionar variavel (intencao)
  const fnAdicionar = useCallback(() => {
    dispatch(
      manutencaoVariaveisSetDrawer({
        tipo: ManutencaoVariaveisModal.modal.ADD_VARIAVEL,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Stack sx={{ my: 1 }} direction="row-reverse">
        <Button
          size="small"
          onClick={fnAdicionar}
          startIcon={<AddIcon />}
          fullWidth={isMobile}
          variant="contained"
        >
          {t("manutencao_variaveis.labelBtnAddVar")}
        </Button>
      </Stack>
      <List disablePadding sx={{ maxHeight: "60vh", overflowY: "auto" }}>
        {itens.map((ele) => {
          return <ItemVariavel key={ele.id} {...ele} />;
        })}
      </List>
    </>
  );
};
// Componente que cria o item da variavel
const ItemVariavel = memo(({ id, nome, descricao }) => {
  const { t } = useTranslation();
  // Veja se e mobile
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const dispatch = useDispatch();
  // Funcao para excluir
  const fnExcluir = useCallback(
    (id) => {
      dispatch(manutencaoVariaveisUpdDelVariavel({ id_variavel: id }, true));
    },
    [dispatch]
  );
  // Funcao para a intencao de editar uma variavel
  const fnEditar = useCallback(
    (id) => {
      dispatch(
        manutencaoVariaveisSetDrawer({
          tipo: ManutencaoVariaveisModal.modal.EDITAR_VARIAVEL,
          dados: id,
        })
      );
    },
    [dispatch]
  );

  return (
    <ListItem divider>
      <ListItemText
        primary={<Body2>{nome}</Body2>}
        secondary={
          <Stack>
            <Body2 align="left">{descricao}</Body2>
            <Stack
              direction="row"
              spacing={1}
              justifyContent={{ xs: "space-between", md: "flex-start" }}
            >
              <Button
                fullWidth={isMobile}
                variant="contained"
                onClick={() => fnEditar(id)}
                size="small"
                startIcon={<EditIcon />}
              >
                {t("manutencao_variaveis.labelBtnEditVar")}
              </Button>
              <Button
                onClick={() => fnExcluir(id)}
                fullWidth={isMobile}
                variant="outlined"
                size="small"
                startIcon={<DeleteIcon />}
              >
                {t("manutencao_variaveis.labelBtnDelVar")}
              </Button>
            </Stack>
          </Stack>
        }
      />
    </ListItem>
  );
});
// Componente para exibir as variaveis globais
const AbaVariaveisGlobais = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const variaveisGlobais = useSelector(selectVariaveisGlobais);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  //
  const fnAdd = useCallback(
    () =>
      dispatch(
        manutencaoVariaveisSetDrawer({
          tipo: ManutencaoVariaveisModal.modal.ADD_VARIAVEL_GLOBAL,
        })
      ),
    [dispatch]
  );

  return (
    <Container maxWidth="lg">
      <Stack direction="row-reverse">
        <Button
          fullWidth={isMobile}
          sx={{ my: 1 }}
          size="small"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={fnAdd}
        >
          {t("manutencao_variaveis.labelBtnAddVarGlobal")}
        </Button>
      </Stack>
      <List disablePadding>
        {variaveisGlobais?.map((ele) => {
          const { nome, descricao, valor, id_variavel } = ele;
          return (
            <VariavelGlobalItem
              key={id_variavel}
              nome={nome}
              descricao={descricao}
              id_variavel={id_variavel}
              valor={valor}
            />
          );
        })}
      </List>
    </Container>
  );
};
// Componente para cada item da lista de variaveis globais
const VariavelGlobalItem = memo(({ valor, nome, descricao, id_variavel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //
  const fnEditar = useCallback(
    () =>
      dispatch(
        manutencaoVariaveisSetDrawer({
          tipo: ManutencaoVariaveisModal.modal.EDITAR_VARIAVEL_GLOBAL,
          dados: id_variavel,
        })
      ),
    [dispatch, id_variavel]
  );
  const fnExcluir = useCallback(
    () =>
      dispatch(
        manutencaoVariaveisSetDrawer({
          tipo: ManutencaoVariaveisModal.modal.DEL_VARIAVEL_GLOBAL,
          dados: id_variavel,
        })
      ),
    [dispatch, id_variavel]
  );
  //
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  return (
    <Stack>
      <ListItem>
        <ListItemText primary={nome} secondary={descricao} />
      </ListItem>
      <Stack spacing={1} direction={{ xs: "column", md: "row" }}>
        <Stack spacing={1} direction="row">
          <Button
            fullWidth={isMobile}
            onClick={fnEditar}
            title={t("manutencao_variaveis.titleEditVarGlobal")}
            size="small"
            variant="contained"
            startIcon={<EditIcon />}
          >
            {t("manutencao_variaveis.labelBtnEditVarGlobal")}
          </Button>
          <Button
            fullWidth={isMobile}
            onClick={fnExcluir}
            title={t("manutencao_variaveis.titleDelVarGlobal")}
            size="small"
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            {t("manutencao_variaveis.labelBtnDelVarGlobal")}
          </Button>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Body2>{t("manutencao_variaveis.labelValueActualy")}</Body2>{" "}
          <Chip
            title={`${t("manutencao_variaveis.labelValueActualy")} ${valor}`}
            color="primary"
            label={valor}
          />
        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
    </Stack>
  );
});

ManutencaoVariaveis.rota = "/manutencao_variaveis";

export default ManutencaoVariaveis;
