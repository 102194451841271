import React, { useCallback, useEffect, useState } from "react";
import {
  Divider,
  Button,
  Stack,
  Container,
  Typography,
  List,
  ListItemText,
  ListItem,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import _ from "lodash";
import axios from "axios";
import PropTypes from "prop-types";
import { parseISO, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { toast } from "react-toastify";
import ImgBolo from "../images/bolo.svg";
import { Comentario } from "../components";
import { useFetch } from "../hooks";
import { ToastErro } from "../utils/utils";
import { useTranslation } from "react-i18next";

export default function Aniversariantes({ rota, imagemIcone, isNiverEmpresa }) {
  const { t } = useTranslation();
  const [aniversariantes, setAniversariantes] = useState([]);
  const titulo = `${t(
    isNiverEmpresa
      ? "components.aniversariantes.titleCompany"
      : "components.aniversariantes.title"
  )} (${_.capitalize(format(new Date(), "MMMM", { locale: ptBR }))})`;
  //const [aguardar, setAguardar] = useState(false);

  // Carregando os aniversariantes
  useEffect(() => {
    (async () => {
      //setAguardar(true);
      try {
        const resp = await axios.get(`${rota}?mes=${format(new Date(), "MM")}`);
        if (resp.status !== 200) {
          toast.dark(t("components.aniversariantes.errorGetBirthDay"), {
            type: "error",
          });
          return false;
        }
        // Veja se tem mensagem de erro
        if (resp.data?.erro) {
          toast.dark(resp.data.erro, {
            type: "error",
          });
          return false;
        }
        // Tudo certo incremente os aniversariantes
        setAniversariantes(resp.data);
      } catch (error) {
        toast.dark("ERRO AO TENTAR RECUPERAR");
        console.log(error);
        return false;
      } finally {
        //setAguardar(false);
      }
    })();
  }, [rota, setAniversariantes, t]);

  // Faz um filtro dos aniversariantes da data atual
  const aniversariantesHoje = isNiverEmpresa
    ? aniversariantes.filter(
        (ele) => format(new Date(), "yyyy-MM-dd") === ele.data
      )
    : aniversariantes.filter((ele) => format(new Date(), "MM-dd") === ele.data);
  //const aniversariantesAntesDeHoje = aniversariantes.filter(ele=> format(new Date(), 'MM-dd') > ele.data );
  const aniversariantesDepoisDeHoje = isNiverEmpresa
    ? aniversariantes.filter(
        (ele) =>
          format(new Date(), "MM-dd") < format(parseISO(ele.data), "MM-dd")
      )
    : aniversariantes.filter((ele) => format(new Date(), "MM-dd") < ele.data);
  const anoAtual = new Date()
    .toLocaleDateString("pt-br")
    .split("/")
    .reverse()[0];

  return (
    <Container disableGutters sx={{ p: 1 }}>
      <Stack direction="row" alignItems="center">
        <img
          alt={t("components.aniversariantes.altImg")}
          src={imagemIcone}
          height={36}
        />
        <Typography
          sx={{ flex: 1 }}
          fontWeight="bold"
          align="center"
          variant="subtitle1"
        >
          {titulo}
        </Typography>
      </Stack>
      <Divider sx={{ my: 0.5 }} />
      <Stack direction="row" alignItems="flex-end">
        {/* <Lottie 
                options={defaultOptions}
                height={48}
                width={48}
            /> */}
        <Typography
          sx={{ flex: 1 }}
          variant="subtitle2"
          fontWeight="bold"
          component="p"
          align="center"
        >
          {t("components.aniversariantes.today")}{" "}
          {format(new Date(), "dd/MM", { locale: ptBR })} (
          {format(new Date(), "EEEE", { locale: ptBR })}) 🥳
        </Typography>
      </Stack>

      <List>
        {aniversariantesHoje?.map((ele, idx) => (
          <ListItemAniversariante
            key={idx}
            {...ele}
            isNiverEmpresa={isNiverEmpresa}
            data={isNiverEmpresa ? ele.data : `${anoAtual}-${ele.data}`}
            isNiverHoje
          />
        ))}
      </List>
      <MuiAccordion disableGutters>
        <MuiAccordionSummary
          disableGutters
          sx={{ zIndex: 999, px: 1, position: "sticky", top: 0 }}
        >
          <Button size="small" variant="contained" fullWidth>
            {t("components.aniversariantes.labelBtnNext")}{" "}
            {aniversariantesDepoisDeHoje?.length > 0 &&
              `(${aniversariantesDepoisDeHoje.length})`}
          </Button>
        </MuiAccordionSummary>
        <MuiAccordionDetails>
          <List disablePadding>
            {aniversariantesDepoisDeHoje?.map((ele, idx) => (
              <ListItemAniversariante
                key={idx}
                {...ele}
                data={isNiverEmpresa ? ele.data : `${anoAtual}-${ele.data}`}
                isNiverEmpresa={isNiverEmpresa}
              />
            ))}
          </List>
        </MuiAccordionDetails>
      </MuiAccordion>
    </Container>
  );
}

const ListItemAniversariante = (props) => {
  const { t } = useTranslation();
  //
  const { matricula, nome, avatar, cargo } = props;
  //
  const { data, error, setFetch } = useFetch(
    "/aniversariantes",
    "POST",
    "param"
  );
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);
  //
  useEffect(() => {
    if (data && data.sucesso) {
      toast.dark(data.sucesso, { type: "success" });
    }
  }, [data]);
  //
  const fnEnviarMensagem = useCallback(
    (mensagem) => {
      setFetch({
        mensagem,
        matricula: matricula,
      });
    },
    [setFetch, matricula]
  );

  return (
    <>
      <ListItem key={matricula}>
        <ListItemAvatar>
          <Avatar
            title={`Avatar de ${nome}`}
            src={avatar}
            alt={nome}
            sx={{ backgroundColor: "#012258" }}
          />
        </ListItemAvatar>
        <ListItemText
          title={props.nome}
          primary={props.nome}
          secondary={
            <Stack>
              <Typography variant="caption">{cargo}</Typography>
              <Typography variant="caption">
                {format(parseISO(props.data), "EEEE dd/MM", { locale: ptBR })}
              </Typography>
            </Stack>
          }
        />
      </ListItem>
      {props?.isNiverHoje && (
        <Comentario
          onEnviarComentario={fnEnviarMensagem}
          placeholder={t("components.aniversariantes.greeting")}
        />
      )}
      <Divider />
    </>
  );
};

Aniversariantes.defaultProps = {
  rota: "/aniversariantes",
  isNiverEmpresa: false,
  imagemIcone: ImgBolo,
};

Aniversariantes.propTypes = {
  rota: PropTypes.string.isRequired,
  isNiverEmpresa: PropTypes.bool,
  imagemIcone: PropTypes.string,
};
