import React from "react";
import { Body1, H6 } from "../../../components";
import { FormSearchAdvanced } from "../../../extra-components";
import { useTranslation } from "react-i18next";

function GestaoHelpdeskFiltroAvancado({ filters, onFilter }) {
  const { t } = useTranslation();
  const titulo = t("gestao_helpdesk.titleSearchAdvanced");
  const descricao = t("gestao_helpdesk.descriptionSearchAdvanced");

  return (
    <>
      <H6>{titulo}</H6>
      <Body1 align="center">{descricao}</Body1>
      <br />
      <FormSearchAdvanced filters={filters} onFilter={onFilter} />
    </>
  );
}

export default GestaoHelpdeskFiltroAvancado;
