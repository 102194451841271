import React, { useState } from "react";
import { Paper, Stack, Chip, Box, Grow } from "@mui/material";
import { Body1, Icone, AnimacaoSemDados } from "../../../components";
import BaixarInformeRendimento from "./informe-rendimentos-download";
import { format } from "date-fns";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const ANOS = _.range(2022, parseInt(format(new Date(), "yyyy")) + 1);

const InformeRendimentosCorpo = () => {
  const { t } = useTranslation();
  const [anoSelecionado, setAnoSelecionado] = useState();
  //
  const tituloChip = t("informe_rendimentos.titleChip");
  const tituloAnimacaoSemDados = t("informe_rendimentos.titleNoData");

  //
  return (
    <Paper sx={{ minHeight: "60vh", p: 1, mb: 1 }}>
      <Stack>
        <Body1 fontWeight="bold">Anos:</Body1>
        <Stack
          direction="row"
          sx={{ mt: 1, width: "100%", overflowX: "auto" }}
          spacing={1}
        >
          {ANOS.map((ele) => (
            <Chip
              title={`${tituloChip} ${ele}`}
              label={ele}
              icon={
                ele === anoSelecionado ? (
                  <Icone icone="Check" />
                ) : (
                  <Icone icone="RadioButtonUnchecked" />
                )
              }
              key={ele}
              variant={ele === anoSelecionado ? "filled" : "outlined"}
              color="primary"
              clickable
              onClick={() =>
                setAnoSelecionado((state) => (state === ele ? null : ele))
              }
            />
          ))}
        </Stack>
        {anoSelecionado ? (
          <Grow key={anoSelecionado} in unmountOnExit>
            <Box>
              <BaixarInformeRendimento ano={anoSelecionado} />
            </Box>
          </Grow>
        ) : (
          <Grow key="selecionar" in unmountOnExit>
            <Box>
              <AnimacaoSemDados titulo={tituloAnimacaoSemDados} />
            </Box>
          </Grow>
        )}
      </Stack>
    </Paper>
  );
};

export default InformeRendimentosCorpo;
