import { Paper } from "@mui/material";
import { Icone } from "../../../components";
import { withStyles } from "@material-ui/styles";

//
export const getSituacao = (situ, t) => {
  switch (situ) {
    case "A":
      return t("dados_cadastrais.approved");
    case "R":
      return t("dados_cadastrais.inAnalysis");
    default:
      return t("dados_cadastrais.pending");
  }
};
//
export const getSituacaoCor = (situ) => {
  switch (situ) {
    case "A":
      return "success";
    case "R":
      return "info";
    default:
      return "error";
  }
};
//
export const getSituacaoIcone = (situ) => {
  switch (situ) {
    case "A":
      return <Icone icone="ThumbUp" />;
    case "R":
      return <Icone icone="SwapHoriz" />;
    default:
      return <Icone icone="AccessTime" />;
  }
};
//
export const getSituacaoTitulo = (situ, t) => {
  switch (situ) {
    case "A":
      return t("dados_cadastrais.titleApproved");
    case "R":
      return t("dados_cadastrais.titleInAnalysis");
    default:
      return t("dados_cadastrais.titlePending");
  }
};

export const PaperCustom = withStyles({
  root: {
    padding: "16px",
  },
})(Paper);
