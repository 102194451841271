import React, { useState } from "react";
import { Pagination, Stack } from "@mui/material";
import { RetornoEmBranco } from ".";
/**
 * Este componente irá montar um sistema automatizado de paginacao ele recebe
 * - callbackPagina (valorAntigo, novoValor) => {} // A funcao de callback que vai lidar com as interaçoes a pagina
 * - componente (props) => {}, // O componente responsavel por receber as props e renderizar
 * - corpo: List[], // A lista de itens. Enviar a lista inteira pois ela sera fatiada (salvo casos onde callbackPagina recupera a lista)
 * - totalPorPagina: int // Um inteiro que determinara a quantidade de itens por pagina
 * - componentPai ReactNode. // Ele será o componente que servira de container para os filhos (component)
 */

export default function Paginacao({
  callBackPagina,
  component,
  corpo,
  totalPorPagina,
  componentePai,
  quantidadeDePaginas,
  paginaAtual,
}) {
  // Controla o estado somente neste componente de lista
  const [pagina, setPagina] = useState(paginaAtual);
  // Verifica quantas paginas serão criadas neste filtro
  let quantidadePagina = 1;
  let copiaCorpo;
  // Se o callbackPagina for enviado eu sei que quero manipular as trocas de pagina
  if (callBackPagina) {
    // Nenhuma operacao ocorre aqui somente a atribuicao do corpo enviado
    quantidadePagina = quantidadeDePaginas;
    copiaCorpo = corpo;
  } else {
    try {
      const resto = corpo.length % totalPorPagina === 0 ? 0 : 1; // Esta sobrando valores nesta divisao
      quantidadePagina = parseInt(corpo.length / totalPorPagina + resto);
    } catch (err) {
      console.log(err);
      quantidadePagina = 1;
    }
    // Agora vamos extrair o valor atual da pagina.
    copiaCorpo = corpo
      .slice()
      .splice(totalPorPagina * (pagina - 1), totalPorPagina);
  }

  // Funcao de callback para manipular paginacao
  const manipularPaginacao = (evt, value) => {
    // Se tiver sido enviado um callback para manipular a pagina, exiba a pagina antiga e a atual neste callback
    if (callBackPagina) callBackPagina(pagina, value);
    // Atualiza internamente a pagina atual
    setPagina(value);
  };

  // RootPaginacao
  return (
    <Stack>
      {quantidadePagina > 1 && (
        <Pagination
          color="primary"
          defaultPage={pagina}
          count={quantidadePagina}
          onChange={manipularPaginacao}
        />
      )}
      {copiaCorpo?.length < 1 ? (
        <RetornoEmBranco />
      ) : componentePai ? (
        componentePai(
          copiaCorpo?.map((ele) => {
            return component(ele);
          })
        )
      ) : (
        <Stack direction="row" flexWrap="wrap">
          {copiaCorpo?.map((ele) => {
            return component(ele);
          })}
        </Stack>
      )}
    </Stack>
  );
}

Paginacao.defaultProps = {
  paginaAtual: 1,
};
