import axios from "axios";
import { toast } from "react-toastify";
import { OPCOES_TOAST } from "../../..//utils/utils";
import { ERRO_STATUS_NAO_200 } from "../../../redux/actions";
import { setAguardar } from "../../../redux/actions/aguardar-actions";
import { ERRO_NAO_ENVIADO } from "../../../redux/actions/erro-actions";
import KaizenModal from "./kaizen-modal";

const VALORES_DRAWER = KaizenModal.modal;

export const CADASTRO_PROJETOS_DRAWER = "CADASTRO_PROJETOS_DRAWER"; // Controla a exibição ou não dos Drawers/Dialogs
export const CADASTRO_PROJETOS_GET_DADOS = "CADASTRO_PROJETOS_GET_DADOS";
export const CADASTRO_PROJETOS_GET_PROJETOS = "CADASTRO_PROJETOS_GET_PROJETOS";
export const CADASTRO_PROJETOS_ATUALIZAR_STATUS =
  "CADASTRO_PROJETOS_ATUALIZAR_STATUS";
export const CADASTRO_PROJETOS_GET_HISTORICO =
  "CADASTRO_PROJETOS_GET_HISTORICO"; // Obter status do projeto
export const CADASTRO_PROJETOS_ADD_JUSTIFICATIVA =
  "CADASTRO_PROJETOS_ADD_JUSTIFICATIVA"; // Para adicionar uma justificativa de atraso
export const CADASTRO_PROJETOS_PROXIMA_PAGINA =
  "CADASTRO_PROJETOS_PROXIMA_PAGINA";
export const CADASTRO_PROJETOS_RESPONDER_CHECKLIST =
  "CADASTRO_PROJETOS_RESPONDER_CHECKLIST"; // Para submeter a resposta do checklist
export const CADASTRO_PROJETOS_FILTRO_AVANCADO =
  "CADASTRO_PROJETOS_FILTRO_AVANCADO"; // Para ver filtros avancados
export const CADASTRO_PROJETOS_FILTRO_AVANCADO_FECHAR =
  "CADASTRO_PROJETOS_FILTRO_AVANCADO_FECHAR"; // Para fechar filtro avancado
export const CADASTRO_PROJETOS_GET_DADOS_GRAFICO =
  "CADASTRO_PROJETOS_GET_DADOS_GRAFICO"; // Para exibicao de dados dos graficos
export const CADASTRO_PROJETOS_UPD_MEMBROS = "CADASTRO_PROJETOS_UPD_MEMBROS"; // Para atualizar os novos membros do projeto

const ROTAS = [
  "/get_cadastro_projetos",
  "/cadastro_projetos",
  "/get_historico_cadastro_projetos",
  "/cadastro_projetos_justificativa",
  "/cadastro_projetos_checklist",
  "/get_cadastro_projetos_checklist",
  "/cadastro_projetos_saving",
  "/cadastro_projetos_filtro_avancado",
  "/cadastro_projetos_grafico",
  "/cadastro_projetos_membros",
];
// Action para controlar a exibicao ou nao do drawer
export const cadastroProjetosSetDrawer = (valor) => ({
  type: CADASTRO_PROJETOS_DRAWER,
  data: valor,
});
// Actions creators para recuperar os dados
export const cadastroProjetosGetDados = () => async (dispatch) => {
  try {
    const resp = await axios.get(`${ROTAS[0]}?tipo=dados`);
    if (resp.status !== 200) {
      toast(ERRO_STATUS_NAO_200);
      return false;
    }
    if (resp.data.erro) {
      toast(resp.data.erro);
      return false;
    }
    // Recebe os dados e monta e estrutura inicial
    dispatch({
      type: CADASTRO_PROJETOS_GET_DADOS,
      data: resp.data,
    });
  } catch (err) {
    toast("ERRO DESCONHECIDO.");
  }
};
// Action para recuperar os projetos que o usuario faz parte
export const cadastroProjetosGetProjetos =
  (tipo, valor, setAguardando) => async (dispatch) => {
    setAguardando(true);

    try {
      const resp = await axios.get(`${ROTAS[0]}?tipo=${tipo}&valor=${valor}`);
      setAguardando(false);
      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200);
        return false;
      }
      if (resp.data.erro) {
        toast(resp.data.erro);
        return false;
      }
      // Se o tipo for id_projeto ou pagina o filtro deve ser false. Senao e true
      //const filtro = (!['id_projeto', 'pagina'].includes(tipo)) ? true : false;
      // Recebe os projetos e monta e estrutura inicial
      dispatch({
        type: ["id_projeto", "pagina"].includes(tipo)
          ? CADASTRO_PROJETOS_PROXIMA_PAGINA
          : CADASTRO_PROJETOS_GET_PROJETOS,
        //data: {...resp.data, filtro },
        data: { ...resp.data },
      });
    } catch (err) {
      console.log(err);
      setAguardando(false);
      toast("ERRO DESCONHECIDO AO TENTAR RECUPERAR OS PROJETOS.");
    }
  };
// Action para registrar o cadastro de um projeto
export const cadastroProjetosCadastro = (obj) => (dispatch) => {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));
  // if(arquivos && arquivos.length > 0){
  //     [...arquivos].forEach((ele)=>{
  //         formData.append('arquivo', ele);
  //     })
  // }
  dispatch(setAguardar(true));
  // Envia os dados para registro
  axios
    .post(ROTAS[1], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      // Veja se tem mensagem de erro
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Exibe um toast informando que deu certo
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Deu tudo  certo, vamos atualizar os dados
      dispatch(
        cadastroProjetosGetProjetos(
          "id_projeto",
          resp.data.id_projeto,
          () => {}
        )
      );
      dispatch(cadastroProjetosGetDados());
      // Fechando a janela que exibe o formulario de cadastro dos projetos
      dispatch(cadastroProjetosSetDrawer(null));
    })
    .catch((err) => {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para atualizar o status de um projeto
export const cadastroProjetosAtualizarStatus = (obj) => (dispatch) => {
  const formData = new FormData();
  // Veja o status, se for para S (validacao FI) entao temos os campos de anexo arquivo_fi e arquivo_cd
  if (obj.status === "S") {
    // Para o campo de anexo FI
    [...obj.arquivo_fi].forEach((arq) => {
      formData.append("arquivo_fi", arq);
    });
    delete obj.arquivo_fi;
    if (obj?.arquivo_cd) {
      // Para o campo de anexo cd
      [...obj.arquivo_cd].forEach((arq) => {
        formData.append("arquivo_cd", arq);
      });
      delete obj.arquivo_cd;
    }
  }
  // Veja se tem anexo envolvido
  if (obj.arquivo) {
    [...obj.arquivo].forEach((arq) => {
      formData.append("arquivo", arq);
    });
    //formData.append('arquivo', obj.arquivo[0]);
    delete obj.arquivo;
  }

  formData.append("dados", JSON.stringify(obj));

  dispatch(setAguardar(true));
  // Envia os dados para registro
  axios
    .patch(ROTAS[1], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200, OPCOES_TOAST);
        return false;
      }
      // Veja se tem mensagem de erro
      if (resp.data.erro) {
        toast(resp.data.erro, OPCOES_TOAST);
        return false;
      }
      // Exibe um toast informando que deu certo
      toast(resp.data.sucesso, OPCOES_TOAST);
      // Deu tudo certo , vamos atualizar o status do projeto
      dispatch({
        type: CADASTRO_PROJETOS_ATUALIZAR_STATUS,
        data: { id_projeto: resp.data.id_projeto, status: resp.data.status },
      });

      //if(fecharDrawer) fecharDrawer();
      dispatch(cadastroProjetosSetDrawer(null));
    })
    .catch((err) => {
      console.log(err);
      toast(ERRO_NAO_ENVIADO, OPCOES_TOAST);
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para exibir o historico do projeto
export const cadastroProjetosGetHistorico = (obj) => async (dispatch) => {
  const formData = new URLSearchParams(obj);
  // Setar o loading
  dispatch(setAguardar(true));

  try {
    const resp = await axios.get(`${ROTAS[2]}?${formData.toString()}`);
    dispatch(setAguardar(false));

    if (resp.status !== 200) {
      toast(ERRO_STATUS_NAO_200);
      return false;
    }
    if (resp.data.erro) {
      toast(resp.data.erro);
      return false;
    }
    // Recebe os dados e monta e estrutura inicial
    dispatch({
      type: CADASTRO_PROJETOS_GET_HISTORICO,
      data: resp.data,
    });
  } catch (err) {
    toast("ERRO DESCONHECIDO.");
    dispatch(setAguardar(false));
  }
};

// Action para justificar um projeto que se encontra atrasado
export const cadastroProjetosAddJustificativa = (obj) => (dispatch) => {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  dispatch(setAguardar(true));
  // Envia os dados para registro
  axios
    .post(ROTAS[3], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200, OPCOES_TOAST);
        return false;
      }
      // Veja se tem mensagem de erro
      if (resp.data.erro) {
        toast(resp.data.erro, OPCOES_TOAST);
        return false;
      }
      // Exibe um toast informando que deu certo
      toast(resp.data.sucesso, OPCOES_TOAST);
      // Deu tudo certo , vamos atualizar o status do projeto
      dispatch({
        type: CADASTRO_PROJETOS_ADD_JUSTIFICATIVA,
        data: {
          id_projeto: resp.data.data.id_projeto,
          justificativa_x_projeto: resp.data.data.justificativa_x_projeto,
          nova_data_prevista: resp.data.data.nova_data_prevista,
        },
      });
      // Fechar o drawer
      dispatch(cadastroProjetosSetDrawer(null));
    })
    .catch((err) => {
      toast(ERRO_NAO_ENVIADO, OPCOES_TOAST);
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para obter mais dados para a pagina
export const cadastroProjetosProximaPagina =
  (valor, fnNaoRecuperar) => async (dispatch) => {
    dispatch(setAguardar(true));
    try {
      const resp = await axios.get(`${ROTAS[0]}?tipo=pagina&valor=${valor}`);

      dispatch(setAguardar(false));

      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200);
        return false;
      }
      if (resp.data.erro) {
        toast(resp.data.erro);
        return false;
      }
      // Se projetos nao tiver dado algum nada deve ser feito
      if (resp.data.projetos.length === 0) {
        // Determina que não se deve mais recuperar dados (esta funcao não deve mais ser chamada)
        if (fnNaoRecuperar) fnNaoRecuperar(true);
        return false;
      }
      // Recebe os projetos e monta e estrutura inicial
      dispatch({
        type: CADASTRO_PROJETOS_PROXIMA_PAGINA,
        data: resp.data,
      });
    } catch (err) {
      dispatch(setAguardar(false));
      toast("ERRO DESCONHECIDO AO TENTAR RECUPERAR OS PROJETOS.");
    }
  };
// Action para solicitar o checklist a ser respondido
export const cadastroProjetosSetChecklist =
  (tipo_projeto, id_projeto, fnValidacaoCD) => (dispatch) => {
    const formData = new URLSearchParams({
      tipo: tipo_projeto,
      id_projeto: id_projeto,
    });
    dispatch(setAguardar(true));
    // Solicitar o checklist
    axios
      .get(`${ROTAS[4]}?${formData.toString()}`)
      .then((resp) => {
        if (resp.status !== 200) {
          toast(ERRO_STATUS_NAO_200);
          return false;
        }
        if (resp.data.erro) {
          toast(resp.data.erro);
          return false;
        }
        // Primeiro verificar se tem o campo id_check_list_projeto, isto determina que há checklist pendente de resposta
        if (
          resp.data.hasOwnProperty("id_checklist_projeto") &&
          resp.data.hasOwnProperty("nota")
        ) {
          // Tudo certo, temos a nota e o id_checklist_projeto
          // Devemos determinar se aprovar ou reprovar
          dispatch(
            cadastroProjetosSetDrawer({
              tipo: VALORES_DRAWER.CHECKLIST_RESPONDIDO,
              valor: {
                nota: resp.data.nota,
                id_checklist_projeto: resp.data.id_checklist_projeto,
              },
            })
          );
          return false;
        }
        // Quer dizer que não tem checklist a ser respondido
        if (Object.keys(resp.data).length < 1) {
          const confirmado = window.confirm(
            "Não existe checklist para este tipo de projeto.\nDeseja continuar ?"
          );
          if (confirmado) {
            // Chama o metodo para exibir a possibilidade de alteração do status para validação CD
            fnValidacaoCD();
          } else {
            // Exibir uma mensagem no toast dizendo que o status prossegue no FI
            toast.info(
              "Você escolheu não prosseguir. Mantido no status VALIDAÇÃO FI"
            );
          }
          return false;
        }
        // Recebe os dados e registra as perguntas para serem respondidas ao checklist
        dispatch(
          cadastroProjetosSetDrawer({
            tipo: VALORES_DRAWER.RESPONDER_CHECKLIST,
            valor: resp.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error("ERRO DESCONHECIDO AO TENTAR RECUPERAR OS PROJETOS.");
      })
      .finally(() => {
        dispatch(setAguardar(false));
      });
  };
// Action para definir se o checklist deve ser aprovado ou nao
export const cadastroProjetosResponderChecklist = (obj) => (dispatch) => {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));
  // Agora coloca o status aguardar
  dispatch(setAguardar(true));
  // Envia os dados para validar o checklist
  axios
    .post(ROTAS[4], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se retornou alguma menagem derro
      if (resp.data.erro) {
        toast.error(resp.data.erro);
        return false;
      }
      // Tudo certo, temos a nota e o id_checklist_projeto que determina se vamos aprovar ou não
      // Precisamos atualizar o estado para que no front o dialog pergunte se desejamos aprovar ou reprovar
      dispatch(
        cadastroProjetosSetDrawer({
          tipo: VALORES_DRAWER.CHECKLIST_RESPONDIDO,
          valor: {
            nota: resp.data.nota,
            id_checklist_projeto: resp.data.id_checklist_projeto,
          },
        })
      );
    })
    .catch((err) => {
      toast.error(ERRO_NAO_ENVIADO);
      return false;
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para enfim determinar se o checklist foi respondido ou não
export const cadastroProjetosConfirmarChecklist = (obj) => (dispatch) => {
  // Nao sabe a resposta ainda, optou por responder o checklist mais tarde
  if (obj.situacao === "C") {
    toast.info("O checklist se manterá pendente de resposta");
    window.setTimeout(() => window.location.reload(), 4000);
    return false;
  }
  //
  dispatch(setAguardar(true));
  // Definido que o checklist será validado ou nao, devemos transmitir isto
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));
  // Envia os dados para confirmar que o checklist foi aprovado
  axios
    .put(ROTAS[4], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se retornou alguma menagem de erro
      if (resp.data.erro) {
        toast.error(resp.data.erro);
        return false;
      }
      // O processo foi concluido com sucesso, precisamos atualizar a pagina
      toast.success("Resposta confirmada página será recarregada");
      window.setTimeout(() => window.location.reload(), 4000);
    })
    .catch((err) => {
      toast.error(ERRO_NAO_ENVIADO);
      return false;
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};

// Action para recuperar os checklists respondidos baseado no projeto
export const cadastroProjetosGetHistoricoChecklist =
  (id_projeto) => (dispatch) => {
    const formData = new URLSearchParams({ id_projeto: id_projeto });
    dispatch(setAguardar(true));
    // Solicitar o checklist
    axios
      .get(`${ROTAS[5]}?${formData.toString()}`)
      .then((resp) => {
        if (resp.status !== 200) {
          toast.error(ERRO_STATUS_NAO_200);
          return false;
        }
        if (resp.data.erro) {
          toast.error(resp.data.erro);
          return false;
        }
        // Se a lista estiver vazia não existe historico de checklist
        if (resp.data.length < 1) {
          toast.info("NÃO EXISTE CHECKLIST RESPONDIDO PARA ESTE PROJETO");
          return false;
        }
        // A lista de respostas dos checklists relacionado ao projeto
        dispatch(
          cadastroProjetosSetDrawer({
            tipo: VALORES_DRAWER.VER_CHECKLIST_RESPONDIDO,
            valor: resp.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error(ERRO_NAO_ENVIADO);
      })
      .finally(() => {
        dispatch(setAguardar(false));
      });
  };

// Action para solicitar os dados de saving (se existir) { id_projeto: N }
export const cadastroProjetosGetDadosSaving = (obj) => (dispatch) => {
  const formData = new URLSearchParams(obj);
  // Setar o loading
  dispatch(setAguardar(true));

  axios
    .get(`${ROTAS[6]}?${formData.toString()}`)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.error(ERRO_STATUS_NAO_200);
        return false;
      }
      if (resp.data.erro) {
        toast.error(resp.data.erro);
        return false;
      }

      // Recupera os dados de saving e passa para o drawer
      dispatch(
        cadastroProjetosSetDrawer({
          tipo: VALORES_DRAWER.FORM_SAVING,
          valor: resp.data,
        })
      );
    })
    .catch((err) => {
      console.log(err);
      toast.error(ERRO_NAO_ENVIADO);
      dispatch(setAguardar(false));
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Para registrar o cadastro de um saving { id_projeto, id_tipo_saving, valor_custo, valor_beneficio, bc, saving, tipo_bc, }
export const cadastroProjetosAddUpdSaving = (obj) => (dispatch) => {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));
  // Setar o loading
  dispatch(setAguardar(true));

  axios
    .post(ROTAS[6], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.error(ERRO_STATUS_NAO_200);
        return false;
      }
      if (resp.data.erro) {
        toast.error(resp.data.erro);
        return false;
      }
      // Deu certo exibe mensagem de sucesso e limpa o campo do saving
      toast.dark(resp.data.sucesso, { type: "success" });
      // Fecha o dialog do formulario de saving
      dispatch(cadastroProjetosSetDrawer(null));
    })
    .catch((err) => {
      console.log(err);
      toast.error(ERRO_NAO_ENVIADO);
      dispatch(setAguardar(false));
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};

// Action mais complexa que recebe um objeto de parametros e transforma eles em tipo&valor para filtrar as buscas
export const cadastroProjetosGetProjetosFiltroComplexo =
  (obj, setAguardando) => async (dispatch) => {
    setAguardando(true);
    //
    const param = new URLSearchParams();
    // Faz um loop sobre as chaves (que sao os tipos) e apenda os valores (que são os campos valores)
    Object.keys(obj).forEach((key, idx) => {
      if (idx === 0) {
        // Primeiro parametro nao tem identificacao de numero
        param.append("tipo", key);
        param.append("valor", obj[key]);
      } else {
        // Os proximos tem, a contar a partir do 2
        param.append(`tipo${idx + 1}`, key);
        param.append(`valor${idx + 1}`, obj[key]);
      }
    });

    try {
      const resp = await axios.get(`${ROTAS[0]}?${param.toString()}`);
      setAguardando(false);
      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200);
        return false;
      }
      if (resp.data.erro) {
        toast(resp.data.erro);
        return false;
      }
      // Recebe os projetos e monta e estrutura inicial
      dispatch({
        type: CADASTRO_PROJETOS_GET_PROJETOS,
        data: resp.data,
      });
    } catch (err) {
      setAguardando(false);
      toast("ERRO DESCONHECIDO AO TENTAR RECUPERAR OS PROJETOS.");
    }
  };
// Action para solicitar novos dados no filtro avancado
export const cadastroProjetosFiltroAvancado = (obj) => (dispatch) => {
  const formData = new URLSearchParams(obj);
  // Setar o loading
  dispatch(setAguardar(true));
  axios
    .get(`${ROTAS[7]}?${formData.toString()}`)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      // Veja se tem mensagem de erro
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Veja se projetos é menor que 1
      if (resp.data?.projetos?.length < 1) {
        toast.dark("Nenhum dado retornado neste filtro", { type: "warning" });
        return false;
      }
      dispatch(cadastroProjetosSetDrawer(null));
      // Tudo certo, aplica no filtro
      dispatch({
        type: CADASTRO_PROJETOS_FILTRO_AVANCADO,
        data: resp.data,
      });
    })
    .catch((err) => {
      console.log(err);
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
//
export const cadastroProjetosFiltroAvancadoFechar = () => ({
  type: CADASTRO_PROJETOS_FILTRO_AVANCADO_FECHAR,
});
// Action para obter os dados relacionados aos graficos
export const cadastroProjetosGetDadosGraficos = (obj) => (dispatch) => {
  const formData = new URLSearchParams();
  formData.append("planta", obj.join(","));

  dispatch(setAguardar(true));

  axios
    .get(`${ROTAS[8]}?${formData.toString()}`)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Tudo certo
      dispatch({
        type: CADASTRO_PROJETOS_GET_DADOS_GRAFICO,
        data: resp.data,
      });
    })
    .catch((err) => {
      console.log(err);
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para recuperar os membros do projeto para possivel futura alteracao
export const cadastroProjetosGetMembros = (id_projeto) => (dispatch) => {
  const formData = new URLSearchParams();
  formData.append("id_projeto", id_projeto);

  dispatch(setAguardar(true));

  axios
    .get(`${ROTAS[9]}?${formData.toString()}`)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Tudo certo
      dispatch(
        cadastroProjetosSetDrawer({
          tipo: VALORES_DRAWER.ALTERAR_MEMBROS,
          valor: resp.data,
        })
      );
    })
    .catch((err) => {
      console.log(err);
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para atualizar os membros do projeto
export const cadastroProjetosUpdMembrosProjeto = (obj) => (dispatch) => {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  dispatch(setAguardar(true));
  // Envia os dados para registro
  axios
    .put(ROTAS[9], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      // Veja se tem mensagem de erro
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Exibe um toast informando que deu certo
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Deu tudo certo , vamos atualizar o os membros do projeto
      dispatch({
        type: CADASTRO_PROJETOS_UPD_MEMBROS,
        data: resp.data.data,
      });
      // Fechar o drawer
      dispatch(cadastroProjetosSetDrawer(null));
    })
    .catch((err) => {
      toast.dark(ERRO_NAO_ENVIADO, OPCOES_TOAST, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};

// Action para um filtro copmlexo paginado
export const cadastroProjetosGetProjetosFiltroComplexoPaginado =
  (obj, setAguardando, pagina) => async (dispatch) => {
    setAguardando(true);
    //
    const param = new URLSearchParams();
    // Faz um loop sobre as chaves (que sao os tipos) e apenda os valores (que são os campos valores)
    Object.keys(obj).forEach((key, idx) => {
      if (idx === 0) {
        // Primeiro parametro nao tem identificacao de numero
        param.append("tipo", key);
        param.append("valor", obj[key]);
      } else {
        // Os proximos tem, a contar a partir do 2
        param.append(`tipo${idx + 1}`, key);
        param.append(`valor${idx + 1}`, obj[key]);
      }
    });

    param.append("pagina", pagina);

    try {
      const resp = await axios.get(`${ROTAS[0]}?${param.toString()}`);
      setAguardando(false);
      if (resp.status !== 200) {
        toast(ERRO_STATUS_NAO_200);
        return false;
      }
      if (resp.data.erro) {
        toast(resp.data.erro);
        return false;
      }
      // Recebe os projetos e monta e estrutura inicial
      dispatch({
        type: CADASTRO_PROJETOS_GET_PROJETOS,
        data: {
          ...resp.data,
          filtroComplexo: obj,
          pagina,
        },
      });
    } catch (err) {
      setAguardando(false);
      toast("ERRO DESCONHECIDO AO TENTAR RECUPERAR OS PROJETOS.");
    }
  };
