import green from "@mui/material/colors/green";
import yellow from "@mui/material/colors/yellow";
import red from "@mui/material/colors/red";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import GroupsIcon from "@mui/icons-material/Groups";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { createNumberMask } from "text-mask-addons";

// Funcao para recuperar nome do status
export const fnKaizenGetText = (situ, t) => {
  switch (situ) {
    case "A":
      return t("cadastro_projetos.open");
    case "S":
      return t("cadastro_projetos.fi");
    case "C":
      return t("cadastro_projetos.cd");
    case "R":
      return t("cadastro_projetos.reproved");
    case "I":
      return t("cadastro_projetos.canceled");
    case "J":
      return t("cadastro_projetos.justify");
    case "VJ": // Ver a justificativa feita ao projeto
      return t("cadastro_projetos.seeJustify");
    case "VC": // Para ver os checklists e respostas escolhidas
      return t("cadastro_projetos.seeChecklist");
    case "VCD": // Para o botao de Definicao valores CD
      return t("cadastro_projetos.seeValuesCD");
    case "ATM": // Para alteracao de membros
      return t("cadastro_projetos.changeMembers");
    case "RCD": // Retornar para opção de validacao CD
      return t("cadastro_projetos.returnCD");
    default:
      return t("cadastro_projetos.closed");
  }
};
//
export const fnKaizenGetTextSigla = (situ) => {
  switch (situ) {
    case "A":
      return "ABE";
    case "S":
      return "FI";
    case "C":
      return "CD";
    case "R":
      return "REPR";
    case "I":
      return "CANC";
    case "J":
      return "JUST";
    case "VJ": // Ver a justificativa feita ao projeto
      return "V.JUS";
    case "VC": // Para ver os checklists e respostas escolhidas
      return "V.CHECK";
    case "VCD": // Para o botao de Definicao valores CD
      return "V.CD";
    default:
      return "ENC";
  }
};

// Baseado no indice faça a colorizacao correta
export const fnGetColor = (situ) => {
  switch (situ) {
    case "A": // Aberto
      return {
        backgroundColor: green["A100"],
        color: "black",
      };
    case "S": // Validação FI
      return {
        backgroundColor: "#87ceeb",
        color: "black",
      };
    case "C": // Validação CD
      return {
        backgroundColor: yellow["A100"],
        color: "black",
      };
    case "R": // Reprovado
      return {
        backgroundColor: red["A100"],
        color: "black",
      };
    case "I": // Cancelado
      return {
        backgroundColor: red["A400"],
        color: "white",
      };
    default: // 'E' Encerrado
      return {
        backgroundColor: red[500],
        color: "white",
      };
  }
};
// Funcao para saber qual o icone
export const fnGetIcon = (situ) => {
  switch (situ) {
    case "A": // Aberto
      return <RecordVoiceOverIcon />;
    case "S": // Validação FI
      return <QueryBuilderIcon />;
    case "C": // Validação CD
      return <AccountBalanceWalletIcon />;
    case "R": // Reprovado
      return <ThumbDownIcon />;
    case "I": // Cancelado
      return <DoNotDisturbIcon />;
    case "J": // Justificativa
      return <EditIcon />;
    case "VJ": // Ver justificativa
      return <SearchIcon />;
    case "VC": // Ver checklists
      return <FactCheckIcon />;
    case "VCD": // Valores do CD
      return <PriceCheckIcon />;
    case "ATM": // Para alteracao de membro
      return <GroupsIcon />;
    default: // E Encerrado
      return <EngineeringIcon />;
  }
};

// Criando mascara monetaria
export const maskToMoney = createNumberMask({
  prefix: " ",
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2,
  integerLimit: 10,
});
