import React, { useCallback, useEffect } from "react";
import BackgroundRouter from "../../background-router";
import TrainningModalViewer from "./components/trainning-modal-viewer";
import TrainningSideBar from "./components/trainning-side-bar";
import {
  Avatar,
  Container,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectFullInstructors } from "./treinamento-selectors";
import { Body1, Body2, H6, Icone } from "../../../components";
import {
  trainningAllInstructors,
  trainningInit,
  trainningSetModal,
} from "./treinamento-actions";
import { green } from "@mui/material/colors";
import TreinamentoModal from "./treinamento-modal";
import OptionsMenu from "../../../components/options-menu";
import { useTranslation } from "react-i18next";

const TreinamentoInstrutor = () => {
  const dispatch = useDispatch();
  const fullInstructors = useSelector(selectFullInstructors);
  const { t } = useTranslation();

  useEffect(() => {
    if (!fullInstructors || fullInstructors.length === 0) {
      dispatch(trainningInit(() => {}));
      dispatch(trainningAllInstructors(() => {}));
    }
  }, [fullInstructors, dispatch]);

  return (
    <BackgroundRouter>
      <TrainningModalViewer />
      <TrainningSideBar isGoBack />
      <Container maxWidth="md">
        <H6>{t("treinamento.trainningInstructorTitle")}</H6>
        <FabAddInstructor t={t} />
        <List>
          {fullInstructors?.map((item) => (
            <InstrutorItem key={item.id} {...item} t={t} />
          ))}
        </List>
      </Container>
    </BackgroundRouter>
  );
};

const FabAddInstructor = ({ t }) => {
  const dispatch = useDispatch();
  const isMobile = useTheme()?.isMobile;
  const sxFab = {};
  if (isMobile) {
    sxFab.position = "fixed";
    sxFab.right = 16;
    sxFab.bottom = 72;
  } else {
    sxFab.mb = 1;
  }
  const newInstructor = {
    icon: <Icone icone="PersonAdd" />,
    text: t("treinamento.textFabNewInstructor"),
    title: t("treinamento.titleFabNewInstructor"),
    background: green[700],
    foreground: "white",
    onClick: () => {
      dispatch(
        trainningSetModal({
          type: TreinamentoModal.modal.FORM_ADD_INSTRUCTOR,
        })
      );
    },
  };
  return (
    <Stack direction="row-reverse">
      <Fab
        onClick={newInstructor.onClick}
        title={newInstructor.title}
        color="success"
        sx={sxFab}
        variant={isMobile ? "circular" : "extended"}
      >
        {newInstructor.icon}
        {!isMobile && <Body2>{newInstructor.text}</Body2>}
      </Fab>
    </Stack>
  );
};

const InstrutorItem = ({ id, nome, mat, planta, origem, entidade, t }) => {
  const dispatch = useDispatch();

  const onEditInstructor = useCallback(() => {
    dispatch(
      trainningSetModal({
        type: TreinamentoModal.modal.FORM_EDT_INSTRUCTOR,
        data: {
          id,
          nome,
          mat,
          planta,
          origem,
          entidade,
        },
      })
    );
  }, [dispatch, nome, mat, planta, origem, entidade, id]);

  const onDelInstructor = useCallback(() => {
    dispatch(
      trainningSetModal({
        type: TreinamentoModal.modal.FORM_DEL_INSTRUCTOR,
        data: id,
      })
    );
  }, [dispatch, id]);

  const optionsInstructor = [
    {
      icon: "Edit",
      title: t("treinamento.titleEdtInstructor"),
      label: t("treinamento.labelEdtInstructor"),
      onClick: onEditInstructor,
    },
    {
      icon: "Delete",
      title: t("treinamento.titleDelInstructor"),
      label: t("treinamento.labelDelInstructor"),
      onClick: onDelInstructor,
    },
  ];
  return (
    <Paper elevation={2} sx={{ my: 1 }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar src={nome} alt={nome} />
        </ListItemAvatar>
        <ListItemText
          primary={nome}
          secondary={
            <Stack gap={0.1}>
              <Body1>{mat && `Mat: ${mat}`}</Body1>
              <Body1>{planta && `Planta: ${planta}`}</Body1>
              <Body1>{entidade && `Entidade: ${entidade}`}</Body1>
            </Stack>
          }
        />
        <ListItemSecondaryAction>
          <OptionsMenu options={optionsInstructor} />
        </ListItemSecondaryAction>
      </ListItem>
    </Paper>
  );
};

TreinamentoInstrutor.rota = "/treinamento_instrutor";

export default TreinamentoInstrutor;
