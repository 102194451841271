import { useDispatch, useSelector } from "react-redux";
import HocRespostaQuestionario from "./twttp-hoc-response-question";
import RespostasQuestionario from "./twttp-response-questions";
import { useCallback } from "react";
import { twttpAtualizaStatus, twttpFecharDrawer } from "./twttp-actions";
import { selectAguardar } from "./twttp-selectors";
import * as yup from "yup";
import { Container, Divider, Stack } from "@mui/material";
import { EntradaForm, H6, Body2, Confirmar } from "../../../components";
import { getStatusText } from "./twttp-utils";
import { useTranslation } from "react-i18next";

// Componente para fazer a pergunta ao usuario e atualizar o status do twttp
const MoverTwttp = ({ id_twttp, status }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aguardar = useSelector(selectAguardar);
  const fnConfirmar = useCallback(() => {
    dispatch(twttpAtualizaStatus({ id_twttp, status }));
  }, [id_twttp, status, dispatch]);
  const fnCancelar = useCallback(
    () => dispatch(twttpFecharDrawer()),
    [dispatch]
  );
  //
  const schema = [
    {
      type: "textarea",
      minRows: 3,
      label: t("twttp.labelJustifyFormMove"),
      name: "justificativa",
      placeholder: t("twttp.placeholderJustifyFOrmMove"),
      grid: { xs: 12 },
      extraProps: {
        multiline: true,
        minRows: 2,
      },
      counter: true,
    },
  ];
  const schemaMessageError = {
    justificativa: t("twttp.errorJustifyRequiredFormMove"),
  };
  const schemaValidator = yup.object().shape({
    justificativa: yup.string().min(3).required(),
  });
  //
  const fn = (val) => {
    dispatch(
      twttpAtualizaStatus({
        id_twttp,
        status,
        justificativa: val.justificativa,
      })
    );
  };

  return (
    <Container
      disableGutters
      sx={{ pb: 1, maxHeight: "90vh", overflow: "auto" }}
      maxWidth="md"
    >
      <HocRespostaQuestionario
        id_twttp={id_twttp}
        render={(resp) => <RespostasQuestionario resp={resp} />}
      />
      <Stack>
        <Divider sx={{ my: 3 }} />
        {status === "R" ? (
          <>
            <H6>{t("twttp.titleReprovedFormMove")}</H6>
            <Body2 sx={{ mb: 1 }} align="center">
              {t("twttp.subtitleReprovedFormMove")}
            </Body2>
            <EntradaForm
              schema={schema}
              schemaMessageError={schemaMessageError}
              schemaValidator={schemaValidator}
              onSubmit={fn}
              wait={aguardar}
            />
          </>
        ) : (
          <Confirmar
            fnCancelar={fnCancelar}
            fnConfirmar={fnConfirmar}
            titulo={`${t("twttp.titleConfirmFormMove1")} ${getStatusText(
              status,
              t
            )}`}
            subtitulo={`${t("twttp.subtitleConfirmFormMove")}  ${getStatusText(
              status,
              t
            )}`}
          />
        )}
      </Stack>
    </Container>
  );
};

export default MoverTwttp;
