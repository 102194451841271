import axios from 'axios';
import { setAguardar } from './aguardar-actions';
import { fecharAlerta } from './alerta-actions';
// Importar mensagens de erro e alerta
import {  setAlerta, ERRO_STATUS_NAO_200, ERRO_NAO_ENVIADO } from './index';
import { fecharModal, setModal } from './modal-actions';
import { setTrSelecionado } from './tr-selecionado-actions';

const ROTAS = [
    '/manutencao_questionario',
    '/questionario',
    '/manutencao_questionario_opcao',
    
];
// Actions
export const AVALIACAO_GET_DADOS = 'AVALIACAO_GET_DADOS';
export const AVALIACAO_POST = 'AVALIACAO_POST';
export const AVALIACAO_PUT = 'AVALIACAO_PUT';
export const AVALIACAO_DELETE = 'AVALIACAO_DELETE';
export const AVALIACAO_PATCH = 'AVALIACAO_PATCH'; 
export const AVALIACAO_ADD_OPCAO = 'AVALIACAO_ADD_OPCAO'; //Para adicionar uma nova opcao

export const AVALIACAO_VIEW = 'AVALIACAO_VIEW'; // Para visualizar um questionario

// Action creator para recuperar as avaliacoes
export const avaliacaoGETDados = ()=> dispatch => {
    dispatch(setAlerta('AGUARDE, ESTAMOS RECUPERANDO OS DADOS'));
    //
    axios.get(ROTAS[0]).then(resp=>{
        if(resp.status !== 200){
            dispatch(setAlerta(ERRO_STATUS_NAO_200));
            return false;
        }
        // Veja se retornou mensagem de erro
        if(resp.data.hasOwnProperty('erro')){
            dispatch(setAlerta(resp.data.erro));
            return false;
        }
        // Deu tudo certo, vamos fazer o input dos dados
        dispatch({
            type: AVALIACAO_GET_DADOS,
            data: resp.data,
        });
        // Fecha o alerta
        dispatch(fecharAlerta());
    }).catch(err=>{
        console.log(err);
        dispatch(setAlerta(ERRO_NAO_ENVIADO));
    })
}
// Action para criar ou editar um questionario
export const avaliacaoPOSTPut = (obj, editar)=> dispatch => {
    dispatch(setAlerta(null));

    const formData = new FormData();
    formData.append('dados', JSON.stringify(obj));

    const fn = editar ? axios.put : axios.post;

    dispatch(setAguardar(true));
    //
    fn(ROTAS[0], formData).then(resp=>{
        if(resp.status !== 200){
            dispatch(setAlerta(ERRO_STATUS_NAO_200));
            return false;
        }
        // Veja se retornou mensagem de erro
        if(resp.data.hasOwnProperty('erro')){
            dispatch(setAlerta(resp.data.erro));
            return false;
        }
        dispatch(setAlerta(resp.data.sucesso));
        // Deu tudo certo, vamos fazer o input dos dados
        dispatch({
            type: editar ? AVALIACAO_PUT : AVALIACAO_POST,
            data: resp.data.data,
        });
        // Limpa a mensagem de erro
        setTimeout(() => {
            dispatch(fecharModal())
            dispatch(fecharAlerta());
        }, 2000);

    }).catch(err=>{
        console.log(err);
        dispatch(setAlerta(ERRO_NAO_ENVIADO));
    }).finally(()=>{
        dispatch(setAguardar(false));
    });
}
// Action para remover um questionario
export const avaliacaoDELETE = (obj)=> dispatch => {
    if(!window.confirm('TEM CERTEZA QUE DESEJA EXCLUIR ?')) return false;
    dispatch(setAlerta(null));

    const formData = new FormData();
    formData.append('dados', JSON.stringify(obj));
    
    dispatch(setAguardar(true));
    //
    axios.delete(ROTAS[0], {data: formData}).then(resp=>{
        if(resp.status !== 200){
            dispatch(setAlerta(ERRO_STATUS_NAO_200));
            return false;
        }
        // Veja se retornou mensagem de erro
        if(resp.data.hasOwnProperty('erro')){
            dispatch(setAlerta(resp.data.erro));
            return false;
        }
        dispatch(setAlerta(resp.data.sucesso));
        // Deu tudo certo, vamos fazer a remocao da tabela
        dispatch({
            type: AVALIACAO_DELETE,
            data: obj.id_avaliacao,
        });
        // Limpa a mensagem de erro
        setTimeout(() => {
            dispatch(fecharAlerta());
            dispatch(setTrSelecionado(obj.id_avaliacao));
        }, 2000);

    }).catch(err=>{
        console.log(err);
        dispatch(setAlerta(ERRO_NAO_ENVIADO));
    }).finally(()=>{
        dispatch(setAguardar(false));
    });
}
// Action para exibir dados de um formulario
export const avaliacaoView = (id) => dispatch => {
    dispatch(setAlerta('AGUARDE, ESTAMOS RECUPERANDO...'));
    dispatch(setAguardar(true));

    axios.get(`${ROTAS[1]}?cod_avaliacao=${id}`).then(resp=>{
        if(resp.status !== 200){
            dispatch(setAlerta(ERRO_STATUS_NAO_200));
            return false;
        }
        // Veja se tem mensagem de erro
        if(resp.data.erro){
            dispatch(setAlerta(resp.data.erro));
            return false;
        }
        // Tudo certo passa para o modal
        dispatch(setModal({tipo: AVALIACAO_VIEW, dados: resp.data}));
        //
        dispatch(setAlerta('DADOS RECUPERADOS'));
        setTimeout(()=> dispatch(fecharAlerta()) , 2000);

    }).catch(err=>{
        console.log(err);
        dispatch(setAlerta(ERRO_NAO_ENVIADO));
    }).finally(()=>{
        dispatch(setAguardar(false));
    });


}
// Action para duplicar uma avaliacao
export const avaliacaoPatch = obj=> dispatch => {

    if(!window.confirm('TEM CERTEZA QUE DESEJA DUPLICAR ESTE QUESTIONÁRIO ?')) return false;
    dispatch(setAlerta(null));

    const formData = new FormData();
    formData.append('dados', JSON.stringify(obj));

    dispatch(setAguardar(true));
    //
    axios.patch(ROTAS[0], formData).then(resp=>{
        if(resp.status !== 200){
            dispatch(setAlerta(ERRO_STATUS_NAO_200));
            return false;
        }
        // Veja se retornou mensagem de erro
        if(resp.data.hasOwnProperty('erro')){
            dispatch(setAlerta(resp.data.erro));
            return false;
        }
        dispatch(setAlerta(resp.data.sucesso));
        // Deu tudo certo, vamos fazer a remocao da tabela
        dispatch({
            type: AVALIACAO_PATCH,
            data: resp.data.data,
        });
        // Limpa a mensagem de erro
        setTimeout(() => {
            dispatch(fecharAlerta());
            dispatch(setTrSelecionado(obj.id_avaliacao));
        }, 2000);

    }).catch(err=>{
        console.log(err);
        dispatch(setAlerta(ERRO_NAO_ENVIADO));
    }).finally(()=>{
        dispatch(setAguardar(false));
    });

}
// Action para adicionar uma nova opcao
export const avaliacaoAddOpcao = obj => dispatch => {
    dispatch(setAlerta(null));

    const formData = new FormData();
    formData.append('dados', JSON.stringify(obj));

    dispatch(setAguardar(true));
    //
    axios.post(ROTAS[2], formData).then(resp=>{
        if(resp.status !== 200){
            dispatch(setAlerta(ERRO_STATUS_NAO_200));
            return false;
        }
        // Veja se retornou mensagem de erro
        if(resp.data.hasOwnProperty('erro')){
            dispatch(setAlerta(resp.data.erro));
            return false;
        }
        dispatch(setAlerta(resp.data.sucesso));
        // Deu tudo certo, vamos fazer o input dos dados
        dispatch({
            type: AVALIACAO_ADD_OPCAO,
            data: resp.data.data,
        });
        // Limpa a mensagem de erro
        setTimeout(() => {
            dispatch(fecharModal())
            dispatch(fecharAlerta());
        }, 2000);

    }).catch(err=>{
        console.log(err);
        dispatch(setAlerta(ERRO_NAO_ENVIADO));
    }).finally(()=>{
        dispatch(setAguardar(false));
    });
}
