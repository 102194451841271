import React, { useCallback, useEffect } from "react";
import BackgroundRouter from "../../background-router";
import { useFetch } from "../../../hooks";
import { ToastErro } from "../../../utils/utils";
import { useSearchParam, useToggle } from "react-use";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  AnimacaoSemDados,
  Body1,
  H6,
  Icone,
  Logo,
  Pesquisando,
} from "../../../components";
import { format, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTrainningApply,
  selectTrainningData,
  selectTrainningDevelop,
  selectTrainningEfficiency,
  selectTrainningTypeOfCourse,
} from "./treinamento-selectors";
import _ from "lodash";
import { trainningInit } from "./treinamento-actions";
import axios from "axios";
import { TreinamentoStudentsWrapper } from "./treinamento-students-form-add";
import { useTranslation } from "react-i18next";

const TreinamentoView = () => {
  const { t } = useTranslation();
  const id = useSearchParam("id");
  const [btnWait, setBtnWait] = useToggle();
  const { wait, setFetch, error, data } = useFetch("/treinamento_ver", "GET");
  const dataInfo = useSelector(selectTrainningData);
  const dispatch = useDispatch();

  //
  useEffect(() => {
    dispatch(trainningInit());
  }, [dispatch]);

  useEffect(() => {
    // {id: N}
    setFetch({ id });
  }, [id, setFetch]);
  //
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);
  //
  const onDownload = useCallback(async () => {
    if (data) {
      setBtnWait();
      const id = data.treinamento_id.id;
      const url = `/treinamento_pdf/${id}`;
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          const a = document.createElement("a");
          a.href = response.data.pdf;
          a.target = "_blank";
          a.setAttribute(
            "download",
            response.data.pdf.substring(response.data.pdf.lastIndexOf("/") + 1)
          );
          a.click();
        }
      } catch (error) {
        ToastErro(error);
      } finally {
        setBtnWait();
      }
    }
  }, [data, setBtnWait]);

  return (
    <BackgroundRouter>
      <Container maxWidth={false}>
        <TreinamentoGoBack />
        {wait ? (
          <Pesquisando />
        ) : data && dataInfo ? (
          <Stack alignItems="center">
            <Button
              variant="contained"
              color="primary"
              sx={{ mb: 1 }}
              startIcon={
                btnWait ? (
                  <CircularProgress size={20} />
                ) : (
                  <Icone icone="Download" />
                )
              }
              onClick={onDownload}
              disabled={!data || btnWait}
            >
              {t("treinamento.downloadPDF")}
            </Button>
            <TreinamentoStudentsWrapper
              id={data.treinamento_id.id}
              noFormatData
              renderItem={(id, dataStudents) => (
                <TreinamentoInfo
                  dataStudents={dataStudents}
                  item={data?.treinamento_id}
                />
              )}
            />
          </Stack>
        ) : (
          <AnimacaoSemDados titulo={t("treinamento.labelNoDataTrainning")} />
        )}
      </Container>
    </BackgroundRouter>
  );
};

const TreinamentoGoBack = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Stack direction="row" sx={{ my: 1 }} justifyContent="space-between">
      <Button
        color="primary"
        title={t("treinamento.titleBtnGoBack")}
        onClick={() => history.goBack()}
        startIcon={<Icone icone="Undo" />}
        variant="text"
      >
        {t("treinamento.labelBtnGoBack")}
      </Button>

      <div />
    </Stack>
  );
};

const TreinamentoInfo = ({ item, dataStudents }) => {
  const { t } = useTranslation();
  const typeOfTrainning = useSelector(selectTrainningTypeOfCourse);
  const efficiency = useSelector(selectTrainningEfficiency);
  const develop = useSelector(selectTrainningDevelop);
  const applyTrainning = useSelector(selectTrainningApply);

  const borderItem = { border: "1px solid #ccc", p: 1 };
  const borderItemCenter = {
    ...borderItem,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const sxText = {
    fontWeight: "bold",
  };

  const header = [
    "#",
    t("treinamento.tableCellNumber"),
    t("treinamento.tableName"),
    t("treinamento.tableAssigned"),
    t("treinamento.tableAvailable"),
  ];
  const rowsInBlank = 15 - dataStudents.length;
  let rows = [];

  dataStudents.forEach((ele, idx) => {
    rows.push([
      { label: idx + 1 },
      { label: ele.matricula },
      { label: ele.nome },
      { label: ele.assinatura },
      { label: ele.avaliacao },
    ]);
  });

  rows = rows.concat(
    _.times(rowsInBlank, (idx) => [
      { label: idx + 1 + dataStudents.length },
      { label: "" },
      { label: "" },
      { label: "" },
      { label: "" },
    ])
  );
  //
  return (
    <Container maxWidth="lg" id="treinamento_view_list">
      <Paper elevation={3} sx={{ p: 0 }}>
        <Grid container>
          <Grid item xs={4} sx={borderItemCenter}>
            <Logo />
          </Grid>
          <Grid item xs={6} sx={borderItemCenter}>
            <H6>{t("treinamento.titleListOfTrainning")}</H6>
          </Grid>
          <Grid item xs={2} sx={borderItem}>
            <Stack>
              <Stack direction="row" gap={1}>
                <Body1 sx={sxText}>{t("treinamento.labelCodeN")}</Body1>
                <Body1>{item.codigo}</Body1>
              </Stack>
              <Stack direction="row" gap={1}>
                <Body1 sx={sxText}>{t("treinamento.labelData")}</Body1>
                <Body1>
                  {format(parseISO(item.data_treinamento), "dd/MM/yyyy")}
                </Body1>
              </Stack>
            </Stack>
          </Grid>
          {/*  */}
          <Grid item xs={12} sx={borderItem}>
            <Stack>
              <H6>{t("treinamento.titleTypeOfTrainning")}</H6>
              <Stack direction="row" gap={1}>
                {typeOfTrainning?.map((ele) => (
                  <TrainningCheckbox
                    key={ele.id}
                    label={ele.descricao}
                    isChecked={ele.id === item.id_tipo}
                  />
                ))}
              </Stack>
            </Stack>
          </Grid>
          {/*  */}
          <Grid item xs={12} sx={borderItem}>
            <Stack>
              <Body1 sx={sxText}>{t("treinamento.titleEfficiency")}</Body1>
              <Stack direction="row" justifyContent="space-around">
                {efficiency?.map((ele) => (
                  <TrainningCheckbox
                    key={ele.id}
                    label={ele.descricao}
                    isChecked={ele.id === item.id_eficacia}
                  />
                ))}
              </Stack>
              <br />
              <Body1>{t("treinamento.labelEfficiencyInfo")}</Body1>
              <TrainningDotted />
              <TrainningDotted />
            </Stack>
          </Grid>

          <Grid item xs={12} sx={borderItem}>
            <Stack>
              <Stack direction="row" gap={1}>
                <TrainningPlaceholder
                  label={t("treinamento.labelLocale")}
                  value={item.local}
                />
                <TrainningPlaceholder
                  label={t("treinamento.labelDuration")}
                  value={item.duracao}
                />
              </Stack>
              <TrainningPlaceholder
                label={t("treinamento.themeAndAttachs")}
                value={item.temas}
              />
              <TrainningDotted />
              <TrainningDotted />
            </Stack>
          </Grid>

          {/*  */}
          <Grid item xs={12} sx={borderItem}>
            <Stack direction="row" gap={1}>
              <Body1 sx={sxText}>{t("treinamento.trainningName")}</Body1>
              <Body1 sx={sxText}>{t("treinamento.trainningAssigned")}</Body1>
            </Stack>
            <Stack direction="row" gap={2}>
              <TrainningPlaceholder
                label={t("treinamento.trainningProffessor")}
                value={`${item.instrutor?.nome} - ${item.planta}`}
              />
              <Box sx={{ flex: 1 }}>
                <TrainningDotted />
              </Box>
            </Stack>
            <Stack direction="row" gap={1}>
              <TrainningPlaceholder
                label={t("treinamento.trainningPersonal")}
              />
              <Box sx={{ flex: 1 }}>
                <TrainningDotted />
              </Box>
            </Stack>
          </Grid>

          {/*  */}
          <Grid item xs={12} sx={borderItem}>
            <Stack>
              <H6>{t("treinamento.titleDevelop")}</H6>
              <Stack>
                {develop?.map((ele) => (
                  <TrainningCheckbox
                    key={ele.id}
                    label={ele.descricao}
                    isChecked={ele.id === item.treinamento_desenvolvimento}
                  />
                ))}
              </Stack>
              <TrainningTable header={header} rows={rows} />
            </Stack>
          </Grid>
          {/*  */}
          <Grid item xs={12} sx={borderItem}>
            <H6>{t("treinamento.titleDevelopFormattion")}</H6>
            <Stack direction="row">
              <Stack sx={{ flex: 3, mr: 1, borderRight: "1px solid #ccc" }}>
                <Body1>{t("treinamento.subtitleDevelopFormattion1")}</Body1>
                {applyTrainning?.map((ele) => (
                  <TrainningCheckbox
                    key={ele.id}
                    label={ele.descricao}
                    isChecked={ele.id === item.treinamento_aplicavel}
                  />
                ))}
              </Stack>
              <Stack sx={{ flex: 2 }}>
                <Body1>{t("treinamento.subtitleDevelopFormattion2")}</Body1>
                {applyTrainning?.map((ele, idx) =>
                  applyTrainning.length > idx + 1 ? (
                    <TrainningDotted key={ele.id} />
                  ) : null
                )}
                <TrainningDotted />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={borderItem}>
            <TrainningPlaceholder
              label={t("treinamento.labelObservationInfo")}
              value={item.observacao}
            />
            <TrainningDotted />
          </Grid>
          {/*  */}
          <Grid item xs={12} sx={borderItem}>
            <TrainningPlaceholder
              label={t("treinamento.labelDocumentDistribution")}
              value={item.documentos}
            />
            <TrainningDotted />
          </Grid>
          {/*  */}
          <Grid item xs={12} sx={borderItem}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Body1>{t("treinamento.titleMoreEfficiency")}</Body1>
              {[
                { id: "S", descricao: "Sim" },
                { id: "N", descricao: "Não" },
              ].map((ele) => (
                <TrainningCheckbox
                  key={ele.id}
                  label={ele.descricao}
                  isChecked={ele.id === item.mais_eficacia}
                />
              ))}
            </Stack>
          </Grid>
          {/*  */}
          <Grid item xs={12} sx={borderItem}>
            <Body1>{t("treinamento.titleMoreTrainning")}</Body1>
            <Stack direction="row" alignItems="center" gap={1}>
              <Body1>{t("treinamento.titleMoreTrainning2")}</Body1>
              {[
                { id: "S", descricao: "Sim" },
                { id: "N", descricao: "Não" },
              ].map((ele) => (
                <TrainningCheckbox
                  key={ele.id}
                  label={ele.descricao}
                  isChecked={ele.id === item.mais_treinamento}
                />
              ))}
            </Stack>
          </Grid>
          {/*  */}
          <Grid item xs={12} sx={borderItem}>
            <Stack>
              <Stack direction="row" gap={1} alignItems="baseline">
                <Body1 sx={sxText}>{t("treinamento.toApproved")}</Body1>
                <Box sx={{ height: 36, flex: 1 }}>{item.aprovacao}</Box>
              </Stack>
              <Stack direction="row" gap={1} alignItems="baseline">
                <Body1 sx={sxText}>{t("treinamento.toFinish")}</Body1>
                <Box sx={{ height: 36, flex: 1 }}>{item.ecerramento}</Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const TrainningPlaceholder = ({ label, value }) => {
  return (
    <Stack
      alignItems="flex-end"
      sx={{ flex: 1, alignItems: "flex-end" }}
      direction="row"
      gap={1}
    >
      <Body1 sx={{ fontWeight: "bold" }}>{label}</Body1>
      <Box sx={{ flex: 1 }}>
        <TrainningDotted value={value} />
      </Box>
    </Stack>
  );
};

const TrainningDotted = ({ value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        borderBottom: "1px dotted",
        height: 36,
      }}
    >
      {value}
    </Box>
  );
};

const TrainningCheckbox = ({ label, isChecked }) => {
  return (
    <FormControlLabel
      label={label}
      control={<Checkbox size="small" />}
      checked={isChecked}
    />
  );
};

const TrainningTable = ({ header, rows }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {header.map((value) => (
              <TableCell key={value}>{value}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, idx) => (
            <TableRow key={idx}>
              {_.map(item, (k, ix) => (
                <TableCell sx={{ p: 1 }} key={ix}>
                  {k.label}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TreinamentoView.rota = "/treinamento_view";

export default TreinamentoView;
