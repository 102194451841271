import { 
    AVALIACAO_GET_DADOS,
    AVALIACAO_POST, AVALIACAO_PUT, 
    AVALIACAO_DELETE, AVALIACAO_PATCH,
    AVALIACAO_ADD_OPCAO,
} from '../actions';

export default function avaliacaoReducer(state = null, action){
    switch(action.type){
        case AVALIACAO_GET_DADOS: // Para insercao inicial
            return {
                ...action.data,
            }
        case AVALIACAO_POST: // Para a insercao de um novo questionario
            return {
                ...state,
                'avaliacao': [action.data, ...state.avaliacao],
            }
        case AVALIACAO_PUT: // Para uma atualizacao de avaliacao
            return (()=>{
                console.log(action.data);
                const filtro = state.avaliacao.filter(ele=> ele.id.toString() !== action.data.id.toString());
                return {
                    ...state,
                    avaliacao: [ action.data, ...filtro],
                }
            })()
        case AVALIACAO_DELETE: // Para remover um questionario
            return {
                ...state,
                avaliacao: state.avaliacao.filter(ele=> ele.id.toString() !== action.data.toString()),
            }
        case AVALIACAO_PATCH: // Para um novo questionario (ele é resultado de uma duplicação)
            return {
                ...state,
                avaliacao: [...state.avaliacao, action.data ],
            }
        case AVALIACAO_ADD_OPCAO: // Para adicionar uma nova opção para se escolhida no selecionador
            return {
                ...state,
                opcao: [...state.opcao, action.data],
            }
        default:
            return state;
    }
}
