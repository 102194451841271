import { memo, useEffect } from "react";
import { useFetch } from "../../../hooks";
import { ROTA_LIXEIRA } from "./documentos-utils";
import { ToastErro } from "../../../utils/utils";
import { H6 } from "../../../components";
import { List } from "@mui/material";
import ItemArquivoMobile from "./documentos-item-file-mobile";
import { useTranslation } from "react-i18next";

// Component que exibe a lixeira
const Lixeira = memo(({ navegarPara, dispatch, colaboradores }) => {
  const { t } = useTranslation();
  const { data, error, setFetch } = useFetch(ROTA_LIXEIRA, "GET");
  useEffect(() => setFetch({}), [setFetch]);
  // Veja se tem mensagem de erro
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  return (
    <>
      <H6>{t("documentos.titleTrash")}</H6>
      <List disablePadding>
        {data?.map((ele) => (
          <ItemArquivoMobile
            key={ele.id_arquivo}
            {...ele}
            isItemLixeira
            dispatch={dispatch}
            navegarPara={navegarPara}
            colaboradores={colaboradores}
          />
        ))}
      </List>
    </>
  );
});

export default Lixeira;
