import {
    MANUTENCAO_AREA_INIT_AREA,
    MANUTENCAO_AREA_INIT_MODELOS,
    MANUTENCAO_AREA_INIT_AREA_X_MODELO,
    MANUTENCAO_AREA_FECHAR_DRAWER,
    MANUTENCAO_AREA_SET_DRAWER,
    MANUTENCAO_AREA_ALTERAR_AREA,
    MANUTENCAO_AREA_ALTERAR_ATUALIZA_AREA_X_MODELO,
    MANUTENCAO_AREA_ADD_AREA
} from '../actions';

export default function manutencaoAreaReducer(state = null, action){
    switch(action.type){
        case MANUTENCAO_AREA_INIT_AREA: //
            return {
                ...state,
                area: action.data,
            }
        case MANUTENCAO_AREA_INIT_MODELOS:
            return {
                ...state,
                modelos: action.data
            }
        case MANUTENCAO_AREA_INIT_AREA_X_MODELO: //
            return {
                ...state,
                areaXModelo: action.data,
            }
        case MANUTENCAO_AREA_FECHAR_DRAWER: // para fechar o drawer
            return {
                ...state,
                drawer: null
            }
        case MANUTENCAO_AREA_SET_DRAWER: // Para setar o drawer
            return {
                ...state,
                drawer: action.data
            }
        case MANUTENCAO_AREA_ALTERAR_AREA: // Para atualizar uma area
            return {
                ...state,
                area: state.area.map(ele=> {
                    if( String(ele.id) === String(action.data.data.id) ){
                        return action.data.data;
                    }
                    return ele;
                }),
            }
        case MANUTENCAO_AREA_ALTERAR_ATUALIZA_AREA_X_MODELO: // Atualiza areaXModelo
            return {
                ...state,
                areaXModelo: {
                    ...state.areaXModelo,
                    ...action.data.data,
                }
            }
        case MANUTENCAO_AREA_ADD_AREA: // Adiciona uma nova area 
            return {
                ...state,
                area: [action.data.data, ...state.area],
            }
        default:
            return state;
    }
}
