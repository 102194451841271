import fetchRedux from "../../../api/fetch_redux";
import { ToastErro } from "../../../utils/utils";

export const MANUTENCAO_COMPONENTES_INIT = "MANUTENCAO_COMPONENTES_INIT";
export const MANUTENCAO_COMPONENTES_LIMPAR_DADOS =
  "MANUTENCAO_COMPONENTES_LIMPAR_DADOS";
export const MANUTENCAO_COMPONENTES_SET_MODAL =
  "MANUTENCAO_COMPONENTES_SET_MODAL";
export const MANUTENCAO_COMPONENTES_FECHAR_MODAL =
  "MANUTENCAO_COMPONENTES_FECHAR_MODAL";

const ROTAS = ["/manutencao_componentes"];

export const manutencaoComponentesInit = (obj, fnAguardar) => (dispatch) => {
  fetchRedux(
    ROTAS[0],
    "GET",
    obj,
    (resposta) => {
      dispatch({
        type: MANUTENCAO_COMPONENTES_INIT,
        data: resposta,
      });
    },
    () => fnAguardar(),
    (erro) => ToastErro(erro)
  );
};
// Inicializando um novo modal
export const manutencaoComponentesSetModal = (obj) => ({
  type: MANUTENCAO_COMPONENTES_SET_MODAL,
  data: obj,
});
// Fechando dados de um modal
export const manutencaoComponentesFecharModal = () => ({
  type: MANUTENCAO_COMPONENTES_FECHAR_MODAL,
});
//
export const manutencaoComponentesLimparDados = () => ({
  type: MANUTENCAO_COMPONENTES_LIMPAR_DADOS,
});
