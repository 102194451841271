import { useEffect } from "react";
import { useFetch } from "../../../hooks";
import { ToastErro, optTabela } from "../../../utils/utils";
import {
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import { H6, TabelaV2 } from "../../../components";
import { PainelSelecaoAvatar } from ".";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

// Corpo para exibir banco de horas
const BancoDeHoras = ({ selecionado, setSelecionado }) => {
  const { t } = useTranslation();
  const { data, error, wait, setFetch } = useFetch(
    "/minha_equipe_banco_de_horas",
    "GET"
  );

  useEffect(() => {
    if (selecionado) {
      setFetch({
        matricula: selecionado,
      });
    }
  }, [setFetch, selecionado]);

  //
  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);
  return (
    <Container maxWidth={false}>
      <H6>{t("minha_equipe.myEquipBankOfHoursPageTitle")}</H6>
      <PainelSelecaoAvatar
        selecionado={selecionado}
        setSelecionado={setSelecionado}
      />

      {wait ? (
        <Stack sx={{ my: 3 }} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        selecionado &&
        data && (
          <Resultado extrato={data.extrato} saldo_final={data.saldo_final} />
        )
      )}
    </Container>
  );
};

//
const Resultado = ({ extrato, saldo_final }) => {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;

  const optTabelaT = {
    ...optTabela,
    baixar_em_excel: false,
    ocultarFiltro: true,
    //calcularRodape: false,
    data: [0],
    alteraRodape: {
      0: "-",
      1: "-",
      2: t("minha_equipe.myEquipBankOfHoursFinalBalance"),
      3: saldo_final,
    },
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 2 }} disableGutters>
      {isMobile ? (
        <RenderMobile {...extrato} saldo_final={saldo_final} />
      ) : (
        <TabelaV2 cabe={extrato.cabe} corpo={extrato.corpo} {...optTabelaT} />
      )}
    </Container>
  );
};

// Renderiza a visualizacao mobile
const RenderMobile = ({ cabe, saldo_final, corpo }) => {
  const { t } = useTranslation();
  return (
    <List disablePadding disableGutters>
      <ListItem divider>
        <ListItemText align="left">{cabe[0]}</ListItemText>
        <ListItemText align="center">{cabe[1]} &nbsp;&nbsp;&nbsp;</ListItemText>
        <ListItemText align="right">{cabe[3]}</ListItemText>
      </ListItem>
      {corpo.map((ele, idx) => {
        const [data, descricao, tipo, hora] = ele;
        const sinal = tipo?.toLowerCase() === "negativo" ? "-" : "+";

        return (
          <ListItem key={idx} divider>
            <ListItemText align="left">
              {format(parseISO(data), "dd/MM/yy")}
            </ListItemText>
            <ListItemText
              primaryTypographyProps={{ fontSize: "12px" }}
              align="center"
            >
              {descricao}
            </ListItemText>
            <ListItemText align="right">{`${sinal}${hora}`}</ListItemText>
          </ListItem>
        );
      })}
      <ListItem divider>
        <ListItemText
          primaryTypographyProps={{
            color: (theme) => theme.palette.primary.main,
            fontWeight: "bold",
          }}
          align="left"
        >
          {t("minha_equipe.myEquipBankOfHoursFinalBalance")}
        </ListItemText>
        <ListItemText align="right">{saldo_final}</ListItemText>
      </ListItem>
    </List>
  );
};

export default BancoDeHoras;
