import React from 'react'
import { Box, Typography } from '@mui/material';

function RetornoEmBranco(){

    return (
        <Box>
            <Typography align='center' variant='h4'>
                Nossa, não encontramos nada 🤔
            </Typography>
            <Typography align='center' sx={{mt: 2, opacity: .7}} variant='body2'>
                Tente uma pesquisa diferente para ver se conseguimos retornar algum dado. 
            </Typography>
        </Box>
    )
}

export default RetornoEmBranco