import { Paper, Stack } from "@mui/material";
import { AnimacaoSemDados, Subtitle1 } from "../../../components";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";

const defaultOptions = {
  loop: true,
  autoplay: true,
  delay: 1000,
  animationData: "",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

// Clima este mes
const ClimaEsteMes = ({ titulo, percentual, animacao, verQuantidade }) => {
  const { t } = useTranslation();
  const copia = { ...defaultOptions };
  copia.animationData = animacao;

  const percentualGeral = isNaN(percentual) ? 0 : percentual;

  return (
    <Paper elevation={4}>
      <Stack>
        {percentualGeral === 0 ? (
          <>
            <Subtitle1>{t("registro_emocional.subtitleNotFound")}</Subtitle1>
            <AnimacaoSemDados width={120} height={120} />
            <Subtitle1>{t("registro_emocional.subtitleNoFeelings")}</Subtitle1>
          </>
        ) : (
          <>
            <Subtitle1>
              {t("registro_emocional.subtitleBiggestFeeling")}
            </Subtitle1>
            <Lottie
              title={t("registro_emocional.titleCompanyFeels")}
              options={copia}
              width={150}
              height={150}
            />
            <Subtitle1>
              {titulo} {percentualGeral} {!verQuantidade && "%"}
            </Subtitle1>
          </>
        )}
      </Stack>
    </Paper>
  );
};

export default ClimaEsteMes;
