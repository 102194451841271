import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import * as yup from "yup";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { EntradaForm, H6 } from "../../../components";
import { useToggle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { selectCriticidade } from "./helpdesk-seletores";
import { helpdeskUpdcriticality } from "./helpdesk-actions";
import { useTranslation } from "react-i18next";

const FIELD_FORM = {
  criticality: "id_criticidade",
};

function HelpdeskAlterarCriticidade({ idTicket, criticidade, id_criticidade }) {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const listOfCriticality = useSelector(selectCriticidade);

  const schema = [
    {
      type: "select",
      name: FIELD_FORM.criticality,
      label: t("helpdesk.labelCriticality"),
      itens: listOfCriticality,
      isMulti: false,
      autoFormat: true,
      defaultValue: id_criticidade
        ? { value: id_criticidade, label: criticidade, key: id_criticidade }
        : null,
      placeholder: t("helpdesk.labelCriticality"),
    },
  ];

  const schemaMessageError = {
    [FIELD_FORM.criticality]: t("helpdesk.errorCriticality"),
  };

  const schemaValidator = yup.object().shape({
    [FIELD_FORM.criticality]: obterValidador(VALIDADOR_TIPO.selectUnico),
  });
  //
  const onSubmit = useCallback(
    (val) => {
      const obj = {
        id_ticket: idTicket,
        [FIELD_FORM.criticality]: parseInt(val[FIELD_FORM.criticality].value),
      };

      dispatch(helpdeskUpdcriticality(obj, setWait));
    },
    [setWait, dispatch, idTicket]
  );
  //
  return (
    <Stack>
      <H6>{t("helpdesk.titleAlterCritical")}</H6>
      <EntradaForm
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
        wait={wait}
        onSubmit={onSubmit}
      />
    </Stack>
  );
}

export default HelpdeskAlterarCriticidade;
