import React, { memo, useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
//import Fundo from "../../images/fundo.jpg";
import * as yup from "yup";
import {
  Container,
  Stack,
  useTheme,
  useMediaQuery,
  List,
  ListItemButton,
  ListItemText,
  Chip,
  FormControlLabel,
  Switch,
  ListItem,
  Paper,
  TextField,
} from "@mui/material";
import {
  SideBar,
  H6,
  ScrollInfinito,
  AnimacaoSemDados,
  DrawerExibicao,
  Dialog,
  Subtitle1,
  FabExtend,
  Caption,
  BotaoIcone,
  EntradaForm,
  InputBusca,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  manutencaoAreaInit,
  manutencaoAreaFecharDrawer,
  manutencaoAreaSetDrawer,
  manutencaoAreaAtivaDesativaModelo,
  manutencaoAreaUpdArea,
  manutencaoAreaAddArea,
} from "../../../redux/actions";
import { useToggle } from "react-use";
import { yupResolver } from "@hookform/resolvers/yup";
import { sxStyleContainer } from "../../../utils/utils";
import {
  selectDados,
  selectDrawer,
  selectArea,
  selectAreaXModelo,
  selectAreaSelecionada,
  selectModelo,
  selectUsuario,
} from "./manutencao-area-seletores";
import { useTranslation } from "react-i18next";

// Definir o modal
const MODAL = {
  VER_MODELOS: "VER_MODELOS",
  VER_ADD_AREA: "VER_ADD_AREA",
};

function ManutencaoArea() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dados = useSelector(selectDados);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  // UseEffect para obter os dados iniciais
  useEffect(() => {
    dispatch(manutencaoAreaInit());
  }, [dispatch]);

  return (
    <Stack direction="row">
      <RenderDrawer />
      <SideBar barraIntegrada />
      <Container
        disableGutters={isMobile}
        sx={{ mt: 0.5, mb: isMobile ? 7 : 2 }}
        maxWidth="lg"
      >
        <H6>{t("manutencao_area.titleArea")}</H6>
        {dados && dados?.area && dados?.areaXModelo ? (
          <RenderCorpo />
        ) : (
          <AnimacaoSemDados />
        )}
      </Container>
    </Stack>
  );
}
// Renderiza o drawer
const RenderDrawer = memo(() => {
  const drawer = useSelector(selectDrawer);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const dispatch = useDispatch();
  let corpo;
  const fecharDrawer = useCallback(() => {
    dispatch(manutencaoAreaFecharDrawer());
  }, [dispatch]);

  if (drawer) {
    switch (drawer.tipo) {
      case MODAL.VER_MODELOS:
        corpo = <VerDetalhesArea />;
        break;
      case MODAL.VER_ADD_AREA: // Para adicionar uma nova area
        corpo = <FormAddArea />;
        break;
      default:
        break;
    }
  }

  return isMobile ? (
    <DrawerExibicao corpo={corpo} fecharDrawer={fecharDrawer} />
  ) : (
    <Dialog comoSlide corpo={corpo} fecharDialogo={fecharDrawer} />
  );
});

// Renderiza o corpo
const RenderCorpo = memo(() => {
  const { t } = useTranslation();
  const [filtro, setFiltro] = useState(null);
  const dispatch = useDispatch();
  const area = useSelector(selectArea);
  // Faça um filtro sobre o nome da area
  let areaFiltrada = area;
  if (filtro) {
    areaFiltrada = _.filter(
      area,
      (val) => _.toUpper(val.nome).search(_.toUpper(filtro)) !== -1
    );
  }

  return (
    <Stack>
      <Stack direction="row" spacing={1}>
        <InputBusca
          sx={{ flex: 1 }}
          label=""
          desativarPesquisaLenta
          placeholder={t("manutencao_area.searchAreaPlaceholder")}
          setFiltro={setFiltro}
          filtro={filtro}
        />
        <FabExtend
          onClick={() =>
            dispatch(manutencaoAreaSetDrawer({ tipo: MODAL.VER_ADD_AREA }))
          }
        />
      </Stack>
      <ScrollInfinito
        tamanho="83vh"
        itens={areaFiltrada}
        itensPorPagina={20}
        render={(item) => {
          return <RenderAreaItem area={item} />;
        }}
      />
    </Stack>
  );
});
// Renderiza um item (uma area)
const RenderAreaItem = memo(({ area }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const areaXModelo = useSelector(selectAreaXModelo);

  // Verifica se esta area existe e separa a lista de modelos
  const modelos = _.includes(Object.keys(areaXModelo), String(area.id))
    ? areaXModelo[String(area.id)]
    : [];

  const fnVerDetalhesItem = () =>
    dispatch(
      manutencaoAreaSetDrawer({
        tipo: MODAL.VER_MODELOS,
        dados: area.id,
      })
    );

  return (
    <Paper
      elevation={2}
      sx={{
        mr: 1,
        mb: 1,
        mt: 1,
        ml: 0.2,
        // backgroundImage: `url("${Fundo}")`
      }}
    >
      <ListItemButton divider onClick={fnVerDetalhesItem}>
        <ListItemText
          primary={area.nome}
          secondary={
            <Stack
              sx={{ width: "100%", overflow: "auto" }}
              direction="row"
              justifyContent="space-between"
            >
              <Stack sx={{ mt: 1 }} direction="row" spacing={1}>
                {modelos?.map((ele, idx) => (
                  <Chip
                    size="small"
                    key={idx}
                    title={
                      ele.situacao !== "A"
                        ? t("manutencao_area.modelInactive")
                        : t("manutencao_area.modelActive")
                    }
                    label={ele.nome}
                    color={ele.situacao === "A" ? "primary" : "default"}
                    variant={ele.situacao !== "A" ? "outlined" : "filled"}
                  />
                ))}
              </Stack>
              <Stack
                sx={{ ml: 0.5 }}
                alignItems="flex-end"
                direction="row"
                spacing={0.5}
              >
                <Chip
                  size="small"
                  // sx={{ borderRadius: "16px 0 16px 0" }}
                  color={area.situacao === "A" ? "secondary" : "error"}
                  label={
                    area.situacao === "A"
                      ? t("manutencao_area.active")
                      : t("manutencao_area.inactive")
                  }
                />
                <Chip
                  size="small"
                  sx={{ borderRadius: "16px 0 16px 0" }}
                  color="success"
                  label={area.planta}
                />
              </Stack>
            </Stack>
          }
        />
      </ListItemButton>
    </Paper>
  );
});
// Renderiza componente para ver detalhes de uma area
const VerDetalhesArea = () => {
  const { t } = useTranslation();
  const [alterarNome, setAlterarNome] = useToggle(null);
  const dispatch = useDispatch();
  const { areaSelecionada, modelosDaArea } = useSelector(selectAreaSelecionada);
  const modelos = useSelector(selectModelo);
  const isAreaAtiva = areaSelecionada.situacao === "A";
  // Funcao que ativa ou desativa uma area
  const fnAtivaDesativaArea = useCallback(() => {
    dispatch(
      manutencaoAreaUpdArea(
        {
          id: areaSelecionada.id,
          situacao: isAreaAtiva ? "B" : "A", // Se esta ativa envia o desativo, senao envia ativa
        },
        true
      )
    );
  }, [dispatch, isAreaAtiva, areaSelecionada.id]);
  //
  const itens = [
    {
      titulo: t("manutencao_area.titleName"),
      secundario: (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {alterarNome ? (
            <Stack direction="row" spacing={0.5} flex={1}>
              <BotaoIcone
                title={t("manutencao_area.titleBtnIconCancel")}
                icone="EditOff"
                onClick={setAlterarNome}
              />
              <RenderFormAlterarNomeArea
                id={areaSelecionada.id}
                fnAlterarEdicao={setAlterarNome}
                nome={areaSelecionada.nome}
              />
            </Stack>
          ) : (
            areaSelecionada.nome
          )}
          {!alterarNome && (
            <BotaoIcone
              title={t("manutencao_area.titleBtnIconEdit")}
              onClick={setAlterarNome}
              icone="Edit"
            />
          )}
        </Stack>
      ),
    },
    {
      titulo: t("manutencao_area.titleSituationArea"),
      secundario: (
        <FormControlLabel
          label={
            isAreaAtiva
              ? t("manutencao_area.titleSituationAreaActive")
              : t("manutencao_area.titleSituationAreaInactive")
          }
          control={
            <Switch onChange={fnAtivaDesativaArea} checked={isAreaAtiva} />
          }
        />
      ),
    },
    {
      titulo: t("manutencao_area.plant"),
      secundario: (
        <Chip
          sx={{ borderRadius: "16px 0 16px 0" }}
          color="primary"
          label={areaSelecionada.planta}
        />
      ),
    },
  ];
  // Funcao de callback para controlar se o modelo deve ficar ativo ou inativo
  const fnAtivaDesativaModelo = useCallback(
    (idModelo, isAtivo) => {
      dispatch(
        manutencaoAreaAtivaDesativaModelo(
          {
            id_area: areaSelecionada.id,
            modelos: [idModelo],
          },
          isAtivo
        )
      );
    },
    [dispatch, areaSelecionada.id]
  );

  return (
    <Container
      sx={{ minHeight: "50vh", maxHeight: "70vh", overflow: "auto", py: 1 }}
      maxWidth="md"
    >
      <Subtitle1>
        {t("manutencao_area.area")} {areaSelecionada.nome}
      </Subtitle1>
      <List disableGutters>
        {itens.map((ele, idx) => (
          <ListItem disablePadding divider key={`area_${idx.toString()}`}>
            <ListItemText primary={ele.titulo} secondary={ele.secundario} />
          </ListItem>
        ))}
        <Paper elevation={0} sx={{ zIndex: 1, position: "sticky", top: 20 }}>
          <Subtitle1 color="primary" fontWeight="bold">
            {t("manutencao_area.listOfModels")}
          </Subtitle1>
        </Paper>
        {modelos.map((ele, idx) => {
          const { id, nome } = ele;
          const isAtivo = _.includes(modelosDaArea, id);
          return (
            <ListItem disablePadding divider key={`modelo_${idx.toString()}`}>
              <ListItemText
                primaryTypographyProps={{
                  color: "info.dark",
                  fontWeight: "bold",
                }}
                primary={`${t("manutencao_area.labelModelName")}: ${nome}`}
                secondary={
                  <Stack
                    alignItems="center"
                    direction="row-reverse"
                    justifyContent="space-between"
                  >
                    <Switch
                      onChange={() => fnAtivaDesativaModelo(id, isAtivo)}
                      checked={isAtivo}
                    />
                    <Caption>
                      {t("manutencao_area.titleModelThis")}
                      <Caption color={isAtivo ? "primary" : "error"}>
                        {isAtivo
                          ? t("manutencao_area.titleModelActive")
                          : t("manutencao_area.titleModelInactive")}
                      </Caption>{" "}
                      {t("manutencao_area.titleModelThis2")}
                    </Caption>
                  </Stack>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};
// Este componente exibe  a opcao de salvar a edicao do nome da area
const RenderFormAlterarNomeArea = ({ id, nome, fnAlterarEdicao }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schemaValidator = yup.object().shape({
    nome: yup.string().min(3).required(),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nome: nome,
    },
    resolver: yupResolver(schemaValidator),
  });
  //
  const fn = (val) => {
    dispatch(
      manutencaoAreaUpdArea({
        id: id,
        nome: val.nome,
      })
    );
    fnAlterarEdicao();
  };

  return (
    <Stack alignItems="center" flex={1} direction="row">
      <Controller
        //defaultValue={nome}
        name="nome"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            autoFocus
            sx={{ flex: 1 }}
            variant="standard"
            size="small"
            fullWidth
            type="text"
            {...field}
            placeholder={t("manutencao_area.placeholderAreaName")}
            error={!!errors.nome}
            helperText={!!errors.nome && t("manutencao_area.errorAreaName")}
          />
        )}
      />
      <BotaoIcone color="primary" onClick={handleSubmit(fn)} icone="Save" />
    </Stack>
  );
};
// Formulario para adicionar uma nova area
const FormAddArea = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const planta = useSelector(selectUsuario).planta;
  console.log(planta);
  //
  const schema = [
    {
      name: "nome",
      label: t("manutencao_area.labelArea"),
      placeholder: t("manutencao_area.placeholderAreaName"),
      defaultValue: "",
    },
  ];
  const schemaValidator = yup.object().shape({
    nome: yup.string().min(3).required(),
  });

  const schemaMessageError = {
    nome: t("manutencao_area.errorAreaMin"),
  };

  const fn = (val) => {
    dispatch(
      manutencaoAreaAddArea({
        nome: val.nome,
        planta,
      })
    );
  };

  return (
    <Container sx={sxStyleContainer} maxWidth="md">
      <Stack>
        <H6>{t("manutencao_area.titleAddArea")}</H6>
        <Caption textAlign="center">
          {t("manutencao_area.subtitleAddArea")}
        </Caption>
        <EntradaForm
          schema={schema}
          schemaValidator={schemaValidator}
          schemaMessageError={schemaMessageError}
          onSubmit={fn}
        />
      </Stack>
    </Container>
  );
};

ManutencaoArea.rota = "/manutencao_area";

export default ManutencaoArea;
