import React, { useCallback, useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  useTheme,
} from "@mui/material";
import Icone from "./icone";

function darkenColor(hex, percent) {
  // Remove o símbolo '#' se existir
  hex = hex.replace(/^#/, "");

  // Converte o valor hexadecimal em RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calcula o novo valor escurecido
  r = Math.floor(r * (1 - percent / 100));
  g = Math.floor(g * (1 - percent / 100));
  b = Math.floor(b * (1 - percent / 100));

  // Converte de volta para hexadecimal
  const newHex =
    "#" +
    ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase();

  return newHex;
}

function getContrastYIQ(hex) {
  // Remove o símbolo '#' se existir
  hex = hex.replace(/^#/, "");

  // Converte o valor hexadecimal em RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calcula o valor de luminosidade
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Retorna 'black' ou 'white' com base na luminosidade
  return yiq >= 128 ? "black" : "white";
}

const Sidebar = ({
  options,
  barColor,
  heightOffset = "52px",
  maxWidthOpen = "30%",
  children,
}) => {
  const isDark = useTheme().palette.mode === "dark";
  const [open, setOpen] = useState(false);

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const sidebarStyle = {
    width: open ? maxWidthOpen : "72px",
    height: `calc(100vh - ${heightOffset})`,
    backgroundColor: !isDark && barColor,
    transition: "width 0.3s ease",
    overflowX: "hidden",
    position: "relative",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
  };

  const sxNoOpen = useCallback(
    (option) => ({
      m: 1,
      "&:hover": {
        backgroundColor: darkenColor(option.background || barColor, 40), // Remove o efeito de hover
      },

      borderRadius: 2,
      background: isDark
        ? darkenColor(option.background || barColor, 5)
        : option.background || darkenColor(barColor, 20),
      color: isDark
        ? darkenColor(option.foreground || barColor, 90)
        : option.foreground || getContrastYIQ(darkenColor(barColor, 20)),
      display: "flex",
      justifyContent: "center",
    }),
    [isDark, barColor]
  );

  return (
    <Paper elevation={4} sx={sidebarStyle}>
      <IconButton
        onClick={toggleSidebar}
        style={{ alignSelf: !open ? "center" : "flex-start" }}
      >
        {open ? <Icone icone="Menu" /> : <Icone icone="Menu" />}
      </IconButton>
      <Divider />
      <List>
        {options.map((option, index) => (
          <ListItemButton
            title={option.title}
            key={index}
            onClick={option.onClick}
            //sx={!open ? sxNoOpen : {}}
            sx={sxNoOpen(option)}
          >
            {open ? (
              <ListItemIcon
                sx={{
                  color: isDark
                    ? darkenColor(option.foreground || barColor, 90)
                    : option.foreground ||
                      getContrastYIQ(darkenColor(barColor, 20)),
                }}
              >
                {option.icon}
              </ListItemIcon>
            ) : (
              option.icon
            )}
            {open && <ListItemText primary={option.text} />}
          </ListItemButton>
        ))}
      </List>
      {open && children}
    </Paper>
  );
};

export default Sidebar;
