import React, { useState } from "react";
import PropTypes from "prop-types";
import { Stack, Chip, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function FiltroAvancado({ onClick, opcoes, children }) {
  const [selecionado, setSelecionado] = useState([]);
  const { t } = useTranslation();

  return (
    <Container sx={{ minHeight: "50vh", maxHeight: "70vh" }} maxWidth="lg">
      <Stack>
        <Typography variant="h6" align="center">
          {t("components.filtro_avancado.title")}
        </Typography>
        <Stack direction="row">
          {opcoes.map((ele, idx) => (
            <Chip
              key={idx}
              sx={{ mx: 0.5 }}
              label={ele.titulo}
              icon={ele.icone}
              color={selecionado.includes(ele.titulo) ? "primary" : "default"}
              onClick={() => {
                setSelecionado((state) => {
                  if (state.includes(ele.titulo)) {
                    const novoValor = state.slice();
                    const index = novoValor.indexOf(ele.titulo);
                    novoValor.splice(index, 1);
                    return novoValor;
                  }
                  return [...state, ele.titulo];
                });
                onClick(ele.titulo);
              }}
            />
          ))}
        </Stack>
        {children}
      </Stack>
    </Container>
  );
}

//
FiltroAvancado.propTypes = {
  opcoes: PropTypes.arrayOf(
    PropTypes.exact({
      titulo: PropTypes.string,
      icone: PropTypes.element,
    })
  ).isRequired,
  children: PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  onClick: PropTypes.func.isRequired,
};
