import React from 'react'
import PropTypes from 'prop-types'
import Icone from './icone-old';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

/**
Este componente permite que mensagens de aviso a alguma rotina entrem na tela vindo do lado esquerdo para o direito,
dando assim um lindo movimento.

 */

const AlertaWrapper = styled(motion.div)`
    z-index: 1002;
    position: fixed;
    bottom: 5%;
    min-width: 25%;
    min-height: 115px;
    padding: 2.5px;
    background: rgb(14,14,14);
    color: white;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 0 2px 16px black;
    background: ${props => props.theme ? props.theme.bg : 'rgb(14,14,14)'};
    color: ${props => props.theme ? props.theme.text : 'white'};
`;

const leftToPosition = {
    hidden: {
        x: '-150vw',
    },
    visible: {
        x: 0,
        transition: {
            duration: .5,
        }
    },
    exit: {
        x: '-150vw',
        transition: {
            duration: .3,
        }
    }
}

class Alerta extends React.Component {
    static defaultProps = {
        className: '',
        fecharTitle: 'CLIQUE AQUI PARA FECHAR O ALERTA',
        visivel: false,
    }

    render(){
        return (
            <AnimatePresence>
                {this.props.visivel && this._getAlerta()}
            </AnimatePresence>
        )
    }
    _getAlerta(){
        const {theme, onFechar, fecharTitle, children} = this.props;    
                       
        return (
            <AlertaWrapper key='alerta' 
                theme={theme}
                variants={leftToPosition}
                initial="hidden"
                animate="visible"
                exit="exit"
                className='Alerta'
            >
                <div style={{textAlign: 'end'}}>
                    <Icone onClick={onFechar} circle 
                        icon='chevron_left' title={fecharTitle} bgColor='#b00022' textColor='#fff' />
                </div>
                {children}
            </AlertaWrapper>
        )
    }
}

Alerta.propTypes = {
    /** Função para fechar o alerta quando o mesmo estiver aberto (Ela deve alterar o valor da props visivel) */
    onFechar: PropTypes.func.isRequired,
    /** Um estilo a ser enviado para o alerta caso necessário */
    style: PropTypes.object,
    /** Uma classe a ser passada para o alerta caso necessário */
    className: PropTypes.string,
    /** Um booleano que determina se o alerta deve ser exibido ou não */
    visivel: PropTypes.bool.isRequired,
}


export default Alerta;