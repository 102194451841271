import { INFO_USUARIO_GET_DADOS } from '../actions';

export default function usuarioReducer(state = null, action){
    switch(action.type){
        case INFO_USUARIO_GET_DADOS:
            return {
                ...action.data
            }
        default:
            return state;
    }
}