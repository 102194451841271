import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { galeriaDeFotosSetDrawer } from "./galeria-de-fotos-actions";
import GaleriaDeFotosModal from "./galeria-de-fotos-modal";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { format, parseISO } from "date-fns";

// Componente para listar os albuns
const ListaDeAlbuns = ({
  planta,
  isEditorFeed,
  setAreaAtual,
  galeriaDeFotos,
}) => {
  return (
    <Stack spacing={0} direction="row" flexWrap="wrap">
      {galeriaDeFotos?.map((ele) => {
        const [matricula, nome] = ele.mat_criador.split(" - ");
        return (
          <Album
            key={ele.id_album}
            assunto={ele.assunto}
            avatar={ele.avatar}
            capa={ele.capa}
            data={ele.data}
            titulo={ele.titulo}
            id_album={ele.id_album}
            nome={nome.trim()}
            matricula={matricula.trim()}
            isEditorFeed={isEditorFeed}
            planta_visivel={ele.planta_visivel}
            setAreaAtual={setAreaAtual}
            total_fotos={ele.total_fotos}
          />
        );
      })}
    </Stack>
  );
};

// Componente que exibe um album
const Album = memo(
  ({
    nome,
    setAreaAtual,
    total_fotos,
    planta_visivel,
    id_album,
    isEditorFeed,
    assunto,
    avatar,
    capa,
    data,
    titulo,
  }) => {
    const [verOpcoes, setVerOpcoes] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
      <Card
        elevation={3}
        sx={{
          m: 1,
          p: 1,
          width: { xs: "100%", md: "calc(47% - 8px )", xl: "calc(30% - 8px)" },
        }}
      >
        <Menu
          anchorEl={verOpcoes}
          open={Boolean(verOpcoes)}
          onClose={() => setVerOpcoes(null)}
        >
          <MenuItem
            title={t("galeria_de_fotos.titleEditAlbum")}
            onClick={() => {
              dispatch(
                galeriaDeFotosSetDrawer({
                  tipo: GaleriaDeFotosModal.modal.EDITAR_ALBUM,
                  dados: {
                    titulo,
                    assunto,
                    id_album,
                    planta_visivel,
                  },
                })
              );

              setVerOpcoes(null);
            }}
          >
            {t("galeria_de_fotos.labelEditAlbum")}
          </MenuItem>
          <MenuItem
            title={t("galeria_de_fotos.titleDelAlbum")}
            onClick={() => {
              dispatch(
                galeriaDeFotosSetDrawer({
                  tipo: GaleriaDeFotosModal.modal.EXCLUIR_ALBUM,
                  dados: id_album,
                })
              );

              setVerOpcoes(null);
            }}
          >
            {t("galeria_de_fotos.labelDelAlbum")}
          </MenuItem>
        </Menu>
        <CardHeader
          action={
            isEditorFeed && (
              <IconButton
                title={t("galeria_de_fotos.titleViewOptions")}
                onClick={(e) => setVerOpcoes(e.currentTarget)}
              >
                <MoreVertIcon color="primary" />
              </IconButton>
            )
          }
          title={titulo}
          titleTypographyProps={{
            align: "center",
          }}
          subheader={assunto}
          subheaderTypographyProps={{
            align: "center",
          }}
        />
        <CardMedia
          title={t("galeria_de_fotos.titleViewPhotosAlbum")}
          onClick={() =>
            setAreaAtual({
              id: id_album,
              nome: titulo,
            })
          }
          sx={{
            cursor: "pointer",
            objectFit: "cover",
          }}
          component="img"
          image={capa}
          height="248"
          alt={titulo}
        />
        <CardContent>
          <Stack direction={{ xs: "column", md: "row" }}>
            <Stack direction="row">
              <Avatar sx={{ mr: 1 }} src={avatar} title={nome}>
                {nome?.substring(0, 1).toUpperCase()}
              </Avatar>
              <Stack>
                <Typography variant="subtitle2">{nome}</Typography>
                <Typography variant="caption" sx={{ opacity: 0.7 }}>
                  {t("galeria_de_fotos.postedOn")}:{" "}
                  {format(parseISO(data), "dd/MM/yy HH:mm")}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              spacing={1}
              sx={{ flex: 1 }}
              direction="row-reverse"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center">
                <PhotoCameraIcon color="primary" />
                <Typography
                  color="primary"
                  title={`${t(
                    "galeria_de_fotos.titleTotalOf"
                  )} ${total_fotos} ${t("galeria_de_fotos.titleInAlbum")}`}
                  fontWeight="bold"
                  variant="body2"
                >
                  ({total_fotos})
                </Typography>
              </Stack>

              {planta_visivel?.map((ele) => (
                <Chip
                  key={ele}
                  label={ele}
                  sx={{ borderRadius: "8px 0 8px" }}
                  color="primary"
                  title={`${t("galeria_de_fotos.titleAlbumVisibleTo")} ${ele}`}
                  variant="filled"
                  size="small"
                />
              ))}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    );
  }
);

export default ListaDeAlbuns;
