import { useCallback, useEffect } from "react";
import { useFetch } from "../../../hooks";
import { Button, CircularProgress, Stack } from "@mui/material";
import { AnimacaoDinheiro, Icone } from "../../../components";
import InformeRendimentos from ".";
import { ToastErro } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const BaixarInformeRendimento = ({ ano }) => {
  const { t } = useTranslation();
  const tituloAnimacao = t("informe_rendimentos.titleAnimation");
  const tituloBotao = `${t("informe_rendimentos.titleButton")} ${ano} `;
  const rotuloBotao = `${t("informe_rendimentos.labelButton")} ${ano}`;
  const { wait, data, error, setFetch } = useFetch(InformeRendimentos.rota);
  //
  const baixarInformeAno = useCallback(() => {
    setFetch({ ano });
  }, [ano, setFetch]);
  //
  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);
  //
  useEffect(() => {
    if (data) {
      // Tudo certo cria o download
      const link = document.createElement("a");
      link.href = data;
      link.download = `informe_${ano}.pdf`;
      link.target = "_blank";
      link.click();
    }
  }, [data, ano]);

  return (
    <Stack alignItems="center">
      <AnimacaoDinheiro titulo={tituloAnimacao} />
      <br />
      <Button
        disabled={wait}
        variant="contained"
        onClick={baixarInformeAno}
        startIcon={
          wait ? <CircularProgress size={20} /> : <Icone icone="Download" />
        }
        title={tituloBotao}
      >
        {rotuloBotao}
      </Button>
    </Stack>
  );
};

export default BaixarInformeRendimento;
