import React from "react";
import { TabelaV2 } from "../../../components";
import { optTabela } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { budgetTableAnalyticSelector } from "./budget-selectors";

function AnalyticPage() {
  const dataTable = useSelector(budgetTableAnalyticSelector);
  //
  const optTabelaT = {
    ...optTabela,
    tamanho: "75vh",
    styleCabe: {
      ...optTabela.styleCabe,
      backgroundColor: "#012258",
      color: "white",
    },

    baixar_em_excel: null,
    monetario: [12, 13],
  };
  return (
    <>
      <br />
      <TabelaV2
        cabe={dataTable.header}
        corpo={dataTable.body}
        {...optTabelaT}
      />
    </>
  );
}

export default AnalyticPage;
