import { ContainerAdaptavel } from "../../../extra-components";
import ManutencaoComponentesFormAddComponente from "./manutencao-componentes-form-add";

const ManutencaoComponentesModal = ({ modal }) => {
  let corpo;
  switch (modal.tipo) {
    case ManutencaoComponentesModal.modal.ADD_COMPONENTE:
      corpo = <ManutencaoComponentesFormAddComponente />;
      break;
    default:
      break;
  }

  return <ContainerAdaptavel>{corpo}</ContainerAdaptavel>;
};

ManutencaoComponentesModal.modal = {
  ADD_COMPONENTE: "ADD_COMPONENTE",
};

export default ManutencaoComponentesModal;
