import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Container,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Collapse,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { format, parseISO } from "date-fns";
import { ThumbUp, Close as CloseIcon } from "@mui/icons-material";
import Lottie from "react-lottie";

// Animacoes do lottie
import Coracao from "../../../lotties/emoji-coracao.json";
import Chorando from "../../../lotties/emoji-chorando.json";
import Nervoso from "../../../lotties/emoji-nervoso.json";
import Oculos from "../../../lotties/emoji-oculos.json";
import Sorrindo from "../../../lotties/emoji-sorrindo.json";
import Curtir from "../../../lotties/emoji-curtir.json";

import {
  galeriaDeFotosGetCurtidas,
  galeriaDeFotosLimparListaCurtidas,
  galeriaDeFotosDelCurtida,
  galeriaDeFotosAddUpdCurtida,
} from "./galeria-de-fotos-actions";
import { HeaderDefault, RetornoEmBranco } from "../../../components";

//
const defaultOptions = {
  loop: true,
  autoplay: true,
  delay: 1000,
  animationData: "",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
// Array com todas as animacoes disponiveis para curtida
const ANIMACOES = [
  {
    animacao: Curtir,
    tipo: 1,
  },
  {
    animacao: Coracao,
    tipo: 2,
  },
  {
    animacao: Chorando,
    tipo: 3,
  },
  {
    animacao: Sorrindo,
    tipo: 4,
  },
  {
    animacao: Oculos,
    tipo: 5,
  },
  {
    animacao: Nervoso,
    tipo: 6,
  },
];
// Objet com todos os tipos representados por emojis
const TIPOS = {
  1: "👍",
  2: "😍",
  3: "😢",
  4: "😀",
  5: "😎",
  6: "😡",
};

// Componente que exibe as curtidas
const ListaDeCurtida = ({ id_foto }) => {
  const { t } = useTranslation();
  const [exibirTipoCurtida, setExibirTipoCurtida] = useState(null);
  const dispatch = useDispatch();
  const curtidas = useSelector((state) => state?.galeriaDeFotos?.curtidas);
  const usuario = useSelector((state) => state?.usuario);
  const matricula = usuario?.usuario?.matricula;
  const planta = usuario?.usuario?.planta;
  // Veja se você ja tem curtida
  const minhaCurtida = curtidas?.some(
    (ele) => String(ele.mat) === matricula && planta === ele.planta
  )
    ? curtidas?.filter(
        (ele) => String(ele.mat) === matricula && planta === ele.planta
      )[0]
    : null;
  // Contabiliza a quantidade de curtidas por tipo
  const curtidaPorTipo = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
  };
  curtidas?.forEach((ele) => {
    curtidaPorTipo[ele.tipo] += 1;
  });

  useEffect(() => {
    // Obtem a lista de curtidas
    dispatch(galeriaDeFotosGetCurtidas(id_foto));
    return () => dispatch(galeriaDeFotosLimparListaCurtidas());
  }, [id_foto, dispatch]);
  //
  const fnCurtir = () => {
    if (minhaCurtida) {
      dispatch(
        galeriaDeFotosDelCurtida({
          id_foto,
          id_curtida: minhaCurtida.id_curtida,
        })
      );
      setExibirTipoCurtida(false);
      return false;
    }
    setExibirTipoCurtida((state) => !state);
  };

  return (
    <Container
      disableGutters
      sx={{ py: 1, maxHeight: "87vh", width: "100vw" }}
      maxWidth="xs"
    >
      <HeaderDefault>
        <Typography variant="subtitle1" fontWeight="bold" align="center">
          {t("galeria_de_fotos.listOfLikes")}
        </Typography>
      </HeaderDefault>
      <Stack sx={{ my: 1 }} direction="row" justifyContent="center" spacing={1}>
        {curtidaPorTipo &&
          Object.keys(curtidaPorTipo).map((key) => (
            <Typography key={key} variant="body2">
              {TIPOS[key]} ({curtidaPorTipo[key]})
            </Typography>
          ))}
      </Stack>
      <List disablePadding sx={{ mb: 1, overflowY: "auto", Maxheight: "50vh" }}>
        {curtidas?.length < 1 ? (
          <RetornoEmBranco />
        ) : (
          curtidas?.map((ele) => (
            <ListaDeCurtidaItem
              key={ele.id_curtida}
              item={ele}
              minhaCuritda={ele.mat === matricula && planta === ele.planta}
            />
          ))
        )}
      </List>
      <AnimatePresence>
        {exibirTipoCurtida && (
          <motion.div
            key="painel_curtidas"
            initial={{ scale: 0.01 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.01 }}
            transition={{ duration: 0.3 }}
          >
            <PainelDeCurtida
              id_foto={id_foto}
              fnOcultarPainel={() => setExibirTipoCurtida(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <Stack direction="row" justifyContent="center">
        <Button
          onClick={fnCurtir}
          title={t("galeria_de_fotos.titleClickChooseLike")}
          startIcon={
            exibirTipoCurtida ? (
              <CloseIcon />
            ) : minhaCurtida ? null : (
              <ThumbUp />
            )
          }
        >
          {minhaCurtida
            ? `${t("galeria_de_fotos.youLike")} ${TIPOS[minhaCurtida.tipo]}`
            : exibirTipoCurtida
            ? t("galeria_de_fotos.closeLike")
            : t("galeria_de_fotos.like")}
        </Button>
      </Stack>
    </Container>
  );
};

// Componetne que lista uma unica curtida
const ListaDeCurtidaItem = memo(({ item }) => {
  const { nome, id_curtida, avatar, data, tipo } = item;
  const [editar, setEditar] = useState(null);

  const CURTIDA = ANIMACOES.filter((ele) => String(ele.tipo) === String(tipo));

  return (
    <>
      <ListItem divider>
        <ListItemAvatar>
          <Avatar src={avatar}>{nome.substring(0, 1)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={nome}
          secondary={
            <Stack direction="row" alignItems="center">
              <Lottie
                options={{
                  ...defaultOptions,
                  animationData: CURTIDA[0].animacao,
                }}
                height={48}
                width={48}
              />
              <Typography align="right" variant="caption">
                {format(parseISO(data), "dd/MM/yy HH:mm")}
              </Typography>
            </Stack>
          }
        />
      </ListItem>
      <Collapse in={editar} unmountOnExit>
        {editar && (
          <PainelDeCurtida
            id_curtida={id_curtida}
            fnFecharEditar={() => setEditar(false)}
          />
        )}
      </Collapse>
    </>
  );
});

// Componente que representa um painel para curtidas
const PainelDeCurtida = ({ id_foto, fnOcultarPainel }) => {
  const dispatch = useDispatch();

  const fnRegistrarCurtida = (tipo) => {
    dispatch(
      galeriaDeFotosAddUpdCurtida(
        {
          id_foto,
          tipo: tipo,
        },
        false,
        fnOcultarPainel
      )
    );
  };

  return (
    <Paper>
      <Stack
        sx={{ overflowX: "auto", width: "100%" }}
        justifyContent="center"
        direction="row"
        spacing={1}
      >
        {ANIMACOES.map((ele, idx) => {
          const copia = { ...defaultOptions };
          copia.animationData = ele.animacao;
          return (
            <IconButton
              key={idx}
              onClick={() => {
                // Enviando a curtida para registro
                fnRegistrarCurtida(ele.tipo);
              }}
            >
              <Lottie options={copia} height={48} width={48} />
            </IconButton>
          );
        })}
      </Stack>
    </Paper>
  );
};

export default ListaDeCurtida;
