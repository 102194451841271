import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectArvores } from "./colaboradores-select";
import * as yup from "yup";
import { colaboradoresAddUsuario } from "./colaboradores-actions";
import { Container, Divider } from "@mui/material";
import { Caption, EntradaForm, H6 } from "../../../components";

const sxMinMaxHeight = {
  minHeight: "50vh",
  maxHeight: "80vh",
  overflow: "auto",
};

// Renderiza formulario de insercao de colaborador
const FormAddColaboradorAutomatico = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const arvores = useSelector(selectArvores);
  const schema = [
    {
      name: "matricula",
      type: "text",
      label: t("colaboradores.labelMat"),
      placeholder: t("colaboradores.placeholderMat"),
      mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
      defaultValue: "",
    },
    {
      name: "arvore",
      type: "select",
      autoFormat: true,
      itens: arvores,
      label: t("colaboradores.labelThree"),
      placeholder: t("colaboradores.placeholderThree"),
    },
    {
      name: "gestor",
      icon: "Engineering",
      type: "switch",
      label: t("colaboradores.labelIsManager"),
      defaultChecked: false,
    },
  ];
  const schemaValidator = yup.object().shape({
    matricula: yup.string().matches(/\d{6}/).required(),
    arvore: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
  });
  //
  const schemaMessageError = {
    matricula: t("colaboradores.errorMat"),
    arvore: t("colaboradores.errorThree"),
  };
  //
  const fn = (val) => {
    const { matricula, arvore, gestor } = val;
    dispatch(
      colaboradoresAddUsuario({
        matricula,
        arvore: arvore.value,
        gestor: gestor ? "S" : "N",
      })
    );
  };

  return (
    <Container sx={sxMinMaxHeight} maxWidth="md">
      <H6>{t("colaboradores.formAddTitle")}</H6>
      <Caption>{t("colaboradores.formAddDescription")}</Caption>
      <Divider sx={{ my: 1 }} />
      <EntradaForm
        schema={schema}
        onSubmit={fn}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
      />
    </Container>
  );
};

export default FormAddColaboradorAutomatico;
