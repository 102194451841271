import { useState, memo, useCallback, useEffect } from "react";
import styled from "styled-components";
import React from "react";
import Logo from "../images/logo.svg";
import LogoBranca from "../images/logo_branca.svg";
import ImagemFabrica from "../images/fabrica.svg";
import { ptBR } from "date-fns/locale";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import _ from "lodash";
import BannerNiverEmpresa from "../images/tempo_de_empresa.jpg";

import { useHistory } from "react-router-dom";
import {
  Avatar,
  Box,
  Paper,
  Hidden,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Badge,
  Stack,
  useMediaQuery,
  useTheme,
  Container,
  ButtonBase,
  List,
  ListItem,
  Checkbox,
  CircularProgress,
  Button,
} from "@mui/material";
import { Menu } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { toggleMenu } from "../redux/actions";
import getLanguage from "../utils/language";
import Icone from "./icone";
import { Body2, Body1, Caption } from ".";
import { Tab, H6, AnimacaoSemNotificacao } from ".";
import Dialog from "./dialog";
import {
  Aniversariantes,
  ContainerAdaptavel,
  DrawerDialog,
} from "../extra-components";
import { ToastErro } from "../utils/utils";
import axios from "axios";
import { format, formatDistance, parseISO } from "date-fns";
import useSWR from "swr";
import fetchGetMensagem from "../api/fetch_mensagem";
import { useSearchParam, useToggle } from "react-use";
import { useFetch } from "../hooks";
import Linkify from "linkify-react";
import TranslateSwitch from "./translate-switch";

const selectCorBarra = (state) =>
  state?.usuario?.variaveis_globais?.COR_BARRA_SITE;

const selectCorBarraFonte = (state) =>
  state?.usuario?.variaveis_globais?.COR_BARRA_SITE_FONTE;

const autoRefresh =
  window.location.host.search(/^localhost.*/g) !== -1 ? null : 1e30;

const WrapperAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
`;
//
const MODAL = {
  VER_ANIVERSARIANTES: "VER_ANIVERSARIANTES",
};
//
const fetchValidaToken = async (url) => {
  const resp = await axios.get(url);
  return resp.data;
};
// Determina como gerar o elemento de link
const renderLink = ({ attributes, content }) => {
  const { href, ...props } = attributes;
  return (
    <p>
      <Button fullWidth variant="contained" size="small" href={href} {...props}>
        clicar aqui
      </Button>
    </p>
  );
};

const Header = (props) => {
  const sxPaper = SX;
  // Parametro de URL que oculta a barra superior
  const isHidden = useSearchParam("appBarHidden");

  const valorCorBarra = useSelector(selectCorBarra);
  const valorCorBarraFonte = useSelector(selectCorBarraFonte);
  //
  const { data } = useSWR("/salvar_token_mobile", fetchValidaToken);

  // Controla a exibicao do Dialog com o niver de empresa
  const [verNiverEmpresa, setVerNiverEmpresa] = useState(null);
  const [atualizarApp, setAtualizarApp] = useState(null);

  const IDIOMA = getLanguage("pt_br", "/");
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuario);
  const isDarkMode = useTheme().palette.mode === "dark";
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const history = useHistory();
  const [verBotaoBack, setVerBotaoBack] = useState(false);

  // Altera entre exibir e ocultar o botao de volta
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.action === "PUSH") {
        setVerBotaoBack(true);
      } else if (history.action === "POP") {
        setVerBotaoBack(false);
      }
    });
    return unlisten;
  }, [setVerBotaoBack, history]);

  useEffect(() => {
    if (usuario?.usuario?.niver_empresa) {
      setVerNiverEmpresa(true);
    }
  }, [usuario, setVerNiverEmpresa]);

  // Verifica se o usuario tem o token do mobile, se nao tiver exibe mensagem para ele
  useEffect(() => {
    if (data?.erro) {
      setAtualizarApp(data.erro);
    }
  }, [data, setAtualizarApp]);

  // Funcao de callbak que cuida do dialog
  const fnFecharDialog = useCallback(() => {
    setVerNiverEmpresa(false);
    setAtualizarApp(false);
  }, [setVerNiverEmpresa, setAtualizarApp]);

  // Funcao para controlar navegacao atras na pilha
  const onBackRoute = useCallback(() => {
    history.goBack();
  }, [history]);
  //
  const onToggleMenu = useCallback(() => {
    dispatch(toggleMenu());
  }, [dispatch]);

  if (isHidden) {
    sxPaper["display"] = "none";
  }

  return (
    <Paper elevation={5} sx={sxPaper}>
      <Dialog
        sxContent={{ p: 0 }}
        comoSlide
        fecharDialogo={fnFecharDialog}
        corpo={
          verNiverEmpresa ? (
            <Container disableGutters maxWidth="md">
              <img
                width="100%"
                src={BannerNiverEmpresa}
                alt="Banner tempo de empresa"
              />
            </Container>
          ) : atualizarApp ? (
            <Container maxWidth="xs">
              <H6>Informação importante</H6>
              <Divider sx={{ my: 1 }} />
              <Linkify
                as={Body1}
                options={{ target: "_blank", render: renderLink }}
              >
                {atualizarApp}
              </Linkify>
            </Container>
          ) : null
        }
      />
      <Hidden smDown>
        <Box
          sx={{
            alignSelf: "stretch",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "80px",
            backgroundColor: valorCorBarra,
            //backgroundColor: (theme) => theme.palette.primary.dark,
          }}
        >
          <IconButton
            sx={{
              pl: "8px",
              //color: (theme) => theme.palette.primary.contrastText,
              color: valorCorBarraFonte,
            }}
            onClick={onToggleMenu}
            aria-label="menu"
            component="span"
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Hidden>
      <Paper
        elevation={0}
        sx={{
          flex: {
            sx: 0,
            md: 1,
          },
          alignSelf: "stretch",
          borderRadius: "0 0px 30px 0",
          //backgroundColor: (theme) => theme.palette.primary.dark,
          backgroundColor: valorCorBarra,
        }}
      >
        {isMobile && verBotaoBack ? (
          <IconButton sx={{ color: "white" }} onClick={onBackRoute}>
            <Icone icone="ArrowBack" />
          </IconButton>
        ) : (
          <Box sx={{ width: 48, height: 48 }} />
        )}
      </Paper>
      <Box sx={{ ...SXBOX, borderTop: `4px solid ${valorCorBarra}` }}>
        <img
          style={{ marginRight: "4px" }}
          src={isDarkMode ? LogoBranca : Logo}
          height={20}
          alt="LOGO TIBERINA"
        />

        {usuario?.usuario && (
          <HeaderOptions
            setDarkMode={props.setDarkMode}
            isDarkMode={isDarkMode}
            idioma={IDIOMA}
            nome={usuario?.usuario?.nome}
            avatar={usuario?.usuario?.avatar}
          />
        )}
      </Box>
    </Paper>
  );
};

const HeaderOptions = ({ setDarkMode, isDarkMode, idioma, nome, avatar }) => {
  const history = useHistory();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const [modal, setModal] = useState(null);

  const [aniversariantes, setAniversariantes] = useState(null);
  // Funcao de callback que retorna os dados a serem exibidos no modal
  const fnDadosModal = useCallback(() => {
    let corpo;
    if (modal) {
      switch (modal.tipo) {
        case MODAL.VER_ANIVERSARIANTES:
          corpo = <TabAniversariantes />;
          break;
        default:
          break;
      }
    }

    return corpo;
  }, [modal]);
  // Veja se temos aniverariantes
  // Carregando os aniversariantes
  useEffect(() => {
    (async () => {
      //setAguardar(true);
      try {
        const resp = await axios.get(
          `/aniversariantes?mes=${format(new Date(), "MM")}`
        );
        if (resp.status !== 200) {
          ToastErro("Erro ao tentar recuperar aniversariantes");
          return false;
        }
        // Veja se tem mensagem de erro
        if (resp.data?.erro) {
          ToastErro(resp.data.erro);
          return false;
        }
        // Tudo certo incremente os aniversariantes
        const aniversariantesHoje = resp.data.filter(
          (ele) => format(new Date(), "MM-dd") === ele.data
        );
        setAniversariantes(aniversariantesHoje.length);
      } catch (error) {
        ToastErro("ERRO AO TENTAR RECUPERAR");
        console.log(error);
        return false;
      } finally {
        //setAguardar(false);
      }
    })();
  }, [setAniversariantes]);

  const [anchorEl, setAnchorEl] = useState(null);
  // Esta funcao lida com a abertura do menu a direita
  const fnAbrirMenuDireita = (e) => setAnchorEl(e.currentTarget);
  // Esta funcao lida com o fechamento do menu
  const fnFecharMenuDireita = () => setAnchorEl(null);

  const primeiroNome = nome.split(" ").splice(0, 1).join(" ");

  // Alterar entre modo dark/light
  const fnDarkLight = useCallback(() => {
    setDarkMode(!isDarkMode);
    setAnchorEl(null);
  }, [setDarkMode, isDarkMode]);

  return (
    <>
      <DrawerDialog
        fnGetCorpo={fnDadosModal}
        fecharModal={() => setModal(null)}
      />
      <WrapperAvatar>
        {!isMobile && (
          <Caption sx={{ whiteSpace: "nowrap" }}>
            {idioma.saudacao} {primeiroNome} &nbsp;&nbsp;
          </Caption>
        )}

        <Avatar
          sx={{
            width: 36,
            height: 36,
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
          src={avatar}
          title={primeiroNome}
          alt="Imagem do usuario"
        >
          {primeiroNome.substr(0, 1)}
        </Avatar>

        <TranslateSwitch />

        <IconButton
          onClick={() => setModal({ tipo: MODAL.VER_ANIVERSARIANTES })}
          color={!isDarkMode ? "primary" : "default"}
          sx={{ mx: 0.5 }}
        >
          <Badge
            max={9}
            color={!isDarkMode ? "primary" : "info"}
            badgeContent={aniversariantes}
          >
            <Icone icone="Cake" />
          </Badge>
        </IconButton>
        <NotificarChat isDarkMode={isDarkMode} setAnchorEl={setAnchorEl} />

        {!isMobile && (
          <>
            <Notificacoes isDarkMode={isDarkMode} />
            <IconButton
              title="Ativa / Desativa o modo escuro"
              onClick={fnDarkLight}
              color={!isDarkMode ? "primary" : "default"}
            >
              {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
          </>
        )}

        <IconButton
          onClick={fnAbrirMenuDireita}
          color={!isDarkMode ? "primary" : "default"}
          aria-label="submenu"
          component="span"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="submenu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={fnFecharMenuDireita}
        >
          <MenuItem onClick={fnDarkLight}>
            <ListItemIcon>
              {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
            </ListItemIcon>
            <ListItemText>Modo Dark / Light</ListItemText>
          </MenuItem>
          <NotificarChat
            isMenuItem
            isDarkMode={isDarkMode}
            setAnchorEl={setAnchorEl}
          />
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              history.push("/configuracoes");
            }}
          >
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText>{idioma.textoAlterarSenha}</ListItemText>
            <Divider />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              window.location.href = "/logout";
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText>{idioma.textoSair}</ListItemText>
            <Divider />
          </MenuItem>
        </Menu>
      </WrapperAvatar>
    </>
  );
};
// Componente para notificacao de mensagem
const NotificarChat = ({ isMenuItem, isDarkMode, setAnchorEl }) => {
  //
  const history = useHistory();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const [novoChat, setNovoChat] = useState(null);
  const { data } = useSWR("/mensagem", fetchGetMensagem, {
    refreshInterval: autoRefresh,
  });
  useEffect(() => {
    if (data) {
      const resultado = _.filter(
        data,
        (val) => !val?.lida && !val?.minha_mensagem
      );
      if (resultado.length > 0) {
        setNovoChat(resultado.length);
      } else {
        setNovoChat(resultado.length);
      }
    }
  }, [setNovoChat, data]);

  // Navegar para mensagem
  const fnChat = useCallback(() => {
    setAnchorEl(null);
    history.push("/caixa_de_mensagem");
  }, [setAnchorEl, history]);

  return isMenuItem ? (
    <MenuItem
      onClick={() => {
        setAnchorEl(null);
        history.push("/caixa_de_mensagem");
      }}
    >
      <ListItemIcon>
        <Icone icone="Comment" />
      </ListItemIcon>
      <ListItemText>Chat</ListItemText>
      <Divider />
    </MenuItem>
  ) : (
    <ButtonBase sx={{ mx: 0.5, p: 1 }} onClick={fnChat}>
      <Stack direction="row" alignItems="center">
        <Badge
          max={9}
          badgeContent={novoChat}
          color={!isDarkMode ? "primary" : "info"}
        >
          <Icone color={!isDarkMode ? "primary" : "default"} icone="Comment" />
        </Badge>
        {!isMobile && <Body2>&nbsp;Chat</Body2>}
      </Stack>
    </ButtonBase>
  );
};
// Componente para o botao de notificacoes
const Notificacoes = memo(({ isDarkMode }) => {
  const [toggle, setToggle] = useToggle(null);
  const { data, error } = useSWR("/notificacoes", fetchGetMensagem, {
    refreshInterval: autoRefresh,
  });
  // Contar notificacoes nao lidas
  const totalNaoLidas = data
    ? _.filter(data, (val) => val.lida === "N").length
    : 0;
  //
  return (
    <>
      {toggle && (
        <DrawerDialog
          fnGetCorpo={() => (
            <VerNotificacoes erro={error} notificacoes={data} />
          )}
          fecharModal={setToggle}
        />
      )}
      <ButtonBase
        title={
          totalNaoLidas > 0
            ? `${totalNaoLidas} notificaç${
                totalNaoLidas > 1 ? "ões não lidas" : "ão não lida"
              }`
            : "Notificações"
        }
        sx={{ mx: 0.5, p: 1 }}
        onClick={setToggle}
      >
        <Stack direction="row" alignItems="center">
          <Badge
            max={9}
            badgeContent={totalNaoLidas}
            color={!isDarkMode ? "primary" : "info"}
          >
            <Icone
              color={!isDarkMode ? "primary" : "default"}
              icone="NotificationsActive"
            />
          </Badge>
        </Stack>
      </ButtonBase>
    </>
  );
});
// Componente que exibe as notificacoes
const VerNotificacoes = ({ notificacoes, erro }) => {
  const [listaNotificacoes, setListaNotificacoes] = useState(notificacoes);
  //
  const marcarTodasLidas = useCallback(
    () => setListaNotificacoes([]),
    [setListaNotificacoes]
  );
  const isLoading = !notificacoes & !erro;
  //
  const marcarComoLida = useCallback(
    (idNotificacao) => {
      setListaNotificacoes((state) =>
        state.filter((ele) => ele.id_notificacao !== idNotificacao)
      );
    },
    [setListaNotificacoes]
  );

  // const { data, error } = useSWR("/notificacoes", fetchGetMensagem, {
  //   refreshInterval: autoRefresh,
  // });
  // const isLoading = !error && !data;
  // // Recupera todas as notificacoes
  // const listaNotificacoes = data
  //   ? _.map(data, (ele) => ele.id_notificacao)
  //   : [];

  return (
    <ContainerAdaptavel>
      <H6>Lista de notificações não lidas</H6>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {listaNotificacoes?.length === 0 ? (
            <AnimacaoSemNotificacao titulo="Não existem notificações a serem lidas" />
          ) : (
            <List dense>
              <NotificacoesTodasLida
                marcarTodasLidas={marcarTodasLidas}
                listaNotificacoes={listaNotificacoes}
              />
              {listaNotificacoes?.map((ele) => (
                <VerNotificacoesItem
                  key={ele.id_notificacao}
                  {...ele}
                  dataRegistro={ele.data}
                  marcarComoLida={marcarComoLida}
                />
              ))}
            </List>
          )}
        </>
      )}
    </ContainerAdaptavel>
  );
};
//
const NotificacoesTodasLida = memo(
  ({ marcarTodasLidas, listaNotificacoes }) => {
    const { error, setFetch, wait } = useFetch("/notificacoes", "POST");

    useEffect(() => {
      if (error) ToastErro(error);
    }, [error]);
    //
    const marcarLida = useCallback(() => {
      const notificacoes = listaNotificacoes.map((ele) => ele.id_notificacao);
      setFetch({ id_notificacao: notificacoes, marcar_lida: "S" });
      marcarTodasLidas();
    }, [marcarTodasLidas, listaNotificacoes, setFetch]);

    return (
      <Paper sx={{ zIndex: 100, position: "sticky", top: 0 }}>
        <ListItem>
          <ListItemIcon>
            {wait ? <CircularProgress /> : <Checkbox onChange={marcarLida} />}
          </ListItemIcon>
          <ListItemText
            primary={
              <Body1 fontWeight="bold">
                Marcar todas as notificações como lidas
              </Body1>
            }
          />
        </ListItem>
      </Paper>
    );
  }
);
//
const VerNotificacoesItem = memo(
  ({
    lida,
    id_notificacao,
    titulo,
    descricao,
    dataRegistro,
    marcarComoLida,
  }) => {
    const { error, setFetch, wait } = useFetch("/notificacoes", "POST");

    useEffect(() => {
      if (error) ToastErro(error);
    }, [error]);
    //
    const marcarNotificacaoLida = useCallback(() => {
      setFetch({ id_notificacao: [id_notificacao], marcar_lida: "S" });
      marcarComoLida(id_notificacao);
    }, [marcarComoLida, id_notificacao, setFetch]);

    return (
      <ListItem divider>
        <ListItemIcon>
          {wait ? (
            <CircularProgress />
          ) : (
            <Checkbox checked={lida === "S"} onChange={marcarNotificacaoLida} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={titulo}
          secondary={
            <Stack>
              <Body2>{descricao}</Body2>
              <Caption align="right">
                {formatDistance(parseISO(dataRegistro), new Date(), {
                  locale: ptBR,
                  addSuffix: true,
                })}
              </Caption>
            </Stack>
          }
        />
      </ListItem>
    );
  }
);
// Componente que exibe os aniversariantes por tab
const TabAniversariantes = () => {
  const cabe = ["Aniversariante do mês", "Niver de empresa"];
  const corpo = [
    <Aniversariantes />,
    <Aniversariantes
      rota="/aniversariante_empresa"
      isNiverEmpresa
      imagemIcone={ImagemFabrica}
    />,
  ];

  return (
    <Stack sx={{ minHeight: "50vh" }}>
      <Tab cabe={cabe} corpo={corpo} />
    </Stack>
  );
};

const SX = {
  display: "flex",
  alignItems: "center",
  height: "48px",
  width: "100%",
  position: "sticky",
  top: 0,
  zIndex: 10,
};
const SXBOX = {
  //backgroundColor: "white",
  alignSelf: "stretch",
  display: "flex",
  alignItems: "center",
  px: { xs: 0, sm: 3 },
  borderTop: "4px solid #012258",
  flex: {
    xs: 1,
    md: 0,
  },
};

export default memo(Header);
