const backgroundColorDark = "#191919";
const colorDark = "white";
// Estilização do componente select
export const selectStyles = {
  menu: (styles) => ({
    ...styles,
    zIndex: 10,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "black",
  }),
  option: (styles) => ({
    ...styles,
    color: "black",
    fontSize: 14,
  }),
};

export const selectStylesDark = {
  input: (styles) => ({
    ...styles,
    color: colorDark,
  }),
  valueContainer: (styles) => ({
    ...styles,
    backgroundColor: backgroundColorDark,
    color: colorDark,
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: backgroundColorDark,
    color: colorDark,
    border: "1px solid #000000",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    backgroundColor: backgroundColorDark,
    color: colorDark,
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 1000,
    color: colorDark,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: colorDark,
  }),
  multiValue: (styles) => ({
    ...styles,
    color: colorDark,
    backgroundColor: backgroundColorDark,
    border: "1px solid white",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: colorDark,
    backgroundColor: backgroundColorDark,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: colorDark,
    backgroundColor: backgroundColorDark,
    padding: "2px",
    border: "1px solid white",
  }),
  placeholder: (styles) => ({
    ...styles,
    //backgroundColor: backgroundColorDark,
    color: colorDark,
  }),
  menu: (styles) => ({
    ...styles,
    //zIndex: 1000,
    backgroundColor: backgroundColorDark,
    color: colorDark,
  }),
  option: (styles) => ({
    ...styles,
    zIndex: 9999,
    backgroundColor: backgroundColorDark,
    color: colorDark,
    borderBottom: "1px solid #ccc",
  }),
};
