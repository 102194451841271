import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Utils from "../../../utils/utils";
// Calcula os descontos e vencimentos
const TotalValoresContraCheque = ({ totDescontos, totVencimentos }) => {
  const { t } = useTranslation();

  const VALORES = [
    {
      valor: totVencimentos,
      texto: t("contra_cheque.contraChequeVencimentos"),
    },
    { valor: totDescontos, texto: t("contra_cheque.contraChequeDescontos") },
    {
      valor: totVencimentos - totDescontos,
      texto: t("contra_cheque.contraChequeLiquido"),
    },
  ];

  return (
    <Grid container>
      {VALORES.map((ele, idx) => (
        <Grid container justifyContent="space-between" key={idx} xs={12}>
          <Typography component="span" variant="subtitle2">
            {ele.texto}
          </Typography>
          <Typography component="span" variant="caption">
            {Utils.converter(parseFloat(ele.valor).toFixed(2))}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default TotalValoresContraCheque;
