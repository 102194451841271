import {
  Button,
  Container,
  ImageListItem,
  ImageListItemBar,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";

// Funcao para chamar o download da imagem
function download(fileUrl, fileName) {
  var a = document.createElement("a");
  a.href = fileUrl;
  a.setAttribute("download", fileName);
  a.click();
}

// Component para ver a foto
const VerFoto = ({ callBack, legenda, anexo, idx }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  // Recupera o objeto de fotos
  const fotos = useSelector((state) => state?.galeriaDeFotos?.fotos);
  let recuo = null;
  let avanco = null;
  if (fotos) {
    // Verifica qual o indice da foto, se for maior que 0 salva os objetos para colocar o recuo
    recuo = idx > 0 ? { ...fotos[idx - 1], idx: idx - 1 } : null;
    // Se o indice for menor que fotos.length - 1 entao coloca o avanco
    avanco =
      idx < fotos.length - 1 ? { ...fotos[idx + 1], idx: idx + 1 } : null;
  }
  //
  // Evento para controlar avanco/recuo
  const controleVerFotos = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        // Fechar a tela
        callBack(null);
      } else if (e.keyCode === 39) {
        if (avanco) callBack(avanco);
        callBack(avanco);
      } else if (e.keyCode === 37) {
        if (recuo) callBack(recuo);
      }
    },
    [callBack, avanco, recuo]
  );
  // UseEffect para ligar evento do teclado de avanco/recuo
  useEffect(() => {
    window.addEventListener("keyup", controleVerFotos);
    // Limpa o evento
    return () => window.removeEventListener("keyup", controleVerFotos);
  }, [controleVerFotos]);

  return (
    <AnimatePresence>
      <Stack sx={{ height: "100vh" }}>
        {isMobile && (
          <VerFotoBarraAcao
            isMobile={isMobile}
            avanco={avanco}
            recuo={recuo}
            callBack={callBack}
            anexo={anexo}
          />
        )}
        <Stack sx={{ flex: 1 }} justifyContent="center" alignItems="center">
          <motion.div
            initial={{ opacity: 0.01 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.01 }}
            key={idx}
          >
            <ImageListItem>
              <img
                style={{
                  width: "100%",
                  objectFit: "contain",
                  maxHeight: "90vh",
                }}
                src={`${anexo}`}
                alt={t("galeria_de_fotos.altPhoto")}
              />
              <ImageListItemBar
                title={legenda || t("galeria_de_fotos.titlePhotoOfAlbum")}
              />
            </ImageListItem>
          </motion.div>
        </Stack>
        {!isMobile && (
          <VerFotoBarraAcao
            isMobile={isMobile}
            avanco={avanco}
            recuo={recuo}
            callBack={callBack}
            anexo={anexo}
          />
        )}
      </Stack>
    </AnimatePresence>
  );
};

// Componente para a barra de acoes
const VerFotoBarraAcao = ({ callBack, avanco, recuo, isMobile, anexo }) => {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ y: -200 }}
      animate={{ y: 0 }}
      exit={{ y: -200 }}
      transition={{ duration: 3 }}
    >
      <Container maxWidth="md">
        <Stack
          sx={{ backgroundColor: "rgba(0,0,0)", width: "100%", mb: 2 }}
          direction="row"
          justifyContent={{ xs: "space-between", md: "space-evenly" }}
        >
          {recuo ? (
            <Button
              onClick={() => callBack(recuo)}
              sx={{ color: "white" }}
              startIcon={<ChevronLeftIcon sx={{ color: "white" }} />}
            >
              {!isMobile && t("galeria_de_fotos.goBack")}
            </Button>
          ) : (
            <Button />
          )}
          <Button
            onClick={() => download(anexo, "ANEXO.jpg")}
            sx={{ color: "white" }}
            startIcon={<DownloadIcon sx={{ color: "white" }} />}
          >
            {!isMobile && t("galeria_de_fotos.downloadPhoto")}
          </Button>
          <Button
            onClick={() => callBack(null)}
            sx={{ color: "white" }}
            startIcon={<CloseIcon sx={{ color: "white" }} />}
          >
            {!isMobile && t("galeria_de_fotos.closePhoto")}
          </Button>
          {avanco ? (
            <Button
              onClick={() => callBack(avanco)}
              sx={{ color: "white" }}
              startIcon={<ChevronRightIcon sx={{ color: "white" }} />}
            >
              {!isMobile && t("galeria_de_fotos.nextPhoto")}
            </Button>
          ) : (
            <Button />
          )}
        </Stack>
      </Container>
    </motion.div>
  );
};

export default VerFoto;
