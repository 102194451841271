import { MANUTENCAO_MODELO_INIT, MANUTENCAO_MODELO_ADD } from '../actions';
import { MANUTENCAO_MODELO_FECHAR_DRAWER, MANUTENCAO_MODELO_SET_DRAWER, MANUTENCAO_MODELO_UPD, MANUTENCAO_MODELO_UPD_SITUACAO } from '../actions/manutencao-modelo-actions';

export default function manutencaoModeloReducer(state = null, action){
    switch(action.type){
        case MANUTENCAO_MODELO_INIT:
            return {
                ...state,
                modelos: action.data,
            }
        case MANUTENCAO_MODELO_SET_DRAWER:
            return {
                ...state,
                drawer: action.data,
            }
        case MANUTENCAO_MODELO_FECHAR_DRAWER:
            return {
                ...state,
                drawer: null
            }
        case MANUTENCAO_MODELO_ADD: 
            return {
                ...state,
                modelos: [
                    action.data.data,
                    ...state.modelos,
                ]
            }
        case MANUTENCAO_MODELO_UPD: // Atualizar o nome
        case MANUTENCAO_MODELO_UPD_SITUACAO: // Atualiza a situacao do modelo
            return {
                ...state,
                modelos: state.modelos.map(ele=>{
                    if(String(ele.id) === String(action.data.data.id) ){
                        return action.data.data;
                    }
                    return ele;
                })
            }
        default:
            return state;
    }

}