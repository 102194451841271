import { Confirmar } from "../../../components";
import { ContainerAdaptavel } from "../../../extra-components";
import EditarNomeArquivo from "./documentos-edit-name-file";
import EditarVisibilidade from "./documentos-edit-visibility";
import CriarPasta from "./documentos-new-folder";
import Lixeira from "./documentos-trash";
import UploadNovosArquivos from "./documentos-upload-new-files";
import { ACTIONS, fnExcluirDaLixeira, fnExcluirItem } from "./documentos-utils";
import VerDetalhesArquivo from "./documentos-view-file";
import VerPDF from "./documentos-view-pdf";
import { useTranslation } from "react-i18next";

// Funcao para retornar o modal correto
const DocumentosModal = ({ modal, dispatch, navegarPara }) => {
  const { t } = useTranslation();
  switch (modal.tipo) {
    case DocumentosModal.modal.RENOMEAR:
      return (
        <ContainerAdaptavel>
          <EditarNomeArquivo {...modal.dados} dispatch={dispatch} />
        </ContainerAdaptavel>
      );
    case DocumentosModal.modal.EXCLUIR: // Excluir arquivo/diretoorio
      return (
        <ContainerAdaptavel sx={{ minHeight: "50vh" }}>
          <Confirmar
            titulo={t("documentos.titleConfirmDelete")}
            subtitulo={t("documentos.subtitleConfirmDelete")}
            fnCancelar={() => dispatch({ type: ACTIONS.FECHAR_MODAL })}
            fnConfirmar={() => {
              const formData = { id_arquivo: modal.dados.id_arquivo };

              fnExcluirItem(
                formData,
                () => {
                  dispatch({ type: ACTIONS.DEL, data: modal.dados.id_arquivo });
                  dispatch({ type: ACTIONS.FECHAR_MODAL });
                },
                t
              );
            }}
          />
        </ContainerAdaptavel>
      );
    case DocumentosModal.modal.DETALHES: // Detalhes do arquivo
      return <VerDetalhesArquivo {...modal.dados} />;
    case DocumentosModal.modal.VISIBILIDADE: // Altera a visibilidade
      return (
        <ContainerAdaptavel sx={{ minHeight: "50vh" }}>
          <EditarVisibilidade {...modal.dados} dispatch={dispatch} />
        </ContainerAdaptavel>
      );
    case DocumentosModal.modal.CRIAR_PASTA: // Criar pasta
      return (
        <ContainerAdaptavel sx={{ minHeight: "50vh" }}>
          <CriarPasta {...modal.dados} />
        </ContainerAdaptavel>
      );
    case DocumentosModal.modal.ENVIAR_ARQUIVO: // Faz upload de novos arquivos
      return (
        <ContainerAdaptavel sx={{ minHeight: "50vh" }}>
          <UploadNovosArquivos {...modal.dados} />
        </ContainerAdaptavel>
      );

    case DocumentosModal.modal.ACESSAR_LIXEIRA: // Acessar a lixeira
      return (
        <ContainerAdaptavel sx={{ minHeight: "50vh" }}>
          <Lixeira
            dispatch={dispatch}
            navegarPara={navegarPara}
            {...modal.dados}
          />
        </ContainerAdaptavel>
      );
    case DocumentosModal.modal.EXCLUIR_PERMANENTEMENTE: // Exclui o item para sempre da lixeira
      return (
        <ContainerAdaptavel sx={{ minHeight: "50vh" }}>
          <Confirmar
            titulo={t("documentos.titleConfirmDelete")}
            subtitulo={t("documentos.subtitleConfirmDeleteFile")}
            fnCancelar={() => dispatch({ type: ACTIONS.FECHAR_MODAL })}
            fnConfirmar={() => {
              const formData = { id_arquivo: modal.dados.id_arquivo };

              fnExcluirDaLixeira(formData, () => {
                dispatch({ type: ACTIONS.FECHAR_MODAL });
              });
            }}
          />
        </ContainerAdaptavel>
      );
    case DocumentosModal.modal.VER_PDF:
      return (
        <ContainerAdaptavel>
          <VerPDF pdf={modal.dados} />
        </ContainerAdaptavel>
      );
    default:
      return null;
  }
};

DocumentosModal.modal = {
  RENOMEAR: "RENOMEAR",
  EXCLUIR: "EXCLUIR",
  DETALHES: "DETALHES",
  ACESSAR_LIXEIRA: "ACESSAR_LIXEIRA",
  EXCLUIR_PERMANENTEMENTE: "EXCLUIR_PERMANENTEMENTE",
  VISIBILIDADE: "VISIBILIDADE",
  CRIAR_PASTA: "CRIAR_PASTA",
  ENVIAR_ARQUIVO: "ENVIAR_ARQUIVO",
};

export default DocumentosModal;
