import axios from 'axios';
import { toast } from 'react-toastify';
import { ERRO_NAO_ENVIADO, ERRO_STATUS_NAO_200, setAguardar } from '.';

const ROTAS = [
    '/get_cartao_seguranca',
    '/cartao_seguranca',
    '/cartao_seguranca_justificativa',
    '/cartao_seguranca_validacao',
];

export const CARTAO_SEGURANCA_GET_DADOS = 'CARTAO_SEGURANCA_GET_DADOS';
export const CARTAO_SEGURANCA_GET_CARTAO = 'CARTAO_SEGURANCA_GET_CARTAO';
export const CARTAO_SEGURANCA_SET_DRAWER = 'CARTAO_SEGURANCA_SET_DRAWER'; // Para exibir o drawer
export const CARTAO_SEGURANCA_FECHAR_DRAWER = 'CARTAO_SEGURANCA_FECHAR_DRAWER'; // Para fecharo drawer
export const CARTAO_SEGURANCA_ADD = 'CARTAO_SEGURANCA_ADD'; // Para adicionar um cartao de seguranca
export const CARTAO_SEGURANCA_UPD_EM_ANALISE = 'CARTAO_SEGURANCA_UPD_EM_ANALISE'; // Para mover um cartao para ostatus em analise
export const CARTAO_SEGURANCA_ADD_JUSTIFICATIVA = 'CARTAO_SEGURANCA_ADD_JUSTIFICATIVA'; // Para definir uma justificativa ao cartao
export const CARTAO_SEGURANCA_ADD_VALIDACAO = 'CARTAO_SEGURANCA_ADD_VALIDACAO'; // Para mover o cartao de incluso a aberto ou validacao
export const CARTAO_SEGURANCA_SET_FILTRO_STATUS = 'CARTAO_SEGURANCA_FILTRO_STATUS'; // Para inserir um filtro por status
export const CARTAO_SEGURANCA_SET_FILTRO_TIPO_CARTAO = 'CARTAO_SEGURANCA_SET_FILTRO_TIPO_CARTAO'; // Para inserir um filtro por tipo de cartao


export const cartaoSegurancaGetDados = () => dispatch => {
    dispatch(setAguardar(true));
    const formData = new URLSearchParams();
    formData.append('tipo', 'dados');
    //
    axios.get(`${ROTAS[0]}?${formData.toString()}` ).then(resp=>{
        if(resp.status !== 200){
            toast.dark(ERRO_STATUS_NAO_200, {
                type: 'error'
            });
            return false;
        }
        // Veja se tem mensagem de erro
        if(resp.data.erro){
            toast.dark(resp.data.erro, {
                type: 'error'
            });
            return false;
        }
        // Tudo certo atualizar os dados
        dispatch({
            type: CARTAO_SEGURANCA_GET_DADOS,
            data: resp.data,
        });

    }).catch(err=>{
        console.log(err);
        toast.dark(ERRO_NAO_ENVIADO, {
            type: 'error'
        });

    }).finally(()=>{
        dispatch(setAguardar(false));
    })
}

export const cartaoSegurancaGetCartao = ()=> dispatch => {
    dispatch(setAguardar(true));
    const formData = new URLSearchParams();
    formData.append('tipo', 'cartao');
    //
    axios.get(`${ROTAS[0]}?${formData.toString()}` ).then(resp=>{
        if(resp.status !== 200){
            toast.dark(ERRO_STATUS_NAO_200, {
                type: 'error'
            });
            return false;
        }
        // Veja se tem mensagem de erro
        if(resp.data.erro){
            toast.dark(resp.data.erro, {
                type: 'error'
            });
            return false;
        }
        // Tudo certo atualizar os dados
        dispatch({
            type: CARTAO_SEGURANCA_GET_CARTAO,
            data: resp.data,
        });

    }).catch(err=>{
        console.log(err);
        toast.dark(ERRO_NAO_ENVIADO, {
            type: 'error'
        });

    }).finally(()=>{
        dispatch(setAguardar(false));
    })
}
// Action para registrar a insercao de um cartao
export const cartaoSegurancaAddCartao = (obj)=> dispatch =>{
    const formData = new FormData();
    if(obj.arquivo){
        [...obj.arquivo].forEach(file=>{
            formData.append('arquivo', file);
        });
        delete obj.arquivo;
    }
    // Adiciona os campos como um json
    formData.append('dados', JSON.stringify(obj));
    //
    dispatch(setAguardar(true));
    // Envia os dados
    axios.post(ROTAS[1], formData).then(resp=>{
        if(resp.status !== 200){
            toast.dark(ERRO_STATUS_NAO_200, {
                type: 'error'
            });
            return false;
        }
        // Verifica se foi retornado algum erro
        if(resp.data.erro){
            toast.dark(resp.data.erro, {
                type: 'error'
            });
            return false;
        }
        // Deu tudo certo
        toast.dark(resp.data.sucesso, {
            type: 'success'
        });
        // Fechar o drawer
        dispatch(cartaoSegurancaFecharDrawer());
        
        // Atualiza o objeto de dados local
        dispatch({
            type: CARTAO_SEGURANCA_ADD,
            data: {
                cartao: resp.data.data,
                cartao_x_anexo: resp.data.data_anexo
            }
        });

    }).catch(err=>{
        console.log(err);
        toast.dark(ERRO_NAO_ENVIADO, {
            type: 'error'
        });
    }).finally(()=>{
        dispatch(setAguardar(false));
    })
}
// Action para a alterar status do cartao para Em analise
export const cartaoSegurancaSetEmAnalise = (obj)=> dispatch =>{
    const formData = new FormData();
    // Adiciona os campos como um json
    formData.append('dados', JSON.stringify(obj));
    //
    dispatch(setAguardar(true));
    // Envia os dados
    axios.put(ROTAS[1], formData).then(resp=>{
        if(resp.status !== 200){
            toast.dark(ERRO_STATUS_NAO_200, {
                type: 'error'
            });
            return false;
        }
        // Verifica se foi retornado algum erro
        if(resp.data.erro){
            toast.dark(resp.data.erro, {
                type: 'error'
            });
            return false;
        }
        // Deu tudo certo
        toast.dark(resp.data.sucesso, {
            type: 'success'
        });
        // Fechar o drawer
        dispatch(cartaoSegurancaFecharDrawer());
        
        // Atualiza o objeto de dados local
        dispatch({
            type: CARTAO_SEGURANCA_UPD_EM_ANALISE,
            data: {
                obj: resp.data.data,
                id: obj.id_cartao,
            }
        });

    }).catch(err=>{
        console.log(err);
        toast.dark(ERRO_NAO_ENVIADO, {
            type: 'error'
        });
    }).finally(()=>{
        dispatch(setAguardar(false));
    })

}
// Action para mover o cartão para outro status quando ele e justificado pelo EHS
export const cartaoSegurancaJustificativa = (obj) => dispatch =>{
    const formData = new FormData();
    // Adiciona os campos como um json
    formData.append('dados', JSON.stringify(obj));
    //
    dispatch(setAguardar(true));
    // Envia os dados
    axios.post(ROTAS[2], formData).then(resp=>{
        if(resp.status !== 200){
            toast.dark(ERRO_STATUS_NAO_200, {
                type: 'error'
            });
            return false;
        }
        // Verifica se foi retornado algum erro
        if(resp.data.erro){
            toast.dark(resp.data.erro, {
                type: 'error'
            });
            return false;
        }
        // Deu tudo certo
        toast.dark(resp.data.sucesso, {
            type: 'success'
        });
        // Fechar o drawer
        dispatch(cartaoSegurancaFecharDrawer());
        
        // Atualiza o objeto de dados local
        dispatch({
            type: CARTAO_SEGURANCA_ADD_JUSTIFICATIVA,
            data: {
                'data_cartao': resp.data.data_cartao,
                'data': resp.data.data,
            }
        });

    }).catch(err=>{
        console.log(err);
        toast.dark(ERRO_NAO_ENVIADO, {
            type: 'error'
        });
    }).finally(()=>{
        dispatch(setAguardar(false));
    })

}
// Action para mover o cartao para o status em aberto ou cancelado pelo EHS
export const cartaoSegurancaValidacao = (obj) => dispatch =>{
    const formData = new FormData();
    // Adiciona os campos como um json
    formData.append('dados', JSON.stringify(obj));
    //
    dispatch(setAguardar(true));
    // Envia os dados
    axios.post(ROTAS[3], formData).then(resp=>{
        if(resp.status !== 200){
            toast.dark(ERRO_STATUS_NAO_200, {
                type: 'error'
            });
            return false;
        }
        // Verifica se foi retornado algum erro
        if(resp.data.erro){
            toast.dark(resp.data.erro, {
                type: 'error'
            });
            return false;
        }
        // Deu tudo certo
        toast.dark(resp.data.sucesso, {
            type: 'success'
        });
        // Fechar o drawer
        dispatch(cartaoSegurancaFecharDrawer());
        
        // Atualiza o objeto de dados local
        dispatch({
            type: CARTAO_SEGURANCA_ADD_VALIDACAO,
            data: {
                'data_cartao': resp.data.data_cartao,
                'data': resp.data.data,
            }
        });

    }).catch(err=>{
        console.log(err);
        toast.dark(ERRO_NAO_ENVIADO, {
            type: 'error'
        });
    }).finally(()=>{
        dispatch(setAguardar(false));
    })

}
// Action para setar o  drawer
export const cartaoSegurancaSetDrawer = (obj)=>({
    type: CARTAO_SEGURANCA_SET_DRAWER,
    data: obj
});
// Action para fechar o drawer
export const cartaoSegurancaFecharDrawer = ()=>({
    type: CARTAO_SEGURANCA_FECHAR_DRAWER,
});
// Action para o filtro por status
export const cartaoSegurancaSetFiltroPorStatus = (item)=>({
    type: CARTAO_SEGURANCA_SET_FILTRO_STATUS,
    data: item
})
// Action para o filtro por tipo de cartao
export const cartaoSegurancaSetFiltroPorTipoCartao = (item)=>({
    type: CARTAO_SEGURANCA_SET_FILTRO_TIPO_CARTAO,
    data: item
})