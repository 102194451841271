import React, { useCallback, useEffect } from "react";
import BackgroundRouter from "../../background-router";
import { CircularProgress, Stack } from "@mui/material";
import { AnimacaoSemDados } from "../../../components";
import { useDispatch } from "react-redux";
import { ncpInit } from "./ncp-actions";
import { useTranslation } from "react-i18next";

function NcpWrapperSemDados({ isBgRouter }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(ncpInit(() => {}));
    }, 2000);
  }, [dispatch]);

  const fnChidren = useCallback(() => {
    return (
      <Stack sx={{ width: "100%" }} alignItems="center">
        <AnimacaoSemDados titulo={t("ncp.tituloSemDadosRetorno")} />
        <CircularProgress />
      </Stack>
    );
  }, [t]);

  return isBgRouter ? (
    <BackgroundRouter>{fnChidren()}</BackgroundRouter>
  ) : (
    fnChidren()
  );
}

export default NcpWrapperSemDados;
