import {
  MANUTENCAO_VARIAVEIS_GET_DADOS,
  MANUTENCAO_VARIAVEIS_ATIVA_VAR_USUARIO,
  MANUTENCAO_VARIAVEIS_DESATIVA_VAR_USUARIO,
  MANUTENCAO_VARIAVEIS_ADD_VARIAVEL,
  MANUTENCAO_VARIAVEIS_UPD_VARIAVEL,
  MANUTENCAO_VARIAVEIS_DEL_VARIAVEL,
  MANUTENCAO_VARIAVEIS_FILTRO_COLABORADOR,
  MANUTENCAO_VARIAVEIS_FILTRO_PLANTA,
  MANUTENCAO_VARIAVEIS_FECHAR_DRAWER,
  MANUTENCAO_VARIAVEIS_SET_DRAWER,
  MANUTENCAO_VARIAVEIS_GLOBAIS_GET_DADOS,
  MANUTENCAO_VARIAVEIS_GLOBAL_UPD,
  MANUTENCAO_VARIAVEIS_GLOBAL_DEL,
  MANUTENCAO_VARIAVEIS_GLOBAL_ADD,
} from "../../../redux/actions";

export default function manutencaoVariaveisReducer(state = null, action) {
  switch (action.type) {
    case MANUTENCAO_VARIAVEIS_GET_DADOS:
      return {
        ...state,
        filtroPlanta: ["TAPE", "TAMG"],
        ...action.data,
      };
    case MANUTENCAO_VARIAVEIS_DESATIVA_VAR_USUARIO: // Para desativar a variavel
      return (() => {
        const { colaborador_x_variavel } = state;
        // Matricula e variavel que precisa ser removida
        const { matricula, id_variavel, planta } = action.data;
        const key = [matricula, planta].join("_");
        const copiaArr = colaborador_x_variavel[key].filter(
          (ele) => String(ele) !== String(id_variavel)
        );

        return {
          ...state,
          colaborador_x_variavel: {
            ...state.colaborador_x_variavel,
            [key]: copiaArr,
          },
        };
      })();
    case MANUTENCAO_VARIAVEIS_ATIVA_VAR_USUARIO: // Para ativar a variavel
      return (() => {
        const { colaborador_x_variavel } = state;
        // Matricula e variavel que precisa ser removida
        const { matricula, id_variavel, planta } = action.data;
        const key = [matricula, planta].join("_");
        if (!colaborador_x_variavel[key]) colaborador_x_variavel[key] = [];
        colaborador_x_variavel[key].push(id_variavel);
        return {
          ...state,
          colaborador_x_variavel: {
            ...colaborador_x_variavel,
          },
        };
      })();
    case MANUTENCAO_VARIAVEIS_ADD_VARIAVEL: // Para cadastrar uma variavel
      return (() => {
        const { variaveis } = state;

        return {
          ...state,
          variaveis: variaveis.concat(action.data),
        };
      })();
    case MANUTENCAO_VARIAVEIS_UPD_VARIAVEL: // Para atualizar a variavel
      return (() => {
        const { variaveis } = state;
        const { id } = action.data;
        // Atualiza a variavel
        const copiaVariaveis = variaveis.map((ele) => {
          if (String(ele.id) === String(id)) {
            return action.data;
          }
          return ele;
        });

        return {
          ...state,
          variaveis: copiaVariaveis,
        };
      })();
    case MANUTENCAO_VARIAVEIS_DEL_VARIAVEL: // Para excluir uma variavel
      return (() => {
        const { colaborador_x_variavel, variaveis } = state;
        const { id_variavel } = action.data;
        const copiaVariaveis = variaveis.filter(
          (ele) => String(ele.id) !== String(id_variavel)
        );
        // Remover a variavel dos colaboradores
        Object.keys(colaborador_x_variavel).forEach((key) => {
          if (colaborador_x_variavel[key].includes(parseInt(id_variavel))) {
            const indice = colaborador_x_variavel[key].indexOf(
              parseInt(id_variavel)
            );
            colaborador_x_variavel[key].splice(indice, 1);
          }
        });

        return {
          ...state,
          colaborador_x_variavel: colaborador_x_variavel,
          variaveis: copiaVariaveis,
        };
      })();
    case MANUTENCAO_VARIAVEIS_FILTRO_COLABORADOR: // Para setar o filtro de colaboradores
      return {
        ...state,
        filtroColaborador: action.data,
      };
    case MANUTENCAO_VARIAVEIS_FILTRO_PLANTA: // Para filtro de planta
      return {
        ...state,
        filtroPlanta: action.data,
      };
    case MANUTENCAO_VARIAVEIS_SET_DRAWER: // Para definir o valor do drawer
      return {
        ...state,
        drawer: action.data,
      };
    case MANUTENCAO_VARIAVEIS_FECHAR_DRAWER: // Para fecharo drawer
      return {
        ...state,
        drawer: false,
      };
    case MANUTENCAO_VARIAVEIS_GLOBAIS_GET_DADOS: // Para obter a listagem de variaveis globais
      return {
        ...state,
        variaveis_globais: action.data,
      };
    case MANUTENCAO_VARIAVEIS_GLOBAL_UPD: // Para atualizar uma variavel global
      return (() => {
        const copiaVariaveisGlobais = state.variaveis_globais.map((ele) => {
          if (String(ele.id_variavel) === String(action.data.id_variavel)) {
            return action.data;
          }
          return ele;
        });

        return {
          ...state,
          variaveis_globais: copiaVariaveisGlobais,
        };
      })();
    case MANUTENCAO_VARIAVEIS_GLOBAL_DEL: // Para retirar a variavel global da listagem
      return {
        ...state,
        variaveis_globais: state.variaveis_globais.filter(
          (ele) => String(ele.id_variavel) !== String(action.data)
        ),
        drawer: null,
      };
    case MANUTENCAO_VARIAVEIS_GLOBAL_ADD: // Para adicionar uma nova variavel glboal
      return {
        ...state,
        drawer: null,
        variaveis_globais: state.variaveis_globais.concat([action.data]),
      };
    default:
      return state;
  }
}
