import React from "react";
import { ContainerAdaptavel } from "../../../extra-components";
import TreinamentoFormAdd, { TreinamentoWrapper } from "./treinamento-form-add";
import TreinamentoStudentsFormAdd, {
  TreinamentoStudentsWrapper,
} from "./treinamento-students-form-add";
import TreinamentoAddUpdCurso from "./treinamento-add-upd-curso";
import TreinamentoDelCurso from "./treinamento-del-curso";
import TreinamentoAddUpdInstrutor from "./treinamento-add-upd-instrutor";
import TreinamentoDelInstrutor from "./treinamento-del-instrutor";
import TreinamentoVerHistorico from "./treinamento-ver-historico";
import TreinamentoFormAlterarStatus from "./treinamento-form-alterar-status";
import TreinamentoDownloadExcel from "./treinamento-download-excel";

function TreinamentoModal({ modal }) {
  let corpo;
  switch (modal.type) {
    case TreinamentoModal.modal.FORM_ADD_TRAINNING:
      corpo = <TreinamentoFormAdd />;
      break;
    case TreinamentoModal.modal.FORM_EDT_TRAINNING:
      corpo = <TreinamentoWrapper id={modal.data} />;
      break;
    case TreinamentoModal.modal.FORM_ADD_STUDENTS:
      corpo = (
        <TreinamentoStudentsWrapper
          id={modal.data}
          renderItem={(id, data) => (
            <TreinamentoStudentsFormAdd id={id} studentsInTrainning={data} />
          )}
        />
      );
      break;
    case TreinamentoModal.modal.FORM_ADD_COURSE:
      corpo = <TreinamentoAddUpdCurso />;
      break;
    case TreinamentoModal.modal.FORM_EDT_COURSE:
      corpo = <TreinamentoAddUpdCurso {...modal.data} />;
      break;
    case TreinamentoModal.modal.FORM_DEL_COURSE:
      corpo = <TreinamentoDelCurso id={modal.data} />;
      break;
    case TreinamentoModal.modal.FORM_ADD_INSTRUCTOR:
      corpo = <TreinamentoAddUpdInstrutor />;
      break;
    case TreinamentoModal.modal.FORM_EDT_INSTRUCTOR:
      corpo = <TreinamentoAddUpdInstrutor instructor={modal.data} />;
      break;
    case TreinamentoModal.modal.FORM_DEL_INSTRUCTOR:
      corpo = <TreinamentoDelInstrutor id={modal.data} />;
      break;
    case TreinamentoModal.modal.VIEW_HISTORY:
      corpo = <TreinamentoVerHistorico id={modal.data} />;
      break;
    case TreinamentoModal.modal.ALTER_STATUS:
      corpo = <TreinamentoFormAlterarStatus id={modal.data} />;
      break;
    case TreinamentoModal.modal.DOWNLOAD_TO_EXCEL:
      corpo = <TreinamentoDownloadExcel />;
      break;

    default:
      break;
  }
  return (
    <ContainerAdaptavel sx={{ minHeight: "50vh" }}>{corpo}</ContainerAdaptavel>
  );
}

TreinamentoModal.modal = {
  FORM_ADD_TRAINNING: "FORM_ADD_TRAINNING",
  FORM_EDT_TRAINNING: "FORM_EDT_TRAINNING",
  FORM_ADD_STUDENTS: "FORM_ADD_STUDENTS",
  FORM_ADD_COURSE: "FORM_ADD_COURSE",
  FORM_EDT_COURSE: "FORM_EDT_COURSE",
  FORM_DEL_COURSE: "FORM_DEL_COURSE",
  FORM_ADD_INSTRUCTOR: "FORM_ADD_INSTRUCTOR",
  FORM_EDT_INSTRUCTOR: "FORM_EDT_INSTRUCTOR",
  FORM_DEL_INSTRUCTOR: "FORM_DEL_INSTRUCTOR",
  VIEW_HISTORY: "VIEW_HISTORY",
  ALTER_STATUS: "ALTER_STATUS",
  DOWNLOAD_TO_EXCEL: "DOWNLOAD_TO_EXCEL",
};

export default TreinamentoModal;
