import axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";
import { setAguardar } from "../../../redux/actions/aguardar-actions";
import {
  ERRO_NAO_ENVIADO,
  ERRO_STATUS_NAO_200,
} from "../../../redux/actions/erro-actions";
import { ToastErro } from "../../../utils/utils";

const ROTAS = [
  "/galeria",
  "/galeria_itens",
  "/galeria_itens_comentarios",
  "/galeria_itens_curtidas",
];

export const GALERIA_DE_FOTOS_GET_ALBUNS = "GALERIA_DE_FOTOS_GET_ALBUNS"; // Obtem todos os albums
export const GALERIA_DE_FOTOS_ADD_ALBUM = "GALERIA_DE_FOTOS_ADD_ALBUM"; // Adicionar um novo album
export const GALERIA_DE_FOTOS_UPD_ALBUM = "GALERIA_DE_FOTOS_UPD_ALBUM"; // Para editar um album
export const GALERIA_DE_FOTOS_DEL_ALBUM = "GALERIA_DE_FOTOS_DEL_ALBUM"; // Para excluir um album
export const GALERIA_DE_FOTOS_LIMPAR_DADOS = "GALERIA_DE_FOTOS_LIMPAR_DADOS"; // Para limpar os dados quando sair da galeria
export const GALERIA_DE_FOTOS_SET_DRAWER = "GALERIA_DE_FOTOS_SET_DRAWER"; // Para definir o drawer
export const GALERIA_DE_FOTOS_FECHAR_DRAWER = "GALERIA_DE_FOTOS_FECHAR_DRAWER"; // Para fechar o drawer
export const GALERIA_DE_FOTOS_GET_FOTOS = "GALERIA_DE_FOTOS_GET_FOTOS"; // Lista as fotos do album selecionado
export const GALERIA_DE_FOTOS_LIMPAR_LISTA_FOTOS =
  "GALERIA_DE_FOTOS_LIMPAR_LISTA_FOTOS"; // Limpa a lista de fotos sendo exibida atualmente
export const GALERIA_DE_FOTOS_ADD_FOTO = "GALERIA_DE_FOTOS_ADD_FOTO"; // Para adicionar novas fotos
export const GALERIA_DE_FOTOS_GET_COMENTARIOS =
  "GALERIA_DE_FOTOS_GET_COMENTARIOS"; // Lista todos os comentarios
export const GALERIA_DE_FOTOS_ADD_COMENTARIO =
  "GALERIA_DE_FOTOS_ADD_COMENTARIO"; // Adiciona novo comentario
export const GALERIA_DE_FOTOS_UPD_COMENTARIO =
  "GALERIA_DE_FOTOS_UPD_COMENTARIO"; // Edita um comentario
export const GALERIA_DE_FOTOS_DEL_COMENTARIO =
  "GALERIA_DE_FOTOS_DEL_COMENTARIO"; // Exclui um comentario
export const GALERIA_DE_FOTOS_LIMPAR_LISTA_COMENTARIOS =
  "GALERIA_DE_FOTOS_LIMPAR_LISTA_COMENTARIOS"; //Limpa a lista de comentarios

export const GALERIA_DE_FOTOS_ADD_CURTIDA = "GALERIA_DE_FOTOS_ADD_CURTIDA"; // Para adicionar curtida
export const GALERIA_DE_FOTOS_UPD_CURTIDA = "GALERIA_DE_FOTOS_UPD_CURTIDA"; // Para editar uma curtida
export const GALERIA_DE_FOTOS_DEL_CURTIDA = "GALERIA_DE_FOTOS_DEL_CURTIDA"; // Para excluir uma curtida
export const GALERIA_DE_FOTOS_GET_CURTIDAS = "GALERIA_DE_FOTOS_GET_CURTIDAS"; // Para obter as curtidas
export const GALERIA_DE_FOTOS_LIMPAR_LISTA_CURTIDAS =
  "GALERIA_DE_FOTOS_LIMPAR_LISTA_CURTIDAS"; //Limpa a lista de curtidas
export const GALERIA_DE_FOTOS_EDITAR_LEGENDA_FOTO =
  "GALERIA_DE_FOTOS_EDITAR_LEGENDA_FOTO"; // Para editar a legenda da foto
export const GALERIA_DE_FOTOS_DEL_FOTO = "GALERIA_DE_FOTOS_DEL_FOTO"; // Para excluir a foto do album

export const galeriaDeFotosGetAlbuns = (pagina) => (dispatch) => {
  dispatch(setAguardar(true));

  const formData = new URLSearchParams();
  formData.append("tipo", 1);
  formData.append("pagina", pagina);
  //
  axios
    .get(`${ROTAS[0]}?${formData.toString()}`)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Tudo certo incrementa o album
      dispatch({
        type: GALERIA_DE_FOTOS_GET_ALBUNS,
        data: resp.data,
      });
    })
    .catch((e) => {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para adicionar ou editar um album
export const galeriaDeFotosAddUpdAlbum = (obj, editar) => (dispatch) => {
  dispatch(setAguardar(true));

  // const formData = new FormData();
  // formData.append('dados', JSON.stringify(obj));
  const formData = obj;

  const fn = editar ? axios.put : axios.post;

  //
  fn(ROTAS[0], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Fechar drawer
      dispatch(galeriaDeFotosFecharDrawer());
      // Tudo certo adiciona ou altera um album
      dispatch({
        type: editar ? GALERIA_DE_FOTOS_UPD_ALBUM : GALERIA_DE_FOTOS_ADD_ALBUM,
        data: resp.data.data,
      });
    })
    .catch((e) => {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para excluir um album
export const galeriaDeFotosDelAlbum = (obj) => (dispatch) => {
  dispatch(setAguardar(true));

  //   const formData = new FormData();
  //   formData.append("dados", JSON.stringify(obj));
  const formData = obj;
  //
  axios
    .delete(ROTAS[0], { data: formData })
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      toast.dark(resp.data.sucesso, {
        type: "success",
      });
      // Fechar drawer
      dispatch(galeriaDeFotosFecharDrawer());
      // Tudo certo exclui o album da listagem
      dispatch({
        type: GALERIA_DE_FOTOS_DEL_ALBUM,
        data: obj.id_album,
      });
    })
    .catch((e) => {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para limpar os dados
export const galeriaDeFotosLimparListaAlbum = () => ({
  type: GALERIA_DE_FOTOS_LIMPAR_DADOS,
});
// Action para definir o drawer
export const galeriaDeFotosSetDrawer = (obj) => ({
  type: GALERIA_DE_FOTOS_SET_DRAWER,
  data: obj,
});
// Action para fechar um drawer
export const galeriaDeFotosFecharDrawer = () => ({
  type: GALERIA_DE_FOTOS_FECHAR_DRAWER,
});
// Action para listar as fotos de um determinado album
export const galeriaDeFotosGetFotos =
  (id_album, pagina, fnProxima) => async (dispatch) => {
    dispatch(setAguardar(true));

    const formData = new URLSearchParams();
    formData.append("id_album", id_album);
    formData.append("pagina", pagina);
    //
    try {
      const resp = await axios.get(`${ROTAS[1]}?${formData.toString()}`);
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Se a funcao tiver sido enviada
      if (fnProxima) {
        // Se estiver vazio, defina o proximaPagina como 0
        if (resp.data.length === 0) {
          fnProxima(0);
        } else {
          // Neste caso fnProxima pode invocar o proximo numero
          fnProxima();
        }
      }
      // Tudo certo incrementa o album
      dispatch({
        type: GALERIA_DE_FOTOS_GET_FOTOS,
        data: resp.data,
      });
    } catch (error) {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    }
    dispatch(setAguardar(false));
  };
// Action para limpar as listagem de fotos que estao sendo exibidas
export const galeriaDeFotosLimparListaFotos = () => ({
  type: GALERIA_DE_FOTOS_LIMPAR_LISTA_FOTOS,
});
// Action para fazer o upload de fotos
export const galeriaDeFotosUploadFotos =
  (obj, fnAtualizarTexto) => async (dispatch) => {
    dispatch(setAguardar(true));

    // const formData = new FormData();
    // formData.append(
    //   "sizes",
    //   JSON.stringify({
    //     XP: [320, 320],
    //     P: [480, 480],
    //     M: [720, 720],
    //     G: [1200, 1200],
    //   })
    // );

    const arquivos = [...obj.arquivos].map((ele) => ele);

    for await (const ele of arquivos) {
      const formData = new FormData();
      formData.append(
        "sizes",
        JSON.stringify({
          XP: [320, 320],
          P: [480, 480],
          M: [720, 720],
          G: [1200, 1200],
        })
      );
      formData.append("arquivo", ele);

      // Objeto de estrutura para registar fotos no album
      const objSend = { id_album: obj.id_album };

      // Enviando as fotos
      try {
        const response = await axios.post("/bucket", formData, {
          headers: {
            ContentType: "multipart/form-data",
          },
        });

        _.forEach(response.data["arquivo"], (item) => {
          if (item.url.search("/bucket/XP_") !== -1) {
            objSend.anexo_XP = item.url;
          } else if (item.url.search("/bucket/P_") !== -1) {
            objSend.anexo_P = item.url;
          } else if (item.url.search("/bucket/M_") !== -1) {
            objSend.anexo_M = item.url;
          } else if (item.url.search("/bucket/G_") !== -1) {
            objSend.anexo_G = item.url;
          } else {
            objSend.anexo = item.url;
          }
        });
      } catch (error) {
        if (error?.message) {
          ToastErro(error.message);
        } else {
          ToastErro("Erro ao enviar arquivos");
        }
        return false;
      }

      try {
        const responseSend = await axios.post(ROTAS[1], objSend);
        dispatch({
          type: GALERIA_DE_FOTOS_ADD_FOTO,
          data: responseSend.data.data,
        });
      } catch (error) {
        if (error?.message) {
          ToastErro(error.message);
        } else {
          ToastErro("Erro ao tentar registrar os arquivos no album");
        }
        return false;
      }
    }

    dispatch(galeriaDeFotosFecharDrawer());
    dispatch(setAguardar(false));

    // [...obj.arquivos].forEach((ele) => {
    //   formData.append("arquivo", ele);
    // });

    // const objSend = { id_album: obj.id_album };

    // try {
    //   const response = await axios.post("/bucket", formData, {
    //     headers: {
    //       ContentType: "multipart/form-data",
    //     },
    //   });

    //   _.forEach(response.data["arquivo"], (item) => {
    //     if (item.url.search("/bucket/XP_") !== -1) {
    //       objSend.anexo_XP = item.url;
    //     } else if (item.url.search("/bucket/P_") !== -1) {
    //       objSend.anexo_P = item.url;
    //     } else if (item.url.search("/bucket/M_") !== -1) {
    //       objSend.anexo_M = item.url;
    //     } else if (item.url.search("/bucket/G_") !== -1) {
    //       objSend.anexo_G = item.url;
    //     } else {
    //       objSend.anexo = item.url;
    //     }
    //   });
    // } catch (error) {
    //   if (error?.message) {
    //     ToastErro(error.message);
    //   } else {
    //     ToastErro("Erro ao enviar arquivos");
    //   }

    //   return false;
    // }

    // //
    // axios
    //   .post(ROTAS[1], objSend)
    //   .then((resp) => {
    //     if (resp.status !== 200) {
    //       toast.dark(ERRO_STATUS_NAO_200, {
    //         type: "error",
    //       });
    //       return false;
    //     }
    //     if (resp.data.erro) {
    //       toast.dark(resp.data.erro, {
    //         type: "error",
    //       });
    //       return false;
    //     }
    //     toast.dark(resp.data.sucesso, {
    //       type: "success",
    //     });
    //     //
    //     dispatch({
    //       type: GALERIA_DE_FOTOS_ADD_FOTO,
    //       data: resp.data.data,
    //     });
    //     dispatch(galeriaDeFotosFecharDrawer());
    //   })
    //   .catch((e) => {
    //     toast.dark(ERRO_NAO_ENVIADO, {
    //       type: "error",
    //     });
    //   })
    //   .finally(() => {
    //     dispatch(setAguardar(false));
    //   });
  };

// Action para obter a lista de comentarios sobre uma foto
export const galeriaDeFotosGetComentarios = (id_foto) => (dispatch) => {
  dispatch(setAguardar(true));
  //
  axios
    .get(`${ROTAS[2]}/${id_foto}`)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Tudo certo lista todos os comentarios
      dispatch({
        type: GALERIA_DE_FOTOS_GET_COMENTARIOS,
        data: resp.data,
      });
    })
    .catch((e) => {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
//
export const galeriaDeFotosAddUpdComentario =
  (obj, fnFecharEditar) => (dispatch) => {
    dispatch(setAguardar(true));

    // const formData = new FormData();
    // formData.append("dados", JSON.stringify(obj));
    const formData = obj;

    const fn = fnFecharEditar ? axios.put : axios.post;

    //
    fn(ROTAS[2], formData)
      .then((resp) => {
        if (resp.status !== 200) {
          toast.dark(ERRO_STATUS_NAO_200, {
            type: "error",
          });
          return false;
        }
        if (resp.data.erro) {
          toast.dark(resp.data.erro, {
            type: "error",
          });
          return false;
        }

        // Fechar editar
        if (fnFecharEditar) fnFecharEditar();
        // Tudo certo adiciona ou altera um comentario
        dispatch({
          type: fnFecharEditar
            ? GALERIA_DE_FOTOS_UPD_COMENTARIO
            : GALERIA_DE_FOTOS_ADD_COMENTARIO,
          data: resp.data.data,
        });
      })
      .catch((e) => {
        toast.dark(ERRO_NAO_ENVIADO, {
          type: "error",
        });
      })
      .finally(() => {
        dispatch(setAguardar(false));
      });
  };
// Action para excluir um comentario
export const galeriaDeFotosDelComentario = (obj) => (dispatch) => {
  dispatch(setAguardar(true));
  const id_foto = obj.id_foto;

  delete obj.id_foto;

  // const formData = new FormData();
  // formData.append("dados", JSON.stringify(obj));
  const formData = obj;
  //
  axios
    .delete(ROTAS[2], { data: formData })
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Tudo certo exclui o album da listagem
      dispatch({
        type: GALERIA_DE_FOTOS_DEL_COMENTARIO,
        data: {
          id_comentario: obj.id_comentario,
          id_foto: id_foto,
        },
      });
    })
    .catch((e) => {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para limpar as listagem de comentarios
export const galeriaDeFotosLimparListaComentarios = () => ({
  type: GALERIA_DE_FOTOS_LIMPAR_LISTA_COMENTARIOS,
});
// Action para obter as curtidas sobre uma foto
export const galeriaDeFotosGetCurtidas = (id_foto) => (dispatch) => {
  dispatch(setAguardar(true));
  //
  axios
    .get(`${ROTAS[3]}/${id_foto}`)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Tudo certo lista todas as curtidas da foto
      dispatch({
        type: GALERIA_DE_FOTOS_GET_CURTIDAS,
        data: resp.data,
      });
    })
    .catch((e) => {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
//
export const galeriaDeFotosAddUpdCurtida =
  (obj, editar, fnFecharEditar) => (dispatch) => {
    dispatch(setAguardar(true));

    // const formData = new FormData();
    // formData.append("dados", JSON.stringify(obj));

    const formData = obj;

    const fn = editar ? axios.put : axios.post;

    //
    fn(ROTAS[3], formData)
      .then((resp) => {
        if (resp.status !== 200) {
          toast.dark(ERRO_STATUS_NAO_200, {
            type: "error",
          });
          return false;
        }
        if (resp.data.erro) {
          toast.dark(resp.data.erro, {
            type: "error",
          });
          return false;
        }

        // Fechar editar
        if (fnFecharEditar) fnFecharEditar();
        // Tudo certo adiciona ou altera uma curtida
        dispatch({
          type: editar
            ? GALERIA_DE_FOTOS_UPD_CURTIDA
            : GALERIA_DE_FOTOS_ADD_CURTIDA,
          data: resp.data.data,
        });
      })
      .catch((e) => {
        toast.dark(ERRO_NAO_ENVIADO, {
          type: "error",
        });
      })
      .finally(() => {
        dispatch(setAguardar(false));
      });
  };
// Action para excluir uma curtida
export const galeriaDeFotosDelCurtida = (obj) => (dispatch) => {
  dispatch(setAguardar(true));
  const id_foto = obj.id_foto;

  delete obj.id_foto;

  // const formData = new FormData();
  // formData.append("dados", JSON.stringify(obj));

  const formData = obj;
  //
  axios
    .delete(ROTAS[3], { data: formData })
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Tudo certo exclui a curtida
      dispatch({
        type: GALERIA_DE_FOTOS_DEL_CURTIDA,
        data: {
          id_curtida: obj.id_curtida,
          id_foto: id_foto,
        },
      });
    })
    .catch((e) => {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para limpar as listagem de curtidas
export const galeriaDeFotosLimparListaCurtidas = () => ({
  type: GALERIA_DE_FOTOS_LIMPAR_LISTA_CURTIDAS,
});
// Action para editar a legenda da foto
export const galeriaDeFotosEditLegendaFoto = (obj) => (dispatch) => {
  dispatch(setAguardar(true));

  // const formData = new FormData();
  // formData.append("dados", JSON.stringify(obj));

  const formData = obj;

  // Editar a legenda da foto
  axios
    .put(ROTAS[1], formData)
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }

      // Fechar editar a legenda
      dispatch(galeriaDeFotosFecharDrawer());

      // Tudo certo edita a legenda da foto
      dispatch({
        type: GALERIA_DE_FOTOS_EDITAR_LEGENDA_FOTO,
        data: resp.data.data,
      });
    })
    .catch((e) => {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
// Action para excluir a foto
export const galeriaDeFotosDelFoto = (obj) => (dispatch) => {
  dispatch(setAguardar(true));

  // const formData = new FormData();
  // formData.append("dados", JSON.stringify(obj));
  const formData = obj;
  //
  axios
    .delete(ROTAS[1], { data: formData })
    .then((resp) => {
      if (resp.status !== 200) {
        toast.dark(ERRO_STATUS_NAO_200, {
          type: "error",
        });
        return false;
      }
      if (resp.data.erro) {
        toast.dark(resp.data.erro, {
          type: "error",
        });
        return false;
      }
      // Fechando o drawer
      dispatch(galeriaDeFotosFecharDrawer());
      // Tudo certo exclui a foto
      dispatch({
        type: GALERIA_DE_FOTOS_DEL_FOTO,
        data: obj.id_foto,
      });
    })
    .catch((e) => {
      toast.dark(ERRO_NAO_ENVIADO, {
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setAguardar(false));
    });
};
