import {
  LESSON_FECHAR_MODAL,
  LESSON_INIT,
  LESSON_PAGE,
  LESSON_SET_MODAL,
  LESSON_ADD,
} from "./lesson_action";

export default function lessonReducer(state = null, action) {
  switch (action.type) {
    case LESSON_INIT:
      return {
        ...state,
        ...action.data,
      };
    case LESSON_PAGE:
      return {
        ...state,
        pagina: action.data.idPage,
        dadosLicao: action.data.data,
      };
    case LESSON_SET_MODAL:
      return {
        ...state,
        modal: action.data,
      };
    case LESSON_FECHAR_MODAL:
      return {
        ...state,
        modal: null,
      };
    case LESSON_ADD:
      return {
        ...state,
        dadosLicao: [action.data, ...state.dadosLicao],
      };
    default:
      return state;
  }
}
