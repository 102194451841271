import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { selectorModeViewPdf } from "./documentos-seletores";
import { formatDistance, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { ACTIONS, getInfoColabs, validaExtensao } from "./documentos-utils";
import {
  Avatar,
  AvatarGroup,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Menu,
  Stack,
} from "@mui/material";
import { Body2, Caption, Icone } from "../../../components";
import DetalhesCabecalhoArquivo from "./documentos-details-header-file";
import ItemArquivoMenuLixeira from "./documentos-item-trash-menu";
import ItemArquivoMenu from "./documentos-item-menu-file";
import { useTranslation } from "react-i18next";

// Item do arquivo
const ItemArquivo = memo(
  ({
    dispatch,
    id_arquivo,
    onNavegar,
    tipo,
    nome,
    matricula,
    planta,
    isItemLixeira,
    avatar,
    data,
    nome_download,
    nome_colaborador,
    navegarPara,
    visivel_por,
    permissao_visualizacao,
    colaboradores,
    onRecortar,
  }) => {
    const { t } = useTranslation();
    const documentoAbrirPDF = useSelector(selectorModeViewPdf);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    // Veja a extensao do tipo de arquivo
    const ext = nome.split(".").pop();
    const criadoHa = `${t("documentos.labelItemCreated")} ${formatDistance(
      parseISO(data),
      new Date(),
      {
        locale: ptBR,
        addSuffix: true,
      }
    )}`;
    // Funcao decallback para o diretorio/arquivo
    const _onClick = () => {
      if (tipo === "D") {
        onNavegar(nome);
      } else {
        if (ext === "pdf" && documentoAbrirPDF && documentoAbrirPDF === "S") {
          dispatch({
            type: ACTIONS.SUPER_MODAL,
            data: {
              tipo: "VER_PDF",
              dados: nome_download,
            },
          });
        } else {
          window.open(nome_download);
        }
      }
    };
    const colabs = getInfoColabs(colaboradores);
    const colabsAvatar = colabs.filter(
      (colab) =>
        permissao_visualizacao.includes(colab.mat_planta) &&
        colab.mat_planta !== `${matricula}_${planta}`
    );

    return (
      <>
        <Card sx={{ width: "100%", height: "100%" }}>
          <CardHeader
            sx={{ py: 1 }}
            action={
              <IconButton onClick={handleClick}>
                <Icone icone="MoreVert" />
              </IconButton>
            }
            title={
              <DetalhesCabecalhoArquivo
                visivel_por={visivel_por}
                nome={nome_colaborador}
                avatar={avatar}
              />
            }
          />

          <ButtonBase
            sx={{ width: "100%" }}
            onClick={isItemLixeira ? null : _onClick}
          >
            <CardContent sx={{ p: 0.5 }}>
              <Divider />
              <Stack alignItems="center" justifyContent="space-between">
                <img
                  width={64}
                  heigth={64}
                  src={validaExtensao(tipo === "F" ? ext : "")}
                  alt={`${t("documentos.labelItemFile")} ${nome}`}
                />
                <Body2>{nome}</Body2>
                <Stack sx={{ my: 1 }} direction="row">
                  <Stack>
                    <Caption>{criadoHa}</Caption>
                  </Stack>
                </Stack>
                <AvatarGroup max={4}>
                  {colabsAvatar.map((ele) => (
                    <Avatar
                      title={`${ele.nome} ${t(
                        "documentos.withPermissionToView"
                      )}`}
                      alt={ele.nome}
                      src={ele.avatar}
                      key={ele.mat_planta}
                    />
                  ))}
                </AvatarGroup>
              </Stack>
            </CardContent>
          </ButtonBase>
        </Card>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {isItemLixeira ? (
            <ItemArquivoMenuLixeira
              ext={ext}
              nome={nome}
              tipo={tipo}
              id_arquivo={id_arquivo}
              data={data}
              fecharMenu={handleClose}
              dispatch={dispatch}
              matricula={matricula}
              planta={planta}
              nome_download={nome_download}
              nome_colaborador={nome_colaborador}
              avatar={avatar}
              navegarPara={navegarPara}
              permissao_visualizacao={permissao_visualizacao}
              colaboradores={colaboradores}
            />
          ) : (
            <ItemArquivoMenu
              ext={ext}
              nome={nome}
              tipo={tipo}
              id_arquivo={id_arquivo}
              data={data}
              fecharMenu={handleClose}
              dispatch={dispatch}
              matricula={matricula}
              planta={planta}
              nome_download={nome_download}
              nome_colaborador={nome_colaborador}
              avatar={avatar}
              onRecortar={onRecortar}
              visivel_por={visivel_por}
              permissao_visualizacao={permissao_visualizacao}
              colaboradores={colaboradores}
            />
          )}
        </Menu>
      </>
    );
  }
);

export default ItemArquivo;
