import { ContainerAdaptavel } from "../../../extra-components";
import DetalhesVaga from "./oportunidades-detalhes-vaga";
import FormAlteracaoFase from "./oportunidades-form-alter-fase";
import RespostasCandidatoAVaga from "./oportunidades-resp-cand-vaga";
import VerHistoricoVaga from "./oportunidades-ver-hist-vaga";

const OportunidadesModal = ({ modal, fecharModal }) => {
  let body;
  switch (modal.tipo) {
    case OportunidadesModal.modal.VER_DETALHES_VAGA: // Para exibir detalhes da vaga
    default:
      body = <DetalhesVaga {...modal.dados} fecharModal={fecharModal} />;
      break;
    case OportunidadesModal.modal.VER_RESPOSTA_CANDIDATO: // Para ver as respostas do candidato a vaga
      body = <RespostasCandidatoAVaga {...modal.dados} />;
      break;
    case OportunidadesModal.modal.ALTERAR_FASE:
      body = <FormAlteracaoFase id_vaga={modal.dados.id_vaga} />;
      break;
    case OportunidadesModal.modal.VER_HISTORICO: // Para ver o historico do concorrente a vaga
      body = <VerHistoricoVaga id_vaga={modal.dados.id_vaga} />;
      break;
  }

  return <ContainerAdaptavel>{body}</ContainerAdaptavel>;
};

OportunidadesModal.modal = {
  VER_DETALHES_VAGA: "VER_DETALHES_VAGA",
  VER_RESPOSTA_CANDIDATO: "VER_RESPOSTA_CANDIDATO",
  ALTERAR_FASE: "ALTERAR_FASE",
  VER_HISTORICO: "VER_HISTORICO",
};

export default OportunidadesModal;
