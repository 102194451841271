import { Grid, Typography } from "@mui/material";
import TotalValoresContraCheque from "./contra-cheque-total-valores";
import Utils from "../../../utils/utils";

// Componente para criar as bases do contracheque
const BasesContraCheque = ({ totVencimentos, totDescontos, bases }) => {
  return (
    <Grid container>
      <Grid item xs={10}>
        <Grid container>
          {bases &&
            Object.keys(bases).map((key, idx) => {
              return (
                <Grid key={idx} item xs={2}>
                  <Typography variant="subtitle2">{key}</Typography>
                  <Typography variant="caption">
                    {Utils.converter(parseFloat(bases[key]))}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <TotalValoresContraCheque
          totVencimentos={totVencimentos}
          totDescontos={totDescontos}
        />
      </Grid>
    </Grid>
  );
};

export default BasesContraCheque;
