import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Container,
  Typography,
  Stack,
  Chip,
  Divider,
  Button,
  IconButton,
  Paper,
  Box,
  LinearProgress,
  Slider,
  useTheme,
  TextField,
} from "@mui/material";
import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import { cadastroProjetosUpdMembrosProjeto } from "./kaizen-actions";
import { selectStyles, selectStylesDark } from "./styles-select";
import { formatarParaSelect } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const selectWait = (state) => state?.aguardar;

// Componente que exibe um formulario para alteracao de membros de um projeto
const ComponenteAlteracaoMembros = ({
  codigo,
  id_projeto,
  lider,
  colaboradores,
  membros,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const aguardar = useSelector(selectWait);

  // Pegando o nome e a matricula do lider
  const matLider = lider.split("-")[0].trim();
  const nomeLider = lider.split("-")[1].trim();
  // Vendo se é um mobile
  const isMobile = useTheme()?.breakpoints?.values["sm"] > window.innerWidth;
  //
  const [novosMembros, setNovosMembros] = useState([
    {
      membro: matLider,
      nome: nomeLider,
      percentual: membros?.length > 0 ? 50 : 100,
    },
    ...membros,
  ]);
  // Registra a intencao de adicionar um novo membro
  const [addMembro, setAddmembro] = useState(false);

  const TOT_PERCENT = novosMembros
    ?.map((ele) => ele.percentual)
    .reduce((a, b) => a + b, 0);
  // Funcao que determina a intencao de adicionar um novo membro
  const fnIntencaoAdicionarNovoMembro = () => {
    const copia = [...novosMembros];
    // Veja se só existe o lider, se sim ele passará a ter 50%
    if (copia.length === 1) {
      copia[0].percentual = 50;
      setNovosMembros(copia);
    } else {
      // Possivelmente tem mais de um membro. Veja se a cota atingiu 100%
      if (100 - TOT_PERCENT === 0) {
        toast.dark(t("cadastro_projetos.error100Percent"), {
          type: "error",
        });
        return false;
      }
    }
    // Chama a funcao para exibir o botao
    setAddmembro(true);
  };
  // Funcao que determina o cancelamento da intencao de adicionar um novo membro
  const fnCancelarIntencaoAdicionarNovoMembro = () => {
    const copia = [...novosMembros];
    // Veja se só existe o lider, se sim ele passará a ter 100%
    if (copia.length === 1) {
      copia[0].percentual = 100;
      setNovosMembros(copia);
    }
    // Chama a funcao para exibir o botao
    setAddmembro(false);
  };
  // Funcao para remover um membro da lista
  const fnRemoverMembro = (mat) => {
    const filtrado = novosMembros.filter((ele) => ele.membro !== mat);
    // Quer dizer que e o ultimo membro disponivel. Lider passa a ter 100%
    if (filtrado.length === 1) {
      filtrado[0].percentual = 100;
    }
    setNovosMembros(filtrado);
  };
  // Funcao para salvar a adicao de um novo membro
  const fnAddNovoMembro = (val) => {
    setAddmembro(false);
    setNovosMembros((state) => [...state, val]);
  };
  //
  const MEMBROS_ESCOLHIDOS = novosMembros?.map((ele) => ele.membro);
  // A funcao que vai pegar os novos membros e enviar para atualizacao da listagem de membros
  const fnRegistrarNovosMembros = () => {
    const dados = novosMembros.map((ele) => ({
      matricula: ele.membro,
      percentual: ele.percentual,
    }));

    dispatch(
      cadastroProjetosUpdMembrosProjeto({
        membros: dados,
        id_projeto: parseInt(id_projeto),
      })
    );
  };

  return (
    <Container sx={{ maxHeight: "85vh", minHeight: "50vh" }} maxWidth="md">
      <Typography variant="h6" align="center">
        {t("cadastro_projetos.titleAlterMember")}
      </Typography>
      <Stack>
        <Stack direction={{ xs: "column", md: "row" }} alignItems="center">
          <Chip
            sx={{ mb: 1, alignSelf: "flex-start" }}
            color="primary"
            label={`${t("cadastro_projetos.code")}: ${codigo}`}
          />
          &nbsp;&nbsp;
          <Typography variant="subtitle1" fontWeight="bold">
            {t("cadastro_projetos.leader")}: {lider}
          </Typography>
        </Stack>

        <Divider sx={{ my: 1 }} />
        <Typography variant="subtitle2" align="center">
          {t("cadastro_projetos.projectMembers")}
        </Typography>
        {!addMembro && (
          <Button
            onClick={fnIntencaoAdicionarNovoMembro}
            fullWidth
            startIcon={<PersonAddIcon />}
          >
            {t("cadastro_projetos.newMember")}
          </Button>
        )}
        <AnimatePresence>
          {addMembro && (
            <motion.div
              initial={{ scale: 0.01 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.3 }}
              exit={{ scale: 0.01 }}
              key="add_membro"
            >
              <ComponenteAdicionarMembro
                fnAdicionar={fnAddNovoMembro}
                fnCancelar={fnCancelarIntencaoAdicionarNovoMembro}
                TOT_PERCENT={TOT_PERCENT}
                MEMBROS_ESCOLHIDOS={MEMBROS_ESCOLHIDOS}
                colaboradores={colaboradores}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <Paper elevation={1} sx={{ my: 1, p: 1 }}>
          {novosMembros?.length > 0 && (
            <Typography align="center" variant="subtitle2">
              {t("cadastro_projetos.membersParticipants")}
            </Typography>
          )}
          {novosMembros?.map((ele, idx) => (
            <Stack sx={{ my: 0.5 }} key={ele.membro}>
              <Stack direction="row" sx={{ my: 0.5 }} key={idx}>
                <TextField
                  fullWidth
                  value={`${idx === 0 ? "( Lider )" : ""} ${ele.membro} - ${
                    ele.nome
                  }`}
                  disabled
                  size="small"
                />
                {/* O primeiro participante e o lider. Nao pode ser removido */}
                {idx > 0 && (
                  <IconButton
                    onClick={() => fnRemoverMembro(ele.membro)}
                    title={t("cadastro_projetos.titleRemoveMember")}
                  >
                    <PersonRemoveIcon color="error" />
                  </IconButton>
                )}
              </Stack>
              <Stack sx={{ mt: 0.5 }} direction="row" alignItems="center">
                <Box sx={{ flex: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={ele.percentual}
                  />
                </Box>
                <Typography variant="body2">
                  &nbsp;
                  {ele.percentual} %
                </Typography>
              </Stack>
            </Stack>
          ))}
          {100 - TOT_PERCENT > 0 && (
            <Typography
              sx={{ my: 1 }}
              align="center"
              color="error"
              variant="body2"
            >
              {t("cadastro_projetos.errorBefore100Percent1")}{" "}
              {100 - TOT_PERCENT}{" "}
              {t("cadastro_projetos.errorBefore100Percent2")}
            </Typography>
          )}
          <Stack direction="row" justifyContent="center">
            <Button
              onClick={fnRegistrarNovosMembros}
              disabled={TOT_PERCENT < 100 || aguardar}
              variant="contained"
              fullWidth={isMobile}
              startIcon={<SaveIcon />}
            >
              {t("cadastro_projetos.save")}
            </Button>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};

// Componente para adicionar novo membro na alteracao de membros
const ComponenteAdicionarMembro = ({
  TOT_PERCENT,
  colaboradores,
  MEMBROS_ESCOLHIDOS,
  fnCancelar,
  fnAdicionar,
}) => {
  const { t } = useTranslation();
  // Vendo se é um mobile
  const isMobile = useTheme()?.breakpoints?.values["sm"] > window.innerWidth;
  //
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const fnVal = (val) => {
    const { value, label } = val.membro;

    fnAdicionar({
      membro: value,
      nome: label,
      percentual: val.percentual,
    });
  };

  const isDarkMode = useTheme()?.palette.mode === "dark";

  return (
    <Paper elevation={3} sx={{ mb: 2, p: 2 }}>
      <Stack>
        <Controller
          control={control}
          name="membro"
          defaultValue={null}
          rules={{ required: true }}
          render={({ field }) => (
            <Stack>
              <Typography variant="body2" fontWeight="bold">
                {t("cadastro_projetos.collaborator")}
              </Typography>
              <Stack direction="row">
                <Box sx={{ flex: 1, mr: 1 }}>
                  <Select
                    {...field}
                    styles={isDarkMode ? selectStylesDark : selectStyles}
                    options={formatarParaSelect(
                      colaboradores.filter(
                        (ele) => !MEMBROS_ESCOLHIDOS.includes(String(ele[0]))
                      )
                    )}
                  />
                </Box>
                <IconButton
                  onClick={fnCancelar}
                  title={t("cadastro_projetos.titleCancelMember")}
                >
                  <PersonRemoveIcon color="error" />
                </IconButton>
              </Stack>
              {!!errors?.membro && (
                <Typography variant="caption" color="error" align="center">
                  {t("cadastro_projetos.chooseCollaborator")}
                </Typography>
              )}
            </Stack>
          )}
        />

        <Controller
          control={control}
          name="percentual"
          defaultValue={100 - TOT_PERCENT}
          rules={{ required: true }}
          render={({ field }) => (
            <Stack direction="row" alignItems="center">
              <Slider
                sx={{ flex: 1 }}
                {...field}
                max={100 - TOT_PERCENT}
                valueLabelDisplay="off"
              />
              <Typography sx={{ ml: 1 }} variant="body2">
                &nbsp;{watch("percentual") || 100 - TOT_PERCENT}%
              </Typography>
            </Stack>
          )}
        />
        <Stack direction="row-reverse">
          <Button
            size="small"
            disabled={!watch("membro")}
            fullWidth={isMobile}
            variant="contained"
            onClick={handleSubmit(fnVal)}
            startIcon={<PersonAddIcon />}
          >
            {t("cadastro_projetos.addMember")}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ComponenteAlteracaoMembros;
