import { Chip } from "@mui/material";
import React from "react";
import { getStatusColor, getStatusName } from "../treinamento-utils";
import { useTranslation } from "react-i18next";

function TrainningStatusChip({ status }) {
  const { t } = useTranslation();
  return (
    <Chip
      size="small"
      label={getStatusName(status, t)}
      color={getStatusColor(status)}
    />
  );
}

export default TrainningStatusChip;
