import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { registrarAcesso } from "../utils/utils";
import { SideBar } from "../components";

function BackgroundRouter({ children }) {
  // Registra a pagina que esta sendo acessada
  useEffect(() => registrarAcesso(window.location.pathname), []);
  return (
    <motion.div
      // initial={{ scale: 0 }}
      // animate={{ scale: 1, transition: { bounce: 1, duration: 0.2 } }}
      // exit={{ scale: 0, transition: { duration: 0.01 } }}
      style={{ display: "flex" }}
    >
      <SideBar barraIntegrada />
      {children}
    </motion.div>
  );
}

export default BackgroundRouter;
