import React from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Typography, Stack, TextField, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { cadastroProjetosAddJustificativa } from "./kaizen-actions";
import { formatarParaSelect } from "../../../utils/utils";
import * as yup from "yup";
import { selectStyles, selectStylesDark } from "./styles-select";
import Select from "react-select";
import { useTranslation } from "react-i18next";

// Schema para validacao dos dados da justificativa de um projeto atrasado
// Criacao do schema de validacao
const schemaFormJustificativa = yup.object().shape({
  descricao: yup.string().min(3).required(),
  descricao_da_acao: yup.string().min(3).required(),
  id_fator: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .required(),
  id_acao: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .required(),
});

// Formulario para incluir a justificativa do projeto
const FormAddJustificativa = ({
  fatores_atrasos,
  fatores_atrasos_x_acoes,
  id,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schemaFormJustificativa),
  });
  //
  const fn = (val) => {
    const { descricao, descricao_da_acao, id_acao, id_fator } = val;
    dispatch(
      cadastroProjetosAddJustificativa({
        descricao,
        descricao_da_acao,
        id_fator: parseInt(id_fator.value),
        id_projeto: id,
        id_acao: parseInt(id_acao.value),
      })
    );
  };
  // Veja qual o fator selecionado e exiba as acoes aceitas
  const fatorSelecionado = watch("id_fator")?.value;
  let fatoresDeAcao = null;

  if (
    fatorSelecionado &&
    fatores_atrasos_x_acoes.hasOwnProperty(fatorSelecionado)
  ) {
    fatoresDeAcao = fatores_atrasos_x_acoes[fatorSelecionado];
  }

  const isDarkMode = useTheme()?.palette.mode === "dark";

  return (
    <Container sx={{ p: 1, minHeight: "85vh" }} maxWidth="md">
      <Typography align="center" variant="subtitle1">
        {t("cadastro_projetos.titleFormAdd")}
      </Typography>
      <Typography component="p" align="center" variant="caption">
        {t("cadastro_projetos.descriptionAddJustify")}
      </Typography>
      <Stack>
        <Controller
          control={control}
          name="descricao"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("cadastro_projetos.descricao")}
              InputLabelProps={{ shrink: true }}
              sx={{ my: 1 }}
              placeholder={t(
                "cadastro_projetos.placeholderDescriptionAddJustify"
              )}
              minRows={3}
              type="textarea"
              multiline
              error={!!errors.descricao}
              helperText={
                !!errors.descricao && t("cadastro_projetos.errorDescription")
              }
            />
          )}
        />
        <Controller
          control={control}
          name="descricao_da_acao"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("cadastro_projetos.descricao_da_acao")}
              sx={{ my: 1 }}
              InputLabelProps={{ shrink: true }}
              placeholder={t("cadastro_projetos.placeholderDescriptionAction")}
              minRows={3}
              type="textarea"
              multiline
              error={!!errors.descricao_da_acao}
              helperText={
                !!errors.descricao_da_acao &&
                t("cadastro_projetos.errorDescriptionAction")
              }
            />
          )}
        />
        <Controller
          control={control}
          name="id_fator"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Stack sx={{ mb: 1 }}>
              <Typography variant="subtitle2" align="center">
                {t("cadastro_projetos.fator_atraso")}
              </Typography>
              <Select
                {...field}
                styles={isDarkMode ? selectStylesDark : selectStyles}
                options={formatarParaSelect(fatores_atrasos)}
                onChange={(val) => {
                  setValue("id_acao", "");
                  setValue("id_fator", val);
                }}
              />
              {!!errors.id_fator && (
                <Typography align="center" color="error" variant="caption">
                  {t("cadastro_projetos.errorFactor")}
                </Typography>
              )}
            </Stack>
          )}
        />
        {fatoresDeAcao && (
          <Controller
            control={control}
            name="id_acao"
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <Stack sx={{ mb: 1 }}>
                <Typography variant="subtitle2" align="center">
                  {t("cadastro_projetos.acao")}
                </Typography>
                <Select
                  {...field}
                  styles={isDarkMode ? selectStylesDark : selectStyles}
                  options={formatarParaSelect(fatoresDeAcao)}
                />
                {!!errors.id_acao && (
                  <Typography align="center" color="error" variant="caption">
                    {t("cadastro_projetos.errorAction")}
                  </Typography>
                )}
              </Stack>
            )}
          />
        )}

        <Button sx={{ mt: 2 }} variant="contained" onClick={handleSubmit(fn)}>
          {t("cadastro_projetos.registrar")}
        </Button>
      </Stack>
    </Container>
  );
};

export default FormAddJustificativa;
