import React from "react";
import { ContainerAdaptavel } from "../../../extra-components";
import BudgetDetailsAccount from "./budget-details-account";

function BudgetModal({ modal }) {
  let body;
  switch (modal.type) {
    case BudgetModal.MODAL.DETAILS_ACCOUNT:
      body = <BudgetDetailsAccount {...modal.data} />;
      break;
    default:
      break;
  }
  return (
    <ContainerAdaptavel sx={{ minHeight: "50vh" }}>{body}</ContainerAdaptavel>
  );
}

BudgetModal.MODAL = {
  DETAILS_ACCOUNT: "DETAILS_ACCOUNT",
};

export default BudgetModal;
