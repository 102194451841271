import {
  ButtonBase,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  Stack,
} from "@mui/material";
import { Body1, Icone } from "../../../components";
import { useSelector } from "react-redux";
import { selectorModeViewPdf } from "./documentos-seletores";
import { memo, useState } from "react";
import ItemArquivoMenuLixeira from "./documentos-item-trash-menu";
import ItemArquivoMenu from "./documentos-item-menu-file";
import { ACTIONS, validaExtensao } from "./documentos-utils";
import DetalhesCabecalhoArquivo from "./documentos-details-header-file";
import { formatDistance, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const ItemArquivoMobile = memo(
  ({
    onNavegar,
    nome,
    visivel_por,
    permissao_visualizacao,
    colaboradores,
    tipo,
    id_arquivo,
    data,
    dispatch,
    matricula,
    planta,
    nome_colaborador,
    avatar,
    nome_download,
    isItemLixeira,
    navegarPara,
    onRecortar,
    itemRecortado,
  }) => {
    const { t } = useTranslation();
    const documentoAbrirPDF = useSelector(selectorModeViewPdf);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    // Veja a extensao do tipo de arquivo
    const ext = nome.split(".").pop();
    const _onClick = () => {
      if (tipo === "D") {
        onNavegar(nome);
      } else {
        if (ext === "pdf" && documentoAbrirPDF && documentoAbrirPDF === "S") {
          dispatch({
            type: ACTIONS.SUPER_MODAL,
            data: {
              tipo: "VER_PDF",
              dados: nome_download,
            },
          });
        } else {
          window.open(nome_download);
        }
      }
    };
    return (
      <>
        <ListItem
          secondaryAction={
            <ButtonBase
              onClick={handleClick}
              sx={{ p: 1, borderRadius: "100%" }}
            >
              <Icone icone="MoreVert" />
            </ButtonBase>
          }
        >
          <ListItemButton
            divider={itemRecortado !== id_arquivo}
            key={id_arquivo}
            onClick={isItemLixeira ? null : _onClick}
          >
            <ListItemAvatar>
              <img
                width={32}
                heigth={32}
                src={validaExtensao(tipo === "F" ? ext : "")}
                alt={`${t("documentos.labelItemFIleMobileFile")} ${nome}`}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Stack>
                  <DetalhesCabecalhoArquivo
                    visivel_por={visivel_por}
                    nome={nome_colaborador}
                    avatar={avatar}
                  />
                  <Body1>{nome}</Body1>
                </Stack>
              }
              secondary={`${t(
                "documentos.labelItemFileMobileCreated"
              )} ${formatDistance(parseISO(data), new Date(), {
                locale: ptBR,
                addSuffix: true,
              })}`}
            />
          </ListItemButton>
        </ListItem>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {isItemLixeira ? (
            <ItemArquivoMenuLixeira
              ext={ext}
              nome={nome}
              tipo={tipo}
              id_arquivo={id_arquivo}
              data={data}
              fecharMenu={handleClose}
              dispatch={dispatch}
              matricula={matricula}
              planta={planta}
              nome_download={nome_download}
              nome_colaborador={nome_colaborador}
              avatar={avatar}
              navegarPara={navegarPara}
              visivel_por={visivel_por}
              permissao_visualizacao={permissao_visualizacao}
              colaboradores={colaboradores}
            />
          ) : (
            <ItemArquivoMenu
              ext={ext}
              nome={nome}
              tipo={tipo}
              id_arquivo={id_arquivo}
              data={data}
              fecharMenu={handleClose}
              dispatch={dispatch}
              matricula={matricula}
              planta={planta}
              nome_download={nome_download}
              nome_colaborador={nome_colaborador}
              avatar={avatar}
              onRecortar={onRecortar}
              visivel_por={visivel_por}
              permissao_visualizacao={permissao_visualizacao}
              colaboradores={colaboradores}
            />
          )}
        </Menu>
      </>
    );
  }
);

export default ItemArquivoMobile;
