import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Componente para cabecalho dos valores
const CabeValoresContraCheque = () => {
  const { t } = useTranslation();
  // Campos do cabecalho
  const CAMPOS = [
    { texto: t("contra_cheque.codigo"), xs: 1 },
    { texto: t("contra_cheque.descricao"), xs: 5 },
    { texto: t("contra_cheque.referencia"), xs: 2 },
    { texto: t("contra_cheque.vencimentos"), xs: 2 },
    { texto: t("contra_cheque.descontos"), xs: 2 },
  ];

  return (
    <Grid container>
      {CAMPOS.map((ele, idx) => (
        <Grid item xs={ele.xs}>
          <Typography variant="subtitle1">{ele.texto}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default CabeValoresContraCheque;
