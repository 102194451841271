import { ButtonBase, Paper, Stack } from "@mui/material";
import { red } from "@mui/material/colors";
import React, { useCallback } from "react";
import { trainningClearFilter } from "../treinamento-actions";
import { useDispatch } from "react-redux";
import { Body1 } from "../../../../components";
import { useTranslation } from "react-i18next";

function TrainningClearFilter() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClear = useCallback(() => {
    dispatch(trainningClearFilter());
  }, [dispatch]);

  return (
    <ButtonBase onClick={onClear}>
      <Paper
        sx={{
          backgroundColor: red[900],
          height: 56,
          width: (180 + 16) * 3,
          p: 1,
        }}
        elevation={3}
      >
        <Stack
          gap={1}
          sx={{ height: "100%", width: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Body1>{t("treinamento.clearFilter")}</Body1>
        </Stack>
      </Paper>
    </ButtonBase>
  );
}

export default TrainningClearFilter;
