import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { EntradaForm, H6 } from "../../../components";
import { useDispatch } from "react-redux";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { useToggle } from "react-use";
import * as yup from "yup";
import { helpdeskAddUpdStatus } from "./helpdesk-actions";
import { useTranslation } from "react-i18next";

const FIELDS = {
  name: "nome",
  authorized: "autorizado_interagir",
  situation: "situacao",
  color: "cor",
};

function HelpdeskStatusAddUpd({
  id,
  descricao,
  autorizado_interagir,
  situacao,
  cor,
}) {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();

  //
  const situationOptions = [
    ["A", t("helpdesk.labelActivated")],
    ["B", t("helpdesk.labelDeactivated")],
  ];
  //
  const authOptions = [
    ["A", t("helpdesk.labelAgent")],
    ["S", t("helpdesk.labelRequester")],
  ];
  //
  const schema = [
    {
      type: "text",
      name: FIELDS.name,
      label: t("helpdesk.labelName"),
      defaultValue: descricao || "",
      placeholder: t("helpdesk.placeholderName"),
      grid: {
        xs: 12,
      },
    },
    {
      type: "radio",
      label: t("helpdesk.labelAuthorized"),
      name: FIELDS.authorized,
      orientation: "horizontal",
      defaultValue: autorizado_interagir || "",
      itens: authOptions,
      placeholder: t("helpdesk.placeholderAuthorized"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "radio",
      label: t("helpdesk.labelSituation"),
      name: FIELDS.situation,
      orientation: "horizontal",
      itens: situationOptions,
      defaultValue: situacao || "",
      placeholder: t("helpdesk.placeholderSituation"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "color_picker",
      label: t("helpdesk.labelColor"),
      name: FIELDS.color,
      defaultValue: cor || "",
      placeholder: t("helpdesk.placeholderColor"),
    },
  ];
  //
  const onSubmit = useCallback(
    (val) => {
      const obj = {
        [FIELDS.name]: val[FIELDS.name],
        [FIELDS.authorized]: val[FIELDS.authorized],
        [FIELDS.situation]: val[FIELDS.situation],
        [FIELDS.color]: val[FIELDS.color],
      };
      if (id) {
        obj.id_status = id;
      }

      //
      dispatch(helpdeskAddUpdStatus(obj, setWait));
    },
    [dispatch, setWait, id]
  );
  //
  const schemaValidator = yup.object().shape({
    [FIELDS.name]: obterValidador(VALIDADOR_TIPO.texto, 3),
    [FIELDS.authorized]: obterValidador(VALIDADOR_TIPO.texto, 1),
    [FIELDS.situation]: obterValidador(VALIDADOR_TIPO.texto, 1),
    [FIELDS.color]: obterValidador(VALIDADOR_TIPO.texto, 7),
  });

  const schemaMessageError = {
    [FIELDS.name]: t("helpdesk.errorName"),
    [FIELDS.authorized]: t("helpdesk.errorAuthorized"),
    [FIELDS.situation]: t("helpdesk.erroSituation"),
    [FIELDS.color]: t("helpdesk.errorColor"),
  };
  return (
    <Stack>
      <H6>{t("helpdesk.titleAddStatus")}</H6>
      <EntradaForm
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
        wait={wait}
        onSubmit={onSubmit}
      />
    </Stack>
  );
}

export default HelpdeskStatusAddUpd;
