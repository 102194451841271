// Funcao para obter texto e cor
export const getSituacaoTexto = (situacao, t) => {
  switch (situacao) {
    case "A": // Ativo
      return t("manutencao_modelo.situationA");
    default: // Inativo
      return t("manutencao_modelo.situationDefault");
  }
};
//
export const getSituacaoCor = (situacao) => {
  switch (situacao) {
    case "A": // Ativo
      return "success";
    default: // Inativo
      return "error";
  }
};
