import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import {
  galeriaDeFotosGetFotos,
  galeriaDeFotosLimparListaFotos,
  galeriaDeFotosSetDrawer,
} from "./galeria-de-fotos-actions";
import {
  CircularProgress,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import GaleriaDeFotosModal from "./galeria-de-fotos-modal";

import ThumbUp from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PainelComentario from "./galeria-de-fotos-painel-comentario";

// Componente que lista as fotos do album selecionado
const ListaDeFotos = ({ isEditor, fnVerFoto, id_album, nome }) => {
  const [proximaPagina, setProximaPagina] = useState(1);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const fotos = useSelector((state) => state?.galeriaDeFotos?.fotos);
  const aguardar = useSelector((state) => state?.galeriaDeFotos?.aguardar);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: aguardar,
    hasNextPage: proximaPagina > 1,
    onLoadMore: () => {
      dispatch(
        galeriaDeFotosGetFotos(id_album, proximaPagina, (valor = 1) =>
          setProximaPagina((state) => (valor === 0 ? 0 : state + 1))
        )
      );
    },
    delayInMs: 600,
    //disabled: aguardar,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const isXl = useMediaQuery(useTheme()?.breakpoints?.up("xl"));

  useEffect(() => {
    dispatch(galeriaDeFotosGetFotos(id_album, 1, () => setProximaPagina(2)));
    return () => dispatch(galeriaDeFotosLimparListaFotos());
  }, [dispatch, id_album]);

  return (
    <Stack>
      <Typography variant="h5" align="center">
        {t("galeria_de_fotos.listOfPhotos")}
      </Typography>

      <ImageList
        ref={rootRef}
        cols={isMobile ? 1 : isXl ? 5 : 3}
        sx={{ overflowY: "auto", height: "75vh" }}
        variant="woven"
      >
        {fotos?.map((ele, idx) => {
          return (
            <ListaDeFotosItem
              key={ele.id_foto}
              ele={ele}
              idx={idx}
              isEditor={isEditor}
              fnVerFoto={fnVerFoto}
              nome={nome}
              qtd_comentarios={ele?.total_comentarios}
              qtd_curtidas={ele?.total_curtidas}
            />
          );
        })}
        {proximaPagina > 1 && (
          <Stack ref={sentryRef} direction="row" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
      </ImageList>
    </Stack>
  );
};

// Componente que representa uma unica foto
const ListaDeFotosItem = memo(
  ({ nome, isEditor, fnVerFoto, qtd_curtidas, qtd_comentarios, ele, idx }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
      <ImageListItem>
        <img
          onClick={() => fnVerFoto({ ...ele, idx: idx })}
          loading="lazy"
          srcSet={`${ele.anexo_XP} 320w,${ele.anexo_P} 480w`}
          src={ele?.anexo_M}
          alt={`${t("galeria_de_fotos.titleImageOfAlbum")} ${ele.id_foto}`}
        />
        <ImageListItemBar
          title={ele?.legenda || nome}
          subtitle={
            <Stack direction="row" justifyContent="space-between">
              <Stack
                title={t("galeria_de_fotos.titleTotalOfLikes")}
                direction="row"
                alignItems="center"
              >
                <IconButton
                  onClick={() =>
                    dispatch(
                      galeriaDeFotosSetDrawer({
                        tipo: GaleriaDeFotosModal.modal.VER_CURTIDAS,
                        dados: ele.id_foto,
                      })
                    )
                  }
                >
                  <ThumbUp sx={{ color: "white" }} />
                </IconButton>
                &nbsp;&nbsp;
                <Typography variant="body2">{qtd_curtidas}</Typography>
              </Stack>
              {isEditor && (
                <IconButton
                  title={t("galeria_de_fotos.titleClickViewLegend")}
                  onClick={() =>
                    dispatch(
                      galeriaDeFotosSetDrawer({
                        tipo: GaleriaDeFotosModal.modal.UPD_LEGENDA_FOTO,
                        dados: { id_foto: ele.id_foto, legenda: ele.legenda },
                      })
                    )
                  }
                >
                  <EditIcon sx={{ color: "white" }} />
                </IconButton>
              )}
              {isEditor && (
                <IconButton
                  title={t("galeria_de_fotos.titleClickDelPhoto")}
                  onClick={() =>
                    dispatch(
                      galeriaDeFotosSetDrawer({
                        tipo: GaleriaDeFotosModal.modal.EXCLUIR_FOTO,
                        dados: ele.id_foto,
                      })
                    )
                  }
                >
                  <DeleteIcon sx={{ color: "white" }} />
                </IconButton>
              )}

              <Stack
                title={t("galeria_de_fotos.titleTotalOfComments")}
                direction="row"
                alignItems="center"
              >
                <IconButton
                  onClick={() =>
                    dispatch(
                      galeriaDeFotosSetDrawer({
                        tipo: GaleriaDeFotosModal.modal.VER_COMENTARIOS,
                        dados: ele.id_foto,
                      })
                    )
                  }
                >
                  <CommentIcon sx={{ color: "white" }} />
                </IconButton>{" "}
                &nbsp;&nbsp;
                <Typography variant="body2">{qtd_comentarios}</Typography>
              </Stack>
            </Stack>
          }
        />
        <ImageListItemBar
          position="below"
          sx={{ pt: 0, pb: 2 }}
          title={
            <Paper elevation={2} sx={{}}>
              <PainelComentario id_foto={ele?.id_foto} />
            </Paper>
          }
        />
      </ImageListItem>
    );
  }
);

export default ListaDeFotos;
