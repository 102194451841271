import React, { useCallback, useEffect } from "react";
import BackgroundRouter from "../../background-router";
import {
  AnimacaoSemDados,
  Body2,
  Icone,
  Pesquisando,
  Tab,
} from "../../../components";
import { Container, Stack } from "@mui/material";
import SyntheticPage from "./synthetic_page";
import AnalyticPage from "./analytic_page";
import { useDispatch, useSelector } from "react-redux";
import { budgetCloseModal, initBudget } from "./budget-actions";
import { useToggle } from "react-use";
import {
  budgetModalSelector,
  budgetTableSyntheticSelector,
} from "./budget-selectors";
import BudgetModal from "./budget-modal";
import { DrawerDialog } from "../../../extra-components";
import { useTranslation } from "react-i18next";

const BUDGET_ICON = {
  tab1: "TableView",
  tab2: "ListAlt",
};

function Budget() {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle(false);
  const dispatch = useDispatch();
  const data = useSelector(budgetTableSyntheticSelector);
  const modal = useSelector(budgetModalSelector);

  useEffect(() => {
    dispatch(initBudget(setWait));
  }, [dispatch, setWait]);

  const closeModal = useCallback(
    () => dispatch(budgetCloseModal()),
    [dispatch]
  );
  //
  return (
    <BackgroundRouter>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => <BudgetModal modal={modal} />}
          fecharModal={closeModal}
        />
      )}
      <Container maxWidth={false}>
        {wait ? (
          <Pesquisando />
        ) : data?.header?.length > 0 ? (
          <BodyPage />
        ) : (
          <AnimacaoSemDados titulo={t("budget.nothing")} />
        )}
      </Container>
    </BackgroundRouter>
  );
}

const BodyPage = () => {
  const { t } = useTranslation();
  const tabs = [
    {
      name: t("budget.tab1"),
      icon: BUDGET_ICON.tab1,
    },
    {
      name: t("budget.tab2"),
      icon: BUDGET_ICON.tab2,
    },
  ].map((ele, idx) => (
    <Stack direction="row" gap={1} key={idx}>
      <Icone icone={ele.icon} />
      <Body2>{ele.name}</Body2>
    </Stack>
  ));
  const body = [<SyntheticPage />, <AnalyticPage />];
  //
  return <Tab cabe={tabs} corpo={body} />;
};
//

Budget.rota = "/budget";

export default Budget;
