import { Divider, Grid, Typography } from "@mui/material";
import Utils from "../../../utils/utils";

// Compnente que vai retornar os valores registrados no contracheque
const ValoresContraCheque = ({ valores }) => {
  return (
    <>
      {valores &&
        valores.map((ele, idx) => {
          //const [codigo, descricao, ref, vencimento, desconto ] = ele;
          return (
            <Grid container key={idx}>
              {ele.map((valor, idx2) => {
                // Para ver se a coluna e vencimentos ou descontos
                // se for precisa formatar o valor em monetario
                let val = [3, 4].includes(idx2)
                  ? Utils.converter(parseFloat(valor).toFixed(2))
                  : valor;
                // Se for vencimento ou desconto podemos somar os valores
                if (idx2 === 3) {
                  val = valor === 0 ? null : val;
                  //totVencimentos += valor;
                } else if (idx2 === 4) {
                  val = valor === 0 ? null : val;
                  //totDescontos += valor;
                }
                // Retorna a tabela com os vencimentos e os descontos
                return (
                  <Grid
                    key={idx2}
                    item
                    xs={idx2 === 0 ? 1 : idx2 === 1 ? 5 : 2}
                  >
                    <Typography variant="caption">{val}</Typography>
                  </Grid>
                );
              })}
              <Divider />
            </Grid>
          );
        })}
    </>
  );
};

export default ValoresContraCheque;
