export const SET_ALERTA = 'SET_ALERTA';
export const FECHAR_ALERTA = 'FECHAR_ALERTA';


export const setAlerta = (mensagem)=>({
    type: SET_ALERTA,
    mensagem
});

export const fecharAlerta = ()=>({
    type: FECHAR_ALERTA,
})