import { Stack } from "@mui/material";
import { Caption, Icone } from "../../../components";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

//
const DataAbertura = ({ dataAbertura }) => {
  const { t } = useTranslation();
  return (
    <Stack
      title={t("oportunidades.titleOpenDate")}
      direction="row"
      spacing={1}
      alignItems="flex-end"
    >
      <Icone icone="Event" />
      <Caption>{format(parseISO(dataAbertura), "dd/MM/yy")}</Caption>
    </Stack>
  );
};

export default DataAbertura;
