import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { estatisticasGetDados } from "./estatisticas-actions";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { useTranslation } from "react-i18next";

// Schema de validacao
const schema = yup.object().shape({
  de: yup.date().required(),
  ate: yup.date().required(),
});

// Componente para selecionar datas de e ate
const FiltroDataDeEAte = ({ fecharFiltro, deAte, setDeAte }) => {
  const { t } = useTranslation();
  // Dispatch para o filtro
  const dispatch = useDispatch();
  const { aguardar } = useSelector((state) => state);
  //
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fn = (val) => {
    const de = format(val.de, "yyyy-MM-dd");
    const ate = format(val.ate, "yyyy-MM-dd");

    setDeAte({ de, ate });

    dispatch(estatisticasGetDados(de, ate, fecharFiltro));
  };
  // Array com as opcoes
  const campos = [
    { label: t("estatisticas.dateFrom"), name: "de", defaultValue: deAte.de },
    { label: t("estatisticas.dateTo"), name: "ate", defaultValue: deAte.ate },
  ];

  return (
    <Container maxWidth="md">
      <Paper elevation={0} sx={{ p: 1 }}>
        <Typography variant="h6" align="center">
          {t("estatisticas.titleFilterByPeriod")}
        </Typography>
        <Typography
          component="p"
          sx={{ opacity: 0.8 }}
          variant="caption"
          align="center"
        >
          {t("estatisticas.subtitleFilterByPeriod")}
        </Typography>

        <Stack sx={{ p: 2 }} spacing={1}>
          <Grid container spacing={2}>
            {campos.map((ele, idx) => (
              <Grid key={idx} item xs={12} md={4}>
                <Controller
                  control={control}
                  name={ele.name}
                  defaultValue={ele.defaultValue}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      type="date"
                      label={ele.label}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors[ele.name]}
                      helperText={
                        !!errors[ele.name] &&
                        `${t("estatisticas.format")} DD/MM/YYYY`
                      }
                    />
                  )}
                />
              </Grid>
            ))}

            <Grid item xs={12} md={4}>
              <Button
                fullWidth
                disabled={aguardar}
                variant="contained"
                color="primary"
                startIcon={
                  aguardar ? (
                    <CircularProgress size={20} />
                  ) : (
                    <FindReplaceIcon />
                  )
                }
                onClick={handleSubmit(fn)}
              >
                {t("estatisticas.btnSend")}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </Container>
  );
};

export default FiltroDataDeEAte;
