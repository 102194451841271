import React, { memo, useCallback, useEffect, useState } from "react";
import BackgroundRouter from "../../background-router";
import _ from "lodash";
import {
  AnimacaoSemDados,
  Body1,
  Body2,
  Caption,
  H5,
  H6,
  Icone,
  Pesquisando,
  ScrollInfinito,
} from "../../../components";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Chip,
  Container,
  Divider,
  Grid,
  Grow,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  Paper,
  Slide,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ouvidoriaAplicarFiltro,
  ouvidoriaFecharModal,
  ouvidoriaInit,
  ouvidoriaLimparDados,
  ouvidoriaLimparFiltro,
  ouvidoriaPagina,
  ouvidoriaSetModal,
} from "./ouvidoria-actions";
import { useToggle } from "react-use";
import { blue, green, grey, red } from "@mui/material/colors";
import Utils from "../../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import OuvidoriaAdd from "./ouvidoria-add";
import { DrawerDialog } from "../../../extra-components";
import OuvidoriaModal from "./ouvidoria-modal";
import OuvidoriaDetalhes from "./ouvidoria-detalhes";
import OuvidoriaBuscaPorToken from "./ouvidoria-busca-por-token";
import OuvidoriaFiltroAvancado from "./ouvidoria-filtro-avancado";
import { useTranslation } from "react-i18next";
import {
  selectDados,
  selectFiltro,
  selectFiltroAplicado,
  selectModal,
  selectSituacao,
  selectTemas,
  selectTipos,
  selectTotal,
  selectTotalPaginas,
} from "./ouvidoria-seletores";
//
const alturaPagina = "calc(100vh - 72px)";
const alinhamentoTexto = "center";

function Ouvidoria() {
  const history = useHistory();
  const [aguardar, setAguardar] = useToggle();
  const dispatch = useDispatch();
  const dados = useSelector(selectDados);
  const modal = useSelector(selectModal);
  const fecharModal = useCallback(
    () => dispatch(ouvidoriaFecharModal()),
    [dispatch]
  );

  useEffect(() => {
    dispatch(ouvidoriaInit(setAguardar));

    return () => {
      const { action, location } = history;
      if (
        action === "PUSH" &&
        _.toLower(location.pathname).search("/ouvidoria") === -1
      ) {
        dispatch(ouvidoriaLimparDados());
      }
    };
  }, [dispatch, setAguardar, history]);
  return (
    <BackgroundRouter>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => <OuvidoriaModal modal={modal} />}
          fecharModal={fecharModal}
        />
      )}
      {aguardar ? <Pesquisando /> : dados ? <RenderCorpo /> : null}
    </BackgroundRouter>
  );
}
//
const RenderCorpo = () => {
  const isMobile = useTheme()?.isMobile;
  return isMobile ? <CorpoMobile /> : <CorpoDesktop />;
};
//
const CorpoMobile = () => {
  return (
    <Container
      disableGutters
      sx={{
        px: 1,
        background: ({ palette }) => palette.mode !== "dark" && grey[200],
      }}
    >
      <PainelPrincipalOuvidoria isMobile />
    </Container>
  );
};
//
const PainelLateral = () => {
  const isDark = useTheme().palette.mode === "dark";

  return (
    <Paper
      sx={{
        borderRadius: 0,
        p: 1,
        height: alturaPagina,
        overflowY: "auto",
        background: !isDark && blue[50],
        color: !isDark && "black",
      }}
      elevation={isDark ? 4 : 0}
    >
      <BotaoAddOuvidoria />
      <Divider sx={{ mt: 1 }} />
      <Temas />
      <Tipos />
      <Situacao />
    </Paper>
  );
};
//
const BotaoAddOuvidoria = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useTheme()?.isMobile;
  const history = useHistory();
  const addOuvidoria = useCallback(() => {
    if (isMobile) {
      // Se for mobile insere como uma rota na pilha
      history.push(OuvidoriaAdd.rota);
    } else {
      // Abre um dialog na tela
      dispatch(
        ouvidoriaSetModal({
          tipo: OuvidoriaModal.MODAL.ADD_OUVIDORIA,
        })
      );
    }
  }, [history, isMobile, dispatch]);
  //
  const buscaPorTokenOuvidoria = useCallback(() => {
    if (isMobile) {
      // Se for mobile insere como uma rota na pilha
      history.push(OuvidoriaBuscaPorToken.rota);
    } else {
      // Abre um dialog na tela
      dispatch(
        ouvidoriaSetModal({
          tipo: OuvidoriaModal.MODAL.BUSCA_POR_TOKEN,
        })
      );
    }
  }, [history, isMobile, dispatch]);
  //
  const opcoes = [
    {
      onClick: buscaPorTokenOuvidoria,
      nome: t("ouvidoria_view.labelBtnSearch"),
      icone: "Search",
    },
    {
      onClick: addOuvidoria,
      nome: t("ouvidoria_view.labelBtnAdd"),
      icone: "Add",
    },
  ];
  //
  return isMobile ? (
    <SpeedDial
      ariaLabel="SpeedDial"
      sx={{ position: "fixed", bottom: 72, right: 16 }}
      icon={<SpeedDialIcon />}
    >
      {opcoes.map((opcao) => (
        <SpeedDialAction
          onClick={opcao.onClick}
          key={opcao.nome}
          icon={<Icone icone={opcao.icone} />}
          tooltipTitle={opcao.nome}
        />
      ))}
    </SpeedDial>
  ) : (
    <Stack direction="row" spacing={1}>
      <Button
        fullWidth
        startIcon={<Icone icone="Add" />}
        color="primary"
        variant="contained"
        title={t("ouvidoria_view.titleBtnAdd")}
        onClick={addOuvidoria}
      >
        {t("ouvidoria_view.labelBtnAdd")}
      </Button>
      <Button
        fullWidth
        startIcon={<Icone icone="Search" />}
        color="primary"
        variant="outlined"
        title={t("ouvidoria_view.titleBtnSearch")}
        onClick={buscaPorTokenOuvidoria}
      >
        {t("ouvidoria_view.labelBtnSearch")}
      </Button>
    </Stack>
  );
};
//
const BotaoPainelLateral = ({ total, texto, onClick, isFiltroAplicado }) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        p: 0.5,
        px: 1,
        borderRadius: 2,
        width: "100%",
        background: ({ palette }) => isFiltroAplicado && palette.primary.main,
      }}
    >
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Body1
          sx={{
            color: ({ palette }) =>
              isFiltroAplicado
                ? palette.primary.contrastText
                : palette.primary.main,
          }}
        >
          {texto}
        </Body1>
        <Chip
          label={total}
          sx={{
            fontWeight: "bold",
            background: isFiltroAplicado ? grey[200] : "none",
            color: ({ palette }) =>
              isFiltroAplicado ? palette.primary.main : "none",
          }}
        />
      </Stack>
    </ButtonBase>
  );
};
//
const Temas = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const temas = useSelector(selectTemas);
  const filtroAplicado = useSelector(selectFiltroAplicado);
  let valorFiltroAplicado;
  //
  if (filtroAplicado && filtroAplicado.hasOwnProperty("tema")) {
    valorFiltroAplicado = filtroAplicado["tema"];
  }
  //
  const aplicarFiltro = useCallback(
    (valorFiltro) => {
      if (
        filtroAplicado &&
        filtroAplicado.hasOwnProperty("tema") &&
        filtroAplicado["tema"] === valorFiltro
      ) {
        dispatch(ouvidoriaLimparFiltro());
      } else {
        dispatch(ouvidoriaAplicarFiltro("tema", valorFiltro));
      }
    },
    [dispatch, filtroAplicado]
  );

  return (
    <Stack sx={{ mt: 2 }}>
      <H5>{t("ouvidoria_view.themePanel")}</H5>
      {temas?.map((ele) => (
        <Grow in key={ele[0]}>
          <Box sx={{ my: 0.5 }}>
            <BotaoPainelLateral
              onClick={() => aplicarFiltro(ele[0])}
              texto={ele[1]}
              total={ele[2]}
              isFiltroAplicado={valorFiltroAplicado === ele[0]}
            />
          </Box>
        </Grow>
      ))}
      <Divider />
    </Stack>
  );
};
//
const Tipos = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tipos = useSelector(selectTipos);
  const filtroAplicado = useSelector(selectFiltroAplicado);
  let valorFiltroAplicado;
  //
  if (filtroAplicado && filtroAplicado.hasOwnProperty("tipo")) {
    valorFiltroAplicado = filtroAplicado["tipo"];
  }
  //
  const aplicarFiltro = useCallback(
    (valorFiltro) => {
      if (
        filtroAplicado &&
        filtroAplicado.hasOwnProperty("tipo") &&
        filtroAplicado["tipo"] === valorFiltro
      ) {
        dispatch(ouvidoriaLimparFiltro());
      } else {
        dispatch(ouvidoriaAplicarFiltro("tipo", valorFiltro));
      }
    },
    [dispatch, filtroAplicado]
  );
  return (
    <Stack sx={{ mt: 2 }}>
      <H5>{t("ouvidoria_view.typePanel")}</H5>
      {tipos?.map((ele) => (
        <Grow in key={ele[0]}>
          <Box sx={{ my: 0.5 }}>
            <BotaoPainelLateral
              onClick={() => aplicarFiltro(ele[0])}
              texto={ele[1]}
              total={ele[2]}
              isFiltroAplicado={valorFiltroAplicado === ele[0]}
            />
          </Box>
        </Grow>
      ))}
      <Divider />
    </Stack>
  );
};
//
const Situacao = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const situacoes = useSelector(selectSituacao);
  const filtroAplicado = useSelector(selectFiltroAplicado);
  let valorFiltroAplicado;
  //
  if (filtroAplicado && filtroAplicado.hasOwnProperty("status")) {
    valorFiltroAplicado = filtroAplicado["status"];
  }
  //
  const aplicarFiltro = useCallback(
    (valorFiltro) => {
      if (
        filtroAplicado &&
        filtroAplicado.hasOwnProperty("status") &&
        filtroAplicado["status"] === valorFiltro
      ) {
        dispatch(ouvidoriaLimparFiltro());
      } else {
        dispatch(ouvidoriaAplicarFiltro("status", valorFiltro));
      }
    },
    [dispatch, filtroAplicado]
  );

  return (
    <Stack sx={{ mt: 2 }}>
      <H5>{t("ouvidoria_view.ombudsmanStatus")}</H5>
      {situacoes?.map((ele) => (
        <Grow in key={ele[0]}>
          <Box sx={{ my: 0.5 }}>
            <BotaoPainelLateral
              onClick={() => aplicarFiltro(ele[0])}
              texto={ele[1]}
              total={ele[2]}
              isFiltroAplicado={valorFiltroAplicado === ele[0]}
            />
          </Box>
        </Grow>
      ))}
      <Divider />
    </Stack>
  );
};
//
const CorpoDesktop = () => {
  const isDark = useTheme().palette.mode === "dark";

  return (
    <Grid container sx={{ background: !isDark && grey[200] }}>
      <Grid item md={4} lg={3} xl={2}>
        <PainelLateral />
      </Grid>
      <Grid item md={8} lg={9} xl={10}>
        <PainelPrincipalOuvidoria />
      </Grid>
    </Grid>
  );
};
//
const PainelPaginacao = memo(({ total }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [paginaAtual, setPaginaAtual] = useState(1);
  const isMobile = useTheme()?.isMobile;
  const totalPaginas = useSelector(selectTotalPaginas);
  const filtroAplicado = useSelector(selectFiltroAplicado);

  //
  const onChangePagina = useCallback(
    (e, pagina) => {
      setPaginaAtual(pagina);
      dispatch(ouvidoriaPagina(pagina));
    },
    [dispatch]
  );

  return (
    <>
      <Divider sx={{ my: 1 }} />
      <FiltrosAvancados />
      <Divider sx={{ my: 1 }} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <TotalOuvidoria total={total} />
        {filtroAplicado ? (
          <div />
        ) : (
          <Pagination
            size={isMobile ? "small" : "medium"}
            title={t("ouvidoria_view.labelComponentPagination")}
            color="primary"
            count={totalPaginas}
            page={paginaAtual}
            onChange={onChangePagina}
          />
        )}
      </Stack>
      <Divider sx={{ my: 1 }} />
    </>
  );
});
//
const FiltrosAvancados = () => {
  const { t } = useTranslation();
  const filtroAplicado = useSelector(selectFiltroAplicado);
  const isMobile = useTheme().isMobile;
  const history = useHistory();
  const dispatch = useDispatch();
  const acionarFiltro = useCallback(() => {
    if (filtroAplicado === "FiltroAvancado") {
      dispatch(ouvidoriaLimparFiltro());
    } else {
      // Se for mobile deve-se atribuir nova rota
      if (isMobile) {
        history.push(OuvidoriaFiltroAvancado.rota);
      } else {
        // Abre o modal
        dispatch(
          ouvidoriaSetModal({
            tipo: OuvidoriaModal.MODAL.FILTRO_AVANCADO,
          })
        );
      }
    }
  }, [dispatch, filtroAplicado, history, isMobile]);

  const opcoes = [
    {
      icone: "FilterAlt",
      rotulo: t("ouvidoria_view.labelBtnFilter"),
      titulo: t("ouvidoria_view.titleBtnFilter"),
      onClick: acionarFiltro,
    },
  ];

  return (
    <Stack direction="row" alignItems="center">
      {opcoes?.map((ele) => (
        <Chip
          title={ele.titulo}
          key={ele.icone}
          label={ele.rotulo}
          icon={<Icone icone={ele.icone} />}
          color="primary"
          variant={filtroAplicado === "FiltroAvancado" ? "filled" : "outlined"}
          clickable
          onClick={ele.onClick}
        />
      ))}
    </Stack>
  );
};
//
const TotalOuvidoria = ({ total }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Icone icone="RecordVoiceOver" />
      <Body2 fontWeight="bold">
        {t("ouvidoria_view.total")} {total}
      </Body2>
    </Stack>
  );
};
//
const PainelPrincipalOuvidoria = ({ isMobile }) => {
  const { t } = useTranslation();
  const corpo = useSelector(selectDados);
  const filtro = useSelector(selectFiltro);
  const totalGeral = useSelector(selectTotal);

  const itensOuvidoria = filtro || corpo;
  const total = filtro ? filtro.length : totalGeral;

  return (
    <Stack sx={{ px: isMobile ? null : 1, width: "100%" }}>
      <PainelPaginacao total={total} />
      {!isMobile && <CabecalhoItemOuvidoria />}
      {isMobile && <BotaoAddOuvidoria />}
      {itensOuvidoria?.length > 0 ? (
        <ScrollInfinito
          itens={itensOuvidoria}
          itensPorPagina={10}
          tamanho="80vh"
          render={(ele) => (
            <Slide direction="left" key={ele.id} in mountOnEnter unmountOnExit>
              <Box>
                <ItemOuvidoria {...ele} isMobile={isMobile} />
              </Box>
            </Slide>
          )}
        />
      ) : (
        <AnimacaoSemDados titulo={t("ouvidoria_view.noData")} />
      )}
    </Stack>
  );
};
//
const CabecalhoItemOuvidoria = () => {
  const { t } = useTranslation();
  const itens = [
    { sx: {}, texto: t("ouvidoria_view.id") },
    { sx: {}, texto: t("ouvidoria_view.plant") },
    { sx: { flex: 1 }, texto: t("ouvidoria_view.owner") },
    { sx: { flex: 1 }, texto: t("ouvidoria_view.theme") },
    { sx: { flex: 2 }, texto: t("ouvidoria_view.subject") },
    { sx: { flex: 1 }, texto: t("ouvidoria_view.situation") },
    { sx: { flex: 1 }, texto: t("ouvidoria_view.dateCreated") },
    { sx: { flex: 1 }, texto: t("ouvidoria_view.lastInteraction") },
  ];

  return (
    <Paper sx={{ mb: 1 }}>
      <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
        {itens.map((ele, idx) => (
          <Button key={idx} sx={ele.sx} onClick={() => {}}>
            {ele.texto}
          </Button>
        ))}
      </Stack>
    </Paper>
  );
};
//
const ItemOuvidoria = ({
  isMobile,
  id_ouvidoria,
  tipo,
  planta,
  tema,
  situacao,
  solicitante,
  avatar,
  assunto,
  matricula,
  data_criacao,
  texto,
  ultima_interacao,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const verDetalhesOuvidoria = useCallback(() => {
    history.push(OuvidoriaDetalhes.rota, {
      idOuvidoria: id_ouvidoria,
      tipo,
      planta,
      tema,
      situacao,
      solicitante,
      avatar,
      assunto,
      matricula,
      texto,
      dataCriacao: data_criacao,
      ultimaInteracao: ultima_interacao,
    });
  }, [
    history,
    id_ouvidoria,
    tipo,
    planta,
    tema,
    situacao,
    solicitante,
    avatar,
    assunto,
    matricula,
    data_criacao,
    ultima_interacao,
    texto,
  ]);
  //
  return (
    <Paper sx={{ my: 1 }} title={null} elevation={1}>
      <ListItemButton dense onClick={verDetalhesOuvidoria}>
        {isMobile ? (
          <ListItem sx={{ width: "100%" }}>
            <ListItemAvatar>
              <Avatar alt={solicitante} src={avatar} />
            </ListItemAvatar>

            <ListItemText
              primary={solicitante}
              secondary={
                <Stack spacing={1}>
                  <Stack direction="row" justifyContent="space-between">
                    <Body2 align={alinhamentoTexto}>
                      {t("ouvidoria_view.ombudsman")}
                      {id_ouvidoria}
                    </Body2>
                    <ChipSituacao situacao={situacao} />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Caption fontWeight="bold">
                      {t("ouvidoria_view.planta")}
                      {planta}
                    </Caption>
                    <Caption>
                      {t("ouvidoria_view.interaction")}
                      {Utils.converterDataHora(ultima_interacao)}
                    </Caption>
                  </Stack>
                  <Caption
                    fontWeight="bold"
                    sx={{ color: ({ palette }) => palette.primary.main }}
                    align="left"
                  >
                    {assunto}
                  </Caption>
                </Stack>
              }
            />
          </ListItem>
        ) : (
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            alignItems="center"
            flexWrap="nowrap"
            spacing={1}
          >
            <H6
              sx={{ pr: 3 - id_ouvidoria.toString().length }}
              align={alinhamentoTexto}
            >
              {id_ouvidoria}
            </H6>
            <Chip label={planta} color="primary" variant="outlined" />
            <ListItem dense disableGutters sx={{ flex: 2 }}>
              <ListItemAvatar>
                <Avatar alt={solicitante} src={avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Body2>{solicitante}</Body2>
                  </Stack>
                }
                secondary={matricula}
              />
            </ListItem>

            <Body1 sx={{ flex: 1 }}>{tema}</Body1>
            <Body1 sx={{ flex: 2 }}>{assunto}</Body1>
            <Box sx={{ flex: 1 }} align={alinhamentoTexto}>
              {situacao && <ChipSituacao situacao={situacao} />}
            </Box>
            <ListItem dense disableGutters sx={{ flex: 1 }}>
              <ListItemText
                secondary={t("ouvidoria_view.dataCriacao")}
                primary={Utils.converterDataHora(data_criacao)}
              />
            </ListItem>
            <ListItem dense disableGutters sx={{ flex: 1 }}>
              <ListItemText
                secondary={t("ouvidoria_view.ultimaInteracao")}
                primary={Utils.converterDataHora(ultima_interacao)}
              />
            </ListItem>
          </Stack>
        )}
      </ListItemButton>
    </Paper>
  );
};
export const ChipSituacao = ({ situacao }) => {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;
  let corSituacao;
  let statusName;
  switch (situacao) {
    case "Em Aberto":
      statusName = t("ouvidoria_view.statusOpen");
      corSituacao = green[600];
      break;
    case "Encerrada":
      statusName = t("ouvidoria_view.statusClosed");
      corSituacao = red[600];
      break;
    case "Em Analise":
      statusName = t("ouvidoria_view.statusMonitoring");
      corSituacao = blue[600];
      break;
    default:
      statusName = t("ouvidoria_view.statusOpen");
      corSituacao = grey[200];
      break;
  }

  return (
    <Chip
      size={isMobile ? "small" : "medium"}
      label={statusName}
      sx={{
        background: corSituacao,
        color: "white",
      }}
    />
  );
};

Ouvidoria.rota = "/ouvidoria_view";

export default Ouvidoria;
