import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { cartaoSegurancaSetEmAnalise } from "../../redux/actions";
import {
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { format } from "date-fns";
import { Icone } from "../../components";
import { useTranslation } from "react-i18next";

// Schema para validar a movimentacao de um status para a analise
const schemaEmAnalise = yup.object().shape({
  data_prevista: yup.date().required(),
  causa_raiz: yup.string().min(3).required(),
  acao_prevista: yup.string().min(3).required(),
});

// Componente para criar formulario de movimentacao
const FormMoverAnalise = ({ id_cartao, matricula }) => {
  const { t } = useTranslation();
  const aguardar = useSelector((state) => state.aguardar);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaEmAnalise),
  });
  const dispatch = useDispatch();

  const fn = (val) => {
    dispatch(
      cartaoSegurancaSetEmAnalise({
        acao_prevista: val.acao_prevista,
        causa_raiz: val.causa_raiz,
        id_cartao,
        data_prevista: format(val.data_prevista, "yyyy-MM-dd"),
      })
    );
  };

  return (
    <Container maxWidth="md">
      <Stack>
        <Typography variant="h6" align="center">
          {t("cartao_seguranca.titleMoveToAnalitycs")}
        </Typography>
        <Typography sx={{ opacity: 0.7 }} variant="caption" align="center">
          {t("cartao_seguranca.subtitleMoveToAnalitycs")}
        </Typography>
        <Controller
          name="causa_raiz"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              sx={{ my: 1 }}
              fullWidth
              type="textarea"
              minRows={1}
              multiline
              {...field}
              label={t("cartao_seguranca.labelRootCause")}
              InputLabelProps={{ shrink: true }}
              placeholder={t("cartao_seguranca.placeholderRootCause")}
              size="small"
              error={!!errors.causa_raiz}
              helperText={
                !!errors.causa_raiz && t("cartao_seguranca.errorMinChar")
              }
            />
          )}
        />
        <Controller
          name="acao_prevista"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              sx={{ my: 1 }}
              fullWidth
              type="textarea"
              minRows={3}
              multiline
              {...field}
              label={t("cartao_seguranca.labelAction")}
              InputLabelProps={{ shrink: true }}
              placeholder={t("cartao_seguranca.placeholderAction")}
              size="small"
              error={!!errors.acao_prevista}
              helperText={
                !!errors.acao_prevista && t("cartao_seguranca.errorMinChar")
              }
            />
          )}
        />
        <Controller
          name="data_prevista"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              sx={{ my: 1 }}
              fullWidth
              type="date"
              {...field}
              label={t("cartao_seguranca.labelDatePrev")}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors.data_prevista}
              helperText={!!errors.data_prevista && "DD/MM/AAAA"}
            />
          )}
        />
        <TextField
          sx={{ my: 1 }}
          fullWidth
          type="text"
          disabled
          label={t("cartao_seguranca.labelResponsible")}
          InputLabelProps={{ shrink: true }}
          size="small"
          value={matricula}
        />

        <Stack
          sx={{ my: 1 }}
          direction="row"
          justifyContent={{ md: "center", xs: "stretch" }}
        >
          <Button
            variant="contained"
            startIcon={
              aguardar ? <CircularProgress size={20} /> : <Icone icone="Save" />
            }
            onClick={handleSubmit(fn)}
          >
            {t("cartao_seguranca.textBtnSave")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default FormMoverAnalise;
