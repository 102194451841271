import {
    OPORTUNIDADES_LIMPAR,
    OPORTUNIDADES_INIT,
    OPORTUNIDADES_AGUARDAR,
    OPORTUNIDADES_FECHAR_MODAL,
    OPORTUNIDADES_VER_MODAL,
    OPORTUNIDADES_CANDIDATO,
    OPORTUNIDADES_CANDIDATOS_RESPOSTAS,
    OPORTUNIDADES_CANDIDATOS_RESPOSTAS_LIMPAR,
    OPORTUNIDADES_ALTERA_FASE,
} from './oportunidades_actions';

const INIT = {
    aguardar: null,
    modal: null,
    fases: null,
    dados: null,
    registradosVaga: [],
}

export default function oportunidadesReducer(state = INIT, action){
    switch(action.type){
        case OPORTUNIDADES_ALTERA_FASE: // Alterar o status da vaga
            return {
                ...state,
                registradosVaga: state.registradosVaga.map((ele)=>{
                    if(String(ele.id) === String(action.data.id_vaga)){
                        ele.fase = action.data.para_fase;
                    }
                    return ele;
                }),
            }
        case OPORTUNIDADES_CANDIDATOS_RESPOSTAS_LIMPAR: // Limpa os candidatos a vaga
            return {
                ...state,
                registradosVaga: [],
            }
        case OPORTUNIDADES_CANDIDATOS_RESPOSTAS: // Candidatos registrados a vaga
            return {
                ...state,
                registradosVaga: action.data,
            }
        case OPORTUNIDADES_CANDIDATO:  // Atualização do registro do candidato a vaga
            return (()=>{
                const _dados = [...state.dados];
                _dados.forEach(ele=>{
                    if(ele.vaga === action.data){
                        ele.me_registrei = true;
                    }
                });
                return {
                    ...state,
                    dados: _dados,
                }
            })();
        case OPORTUNIDADES_VER_MODAL: // Ativa o modal para exibicao
            return {
                ...state,
                modal: action.data,
            }
        case OPORTUNIDADES_FECHAR_MODAL: // Fecha o modal para exibicao
            return {
                ...state,
                modal: null,
            }
        case OPORTUNIDADES_LIMPAR: // Limpa os dados das oportunidades
            return INIT;
        case OPORTUNIDADES_INIT:
            return {
                ...state,
                dados: action.data.vagas,
                fases: action.data.fases,
            };
        case OPORTUNIDADES_AGUARDAR: // Ativa/desativa o loading
            return {
                ...state,
                aguardar: action.data
            }
        default:
            return state;
    }

}