import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import _ from "lodash";
import { useCallback } from "react";
import * as yup from "yup";
import { oportunidadesCandidatarSe } from "./oportunidades_actions";
import { EntradaForm } from "../../../components";
import { escolaridade } from "./oportunidades-utils";
import { useTranslation } from "react-i18next";

// Formulario de registro
const FormRegistroAVaga = ({ vaga, id_questao, perguntas }) => {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();

  const schema = [
    {
      label: t("oportunidades.labelEmail"),
      placeholder: t("oportunidades.placeholderEmail"),
      icon: "Email",
      type: "email",
      name: "email",
      required: true,
    },
    {
      label: t("oportunidades.labelPhone"),
      placeholder: t("oportunidades.placeholderPhone"),
      icon: "Phone",
      type: "text",
      name: "celular",
      required: true,
      mask: [
        "(",
        /\d/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
    },
    {
      label: t("oportunidades.labelSchooling"),
      icon: "School",
      type: "select",
      autoFormat: true,
      itens: escolaridade(t),
      name: "escolaridade",
      required: true,
    },
  ];
  let schemaValidator = {},
    schemaMessageError = {};

  _.forEach(perguntas, (val) => {
    //
    const nomePerg = `${
      val.opcoes?.length > 0 ? "R" : "T"
    }_${val.id_pergunta.toString()}`;
    schema.push({
      type: val.opcoes?.length === 0 ? "textarea" : "radio",
      extraProps: {
        multiline: val.opcoes?.length === 0,
        minRows: val.opcoes?.length === 0 ? 2 : 1,
      },
      wrapperPaperProps: {
        elevation: 3,
        sx: { p: 1, my: 1 },
      },
      counter: true,
      defaultValue: "",
      placeholder: t("oportunidades.placeholderWriteHere"),
      autoFormat: val.opcoes?.length > 0,
      itens: val.opcoes,
      name: nomePerg,
      titulo: val.descricao,
    });
    // Validando o campo
    schemaValidator[nomePerg] =
      val.opcoes?.length > 0
        ? yup.string().min(1).required()
        : yup.string().min(3).required();
    // Cria o schema de erro
    schemaMessageError[nomePerg] = t("oportunidades.errorQuestion");
  });
  schema.push({
    type: "file",
    name: "arquivo",
    multiple: true,
  });

  // Envolve o schemaValidador em um shape
  schemaValidator = yup.object().shape(schemaValidator);
  //
  const onSubmit = useCallback(
    (val) => {
      const obj = {
        id_questao: id_questao,
        vaga: vaga,
        escolaridade: val.escolaridade.value,
        email: val.email,
        celular: val.celular
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .trim(),
        respostas: [],
      };
      Object.keys(val).forEach((k) => {
        const [tipo, idPerg] = k.split("_");
        if (!isNaN(idPerg)) {
          obj["respostas"].push({
            id_pergunta: parseInt(idPerg),
            id_opcao: tipo === "T" ? 0 : parseInt(val[k]),
            texto: tipo === "T" ? val[k] : "",
          });
        }
      });

      const formData = new FormData();
      formData.append("dados", JSON.stringify(obj));
      if (val.arquivo) {
        [...val.arquivo].forEach((arq) => {
          formData.append("arquivo", arq);
        });
      }
      //
      dispatch(oportunidadesCandidatarSe(formData, setWait));
    },
    [vaga, id_questao, dispatch, setWait]
  );

  return (
    <EntradaForm
      schema={schema}
      schemaMessageError={schemaMessageError}
      schemaValidator={schemaValidator}
      wait={wait}
      onSubmit={onSubmit}
    />
  );
};

export default FormRegistroAVaga;
