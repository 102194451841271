//
export const selectDados = (state) => state?.ouvidoria?.dados;
export const selectModal = (state) => state?.ouvidoria?.modal;
export const selectTotalPaginas = (state) =>
  state?.ouvidoria?.quantidade_paginas;
export const selectTotal = (state) => state?.ouvidoria?.total;
export const selectTemas = (state) => state?.ouvidoria?.tema;
export const selectTipos = (state) => state?.ouvidoria?.tipo;
export const selectSituacao = (state) => state?.ouvidoria?.situacao;
export const selectFiltroAplicado = (state) => state?.ouvidoria?.filtroAplicado;
export const selectFiltro = (state) => state?.ouvidoria?.filtro;
export const selectIsRH = (state) =>
  state?.usuario?.variaveis?.includes("GRUPO_RH");
export const selectIds = (state) => state?.ouvidoria?.ids;
