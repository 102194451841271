import {
  Avatar,
  Button,
  Container,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Body1, Body2, Icone, Subtitle1 } from "../../../components";
import { oportunidadesFecharModal } from "./oportunidades_actions";
import DetalheCandidatoAVaga from "./oportunidades-detalhe-candidato-vaga";
import Utils from "../../../utils/utils";
import { baixarPDF } from "./oportunidades-utils";
import FormAlteracaoFase from "./oportunidades-form-alter-fase";
import { useTranslation } from "react-i18next";

// Lista com as respostas informadas pelo candidato
const RespostasCandidatoAVaga = ({
  nome,
  funcao,
  avatar,
  matricula,
  id_vaga,
  respostas,
  arvore,
  data_inscricao,
  escolaridade,
  email,
  celular,
  anexos,
  vaga,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Container>
      <Hidden smUp>
        <Button
          sx={{ mb: 1 }}
          fullWidth
          startIcon={<Icone icone="Close" />}
          size="small"
          variant="contained"
          onClick={() => dispatch(oportunidadesFecharModal())}
        >
          {t("oportunidades.labelBtnClose")}
        </Button>
      </Hidden>
      <Stack>
        <Stack direction="row" spacing={1}>
          <Avatar src={avatar} alt={nome} />
          <Stack>
            <Body1>{funcao}</Body1>
            <Body2>{nome}</Body2>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          justifyContent={{ md: "space-between" }}
          direction={{ xs: "column", md: "row" }}
        >
          <DetalheCandidatoAVaga
            icone="School"
            descricao={escolaridade}
            titulo={t("oportunidades.titleScholarship")}
          />
          <DetalheCandidatoAVaga
            icone="WhatsApp"
            descricao={
              celular
                ? `(${celular.substring(0, 2)}) ${celular.substring(2)}`
                : celular
            }
            titulo={t("oportunidades.titlePhone")}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }}>
          <DetalheCandidatoAVaga
            icone="Event"
            descricao={Utils.converterDataHora(data_inscricao)}
            titulo={t("oportunidades.titleDateInscription")}
          />
          <DetalheCandidatoAVaga
            icone="AccountTree"
            descricao={arvore}
            titulo={t("oportunidades.titleSector")}
          />
          <DetalheCandidatoAVaga
            icone="Email"
            descricao={email}
            titulo={t("oportunidades.titleEmail")}
          />
        </Stack>
        <Subtitle1>{t("oportunidades.titleAttachment")}</Subtitle1>
        <Stack spacing={1} direction="row" flexWrap="wrap">
          {anexos?.map((ele, idx) => (
            <Button
              href={ele.arquivo}
              target="_blank"
              variant="outlined"
              key={idx}
              size="small"
            >
              {idx + 1}° {t("oportunidades.attachment")}
            </Button>
          ))}
        </Stack>
        <Divider sx={{ mt: 1 }} />
        <Subtitle1>{t("oportunidades.titleAnswerQuestionnaire")}</Subtitle1>
        <List>
          {respostas?.map((ele, idx) => (
            <ListItem disablePadding key={idx} divider>
              <ListItemIcon>
                <Icone icone="Comment" />
              </ListItemIcon>
              <ListItemText
                primary={ele.descricao}
                secondary={`R: ${
                  ele.id_opcao?.length > 0 ? ele.id_opcao : ele.texto
                }`}
              />
            </ListItem>
          ))}
        </List>
        <Button
          title={t("oportunidades.titleBtnDownloadPdf")}
          sx={{ my: 1 }}
          variant="contained"
          startIcon={<Icone icone="Download" />}
          onClick={() => baixarPDF(vaga, matricula, t)}
        >
          {t("oportunidades.labelBtnDownloadPdf")}
        </Button>
        <Paper sx={{ p: 1 }} elevation={3}>
          <FormAlteracaoFase id_vaga={id_vaga} />
        </Paper>
      </Stack>
    </Container>
  );
};

export default RespostasCandidatoAVaga;
