import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import { Container, Stack } from "@mui/material";
import { selectVariavelGlobalSelecionada } from "./manutencao-variaveis-selectors";
import { Caption, EntradaForm, H6, Tab } from "../../../components";
import { manutencaoVariaveisUpdDescricaoValorVariavelGlobal } from "./manutencao-variaveis-actions";
import { useTranslation } from "react-i18next";

// Este componente exibe um tab que permite fazer a edição da variavel por valor ou descricao
const FormEditVariavelGlobal = memo(() => {
  const { t } = useTranslation();
  const variavel = useSelector(selectVariavelGlobalSelecionada);
  //
  const cabe = [
    t("manutencao_variaveis.tabVarGlobal1"),
    t("manutencao_variaveis.tabVarGlobal2"),
  ];

  return (
    <Container maxWidth="md" sx={{ py: 2, height: "55vh", overflow: "auto" }}>
      <Tab
        cabe={cabe}
        corpo={[
          <FormEditarValorVarGlobal variavel={variavel} />,
          <FormEditarDescricaoVarGlobal variavel={variavel} />,
        ]}
      />
    </Container>
  );
});

// Componente para editar o valor da variavel global
const FormEditarValorVarGlobal = memo(({ variavel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schema = [
    {
      label: t("manutencao_variaveis.labelEditVarGlobalValue"),
      placeholder: t("manutencao_variaveis.placeholderEditVarGlobalValue"),
      name: "valor",
      type: "text",
      defaultValue: variavel.valor,
      grid: { xs: 12 },
    },
  ];
  const schemaValidator = yup.object().shape({
    valor: yup.string().min(1).required(),
  });
  const schemaMessageError = {
    valor: t("manutencao_variaveis.errorEditVarGlobalValue"),
  };
  const fn = (val) => {
    dispatch(
      manutencaoVariaveisUpdDescricaoValorVariavelGlobal(
        {
          id_variavel: variavel.id_variavel,
          valor: val.valor,
        },
        true
      )
    );
  };

  return (
    <Stack spacing={1}>
      <H6>{variavel.nome} </H6>
      <Caption>{t("manutencao_variaveis.descriptionEditVarGlobal")}</Caption>
      <EntradaForm
        schema={schema}
        schemaValidator={schemaValidator}
        schemaMessageError={schemaMessageError}
        onSubmit={fn}
      />
    </Stack>
  );
});

// Componente para editar a descricao da variavel global
const FormEditarDescricaoVarGlobal = memo(({ variavel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schema = [
    {
      name: "descricao",
      type: "textarea",
      label: t("manutencao_variaveis.labelEditVarGlobalDescription"),
      placeholder: t(
        "manutencao_variaveis.placeholderEditVarGlobalDescription"
      ),
      multiline: true,
      minRows: 2,
      defaultValue: variavel.descricao,
      grid: { xs: 12 },
    },
  ];
  const schemaValidator = yup.object().shape({
    descricao: yup.string().min(3).required(),
  });
  const schemaMessageError = {
    descricao: t("manutencao_variaveis.errorEditVarGlobalDescription"),
  };
  const fn = (val) => {
    dispatch(
      manutencaoVariaveisUpdDescricaoValorVariavelGlobal({
        id_variavel: variavel.id_variavel,
        descricao: val.descricao,
      })
    );
  };

  return (
    <Stack spacing={1}>
      <H6>{variavel.nome} </H6>
      <Caption>
        {t("manutencao_variaveis.descriptionEditVarGlobalDesc")}
      </Caption>
      <EntradaForm
        schema={schema}
        schemaValidator={schemaValidator}
        schemaMessageError={schemaMessageError}
        onSubmit={fn}
      />
    </Stack>
  );
});

export default FormEditVariavelGlobal;
