import {
  TRAINNING_INIT,
  TRAINNING_CLEAR_DATA,
  TRAINNING_ADD,
  TRAINNING_PAGE,
  TRAINNING_SET_MODAL,
  TRAINNING_CLOSE_MODAL,
  TRAINNING_EDIT,
  TRAINNING_ADD_STUDENTS,
  TRAINNING_ADD_COURSE,
  TRAINNING_EDIT_COURSE,
  TRAINNING_DEL_COURSE,
  TRAINNING_EDIT_INSTRUCTOR,
  TRAINNING_DEL_INSTRUCTOR,
  TRAINNING_ALL_INSTRUCTORS,
  TRAINNING_ADD_INSTRUCTOR,
  TRAINNING_ALTER_STATUS,
  TRAINNING_CLEAR_FILTER,
  TRAINNING_SET_FILTER,
} from "./treinamento-actions";

export default function treinamentoReducer(state = null, action) {
  switch (action.type) {
    case TRAINNING_INIT:
      return {
        ...state,
        ...action.data,
      };
    case TRAINNING_PAGE:
      return {
        ...state,
        registers: action.data,
      };
    case TRAINNING_ADD:
      return {
        ...state,
        registers: [action.data, ...state.registers],
        modal: null,
      };
    case TRAINNING_EDIT:
      return {
        ...state,
        registers: state.registers.map((item) =>
          item.id === action.data.id ? action.data : item
        ),
        modal: null,
      };
    case TRAINNING_ADD_STUDENTS:
      return {
        ...state,
        modal: null,
      };
    case TRAINNING_CLEAR_DATA:
      return null;
    case TRAINNING_SET_MODAL:
      return {
        ...state,
        modal: action.data,
      };
    case TRAINNING_CLOSE_MODAL:
      return {
        ...state,
        modal: null,
      };
    case TRAINNING_ADD_COURSE:
      return {
        ...state,
        cursos: [action.data, ...state.cursos],
        modal: null,
      };
    case TRAINNING_EDIT_COURSE:
      return {
        ...state,
        cursos: state.cursos.map((item) =>
          item.id === action.data.id ? action.data : item
        ),
        modal: null,
      };
    case TRAINNING_DEL_COURSE:
      return {
        ...state,
        cursos: state.cursos.filter((item) => item.id !== action.data),
        modal: null,
      };
    case TRAINNING_ALL_INSTRUCTORS:
      return {
        ...state,
        full_instructors: action.data,
      };
    case TRAINNING_ADD_INSTRUCTOR:
      return {
        ...state,
        full_instructors: [action.data, ...state.full_instructors],
        modal: null,
      };
    case TRAINNING_EDIT_INSTRUCTOR:
      return {
        ...state,
        full_instructors: state.full_instructors.map((item) =>
          item.id === action.data.id ? action.data : item
        ),
        modal: null,
      };
    case TRAINNING_DEL_INSTRUCTOR:
      return {
        ...state,
        full_instructors: state.full_instructors.filter(
          (item) => item.id !== action.data
        ),
        modal: null,
      };
    case TRAINNING_ALTER_STATUS:
      return {
        ...state,
        registers: state.registers.map((item) =>
          item.id === action.data.id ? action.data : item
        ),
        modal: null,
      };
    case TRAINNING_SET_FILTER:
      return {
        ...state,
        dataFilter: action.data.data,
        filter: action.data.filter,
      };
    case TRAINNING_CLEAR_FILTER:
      return {
        ...state,
        dataFilter: null,
        filter: null,
      };

    default:
      return state;
  }
}
