import { IDX_MENU_SELECIONADO } from "../actions/menu-actions";

export default function menuReducer(state = 0, action) {
  switch (action.type) {
    case IDX_MENU_SELECIONADO:
      return action.data;
    default:
      return state;
  }
}
