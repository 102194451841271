import React from "react";
import { Container, Typography, Paper, Stack, TextField } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

// Componente para exibir o historico da justificativa
const VerHistoricoJustificativa = ({ listaHistorico }) => {
  const { t } = useTranslation();
  return (
    <Container maxWidth="lg" sx={{ p: 1, height: "80vh" }}>
      <Typography variant="h6" align="center">
        {t("cadastro_projetos.titleJustify")}
      </Typography>
      <Typography sx={{ opacity: 0.6 }} variant="caption" align="center">
        {t("cadastro_projetos.subtitle")}
      </Typography>

      {listaHistorico?.map((ele, idx) => {
        const {
          descricao,
          descricao_acao,
          fator_atraso,
          acao,
          data_prevista,
          data_justificativa,
        } = ele;
        return (
          <Paper
            sx={{
              p: 2,
              my: 2,
            }}
            key={idx}
            elevation={2}
          >
            <Stack spacing={2}>
              <TextField
                label={t("cadastro_projetos.descriptionLabel")}
                type="textarea"
                value={descricao}
                multiline
                rows={3}
                size="small"
              />
              <TextField
                label={t("cadastro_projetos.delayFactorLabel")}
                type="text"
                value={fator_atraso}
                size="small"
              />

              <TextField
                label={t("cadastro_projetos.actionDescriptionLabel")}
                type="textarea"
                value={descricao_acao}
                multiline
                rows={3}
                size="small"
              />
              <TextField
                label={t("cadastro_projetos.executedActionLabel")}
                type="text"
                value={acao}
                size="small"
              />
              <TextField
                label={t("cadastro_projetos.newExpectedDateLabel")}
                type="datetime"
                size="small"
                value={format(parseISO(data_prevista), "dd/MM/yyy")}
              />
              <TextField
                label={t("cadastro_projetos.justificationDateLabel")}
                type="datetime"
                size="small"
                value={format(
                  parseISO(data_justificativa),
                  "dd/MM/yyy HH:mm:ss"
                )}
              />
            </Stack>
          </Paper>
        );
      })}
      <br />
    </Container>
  );
};

export default VerHistoricoJustificativa;
