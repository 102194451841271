import React from "react";
import { Aniversariantes, ContainerAdaptavel } from "../../../extra-components";
import FormAddUpdFeed from "./components/form-add-upd";
import FeedExcluir from "./components/feed-excluir";
import ListaComentarios from "./components/feed-lista-comentarios";
import ListCurtidas from "./components/feed-curtidas";

function FeedModal({ modal }) {
  let corpo;
  const dados = modal?.dados;
  switch (modal.tipo) {
    case FeedModal.modal.FEED_DRAWER_INTENCAO_FORM:
      corpo = <FormAddUpdFeed feed={dados} />;
      break;
    case FeedModal.modal.FEED_DEL_FEED:
      corpo = <FeedExcluir id_feed={dados} />;
      break;
    case FeedModal.modal.LISTAR_COMENTARIOS:
      corpo = <ListaComentarios comentarios={dados} />;
      break;
    case FeedModal.modal.LISTAR_CURTIDAS:
      corpo = <ListCurtidas curtidas={dados.data} id_feed={dados.id_feed} />;
      break;
    case FeedModal.modal.VER_ANIVERSARIANTES:
      corpo = <Aniversariantes />;
      break;
    default:
      break;
  }
  //
  return <ContainerAdaptavel>{corpo}</ContainerAdaptavel>;
}

FeedModal.modal = {
  FEED_DRAWER_INTENCAO_FORM: "FEED_DRAWER_INTENCAO_FORM",
  FEED_DEL_FEED: "FEED_DEL_FEED",
  LISTAR_COMENTARIOS: "LISTAR_COMENTARIOS",
  LISTAR_CURTIDAS: "LISTAR_CURTIDAS",
  VER_ANIVERSARIANTES: "VER_ANIVERSARIANTES",
};

export default FeedModal;
