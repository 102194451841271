import axios from 'axios';

const ROTA = '/info_tamanho_para_descricao'

export const INFO_TAMANHO_CAMPO = 'INFO_TAMANHO_CAMPO';

export const tamanhoCamposGet = ()=> dispatch => {
    axios.get(ROTA).then(resp=>{
        if(resp.status !== 200){
            alert('ERRO AO TENTAR OBTER DADOS DO USUARIO');
            return false;
        }
        // Veja se retornou algum erro
        if(resp.data.erro){
            alert(resp.data.erro);
            return false;
        }
        // Registra o tamanho dos campos
        dispatch({
            type: INFO_TAMANHO_CAMPO,
            data: resp.data
        });
    })
}