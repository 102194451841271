import {SET_ERRO} from '../actions';//

const erroReducer = (state = null, action)=>{
    switch (action.type) {
        case SET_ERRO: // DEFINE UMA MENSAGEM DE ERRO
            return action.valor;
        default:
            return state;
    }
}

export default erroReducer;