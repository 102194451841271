import { useSelector } from "react-redux";
import { useToggle } from "react-use";
import { selectIsRH } from "./oportunidades-seletores";
import { Button, Divider, Paper, Stack } from "@mui/material";
import { Body1, Body2, Icone } from "../../../components";
import { motion, AnimatePresence } from "framer-motion";
import { blue } from "@mui/material/colors";
import DataAbertura from "./oportunidades-data-abertura";
import StatusVaga from "./oportunidades-status-vaga";
import Mecandidatei from "./oportunidades-me-candidatei";
import ListaDeCandidatosAVaga from "./oportunidades-lista-candidato-vaga";
import { useTranslation } from "react-i18next";

//
const QuadroOportunidade = ({
  vaga,
  fase,
  funcao,
  atividade,
  data_abertura,
  data_fechamento,
  fnVerVaga,
  me_registrei,
}) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useToggle();
  // Verifica se o usuário é do grupo RH
  const isRH = useSelector(selectIsRH);

  return (
    <Paper
      elevation={3}
      sx={{
        cursor: "pointer",
        p: 1,
        backgroundColor: blue[100],
        color: "black",
        width: { md: "33.3%" },
      }}
      // onClick={fnVerVaga}
    >
      {/* <ButtonBase onClick={fnVerVaga}> */}
      <Stack onClick={fnVerVaga}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Icone icone="WorkOutline" sx={{ fontSize: 72 }} />
          <Stack flex={1}>
            <Body1 align="center">{funcao}</Body1>
            <Divider sx={{ my: 0.5 }} />
            <Body2 sx={{ whiteSpace: "pre-line" }} align="left">
              {atividade}
            </Body2>
            <Divider sx={{ my: 0.5 }} />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <DataAbertura dataAbertura={data_abertura} />
          <StatusVaga isPreenchida={data_fechamento?.length > 0} />
        </Stack>
        {me_registrei && <Mecandidatei fase={fase} />}
      </Stack>
      {/* </ButtonBase> */}
      {isRH && (
        <Button
          size="small"
          variant="contained"
          sx={{ mt: 1 }}
          fullWidth
          onClick={setToggle}
        >
          {toggle ? t("oportunidades.hidden") : t("oportunidades.show")}
        </Button>
      )}
      <AnimatePresence>
        {toggle && (
          <motion.div
            initial={{ scale: 0.01 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.01 }}
          >
            <ListaDeCandidatosAVaga vaga={vaga} />
          </motion.div>
        )}
      </AnimatePresence>
    </Paper>
  );
};

export default QuadroOportunidade;
