import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { seletorCardSecurityUser } from "./cartao-seguranca-selectors";
import * as yup from "yup";
import { format } from "date-fns";
import { cartaoSegurancaAddCartao } from "../../redux/actions";
import {
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";

import Select from "../../components/select";
import { formatarParaSelect } from "../../utils/utils";
import { Icone } from "../../components";
import { useTranslation } from "react-i18next";

// Schema para validar o formulario principal de insercao
const schema = yup.object().shape({
  data_abertura: yup.date().required(),
  id_tipo_cartao: yup.string().required(),
  descricao: yup.string().min(1).max(230).required(),
  id_area: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .required(),
  matricula: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .required(),
  turno: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .required(),
  id_categoria: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .required(),
  operacao: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .required(),
});

// Estilização do componente select
const selectStyles = {
  menu: (styles) => ({
    ...styles,
    zIndex: 10,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "black",
  }),
  option: (styles) => ({
    ...styles,
    color: "black",
    fontSize: 14,
  }),
};

// Painel para adicionar o cartao
const AddCartao = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Obtendo detalhes do lider (quem inclui) do cartao
  const usuario = useSelector(seletorCardSecurityUser);
  const aguardar = useSelector((state) => state.aguardar);

  // Estado para controlar a insercao dos anexos
  const [arquivos, setArquivos] = useState(null);
  // Funcao que lida com a inserção de anexos
  const addArquivo = (e) => {
    setArquivos(e.target.files);
  };
  // Separa os itens do objeto
  const {
    area,
    cartao_tipo,
    cartao_cor,
    cartao_tipo_x_categoria,
    modelos,
    operacao,
    turno,
    colaboradores,
  } = props;
  //
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Funcao para enviar o cartao
  const fn = (val) => {
    const obj = {
      id_area: parseInt(val.id_area.value),
      id_categoria: parseInt(val.id_categoria.value),
      id_tipo_cartao: parseInt(val.id_tipo_cartao),
      matricula: val.matricula.value,
      operacao: val.operacao.value,
      turno: val.turno.value,
      data_abertura: format(val.data_abertura, "yyyy-MM-dd"),
      descricao: val.descricao,
    };
    // Tipo de cartao seguranca
    if (obj.id_tipo_cartao === 1) {
      obj.id_modelo = parseInt(val.id_modelo.value);
    }
    // Verifica se tem arquivo
    if (arquivos?.length > 0) {
      obj["arquivo"] = arquivos;
    }
    // Submete os dados
    dispatch(cartaoSegurancaAddCartao(obj));
  };

  return (
    <Container sx={{ maxHeight: "80vh" }} maxWidth="md">
      <Controller
        name="id_tipo_cartao"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <Stack>
            <Typography align="center" variant="subtitle1">
              {t("cartao_seguranca.labelTypeCard")}
            </Typography>
            <RadioGroup
              sx={{ flexDirection: "row", justifyContent: "center" }}
              aria-label="id_tipo_cartao"
              {...field}
            >
              {cartao_tipo.map((ele, idx) => (
                <FormControlLabel
                  key={idx}
                  value={ele[0]}
                  label={<Typography variant="subtitle2">{ele[1]}</Typography>}
                  control={<Radio size="small" />}
                />
              ))}
            </RadioGroup>
          </Stack>
        )}
      />
      <AnimatePresence>
        {watch("id_tipo_cartao") && (
          <motion.div
            key={1}
            initial={{ scale: 0.01 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.01 }}
          >
            <Paper
              sx={{
                p: 1,
                color: "black",
                minHeight: "50vh",
                backgroundColor: cartao_cor[watch("id_tipo_cartao")],
              }}
            >
              <Stack
                sx={{ p: 1, backgroundColor: "white" }}
                justifyContent="space-between"
                direction="row"
              >
                <Typography variant="subtitle2">
                  {t("cartao_seguranca.nameLeader")}: {usuario.nome}
                </Typography>

                <Typography variant="subtitle2">
                  {t("cartao_seguranca.mat")}: {usuario.matricula}
                </Typography>
              </Stack>

              <Controller
                control={control}
                name="matricula"
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {t("cartao_seguranca.mat")}
                    </Typography>
                    <Select
                      {...field}
                      options={formatarParaSelect(colaboradores)}
                      styles={selectStyles}
                    />
                    {!!errors.matricula && (
                      <Typography
                        align="center"
                        color="error"
                        variant="caption"
                      >
                        {t("cartao_seguranca.errorMat")}
                      </Typography>
                    )}
                  </Stack>
                )}
              />
              <Stack direction="row" gap={1}>
                <Controller
                  control={control}
                  name="id_area"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Stack flex={1}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("cartao_seguranca.labelArea")}
                      </Typography>
                      <Select
                        {...field}
                        options={formatarParaSelect(area)}
                        styles={selectStyles}
                      />
                      {!!errors.id_area && (
                        <Typography
                          align="center"
                          color="error"
                          variant="caption"
                        >
                          {t("cartao_seguranca.errorArea")}
                        </Typography>
                      )}
                    </Stack>
                  )}
                />
                {watch("id_tipo_cartao") && watch("id_tipo_cartao") === "1" && (
                  <Controller
                    control={control}
                    name="id_modelo"
                    defaultValue=""
                    // rules={{ required: true }}
                    render={({ field }) => (
                      <Stack flex={1}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          {t("cartao_seguranca.labelModel")}
                        </Typography>
                        <Select
                          {...field}
                          options={formatarParaSelect(modelos)}
                          styles={selectStyles}
                        />
                        {!!errors.id_modelo && (
                          <Typography
                            align="center"
                            color="error"
                            variant="caption"
                          >
                            {t("cartao_seguranca.errorModel")}
                          </Typography>
                        )}
                      </Stack>
                    )}
                  />
                )}
              </Stack>

              <Stack
                spacing={1}
                justifyContent="space-between"
                direction={{ xs: "column", md: "row" }}
              >
                <Controller
                  control={control}
                  name="id_categoria"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Stack sx={{ flex: 1 }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("cartao_seguranca.labelCategory")}
                      </Typography>
                      <Select
                        {...field}
                        options={formatarParaSelect(
                          cartao_tipo_x_categoria[watch("id_tipo_cartao")]
                        )}
                        styles={selectStyles}
                      />
                      {!!errors.id_categoria && (
                        <Typography
                          align="center"
                          color="error"
                          variant="caption"
                        >
                          {t("cartao_seguranca.errorCategory")}
                        </Typography>
                      )}
                    </Stack>
                  )}
                />
                <Controller
                  control={control}
                  name="operacao"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Stack sx={{ flex: 1 }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("cartao_seguranca.labelOperation")}
                      </Typography>
                      <Select
                        {...field}
                        options={formatarParaSelect(operacao)}
                        styles={selectStyles}
                      />
                      {!!errors.operacao && (
                        <Typography
                          align="center"
                          color="error"
                          variant="caption"
                        >
                          {t("cartao_seguranca.errorOperation")}
                        </Typography>
                      )}
                    </Stack>
                  )}
                />
              </Stack>
              <Stack
                sx={{ my: 1 }}
                alignItems={{ xs: "stretch", md: "flex-end" }}
                justifyContent="space-between"
                spacing={1}
                direction={{ xs: "column", md: "row" }}
              >
                <Controller
                  control={control}
                  name="turno"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Stack sx={{ flex: 1 }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("cartao_seguranca.labelTurn")}
                      </Typography>
                      <Select
                        {...field}
                        options={formatarParaSelect(turno)}
                        styles={selectStyles}
                      />
                      {!!errors.turno && (
                        <Typography
                          align="center"
                          color="error"
                          variant="caption"
                        >
                          {t("cartao_seguranca.errorTurn")}
                        </Typography>
                      )}
                    </Stack>
                  )}
                />
                <Controller
                  control={control}
                  name="data_abertura"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Stack sx={{ flex: 1 }}>
                      <Paper>
                        <TextField
                          {...field}
                          fullWidth
                          size="small"
                          //sx={{ backgroundColor: "white",}}
                          variant="filled"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="date"
                          label={t("cartao_seguranca.labelDateOpen")}
                          error={!!errors.data_abertura}
                          helperText={
                            !!errors.data_abertura &&
                            t("cartao_seguranca.errorOpenDate")
                          }
                        />
                      </Paper>
                    </Stack>
                  )}
                />
              </Stack>
              <Controller
                control={control}
                name="descricao"
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack sx={{ my: 1, flex: 1 }}>
                    <Paper>
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        variant="filled"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="textarea"
                        label={t("cartao_seguranca.labelDescription")}
                        multiline
                        placeholder={t(
                          "cartao_seguranca.placeholderDescription"
                        )}
                        minRows={3}
                        error={!!errors.descricao}
                        helperText={
                          !!errors.descricao &&
                          t("cartao_seguranca.errorDescriptionCard")
                        }
                      />
                    </Paper>
                  </Stack>
                )}
              />
              <Paper>
                <TextField
                  type="file"
                  inputProps={{ multiple: true }}
                  variant="filled"
                  label={t("cartao_seguranca.labelAttachment")}
                  fullWidth
                  //sx={{ backgroundColor: "white" }}
                  onChange={addArquivo}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </Paper>
            </Paper>
          </motion.div>
        )}
        <Stack sx={{ my: 1 }} alignItems={{ xs: "stretch", md: "center" }}>
          {watch("id_tipo_cartao") && (
            <Button
              variant="contained"
              onClick={handleSubmit(fn)}
              disabled={aguardar}
              startIcon={
                aguardar ? (
                  <CircularProgress size={20} />
                ) : (
                  <Icone icone="PostAdd" />
                )
              }
            >
              {t("cartao_seguranca.textBtnSave")}
            </Button>
          )}
        </Stack>
      </AnimatePresence>
      <br /> <br />
    </Container>
  );
};

export default AddCartao;
