import { Paper, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Body1, Body2, Caption, Subtitle1 } from "../../../components";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";

const defaultOptions = {
  loop: true,
  autoplay: true,
  delay: 1000,
  animationData: "",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

// Define outras reacoes este mes
const OutrasReacoes = ({ verQuantidade, reacoes }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  return (
    <Paper elevation={3} sx={{ my: 1, py: 1.5 }}>
      <Stack>
        <Subtitle1>
          {t("registro_emocional.subtitleOtherReaction")}
          {verQuantidade && t("registro_emocional.labelPerQuantity")}
        </Subtitle1>
        <Stack
          sx={{ overflow: "auto" }}
          direction="row"
          justifyContent={{ xs: "flex-start", md: "space-evenly" }}
        >
          {reacoes.map((ele, idx) => {
            const copia = { ...defaultOptions };
            copia.animationData = ele.animacao;
            return (
              <Stack sx={{ px: 1 }} alignItems="center" key={idx}>
                <Lottie
                  title={t("registro_emocional.titleOtherReactionLottie")}
                  options={copia}
                  width={isMobile ? 24 : 48}
                  height={isMobile ? 24 : 48}
                />
                {isMobile ? (
                  <Caption>{ele.titulo}</Caption>
                ) : (
                  <Body2>{ele.titulo}</Body2>
                )}
                <Body1>
                  {isNaN(ele.percentual) ? 0 : ele.percentual}{" "}
                  {!verQuantidade && "%"}
                </Body1>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default OutrasReacoes;
