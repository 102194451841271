import { ContainerAdaptavel } from "../../../extra-components";
import ConfirmarMudancaStatus from "./twttp-confirmation-change-status";
import HocRespostaQuestionario from "./twttp-hoc-response-question";
import RespostasQuestionario from "./twttp-response-questions";
import VerFiltroAvancado from "./twttp-view-advanced-filter";
import MoverTwttp from "./twttp-move";
import MoverTwttpValidacao from "./twttp-move-validation";
import Entrevistar from "./twttp-to-interview";
import VerDetalheTwttp from "./twttp-view-details";
import FormAddTwttp from "./twttp-form-add";
import MoverTwttpValidacaoProducao from "./twttp-move-validation-production";

const TwttpModal = ({ modal }) => {
  let corpo;
  if (modal) {
    const { dados } = modal;

    switch (modal.tipo) {
      case TwttpModal.modal.ADD_TWTTP: // Para adicionar um twttp
        corpo = <FormAddTwttp />;
        break;
      case TwttpModal.modal.VER_DETALHE_TWTTP: // Para ver detalhes do twttp
        corpo = <VerDetalheTwttp id_twttp={dados} />;
        break;
      case TwttpModal.modal.ENTREVISTAR: // Para iniciar uma entrevista
        corpo = <Entrevistar status={dados.status} id_twttp={dados.id_twttp} />;
        break;
      case TwttpModal.modal.MOVER_VALIDACAO: // Para mover o twttp para validação (Formulario de anexo requerido)
        corpo = <MoverTwttpValidacao id_twttp={dados} status="V" />;
        break;
      case TwttpModal.modal.MOVER_VALIDACAO_SUPERIOR: // Para mover a validacao superior
        corpo = <MoverTwttpValidacaoProducao id_twttp={dados} status="Y" />;
        break;
      case TwttpModal.modal.MOVER_APROVADO: // Para mover twttp para aprovado (Encerrado)
        corpo = <MoverTwttp id_twttp={dados} status="E" />;
        break;
      case TwttpModal.modal.MOVER_REPROVADO: // Para mover o twttp para reprovado
        corpo = <MoverTwttp id_twttp={dados} status="R" />;
        break;
      case TwttpModal.modal.VER_RESPOSTAS: // Para ver as respostas preenchidas
        corpo = (
          <HocRespostaQuestionario
            id_twttp={dados}
            render={(resp) => <RespostasQuestionario resp={resp} />}
          />
        );
        break;
      case TwttpModal.modal.VER_FILTRO_AVANCADO: // Para ver opcoes no filtro FiltroAvancado
        corpo = <VerFiltroAvancado />;
        break;
      case TwttpModal.modal.CONFIRMAR_MOVER_CANCELADO: // Para confirmar se realmente deseja mudar para cancelado
        corpo = <ConfirmarMudancaStatus {...dados} />;
        break;
      default:
        break;
    }
  }

  return <ContainerAdaptavel>{corpo}</ContainerAdaptavel>;
};

TwttpModal.modal = {
  ADD_TWTTP: "ADD_TWTTP",
  VER_DETALHE_TWTTP: "VER_DETALHE_TWTTP",
  ENTREVISTAR: "ENTREVISTAR",
  MOVER_VALIDACAO: "MOVER_VALIDACAO",
  MOVER_REPROVADO: "MOVER_REPROVADO",
  MOVER_APROVADO: "MOVER_APROVADO",
  VER_RESPOSTAS: "VER_RESPOSTAS",
  VER_FILTRO_AVANCADO: "VER_FILTRO_AVANCADO",
  CONFIRMAR_MOVER_CANCELADO: "CONFIRMAR_MOVER_CANCELADO",
  MOVER_VALIDACAO_SUPERIOR: "MOVER_VALIDACAO_SUPERIOR",
};

export default TwttpModal;
