import { toast } from "react-toastify";
import fetchRedux from "../../../api/fetch_redux";
import { ToastErro } from "../../../utils/utils";
import OuvidoriaModal from "./ouvidoria-modal";

export const OUVIDORIA_INIT = "OUVIDORIA_INIT";
export const OUVIDORIA_INIT_OUVIDORIAS = "OUVIDORIA_INIT_OUVIDORIAS";
export const OUVIDORIA_LIMPAR_DADOS = "OUVIDORIA_LIMPAR_DADOS";
export const OUVIDORIA_SET_MODAL = "OUVIDORIA_SET_MODAL";
export const OUVIDORIA_FECHAR_MODAL = "OUVIDORIA_FECHAR_MODAL";
export const OUVIDORIA_FILTRO = "OUVIDORIA_FILTRO";
export const OUVIDORIA_LIMPAR_FILTRO = "OUVIDORIA_LIMPAR_FILTRO";
export const OUVIDORIA_ADD = "OUVIDORIA_ADD";
export const OUVIDORIA_UPD = "OUVIDORIA_UPD";
export const OUVIDORIA_FILTRO_AVANCADO = "OUVIDORIA_FILTRO_AVANCADO";
//
const ROTAS = ["/ouvidoria", "/ouvidoria_filtro"];

export const ouvidoriaLimparDados = () => ({
  type: OUVIDORIA_LIMPAR_DADOS,
});
//
export const ouvidoriaInitInsumos = () => (dispatch) => {
  const params = new URLSearchParams();
  params.append("dados", true);
  // Obter os dados inciais
  fetchRedux(
    `${ROTAS[0]}?${params.toString()}`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: OUVIDORIA_INIT,
        data: resposta,
      });
    },
    () => {},
    (err) => ToastErro(err)
  );
};
export const ouvidoriaPagina = (pagina, setAguardar) => (dispatch) => {
  // Obter a lista de ouvidorias
  fetchRedux(
    `${ROTAS[0]}?pagina=${pagina}`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: OUVIDORIA_INIT_OUVIDORIAS,
        data: resposta,
      });
    },
    setAguardar,
    (err) => ToastErro(err)
  );
};
//
export const ouvidoriaInit = (setAguardar) => (dispatch) => {
  // Obter a lista de ouvidorias
  dispatch(ouvidoriaPagina(1, setAguardar));
  // Obter os insumos
  dispatch(ouvidoriaInitInsumos());
};
//
export const ouvidoriaAplicarFiltro =
  (tipoFiltro, valorFiltro) => (dispatch) => {
    // Obter a lista de ouvidorias
    fetchRedux(
      `${ROTAS[1]}?${tipoFiltro}=${valorFiltro}`,
      "GET",
      null,
      (resposta) => {
        dispatch({
          type: OUVIDORIA_FILTRO,
          data: {
            dados: resposta,
            filtroAplicado: { [tipoFiltro]: valorFiltro },
          },
        });
      },
      () => {},
      (err) => ToastErro(err)
    );
  };
//
export const ouvidoriaAplicarFiltroAvancado =
  (parametros, funcaoAuxiliar) => (dispatch) => {
    // Obter a lista de ouvidorias
    fetchRedux(
      `${ROTAS[1]}?${parametros.toString()}`,
      "GET",
      null,
      (resposta) => {
        if (funcaoAuxiliar) funcaoAuxiliar();

        dispatch({
          type: OUVIDORIA_FILTRO_AVANCADO,
          data: resposta,
        });
      },
      () => {},
      (err) => ToastErro(err)
    );
  };
//
export const ouvidoriaLimparFiltro = () => ({
  type: OUVIDORIA_LIMPAR_FILTRO,
});
// //
export const ouvidoriaAdd =
  (formData, setAguardar, funcaoVoltar) => (dispatch) => {
    fetchRedux(
      ROTAS[0],
      "POST",
      formData,
      (resposta) => {
        // Executa a funcao enviada
        if (funcaoVoltar) funcaoVoltar();
        if (resposta.data?.hasOwnProperty("id_ouvidoria")) {
          toast.dark(resposta.sucesso, {
            type: "success",
          });
          dispatch({
            type: OUVIDORIA_ADD,
            data: resposta.data,
          });
        } else {
          // E anonimo exiba o token para o usuario

          dispatch(
            ouvidoriaSetModal({
              tipo: OuvidoriaModal.MODAL.COPIAR_TOKEN,
              dados: resposta.data?.token,
            })
          );
        }
        //
        dispatch(ouvidoriaInitInsumos());
      },
      setAguardar,
      (err) => ToastErro(err)
    );
  };
//
export const ouvidoriaUpd =
  (formData, setAguardar, fnExecutar) => (dispatch) => {
    fetchRedux(
      ROTAS[0],
      "PUT",
      formData,
      (resposta) => {
        toast.dark(resposta.sucesso, {
          type: "success",
        });

        if (fnExecutar) fnExecutar();

        dispatch({
          type: OUVIDORIA_UPD,
          data: resposta.data,
        });
        //
        dispatch(ouvidoriaInitInsumos());
      },
      setAguardar,
      (err) => ToastErro(err)
    );
  };
//
export const ouvidoriaSetModal = (data) => ({
  type: OUVIDORIA_SET_MODAL,
  data,
});
//
export const ouvidoriaFecharModal = () => ({
  type: OUVIDORIA_FECHAR_MODAL,
});
