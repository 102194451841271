import { useDispatch } from "react-redux";
import {
  twttpAtualizaStatusValidacao,
  twttpFecharDrawer,
} from "./twttp-actions";
import { useCallback } from "react";
import { Container } from "@mui/material";
import { Confirmar } from "../../../components";

import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

const MoverTwttpValidacao = ({ id_twttp, status }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();

  const fnConfirm = useCallback(
    (val) => {
      dispatch(
        twttpAtualizaStatusValidacao(
          {
            id_twttp,
            status: status,
          },
          setWait
        )
      );
    },
    [id_twttp, setWait, status, dispatch]
  );
  const fnCancel = useCallback(() => {
    dispatch(twttpFecharDrawer());
  }, [dispatch]);

  return (
    <Container
      sx={{ pb: 1, maxHeight: "90vh", overflow: "auto" }}
      maxWidth="md"
    >
      <Confirmar
        aguardar={wait}
        fnCancelar={fnCancel}
        fnConfirmar={fnConfirm}
        titulo={t("twttp.titleFormMoveValidationProd")}
        subtitulo={t("twttp.subtitleFormMoveValidationProd")}
      />
    </Container>
  );
};

export default MoverTwttpValidacao;
