import { ContainerAdaptavel } from "../../../extra-components";
import FormAddFoto from "./galeria-de-fotos-add-foto";
import FormAddUpdAlbum from "./galeria-de-fotos-add-upd-album";
import ListaDeComentarios from "./galeria-de-fotos-comentarios";
import ListaDeCurtida from "./galeria-de-fotos-curtidas";
import FormDelAlbum from "./galeria-de-fotos-del-album";
import FormDelFoto from "./galeria-de-fotos-del-foto";
import FormUpdLegendaFoto from "./galeria-de-fotos-upd-legenda-foto";
import VerFoto from "./galeria-de-fotos-ver-foto";

const GaleriaDeFotosModal = ({ modal }) => {
  let corpo;
  if (modal) {
    const dados = modal.dados;
    switch (modal.tipo) {
      case GaleriaDeFotosModal.modal.ADD_ALBUM:
      case GaleriaDeFotosModal.modal.EDITAR_ALBUM: // Para edicao de um album
        corpo = <FormAddUpdAlbum {...dados} />;
        break;
      case GaleriaDeFotosModal.modal.EXCLUIR_ALBUM: // Para excluir album
        corpo = <FormDelAlbum id_album={dados} />;
        break;
      case GaleriaDeFotosModal.modal.ADD_FOTO: // Para adicionar novas fotos no album atual
        corpo = <FormAddFoto {...dados} />;
        break;
      case GaleriaDeFotosModal.modal.VER_FOTO: // Para ver as fotos
        corpo = <VerFoto {...dados} />;
        break;
      case GaleriaDeFotosModal.modal.VER_COMENTARIOS: // pARA VER OS COMETNARIOS
        corpo = <ListaDeComentarios id_foto={dados} />;
        break;
      case GaleriaDeFotosModal.modal.VER_CURTIDAS: // Para ver as curtidas
        corpo = <ListaDeCurtida id_foto={dados} />;
        break;
      case GaleriaDeFotosModal.modal.UPD_LEGENDA_FOTO: // Para editar a legenda da foto
        corpo = <FormUpdLegendaFoto {...dados} />;
        break;
      case GaleriaDeFotosModal.modal.EXCLUIR_FOTO: // Para excluir uma foto
        corpo = <FormDelFoto id_foto={dados} />;
        break;
      default:
        break;
    }
  }

  return <ContainerAdaptavel>{corpo}</ContainerAdaptavel>;
};

GaleriaDeFotosModal.modal = {
  ADD_ALBUM: "ADD_ALBUM",
  EDITAR_ALBUM: "EDITAR_ALBUM",
  EXCLUIR_ALBUM: "EXCLUIR_ALBUM",
  ADD_FOTO: "ADD_FOTO",
  UPD_LEGENDA_FOTO: "UPD_LEGENDA_FOTO",
  VER_FOTO: "VER_FOTO",
  VER_COMENTARIOS: "VER_COMENTARIOS",
  VER_CURTIDAS: "VER_CURTIDAS",
};

export default GaleriaDeFotosModal;
