import { useEffect } from "react";
import { useFetch } from "../../../hooks";
import { Button, Container, Paper, Stack } from "@mui/material";
import { Body1, Body2, H6 } from "../../../components";
import { format } from "date-fns";
import { parseISO } from "date-fns/esm";
import { ToastErro } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

// Renderiza o historico do candidato
const VerHistoricoVaga = ({ id_vaga }) => {
  const { t } = useTranslation();
  const { setFetch, data, error } = useFetch(
    `oportunidades_historico/${id_vaga}`,
    "GET"
  );

  useEffect(() => {
    setFetch({});
  }, [setFetch]);
  //
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  return (
    <Container maxWidth="md">
      <H6>{t("oportunidades.titleHistVacation")}</H6>
      <Stack spacing={1}>
        {data?.map((ele, idx) => (
          <Paper sx={{ p: 1 }} key={idx}>
            <Stack spacing={1}>
              <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <Body1>
                  {t("oportunidades.fromPhase")} {ele.de_fase}
                </Body1>

                <Body1>
                  {t("oportunidades.toPhase")} {ele.para_fase}
                </Body1>
              </Stack>
              <Body1 fontWeight="bold">
                {t("oportunidades.observation")}: {ele.observacao}
              </Body1>
              {ele.anexo && (
                <Button
                  href={ele.anexo}
                  target="_blank"
                  variant="outlined"
                  size="small"
                >
                  {t("oportunidades.btnHistAttachment")}
                </Button>
              )}
              <Body2 align="right">
                {t("oportunidades.histData")}:{" "}
                {format(parseISO(ele.data), "dd/MM/yy HH:mm")}
              </Body2>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Container>
  );
};

export default VerHistoricoVaga;
