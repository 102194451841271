import { useDispatch, useSelector } from "react-redux";
import {
  fnSeletorTamanho,
  selectAguardar,
  selectDados,
} from "./twttp-selectors";
import { useCallback } from "react";
import { format } from "date-fns";
import { twttpAddForm } from "./twttp-actions";
import * as yup from "yup";
import { memo } from "react";
import { Container, Divider } from "@mui/material";
import { EntradaForm, HeaderDefault } from "../../../components";
import { useTranslation } from "react-i18next";

// Formulario para adicao do twttp
const FormAddTwttp = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aguardar = useSelector(selectAguardar);
  const dados = useSelector(selectDados);
  const tamanhoCampos = useSelector(fnSeletorTamanho);

  const {
    fator,
    turno,
    areas,
    tipo_caracteristicas,
    times,
    origens,
    twttp_tipos,
    setores,
    area_x_modelo,
  } = dados;
  // Funcao de callback que recebe o id_area e aponta os modelos a escolher
  const fngetModelos = useCallback(
    (area) => {
      return area_x_modelo?.hasOwnProperty(area?.value)
        ? area_x_modelo[area?.value]
        : [];
    },
    [area_x_modelo]
  );
  //
  const schema = [
    {
      type: "date",
      name: "data_abertura",
      label: t("twttp.labelOpenDateFormAdd"),
      defaultValue: format(new Date(), "yyyy-MM-dd"),
      grid: { xs: 12 },
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "radio",
      name: "id_tipo_twttp",
      label: t("twttp.labelTwttpTypeFormAdd"),
      itens: twttp_tipos,
      autoFormat: true,
      grid: { xs: 12 },
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "textarea",
      name: "problema",
      label: t("twttp.labelProblemFormAdd"),
      placeholder: "Descreva o problema",
      grid: { xs: 12 },
      extraProps: {
        multiline: true,
        minRows: 2,
      },
      maxLength: tamanhoCampos["addFormTwttp"],
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "select",
      name: "id_area",
      label: t("twttp.labelProcessMachineFormAdd"),
      itens: areas,
      autoFormat: true,
      grid: { xs: 12 },
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "select",
      name: "id_modelos",
      label: t("twttp.labelModelsFormAdd"),
      itens: [],
      isMulti: true,
      exibirSe: fngetModelos,
      autoFormat: true,
      grid: { xs: 12 },
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "select",
      name: "id_arvore",
      label: t("twttp.labelSectorInterviewedFormAdd"),
      itens: setores,
      grid: { xs: 12, md: 6 },
      autoFormat: true,
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "select",
      name: "id_caracteristica",
      label: t("twttp.labelErrorFeatureFormAdd"),
      itens: tipo_caracteristicas,
      grid: { xs: 12, md: 6 },
      autoFormat: true,
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "textarea",
      name: "detalhe_erro",
      label: t("twttp.labelErrorDetailFormAdd"),
      placeholder: t("twttp.placeholderDetailErrorFormAdd"),
      grid: { xs: 12 },
      extraProps: {
        multiline: true,
        minRows: 2,
      },
      counter: true,
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "select",
      name: "id_origem_twttp",
      label: t("twttp.labelProblemOriginFormAdd"),
      itens: origens,
      grid: { xs: 12 },
      autoFormat: true,
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "select",
      name: "fator",
      label: t("twttp.labelFactorFormAdd"),
      itens: fator,
      grid: { xs: 12, md: 6 },
      autoFormat: true,
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "select",
      name: "turno",
      label: t("twttp.labelShiftFormAdd"),
      itens: turno,
      grid: { xs: 12, md: 6 },
      autoFormat: true,
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "select",
      name: "id_times",
      label: t("twttp.labelAnalysisTeamFormAdd"),
      itens: times,
      grid: { xs: 12 },
      isMulti: true,
      autoFormat: true,
      wrapperPaperProps: { sx: { p: 1 } },
    },
    {
      type: "file",
      label: t("twttp.labelAttachmentFormAdd"),
      name: "arquivo",
      grid: { xs: 12 },
      wrapperPaperProps: { sx: { p: 1 } },
    },
  ];
  const schemaValidator = yup.object().shape({
    data_abertura: yup.date().required(),
    id_tipo_twttp: yup.string().required(),
    problema: yup.string().min(1).max(tamanhoCampos["addFormTwttp"]).required(),
    id_area: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    id_modelos: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().min(1).required(),
          value: yup.string().min(1).required(),
        })
      )
      .min(1)
      .required(),
    id_arvore: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    id_caracteristica: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    detalhe_erro: yup.string().min(1).required(),
    id_origem_twttp: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    fator: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    turno: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    id_times: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().min(1).required(),
          value: yup.string().min(1).required(),
        })
      )
      .min(1)
      .required(),
    arquivo: yup
      .mixed()
      .required()
      .test("name", t("twttp.errorAttachmentRequiredFormAdd"), (value) => {
        return value && value[0]?.name.length > 0;
      }),
  });

  const schemaMessageError = {
    data_abertura: t("twttp.errorOpenDateRequiredFormAdd"),
    id_tipo_twttp: t("twttp.errorTwttpTypeRequiredFormAdd"),
    problema: t("twttp.errorProblemRequiredFormAdd"),
    id_area: t("twttp.errorAreaRequiredFormAdd"),
    id_modelos: t("twttp.errorModelRequiredFormAdd"),
    id_arvore: t("twttp.errorSectorRequiredFormAdd"),
    detalhe_erro: t("twttp.errorDetailErrorRequiredFormAdd"),
    id_origem_twttp: t("twttp.errorProblemOriginRequiredFormAdd"),
    id_caracteristica: t("twttp.errorErrorFeatureRequiredFormAdd"),
    id_times: t("twttp.errorAnalysisTeamRequiredFormAdd"),
    arquivo: t("twttp.errorAttachmentRequiredFormAdd"),
    fator: t("twttp.errorFactorRequiredFormAdd"),
    turno: t("twttp.errorShiftRequiredFormAdd"),
  };

  // Funcao para submeter os dados
  const fn = useCallback(
    (val) => {
      const obj = {
        data_abertura: format(val.data_abertura, "yyyy-MM-dd"),
        problema: val.problema,
        detalhe_erro: val.detalhe_erro,
        id_area: parseInt(val.id_area.value),
        id_modelos: val.id_modelos.map((ele) => parseInt(ele.value)),
        id_caracteristica: parseInt(val.id_caracteristica.value),
        id_origem_twttp: parseInt(val.id_origem_twttp.value),
        id_times: val.id_times.map((ele) => parseInt(ele.value)),
        id_tipo_twttp: parseInt(val.id_tipo_twttp),
        id_arvore: parseInt(val.id_arvore.value),
        turno: val.turno.value,
        fator: val.fator.value,
        arquivo: val.arquivo,
      };
      dispatch(twttpAddForm(obj));
    },
    [dispatch]
  );

  return (
    <Container sx={{ pb: 2, maxHeight: "75vh" }} maxWidth="md">
      <HeaderDefault children="TWTTP" />
      <Divider component="br" />
      <EntradaForm
        wait={aguardar}
        schema={schema}
        schemaValidator={schemaValidator}
        schemaMessageError={schemaMessageError}
        onSubmit={fn}
        exibirSe={{ ouvir: "id_area", atualizar: "id_modelos" }}
      />
    </Container>
  );
});

export default FormAddTwttp;
