import React, { useEffect } from "react";
import BackgroundRouter from "../../background-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useFetch } from "../../../hooks";
import { ToastErro } from "../../../utils/utils";
import { Body1, H4, Icone, Pesquisando, Tab } from "../../../components";
import { RNCI_ICONES as ICN } from "./rnci-ncp";
import {
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import RnciNcpForm, { RnciGoBack } from "./rnci-ncp-form";
import { blue } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

function RnciDetalhes() {
  const { data, wait, setFetch, error } = useFetch("/rnci_ncp", "GET");
  const history = useHistory();

  // Valores retornados em caso de uma visualização do formulário
  const rnciCodigo = history?.location?.state?.id;
  //
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  //
  useEffect(() => {
    setFetch({
      rnci: rnciCodigo,
    });
  }, [rnciCodigo, setFetch]);

  return (
    <BackgroundRouter>
      {wait ? <Pesquisando /> : data ? <Corpo {...data} /> : null}
    </BackgroundRouter>
  );
}
//
const Corpo = ({ rnci_info, rnci_subida_linha, rnci_qualidade }) => {
  const isMobile = useTheme()?.isMobile;
  const { t } = useTranslation();

  const cabe = [
    { icone: ICN.form1, texto: t("rnci_ncp.abaForm1") },
    { icone: ICN.subidaLinha, texto: t("rnci_ncp.abaSubida") },
    { icone: ICN.qualidade, texto: t("rnci_ncp.abaQualidade") },
  ].map((ele, idx) => (
    <Stack key={idx} direction="row" spacing={0.5}>
      <Icone icone={ele.icone} />
      <Body1>{ele.texto}</Body1>
    </Stack>
  ));

  const corpo = [
    <RnciNcpForm dataItem={rnci_info} />,
    <VerSubidaLinha subidaLinha={rnci_subida_linha} />,
    <VerQualidade qualidade={rnci_qualidade} />,
  ];
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ pt: 1, background: ({ backgroundPage }) => backgroundPage }}
    >
      {!isMobile && <RnciGoBack />}
      <Tab cabe={cabe} corpo={corpo} />
    </Container>
  );
};
//
const VerSubidaLinha = ({ subidaLinha }) => {
  const { t } = useTranslation();
  //
  const opcoes = [
    { titulo: t("rnci_ncp.subidaLinhaMod"), valor: subidaLinha?.mod, md: 4 },
    { titulo: t("rnci_ncp.subidaLinhaMoi"), valor: subidaLinha?.moi, md: 4 },
    {
      titulo: t("rnci_ncp.subidaLinhaTempo"),
      valor: subidaLinha?.tempo,
      md: 4,
    },
    { titulo: t("rnci_ncp.subidaLinhaOK"), valor: subidaLinha?.ok, md: 6 },
    { titulo: t("rnci_ncp.subidaLinhaKO"), valor: subidaLinha?.ko, md: 6 },
    {
      titulo: t("rnci_ncp.subidaLinhaObs"),
      valor: subidaLinha?.observacao,
      md: 12,
    },
  ];
  return (
    <Container maxWidth={false} disableGutters sx={{ pt: 1 }}>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <H4>
                {t("rnci_ncp.formRNCI")} {subidaLinha?.codigornci}
              </H4>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
            </Grid>
            {opcoes.map((ele, idx) => (
              <Grid item xs={12} md={ele.md} key={idx}>
                <Body1 fontWeight="bold">{ele.titulo}</Body1>
                <Body1
                  sx={{
                    fontSize: "1.25rem",
                    borderRadius: 1,
                    border: ele.md === 12 ? "1px solid #ddd" : "none",
                    minHeight: ele.md === 12 ? 72 : "auto",
                    p: 1,
                    color: blue[500],
                  }}
                >
                  {ele.valor}
                </Body1>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Container>
    </Container>
  );
};
//
const VerQualidade = ({ qualidade }) => {
  const { t } = useTranslation();
  //
  const opcoes = [
    {
      titulo: t("rnci_ncp.qualidadeObs"),
      valor: qualidade?.acoes,
      md: 12,
    },
  ];
  if (qualidade?.cod_projeto) {
    opcoes.splice(0, 0, {
      titulo: t("rnci_ncp.qualidadeCodProjeto"),
      valor: qualidade?.cod_projeto,
      md: 8,
    });
  }

  return (
    <Container maxWidth={false} disableGutters sx={{ pt: 1 }}>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <H4>
                {t("rnci_ncp.formRNCI")} {qualidade?.codigornci}
              </H4>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
            </Grid>
            {opcoes.map((ele, idx) => (
              <Grid item xs={12} md={ele.md} key={idx}>
                <Body1 fontWeight="bold">{ele.titulo}</Body1>
                <Body1
                  sx={{
                    fontSize: "1.25rem",
                    borderRadius: 1,
                    border: ele.md === 12 ? "1px solid #ddd" : "none",
                    minHeight: ele.md === 12 ? 72 : "auto",
                    p: 1,
                    color: blue[500],
                  }}
                >
                  {ele.valor}
                </Body1>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Container>
    </Container>
  );
};

RnciDetalhes.rota = "/rnci_detalhes";

export default RnciDetalhes;
