import { Chip } from "@mui/material";
import { Body2 } from "../../../components";
import { useTranslation } from "react-i18next";

//
const Mecandidatei = ({ fase }) => {
  const { t } = useTranslation();

  return (
    <Chip
      color="success"
      label={
        <Body2>
          {t("oportunidades.labelPhaseActualy")}: {fase}
        </Body2>
      }
    />
  );
};

export default Mecandidatei;
