import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  manutencaoComponentesFecharModal,
  manutencaoComponentesSetModal,
} from "./manutencao-componentes-actions";
import ManutencaoComponentesModal from "./manutencao-componentes-modal";
import _ from "lodash";
import { selectModal } from "./manutencao-components-selectors";
import {
  Container,
  Fab,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { DrawerDialog } from "../../../extra-components";
import { Body2, Icone, InputBusca, ScrollInfinito } from "../../../components";
import { useTranslation } from "react-i18next";

// Corpo da Pagina
const ManutencaoComponentesCorpo = ({ componentes }) => {
  const { t } = useTranslation();
  const [filtro, setFiltro] = useState("");
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const fnAddComponente = useCallback(() => {
    dispatch(
      manutencaoComponentesSetModal({
        tipo: ManutencaoComponentesModal.modal.ADD_COMPONENTE,
      })
    );
  }, [dispatch]);
  //
  const itens =
    filtro.length > 0
      ? componentes.filter(
          (ele) =>
            _.toUpper(ele.descricao_portugues).search(_.toUpper(filtro)) !==
              -1 ||
            _.toUpper(ele.descricao_ingles).search(_.toUpper(filtro)) !== -1 ||
            _.toUpper(ele.descricao_italiano).search(_.toUpper(filtro)) !==
              -1 ||
            _.toUpper(ele.codigo).search(_.toUpper(filtro)) !== -1 ||
            _.toUpper(ele.fornecedor).search(_.toUpper(filtro)) !== -1
        )
      : componentes;

  return (
    <Container maxWidth="md">
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => <ManutencaoComponentesModal modal={modal} />}
          fecharModal={() => dispatch(manutencaoComponentesFecharModal())}
        />
      )}

      <Stack
        direction={{ xs: "column", md: "row-reverse" }}
        alignItems={{ xs: "stretch", md: "center" }}
        spacing={1}
      >
        <Fab
          title={t("manutencao_componentes.titleFab")}
          color="primary"
          onClick={fnAddComponente}
          variant="extended"
        >
          <Icone icone="Add" />
          <Body2 sx={{ whiteSpace: "nowrap" }}>
            {t("manutencao_componentes.labelFab")}
          </Body2>
        </Fab>
        <InputBusca
          sx={{ flex: 1 }}
          desativarPesquisaLenta
          filtro={filtro}
          setFiltro={setFiltro}
        />
      </Stack>
      <Body2>
        {itens.length} {t("manutencao_componentes.components")}
      </Body2>
      <ScrollInfinito
        tamanho="75vh"
        itens={itens}
        render={(ele) => (
          <Paper elevation={3} sx={{ p: 1, m: 1 }}>
            <ListItem>
              <ListItemText
                primary={`CÓDIGO: ${ele.codigo}`}
                secondary={`${t(
                  "manutencao_componentes.descriptionPortuguese"
                )}: ${ele.descricao_portugues} `}
              />
            </ListItem>
          </Paper>
        )}
      />
    </Container>
  );
};

export default ManutencaoComponentesCorpo;
