import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import BackgroundRouter from "../../background-router.jsx";
import {
  AnimacaoSemDados,
  Body1,
  Body2,
  Caption,
  H6,
  Icone,
  Pesquisando,
} from "../../../components/index.js";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Slide,
  Stack,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ncpClearFilter,
  ncpFecharModal,
  ncpInit,
  ncpPagina,
  ncpPaginaFiltro,
  ncpSetFilter,
  ncpSetModal,
} from "./ncp-actions.js";
import { useToggle } from "react-use";
import Utils from "../../../utils/utils.js";
import NcpForm from "./ncp-form.jsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";

import NcpModal from "./ncp-modal.jsx";
import { DrawerDialog } from "../../../extra-components/index.js";
import NcpDetalhes from "./ncp-detalhes.jsx";
import { toast } from "react-toastify";
import axios from "axios";
import {
  selectModal,
  selectNcp,
  selectNcpDadosPagina,
  selectNcpPermissionCancelStatus,
  seletorIsArvore,
  seletorPageFilter,
  seletorNcpGetFilterData,
  seletorNcpOptionsFilter,
} from "./ncp-selectors.js";
import { obterCorStatus } from "./ncp-panel-info.jsx";
import NcpPanelInfo from "./ncp-panel-info.jsx";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores.js";
import { useTranslation } from "react-i18next";

export const NCP_ICONES = {
  form1: "Receipt",
  detalhes: "Search",
  subidaLinha: "Build",
  qualidade: "QueryStats",
  downloadPdf: "PictureAsPdf",
  excluirNcp: "Delete",
  retornarAberto: "Undo",
};
//
const obterLegendaSituacao = (situacao, t) => {
  switch (situacao) {
    default:
      return t("ncp.situacaoAberto");
  }
};
//
const downloadPdf = async (id) => {
  return axios.get(`/ncp_pdf?ncp=${id}`);
};

function Ncp() {
  const { t } = useTranslation();
  const backgroundPage = useTheme()?.backgroundPage;
  const modal = useSelector(selectModal);

  const dispatch = useDispatch();
  const [aguardar, setAguardar] = useToggle();
  const dados = useSelector(selectNcpDadosPagina);

  // Recupera os dados da primeira pagina
  useEffect(() => {
    dispatch(ncpInit(setAguardar));
  }, [dispatch, setAguardar]);
  //
  const onCloseModal = useCallback(() => {
    dispatch(ncpFecharModal());
  }, [dispatch]);
  //
  return (
    <BackgroundRouter>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => <NcpModal modal={modal} />}
          fecharModal={onCloseModal}
        />
      )}
      <Container
        maxWidth={false}
        sx={{
          background: backgroundPage,
        }}
      >
        <H6>{t("ncp.titulo")}</H6>
        {aguardar ? <Pesquisando /> : dados ? <Corpo /> : null}
      </Container>
    </BackgroundRouter>
  );
}
//
const Corpo = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useTheme()?.isMobile;
  const sxFab = {};
  if (isMobile) {
    sxFab.position = "fixed";
    sxFab.right = 16;
    sxFab.bottom = 72;
  } else {
    sxFab.mb = 1;
  }
  //

  return (
    <>
      <Stack direction="row-reverse">
        <Fab
          title={t("ncp.tituloBotaoAdd")}
          color="success"
          sx={sxFab}
          variant={isMobile ? "circular" : "extended"}
          onClick={() => history.push(NcpForm.rota)}
        >
          <Icone icone="Add" />
          {!isMobile && <Body2>{t("ncp.rotuloBotaoAdd")}</Body2>}
        </Fab>
      </Stack>
      {isMobile ? <CorpoMobile /> : <CorpoDesktop />}
    </>
  );
};
//
const CorpoMobile = () => {
  const { t } = useTranslation();
  const isArvoreQualidade = useSelector(seletorIsArvore);
  const dados = useSelector(selectNcpDadosPagina);
  const dadosFiltro = useSelector(seletorNcpGetFilterData);

  let dadosVer;
  if (dadosFiltro) {
    dadosVer = dadosFiltro;
  } else {
    dadosVer = dados;
  }

  return (
    <>
      <NcpPanelInfo />
      <Paginacao />

      <List disablePadding>
        {!dadosVer || dadosVer.length === 0 ? (
          <AnimacaoSemDados titulo={t("ncp.tituloSemDados")} />
        ) : (
          dadosVer?.map((ele) => (
            <Slide direction="left" mountOnEnter in key={ele.id} unmountOnExit>
              <Box>
                <ItemNcp
                  {...ele}
                  dataItem={ele}
                  isArvoreQualidade={isArvoreQualidade}
                />
              </Box>
            </Slide>
          ))
        )}
      </List>
    </>
  );
};
//
const Paginacao = () => {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;
  const dispatch = useDispatch();
  const { total_pagina, pagina_atual } = useSelector(selectNcp);
  const { totalPaginaFiltro, paginaAtualFiltro } =
    useSelector(seletorPageFilter);

  const onChangePagina = useCallback(
    (evt, pageID) => {
      // Se estiver usando dados do filtro
      if (totalPaginaFiltro) {
        dispatch(ncpPaginaFiltro(pageID));
      } else {
        dispatch(ncpPagina(pageID, () => {}));
      }
    },
    [dispatch, totalPaginaFiltro]
  );

  let totalPage, pageActualy;
  if (totalPaginaFiltro) {
    totalPage = totalPaginaFiltro;
    pageActualy = paginaAtualFiltro;
  } else {
    totalPage = total_pagina;
    pageActualy = pagina_atual;
  }

  return (
    <Stack>
      <Divider sx={{ mb: 1 }} />
      <Stack direction="row" alignItems="center">
        <Pagination
          size={isMobile ? "small" : "medium"}
          title={t("ncp.tituloPaginacao")}
          color="primary"
          count={totalPage}
          page={pageActualy}
          onChange={onChangePagina}
        />

        <Box sx={{ flex: isMobile ? 0 : 1 }}>
          <FiltroAvancadoNcp />
        </Box>
      </Stack>
      <Divider sx={{ mt: 1 }} />
    </Stack>
  );
};
//
const FiltroAvancadoNcp = () => {
  const { t } = useTranslation();
  const [filterActive, setFilterActive] = useToggle();
  const dispatch = useDispatch();
  const filtros = useSelector(seletorNcpOptionsFilter);
  let schema = useMemo(() => {
    if (filtros) {
      return [
        {
          name: "status",
          rotulo: t("ncp.filtroNomeStatus"),
          label: t("ncp.filtroNomeStatus"),
          icone: "SwapVert",
          type: "select",
          itens: filtros.status || [],
          autoFormat: true,
          isMulti: true,
          erro: t("ncp.filtroNomeStatusErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "matricula",
          rotulo: t("ncp.filtroNomeMatricula"),
          label: t("ncp.filtroNomeMatricula"),
          icone: "Person",
          type: "select",
          itens: filtros.matriculas || [],
          autoFormat: true,
          isMulti: true,
          erro: t("ncp.filtroNomeMatriculaErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "area",
          rotulo: t("ncp.filtroNomeArea"),
          label: t("ncp.filtroNomeArea"),
          icone: "PrecisionManufacturing",
          type: "select",
          itens: filtros.area || [],
          autoFormat: true,
          isMulti: true,
          erro: t("ncp.filtroNomeAreaErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "turno",
          rotulo: t("ncp.filtroNomeTurno"),
          label: t("ncp.filtroNomeTurno"),
          icone: "WatchLater",
          type: "select",
          itens: filtros.turnos || [],
          autoFormat: true,
          isMulti: true,
          erro: t("ncp.filtroNomeTurnoErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "ncp",
          rotulo: t("ncp.filtroNomeNcp"),
          label: t("ncp.filtroNomeNcp"),
          icone: "VpnKey",
          type: "select",
          itens: filtros.codigo_ncp || [],
          autoFormat: true,
          isMulti: true,
          erro: t("ncp.filtroNomeNcpErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "operacao",
          rotulo: t("ncp.filtroNomeOperacao"),
          label: t("ncp.filtroNomeOperacao"),
          icone: "Edit",
          type: "select",
          itens: filtros.operacao || [],
          autoFormat: true,
          isMulti: true,
          erro: t("ncp.filtroNomeOperacaoErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "pn",
          rotulo: t("ncp.filtroNomePN"),
          label: t("ncp.filtroNomePN"),
          icone: "Edit",
          type: "select",
          itens: filtros.pn || [],
          autoFormat: true,
          isMulti: true,
          erro: t("ncp.filtroNomePNErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
      ];
    } else {
      return [];
    }
  }, [filtros, t]);

  // Aplica o filtro na interface
  const onFilter = useCallback(
    (val) => {
      const filterFormatted = {};
      _.forEach(_.keys(val), (k) => {
        if (!filterFormatted.hasOwnProperty(k)) {
          filterFormatted[k] = [];
        }
        if (Array.isArray(val[k])) {
          filterFormatted[k] = _.map(val[k], (value) => value.value);
        }
      });

      setFilterActive();

      dispatch(ncpSetFilter(filterFormatted));
    },
    [dispatch, setFilterActive]
  );

  // Chama modal para intencao de filtro
  const onClickFilter = useCallback(() => {
    dispatch(
      ncpSetModal({
        tipo: NcpModal.modal.FILTRO_AVANCADO,
        dados: {
          filters: schema,
          onFilter: onFilter,
        },
      })
    );
  }, [dispatch, schema, onFilter]);
  // Limpeza de filtro
  const onClearFilter = useCallback(() => {
    setFilterActive();
    dispatch(ncpClearFilter());
  }, [setFilterActive, dispatch]);

  return (
    <Chip
      clickable
      onClick={filterActive ? onClearFilter : onClickFilter}
      variant={filterActive ? "filled" : "outlined"}
      color="primary"
      label={t("ncp.rotuloBtnFiltro")}
      icon={<Icone icone="FilterAlt" />}
    />
  );
};
//
const CorpoDesktop = () => {
  const { t } = useTranslation();
  const isArvoreQualidade = useSelector(seletorIsArvore);
  const dados = useSelector(selectNcpDadosPagina);
  const dadosFiltro = useSelector(seletorNcpGetFilterData);

  let dadosVer;
  if (dadosFiltro) {
    dadosVer = dadosFiltro;
  } else {
    dadosVer = dados;
  }

  console.log(dadosVer);

  return (
    <>
      <Container maxWidth="xs">
        <NcpPanelInfo />
      </Container>
      <Paginacao />

      <List disablePadding>
        {!dadosVer || dadosVer.length === 0 ? (
          <AnimacaoSemDados titulo={t("ncp.tituloSemDados")} />
        ) : (
          dadosVer?.map((ele) => (
            <Slide direction="left" mountOnEnter in key={ele.id} unmountOnExit>
              <Box>
                <ItemNcp
                  {...ele}
                  dataItem={ele}
                  isArvoreQualidade={isArvoreQualidade}
                />
              </Box>
            </Slide>
          ))
        )}
      </List>
    </>
  );
};
//
const ItemNcp = memo(
  ({
    dataItem,
    id,
    nome,
    hora,
    area,
    data,
    anomalia,
    matricula,
    avatar,
    situacao,
    isArvoreQualidade,
  }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    return (
      <Paper elevation={3} sx={{ my: 1 }}>
        <ListItem sx={{ p: 1 }} disableGutters disablePadding>
          <ListItemAvatar>
            <Avatar alt={nome} src={avatar} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Stack direction="row" justifyContent="space-between">
                <Chip
                  size="small"
                  color="primary"
                  label={`${t("ncp.codigo")}: ${id}`}
                />
                <Chip
                  size="small"
                  color={obterCorStatus(situacao)}
                  label={obterLegendaSituacao(situacao, t)}
                />
              </Stack>
            }
            secondary={
              <Stack sx={{ mt: 1 }} spacing={0.25}>
                <Body1 fontWeight="bold">
                  {matricula} - {nome}
                </Body1>
                <ItemNcpDetalhe label={t("ncp.area")} text={area} />
                <ItemNcpDetalhe label={t("ncp.anomalia")} text={anomalia} />
                <ItemNcpOpcoes
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  isArvoreQualidade={isArvoreQualidade}
                  data={data}
                  hora={hora}
                  id={id}
                  dataItem={dataItem}
                  situacao={situacao}
                />
              </Stack>
            }
          />
        </ListItem>
      </Paper>
    );
  }
);
//
const ItemNcpOpcoes = ({
  data,
  hora,
  setAnchorEl,
  anchorEl,
  id,
  dataItem,
  situacao,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isCancelStatus = useSelector(selectNcpPermissionCancelStatus);
  const history = useHistory();
  const open = Boolean(anchorEl);
  // Funcao para manipular a exibicao do menu
  const onViewMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl]
  );

  // Funcao para fechar
  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  // Ver o Ncp em detalhes
  const onViewNcp = useCallback(() => {
    history.push(NcpDetalhes.rota, dataItem);
  }, [dataItem, history]);
  //
  const onDownloadPdf = useCallback(() => {
    setAnchorEl(null);
    //
    const idToast = toast.loading("Aguarde, gerando PDF...", {
      theme: "dark",
    });
    //
    downloadPdf(id).then((val) => {
      if (val.data.hasOwnProperty("path")) {
        const link = document.createElement("a");
        link.href = val.data.path;
        link.target = "_blank";
        link.click();
        toast.dismiss(idToast);
      }
    });
  }, [id, setAnchorEl]);

  // Retorna ao status anterior
  const onGoBackStatus = useCallback(
    (e) => {
      e.stopPropagation();
      setAnchorEl(null);

      dispatch(
        ncpSetModal({
          tipo: NcpModal.modal.NCP_CANCEL_STATUS,
          dados: {
            id,
            statusActualy: situacao,
          },
        })
      );
    },
    [dispatch, id, situacao, setAnchorEl]
  );

  //
  const options = [
    {
      icone: NCP_ICONES.detalhes,
      texto: t("ncp.opcaoDetalhes"),
      onClick: onViewNcp,
    },
  ];

  // verifica qual a opcao que se encontra
  if (situacao === "A") {
    if (isCancelStatus) {
      options.push({
        icone: NCP_ICONES.excluirNcp,
        texto: t("ncp.opcaoExcluirNcp"),
        onClick: onGoBackStatus,
      });
    }
  }
  //
  options.push({
    icone: NCP_ICONES.downloadPdf,
    texto: t("ncp.opcaoDownloadPdf"),
    onClick: onDownloadPdf,
  });

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      {options.length > 0 ? (
        <Button
          title={t("ncp.tituloBotaoVerOpcoes")}
          variant="contained"
          size="small"
          startIcon={<Icone icone="ArrowDropDown" />}
          onClick={onViewMenu}
        >
          {t("ncp.rotuloBotaoVerOpcoes")}
        </Button>
      ) : (
        <br />
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={onCloseMenu}>
        {options.map((ele, idx) => (
          <MenuItem key={idx} onClick={ele.onClick}>
            <Stack direction="row" spacing={1}>
              <Icone icone={ele.icone} />
              <Body1>{ele.texto}</Body1>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
      <Body2
        align="right"
        fontWeight={600}
        sx={{ color: ({ palette }) => palette.primary.main }}
      >
        {t("ncp.data")} {Utils.converterDataHora(`${data} ${hora}`)}
      </Body2>
    </Stack>
  );
};
//
const ItemNcpDetalhe = ({ label, text }) => {
  const isMobile = useTheme()?.isMobile;

  return isMobile ? (
    <Caption align="left">
      {label} {text}
    </Caption>
  ) : (
    <Body2>
      {label} {text}
    </Body2>
  );
};

Ncp.rota = "/ncp";

export default Ncp;
