import { toast } from "react-toastify";
import fetchRedux from "../../../api/fetch_redux";
import { ToastErro } from "../../../utils/utils";
import _ from "lodash";

export const TRAINNING_INIT = "TRAINNING_INIT";
export const TRAINNING_PAGE = "TRAINNING_PAGE";
export const TRAINNING_CLEAR_DATA = "TRAINNING_CLEAR_DATA";
export const TRAINNING_ADD = "TRAINNING_ADD";
export const TRAINNING_EDIT = "TRAINNING_EDIT";
export const TRAINNING_SET_MODAL = "TRAINNING_SET_MODAL";
export const TRAINNING_CLOSE_MODAL = "TRAINNING_CLOSE_MODAL";
export const TRAINNING_ADD_STUDENTS = "TRAINNING_ADD_STUDENTS";
export const TRAINNING_ADD_COURSE = "TRAINNING_ADD_COURSE";
export const TRAINNING_EDIT_COURSE = "TRAINNING_EDIT_COURSE";
export const TRAINNING_REMOVE_COURSE = "TRAINNING_REMOVE_COURSE";
export const TRAINNING_DEL_COURSE = "TRAINNING_DEL_COURSE";
export const TRAINNING_ALL_INSTRUCTORS = "TRAINNING_ALL_INSTRUCTORS";
export const TRAINNING_ADD_INSTRUCTOR = "TRAINNING_ADD_INSTRUCTOR";
export const TRAINNING_EDIT_INSTRUCTOR = "TRAINNING_EDIT_INSTRUCTOR";
export const TRAINNING_DEL_INSTRUCTOR = "TRAINNING_DEL_INSTRUCTOR";
export const TRAINNING_ALTER_STATUS = "TRAINNING_ALTER_STATUS";
export const TRAINNING_SET_FILTER = "TRAINNING_SET_FILTER";
export const TRAINNING_CLEAR_FILTER = "TRAINNING_CLEAR_FILTER";

//
const ROUTES = [
  "/treinamento",
  "/treinamento_ver",
  "/treinamento_pagina",
  "/treinamento_alunos",
  "/treinamento_cursos",
  "/treinamento_instrutor",
  "/treinamento_historico",
  "/treinamento_filtro",
];

export const trainningInit = (setWait) => (dispatch) => {
  const params = new URLSearchParams();
  params.append("dados", true);
  // Obter os dados inciais
  fetchRedux(
    `${ROUTES[1]}?${params.toString()}`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: TRAINNING_INIT,
        data: resposta,
      });
    },
    () => {},
    (err) => ToastErro(err)
  );
};

export const trainningPage = (page, setWait) => (dispatch) => {
  const params = new URLSearchParams();
  params.append("pagina", page);
  // Obter os dados inciais
  fetchRedux(
    `${ROUTES[2]}?${params.toString()}`,
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: TRAINNING_PAGE,
        data: resposta,
      });
    },
    () => {
      setWait();
    },
    (err) => ToastErro(err)
  );
};

// Adicionar um novo treinamento
export const trainningAdd =
  (data, setWait, callbackOnSuccess) => (dispatch) => {
    fetchRedux(
      ROUTES[0],
      "POST",
      data,
      (resposta) => {
        toast.success(resposta.sucesso);
        dispatch({
          type: TRAINNING_ADD,
          data: resposta.data,
        });
        if (callbackOnSuccess) {
          callbackOnSuccess();
        }
      },
      () => {
        setWait();
      },
      (err) => ToastErro(err)
    );
  };

export const trainningClearData = () => ({
  type: TRAINNING_CLEAR_DATA,
});

export const trainningSetModal = (modal) => ({
  type: TRAINNING_SET_MODAL,
  data: modal,
});

export const trainningCloseModal = () => ({
  type: TRAINNING_CLOSE_MODAL,
});

export const trainningEdit =
  (data, setWait, callbackOnSuccess) => (dispatch) => {
    fetchRedux(
      ROUTES[0],
      "PUT",
      data,
      (resposta) => {
        toast.success(resposta.sucesso);
        dispatch({
          type: TRAINNING_EDIT,
          data: resposta.data,
        });
        if (callbackOnSuccess) {
          callbackOnSuccess();
        }
      },
      () => {
        setWait();
      },
      (err) => ToastErro(err)
    );
  };

///
export const trainningAddStudents =
  (data, setWait, callbackOnSuccess) => (dispatch) => {
    fetchRedux(
      ROUTES[3],
      "POST",
      data,
      (resposta) => {
        toast.success(resposta.sucesso);
        dispatch({
          type: TRAINNING_ADD_STUDENTS,
          data: resposta.data,
        });
        if (callbackOnSuccess) {
          callbackOnSuccess();
        }
      },
      () => {
        setWait();
      },
      (err) => ToastErro(err)
    );
  };

// Adicionar um novo curso ou editar um existente
export const trainningCouserAddOrEdit =
  (data, setWait, callbackOnSuccess) => (dispatch) => {
    let method = "POST";
    if (data.id) {
      method = "PUT";
    }
    fetchRedux(
      ROUTES[4],
      method,
      data,
      (resposta) => {
        toast.success(resposta.sucesso);
        dispatch({
          type: data.id ? TRAINNING_EDIT_COURSE : TRAINNING_ADD_COURSE,
          data: resposta.data,
        });
        if (callbackOnSuccess) {
          callbackOnSuccess();
        }
      },
      () => {
        setWait();
      },
      (err) => ToastErro(err)
    );
  };

export const trainningCourseDel =
  (data, setWait, callbackOnSuccess) => (dispatch) => {
    fetchRedux(
      ROUTES[4],
      "DELETE",
      data,
      (resposta) => {
        toast.success(resposta.sucesso);
        dispatch({
          type: TRAINNING_DEL_COURSE,
          data: data.id,
        });
        if (callbackOnSuccess) {
          callbackOnSuccess();
        }
      },
      () => {
        setWait();
      },
      (err) => ToastErro(err)
    );
  };

// Obter todos os instrutores
export const trainningAllInstructors = (setWait) => (dispatch) => {
  fetchRedux(
    ROUTES[5],
    "GET",
    null,
    (resposta) => {
      dispatch({
        type: TRAINNING_ALL_INSTRUCTORS,
        data: resposta,
      });
    },
    () => {
      setWait();
    },
    (err) => ToastErro(err)
  );
};
// Adicionar um novo instrutor ou editar um existente
export const trainningInstructorAddOrEdit =
  (data, setWait, callbackOnSuccess) => (dispatch) => {
    let method = "POST";
    if (data.id) {
      method = "PUT";
    }
    fetchRedux(
      ROUTES[5],
      method,
      data,
      (resposta) => {
        toast.success(resposta.sucesso);
        dispatch({
          type: data.id ? TRAINNING_EDIT_INSTRUCTOR : TRAINNING_ADD_INSTRUCTOR,
          data: resposta.data,
        });
        if (callbackOnSuccess) {
          callbackOnSuccess();
        }
      },
      () => {
        setWait();
      },
      (err) => ToastErro(err)
    );
  };

// Deletar um instrutor
export const trainningInstructorDel =
  (data, setWait, callbackOnSuccess) => (dispatch) => {
    fetchRedux(
      ROUTES[5],
      "DELETE",
      data,
      (resposta) => {
        toast.success(resposta.sucesso);
        dispatch({
          type: TRAINNING_DEL_INSTRUCTOR,
          data: data.id,
        });
        if (callbackOnSuccess) {
          callbackOnSuccess();
        }
      },
      () => {
        setWait();
      },
      (err) => ToastErro(err)
    );
  };

//
export const trainningAlterStatus =
  (data, setWait, callbackOnSuccess) => (dispatch) => {
    fetchRedux(
      ROUTES[6],
      "POST",
      data,
      (resposta) => {
        toast.success(resposta.sucesso);
        dispatch({
          type: TRAINNING_ALTER_STATUS,
          data: resposta.data,
        });
        if (callbackOnSuccess) {
          callbackOnSuccess();
        }
      },
      () => {
        setWait();
      },
      (err) => ToastErro(err)
    );
  };
// Adicionar um filtro
export const trainningSetFilter = (data, setWait) => (dispatch) => {
  const params = new URLSearchParams();
  _.keys(data).forEach((key) => {
    params.append(key, data[key]);
  });
  //
  fetchRedux(
    ROUTES[7],
    "GET",
    params,
    (resposta) => {
      dispatch({
        type: TRAINNING_SET_FILTER,
        data: {
          data: resposta,
          filter: data,
        },
      });
    },
    () => {
      if (setWait) setWait();
    },
    (err) => ToastErro(err)
  );
};
// Limpar o filtro
export const trainningClearFilter = () => ({
  type: TRAINNING_CLEAR_FILTER,
});
