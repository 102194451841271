import { createSelector } from "reselect";
import _ from "lodash";

export const selectAguardar = (state) => state?.aguardar;
export const selectModelos = (state) => state?.manutencaoModelo?.modelos;
export const selectDrawer = (state) => state?.manutencaoModelo?.drawer;

// Seletor para modelo selecinoado
export const selectModeloSelecionado = createSelector(
  selectModelos,
  selectDrawer,
  (modelos, drawer) => {
    const { dados } = drawer;
    // Busca o modelo e o retorna
    return _.filter(modelos, (val) => String(val.id) === String(dados))[0];
  }
);
