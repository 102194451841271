import { Container, Divider, Paper, Stack } from "@mui/material";
import React from "react";
import { useCallback, useRef, useState } from "react";
import * as yup from "yup";
import { Body1, EntradaForm, H6, Logo } from "../../components";
import { API_KEY } from "../../config";
import ReCAPTCHA from "react-google-recaptcha";
import { useFetch } from "../../hooks";
import { useEffect } from "react";
import { ToastErro } from "../../utils/utils";

const styleBackground = {
  height: "calc(100vh)",
  width: "100%",
  display: "flex",
  paddingTop: "16px",
  flexDirection: "column",
  justifyContent: "space-between",
  background: "linear-gradient(to bottom, #FFFFFF, #6DD5FA, #2980B9)",
};
//
//const rulePassword = /^(?=.*([a-zA-Z\d]|[a-z\d]|))(?=.*[@$!#%*?&])[a-zA-Z@$!%#*?&]{8,}$/g;
const rulePassword =
  /^((?=.*[a-zA-Z])|(?=.*[A-Z]))(?=.*[@$!#%*?&])[a-zA-Z\d@$!%#*?&]{8,}$/g;

function ForceAlterPassword() {
  const recaptchaRef = useRef();
  return (
    <Paper sx={styleBackground}>
      <Stack>
        <Stack direction="row-reverse" sx={{ mr: 2 }}>
          <Logo />
        </Stack>
        <Divider sx={{ my: 1 }} />
      </Stack>

      <FormAlterPassword recaptchaRef={recaptchaRef} />

      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={API_KEY} />
    </Paper>
  );
}
const FormAlterPassword = ({ recaptchaRef }) => {
  const { wait, setFetch, error, data } = useFetch(
    "/forca_alteracao_senha",
    "POST"
  );

  const [token, setToken] = useState(null);
  //
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);
  //
  useEffect(() => {
    if (data) {
      window.location.href = "/splash";
    }
  }, [data]);

  const schema = [
    { type: "password", name: "senha", label: "Senha" },
    { type: "password", name: "confirmar_senha", label: "Confirmar Senha" },
  ];
  //
  const schemaMessageError = {
    senha: "* Não atende aos requisitos mínimos.",
    confirmar_senha: "* Não atende aos requisitos ou não são idênticos.",
  };
  //
  const schemaValidator = yup.object().shape({
    senha: yup.string().matches(rulePassword).required(),
    confirmar_senha: yup
      .string()
      .test("", "", function (value) {
        return value === this.parent.senha;
      })
      .required(),
  });
  //
  const onSubmitForm = useCallback(
    async (val) => {
      let TOKEN = recaptchaRef.current.getValue();
      if (!token) {
        TOKEN = await recaptchaRef.current.executeAsync();
        setToken(TOKEN);
      } else {
        await recaptchaRef.current.reset();
        TOKEN = await recaptchaRef.current.executeAsync();
      }
      //
      setFetch({
        confirmar_senha: val.confirmar_senha,
        captcha: TOKEN,
      });
    },
    [recaptchaRef, setFetch, token, setToken]
  );

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 2 }}>
        <H6>Alteração de senha</H6>
        <Body1>
          A senha deve conter ao menos 1 dos seguintes caracteres (#$@!) e ao
          menos 7 letras.
        </Body1>
        <br />
        <EntradaForm
          schema={schema}
          schemaValidator={schemaValidator}
          schemaMessageError={schemaMessageError}
          onSubmit={onSubmitForm}
          wait={wait}
        />
      </Paper>
    </Container>
  );
};

export default ForceAlterPassword;
