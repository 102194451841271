/* Cadastro de seletores necessários para o módulo de Kaizen */
import _ from "lodash";
import { createSelector } from "reselect";

const selectorTamanhoCampos = (state) => state?.tamanhoCampos;
export const fnSeletorTamanho = createSelector(selectorTamanhoCampos, (obj) => {
  const USADOS = {};
  _.keys(obj).forEach((key) => {
    if (key === "projetos") {
      USADOS["addProjDescricao"] = obj[key].descricao; // Pegando total do campo descricao da tabela projetos
    }
  });
  return USADOS;
});
