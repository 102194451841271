//
export const SET_TR_SELECIONADO = 'SET_TR_SELECIONADO';
export const LIMPAR_TR_SELECIONADO = 'LIMPAR_TR_SELECIONADO';

export const setTrSelecionado = ID=>({
    type: SET_TR_SELECIONADO,
    id: ID
});

export const limparTrSelecionado = ()=>({
    type: LIMPAR_TR_SELECIONADO,
})