import React from "react";
import { Container } from "@mui/material";
import BackgroundRouter from "../background-router";

function GerenciaEstoque() {
  const _html =
    '<iframe title="workload - Página 1" style="width:100%; height:calc(100vh - 80px)" src="https://app.powerbi.com/view?r=eyJrIjoiZGZhM2FiNGMtYWI2ZC00ZTVjLTk1ODYtYzMyZjcwMjEwZTMwIiwidCI6Ijk4NmIwMzBlLWJmMzEtNDdlNy1hZTk0LWYyNzQ4MTA1ZTU0NiJ9" frameborder="0" allowFullScreen="true"></iframe>';
  return (
    <BackgroundRouter>
      <Container sx={{ mt: 1 }} maxWidth={false}>
        <div dangerouslySetInnerHTML={{ __html: _html }} />
      </Container>
    </BackgroundRouter>
  );
}

GerenciaEstoque.rota = "/gerencia_estoque";

export default GerenciaEstoque;
