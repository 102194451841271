import {
  Avatar,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAnimations } from "../feed_rh";
import { useFetch } from "../../../hooks";
import { format } from "date-fns";
import { ToastErro } from "../../../utils/utils";
import _ from "lodash";
import { DrawerDialog } from "../../../extra-components";
import { Body1, Calendario, Caption, H6 } from "../../../components";
import Lottie from "react-lottie";

const defaultOptions = {
  loop: true,
  autoplay: true,
  delay: 1000,
  animationData: "",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

// Representacao grafica
const GraficoEmocional = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const [drawer, setDrawer] = useState(null);

  const ANIMACOES = getAnimations(t);

  const { wait, error, data, setFetch } = useFetch(window.location.pathname);
  // Aciona a recuperação do Mes vigente
  useEffect(() => {
    const de = format(new Date(), "yyyy-MM-01");
    const ate = format(new Date(), "yyyy-MM-dd");

    setFetch({ de, ate });
  }, [setFetch]);
  // Verifica se tem erro e exibe
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  // Separa os dias
  const emocoesPorDia = {};
  // Passa pelo array e contabiliza as emocoes
  _.map(data, (val) => {
    if (!emocoesPorDia.hasOwnProperty(val.data)) {
      emocoesPorDia[val.data] = {};
    }
    // Verifica se a emocao não existe
    if (!emocoesPorDia[val.data].hasOwnProperty(val.id_emocao)) {
      emocoesPorDia[val.data][val.id_emocao] = 0;
    }
    // Incrementa a emocao em 1
    emocoesPorDia[val.data][val.id_emocao] += 1;
  });
  // Coloca como destino do dia a emocao que mais se intensificou neste dia
  const totalDiaPorEmocoes = {};
  _.keys(emocoesPorDia).forEach((key) => {
    // Cria um array dos objetos
    const arr = _.keys(emocoesPorDia[key]).map((k) => ({
      emocao: k,
      quantidade: emocoesPorDia[key][k],
    }));

    totalDiaPorEmocoes[key] = _.maxBy(arr, (val) => val.quantidade);
    // Agora pega esta emocao e cria um objeto que exiba o seu emoji emocional
    const _key = totalDiaPorEmocoes[key]["emocao"];

    totalDiaPorEmocoes[key] = _.filter(
      ANIMACOES,
      (val) => String(val.tipo) === String(_key)
    )[0];
  });
  // FUncao de callback que consegue controlar o avanço/recuo do calendário
  const fnControleCalendario = useCallback(
    (de, ate, __) => {
      setFetch({ de, ate });
    },
    [setFetch]
  );
  // Funcao que retorna o componente que representa o dia clicado
  const fnGetDiaClicado = useCallback(() => {
    if (!drawer) return null;
    //
    const dados = _.filter(data, (val) => val.data === drawer);
    if (dados.length < 1) return null;

    return <ListaEmocoesDoDia dados={dados} />;
  }, [data, drawer]);

  return (
    <Paper elevation={3}>
      <DrawerDialog
        fnGetCorpo={fnGetDiaClicado}
        fecharModal={() => setDrawer(null)}
      />
      <H6>{t("registro_emocional.titleEmotionGraph")}</H6>
      <Caption component="p">
        {t("registro_emocional.subtitleEmotionGraph")}
      </Caption>
      <Calendario
        onControleCalendario={fnControleCalendario}
        dataInicial={format(new Date(), "yyyy-MM-dd")}
        render={(dia) => {
          if (totalDiaPorEmocoes.hasOwnProperty(dia)) {
            const copia = { ...defaultOptions };
            copia.animationData = totalDiaPorEmocoes[dia].animacao;
            //
            return (
              <Lottie
                title={` ${t("registro_emocional.titleEmotionGraphLottie1")} ${
                  totalDiaPorEmocoes[dia].titulo
                }(s) ${t("registro_emocional.titleEmotionGraphLottie2")}`}
                options={copia}
                width={isMobile ? 36 : 56}
                height={isMobile ? 36 : 56}
              />
            );
          }
          return null;
        }}
        onClick={(dia) => setDrawer(dia)}
        aguardar={wait}
      />
      <br />
    </Paper>
  );
};

// Uma lista com todas as emocoes registradas no dia
const ListaEmocoesDoDia = ({ dados }) => {
  const { t } = useTranslation();
  const ANIMACOES = getAnimations(t);

  return (
    <Container maxWidth="lg">
      <H6>{t("registro_emocional.titleEmotionGraphToday")}</H6>
      <List>
        {dados.map((ele, idx) => {
          const lottieOptions = { ...defaultOptions };
          // Verifica qual emocao foi recuperada
          const emocao = _.filter(
            ANIMACOES,
            (val) => String(val.tipo) === String(ele.id_emocao)
          )[0];
          lottieOptions.animationData = emocao.animacao;

          return (
            <ListItem divider key={idx}>
              <ListItemAvatar>
                <Avatar
                  src={`/static/imagens/avatar/${ele.planta}/${ele.matricula}.jpg`}
                  alt={ele.nome}
                />
              </ListItemAvatar>
              <ListItemText
                primary={ele.nome}
                secondary={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Lottie
                      title={emocao.titulo}
                      options={lottieOptions}
                      width={48}
                      height={48}
                    />
                    &nbsp;&nbsp;
                    <Body1 sx={{ flex: 1 }} align="left">
                      {t("registro_emocional.labelSector")}: {ele.arvore}
                    </Body1>
                  </Stack>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

export default GraficoEmocional;
