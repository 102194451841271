import React, { memo } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { manutencaoVariaveisAddVariavelGlobal } from "./manutencao-variaveis-actions";
import { Container } from "@mui/material";
import { Caption, EntradaForm, H6 } from "../../../components";
import { useTranslation } from "react-i18next";

// Componente para adicionar variavel global
const FormAddVariavelGlobal = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schema = [
    {
      name: "nome",
      type: "text",
      label: t("manutencao_variaveis.labelVarName"),
      placeholder: t("manutencao_variaveis.placeholderVarName"),
      defaultValue: "",
      grid: { xs: 12 },
    },
    {
      name: "descricao",
      type: "textarea",
      label: t("manutencao_variaveis.labelVarDescription"),
      placeholder: t("manutencao_variaveis.placeholderVarDescription"),
      multiline: true,
      minRows: 2,
      defaultValue: "",
      grid: { xs: 12 },
    },
    {
      name: "valor",
      type: "text",
      label: t("manutencao_variaveis.labelVarValue"),
      placeholder: t("manutencao_variaveis.placeholderVarValue"),
      defaultValue: "",
      grid: { xs: 12 },
    },
  ];
  const schemaValidator = yup.object().shape({
    nome: yup.string().min(3).required(),
    descricao: yup.string().min(3).required(),
    valor: yup.string().min(1).required(),
  });
  const schemaMessageError = {
    descricao: t("manutencao_variaveis.errorVarDescription"),
    nome: t("manutencao_variaveis.errorVarName"),
    valor: t("manutencao_variaveis.errorVarValue"),
  };
  const fn = (val) => {
    dispatch(
      manutencaoVariaveisAddVariavelGlobal({
        nome: val.nome,
        descricao: val.descricao,
        valor: val.valor,
      })
    );
  };

  return (
    <Container maxWidth="md" sx={{ height: "60vh", overflowY: "auto" }}>
      <H6>{t("manutencao_variaveis.titleNewVar")}</H6>
      <Caption align="center" component="p">
        {t("manutencao_variaveis.descriptionNewVar")}
      </Caption>

      <EntradaForm
        schema={schema}
        schemaValidator={schemaValidator}
        schemaMessageError={schemaMessageError}
        onSubmit={fn}
      />
    </Container>
  );
});

export default FormAddVariavelGlobal;
