import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTheme, Stack, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import Logo from "./images/logo_branca.svg";
import Lottie from "react-lottie";
import dataLottie from "./lotties/splash.json";

import axios from "axios";
import { useDispatch } from "react-redux";
import { tamanhoCamposGet } from "./redux/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Image = styled(motion.img)`
  object-fit: contain;
  ${(props) =>
    props.isMobile &&
    `
        width: 250px;    
    `};
`;

const efeitos = {
  slideLeft: {
    hidden: {
      x: "-150vw",
    },
    visible: {
      x: 0,
      transition: {
        delay: 0.4,
        duration: 0.4,
      },
    },
  },
};

const Splash = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ativar, setAtivar] = useState(false);

  useEffect(() => {
    dispatch(tamanhoCamposGet());
  }, [dispatch]);

  const {
    breakpoints: { values },
  } = useTheme();
  const isMobile = values["sm"] > window.innerWidth;
  useEffect(() => {
    (async () => {
      const resp = await axios.get("/get_dados_usuario");
      if (resp.status !== 200) {
        alert("ERRO INTERNO, SE PERSISTIR INFORMAR AO ADMIN !!!");
        return false;
      }
      // Veja se alguma mensagem de erro foi retornada
      if (resp.data.erro) {
        alert(resp.data.erro);
        return false;
      }

      // Veja se ele tem algum menu vinculado
      if (resp.data.menu && Object.keys(resp.data.menu).length < 1) {
        alert(
          "USUARIO AUTENTICADO, MAS NÃO TEM NENHUM MENU VINCULADO, NÃO PODE PROSSEGUIR"
        );
        return false;
      }
      // Encontrou um menu, redirecione o usuario a este menu
      //const ROTA = resp.data.menu[ Object.keys(resp.data.menu)[0] ][0][0];
      setTimeout(() => {
        history.replace("/feed_rh");
        //window.location.href = "/feed_rh";
        //history.replace("/feed_rh");
      }, 3000);
    })();
  }, [history]);
  //
  useEffect(() => {
    setTimeout(() => {
      setAtivar(true);
    }, 500);
  }, []);
  //
  const defaultOptions = {
    loop: false,
    autoplay: ativar,
    delay: 2000,
    animationData: dataLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Stack
      sx={{
        p: 2,
        height: "100vh",
        background:
          "linear-gradient(180deg, #005387 0%, rgba(3, 34, 53, 0.36) 97.67%, rgba(255, 255, 255, 0) 100%)",
      }}
      alignItems="center"
    >
      <Lottie options={defaultOptions} height={200} />

      <Image
        src={Logo}
        isMobile={isMobile}
        variants={efeitos.slideLeft}
        initial="hidden"
        animate="visible"
      />
      <Stack sx={{ mt: 3 }} direction="row" justifyContent="center">
        <CircularProgress sx={{ color: "white" }} />
      </Stack>
    </Stack>
  );
};
export default Splash;
