import { createSelector } from "reselect";
import _ from "lodash";
//
export const selectDados = (state) => state?.manutencaoArea;
export const selectUsuario = (state) => state?.usuario?.usuario;
export const selectArea = (state) => state?.manutencaoArea?.area;
export const selectModelo = (state) => state?.manutencaoArea?.modelos;
export const selectAreaXModelo = (state) => state?.manutencaoArea?.areaXModelo;
export const selectDrawer = (state) => state?.manutencaoArea?.drawer;

export const selectAreaSelecionada = createSelector(
  selectArea,
  selectDrawer,
  selectAreaXModelo,
  (area, drawer, areaXModelo) => {
    // Primeiro passo e pegar os dados da area
    const areaSelecionada = area.filter(
      (ele) => String(ele.id) === String(drawer.dados)
    )[0];
    // Depois reservar os modelos que fazem parte desta area (somente id)
    const modelosDaArea = _.includes(
      Object.keys(areaXModelo),
      String(drawer.dados)
    )
      ? _.map(areaXModelo[drawer.dados], (val) => val.id)
      : [];
    // Por fim retorne as areas selecionadas e o modelo
    return {
      areaSelecionada,
      modelosDaArea,
    };
  }
);
