import React, { memo } from "react";
import {
  List,
  Stack,
  ListItem,
  ListItemText,
  Typography,
  Skeleton,
  Divider,
} from "@mui/material";

// Skeletos
const ListaSkeletonProjetos = memo(() => {
  return (
    <List
      sx={{
        mt: 2,
        height: "70vh",
        overflow: "auto",
      }}
      disablePadding
    >
      {Array.from(Array(10)).map((ele, idx) => {
        return (
          <Stack key={idx}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography component="p" variant="subtitle2">
                    <Skeleton />
                  </Typography>
                }
                secondary={
                  <Stack>
                    <Typography variant="subtitle1">
                      <Skeleton />
                    </Typography>
                    <Typography variant="subtitle2">
                      <Skeleton />
                    </Typography>
                    <Typography variant="caption">
                      <Skeleton />
                    </Typography>
                    <Typography variant="caption">
                      <Skeleton />
                    </Typography>
                  </Stack>
                }
              />
            </ListItem>
            <Divider sx={{ mt: 1 }} />
          </Stack>
        );
      })}
    </List>
  );
});

export default ListaSkeletonProjetos;
