import React, { useCallback } from "react";
import { Body1, Caption, Icone, TabelaV2 } from "../../../components";
import Utils, { optTabela } from "../../../utils/utils";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  splitbudgetTableSyntheticSelector,
  selectorMonths,
} from "./budget-selectors";
import { format } from "date-fns";
import { budgetSetModal } from "./budget-actions";
import BudgetModal from "./budget-modal";
import { Accordion, AccordionSummary, Button, Stack } from "@mui/material";
import { AccordionDetails } from "@material-ui/core";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

// Fatias das tabelas que precisam ser geradas para cada sintético mensal
const SLICES = _.reverse([
  [2, 5, 0],
  [6, 9, 1],
  [10, 13, 2],
  [14, 17, 3],
  [18, 21, 4],
  [22, 25, 5],
  [26, 29, 6],
  [30, 33, 7],
  [34, 37, 8],
  [36, 41, 9],
  [42, 45, 10],
  [46, 49, 11],
  [50, 53, 12],
]);

const styleSuperCabecalho = {
  p: 1,
  m: 0,
  borderRadius: 0,
  color: "white",
  fontSize: "11pt",
  backgroundColor: "#012258",
};
//
const styleTH = {
  fontSize: "10pt",
  color: "white",
  backgroundColor: "#012258",
};

function SyntheticPage() {
  const months = useSelector(selectorMonths);
  const { t } = useTranslation();

  return (
    <Stack gap={0}>
      {SLICES.map((ele, idx) => (
        <Accordion key={idx} disableGutters>
          <AccordionSummary expandIcon={<Icone icone="ExpandMore" />}>
            <Body1>
              {t("budget.month")} {months[ele[2]]}
            </Body1>
          </AccordionSummary>
          <AccordionDetails>
            <RenderTable start={ele[0]} final={ele[1]} idxMonth={ele[2]} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
}

const RenderTable = ({ start, final, idxMonth }) => {
  const dataTable = useSelector(
    splitbudgetTableSyntheticSelector(start, final, idxMonth)
  );
  // Funcao que permite interatividade com a coluna selecionada
  const fnViewDetails = useCallback(
    (val, idx, reg) => {
      const keyFilter = `${reg.values[0]}${format(new Date(), "yyyy")}${(
        idxMonth + 1
      )
        .toString()
        .padStart(2, "0")}`;
      return <ViewDetailsValueBudget value={val} keyFilter={keyFilter} />;
    },
    [idxMonth]
  );
  // Funcao para determinar se a diferença é positiva e/ou negativa
  const fnViewDiff = useCallback((val, idx, reg) => {
    const style = {};
    if (val.toString().search("-") !== -1) {
      style.color = red[600];
      style.fontWeight = "bold";
    }
    return <Caption sx={style}>{Utils.converter(val.toFixed(2))}</Caption>;
  }, []);

  if (dataTable.body?.length === 0) {
    return null;
  }

  const styleTHReg = {};
  _.forEach(dataTable.body[0], (vl, i) => {
    styleTHReg[i] = {
      ...styleSuperCabecalho,
      ...styleTH,
    };
  });

  const styleSuperCabe = {};
  _.forEach(dataTable.header, (k, i) => {
    styleSuperCabe[i] = {
      ...styleSuperCabecalho,
    };
  });
  //
  const optTabelaT = {
    ...optTabela,
    tamanho: 64 + 47 * dataTable.body.length + 47 + 10,
    ocultarFiltro: true,
    baixar_em_excel: null,
    monetario: [2, 3, 4],
    percentual: [5],
    styleSuperCabecalho: styleSuperCabe,
    styleTH: styleTHReg,
    envolver: {
      2: fnViewDetails,
      4: fnViewDiff,
    },
  };

  return (
    <TabelaV2 cabe={dataTable.header} corpo={dataTable.body} {...optTabelaT} />
  );
};

const ViewDetailsValueBudget = ({ keyFilter, value }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    dispatch(
      budgetSetModal({
        type: BudgetModal.MODAL.DETAILS_ACCOUNT,
        data: {
          keyFilter,
        },
      })
    );
  }, [keyFilter, dispatch]);

  return (
    <Button
      variant="text"
      size="small"
      sx={{ textAlign: "left" }}
      title={t("budget.titleClickValue")}
      onClick={onClick}
    >
      {Utils.converter(parseFloat(value).toFixed(2))}
    </Button>
  );
};

export default SyntheticPage;
