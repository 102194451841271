import React, { useState, useEffect, useCallback } from "react";
import ImagemFabrica from "../../../images/fabrica.svg";
import {
  useTheme,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Typography,
  Grow,
  Box,
  ButtonBase,
} from "@mui/material";
import {
  Hidden,
  CardMedia,
  Grid,
  Menu,
  MenuItem,
  Fab,
  Paper,
  Button,
  Stack,
  List,
  Card,
  Avatar,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { useLongPress, useToggle } from "react-use";
import {
  feedRHGetFeed,
  feedRHSetDrawer,
  feedRHFecharDrawer,
  feedRHGetComentarios,
  feedRHAddUpdCurtida,
  feedRHDelCurtida,
  feedRHGetCurtidas,
  feedRHLimparDados,
} from "./feed-actions";
import { ToastContainer } from "react-toastify";
import { blue } from "@mui/material/colors";
import { format, parseISO } from "date-fns";
import { ToastErro } from "../../../utils/utils";
// Icones
import CommentIcon from "@mui/icons-material/Comment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// Animacoes do lottie
import Coracao from "../../../lotties/emoji-coracao.json";
import Nervoso from "../../../lotties/emoji-nervoso.json";
import Sorrindo from "../../../lotties/emoji-sorrindo.json";
import ToNemAi from "../../../lotties/emoji-to-nem-ai.json";
import Triste from "../../../lotties/emoji-triste.json";
import Zen from "../../../lotties/emoji-zen.json";
import Curtir from "../../../lotties/emoji-curtir.json";

import FeedVazio from "../../../lotties/feed_rh_vazio.json";
//
import Lottie from "react-lottie";
import { H6, Body2, Body1, ScrollInfinito } from "../../../components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Aniversariantes, DrawerDialog } from "../../../extra-components";

import Logo from "../../../images/logo.svg";
import { motion } from "framer-motion";
import axios from "axios";
import BackgroundRouter from "../../background-router";
import ZoomImage from "../../../components/zoom-image";
import {
  selectorFeedRH,
  selectorIsEditorFeed,
  selectorPlantUser,
  selectorViewLogoFeed,
} from "./selectors";
import FeedModal from "./feed-modal";
import PainelComentario from "./components/feed-painel-comentario";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
//
const defaultOptions = {
  loop: true,
  autoplay: true,
  delay: 1000,
  animationData: "",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const getAnimations = (t) => {
  return [
    {
      animacao: Curtir,
      tipo: 7,
      titulo: t("feed_rh.animationLike"),
    },
    {
      animacao: Sorrindo,
      tipo: 1,
      titulo: t("feed_rh.animationHappy"),
    },
    {
      animacao: Zen,
      tipo: 2,
      titulo: t("feed_rh.animationZen"),
    },
    {
      animacao: Coracao,
      tipo: 3,
      titulo: t("feed_rh.animationLove"),
    },
    {
      animacao: ToNemAi,
      tipo: 4,
      titulo: t("feed_rh.animationConfused"),
    },
    {
      animacao: Triste,
      tipo: 5,
      titulo: t("feed_rh.animationSad"),
    },
    {
      animacao: Nervoso,
      tipo: 6,
      titulo: t("feed_rh.animationUngry"),
    },
  ];
};
// URL
const ROTA = "/como_me_sinto";
//
const IMAGENS = ["jpg", "jpeg", "png", "gif", "webp"];
// Objet com todos os tipos representados por emojis
const TIPOS = {
  1: "😀", // Ok
  2: "😌", // OK
  3: "😍", // OK
  4: "🙄", // OK
  5: "😟", // OK
  6: "😡", // OK
  7: "👍",
};

const selectTamanhoCampoComentario = (state) =>
  state?.tamanhoCampos?.comentarios?.comentario;

function FeedRH() {
  const dispatch = useDispatch();
  const isMobile = useTheme()?.isMobile;
  const feedRH = useSelector(selectorFeedRH);
  const isEditorFeed = useSelector(selectorIsEditorFeed);

  useEffect(() => {
    dispatch(feedRHGetFeed(1));
    return () => dispatch(feedRHLimparDados());
  }, [dispatch]);
  // Se tiver um drawer no feedRH devemos prepara o objeto para exibicao
  //let corpo;
  let fnFecharDrawer = () => dispatch(feedRHFecharDrawer());

  return (
    <BackgroundRouter>
      <Container disableGutters={isMobile} sx={{ mt: 0.5 }} maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Container sx={{ mt: 0.5 }} disableGutters>
              {!isMobile && isEditorFeed && (
                <PainelSuperior isEditorFeed={isEditorFeed} />
              )}
              {feedRH?.drawer && (
                <DrawerDialog
                  fecharModal={fnFecharDrawer}
                  fnGetCorpo={() => <FeedModal modal={feedRH?.drawer} />}
                />
              )}
              <ToastContainer position="bottom-center" />

              <ComoEstouMeSentindo />
              <ListaFeed
                feed={feedRH?.feed}
                feed_x_anexo={feedRH?.feed_x_anexo}
                feed_x_comentario={feedRH?.feed_x_comentario}
                feed_x_curtida={feedRH?.feed_x_curtida}
              />
              {isMobile && isEditorFeed && (
                <Fab
                  size="medium"
                  color="primary"
                  aria-label="add"
                  onClick={() => {
                    dispatch(
                      feedRHSetDrawer({
                        tipo: FeedModal.modal.FEED_DRAWER_INTENCAO_FORM,
                      })
                    );
                  }}
                  sx={{
                    zIndex: 1000,
                    position: "fixed",
                    right: 16,
                    bottom: 72,
                  }}
                >
                  <AddIcon />
                </Fab>
              )}
              <br />
              <br />
              <br />
              <br />
            </Container>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={4}>
              <Paper sx={{ mt: 1 }} elevation={4}>
                <Aniversariantes
                  rota="/aniversariante_empresa"
                  isNiverEmpresa
                  imagemIcone={ImagemFabrica}
                />
              </Paper>
            </Grid>
          </Hidden>
          <Hidden mdDown>
            <Grid item xs={12} md={4}>
              <Paper sx={{ mt: 1 }} elevation={4}>
                <Aniversariantes />
              </Paper>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </BackgroundRouter>
  );
}
// Componente de sentimentos
const ComoEstouMeSentindo = () => {
  const { t } = useTranslation();
  const [sentimento, setSentimento] = useState(null);
  const viewLogoFeed = useSelector(selectorViewLogoFeed);
  // Tenta obter o estado emocional do individuo pela data atual
  useEffect(() => {
    (async () => {
      try {
        const resp = await axios.get(
          `${ROTA}?de=${format(new Date(), "yyyy-MM-dd")}&ate=${format(
            new Date(),
            "yyyy-MM-dd"
          )}`
        );
        if (resp.status !== 200) {
          ToastErro(t("feed_rh.errStatusNotOK"));
          return false;
        }
        // Verifica se tem mensagem de erro
        if (resp.data.erro) {
          ToastErro(resp.data.erro);
          return false;
        }
        // Recebido verifica se tem estado emocionale atualiza o sentimento
        if (resp.data.length > 0) {
          setSentimento(resp.data[0].id_emocao);
        } else {
          setSentimento(null);
        }
      } catch (e) {
        ToastErro(t("feed_rh.errUnknown"));
      }
    })();
  }, [setSentimento, t]);
  // Callback para atualizar o estado emocional
  const atualizaEstadoEmocional = useCallback(
    async (id_emocao) => {
      try {
        // Se a emocao enviada for a mesma registrada dele
        let resp;
        let valorSentimento;
        if (id_emocao === sentimento) {
          resp = await axios.delete(ROTA);
          valorSentimento = null;
        } else {
          // const formData = new FormData();
          // formData.append("dados", JSON.stringify({ id_emocao }));
          const formData = { id_emocao };
          resp = await axios.post(ROTA, formData);
          valorSentimento = id_emocao;
        }
        if (resp.status !== 200) {
          ToastErro(t("feed_rh.errStatusNotOK"));
          return false;
        }
        // Verifica se tem mensagem de erro
        if (resp.data.erro) {
          ToastErro(resp.data.erro);
          return false;
        }
        //
        setSentimento(valorSentimento);
      } catch (error) {
        console.log(error);
        ToastErro(t("feed_rh.errUnknown"));
      }
    },
    [setSentimento, sentimento, t]
  );

  const ANIMACOES = getAnimations(t);

  return (
    <Paper
      elevation={3}
      sx={{ pb: 1, mt: 1, width: "100%", overflowX: "auto" }}
    >
      <Stack direction="column" alignItems="center">
        {viewLogoFeed && viewLogoFeed?.length > 2 && (
          <ViewImage imageUrl={viewLogoFeed} />
        )}
        <Stack alignItems="center">
          <H6>{t("feed_rh.titleEmotionalClimate")}</H6>
          <Body1 sx={{ opacity: 0.7 }}>
            {t("feed_rh.descriptionEmotionalClimate")}
          </Body1>
          <Stack direction="row" spacing={1}>
            {ANIMACOES.map((ele, idx) => {
              const copia = { ...defaultOptions };
              copia.animationData = ele.animacao;
              const isHoje = sentimento === ele.tipo;
              return (
                <ButtonBase
                  title={ele.titulo}
                  key={idx}
                  onClick={() => atualizaEstadoEmocional(ele.tipo)}
                >
                  <Paper
                    elevation={isHoje ? 1 : 0}
                    sx={{ p: 1, backgroundColor: isHoje && blue[100] }}
                  >
                    <Stack alignItems="center">
                      <Lottie
                        title={ele.titulo}
                        options={copia}
                        height={24}
                        width={24}
                      />

                      <Grow in={isHoje} unmountOnExit={true}>
                        <Box>
                          <Body2 fontWeight={500}>
                            {isHoje && t("feed_rh.today")}
                          </Body2>
                        </Box>
                      </Grow>
                    </Stack>
                  </Paper>
                </ButtonBase>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};
//
const ViewImage = ({ imageUrl }) => {
  const { t } = useTranslation();
  let [url, params] = imageUrl.split("?");
  const styleImg = {
    height: 72,
  };

  params = new URLSearchParams(params);

  if (params?.has("w")) {
    styleImg.width = `${params.get("w")}px`;
  }
  if (params?.has("h")) {
    styleImg.height = `${params.get("h")}px`;
  }

  return (
    <Box sx={{ m: 1 }}>
      <img alt={t("feed_rh.altImageFeed")} src={url} style={styleImg} />
    </Box>
  );
};
// Componente para o painel superior
const PainelSuperior = ({ isEditorFeed }) => {
  const dispatch = useDispatch();
  const isMobile = useTheme()?.breakpoints?.values.sm > window.innerWidth;

  return (
    <Paper elevation={0} sx={{ p: 1, mb: 1, minHeight: 24 }}>
      <Stack direction="row-reverse">
        {!isMobile && isEditorFeed && (
          <Button
            onClick={() =>
              dispatch(
                feedRHSetDrawer({
                  tipo: FeedModal.modal.FEED_DRAWER_INTENCAO_FORM,
                  dados: null,
                })
              )
            }
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
          >
            {t("feed_rh.btnNewPost")}
          </Button>
        )}
      </Stack>
    </Paper>
  );
};
// Componente para criar o list das imagens
const ListaFeed = ({
  feed,
  feed_x_anexo,
  feed_x_curtida,
  feed_x_comentario,
}) => {
  const { t } = useTranslation();
  // Obter os dados do usuario logado
  const planta = useSelector(selectorPlantUser);
  const isEditorFeed = useSelector(selectorIsEditorFeed);
  // Faz um filtro sobre os feeds para ver se ele pode ser visivel para o usuario logado
  const copiaFeed = feed
    ? feed.filter((ele) => ele.visivel_para.includes(planta))
    : [];

  return (
    <List sx={{ mx: 0.5 }}>
      {copiaFeed ? (
        copiaFeed.length < 1 ? (
          <Stack>
            <Lottie
              options={{
                ...defaultOptions,
                animationData: FeedVazio,
              }}
              height={240}
              width={319}
            />
            <Typography variant="h6" align="center">
              {t("feed_rh.feedNotFound")}
            </Typography>
          </Stack>
        ) : (
          <ScrollInfinito
            tamanho="75vh"
            itens={copiaFeed}
            render={(ele) => (
              <ListaFeedItens
                key={ele.id_feed}
                feed={ele}
                anexo={feed_x_anexo[ele.id_feed]}
                curtidas={feed_x_curtida[ele.id_feed]}
                comentarios={feed_x_comentario[ele.id_feed]}
                editorFeed={isEditorFeed}
              />
            )}
          />
        )
      ) : null}
    </List>
  );
};
// Componente para listar um Feed
const ListaFeedItens = ({ feed, anexo, curtidas, comentarios, editorFeed }) => {
  const { t } = useTranslation();
  const tamanhoCampoComentario = useSelector(selectTamanhoCampoComentario);
  const isMobile = useTheme()?.isMobile;
  const { avatar, assunto, data, id_feed, nome, observacao, titulo } = feed;
  // Veja a extensao do tipo do anexo
  const ext = anexo.split(".").pop();
  // Funcao para excluir a curtida
  const fnExcluirCurtida = () => {
    dispatch(
      feedRHDelCurtida({
        id_feed,
      })
    );
  };
  //
  const dispatch = useDispatch();
  const [verOpcoes, setVerOpcoes] = useState(null); // Para exibir/ocultar opcoes do feed (editar, excluir)
  const [verOpcoesCurtida, setVerOpcoesCurtida] = useState(false); // Para exibir/ocultar mais opções das curtidas

  // Para controlar a exibição dos emojis de reação
  const longPressEvent = useLongPress(
    (e) => {
      // Se tiver sido curtido devemos definir a opcao de exclusao
      if (curtidas?.euCurti) {
        fnExcluirCurtida();
      } else {
        setVerOpcoesCurtida(e.target);
      }
    },
    {
      isPreventDefault: true,
      delay: curtidas?.euCurti ? 1 : 1,
    }
  );
  // Funcao que vai fazer com que a curtida seja gravada
  const fnRegistrarCurtida = (tipoCurtida) => {
    const obj = {
      id_feed,
    };
    if (curtidas?.euCurti) {
      obj["id_curtida"] = curtidas.euCurti;
    }
    obj["tipo"] = tipoCurtida;

    dispatch(feedRHAddUpdCurtida(obj, curtidas?.euCurti));
  };
  // Criar uma funcao que vai exibir todas as curtidas
  const fnVerCurtidas = () => {
    dispatch(feedRHGetCurtidas(id_feed));
  };

  // Criar a funcao que irá fazer com que os comentarios sobre um post sejam exibidos
  const fnVerComentarios = () => {
    dispatch(feedRHGetComentarios(id_feed));
  };

  const ANIMACOES = getAnimations(t);

  return (
    <Card elevation={3} sx={{ py: 1, my: 1 }}>
      <Menu
        anchorEl={verOpcoes}
        open={Boolean(verOpcoes)}
        onClose={() => setVerOpcoes(null)}
      >
        <MenuItem
          onClick={() => {
            dispatch(
              feedRHSetDrawer({
                tipo: FeedModal.modal.FEED_DRAWER_INTENCAO_FORM,
                dados: feed,
              })
            );
            setVerOpcoes(null);
          }}
        >
          {t("feed_rh.btnEdit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(
              feedRHSetDrawer({
                tipo: FeedModal.modal.FEED_DEL_FEED,
                dados: feed.id_feed,
              })
            );
            setVerOpcoes(null);
          }}
        >
          {t("feed_rh.btnDelete")}
        </MenuItem>
      </Menu>

      <CardHeader
        action={
          editorFeed && (
            <IconButton
              onClick={(e) => setVerOpcoes(e.currentTarget)}
              aria-label="settings"
            >
              <MoreVertIcon color="primary" />
            </IconButton>
          )
        }
        title={titulo}
        titleTypographyProps={{
          align: "center",
        }}
        subheaderTypographyProps={{
          align: "center",
          fontWeight: "bold",
        }}
        subheader={assunto}
      />
      {IMAGENS.includes(ext?.toLowerCase()) ? (
        <ImagemFeed titulo={titulo} anexo={anexo} isMobile={isMobile} />
      ) : (
        <CardMedia
          component="video"
          src={anexo}
          //autoPlay
          controls
        />
      )}
      <CardContent>
        <Stack direction="row">
          <Avatar sx={{ height: 36 }} src={avatar} alt={nome} />

          <Stack>
            <Typography sx={{ ml: 1 }} variant="caption">
              {nome}
            </Typography>
            <Typography sx={{ ml: 1, opacity: 0.7 }} variant="caption">
              {`${t("feed_rh.postedOn")} ${format(
                parseISO(data),
                "dd/MM/yy HH:mm"
              )}`}
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ my: 2 }} />

        <Typography variant="body2">{observacao}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Menu
          anchorEl={verOpcoesCurtida}
          open={Boolean(verOpcoesCurtida)}
          onClose={() => setVerOpcoesCurtida(false)}
        >
          <Stack sx={{ overflowX: "auto" }} direction="row" spacing={1}>
            {ANIMACOES.map((ele, idx) => {
              const copia = { ...defaultOptions };
              copia.animationData = ele.animacao;
              return (
                <IconButton
                  key={idx}
                  onClick={() => {
                    setVerOpcoesCurtida(false);
                    // Enviando a curtida para registro
                    fnRegistrarCurtida(ele.tipo);
                  }}
                >
                  <Lottie options={copia} height={36} width={36} />
                </IconButton>
              );
            })}
          </Stack>
        </Menu>
        <Stack sx={{ width: "100vw" }}>
          <Stack direction="row">
            {curtidas && (
              <Button onClick={fnVerCurtidas}>
                {curtidas &&
                  Object.keys(curtidas.tipos).map(
                    (key) =>
                      curtidas.tipos[key] > 0 && (
                        <Typography key={key} variant="h6">
                          {TIPOS[key]}
                        </Typography>
                      )
                  )}
                &nbsp;
                <Typography variant="caption">
                  {t("feed_rh.viewLikes")}
                </Typography>
              </Button>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={{ md: "flex-start", xs: "space-between" }}
          >
            <IconButton
              onClick={fnVerComentarios}
              title={t("feed_rh.titleWhenComments")}
            >
              <CommentIcon />
              {comentarios ? (
                <Typography variant="caption">
                  &nbsp;{" "}
                  {`${comentarios?.quantidade} ${
                    comentarios?.quantidade < 2
                      ? t("feed_rh.comment")
                      : t("feed_rh.comments")
                  }`}
                </Typography>
              ) : (
                <Typography variant="caption">
                  &nbsp; {t("feed_rh.notComments")}
                </Typography>
              )}
            </IconButton>

            <IconButton
              disableRipple
              title={
                curtidas?.euCurti
                  ? t("feed_rh.titleLikedYou")
                  : t("feed_rh.titleLike")
              }
              //onClick={fnExcluirCurtida}
              {...longPressEvent}
            >
              <ThumbUpIcon color={curtidas?.euCurti ? "primary" : "default"} />
              {curtidas ? (
                <Typography
                  color={curtidas?.euCurti ? "primary" : "default"}
                  variant="caption"
                >
                  &nbsp;{" "}
                  {`${curtidas?.quantidade} ${
                    curtidas.quantidade < 2
                      ? t("feed_rh.like")
                      : t("feed_rh.likes")
                  } `}
                </Typography>
              ) : (
                <Typography variant="caption">
                  &nbsp; {t("feed_rh.notLikes")}
                </Typography>
              )}
            </IconButton>
          </Stack>
        </Stack>
      </CardActions>
      <PainelComentario
        id_feed={id_feed}
        maxLength={tamanhoCampoComentario || 0}
      />
    </Card>
  );
};
// Componente para representar um feed de imagem
const ImagemFeed = ({ titulo, anexo, isMobile }) => {
  const { t } = useTranslation();
  const [verFeedFull, setVerFeedFull] = useState(false);
  //
  const [modal, setModal] = useToggle();
  //

  return (
    <>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => (
            <Stack sx={{ maxHeight: "85vh", overflowY: "auto" }}>
              <ZoomImage src={anexo} alt={titulo} />
              <br />
              <br />
            </Stack>
          )}
          fecharModal={setModal}
        />
      )}
      <Paper elevation={1} sx={{ m: 1 }}>
        <motion.div
          style={{ overflow: "hidden" }}
          transition={{ duration: 0.3 }}
          animate={{ height: verFeedFull ? "auto" : 350 }}
          onClick={setModal}
        >
          <LazyLoadImage
            src={anexo}
            alt={titulo}
            width="100%"
            placeholderSrc={Logo}
          />
        </motion.div>
      </Paper>
      <Stack sx={{ px: 1 }} direction="row" justifyContent="center">
        {!verFeedFull && (
          <Button
            fullWidth={isMobile}
            variant="contained"
            size="small"
            startIcon={verFeedFull ? <VisibilityOffIcon /> : <VisibilityIcon />}
            onClick={() => setVerFeedFull(!verFeedFull)}
          >
            {verFeedFull ? t("feed_rh.viewMinus") : t("feed_rh.viewMore")}
          </Button>
        )}
      </Stack>
    </>
  );
};

FeedRH.rota = "/feed_rh";

export default FeedRH;
