import React, { useEffect, useState, memo, useCallback } from "react";
import { ToastContainer } from "react-toastify";
import {
  Typography,
  Container,
  Stack,
  Button,
  Grid,
  Hidden,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { useTranslation } from "react-i18next";

// Icones
import PostAddIcon from "@mui/icons-material/PostAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { registrarAcesso } from "../../../utils/utils";
import {
  cadastroProjetosSetDrawer,
  cadastroProjetosGetDados,
  cadastroProjetosGetProjetos,
  cadastroProjetosFiltroAvancadoFechar,
  cadastroProjetosGetDadosGraficos,
  cadastroProjetosGetProjetosFiltroComplexoPaginado, // Para receber parametros para um filtro de projetos mais complexo
} from "./kaizen-actions";
//
import { SideBar, RetornoEmBranco } from "../../../components";

import AccountTree from "@mui/icons-material/AccountTree";
import KaizenModal from "./kaizen-modal";
import PainelDeStatus from "./kaizen-panel-status";
import ListaProjetos from "./kaizen-list-projects";
import { DrawerDialog } from "../../../extra-components";
import ListaSkeletonProjetos from "./kaizen-skeleton-project";

const CadastroProjetos = () => {
  const { t } = useTranslation();
  const bgPage = useTheme()?.backgroundPage;
  const cadastroProjetos = useSelector((state) => state.cadastroProjetos);
  const dispatch = useDispatch();

  const {
    breakpoints: { values },
  } = useTheme();

  const isMobile = values["sm"] > window.innerWidth;
  const isXl = useMediaQuery(useTheme()?.breakpoints?.up("xl"));

  // State para aguardar
  const [aguardar, setAguardar] = useState(false);

  // State para controlar o filtro
  const [statusSelecionado, setStatusSelecionado] = useState(null);

  // State para controlar o filtro complexo para consulta dos projetos que precisam retornar
  const [filtroComplexo, setFiltroComplexo] = useState({});

  // useEffect para ser chamado todas as vezes que o filtro complexo mudar
  useEffect(() => {
    if (Object.keys(filtroComplexo).length > 0) {
      dispatch(
        // cadastroProjetosGetProjetosFiltroComplexo(filtroComplexo, setAguardar)
        cadastroProjetosGetProjetosFiltroComplexoPaginado(
          filtroComplexo,
          setAguardar,
          1
        )
      );
    } else {
      dispatch(cadastroProjetosGetProjetos("pagina", 1, setAguardar));
    }
  }, [filtroComplexo, dispatch]);

  // Recuperando os dados necessarios
  useEffect(() => {
    dispatch(cadastroProjetosGetDados());
  }, [dispatch]);

  // Registrando acesso a pagina
  useEffect(() => registrarAcesso(window.location.pathname), []);

  // Este efeito e para recuperar os dados graficos
  useEffect(
    () => dispatch(cadastroProjetosGetDadosGraficos(["TAPE", "TAMG"])),
    [dispatch]
  );

  // Funcao para fechar o drawer
  const fecharDrawer = () => dispatch(cadastroProjetosSetDrawer(null));
  const fecharDrawerFilter = () =>
    dispatch(cadastroProjetosFiltroAvancadoFechar());

  // FUncao de callback que vai solicitar o status de acordo com o selecionado
  const fnObterStatusSelecionado = useCallback((ST) => {
    if (ST === null) {
      // Limpando todos os filtros
      setFiltroComplexo({});
    } else {
      setFiltroComplexo((state) => ({ ...state, status: ST }));
    }
    setStatusSelecionado(ST);
  }, []);

  // Veja se o filtro esta sendo aplicado, as quantidades por status devem ser as do filtro
  let quantidadePorStatus = {};
  if (cadastroProjetos?.filtro) {
    quantidadePorStatus = cadastroProjetos.filtro?.qtd_por_status;
  } else if (cadastroProjetos?.qtd_por_status) {
    quantidadePorStatus = cadastroProjetos?.qtd_por_status;
  }
  return (
    <Stack direction="row" sx={{ background: bgPage }}>
      <SideBar barraIntegrada />
      <ToastContainer position="bottom-center" />
      {cadastroProjetos?.drawer ? (
        <DrawerDialog
          fullTela={Boolean(cadastroProjetos?.filtroAvancado)}
          fnGetCorpo={() => (
            <KaizenModal
              drawer={cadastroProjetos.drawer}
              fecharDrawer={fecharDrawer}
            />
          )}
          fecharModal={fecharDrawer}
        />
      ) : cadastroProjetos?.filtroAvancado ? (
        <DrawerDialog
          fullTela={Boolean(cadastroProjetos?.filtroAvancado)}
          fnGetCorpo={() => (
            <KaizenModal
              drawer={{
                tipo: KaizenModal.modal.VER_FILTRO_AVANCADO_APLICADO,
              }}
              fecharDrawer={fecharDrawerFilter}
            />
          )}
          fecharModal={fecharDrawerFilter}
        />
      ) : null}

      <Container maxWidth={isXl ? "xl" : "lg"}>
        <Typography align="center" variant="h6">
          {t("cadastro_projetos.titlePage")}
        </Typography>
        {cadastroProjetos?.dadosForm && (
          <PainelDeStatus
            isMobile={isMobile}
            onClick={fnObterStatusSelecionado}
            statusSelecionado={statusSelecionado}
            qtd_por_status={quantidadePorStatus}
          />
        )}
        <SubPainel
          filtroComplexo={filtroComplexo}
          setFiltroComplexo={setFiltroComplexo}
        />
        {aguardar ? (
          <ListaSkeletonProjetos />
        ) : cadastroProjetos?.projetos ? (
          cadastroProjetos.projetos.length < 1 ? (
            <RetornoEmBranco />
          ) : cadastroProjetos?.filtro ? (
            <ListaProjetos
              projetos={cadastroProjetos?.filtro?.projetos}
              membros={cadastroProjetos?.filtro?.projeto_membros}
              justificativa_x_projeto={
                cadastroProjetos?.filtro?.justificativa_x_projeto
              }
              isMobile={isMobile}
              isFiltro
              statusSelecionado={statusSelecionado}
            />
          ) : (
            <ListaProjetos
              projetos={cadastroProjetos?.projetos}
              membros={cadastroProjetos?.projeto_membros}
              justificativa_x_projeto={
                cadastroProjetos?.justificativa_x_projeto
              }
              isMobile={isMobile}
              isFiltro={
                Object.keys(filtroComplexo).length > 0 ||
                statusSelecionado !== null
              }
              statusSelecionado={statusSelecionado}
            />
          )
        ) : null}
      </Container>
    </Stack>
  );
};

// Componente que exibe um subpainel para os filtros
const SubPainel = memo(({ setFiltroComplexo, filtroComplexo }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Stack direction="row" sx={{ my: 1, overflowX: "auto" }}>
          <ItensSelecionadores
            filtroComplexo={filtroComplexo}
            setFiltroComplexo={setFiltroComplexo}
          />
        </Stack>
      </Grid>
      <Hidden mdDown>
        <Grid item md={4} />
      </Hidden>
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          size="small"
          variant="contained"
          startIcon={<PostAddIcon />}
          onClick={() =>
            dispatch(
              cadastroProjetosSetDrawer({
                tipo: KaizenModal.modal.ADD_FORM,
                valor: true,
              })
            )
          }
        >
          {t("cadastro_projetos.includeProject")}
        </Button>
      </Grid>
    </Grid>
  );
});
// Criando elemento para as chipas do subpainel
const ItensSelecionadores = ({ filtroComplexo, setFiltroComplexo }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const usuario = useSelector((state) => state.usuario);

  // Funcao de callback que controla quais dados a serem filtrados
  const fnControle = (tipoAtivo) => {
    if (filtroComplexo[tipoAtivo]) {
      setFiltroComplexo((state) => {
        delete state[tipoAtivo];
        return { ...state };
      });
      return false;
    }

    setFiltroComplexo((state) => ({
      ...state,
      [tipoAtivo]: usuario?.usuario?.matricula,
    }));
  };

  // Lista para conter os dados que determinaram o chip
  const dadosChip = [
    {
      titulo: t("cadastro_projetos.titleAdvancedFilter"),
      icone: <SearchIcon />,
      texto: t("cadastro_projetos.advancedFilter"),
      onClick: () => {
        dispatch(
          cadastroProjetosSetDrawer({
            tipo: KaizenModal.modal.FILTRO_AVANCADO,
          })
        );
      },

      cor: "default",
    },
    {
      titulo: t("cadastro_projetos.titleILeader"),
      icone: <RecordVoiceOverIcon />,
      texto: t("cadastro_projetos.ILeader"),
      onClick: () => fnControle("lider"),

      cor: filtroComplexo["lider"] ? "primary" : "default",
    },
    {
      titulo: t("cadastro_projetos.titleIMember"),
      icone: <GroupsIcon />,
      texto: t("cadastro_projetos.IMember"),
      onClick: () => fnControle("membro"),

      cor: filtroComplexo["membro"] ? "primary" : "default",
    },
    {
      titulo: t("cadastro_projetos.titleMyDeport"),
      icone: <AccountTree />,
      texto: t("cadastro_projetos.myDeport"),
      onClick: () => fnControle("departamento_matricula"),

      cor: filtroComplexo["departamento_matricula"] ? "primary" : "default",
    },
  ];

  return dadosChip.map((ele, idx) => (
    <Chip
      key={idx}
      //size='small'
      icon={ele.icone}
      title={ele.titulo}
      sx={{ mx: 0.5 }}
      label={ele.texto}
      color={ele.cor}
      onClick={ele.onClick}
    />
  ));
};

export default CadastroProjetos;
