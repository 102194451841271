import {LIMPAR_TR_SELECIONADO, SET_TR_SELECIONADO} from '../actions/';


export default function trSelecionadoReducer(state = null, action){
    switch (action.type) {
        case SET_TR_SELECIONADO: // Se for o mesmo tr desative-o
            return action.id === state ? null : action.id;
        case LIMPAR_TR_SELECIONADO: // Este desativa totalmente o tr
            return null;
        default:
            return state;
    }
}