import { CircularProgress, Container, Stack } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { AnimacaoSemDados, H6 } from "../../../components";
import { DrawerDialog } from "../../../extra-components";
import BackgroundRouter from "../../background-router";
//
import {
  oportunidadesGetDados,
  oportunidadesLimpar,
  oportunidadesSetModal,
  oportunidadesFecharModal,
} from "./oportunidades_actions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAguardar,
  selectDados,
  selectModal,
} from "./oportunidades-seletores";
import OportunidadesModal from "./oportunidades-modal";
import QuadroOportunidade from "./oportunidades-quadro-oportunidades";
import { useTranslation } from "react-i18next";

function Oportunidades() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(oportunidadesGetDados());
    return () => {
      dispatch(oportunidadesLimpar());
    };
  }, [dispatch]);
  const data = useSelector(selectDados);
  const wait = useSelector(selectAguardar);
  const modal = useSelector(selectModal);
  //
  const fnFecharModal = useCallback(() => {
    dispatch(oportunidadesFecharModal());
  }, [dispatch]);

  return (
    <BackgroundRouter>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => (
            <OportunidadesModal modal={modal} fecharModal={fnFecharModal} />
          )}
          fecharModal={fnFecharModal}
        />
      )}

      <Container maxWidth={false}>
        <H6>{t("oportunidades.titlePage")}</H6>
        {wait ? (
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : !data || data.length === 0 ? (
          <AnimacaoSemDados titulo={t("oportunidades.titleNoData")} />
        ) : (
          <Corpo oportunidades={data} />
        )}
      </Container>
    </BackgroundRouter>
  );
}
//
const Corpo = ({ oportunidades }) => {
  const dispatch = useDispatch();
  const fnCallback = useCallback(
    (elemento) => {
      dispatch(
        oportunidadesSetModal({
          tipo: OportunidadesModal.modal.VER_DETALHES_VAGA,
          dados: elemento,
        })
      );
    },
    [dispatch]
  );
  return (
    <Stack direction={{ sm: "column", md: "row" }}>
      {oportunidades?.map((ele, idx) => (
        <QuadroOportunidade
          {...ele}
          key={idx}
          fnVerVaga={() => fnCallback(ele)}
        />
      ))}
    </Stack>
  );
};

Oportunidades.rota = "/oportunidades";

export default Oportunidades;
