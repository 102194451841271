import { Stack } from "@mui/material";
import { Caption, Icone } from "../../../components";
import { useTranslation } from "react-i18next";

//
const StatusVaga = ({ isPreenchida }) => {
  const { t } = useTranslation();

  return (
    <Stack
      title={t("oportunidades.titleSituationActualy")}
      direction="row"
      spacing={1}
      alignItems="flex-end"
    >
      <Icone icone={isPreenchida ? "Block" : "Done"} />
      <Caption>
        {isPreenchida ? t("oportunidades.isOk") : t("oportunidades.isOpen")}
      </Caption>
    </Stack>
  );
};

export default StatusVaga;
