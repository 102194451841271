import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "react-use";
import * as yup from "yup";
import { oportunidadesAlterarFase } from "./oportunidades_actions";
import { Container, Stack } from "@mui/material";
import { Caption, EntradaForm, H6 } from "../../../components";
import { selectFases } from "./oportunidades-seletores";
import { useTranslation } from "react-i18next";

// Formulario para alteração da fase
const FormAlteracaoFase = ({ id_vaga }) => {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const fases = useSelector(selectFases);

  const schema = [
    {
      name: "para_fase",
      type: "select",
      itens: fases,
      autoFormat: true,
      label: t("oportunidades.labelToPhase"),
      icon: "SwapHoriz",
    },
    {
      name: "observacao",
      type: "textarea",
      placeholder: t("oportunidades.placeholderObservation"),
      label: t("oportunidades.labelObservation"),
      counter: true,
      extraProps: {
        multiline: true,
        minRows: 2,
      },
    },
    {
      name: "arquivo",
      type: "file",
      placeholder: t("oportunidades.placeholderFile"),
      label: t("oportunidades.labelFile"),
    },
  ];
  //
  const schemaMessageError = {
    observacao: t("oportunidades.errorObservation"),
    para_fase: t("oportunidades.errorToPhase"),
  };
  //
  const schemaValidador = yup.object().shape({
    observacao: yup.string().min(3).required(),
    para_fase: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
  });
  //
  const onSubmit = useCallback(
    (val) => {
      const formData = new FormData();
      if (val.arquivo && val.arquivo.length > 0) {
        [...val.arquivo].forEach((ele) => {
          formData.append("arquivo", ele);
        });
      }
      //
      formData.append(
        "dados",
        JSON.stringify({
          id_vaga,
          observacao: val.observacao,
          para_fase: parseInt(val.para_fase.value),
        })
      );

      dispatch(oportunidadesAlterarFase(formData, setWait));
    },
    [dispatch, id_vaga, setWait]
  );

  return (
    <Container maxWidth="sm">
      <Stack>
        <H6>{t("oportunidades.titleAlterPhase")}</H6>
        <Caption>{t("oportunidades.subtitleNewPhase")}</Caption>
        <EntradaForm
          schema={schema}
          schemaMessageError={schemaMessageError}
          schemaValidator={schemaValidador}
          onSubmit={onSubmit}
          wait={wait}
        />
      </Stack>
    </Container>
  );
};

export default FormAlteracaoFase;
