import React, { useEffect } from "react";
import { subMonths, addMonths, parseISO, format } from "date-fns";
import { Grid, Button, CircularProgress } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import Select from "../components/select-old";
// Gerar a estrutura de periodos
let periodoAtual;

const periodos = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((ele) => {
  // Pega o primeiro dia
  // const mes = ele.toString().padStart(2, '0');
  //const periodo1 = format(parseISO(`2021-${mes}-21`), 'yyyyMMdd');
  const periodo1 = format(subMonths(new Date(), ele), "yyyyMM21");
  const periodo2 = format(addMonths(parseISO(periodo1), 1), "yyyyMM20");
  // Verifica se a data atual esta no range
  const filtro = format(new Date(), "yyyyMMdd");

  if (periodo1 <= filtro && periodo2 >= filtro)
    periodoAtual = `${periodo1}-${periodo2}`;

  return [
    `${periodo1}-${periodo2}`,
    `${format(parseISO(periodo1), "dd/MM/yyyy")} - ${format(
      parseISO(periodo2),
      "dd/MM/yyyy"
    )}`,
  ];
});

const FormPeriodoPonto = ({ isGestor, idioma, onClick, aguardar }) => {
  //const { aguardar } = useSelector((state) => state);
  // Obtendo o idioma

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  //const dispatch = useDispatch();
  // Utilizar useEffect para recuperar os registros assim que o App é aberto
  useEffect(() => {
    const [de, ate] = periodoAtual.split("-");
    const obj = {
      de: format(parseISO(de), "yyyy-MM-dd"),
      ate: format(parseISO(ate), "yyyy-MM-dd"),
    };

    if (isGestor) obj["matricula"] = isGestor;
    //dispatch(pontoEletronicoGetDados(obj));
    onClick(obj);
  }, [onClick, isGestor]);

  const fn = (val) => {
    const [de, ate] = val["PERIODO"].split("-");
    const obj = {
      de: format(parseISO(de), "yyyy-MM-dd"),
      ate: format(parseISO(ate), "yyyy-MM-dd"),
    };
    // Se for o gestor (ou seja isto veio de minha_equipe devemos enviar a matricula)
    if (isGestor) obj["matricula"] = isGestor;
    //
    onClick(obj);
    //dispatch(pontoEletronicoGetDados(obj));
  };

  return (
    <Grid alignItems="flex-end" container spacing={2}>
      <Grid item md={3} xs={12}>
        <Controller
          control={control}
          rules={{ required: true }}
          defaultValue={periodoAtual}
          name="PERIODO"
          render={({ field }) => (
            <Select
              {...field}
              fullWidth
              options={periodos}
              label={idioma.rotuloCampo}
              error={!!errors.de}
            />
          )}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Button
          startIcon={
            aguardar ? <CircularProgress size={20} /> : <FindReplaceIcon />
          }
          onClick={handleSubmit(fn)}
          disabled={aguardar}
          fullWidth
          variant="contained"
          color="primary"
        >
          {idioma.btnEnviar}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormPeriodoPonto;
