import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import BackgroundRouter from "../../background-router";
import {
  AnimacaoSemDados,
  Body1,
  Body2,
  Caption,
  H6,
  Icone,
  Pesquisando,
} from "../../../components";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Slide,
  Stack,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  rnciNcpClearFilter,
  rnciNcpFecharModal,
  rnciNcpInit,
  rnciNcpPagina,
  rnciNcpPaginaFiltro,
  rnciNcpSetFilter,
  rnciNcpSetModal,
} from "./rnci-ncp-actions";
import { useToggle } from "react-use";
import Utils from "../../../utils/utils";
import RnciNcpForm from "./rnci-ncp-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";

import RnciModal from "./rnci-modal";
import { DrawerDialog } from "../../../extra-components";
import RnciDetalhes from "./rnci-detalhes";
import RnciFormSubida from "./rnci-form-subida";
import RnciFormQualidade from "./rnci-form-qualidade";
import { toast } from "react-toastify";
import axios from "axios";
import {
  selectModal,
  selectRnci,
  selectRnciDadosPagina,
  selectRnciPermissionCancelStatus,
  seletorIsArvore,
  seletorPageFilter,
  seletorRnciGetFilterData,
  seletorRnciOptionsFilter,
} from "./rnci-selectors";
import { obterCorStatus } from "./rnci-panel-info.jsx";
import RnciPanelInfo from "./rnci-panel-info.jsx";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { useTranslation } from "react-i18next";

export const RNCI_ICONES = {
  form1: "Receipt",
  detalhes: "Search",
  subidaLinha: "Build",
  qualidade: "QueryStats",
  downloadPdf: "PictureAsPdf",
  excluirRnci: "Delete",
  retornarAberto: "Undo",
};
//
const obterLegendaSituacao = (situacao, t) => {
  switch (situacao) {
    case "Q":
      return t("rnci_ncp.situacaoQualidade");
    case "S":
      return t("rnci_ncp.situacaoSubida");
    default:
      return t("rnci_ncp.situacaoAberto");
  }
};
//
const downloadPdf = async (id) => {
  return axios.get(`/rnci_ncp_pdf?rnci=${id}`);
};

function RnciNcp() {
  const { t } = useTranslation();
  const backgroundPage = useTheme()?.backgroundPage;
  const modal = useSelector(selectModal);

  const dispatch = useDispatch();
  const [aguardar, setAguardar] = useToggle();
  const dados = useSelector(selectRnciDadosPagina);
  // Recupera os dados da primeira pagina
  useEffect(() => {
    dispatch(rnciNcpInit(setAguardar));
  }, [dispatch, setAguardar]);
  //
  const onCloseModal = useCallback(() => {
    dispatch(rnciNcpFecharModal());
  }, [dispatch]);
  //
  return (
    <BackgroundRouter>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => <RnciModal modal={modal} />}
          fecharModal={onCloseModal}
        />
      )}
      <Container
        maxWidth={false}
        sx={{
          background: backgroundPage,
        }}
      >
        <H6>{t("rnci_ncp.titulo")}</H6>
        {aguardar ? <Pesquisando /> : dados ? <Corpo /> : null}
      </Container>
    </BackgroundRouter>
  );
}
//
const Corpo = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useTheme()?.isMobile;
  const sxFab = {};
  if (isMobile) {
    sxFab.position = "fixed";
    sxFab.right = 16;
    sxFab.bottom = 72;
  } else {
    sxFab.mb = 1;
  }
  //

  return (
    <>
      <Stack direction="row-reverse">
        <Fab
          title={t("rnci_ncp.tituloBotaoAdd")}
          color="success"
          sx={sxFab}
          variant={isMobile ? "circular" : "extended"}
          onClick={() => history.push(RnciNcpForm.rota)}
        >
          <Icone icone="Add" />
          {!isMobile && <Body2>{t("rnci_ncp.rotuloBotaoAdd")}</Body2>}
        </Fab>
      </Stack>
      {isMobile ? <CorpoMobile /> : <CorpoDesktop />}
    </>
  );
};
//
const CorpoMobile = () => {
  const isArvoreQualidade = useSelector(seletorIsArvore);
  const dados = useSelector(selectRnciDadosPagina);
  const dadosFiltro = useSelector(seletorRnciGetFilterData);
  const { t } = useTranslation();

  let dadosVer;
  if (dadosFiltro) {
    dadosVer = dadosFiltro;
  } else {
    dadosVer = dados;
  }

  return (
    <>
      <RnciPanelInfo />
      <Paginacao />

      <List disablePadding>
        {!dadosVer || dadosVer.length === 0 ? (
          <AnimacaoSemDados titulo={t("rnci_ncp.tituloSemDados")} />
        ) : (
          dadosVer?.map((ele) => (
            <Slide direction="left" mountOnEnter in key={ele.id} unmountOnExit>
              <Box>
                <ItemRnci
                  {...ele}
                  dataItem={ele}
                  isArvoreQualidade={isArvoreQualidade}
                />
              </Box>
            </Slide>
          ))
        )}
      </List>
    </>
  );
};
//
const Paginacao = () => {
  const isMobile = useTheme()?.isMobile;
  const dispatch = useDispatch();
  const { total_pagina, pagina_atual } = useSelector(selectRnci);
  const { totalPaginaFiltro, paginaAtualFiltro } =
    useSelector(seletorPageFilter);
  const { t } = useTranslation();

  const onChangePagina = useCallback(
    (evt, pageID) => {
      // Se estiver usando dados do filtro
      if (totalPaginaFiltro) {
        dispatch(rnciNcpPaginaFiltro(pageID));
      } else {
        dispatch(rnciNcpPagina(pageID, () => {}));
      }
    },
    [dispatch, totalPaginaFiltro]
  );

  let totalPage, pageActualy;
  if (totalPaginaFiltro) {
    totalPage = totalPaginaFiltro;
    pageActualy = paginaAtualFiltro;
  } else {
    totalPage = total_pagina;
    pageActualy = pagina_atual;
  }

  return (
    <Stack>
      <Divider sx={{ mb: 1 }} />
      <Stack direction="row" alignItems="center">
        <Pagination
          size={isMobile ? "small" : "medium"}
          title={t("rnci_ncp.tituloPaginacao")}
          color="primary"
          count={totalPage}
          page={pageActualy}
          onChange={onChangePagina}
        />

        <Box sx={{ flex: isMobile ? 0 : 1 }}>
          <FiltroAvancadoRnci />
        </Box>
      </Stack>
      <Divider sx={{ mt: 1 }} />
    </Stack>
  );
};
//
const FiltroAvancadoRnci = () => {
  const { t } = useTranslation();
  const [filterActive, setFilterActive] = useToggle();
  const dispatch = useDispatch();
  const filtros = useSelector(seletorRnciOptionsFilter);
  let schema = useMemo(() => {
    if (filtros) {
      return [
        {
          name: "status",
          rotulo: t("rnci_ncp.filtroNomeStatus"),
          label: t("rnci_ncp.filtroNomeStatus"),
          icone: "SwapVert",
          type: "select",
          itens: filtros.status || [],
          autoFormat: true,
          isMulti: true,
          erro: t("rnci_ncp.filtroNomeStatusErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "matricula",
          rotulo: t("rnci_ncp.filtroNomeMatricula"),
          label: t("rnci_ncp.filtroNomeMatricula"),
          icone: "Person",
          type: "select",
          itens: filtros.matriculas || [],
          autoFormat: true,
          isMulti: true,
          erro: t("rnci_ncp.filtroNomeMatriculaErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "area",
          rotulo: t("rnci_ncp.filtroNomeArea"),
          label: t("rnci_ncp.filtroNomeArea"),
          icone: "PrecisionManufacturing",
          type: "select",
          itens: filtros.area || [],
          autoFormat: true,
          isMulti: true,
          erro: t("rnci_ncp.filtroNomeAreaErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "turno",
          rotulo: t("rnci_ncp.filtroNomeTurno"),
          label: t("rnci_ncp.filtroNomeTurno"),
          icone: "WatchLater",
          type: "select",
          itens: filtros.turnos || [],
          autoFormat: true,
          isMulti: true,
          erro: t("rnci_ncp.filtroNomeTurnoErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "rnci",
          rotulo: t("rnci_ncp.filtroNomeRnci"),
          label: t("rnci_ncp.filtroNomeRnci"),
          icone: "VpnKey",
          type: "select",
          itens: filtros.codigo_rnci || [],
          autoFormat: true,
          isMulti: true,
          erro: t("rnci_ncp.filtroNomeRnciErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "operacao",
          rotulo: t("rnci_ncp.filtroNomeOperacao"),
          label: t("rnci_ncp.filtroNomeOperacao"),
          icone: "Edit",
          type: "select",
          itens: filtros.operacao || [],
          autoFormat: true,
          isMulti: true,
          erro: t("rnci_ncp.filtroNomeOperacaoErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
        {
          name: "pn",
          rotulo: t("rnci_ncp.filtroNomePN"),
          label: t("rnci_ncp.filtroNomePN"),
          icone: "Edit",
          type: "select",
          itens: filtros.pn || [],
          autoFormat: true,
          isMulti: true,
          erro: t("rnci_ncp.filtroNomePNErro"),
          validador: obterValidador(VALIDADOR_TIPO.selectMultiplo),
        },
      ];
    } else {
      return [];
    }
  }, [filtros, t]);

  // Aplica o filtro na interface
  const onFilter = useCallback(
    (val) => {
      const filterFormatted = {};
      _.forEach(_.keys(val), (k) => {
        if (!filterFormatted.hasOwnProperty(k)) {
          filterFormatted[k] = [];
        }
        if (Array.isArray(val[k])) {
          filterFormatted[k] = _.map(val[k], (value) => value.value);
        }
      });

      setFilterActive();

      dispatch(rnciNcpSetFilter(filterFormatted));
    },
    [dispatch, setFilterActive]
  );

  // Chama modal para intencao de filtro
  const onClickFilter = useCallback(() => {
    dispatch(
      rnciNcpSetModal({
        tipo: RnciModal.modal.FILTRO_AVANCADO,
        dados: {
          filters: schema,
          onFilter: onFilter,
        },
      })
    );
  }, [dispatch, schema, onFilter]);
  // Limpeza de filtro
  const onClearFilter = useCallback(() => {
    setFilterActive();
    dispatch(rnciNcpClearFilter());
  }, [setFilterActive, dispatch]);

  return (
    <Chip
      clickable
      onClick={filterActive ? onClearFilter : onClickFilter}
      variant={filterActive ? "filled" : "outlined"}
      color="primary"
      label={t("rnci_ncp.rotuloBtnFiltro")}
      icon={<Icone icone="FilterAlt" />}
    />
  );
};
//
const CorpoDesktop = () => {
  const isArvoreQualidade = useSelector(seletorIsArvore);
  const dados = useSelector(selectRnciDadosPagina);
  const dadosFiltro = useSelector(seletorRnciGetFilterData);
  const { t } = useTranslation();

  let dadosVer;
  if (dadosFiltro) {
    dadosVer = dadosFiltro;
  } else {
    dadosVer = dados;
  }

  return (
    <>
      <Container maxWidth="md">
        <RnciPanelInfo />
      </Container>
      <Paginacao />

      <List disablePadding>
        {!dadosVer || dadosVer.length === 0 ? (
          <AnimacaoSemDados titulo={t("rnci_ncp.tituloSemDados")} />
        ) : (
          dadosVer?.map((ele) => (
            <Slide direction="left" mountOnEnter in key={ele.id} unmountOnExit>
              <Box>
                <ItemRnci
                  {...ele}
                  dataItem={ele}
                  isArvoreQualidade={isArvoreQualidade}
                />
              </Box>
            </Slide>
          ))
        )}
      </List>
    </>
  );
};
//
const ItemRnci = memo(
  ({
    dataItem,
    id,
    nome,
    hora,
    area,
    data,
    anomalia,
    matricula,
    avatar,
    situacao,
    isArvoreQualidade,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    return (
      <Paper elevation={3} sx={{ my: 1 }}>
        <ListItem sx={{ p: 1 }} disableGutters disablePadding>
          <ListItemAvatar>
            <Avatar alt={nome} src={avatar} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Stack direction="row" justifyContent="space-between">
                <Chip
                  size="small"
                  color="primary"
                  label={`${t("rnci_ncp.codigo")}: ${id}`}
                />
                <Chip
                  size="small"
                  color={obterCorStatus(situacao)}
                  label={obterLegendaSituacao(situacao, t)}
                />
              </Stack>
            }
            secondary={
              <Stack sx={{ mt: 1 }} spacing={0.25}>
                <Body1 fontWeight="bold">
                  {matricula} - {nome}
                </Body1>
                <ItemRnciDetalhe label={t("rnci_ncp.area")} text={area} />
                <ItemRnciDetalhe
                  label={t("rnci_ncp.anomalia")}
                  text={anomalia}
                />
                <ItemRnciOpcoes
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  isArvoreQualidade={isArvoreQualidade}
                  data={data}
                  hora={hora}
                  id={id}
                  dataItem={dataItem}
                  situacao={situacao}
                />
              </Stack>
            }
          />
        </ListItem>
      </Paper>
    );
  }
);
//
const ItemRnciOpcoes = ({
  data,
  hora,
  setAnchorEl,
  anchorEl,
  isArvoreQualidade,
  id,
  dataItem,
  situacao,
}) => {
  const isMobile = useTheme()?.isMobile;
  const dispatch = useDispatch();
  const isCancelStatus = useSelector(selectRnciPermissionCancelStatus);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  // Funcao para manipular a exibicao do menu
  const onViewMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl]
  );

  // Funcao para fechar
  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  // Ver o Rnci em detalhes
  const onViewRnci = useCallback(() => {
    history.push(RnciDetalhes.rota, dataItem);
  }, [dataItem, history]);

  // Funcao para alterar o status para subida de linha
  const onAlterToUpOfLine = useCallback(
    (e) => {
      e.stopPropagation();
      setAnchorEl(null);
      if (isMobile) {
        history.push(RnciFormSubida.rota, id);
      } else {
        dispatch(
          rnciNcpSetModal({
            tipo: RnciModal.modal.FORM_SUBIDA_LINHA,
            dados: {
              id,
            },
          })
        );
      }
    },
    [isMobile, history, setAnchorEl, dispatch, id]
  );

  //
  const onAlterToQuality = useCallback(
    (e) => {
      e.stopPropagation();
      setAnchorEl(null);
      if (isMobile) {
        history.push(RnciFormQualidade.rota, id);
      } else {
        dispatch(
          rnciNcpSetModal({
            tipo: RnciModal.modal.FORM_QUALIDADE,
            dados: {
              id,
            },
          })
        );
      }
    },
    [setAnchorEl, history, isMobile, dispatch, id]
  );
  //
  const onDownloadPdf = useCallback(() => {
    setAnchorEl(null);
    //
    const idToast = toast.loading(t("rnci_ncp.wait_generate_pdf"), {
      theme: "dark",
    });
    //
    downloadPdf(id).then((val) => {
      if (val.data.hasOwnProperty("path")) {
        const link = document.createElement("a");
        link.href = val.data.path;
        link.target = "_blank";
        link.click();
        toast.dismiss(idToast);
      }
    });
  }, [id, t, setAnchorEl]);

  // Retorna ao status anterior
  const onGoBackStatus = useCallback(
    (e) => {
      e.stopPropagation();
      setAnchorEl(null);

      dispatch(
        rnciNcpSetModal({
          tipo: RnciModal.modal.RNCI_CANCEL_STATUS,
          dados: {
            id,
            statusActualy: situacao,
          },
        })
      );
    },
    [dispatch, id, situacao, setAnchorEl]
  );

  //
  const options = [
    {
      icone: RNCI_ICONES.detalhes,
      texto: t("rnci_ncp.opcaoDetalhes"),
      onClick: onViewRnci,
    },
  ];

  // verifica qual a opcao que se encontra
  if (situacao === "A") {
    options.push({
      icone: RNCI_ICONES.subidaLinha,
      texto: t("rnci_ncp.opcaoSubida"),
      onClick: onAlterToUpOfLine,
    });
    if (isCancelStatus) {
      options.push({
        icone: RNCI_ICONES.excluirRnci,
        texto: t("rnci_ncp.opcaoExcluirRnci"),
        onClick: onGoBackStatus,
      });
    }
  }

  if (situacao === "S") {
    if (isCancelStatus) {
      options.push({
        icone: RNCI_ICONES.retornarAberto,
        texto: t("rnci_ncp.opcaoRetornarAbertoRnci"),
        onClick: onGoBackStatus,
      });
    }
  }

  //
  if (situacao === "S" && isArvoreQualidade) {
    // E da arvore da qualidade e o status se encontra na subida_de_linha
    options.push({
      icone: RNCI_ICONES.qualidade,
      texto: t("rnci_ncp.opcaoQualidade"),
      onClick: onAlterToQuality,
    });
  }

  if (situacao === "Q") {
    if (isCancelStatus) {
      options.push({
        icone: RNCI_ICONES.subidaLinha,
        texto: t("rnci_ncp.opcaoRetornarSubidaDeLinhaRnci"),
        onClick: onGoBackStatus,
      });
    }
  }
  //
  options.push({
    icone: RNCI_ICONES.downloadPdf,
    texto: t("rnci_ncp.opcaoDownloadPdf"),
    onClick: onDownloadPdf,
  });

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      {options.length > 0 ? (
        <Button
          title={t("rnci_ncp.tituloBotaoVerOpcoes")}
          variant="contained"
          size="small"
          startIcon={<Icone icone="ArrowDropDown" />}
          onClick={onViewMenu}
        >
          {t("rnci_ncp.rotuloBotaoVerOpcoes")}
        </Button>
      ) : (
        <br />
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={onCloseMenu}>
        {options.map((ele, idx) => (
          <MenuItem key={idx} onClick={ele.onClick}>
            <Stack direction="row" spacing={1}>
              <Icone icone={ele.icone} />
              <Body1>{ele.texto}</Body1>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
      <Body2
        align="right"
        fontWeight={600}
        sx={{ color: ({ palette }) => palette.primary.main }}
      >
        {t("rnci_ncp.data")} {Utils.converterDataHora(`${data} ${hora}`)}
      </Body2>
    </Stack>
  );
};
//
const ItemRnciDetalhe = ({ label, text }) => {
  const isMobile = useTheme()?.isMobile;

  return isMobile ? (
    <Caption align="left">
      {label} {text}
    </Caption>
  ) : (
    <Body2>
      {label} {text}
    </Body2>
  );
};

RnciNcp.rota = "/rnci_ncp";

export default RnciNcp;
