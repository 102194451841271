import { CONTROLE_MENU_TOGGLE } from '../actions';


export default function controleMenuReducer(state = true, action){
    switch(action.type){
        case CONTROLE_MENU_TOGGLE:
            return !state;
        default:
            return state;
    }
}