import React from 'react'
import { Container } from '@mui/material';
import BackgroundRouter from '../background-router';

export default function GestaoProjetos() {
  const _html = '<iframe title="Gestão de Projetos 2.0 - Visão Geral" style="width: 100%; height: calc(100vh - 80px)" src="https://app.powerbi.com/view?r=eyJrIjoiYWM5Zjk4M2EtOTRhZS00NjM2LWI0MTYtZmQ0N2YzY2QxMzhjIiwidCI6Ijk4NmIwMzBlLWJmMzEtNDdlNy1hZTk0LWYyNzQ4MTA1ZTU0NiJ9" frameborder="0" allowFullScreen="true"></iframe>';
  return (
    <BackgroundRouter>
      <Container sx={{ mt: 1 }} maxWidth={false}>
        <div dangerouslySetInnerHTML={{ __html: _html }} />
      </Container>

    </BackgroundRouter>
  )
}
