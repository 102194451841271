import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../../hooks";
import _ from "lodash";
import * as yup from "yup";
import { useToggle } from "react-use";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Grow,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import {
  Body1,
  Body2,
  Caption,
  EntradaForm,
  H5,
  H6,
  Icone,
  Logo,
} from "../../../components";
import Utils, { ToastErro } from "../../../utils/utils";
import axios from "axios";
import { PainelSelecaoAvatar } from ".";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

const QuestionarioComportamental = ({ selecionado, setSelecionado }) => {
  const { t } = useTranslation();
  const { data, error, wait, setFetch } = useFetch(
    "/minha_equipe_questionario",
    "GET"
  );

  useEffect(() => {
    if (selecionado) {
      setFetch({
        matricula: selecionado,
      });
    }
  }, [setFetch, selecionado]);

  //
  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);
  //
  return (
    <Container
      sx={{ maxHeight: "calc(100vh - 72px)", overflow: "auto" }}
      maxWidth={false}
    >
      <H6>{t("minha_equipe.myEquipQuestionTitle")}</H6>

      <PainelSelecaoAvatar
        selecionado={selecionado}
        setSelecionado={setSelecionado}
      />
      <br />
      <Stack
        sx={{
          maxHeight: "calc(82vh - 72px)",
          overflow: "auto",
        }}
      >
        {wait ? (
          <CircularProgress />
        ) : data?.perguntas ? (
          <Grow in unmountOnExit key={data.id_questionario}>
            <Box>
              <Questionario {...data} onLoadNewQuestion={setFetch} />
            </Box>
          </Grow>
        ) : data?.id_avaliacao ? (
          <Grow in unmountOnExit key={data.id_avaliacao}>
            <Box>
              <QuestionarioObservacao
                matricula={selecionado}
                id_avaliacao={data.id_avaliacao}
                onLoadNewQuestion={setFetch}
              />
            </Box>
          </Grow>
        ) : data?.avaliacoes_respondidas ? (
          <ListaAvaliacoesRespondidas
            matricula={selecionado}
            listaAvaliacoes={data.avaliacoes_respondidas}
          />
        ) : (
          <Body1 align="center">{t("minha_equipe.myEquipQuestionView")}</Body1>
        )}
      </Stack>
    </Container>
  );
};
//
const Questionario = ({
  id_questionario,
  matricula,
  perguntas,
  planta,
  titulo,
  onLoadNewQuestion,
}) => {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  //
  const schema = [],
    schemaValidator = {},
    schemaError = {};
  _.forEach(perguntas, (perg) => {
    const nameQuestion = `${perg.id_pergunta}`;

    schemaValidator[nameQuestion] = yup.string().min(1).required();
    schemaError[nameQuestion] = t("minha_equipe.myEquipQuestionErrorName");

    schema.push({
      name: nameQuestion,
      type: perg.opcoes.length === 0 ? "text" : "radio",
      itens:
        perg.opcoes.length > 0 &&
        _.map(perg.opcoes, (opt) => [opt.id_opcao, opt.descricao]),
      titulo: perg.titulo,
      descricao: perg.descricao,
      descricaoTipografia: {
        component: "p",
        align: "left",
        sx: { whiteSpace: "pre-line" },
      },
      wrapperPaperProps: {
        elevation: 4,
        sx: {
          p: 1,
        },
      },
    });
  });

  //
  const onSubmit = useCallback(
    async (val) => {
      const responses = _.map(_.keys(val), (k) => ({
        resposta: val[k],
        id_pergunta: parseInt(k),
      }));

      const obj = {
        respostas: responses,
        id_questionario,
        matricula,
      };

      setWait();

      try {
        await axios.post("/minha_equipe_questionario", obj);
        // Questionario respondido, invoke o proximo questionario
        onLoadNewQuestion({ matricula });
      } catch (error) {
        if (error.message) {
          ToastErro(error.message);
        }
      } finally {
        setWait();
      }
    },
    [id_questionario, matricula, setWait, onLoadNewQuestion]
  );

  return (
    <Stack>
      <H6>{titulo}</H6>
      <EntradaForm
        schemaMessageError={schemaError}
        schemaValidator={yup.object().shape(schemaValidator)}
        schema={schema}
        onSubmit={onSubmit}
        wait={wait}
      />
    </Stack>
  );
};
// Gerar as perguntas do questionario
const QuestionarioObservacao = ({
  onLoadNewQuestion,
  matricula,
  id_avaliacao,
}) => {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const schema = [
    {
      type: "textarea",
      minRows: 3,
      name: "observacao_avaliador",
      placeholder: t("minha_equipe.myEquipQuestionObsEvaluatorPlaceholder"),
      label: t("minha_equipe.myEquipQuestionObsEvaluatorLabel"),
      multiline: true,
      defaultValue: "",
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "textarea",
      minRows: 3,
      name: "observacao_avaliado",
      placeholder: t("minha_equipe.myEquipQuestionObsEvaluatedPlaceholder"),
      label: t("minha_equipe.myEquipQuestionObsEvaluatedLabel"),
      multiline: true,
      defaultValue: "",
      grid: {
        xs: 12,
        md: 6,
      },
    },
  ];
  const schemaValidator = yup.object().shape({
    observacao_avaliador: yup.string().min(1).required(),
    observacao_avaliado: yup.string().min(1).required(),
  });
  //
  const schemaMessageError = {
    observacao_avaliador: t("minha_equipe.myEquipQuestionEvaluatorError"),
    observacao_avaliado: t("minha_equipe.myEquipQuestionEvaluatedError"),
  };
  //
  const onSubmit = useCallback(
    async (val) => {
      const obj = {
        id_avaliacao,
        respostas: {
          avaliador: {
            observacao: val.observacao_avaliador,
          },
          avaliado: {
            matricula,
            observacao: val.observacao_avaliado,
          },
        },
      };
      setWait();
      // Enviar os dados para atualização do registro
      try {
        await axios.post("/minha_equipe_questionario_observacao", obj);
        // Avaliacao OK volte a invucar novamente o relatorio que recupera os questionários.
        onLoadNewQuestion({ matricula });
      } catch (error) {
        if (error?.message) {
          ToastErro(error?.message);
        } else {
          ToastErro(t("minha_equipe.myEquipQuestionErrorUnknown"));
        }
      } finally {
        setWait();
      }
    },
    [setWait, t, onLoadNewQuestion, id_avaliacao, matricula]
  );

  return (
    <Container maxWidth="md" sx={{ p: 1 }}>
      <Paper elevation={3} sx={{ p: 1 }}>
        <H6>{t("minha_equipe.myEquipQuestionTitleObsQuestion")}</H6>
        <EntradaForm
          schema={schema}
          schemaMessageError={schemaMessageError}
          schemaValidator={schemaValidator}
          onSubmit={onSubmit}
          wait={wait}
        />
      </Paper>
    </Container>
  );
};
//
const ListaAvaliacoesRespondidas = ({ matricula, listaAvaliacoes }) => {
  const { t } = useTranslation();
  const [avaliacao, setAvaliacao] = useState(null);
  const { data, error, setFetch } = useFetch(
    "/minha_equipe_questionario",
    "GET"
  );
  //
  useEffect(() => {
    if (avaliacao) {
      const obj = {
        matricula,
        ver_respostas: avaliacao,
      };
      setFetch(obj);
    }
  }, [matricula, avaliacao, setFetch]);
  //
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  //
  const avaliacoesLista = _.map(listaAvaliacoes, (arr) => ({
    id: arr.id,
    rotulo: `${_.capitalize(
      format(parseISO(arr.data_inclusao), "MMMM - yyyy", {
        locale: ptBR,
      })
    )}`,
  }));
  //

  return (
    <Container maxWidth={false}>
      <H6>{t("minha_equipe.myEquipQuestionEvaluationResponseTitle")}</H6>
      <Grow in unmountOnExit>
        <Paper elevation={2} sx={{ my: 1, p: 1 }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ width: "100%", overflow: "auto" }}
          >
            {avaliacoesLista.map(({ id, rotulo }) => (
              <Chip
                clickable
                onClick={() => setAvaliacao(id)}
                key={id}
                label={rotulo}
                color="primary"
                variant={id === avaliacao ? "filled" : "outlined"}
              />
            ))}
          </Stack>
        </Paper>
      </Grow>
      <Grow in={Boolean(data)} unmountOnExit>
        <Box>
          {data && (
            <VerAvaliacaoRespondida
              {...data}
              verBotaoDownload
              idAvaliacao={avaliacao}
            />
          )}
        </Box>
      </Grow>
    </Container>
  );
};
//
const BotaoBaixarPdf = ({ matricula, avaliacao }) => {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();

  const fnBaixarQuestionario = useCallback(async () => {
    setWait();
    try {
      const resp = await axios.get(
        `/minha_equipe_questionario_pdf?matricula=${matricula}&ver_respostas=${avaliacao}`
      );
      const a = document.createElement("a");
      a.href = resp.data.url;
      a.target = "_blank";
      a.setAttribute(
        "download",
        resp.data.url.substring(resp.data.url.lastIndexOf("/") + 1)
      );
      a.click();
    } catch (error) {
      if (error.message) {
        ToastErro(error.message);
      } else {
        ToastErro(error);
      }
    } finally {
      setWait();
    }
  }, [matricula, avaliacao, setWait]);

  return (
    <Button
      title={t("minha_equipe.myEquipQuestionDownloadPdfTitle")}
      variant="contained"
      size="small"
      disabled={wait}
      onClick={fnBaixarQuestionario}
      startIcon={
        wait ? <CircularProgress size={20} /> : <Icone icone="Download" />
      }
    >
      {t("minha_equipe.myEquipQuestionDownloadPdfBtnLabel")}
    </Button>
  );
};
// Cabecalho da avaliacao respondida
const CabeAvaliacaoRespondida = ({
  avaliado,
  avaliador,
  data_avaliacao,
  verBotaoDownload,
  idAvaliacao,
}) => {
  const { t } = useTranslation();
  const valores = [
    {
      titulo: t("minha_equipe.myEquipQuestionHeaderResponseName"),
      rotulo: avaliado.nome,
    },
    {
      titulo: t("minha_equipe.myEquipQuestionHeaderResponseMat"),
      rotulo: avaliado.matricula,
    },
    {
      titulo: t("minha_equipe.myEquipQuestionHeaderResponseFunction"),
      rotulo: avaliado.cargo,
    },
    {
      titulo: t("minha_equipe.myEquipQuestionHeaderResponseEvaluator"),
      rotulo: avaliador.nome,
    },
    {
      titulo: t("minha_equipe.myEquipQuestionHeaderResponseDateOfEvaluation"),
      rotulo: Utils.converterDataHora(data_avaliacao),
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <Logo />
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <H5>{t("minha_equipe.myEquipQuestionHeaderResponsePageTitle")}</H5>
          {verBotaoDownload && (
            <BotaoBaixarPdf
              matricula={avaliado.matricula}
              avaliacao={idAvaliacao}
            />
          )}
        </Stack>
      </Grid>
      {valores.map(({ titulo, rotulo }) => (
        <Grid item xs={12} md={4} key={titulo}>
          <ListItem>
            {titulo === "Nome" && (
              <ListItemAvatar sx={{ mr: 1 }}>
                <Avatar
                  sx={{ width: 64, height: 64 }}
                  alt={avaliado.nome}
                  src={avaliado.avatar}
                />
              </ListItemAvatar>
            )}
            <ListItemText primary={titulo} secondary={rotulo} />
          </ListItem>
        </Grid>
      ))}
    </Grid>
  );
};
//
export const VerAvaliacaoRespondida = ({
  avaliado,
  observacoes,
  questionarios,
  data_avaliacao,
  avaliador,
  verBotaoDownload,
  idAvaliacao,
}) => {
  const { t } = useTranslation();
  return (
    <Paper elevation={2} sx={{ p: 1 }}>
      <CabeAvaliacaoRespondida
        avaliado={avaliado}
        avaliador={avaliador}
        data_avaliacao={data_avaliacao}
        verBotaoDownload={verBotaoDownload}
        idAvaliacao={idAvaliacao}
      />
      <Divider sx={{ mb: 2 }} />
      <Stack>
        {questionarios.map(({ id_questionario, titulo, perguntas }) => {
          return (
            <div key={id_questionario}>
              <H5>{titulo}</H5>
              {_.map(perguntas, (perg, idx) => (
                <AvaliacaoRespostaPergunta {...perg} key={idx} />
              ))}
            </div>
          );
        })}
        <H5 sx={{ my: 1 }}>
          {t("minha_equipe.myEquipQuestionViewEvaluationResponsePageTitle")}
        </H5>
        <AvaliacaoRepostaObservacoes {...observacoes} />
        <br />
        <Stack direction="row" spacing={1}>
          {questionarios.map(({ id_questionario, titulo, perguntas }, idx) => (
            <AvaliacaoGraficoRadar
              key={id_questionario}
              respostas={perguntas}
              nomeAvaliado={avaliado.nome}
              titulo={titulo}
              indice={idx + 1}
            />
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
};
//
const AvaliacaoRespostaPergunta = ({ descricao, resposta, titulo }) => {
  const { t } = useTranslation();
  //
  const backgroundPaper = useTheme()?.backgroundPage;

  return (
    <Stack spacing={1}>
      <Paper elevation={0} sx={{ p: 0.5, background: backgroundPaper }}>
        <H6 fontWeight="bold" align="left">
          {titulo}
        </H6>
      </Paper>

      <Body2 align="left">{descricao}</Body2>
      <Body1 fontWeight="bold" align="left">
        {t("minha_equipe.myEquipQuestionEvaluationResponseAskBody")}: {resposta}
      </Body1>
      <Divider sx={{ mb: 1 }} />
    </Stack>
  );
};
//
const AvaliacaoRepostaObservacoes = ({ avaliado, avaliador }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={1} direction="row" justifyContent="space-around">
      <AvaliacaoRespostaObsDetalhes
        {...avaliado}
        titulo={t(
          "minha_equipe.myEquipQuestionEvaluationResponseObsEvaluatedTitle"
        )}
      />
      <AvaliacaoRespostaObsDetalhes
        {...avaliador}
        titulo={t(
          "minha_equipe.myEquipQuestionEvaluationResponseObsEvaluatorTitle"
        )}
      />
    </Stack>
  );
};
const AvaliacaoRespostaObsDetalhes = ({
  titulo,
  data_inclusao,
  observacao,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{ border: "1px solid #ccc", p: 1, flex: 1, minHeight: "72px" }}
    >
      <Stack>
        <H6>{titulo}</H6>
        <Body1>{observacao}</Body1>
        <Divider sx={{ my: 1 }} />
        <Caption>{format(parseISO(data_inclusao), "dd/MM/yy HH:mm")}</Caption>
      </Stack>
    </Paper>
  );
};
//
const AvaliacaoGraficoRadar = ({ indice, titulo, respostas, nomeAvaliado }) => {
  const data = respostas.map(({ peso }, idx) => ({
    dataName: `${idx + 1}`,
    dataValue: peso,
    fullMark: 5,
  }));

  return (
    <Paper sx={{ flex: 1, p: 1, height: "360px" }}>
      <H6>
        {indice} - {titulo}
      </H6>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="dataName" />
          <PolarRadiusAxis domain={[0, 5]} />
          <Radar
            name={nomeAvaliado}
            dataKey="dataValue"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
        </RadarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default QuestionarioComportamental;
