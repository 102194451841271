import {
  Box,
  Chip,
  Container,
  Grow,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectManuStatus, selectModal } from "./helpdesk-seletores";
import {
  helpdeskFecharModal,
  helpdeskGetStatus,
  helpdeskSetModal,
} from "./helpdesk-actions";
import _ from "lodash";
import BackgroundRouter from "../../background-router";
import { DrawerDialog } from "../../../extra-components";
import HelpdeskModal, { MODAL } from "./helpdesk-modal";
import HelpdeskGoBack from "./helpdesk-goback";
import { Body1, H6, Icone } from "../../../components";
import RowPaginateTemplate from "../../../templates/row_paginate_template";
import { useTranslation } from "react-i18next";

const getSituationName = (situation, t) => {
  switch (situation) {
    case "A":
      return t("helpdesk.labelActive");
    case "B":
    default:
      return t("helpdesk.labelInactive");
  }
};

const getIconSituation = (situation) => {
  switch (situation) {
    case "A":
      return "Check";
    case "B":
    default:
      return "Block";
  }
};

const getColorSituation = (situation) => {
  switch (situation) {
    case "A":
      return "success";
    case "B":
    default:
      return "error";
  }
};

export const getAuthorized = (auth, t) => {
  switch (auth) {
    case "A":
      return t("helpdesk.labelAgent");
    case "S":
    default:
      return t("helpdesk.labelRequester");
  }
};

function HelpdeskStatus() {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const status = useSelector(selectManuStatus);
  useEffect(() => {
    if (!status) {
      dispatch(helpdeskGetStatus());
    }
  }, [dispatch, status]);

  const mapKeyToTitle = {
    [t("helpdesk.headerName")]: "descricao",
    [t("helpdesk.headerAuthorized")]: "autorizado_interagir",
    [t("helpdesk.headerColor")]: "cor",
    [t("helpdesk.headerActive")]: "situacao",
    [t("helpdesk.headerDelete")]: "id",
    [t("helpdesk.headerMove")]: "id",
  };

  const header = [
    t("helpdesk.headerName"),
    t("helpdesk.headerAuthorized"),
    t("helpdesk.headerColor"),
    t("helpdesk.headerActive"),
    t("helpdesk.headerDelete"),
    t("helpdesk.headerMove"),
  ];
  let rows = [];

  if (status) {
    rows = _.map(status, (val) => {
      const row = {
        onClick: () => {
          dispatch(
            helpdeskSetModal({
              tipo: MODAL.ADD_UPD_STATUS,
              dados: val,
            })
          );
        },
      };
      _.forEach(header, (k) => {
        // Quando codigo aplica o chip no campo
        if (k === t("helpdesk.headerActive")) {
          row[k] = (
            <Chip
              size="small"
              icon={<Icone icone={getIconSituation(val[mapKeyToTitle[k]])} />}
              color={getColorSituation(val[mapKeyToTitle[k]])}
              label={getSituationName(val[mapKeyToTitle[k]], t)}
            />
          );
        } else if (k === t("helpdesk.headerMove")) {
          row[k] = (
            <IconButton
              color="info"
              title={t("helpdesk.titleBtnMove")}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  helpdeskSetModal({
                    tipo: MODAL.MOVE_STATUS,
                    dados: val,
                  })
                );
              }}
            >
              <Icone icone="SwapHoriz" />
            </IconButton>
          );
        } else if (k === t("helpdesk.headerDelete")) {
          row[k] = (
            <IconButton
              title={t("helpdesk.titleBtnDelete")}
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  helpdeskSetModal({
                    tipo: MODAL.DEL_STATUS,
                    dados: val.id,
                  })
                );
              }}
            >
              <Icone icone="Delete" />
            </IconButton>
          );
        } else if (k === t("helpdesk.headerAuthorized")) {
          row[k] = getAuthorized(val[mapKeyToTitle[k]], t);
        } else if (k === t("helpdesk.headerColor")) {
          row[k] = (
            <Chip
              label={val[mapKeyToTitle[t("helpdesk.headerName")]]}
              sx={{ color: "white", background: val[mapKeyToTitle[k]] }}
            />
          );
        } else {
          row[k] = val[mapKeyToTitle[k]];
        }
      });

      return row;
    });
  }
  //
  const onAddStatus = useCallback(() => {
    dispatch(
      helpdeskSetModal({
        tipo: MODAL.ADD_UPD_STATUS,
      })
    );
  }, [dispatch]);
  //
  const fecharModal = useCallback(
    () => dispatch(helpdeskFecharModal()),
    [dispatch]
  );

  //
  return (
    <BackgroundRouter>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => <HelpdeskModal modal={modal} />}
          fecharModal={fecharModal}
        />
      )}
      <Grow in unmountOnExit>
        <Stack sx={{ width: "100vw" }} direction="row">
          {!isMobile && (
            <Box sx={{ mt: 2, flex: 0 }}>
              <HelpdeskGoBack />
            </Box>
          )}
          <Box sx={{ flex: 1 }}>
            <Container maxWidth="md">
              <H6>{t("helpdesk.title")}</H6>
              <Body1 align="center">{t("helpdesk.subtitle")}</Body1>
              <RowPaginateTemplate
                pageCurrent={1}
                totalPages={1}
                onSetPage={() => {}}
                titlePage=""
                onClickAdd={onAddStatus}
                headerAboveTable={null}
                titleButtonAdd={t("helpdesk.titleBtnAdd")}
                header={header}
                backgroundPage="transparent"
                sxHeader={{
                  background: ({ palette }) =>
                    palette.mode === "dark"
                      ? palette.background.paper
                      : palette.primary.main,
                  color: ({ palette }) =>
                    palette.mode === "dark"
                      ? palette.background.paper.contrastText
                      : palette.primary.contrastText,
                }}
                rows={rows}
              />
            </Container>
          </Box>
        </Stack>
      </Grow>
    </BackgroundRouter>
  );
}

HelpdeskStatus.rota = "/helpdesk_status_page";

export default HelpdeskStatus;
