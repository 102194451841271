import React, {useEffect } from 'react';
import Modal from '../../components/modal';
import Alerta from '../../components/alerta';
import Icone from '../../components/icone-old';
import Tabela from '../../components/tabela';
import Select from '../../components/select';

import { useSelector, useDispatch } from 'react-redux';
import { avaliacaoGETDados, avaliacaoPOSTPut, avaliacaoDELETE, 
    avaliacaoView, avaliacaoAddOpcao,
    fecharAlerta, fecharModal, setModal, setTrSelecionado, AVALIACAO_VIEW, avaliacaoPatch
} from '../../redux/actions';
import { AnimatePresence } from 'framer-motion';
import CabeModal from '../../components/cabe-modal';
import { CriadorDeAvaliacao } from '../../extra-components/';
import { Stack, Hidden, Button, TextField, Box, Container, Grid, Typography, useMediaQuery, useTheme, } from '@mui/material';
import { indigo, red, green, yellow } from '@material-ui/core/colors';
import Utils from '../../utils/utils';
import { registrarAcesso } from '../../utils/utils';
import { useForm, Controller } from 'react-hook-form';
import { SideBar } from '../../components';
//

const cabe = [
    'QUESTIONÁRIO',
    'DATA INICIO',
    'DATA TÉRMINO',
    'SITUACAO',
]

const modalTipos = {
    AVALIACAO_ADD: 'AVALIACAO_ADD',
    AVALIACAO_VIEW: AVALIACAO_VIEW,
    AVALIACAO_UPD: 'AVALIACAO_UPD',
    AVALIACAO_ADD_OPCAO: 'AVALIACAO_ADD_OPCAO',
}
// Strings
const STRINGS = {
    btnAdd: {
        icon: 'add',
        title: 'Adicione um questionário',
        bgColor: green[900],
        textColor: '#fff',

    },
    btnDel: {
        icon: 'delete',
        title: 'Remova um questionário',
        bgColor: red[900],
        textColor: '#fff',
    },
    btnView: {
        icon: 'grading',
        title: 'Veja detalhes de um questionário',
        bgColor: yellow[700],
        textColor: '#000'
    },
    btnCopy: {
        icon: 'copy_all',
        title: 'Duplique este questionário',
        bgColor: indigo[700],
        textColor: '#FFF'
    },
    btnEdit: {
        icon: 'edit',
        title: 'Atualize o cabeçalho do questionario',
        bgColor: yellow[700],
        textColor: '#000'
    },
    btnAddOpcao: {
        icon: 'playlist_add',
        title: 'Adicionar mais uma alternativa para pergunta',
        bgColor: green[400],
        textColor: '#000',
    }
}
//
const Avaliacao = (props)=>{
    const dispatch = useDispatch();
    const { avaliacao, modal, alerta } = useSelector(state => state);
    const isXl = useMediaQuery( useTheme()?.breakpoints?.up('xl') );

    useEffect(() => {
        dispatch(avaliacaoGETDados());        
    }, [dispatch]);
    useEffect(()=>{
        // Registrando acesso a pagina
        registrarAcesso(window.location.pathname);

   }, []);

    return (
        <Stack direction='row'>
            <SideBar barraIntegrada />
        
        <Container maxWidth={isXl ? 'xl' : 'lg'}>
            <AnimatePresence>{modal && <RenderModal modal={modal} />} </AnimatePresence>
            {alerta && <Alerta onFechar={()=> dispatch(fecharAlerta()) } 
                visivel={alerta.visivel}>
                    <Typography variant="body2">{alerta.mensagem}</Typography>
                </Alerta>
            }
          <Typography align='center' variant="h5">Manutenção de Avaliacao</Typography>
          {avaliacao && <RenderTabela cabe={cabe} corpo={avaliacao.avaliacao} />}
        </Container>
        </Stack>
    )
}
// Renderizador da tabela
const RenderTabela = ({ cabe, corpo })=>{
    const dispatch = useDispatch();
    const { avaliacao, aguardar, optTabela, trSelecionado } = useSelector(state => state);
    // Marcaro registro em destaque
    const trS = trSelecionado ? {[trSelecionado]: {backgroundColor: yellow[700]}} : null;

    const optTabelaT = {
        ...optTabela,
        trSelecionado: trS,
        baixar: false,
        datas: [1,2],
        pesquisando: aguardar,
        onClick: e=> dispatch(setTrSelecionado(e.target.dataset.row)),
    }
    // Strings para titles dos botoes
    return (
        <Tabela cabe={cabe} corpo={corpo} {...optTabelaT}>
            <AnimatePresence>
                <Icone key={STRINGS.btnAdd.icon} icon={STRINGS.btnAdd.icon} effect='scale' circle 
                    bgColor={STRINGS.btnAdd.bgColor} textColor={STRINGS.btnAdd.textColor} 
                    title={STRINGS.btnAdd.title}  onClick={()=> dispatch( setModal({tipo: modalTipos.AVALIACAO_ADD, dados: avaliacao.opcao }) )}
                />
                <Icone key={STRINGS.btnAddOpcao.icon} icon={STRINGS.btnAddOpcao.icon} effect='scale' circle 
                    bgColor={STRINGS.btnAddOpcao.bgColor} textColor={STRINGS.btnAddOpcao.textColor} 
                    title={STRINGS.btnAddOpcao.title} onClick={()=> dispatch( setModal({tipo: modalTipos.AVALIACAO_ADD_OPCAO}))} 
                />
                {trSelecionado && (
                    <>
                        <Icone key={STRINGS.btnView.icon} icon={STRINGS.btnView.icon} circle effect='scale' 
                            bgColor={STRINGS.btnView.bgColor} textColor={STRINGS.btnView.textColor} 
                            title={STRINGS.btnView.title}
                            onClick={()=> dispatch( avaliacaoView(trSelecionado) )}
                        />
                        <Icone key={STRINGS.btnCopy.icon} icon={STRINGS.btnCopy.icon} circle effect='scale' 
                            bgColor={STRINGS.btnCopy.bgColor} textColor={STRINGS.btnCopy.textColor} 
                            title={STRINGS.btnCopy.title} 
                            onClick={()=> dispatch(avaliacaoPatch({id_avaliacao: trSelecionado}))} 
                        />
                        <Icone key={STRINGS.btnEdit.icon} icon={STRINGS.btnEdit.icon} circle effect='scale' 
                            bgColor={STRINGS.btnEdit.bgColor} textColor={STRINGS.btnEdit.textColor} 
                            title={STRINGS.btnEdit.title} 
                            onClick={()=> dispatch(setModal({tipo: modalTipos.AVALIACAO_UPD }))} 
                        />
                        <Icone key={STRINGS.btnDel.icon} icon={STRINGS.btnDel.icon} circle effect='scale' 
                            bgColor={STRINGS.btnDel.bgColor} textColor={STRINGS.btnDel.textColor} 
                            title={STRINGS.btnDel.title} 
                            onClick={()=> dispatch(avaliacaoDELETE({id_avaliacao: trSelecionado }))}
                        />

                    </>
                )}
            </AnimatePresence>
        </Tabela>
    )
}
// Renderiza o modal
const RenderModal = ({ modal })=>{
    const dispatch = useDispatch();

    const cabe = <CabeModal />;
    let corpo, modalGrande;
    

    switch(modal.tipo){
        case modalTipos.AVALIACAO_ADD:
            corpo = (()=>{
                // Funcao de callback para capturar o questionario
                const fn = (arrQuestionario)=>{
                    // Fazer a separação dos campos para enviar o formulario
                    const obj = {
                        perguntas: [],
                    };
                    
                    arrQuestionario.forEach((ele,idx)=>{
                        // Quer dizer que e o cabecalho do formulario
                        if(idx === 0){
                            Object.keys(ele).forEach(key=>{
                                switch(key){
                                    case 'dataFim':
                                        obj['data_fim'] = ele[key];
                                        break;
                                    case 'dataInicio':
                                        obj['data_inicio'] = ele[key];
                                        break;
                                    case 'situacao':
                                        obj['situacao'] = ele[key];
                                        break;
                                    default:
                                        obj['descricao'] = ele[key]
                                        break;
                                }
                            })
                        } else { // Sao todas as perguntas
                            const objInterno = {
                                opcao: [],
                            }; // Objeto interno para as perguntas

                            Object.keys(ele).forEach(key=>{
                                switch(key){
                                    case 'tipo':
                                        objInterno['tipo'] = parseInt(ele[key]);
                                        break;
                                    case 'descricao':
                                        objInterno['descricao'] = ele[key];
                                        break;
                                    case 'topico':
                                        objInterno['titulo'] = ele[key];
                                        break;
                                    default: // Sao os checkeds
                                        // check_ID : true 
                                        // Podem vir valores núméricos, mas o certo é que seja um booleano
                                        if(ele[key] === true) objInterno['opcao'].push(parseInt( key.replace('check_', '') ) );
                                        break;
                                }
                            });
                            obj.perguntas.push(objInterno);
                        }
                    });
                    // Dados hidratados podemos fazer o post
                    dispatch(avaliacaoPOSTPut(obj));
                }
                return <CriadorDeAvaliacao salvarAvaliacao={fn} opcoesDaPergunta={modal.dados} />;;
            })();
            break;
        case modalTipos.AVALIACAO_VIEW:
            modalGrande = true;
            corpo = <VisualizadorDeQuestionario dados={modal.dados} />;
            break;
        case modalTipos.AVALIACAO_UPD: // Para atualizar o cabecalho de um questionario
            corpo = <FormAlterarQuestionario />
            break;
        case modalTipos.AVALIACAO_ADD_OPCAO: // Para adicionar uma nova opcao
            corpo = <FormAddOpcaoPergunta />;
            break;
        default:
            break;
    }

    return <Modal modalGrande={modalGrande} titulo={cabe} onFechar={()=> dispatch(fecharModal())}>{corpo}</Modal>
}
// Componente usado para ver todos os campos definidos no questionario
const VisualizadorDeQuestionario = (props)=>{
    const { dados } = props;
    const { avaliacao, pergunta } = dados;
    const { nome, data_inicio, data_fim, situacao} = avaliacao;
        
    return (
        <Container>
            <Grid container spacing={1}>
                <Grid xs={12} item>
                    <Typography align='center' variant="h4">{nome}</Typography>
                </Grid>
                <Grid xs={4} item>
                    <Typography color="secondary" variant="body1">Situação: {situacao}</Typography>
                </Grid>
                <Grid xs={4} item>
                    <Typography variant='body1'>Inicio:&nbsp;&nbsp;
                        <Box fontWeight='bold' color='#b00022' component='span'>{Utils.converterData(data_inicio)}</Box> 
                    </Typography>
                </Grid>
                <Grid xs={4} item>
                <Typography variant='body1'>Término:&nbsp;&nbsp;
                    <Box fontWeight='bold' color='#b00022' component='span'>{Utils.converterData(data_fim)}</Box> 
                </Typography>
                </Grid>
            </Grid>
            <hr/>
            {pergunta && pergunta.map((ele,idx)=>(
                <Box m={2}>
                    <Grid key={idx} container spacing={1}>
                        <Grid xs={12} item>
                            <Typography align='center' variant='h6'>{ele.titulo}</Typography>
                        </Grid>
                        <Grid xs={12} item>
                            <Typography variant='subtitle1'>{ele.descricao}</Typography>
                        </Grid>
                        <Box>
                            <Typography variant='subtitle2'>Tipo da pergunta:&nbsp;&nbsp;
                                <Box component='span' fontWeight='bold' color='#b00022' >{ele.tipo === 1 ? 'Escolha Única' : 'Dissertativa'}</Box>
                            </Typography> 
                            
                        </Box>
                        {ele.tipo === 1 && ele.opcoes?.length > 0 && (
                            <Grid item xs={12} >
                                {ele.opcoes.map((item,ix)=>(
                                    <Box key={ix} mt={1} mb={1} color='#b00022'>
                                        <Typography variant='body2'>{item[1]}</Typography>
                                    </Box>
                                    
                                ))}
                            </Grid>
                        )}
                    </Grid>
                    <hr/>
                </Box>
            ))}        
            
        </Container>
    )
}
// Componente que vai montar o formulario para alteracao do questionari
const FormAlterarQuestionario = (props)=>{
    const { trSelecionado, avaliacao } = useSelector(state=> state);
    const dispatch = useDispatch();
    // Procura o registro selecionado
    const [val_descri, dt_inicio, dt_fim, situ] = avaliacao.avaliacao.filter(ele=> ele.id.toString() === trSelecionado)[0].data;

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            descricao: val_descri, data_inicio: dt_inicio, data_fim: dt_fim, situacao: situ,
        }
    });

    const { descricao, data_inicio, data_fim, situacao } = errors;
    // Funcao para enviar os dados para atualização
    const fn = val=>{
        dispatch(avaliacaoPOSTPut({
            id_avaliacao: trSelecionado, ...val,
        }, true));
    }
    const options = [
        ['', ''],
        ['A', 'A'],
        ['B', 'B'],
    ]
    return (
        <Container fullWidth>
        <form onSubmit={handleSubmit(fn)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller 
                        name='descricao'
                        control={control}
                        rules={{required: true}}
                        render={({ field })=> (
                            <TextField {...field} margin='dense' variant='outlined' label='Titulo' fullWidth 
                            error={!!descricao} />
                        )}
                        />
                </Grid>
                <Grid item xs={6} lg={4} >
                    <Controller
                        name="data_inicio"
                        control={control}
                        rules={{required: true}}
                        render={({ field })=> (
                        <TextField {...field} type="date" margin='dense' variant='outlined' label='Data de inicio' InputLabelProps={{ shrink: true }}
                            error={!!data_inicio} />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={4} >
                    <Controller
                        name="data_fim"
                        control={control}
                        rules={{required: true}}
                        render={({ field })=>(
                        <TextField {...field} type="date" margin='dense' variant='outlined' label='Data de término' InputLabelProps={{ shrink: true }}
                            error={!!data_fim} 
                        />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                    name="situacao"
                    control={control}
                    rules={{required: true}}
                    render={({ field })=>(
                        <Select {...field} fullWidth options={options} label='Situação'  
                        error={!!situacao} />
                        )}
                    />
                </Grid>
                <Hidden smDown>
                    <Grid item sm={3} />
                </Hidden>
                <Grid item xs={12} sm={6}>
                    <Button type="submit" fullWidth variant='contained' color='primary'>
                        <Typography variant='body2'>ATUALIZAR</Typography>
                    </Button>
                </Grid>
                </Grid>
        </form>
        </Container>
    )
}
// Componente para adicionar mais uma alternativa para as perguntas
const FormAddOpcaoPergunta = ()=>{
    const { control, handleSubmit, formState: { errors }} = useForm();
    const dispatch = useDispatch();

    const { descricao, peso} = errors;
    // Funcao de callback para enviar os dados
    const fn = val=>{
        console.log(val);
        dispatch(avaliacaoAddOpcao({ descricao: val.descricao, peso: parseInt(val.peso) }));
    }

    return (
        <Container fullWidth>
            <form onSubmit={handleSubmit(fn)}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="descricao"
                        rules={{required: true}}
                        render={({ field })=>(
                            <TextField fullWidth {...field} margin="dense" variant='outlined' 
                                InputLabelProps={{shrink: true}} label='Descrição' 
                                placeholder='Descreva o nome da opção' error={!!descricao}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="peso"
                        defaultValue={1}
                        rules={{required: true}}
                        render={({ field })=>(
                            <TextField type="number" fullWidth {...field} margin="dense" variant='outlined' 
                                InputLabelProps={{ shrink: true}} label='Peso' 
                                inputProps={{ min: 1}}
                                placeholder='' error={!!peso} 
                                max={5}
                            />
                        )}
                    />                                        
                </Grid>
                <Hidden smDown>
                    <Grid item sm={3} />
                </Hidden>
                <Grid item xs={12} sm={6}>
                    <Button fullWidth variant="contained" color="primary" type="submit">
                        <Typography variant="body2">ADICIONAR</Typography>
                    </Button>
                </Grid>
            </Grid>
            </form>
        </Container>
    )
}
//
export default Avaliacao;