import React from "react";
import { Container } from "@mui/material";
import BackgroundRouter from "../background-router";

function GestaoAlmoxarifado() {
  const _html =
    '<iframe title="workload - Página 1" style="width:100%; height:calc(100vh - 80px)" src="https://app.powerbi.com/view?r=eyJrIjoiNzExOTJhMzEtOWJmYS00NWI0LWE3NjItODBlZTM5ZmI2NzRiIiwidCI6Ijk4NmIwMzBlLWJmMzEtNDdlNy1hZTk0LWYyNzQ4MTA1ZTU0NiJ9" frameborder="0" allowFullScreen="true"></iframe>';
  return (
    <BackgroundRouter>
      <Container sx={{ mt: 1 }} maxWidth={false}>
        <div dangerouslySetInnerHTML={{ __html: _html }} />
      </Container>
    </BackgroundRouter>
  );
}

GestaoAlmoxarifado.rota = "/gestao_almoxarifado";

export default GestaoAlmoxarifado;
