import {
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { Body1, H6, Icone, Pesquisando, Select } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { selectStudents } from "./treinamento-selectors";
import { useList, useToggle } from "react-use";
import { ToastErro } from "../../../utils/utils";
import _ from "lodash";
import { trainningAddStudents } from "./treinamento-actions";
import { useFetch } from "../../../hooks";
import { useTranslation } from "react-i18next";

function TreinamentoStudentsFormAdd({ id, studentsInTrainning }) {
  const [wait, setWait] = useToggle(false);
  const [studentsListAdd, { push, removeAt, clear }] =
    useList(studentsInTrainning);
  const dispatch = useDispatch();
  const students = useSelector(selectStudents);
  const { t } = useTranslation();

  const onAddStudent = useCallback(
    (newStudent) => {
      if (
        _.filter(studentsListAdd, (val) => val[0] === newStudent[0]).length > 0
      ) {
        ToastErro(t("treinamento.studentAlreadyAdded"));
        return;
      }
      push(newStudent);
    },
    [push, studentsListAdd, t]
  );

  // Salvar os estudantes inclusos no treinamento
  const saveStudents = useCallback(() => {
    setWait();

    if (studentsListAdd.length > 0) {
      dispatch(
        trainningAddStudents(
          {
            id_treinamento: id,
            alunos: studentsListAdd.map((student) => ({
              aluno: student[0],
              assinatura: "",
              avaliacao: "",
            })),
          },
          setWait
        )
      );
    }
  }, [dispatch, setWait, id, studentsListAdd]);

  return (
    <Container>
      <H6>{t("treinamento.titleFormStudents")}</H6>
      <Body1>{t("treinamento.titleFormStudentsSubtitle")}</Body1>
      <StudentAdd students={students} onAdd={onAddStudent} />
      <StudentsListForm students={studentsListAdd} onRemove={removeAt} />
      <br />
      <Stack justifyContent="center" direction="row" gap={1}>
        <Fade in={studentsListAdd.length > 0}>
          <Button
            disabled={wait}
            variant="outlined"
            onClick={clear}
            startIcon={
              wait ? (
                <CircularProgress size={20} />
              ) : (
                <Icone icone="DeleteSweep" />
              )
            }
          >
            {t("treinamento.labelBtnClearAllStudents")}
          </Button>
        </Fade>
        <Fade in={studentsListAdd.length > 0}>
          <Button
            disabled={wait}
            variant="contained"
            startIcon={
              wait ? <CircularProgress size={20} /> : <Icone icone="Save" />
            }
            onClick={saveStudents}
          >
            {t("treinamento.labelBtnSaveStudents")}
          </Button>
        </Fade>
      </Stack>
    </Container>
  );
}

const StudentAdd = ({ students, onAdd }) => {
  const [student, setStudent] = React.useState("");

  // A cada inclusão de um novo aluno
  useEffect(() => {
    if (student) {
      onAdd([student.value, student.label]);
      setStudent("");
    }
  }, [onAdd, student, setStudent]);

  return (
    <Stack direction="row" gap={1}>
      <Box sx={{ flex: 1 }}>
        <Select autoFormat options={students} onChange={setStudent} />
      </Box>
    </Stack>
  );
};

const StudentsListForm = ({ students, onRemove }) => {
  const { t } = useTranslation();

  return (
    <List>
      {students.map((student, index) => (
        <Fade key={index} in>
          <Paper elevation={2} sx={{ p: 1, my: 1 }}>
            <ListItem>
              <ListItemText
                primary={student[1]}
                secondary={`${t("treinamento.studentNumber")} ${index + 1}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  title={t("treinamento.studentRemove")}
                  onClick={() => onRemove(index)}
                  color="error"
                >
                  <Icone icone="Delete" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Paper>
        </Fade>
      ))}
    </List>
  );
};

export const TreinamentoStudentsWrapper = ({
  id,
  noFormatData,
  renderItem,
}) => {
  const { wait, setFetch, data, error } = useFetch(
    "/treinamento_alunos",
    "GET"
  );

  const fnFormatter = noFormatData
    ? (item) => item
    : (item) => [
        `${item.planta}_${item.matricula}`,
        `${item.matricula}_${item.nome}`,
      ];

  useEffect(() => {
    setFetch({ id_treinamento: id });
  }, [setFetch, id]);

  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);

  return data ? (
    renderItem(id, data.map(fnFormatter))
  ) : wait ? (
    <Container maxWidth="md">
      {" "}
      <Pesquisando />{" "}
    </Container>
  ) : null;
};

export default TreinamentoStudentsFormAdd;
