import { useDispatch, useSelector } from "react-redux";
import {
  selectAguardar,
  selectArvores,
  selectColaboradorUnico,
  selectPlanta,
} from "./colaboradores-select";
import { Avatar, Container, Divider, Stack } from "@mui/material";
import { Body1, Caption, EntradaForm } from "../../../components";
import * as yup from "yup";
import {
  colaboradoresUpdEmail,
  colaboradoresUpdGestorArvoreSituacao,
  colaboradoresUpdSenha,
} from "./colaboradores-actions";
import { useTranslation } from "react-i18next";

const sxMinMaxHeight = {
  minHeight: "50vh",
  maxHeight: "80vh",
  overflow: "auto",
};

// Path base para avatar
const PATH_AVATAR = "/static/imagens/avatar/";

// Renderiza as opcoes para alterar email, senha e arvore
const RenderFormAlterarEmailSenhaArvore = () => {
  const planta = useSelector(selectPlanta);
  const { matricula, arvore, cargo, email, nome } = useSelector(
    selectColaboradorUnico
  );

  return (
    <Container maxWidth="sm" sx={{ ...sxMinMaxHeight, width: "100vw", pb: 2 }}>
      <Stack spacing={2}>
        <Stack
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            src={`${PATH_AVATAR}/${planta}/${matricula}.jpg`}
            alt={nome}
          />
          <Stack>
            <Body1 fontWeight="bold">{nome}</Body1>
            <Caption>{cargo}</Caption>
          </Stack>
        </Stack>
        <Divider />
        <FormAlterarSenha matricula={matricula} />
        <FormAlterarArvore matricula={matricula} arvore={arvore} />
        <FormAlterarEmail matricula={matricula} email={email} />
      </Stack>
    </Container>
  );
};

// Componente para alterar a senha
const FormAlterarSenha = ({ matricula }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aguardar = useSelector(selectAguardar);
  const schema = [
    {
      name: "senha",
      titulo: t("colaboradores.labelPasswdTitle"),
      descricao: t("colaboradores.labelPasswdDescription"),
      icon: "VpnKey",
      placeholder: t("colaboradores.labelPasswdPlaceholder"),
      type: "password",
    },
  ];
  const schemaMessageError = {
    senha: t("colaboradores.errorPasswd"),
  };
  const schemaValidator = yup.object().shape({
    senha: yup.string().min(6).required(),
  });
  //
  const fn = (val) => {
    dispatch(
      colaboradoresUpdSenha({
        matricula,
        senha: val.senha,
      })
    );
  };

  return (
    <EntradaForm
      schema={schema}
      schemaMessageError={schemaMessageError}
      schemaValidator={schemaValidator}
      onSubmit={fn}
      buttonProps={{
        size: "small",
        label: t("colaboradores.labelBtnSavePasswd"),
        icon: "Edit",
      }}
      wait={aguardar}
    />
  );
};

// Componente para alterar a arvore
const FormAlterarArvore = ({ arvore, matricula }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const arvores = useSelector(selectArvores);
  const aguardar = useSelector(selectAguardar);

  //
  const schema = [
    {
      name: "arvore",
      titulo: t("colaboradores.labelThreeTitle"),
      descricao: t("colaboradores.labelThreeDescription"),
      icon: "",
      placeholder: t("colaboradores.labelThreePlaceholder"),
      type: "select",
      autoFormat: true,
      itens: arvores,
      defaultValue: arvore,
    },
  ];
  const schemaMessageError = {
    arvore: t("colaboradores.errorThree"),
  };
  const schemaValidator = yup.object().shape({
    arvore: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
  });
  //
  const fn = (val) => {
    dispatch(
      colaboradoresUpdGestorArvoreSituacao({
        matricula,
        arvore: parseInt(val.arvore.value),
      })
    );
  };

  return (
    <EntradaForm
      schema={schema}
      schemaMessageError={schemaMessageError}
      schemaValidator={schemaValidator}
      onSubmit={fn}
      buttonProps={{
        size: "small",
        label: t("colaboradores.labelBtnSaveThree"),
      }}
      wait={aguardar}
    />
  );
};

// Formulario para alterar o email
const FormAlterarEmail = ({ email, matricula }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aguardar = useSelector(selectAguardar);
  //
  const schema = [
    {
      name: "email",
      titulo: t("colaboradores.labelEmailTitle"),
      descricao: t("colaboradores.labelEmailDescription"),
      icon: "Email",
      defaultValue: email,
      placeholder: t("colaboradores.labelEmailPlaceholder"),
      type: "email",
    },
  ];
  const schemaMessageError = {
    email: t("colaboradores.errorEmail"),
  };
  const schemaValidator = yup.object().shape({
    email: yup.string().email().required(),
  });
  //
  const fn = (val) => {
    dispatch(
      colaboradoresUpdEmail({
        matricula,
        email: val.email,
      })
    );
  };

  return (
    <EntradaForm
      schema={schema}
      schemaMessageError={schemaMessageError}
      schemaValidator={schemaValidator}
      onSubmit={fn}
      buttonProps={{
        size: "small",
        label: t("colaboradores.labelBtnSaveEmail"),
        icon: "Email",
      }}
      wait={aguardar}
    />
  );
};

export default RenderFormAlterarEmailSenhaArvore;
