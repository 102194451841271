import React, { useCallback } from "react";
import { trainningSetModal } from "../treinamento-actions";
import { selectModal } from "../treinamento-selectors";
import { useDispatch, useSelector } from "react-redux";
import { DrawerDialog } from "../../../../extra-components";
import TreinamentoModal from "../treinamento-modal";

function TrainningModalViewer() {
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);

  const fnCloseModal = useCallback(() => {
    dispatch(trainningSetModal());
  }, [dispatch]);

  return modal ? (
    <DrawerDialog
      fnGetCorpo={() => <TreinamentoModal modal={modal} />}
      fecharModal={fnCloseModal}
    />
  ) : null;
}

export default TrainningModalViewer;
