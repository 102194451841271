import { toast } from "react-toastify";
import fetchRedux from "../../../api/fetch_redux";

export const OPORTUNIDADES_LIMPAR = 'OPORTUNIDADES_LIMPAR';
export const OPORTUNIDADES_INIT = 'OPORTUNIDADES_INIT';
export const OPORTUNIDADES_AGUARDAR = 'OPORTUNIDADES_AGUARDAR';
export const OPORTUNIDADES_VER_MODAL = 'OPORTUNIDADES_VER_MODAL';
export const OPORTUNIDADES_FECHAR_MODAL = 'OPORTUNIDADES_FECHAR_MODAL';
export const OPORTUNIDADES_CANDIDATO = 'OPORTUNIDADES_CANDIDATO';
export const OPORTUNIDADES_CANDIDATOS_RESPOSTAS = 'OPORTUNIDADES_CANDIDATOS_RESPOSTAS';
export const OPORTUNIDADES_CANDIDATOS_RESPOSTAS_LIMPAR = 'OPORTUNIDADES_CANDIDATOS_RESPOSTAS_LIMPAR';
export const OPORTUNIDADES_ALTERA_FASE = 'OPORTUNIDADES_ALTERA_FASE';
//
const ROTAS = [
    '/oportunidades',
    '/oportunidades_questionario',
    '/oportunidades_questionario_resposta',
];
//
const oportunidadesSetAguardar = value=>({
    type: OPORTUNIDADES_AGUARDAR,
    data: value,
})
//
export const oportunidadesGetDados = () => dispatch => {
    fetchRedux(ROTAS[0], 'POST', null, (resposta)=>{
        dispatch({
            type: OPORTUNIDADES_INIT,
            data: resposta,
        });
    }, (value) => dispatch(oportunidadesSetAguardar(value)) );
}
// Se cadastra a vaga 
export const oportunidadesCandidatarSe = (formData, onWait) => dispatch => {
    fetchRedux(ROTAS[1], 'POST', formData, (resposta)=>{
        toast.dark(resposta.sucesso, { type: 'success'});
        dispatch({
            type: OPORTUNIDADES_CANDIDATO,
            data: resposta.data.vaga,
        });
        // Fechar o modal que foi aberto
        dispatch(oportunidadesFecharModal());
    }, onWait );
}
// Lista todos os candidatos que foram registrados para a vaga
export const oportunidadesCandidatosVaga = (vaga, onWait) => dispatch => {
    fetchRedux(`${ROTAS[2]}/${vaga}`, 'GET', null, (resposta)=>{
        dispatch({
            type: OPORTUNIDADES_CANDIDATOS_RESPOSTAS,
            data: resposta,
        });
    }, onWait );
}
// Limpa a lista dos candidatos a vaga
export const oportunidadesCandidatosVagaLimpar = ()=>({
    type: OPORTUNIDADES_CANDIDATOS_RESPOSTAS_LIMPAR,
})
// Exibe um modal
export const oportunidadesSetModal = (data)=>({
    type: OPORTUNIDADES_VER_MODAL,
    data
});
// fechar o modal
export const oportunidadesFecharModal = ()=>({
    type: OPORTUNIDADES_FECHAR_MODAL
})
// Para limpar os dados na saida
export const oportunidadesLimpar = ()=>({
    type: OPORTUNIDADES_LIMPAR,
});
// Para realizar a alteração da fase do candidato a concorrencia da vaga
export const oportunidadesAlterarFase = (formData, onWait) => dispatch => {

    fetchRedux(ROTAS[0], 'PUT', formData, (resposta)=>{
        toast.dark(resposta.sucesso, { type: 'success'});
        dispatch({
            type: OPORTUNIDADES_ALTERA_FASE,
            data: resposta.data, // {id_vaga, para_fase}
        });
        // Fechar o modal que foi aberto
        dispatch(oportunidadesFecharModal());
    }, onWait );
}