import {
  OUVIDORIA_FECHAR_MODAL,
  OUVIDORIA_INIT,
  OUVIDORIA_INIT_OUVIDORIAS,
  OUVIDORIA_LIMPAR_DADOS,
  OUVIDORIA_SET_MODAL,
  OUVIDORIA_FILTRO,
  OUVIDORIA_LIMPAR_FILTRO,
  OUVIDORIA_ADD,
  OUVIDORIA_UPD,
  OUVIDORIA_FILTRO_AVANCADO,
} from "./ouvidoria-actions";

export default function ouvidoriaReducer(state = null, action) {
  switch (action.type) {
    case OUVIDORIA_INIT:
      return {
        ...state,
        ...action.data,
      };
    case OUVIDORIA_INIT_OUVIDORIAS: //
      return {
        ...state,
        dados: action.data,
      };
    case OUVIDORIA_LIMPAR_DADOS:
      return null;
    case OUVIDORIA_SET_MODAL:
      return {
        ...state,
        modal: action.data,
      };
    case OUVIDORIA_FECHAR_MODAL:
      return {
        ...state,
        modal: null,
      };
    case OUVIDORIA_FILTRO:
      return {
        ...state,
        filtro: action.data.dados,
        filtroAplicado: action.data.filtroAplicado,
      };
    case OUVIDORIA_FILTRO_AVANCADO:
      return {
        ...state,
        filtro: action.data,
        filtroAplicado: "FiltroAvancado",
        modal: null,
      };
    case OUVIDORIA_LIMPAR_FILTRO:
      return {
        ...state,
        filtro: null,
        filtroAplicado: null,
      };
    case OUVIDORIA_ADD:
      return {
        ...state,
        modal: null,
        dados: [action.data, ...state.dados],
      };
    case OUVIDORIA_UPD: // Atualizando o status
      return {
        ...state,
        dados: state.dados.map((ele) => {
          if (ele.id_ouvidoria === action.data.id_ouvidoria) {
            return action.data;
          }
          return ele;
        }),
      };
    default:
      return state;
  }
}
