import _ from "lodash";
import { useMediaQuery, useTheme, Container, Grow } from "@mui/material";
import React, { useCallback } from "react";
import { H5, EntradaForm } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "react-use";
import * as yup from "yup";
import { obterValidador, VALIDADOR_TIPO } from "../../../utils/validadores";
import {
  selectAssuntos,
  selectColaboradoresFormatados,
  selectUsuario,
} from "./helpdesk-seletores";
import { helpdeskAddTicket } from "./helpdesk-actions";
import { useHistory } from "react-router-dom";
import { createSelector } from "reselect";
import { useTranslation } from "react-i18next";

const selectColaboradores = (state) => state?.helpdesk?.colaboradores;
const selectIsAgente = (state) =>
  state?.usuario?.variaveis?.includes("HELPDESK_AGENTE");

const selectColaboradoresSolicitantes = createSelector(
  selectColaboradores,
  (val) => {
    return _.map(val, (item) => [
      `${item.matricula}_${item.planta}`,
      `${item.planta} - ${item.nome}`,
    ]);
  }
);

// Formulario para abertura de um ticket
const HelpdeskAdicionar = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const itensAssunto = useSelector(selectAssuntos);
  const colaboradores = useSelector(selectColaboradoresFormatados);
  const solicitantes = useSelector(selectColaboradoresSolicitantes);
  const usuario = useSelector(selectUsuario);
  const isAgente = useSelector(selectIsAgente);
  const history = useHistory();
  //
  const schema = [
    {
      type: "select",
      name: "idassunto",
      itens: itensAssunto,
      label: t("helpdesk.labelSubject"),
      autoFormat: true,
    },
    {
      type: "text",
      name: "titulo",
      label: t("helpdesk.labelTitleAdd"),
      placeholder: t("helpdesk.placeholderTitle"),
    },

    { type: "textrich", name: "descricao" },
    {
      type: "file",
      name: "arquivo",
      multiple: true,
      label: t("helpdesk.labelFiles"),
    },
    {
      type: "select",
      name: "copia_chamado",
      itens: colaboradores,
      autoFormat: true,
      isMulti: true,
      label: t("helpdesk.labelColabCopy"),
    },
    {
      type: "switch",
      name: "enviar_email",
      defaultChecked: true,
      label: t("helpdesk.labelSendEmail"),
    },
  ];
  let schemaValidator = {
    titulo: obterValidador(VALIDADOR_TIPO.texto, 3),
    idassunto: obterValidador(VALIDADOR_TIPO.selectUnico),
    descricao: yup.array().min(1).required(),
  };
  //
  const schemaMessageError = {
    titulo: t("helpdesk.errorTitle"),
    idassunto: t("helpdesk.errorSubject"),
    descricao: t("helpdesk.errorDescription"),
  };
  // Se for agente devemos ter um select para o colaborador
  if (isAgente) {
    schema.splice(0, 0, {
      type: "select",
      name: "matricula",
      itens: solicitantes,
      autoFormat: true,
      label: t("helpdesk.labelRequester"),
    });
    //
    schemaValidator.matricula = obterValidador(VALIDADOR_TIPO.selectUnico);
    schemaMessageError.matricula = t("helpdesk.errorMat");
  }
  //
  schemaValidator = yup.object().shape(schemaValidator);

  const onResultSuccess = useCallback(() => {
    if (isMobile) {
      history.goBack();
    }
  }, [isMobile, history]);
  //
  const onSubmit = useCallback(
    (val) => {
      const formData = new FormData();
      const obj = {
        enviar_email:
          typeof val.enviar_email === "undefined" ? true : val.enviar_email,
        titulo: val.titulo,
        idstatus: 1, //aberto
        idassunto: parseInt(val.idassunto.value),
        descricao: val.descricao,
        copia_chamado: [`${usuario.matricula}_${usuario.planta}`],

        matricula: usuario.matricula,
        planta: usuario?.planta,
      };

      // Se for agente recupere a matricula/planta formatada
      if (isAgente) {
        const [mat, planta] = val.matricula.value.split("_");
        obj.matricula = mat;
        obj.planta = planta;
      }
      if (val.arquivo) {
        [...val.arquivo].forEach((arq) => {
          formData.append("arquivo", arq);
        });
      }
      // Verifique se teve outros usuarios envolvidos
      if (val.copia_chamado?.length > 0) {
        _.forEach(val.copia_chamado, (reg) => {
          obj.copia_chamado.push(reg.value);
        });
      }
      formData.append("dados", JSON.stringify(obj));
      //
      dispatch(helpdeskAddTicket(formData, setWait, onResultSuccess));
    },
    [dispatch, isAgente, setWait, usuario, onResultSuccess]
  );

  return (
    <Grow in unmountOnExit>
      <Container maxWidth="md" sx={{ minHeight: isMobile ? "70vh" : "50vh" }}>
        <H5>{t("helpdesk.titleNewTicket")}</H5>
        <EntradaForm
          schema={schema}
          schemaMessageError={schemaMessageError}
          schemaValidator={schemaValidator}
          wait={wait}
          onSubmit={onSubmit}
        />
      </Container>
    </Grow>
  );
};

export default HelpdeskAdicionar;
