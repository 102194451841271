import React, { useState, useRef } from "react";
import {
  Typography,
  Button,
  TextField,
  Box,
  Paper,
  Stack,
  Container,
  InputLabel,
  CircularProgress,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Select from "react-select";
import Logo from "../images/logo.svg";
import BackgroundImage from "../images/fundo-tiberina.jpg";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { motion } from "framer-motion";
import MaskedInput from "react-text-mask";
//
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import { API_KEY } from "../config";

const variants = {
  scale: {
    hidden: {
      scale: 0.01,
    },
    visible: {
      scale: 1,
      transition: {
        delay: 0.7,
        duration: 0.3,
      },
    },
    exit: {
      scale: 0.01,
      transition: { duration: 0.1 },
    },
  },
  slideLeft: {
    hidden: {
      x: "-150vw",
    },
    visible: {
      x: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      x: "-150vw",
      transition: { duration: 0.1 },
    },
  },
  fade: {
    hidden: {
      opacity: 0.01,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.1,
      },
    },
    exit: {
      opacity: 0.01,
      transition: { duration: 0.1 },
    },
  },
};
const SXBTN = {
  borderRadius: "72px",
  py: 1,
  px: 8,
  my: 2,
  background:
    "linear-gradient(180deg, #012258 0%, rgba(3, 34, 53, 0.36) 99.99%, rgba(0,0,0, 1) 100%)",
};
const SX = {
  display: "flex",
  alignItems: "center",
  height: "56px",

  mb: 2,
  //background: '#012258',
  //pt: .5,
};
const SXBOX = {
  backgroundColor: "white",
  alignSelf: "stretch",
  display: "flex",
  alignItems: "center",
  px: { xs: 0, sm: 3 },
  borderTop: "4px solid #012258",
};

//
const opcoes_planta = [
  ["TAPE", "TAPE"],
  ["TAMG", "TAMG"],
  ["TAA", "TAA"],
].map((ele) => ({
  key: ele[0],
  label: ele[1],
  value: ele[0],
}));

const planta = window?.localStorage?.getItem("planta")
  ? JSON.parse(window.localStorage.getItem("planta"))
  : opcoes_planta[0];

// Schema de validacao
const schema = yup.object().shape({
  matricula: yup
    .string()
    .matches(/^[0-9]{6}$/g)
    .required(),
  senha: yup.string().required(),
  planta: yup
    .object()
    .shape({
      key: yup.string().min(1).required(),
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .required(),
});

const HeaderForm = () => {
  return (
    <Paper elevation={4} sx={SX}>
      <Paper
        elevation={4}
        sx={{
          flex: 1,
          alignSelf: "stretch",
          borderRadius: "0 0px 30px 0",
          backgroundColor: "#012258",
        }}
      />
      <Box sx={SXBOX}>
        <motion.img
          src={Logo}
          height={36}
          alt="Logo da Tiberina"
          style={{ marginLeft: "8px" }}
          variants={variants.scale}
          initial="hidden"
          animate="visible"
          exit="exit"
        />
      </Box>
    </Paper>
  );
};

export default function Login() {
  // Para referenciar o captcha invisivel
  const recaptchaRef = useRef();
  // Estado que controla a atividade do token
  const [token, setToken] = useState(null);
  const [aguardar, setAguardar] = useState(null);
  const [exibirSenha, setExibirSenha] = useState(false);
  const [erro, setErro] = useState(null);
  //

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fn = async (val) => {
    console.log(val);
    setErro(null);

    const { planta, matricula, senha } = val;
    let TOKEN = recaptchaRef.current.getValue();
    if (!token) {
      TOKEN = await recaptchaRef.current.executeAsync();
      setToken(TOKEN);
    } else {
      await recaptchaRef.current.reset();
      TOKEN = await recaptchaRef.current.executeAsync();
    }
    // Enviar para o servidor validar
    setAguardar(true);
    const formData = {
      planta: planta.value,
      matricula,
      senha,
      captcha: TOKEN,
    };

    try {
      await axios.post("/", formData);
      // Salve a planta
      window.localStorage.setItem("planta", JSON.stringify(planta));
      // Tudo certo redirecione o usuario
      window.location.href = "/splash";
    } catch (error) {
      if (error?.response?.data?.message) {
        setErro(error?.response?.data?.message);
      } else {
        setErro(error.toString());
      }
    } finally {
      setAguardar(false);
    }
  };

  return (
    <Stack
      spacing={0}
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "50%",
        minHeight: "100vh",
      }}
    >
      <Paper sx={{ mx: 1 }}>
        <HeaderForm />
        <Container maxWidth="sm">
          <Controller
            name="matricula"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <MaskedInput
                {...field}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                render={(ref, props) => (
                  <TextField
                    inputRef={ref}
                    {...props}
                    fullWidth
                    type="text"
                    sx={{ my: 1 }}
                    label="Matricula"
                    size="small"
                    placeholder="000000"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      autoComplete: "disabled",
                    }}
                    InputProps={{
                      startAdornment: <PersonIcon sx={{ color: "#005387" }} />,
                    }}
                    error={!!errors.matricula}
                    helperText={!!errors.matricula && "Ex: 000000"}
                  />
                )}
              />
            )}
          />
          <Controller
            name="senha"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type={exibirSenha ? "text" : "password"}
                sx={{ my: 1 }}
                label="Senha"
                size="small"
                InputProps={{
                  startAdornment: <LockIcon sx={{ color: "#005387" }} />,
                  endAdornment: (
                    <IconButton
                      onClick={() => setExibirSenha((state) => !state)}
                    >
                      {exibirSenha ? (
                        <VisibilityIcon sx={{ color: "#005387" }} />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  ),
                }}
                placeholder="Sua senha"
                error={!!errors.senha}
                helperText={!!errors.senha && "Digite a senha"}
              />
            )}
          />
          <Controller
            name="planta"
            defaultValue={planta}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <InputLabel>UNIDADE:</InputLabel>
                <Select
                  isDisabled={aguardar}
                  fullWidth
                  {...field}
                  options={opcoes_planta}
                />
                {!!errors.planta && (
                  <Typography variant="caption" align="center" color="error">
                    Escolha uma unidade
                  </Typography>
                )}
              </>
            )}
          />

          {erro && (
            <Typography
              sx={{ my: 1 }}
              component="p"
              variant="caption"
              align="center"
              color="error"
            >
              {erro}
            </Typography>
          )}
          <Stack direction="row" justifyContent="center">
            <Button
              onClick={handleSubmit(fn)}
              startIcon={
                aguardar ? (
                  <CircularProgress size={20} sx={{ color: "white" }} />
                ) : (
                  <LoginIcon />
                )
              }
              variant="contained"
              sx={SXBTN}
            >
              ENTRAR
            </Button>
          </Stack>
        </Container>
      </Paper>
      <br />
      <br />
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={API_KEY} />
    </Stack>
  );
}
