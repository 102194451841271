import { useState } from 'react';
/**
 * Este hook lida com a logica para controlar o avanço das respostas nos campos
 * 
 */
export default function useQuestionario(){
    const [contador, setContador] = useState(0);
    const [resposta, setResposta] = useState([null]);

    const avancar = (valor)=>{
        // Criar incremento
        const incremento = contador + 1;
        // Pegar o valor enviado, colocar na posicao atual 
        const copia = [...resposta];
        copia[contador] = valor;
        // Se o proximo valor ja existir nao apendar
        if(incremento > copia.length - 1 ) copia.push(null);
        // Para incrementar o valor do contador em 1
        setContador(incremento);
        // Atualizar as respostas
        setResposta(copia);        
        
    }
    const recuar = ()=>{
        if(contador > 0) setContador(val => val - 1);
    }

    return {
        contador, resposta, avancar, recuar
    }
}