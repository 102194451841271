import {
  FEED_RH_FECHAR_DRAWER,
  FEED_RH_GET_FEED,
  FEED_RH_ADD_FEED,
  FEED_RH_SET_DRAWER,
  FEED_RH_UPD_FEED,
  FEED_RH_DEL_FEED,
  FEED_RH_ADD_COMENTARIO,
  FEED_RH_UPD_COMENTARIO,
  FEED_RH_DEL_COMENTARIO,
  FEED_RH_ADD_CURTIDA,
  FEED_RH_UPD_CURTIDA,
  FEED_RH_DEL_CURTIDA,
  FEED_RH_LIMPAR_DADOS,
} from "./feed-actions";

const INIT_STATE = {
  feed: [],
  feed_x_anexo: {},
  feed_x_curtida: {},
  feed_x_comentario: {},
};

const feedRHReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FEED_RH_GET_FEED: // Para mais feeds
      return {
        feed: state.feed.concat(action.data.feed),
        feed_x_anexo: {
          ...state.feed_x_anexo,
          ...action.data.feed_x_anexo,
        },
        feed_x_curtida: {
          ...state.feed_x_curtida,
          ...action.data.feed_x_curtida,
        },
        feed_x_comentario: {
          ...state.feed_x_comentario,
          ...action.data.feed_x_comentario,
        },
      };
    case FEED_RH_ADD_FEED: // Para insercao de mais um feed
      return (() => {
        const { feed } = state;
        feed.unshift(action.data.feed);
        //
        return {
          ...state,
          feed: feed,
          feed_x_anexo: {
            ...state.feed_x_anexo,
            ...action.data.data_anexo,
          },
        };
      })();
    case FEED_RH_UPD_FEED: // Para atualizacao de um feed
      return (() => {
        const { feed } = state;
        // Fazz um loop e atualiza o feed existente
        const newFeeds = feed.map((ele) => {
          if (String(ele.id_feed) === String(action.data.feed.id_feed)) {
            return action.data.feed;
          }
          return ele;
        });
        //
        return {
          ...state,
          feed: newFeeds,
          feed_x_anexo: {
            ...state.feed_x_anexo,
            ...action.data.data_anexo,
          },
        };
      })();
    case FEED_RH_DEL_FEED: // Para excluir um feed
      return (() => {
        const { feed, feed_x_anexo } = state;
        const newFeeds = [];
        feed.forEach((ele) => {
          if (String(ele.id_feed) !== String(action.data)) {
            newFeeds.push(ele);
          }
        });
        // Exclui o anexo tambem
        delete feed_x_anexo[action.data];
        return {
          ...state,
          feed: newFeeds,
          feed_x_anexo: feed_x_anexo,
        };
      })();
    case FEED_RH_SET_DRAWER: // Para definir um drawer
      return {
        ...state,
        drawer: action.data,
      };
    case FEED_RH_FECHAR_DRAWER: // Para fechar um drawer
      return {
        ...state,
        drawer: null,
      };
    case FEED_RH_ADD_COMENTARIO: // Para inserir um novo comentario
      return (() => {
        const { feed_x_comentario } = state;

        if (feed_x_comentario.hasOwnProperty(action.data.id_feed)) {
          feed_x_comentario[action.data.id_feed].quantidade += 1;
        } else {
          feed_x_comentario[action.data.id_feed] = {
            quantidade: 1,
            euComentei: true,
          };
        }

        return {
          ...state,
          feed_x_comentario: feed_x_comentario,
        };
      })();
    case FEED_RH_UPD_COMENTARIO: // Para editar um comentario
      return (() => {
        const {
          drawer: { dados },
        } = state;
        // Faz um loop e procura pelo comentario que esta sofendo atualizacao
        const newDados = dados.map((ele) => {
          if (String(ele.id_comentario) === String(action.data.id_comentario)) {
            return action.data;
          }
          return ele;
        });

        return {
          ...state,
          drawer: {
            ...state.drawer,
            dados: newDados,
          },
        };
      })();
    case FEED_RH_DEL_COMENTARIO: // Para excluir um comentario
      return (() => {
        const {
          feed_x_comentario,
          drawer: { dados },
        } = state;
        const newDados = dados.filter(
          (ele) =>
            String(ele.id_comentario) !== String(action.data.id_comentario)
        );
        // Se encontrar o feed reduzir a quantidadde de comentarios
        if (feed_x_comentario?.hasOwnProperty(action.data.id_feed)) {
          feed_x_comentario[action.data.id_feed].quantidade -= 1;
        }

        return {
          ...state,
          feed_x_comentario: feed_x_comentario,
          drawer: {
            ...state.drawer,
            dados: newDados,
          },
        };
      })();
    case FEED_RH_ADD_CURTIDA: // Para adicionar uma curtida
      return (() => {
        const { feed_x_curtida } = state;
        const { id_feed, data } = action.data;
        // Veja se consegue encontrar o feed no objeto de curtida e incremente
        if (feed_x_curtida?.hasOwnProperty(id_feed)) {
          feed_x_curtida[id_feed].quantidade += 1;
          feed_x_curtida[id_feed].euCurti = data.id_curtida;
          feed_x_curtida[id_feed].tipoMinhaCurtida = data.tipo;
          // Incrementa a curtida (se existir deste tipo)
          if (feed_x_curtida[id_feed].tipos.hasOwnProperty(data.tipo)) {
            feed_x_curtida[id_feed].tipos[data.tipo] += 1;
          } else {
            feed_x_curtida[id_feed].tipos[data.tipo] = 1;
          }
        } else {
          feed_x_curtida[id_feed] = {
            quantidade: 1,
            euCurti: data.id_curtida,
            tipoMinhaCurtida: data.tipo,
            tipos: {
              [data.tipo]: 1,
            },
          };
        }
        return {
          ...state,
          feed_x_curtida: feed_x_curtida,
        };
      })();
    case FEED_RH_UPD_CURTIDA: // Para editar uma curtida
      return (() => {
        const { feed_x_curtida } = state;
        const { id_feed, data } = action.data;
        // Veja se consegue encontrar o feed no objeto de curtida e atualize
        if (feed_x_curtida?.hasOwnProperty(id_feed)) {
          const tipoAntigo = feed_x_curtida[id_feed].tipoMinhaCurtida;
          // Decrementa do tipo antigo (se valor nao for zerado)
          if (
            feed_x_curtida[id_feed].tipos.hasOwnProperty(tipoAntigo) &&
            feed_x_curtida[id_feed].tipos[tipoAntigo] > 0
          ) {
            feed_x_curtida[id_feed].tipos[tipoAntigo] -= 1;
          }

          if (feed_x_curtida[id_feed].tipos.hasOwnProperty(data.tipo)) {
            // Incrementa do tipo novo (se existir este tipo novo, senao define como 1)
            feed_x_curtida[id_feed].tipos[data.tipo] += 1;
          } else {
            feed_x_curtida[id_feed].tipos[data.tipo] = 1;
          }
          // Atualizando o tipo de curtida registrado
          feed_x_curtida[id_feed].tipoMinhaCurtida = data.tipo;
        }
        return {
          ...state,
          feed_x_curtida: feed_x_curtida,
        };
      })();
    case FEED_RH_DEL_CURTIDA: // Para excluir uma curtida de um feed
      return (() => {
        const { feed_x_curtida } = state;
        const { id_feed } = action.data;
        // Veja se consegue encontrar o feed no objeto de curtida e atualize
        if (feed_x_curtida?.hasOwnProperty(id_feed)) {
          const tipoMinhaCurtida = parseInt(
            feed_x_curtida[id_feed].tipoMinhaCurtida
          );
          // Decrementa do tipo antigo (se valor nao for zerado)
          if (
            feed_x_curtida[id_feed].tipos.hasOwnProperty(tipoMinhaCurtida) &&
            feed_x_curtida[id_feed].tipos[tipoMinhaCurtida] > 0
          ) {
            feed_x_curtida[id_feed].tipos[tipoMinhaCurtida] -= 1;
          }
          // Decrementa da quantidade total de curtidas do feed
          feed_x_curtida[id_feed].quantidade -= 1;
          // E registra como se nao tivesse sido curtido por mim
          feed_x_curtida[id_feed].euCurti = false;
          feed_x_curtida[id_feed].tipoMinhaCurtida = false;
        }

        return {
          ...state,
          feed_x_curtida: feed_x_curtida,
        };
      })();
    case FEED_RH_LIMPAR_DADOS: // Limpar dados do feed
      return {
        feed: [],
        feed_x_anexo: {},
        feed_x_curtida: {},
        feed_x_comentario: {},
      };
    default:
      return state;
  }
};

export default feedRHReducer;
