import React, { useEffect } from "react";
import { useFetch } from "../../../hooks";
import { VerAvaliacaoRespondida } from "./minha-equipe-questionario";
import { useSearchParam } from "react-use";
import { ToastErro } from "../../../utils/utils";
import { Container } from "@mui/material";

function MinhaEquipeGerarPdfAvaliacao() {
  const matricula = useSearchParam("matricula");
  const avaliacao = useSearchParam("ver_respostas");
  //
  const { data, error, setFetch } = useFetch(
    "/minha_equipe_questionario",
    "GET"
  );
  //
  useEffect(() => {
    if (avaliacao) {
      const obj = {
        matricula,
        ver_respostas: avaliacao,
      };
      setFetch(obj);
    }
  }, [matricula, avaliacao, setFetch]);
  //
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  return (
    <Container id="minha_equipe_avaliacao_exibir" sx={{ pb: 2 }} maxWidth="md">
      {data && <VerAvaliacaoRespondida {...data} />}
    </Container>
  );
}

MinhaEquipeGerarPdfAvaliacao.rota = "/minha_equipe_gerar_pdf_avaliacao";

export default MinhaEquipeGerarPdfAvaliacao;
