import {SET_ALERTA, FECHAR_ALERTA} from '../actions';

const   INITIAL_ALERTA = {
    visivel: false,
    mensagem: null,
    tipo: null,

}

export default function alertaReducer(state = INITIAL_ALERTA, action){
    switch (action.type) {
        case SET_ALERTA:
            return {...state, "mensagem": action.mensagem, "visivel": true };
        case FECHAR_ALERTA:
            return INITIAL_ALERTA;
        default:
            return state;
    }
}