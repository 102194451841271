import { memo, useState } from "react";
import _ from "lodash";
import { Body1, Icone, InputBusca, ScrollInfinito } from "../../../components";
import {
  Avatar,
  Chip,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import PainelDeNotificacaoModal from "./painel-de-notificacao-modal";

const ListaColaboradores = ({ data, setModal }) => {
  const [filtro, setFiltro] = useState(null);

  let filtrado;
  if (filtro?.length > 0) {
    filtrado = _.filter(
      data,
      (val) =>
        _.toLower(val.nome).search(filtro) !== -1 ||
        _.toLower(val.setor).search(filtro) !== -1 ||
        _.toLower(val.matricula).search(filtro) !== -1
    );
  } else {
    filtrado = data;
  }

  return (
    <>
      <InputBusca
        desativarPesquisaLenta
        sx={{ mt: 1, mb: 0 }}
        filtro={filtro}
        setFiltro={setFiltro}
        fullWidth
      />
      <ScrollInfinito
        tamanho="75vh"
        itensPorPagina={15}
        render={(ele) => (
          <ListaColaboradoresItem ele={ele} setModal={setModal} />
        )}
        itens={filtrado}
      />
    </>
  );
};

const ListaColaboradoresItem = memo(({ ele, setModal }) => (
  <ListItemButton
    onClick={() =>
      setModal({
        tipo: PainelDeNotificacaoModal.modal.AVULSA,
        dados: {
          matricula: ele.matricula,
          planta: ele.planta,
          nome: ele.nome,
          setor: ele.setor,
        },
      })
    }
    divider
    key={`${ele.matricula}_${ele.planta}`}
  >
    <ListItemAvatar>
      <Avatar src={ele.avatar} alt={ele.nome} />
    </ListItemAvatar>
    <ListItemText
      primary={
        <Stack
          sx={{ mb: 1 }}
          direction="row"
          spacing={1}
          justifyContent="space-between"
        >
          <Body1 sx={{ flex: 1 }}>{ele.setor}</Body1>
          <Chip color="info" label={ele.planta} size="small" />
          <Chip
            icon={
              <Icone
                icone={
                  ele.is_token_mobile === "S"
                    ? "NotificationsActive"
                    : "NotificationsOff"
                }
              />
            }
            color={ele.is_token_mobile === "S" ? "info" : "error"}
            size="small"
          />
        </Stack>
      }
      secondary={ele.nome}
    />
  </ListItemButton>
));

export default ListaColaboradores;
