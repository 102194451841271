import React from "react";
import { ContainerAdaptavel } from "../../../extra-components";
import NcpFiltroAvancado from "./ncp-filtro-avancado";
import NcpCancelStatus from "./ncp-cancel-status";

function NcpModal({ modal }) {
  let corpo;
  switch (modal.tipo) {
    case NcpModal.modal.FILTRO_AVANCADO:
      corpo = <NcpFiltroAvancado {...modal.dados} />;
      break;
    case NcpModal.modal.NCP_CANCEL_STATUS:
      corpo = <NcpCancelStatus {...modal.dados} />;
      break;
    default:
      break;
  }
  return (
    <ContainerAdaptavel sx={{ minHeight: "50vh" }}>{corpo}</ContainerAdaptavel>
  );
}

NcpModal.modal = {
  FILTRO_AVANCADO: "FILTRO_AVANCADO",
  NCP_CANCEL_STATUS: "NCP_CANCEL_STATUS",
};

export default NcpModal;
