import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectVariaveis,
  selectVariaveisColaborador,
  selectDrawer,
} from "./manutencao-variaveis-selectors";
import { manutencaoVariaveisAtivaDesativaVariavel } from "./manutencao-variaveis-actions";
import { Container, List, ListItem, ListItemText, Switch } from "@mui/material";
import { Body2, H6 } from "../../../components";
import { useTranslation } from "react-i18next";

// Componente para exibir as variaveis do colaborador
const ListDeVariaveisColaborador = memo(() => {
  const { t } = useTranslation();
  const variaveis = useSelector(selectVariaveis);
  const variaveisDoColaborador = useSelector(selectVariaveisColaborador);
  const mat_planta = useSelector(selectDrawer).dados;

  const dispatch = useDispatch();
  // Funcao para controlar a ativacao ou desativacao da variavel do colaborador
  const fnAtivaDesativaVariavel = (obj, ativo) => {
    // Se estiver ativo precisa enviar o contrario do ativo
    dispatch(manutencaoVariaveisAtivaDesativaVariavel(obj, ativo));
  };

  return (
    <Container
      sx={{ pt: 1, minHeight: "75vh", maxHeight: "85vh" }}
      maxWidth="md"
    >
      <H6>{t("manutencao_variaveis.titleListVarColab")}</H6>
      <List>
        {variaveis.map((ele, idx) => {
          const isChecado = variaveisDoColaborador.includes(parseInt(ele.id));
          const fn = (e) => {
            const [matri, planta] = mat_planta.split("_");

            fnAtivaDesativaVariavel(
              {
                matricula: matri,
                planta: planta,
                id_variavel: ele.id,
              },
              e.target.checked
            );
          };

          return (
            <ListItem key={ele.id}>
              <ListItemText
                primary={<Body2>{ele.nome}</Body2>}
                secondary={ele.descricao}
              />
              <Switch onChange={fn} checked={isChecado || false} />
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
});

export default ListDeVariaveisColaborador;
