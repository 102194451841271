import {
  BUDGET_CLOSE_MODAL,
  BUDGET_INIT,
  BUDGET_SET_MODAL,
} from "./budget-actions";

export default function budgetReducer(state = null, action) {
  switch (action.type) {
    case BUDGET_INIT:
      return action.data;
    case BUDGET_SET_MODAL:
      return {
        ...state,
        modal: action.data,
      };
    case BUDGET_CLOSE_MODAL:
      return {
        ...state,
        modal: null,
      };
    default:
      return state;
  }
}
