import { createSelector } from "reselect";

export const seletorCardSecurity = (state) => state.cartaoSeguranca;
export const seletorCardSecurityWait = (state) =>
  state?.cartaoSeguranca?.aguardar;
export const seletorCardSecurityCardStatus = (state) =>
  state.cartaoSeguranca?.cartao_status;
export const seletorCardSecurityTypeFilterQtdStatus = (state) =>
  state.cartaoSeguranca?.cartao_filtro_tipo_qtd_status;
export const seletorCardSecurityFilterStatus = (state) =>
  state?.cartaoSeguranca?.cartao_filtro_status;
export const seletorCardSecurityTypeFilter = (state) =>
  state?.cartaoSeguranca?.cartao_filtro_tipo;
export const seletorCardSecurityUser = (state) => state?.usuario?.usuario;
const seletorCardSecurityCardXModel = (state) =>
  state.cartaoSeguranca?.cartao_x_modelo;

export const selectorCardSecurityGetModel = (idCard) =>
  createSelector(seletorCardSecurityCardXModel, (cardXModel) => {
    if (!cardXModel?.hasOwnProperty(idCard)) {
      return null;
    }
    //
    return cardXModel[idCard][0];
  });
