import { Stack, useTheme } from "@mui/material";
import React, { useCallback } from "react";
import { EntradaForm, H6 } from "../../../components";
import * as yup from "yup";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { useToggle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { rnciNcpSubidaDeLinha } from "./rnci-ncp-actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const selectUsuario = (state) => state?.usuario?.usuario?.matricula;

//
const campos = {
  tempoGasto: "tempo_gasto",
  mod: "mod",
  moi: "moi",
  ok: "ok",
  ko: "ko",
  observacao: "observacao",
};

function RnciFormSubida({ id }) {
  const { t } = useTranslation();
  const history = useHistory();
  let rnci = id;
  if (history?.location?.state) {
    rnci = history.location.state;
  }

  return (
    <Stack sx={{ p: 1 }}>
      <H6>{t("rnci_ncp.tituloFormSubida")}</H6>
      <Form id={rnci} />
    </Stack>
  );
}
//
const Form = ({ id }) => {
  const isMobile = useTheme()?.isMobile;
  const history = useHistory();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const matricula = useSelector(selectUsuario);
  const { t } = useTranslation();

  //
  const schema = [
    {
      type: "number",
      name: campos.mod,
      placeholder: t("rnci_ncp.formPlaceholderMod"),
      label: t("rnci_ncp.formMod"),
      icon: "Numbers",
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "number",
      name: campos.moi,
      placeholder: t("rnci_ncp.formPlaceholderMoi"),
      label: t("rnci_ncp.formMoi"),
      icon: "Numbers",
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "number",
      name: campos.ok,
      placeholder: t("rnci_ncp.formPlaceholderOK"),
      label: t("rnci_ncp.formOK"),
      icon: "ThumbUp",
      grid: {
        xs: 12,
        md: 4,
      },
    },
    {
      type: "number",
      name: campos.ko,
      placeholder: t("rnci_ncp.formPlaceholderKO"),
      label: t("rnci_ncp.formKO"),
      icon: "ThumbDown",
      grid: {
        xs: 12,
        md: 4,
      },
    },
    {
      type: "time",
      name: campos.tempoGasto,
      placeholder: t("rnci_ncp.formPlaceholderTempoGasto"),
      label: t("rnci_ncp.formTempoGasto"),
      icon: "Schedule",
      grid: {
        xs: 12,
        md: 4,
      },
    },
    {
      type: "textarea",
      extraProps: {
        multiline: true,
        minRows: 3,
      },
      name: campos.observacao,
      placeholder: t("rnci_ncp.formPlaceholderObservacao"),
      label: t("rnci_ncp.formObservacao"),
    },
  ];
  //
  const schemaMessageError = {
    [campos.tempoGasto]: t("rnci_ncp.formErrorTempoGasto"),
    [campos.mod]: t("rnci_ncp.formErrorMod"),
    [campos.moi]: t("rnci_ncp.formErrorMoi"),
    [campos.ok]: t("rnci_ncp.formErrorOK"),
    [campos.ko]: t("rnci_ncp.formErrorKO"),
    [campos.observacao]: t("rnci_ncp.formErrorObservacao"),
  };

  const schemaValidator = yup.object().shape({
    [campos.tempoGasto]: obterValidador(VALIDADOR_TIPO.hora),
    [campos.mod]: yup.number().min(0).required(),
    [campos.moi]: yup.number().min(0).required(),
    [campos.ok]: yup.number().min(0).required(),
    [campos.ko]: yup.number().min(0).required(),
    [campos.observacao]: obterValidador(VALIDADOR_TIPO.texto, 3),
  });
  //
  const onSubmit = useCallback(
    (val) => {
      // const formData = new FormData();
      const obj = {
        mat: matricula,
        codigo_rnci: id,
        tempo_gasto: val[campos.tempoGasto],
        mod: val[campos.mod],
        moi: val[campos.moi],
        ok: val[campos.ok],
        ko: val[campos.ko],
        observacao: val[campos.observacao],
      };
      // formData.append("dados", JSON.stringify(obj));
      //
      let fn = () => {};
      if (isMobile) {
        fn = () => history.goBack();
      }
      //
      dispatch(rnciNcpSubidaDeLinha(obj, setWait, fn));
    },
    [dispatch, matricula, setWait, id, history, isMobile]
  );

  return (
    <EntradaForm
      schema={schema}
      schemaMessageError={schemaMessageError}
      schemaValidator={schemaValidator}
      onSubmit={onSubmit}
      wait={wait}
    />
  );
};

RnciFormSubida.rota = "/rnci_form_subida";

export default RnciFormSubida;
