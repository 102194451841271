import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import {
  Container,
  Stack,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Hidden,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { cadastroProjetosAtualizarStatus } from "./kaizen-actions";
import { fnGetIcon, fnKaizenGetText } from "./kaizen-utils";
import { useTranslation } from "react-i18next";

// Criacao do schema de validação de status
const schemaAlteracaoStatus = yup.object().shape({
  observacao: yup.string().notRequired(),
});

// Este componente exibe um campo para entrada de texto que deve ser incluso ao projeto
const FormularioAlteracaoStatus = ({ acao }) => {
  const { t } = useTranslation();
  const [arquivo, setArquivo] = useState(null);
  // Cria mais 2 states para gravar arquivos dos campos de anexo CD e FI
  const [arquivoFI, setArquivoFI] = useState(null);
  const [arquivoCD, setArquivoCD] = useState(null);

  const { aguardar } = useSelector((state) => state);
  // Validacao e manipulacao do formulario
  const newValidator = schemaAlteracaoStatus;
  if (acao.situacaoAnterior) {
    newValidator.data_saving = yup.date().required();
  }

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(newValidator),
  });

  const dispatch = useDispatch();
  // Funcao para atualizacao do status
  const fnValidador = (val) => {
    const { observacao } = val;
    // Este sera o objeto a ser submetido a envio
    const objEnvio = {
      id_projeto: acao.idProjeto,
      status: acao.status,
      observacao,
    };
    if (val.data_saving) {
      objEnvio.data_saving = format(parseISO(val.data_saving), "yyyy-MM-dd");
    }
    // Se o status estiver indo para validacao FI o anexo torna-se obrigatorio
    if (acao.status === "S") {
      if (!arquivoFI?.files || arquivoFI.files.length < 1) {
        toast.dark(t("cadastro_projetos.errorAttachFI"), {
          type: "error",
        });
        return false;
      }
      // Campos de arquivos FI e CD separados para submissao
      objEnvio["arquivo_fi"] = arquivoFI.files;
      if (arquivoCD?.files?.length > 0) {
        objEnvio["arquivo_cd"] = arquivoCD.files;
      }
    } else {
      // Em um caso normal, o campo arquivo será acionado
      objEnvio["arquivo"] = arquivo?.files ? arquivo.files : null;
    }
    // Isto e para alterar o status
    dispatch(cadastroProjetosAtualizarStatus(objEnvio));
  };

  return (
    <Container maxWidth="md">
      <Stack sx={{ minHeight: "25vh", p: 1 }}>
        <Typography variant="subtitle1" align="center">
          {t("cadastro_projetos.alterStatus")}
        </Typography>
        <Typography align="center" variant="caption" sx={{ opacity: 0.7 }}>
          {t("cadastro_projetos.descriptionUpdStatus")}
          <Typography color="error" variant="caption">
            &nbsp;{fnKaizenGetText(acao.status, t)}
          </Typography>
        </Typography>
        <Grid container spancing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              defaultValue=""
              name="observacao"
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ my: 2 }}
                  size="small"
                  label={t("cadastro_projetos.observation")}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="textarea"
                  rows={3}
                  multiline
                  placeholder={t("cadastro_projetos.observationPlaceholder")}
                  error={!!errors.observacao}
                  helperText={
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="caption">
                        {errors?.observacao &&
                          t("cadastro_projetos.errorNoLimit")}
                      </Typography>
                    </Stack>
                  }
                />
              )}
            />
          </Grid>
          {acao.status === "S" ? (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ mr: { xs: 5, md: 1 }, my: { xs: 1, md: 2 } }}
                  size="small"
                  label={t("cadastro_projetos.attachFI")}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ startAdornment: fnGetIcon("S") }}
                  inputProps={{ multiple: true }}
                  type="file"
                  required={acao.status === "S"}
                  onChange={(evt) => setArquivoFI(evt.target)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ ml: { xs: 0, md: 1 }, my: { xs: 1, md: 2 } }}
                  size="small"
                  label={t("cadastro_projetos.attachCD")}
                  fullWidth
                  InputProps={{ startAdornment: fnGetIcon("C") }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ multiple: true }}
                  type="file"
                  required={true}
                  onChange={(evt) => setArquivoCD(evt.target)}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <TextField
                sx={{ my: 2 }}
                size="small"
                label={t("cadastro_projetos.attachLabel")}
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ multiple: true }}
                type="file"
                required={acao.status === "S"}
                onChange={(evt) => setArquivo(evt.target)}
              />
            </Grid>
          )}
          {acao?.situacaoAnterior && (
            <Controller
              control={control}
              defaultValue=""
              name="data_saving"
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ my: 2 }}
                  size="small"
                  label={t("cadastro_projetos.labelDateClosedLabel")}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  placeholder={t("cadastro_projetos.placeholderDateClosed")}
                  error={!!errors.data_saving}
                  helperText={
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="caption">
                        {errors?.data_saving &&
                          t("cadastro_projetos.errorDateClosed")}
                      </Typography>
                    </Stack>
                  }
                />
              )}
            />
          )}
          <Hidden smDown>
            <Grid item xs={3} />
          </Hidden>
          <Grid item xs={12} sm={6}>
            <Button
              sx={{ my: 1 }}
              startIcon={
                aguardar ? <CircularProgress size={20} /> : <SaveIcon />
              }
              fullWidth
              variant="contained"
              color="primary"
              disabled={aguardar}
              onClick={handleSubmit(fnValidador)}
            >
              {t("cadastro_projetos.btnSend")}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

export default FormularioAlteracaoStatus;
