import { Container } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { EntradaForm, H6 } from "../../../components";
import * as yup from "yup";

import { format } from "date-fns";
import { ToastErro } from "../../../utils/utils";
import { useDispatch } from "react-redux";
import { trainningCloseModal } from "./treinamento-actions";
import { useFetch } from "../../../hooks";
import { useTranslation } from "react-i18next";

const KEYS = {
  date_from: "data_treinamento_de",
  date_to: "data_treinamento_ate",
};

function TreinamentoDownloadExcel() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, setFetch, wait, error } = useFetch(
    "/treinamento_excel",
    "POST",
    "JSON"
  );
  //
  const schema = [
    {
      type: "date",
      name: KEYS.date_from,
      label: t("treinamento.trainningDownloadExcelLabelDateFrom"),
      required: true,
      grid: {
        xs: 6,
      },
    },
    {
      type: "date",
      name: KEYS.date_to,
      label: t("treinamento.trainningDownloadExcelLabelDateTo"),
      required: true,
      grid: {
        xs: 6,
      },
    },
  ];
  const schemaMessageError = {
    [KEYS.date_from]: t("treinamento.trainningDownloadExcelErrorDateFrom"),
    [KEYS.date_to]: t("treinamento.trainningDownloadExcelErrorDateTo"),
  };
  const schemaValidator = yup.object().shape({
    [KEYS.date_from]: yup.date().required(),
    [KEYS.date_to]: yup.date().required(),
  });
  //
  const onSubmit = useCallback(
    (val) => {
      const obj = {
        [KEYS.date_from]: format(val[KEYS.date_from], "yyyy-MM-dd"),
        [KEYS.date_to]: format(val[KEYS.date_to], "yyyy-MM-dd"),
      };

      setFetch(obj);
    },
    [setFetch]
  );

  useEffect(() => {
    if (data) {
      const a = document.createElement("a");
      a.href = data.path;
      a.target = "_blank";
      a.setAttribute(
        "download",
        data.path.substring(data.path.lastIndexOf("/") + 1)
      );
      a.click();

      dispatch(trainningCloseModal());
    }
  }, [data, dispatch]);
  //
  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);

  return (
    <Container maxWidth="md">
      <H6>{t("treinamento.trainningDownloadExcelTitle")}</H6>
      <EntradaForm
        wait={wait}
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

export default TreinamentoDownloadExcel;
