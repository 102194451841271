const rotaHelpdesk = "/helpdesk";
const rotaHelpdeskDetalhes = "/helpdesk_detalhes";
const rotaHelpdeskAdicionar = "/helpdesk_adicionar";
const rotaMenus = "/menus_mobile";
const rotaHelpdeskFiltroAvancado = "/helpdesk_filtro_avancado";
const rotaInformeRendimentos = "/informe_rendimentos";
const rotaGestaoTickets = "/gestao_tickets";

export {
  rotaGestaoTickets,
  rotaMenus,
  rotaHelpdeskFiltroAvancado,
  rotaHelpdesk,
  rotaHelpdeskDetalhes,
  rotaHelpdeskAdicionar,
  rotaInformeRendimentos,
};
