import { Container } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import { Body2 } from "./tipografia";
import dataLottie2 from "../lotties/sem-dados-encontrados2.json";
import dataLottie3 from "../lotties/desenvolvimento.json";
import dataLottie4 from "../lotties/pesquisando.json";
import dataLottie5 from "../lotties/sem-notificacao.json";
import dataLottie6 from "../lotties/dinheiro.json";
import dataLottie7 from "../lotties/cadeado.json";
import dataLottie8 from "../lotties/conclusao.json";

import { useMediaQuery, useTheme } from "@mui/material";

const defaultOptions = {
  loop: true,
  autoplay: true,
  delay: 2000,
  animationData: dataLottie2,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const AnimacaoSemNotificacao = ({ titulo, width, height }) => {
  return (
    <Container disableGutters maxWidth="xs" sx={{ p: 1, mt: 2 }}>
      <Lottie
        options={{ ...defaultOptions, animationData: dataLottie5 }}
        height={width || 250}
        width={height || 250}
      />
      {titulo && (
        <Body2 component="p" align="center">
          {titulo}
        </Body2>
      )}
    </Container>
  );
};

export const AnimacaoSemDados = ({ titulo, width, height }) => {
  return (
    <Container disableGutters maxWidth="xs" sx={{ p: 1, mt: 2 }}>
      <Lottie
        options={{ ...defaultOptions, animationData: dataLottie2 }}
        height={width || 250}
        width={height || 250}
      />
      {titulo && (
        <Body2 component="p" align="center">
          {titulo}
        </Body2>
      )}
    </Container>
  );
};

export const Desenvolvimento = ({ titulo }) => {
  return (
    <Container disableGutters maxWidth="xs" sx={{ p: 1, mt: 2 }}>
      <Lottie
        options={{ ...defaultOptions, animationData: dataLottie3 }}
        height={250}
        width={250}
      />
      {titulo && (
        <Body2 component="p" align="center">
          {titulo}
        </Body2>
      )}
    </Container>
  );
};

export const Pesquisando = () => {
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  return (
    <Container disableGutters maxWidth="xs" sx={{ p: 1, mt: 2 }}>
      <Lottie
        options={{ ...defaultOptions, animationData: dataLottie4 }}
        height={isMobile ? 200 : 400}
        width={isMobile ? 200 : 400}
      />
    </Container>
  );
};

export const AnimacaoDinheiro = ({ titulo }) => {
  //const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  return (
    <Container disableGutters maxWidth="xs" sx={{ p: 1, mt: 2 }}>
      <Lottie
        options={{ ...defaultOptions, animationData: dataLottie6 }}
        height={300}
        width={300}
      />
      {titulo && (
        <Body2 component="p" align="center">
          {titulo}
        </Body2>
      )}
    </Container>
  );
};

export const AnimacaoCadeado = ({ titulo }) => {
  return (
    <Container disableGutters maxWidth="xs" sx={{ p: 1, mt: 2 }}>
      <Lottie
        options={{
          ...defaultOptions,
          delay: 1000,
          loop: false,
          animationData: dataLottie7,
        }}
        height={300}
        width={300}
      />
      {titulo && (
        <Body2 component="p" align="center">
          {titulo}
        </Body2>
      )}
    </Container>
  );
};
//
export const AnimacaoConClusao = ({ titulo }) => {
  return (
    <Container disableGutters maxWidth="xs" sx={{ p: 1, mt: 2 }}>
      <Lottie
        options={{
          ...defaultOptions,
          delay: 1000,
          loop: 3,
          animationData: dataLottie8,
        }}
        height={300}
        width={300}
      />
      {titulo && (
        <Body2 component="p" align="center">
          {titulo}
        </Body2>
      )}
    </Container>
  );
};
