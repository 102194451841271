import React, { useEffect, useState } from "react";
import BackgroundRouter from "../../background-router";
import _ from "lodash";
import { FabMulti, H6, Subtitle1 } from "../../../components";
import {
  Container,
  Paper,
  CircularProgress,
  List,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import { useFetch } from "../../../hooks";
import { ToastErro } from "../../../utils/utils";
import { DrawerDialog } from "../../../extra-components";
import { useSelector } from "react-redux";
import { useSet } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import PainelDeNotificacaoModal from "./painel-de-notificacao-modal";
import { selectPlanta } from "./painel-de-notificacao-seletores";
import PainelSuperior from "./painel-de-notificacao-painel-superior";
import ListaColaboradores from "./painel-de-notificacao-list-of-colabs";
import EnviarNotificacaoPorSetor from "./painel-de-notificacao-envio-por-setor";
import ListaItemSetor from "./painel-de-notificacao-item-setor";
import EnviarNotificacaoPorPlanta from "./painel-de-notificacao-send-notification-plant";
import { URL } from "./painel-de-notificacao-utils";
import { useTranslation } from "react-i18next";

function PainelDeNotificacao() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const { wait, setFetch, data, error } = useFetch(URL[0], "GET");
  const [filtrado, setFiltrado] = useState(null); // Determina qual filtro aplicar, notificados ou nao podem se notificar
  const [modal, setModal] = useState(null); // Modal para casos de envio de notificacoes

  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);
  // Chamada para solicitar todos os colaboradores
  useEffect(() => {
    setFetch({});
  }, [setFetch]);

  // Veja se tem que filtrar o array data.
  let filtro;
  if (!filtrado) {
    filtro = data;
  } else {
    filtro = _.filter(data, (val) => val.is_token_mobile === filtrado);
  }

  return (
    <BackgroundRouter>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => (
            <PainelDeNotificacaoModal
              fecharModal={() => setModal(null)}
              modal={modal}
            />
          )}
          fecharModal={() => setModal(null)}
        />
      )}
      <Container maxWidth={false}>
        <H6>{t("painel_de_notificacao.titlePage")}</H6>
        {wait ? (
          <CircularProgress />
        ) : data ? (
          isMobile ? (
            <RenderMobile
              setModal={setModal}
              data={data}
              filtro={filtro}
              setFiltrado={setFiltrado}
              filtrado={filtrado}
            />
          ) : (
            <RenderDesktop
              setModal={setModal}
              data={data}
              filtro={filtro}
              setFiltrado={setFiltrado}
              filtrado={filtrado}
            />
          )
        ) : null}
      </Container>
    </BackgroundRouter>
  );
}
//
const RenderDesktop = ({ data, filtro, setFiltrado, filtrado, setModal }) => {
  const { t } = useTranslation();
  const planta = useSelector(selectPlanta); // A planta do usuario logado
  const setor = _.union(_.map(data, (val) => val.setor));
  setor.sort();

  const [set, { toggle, has, reset }] = useSet(new Set([]));

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Paper sx={{ p: 1.5 }} elevation={4}>
          <PainelSuperior
            data={data}
            onClick={setFiltrado}
            isValidator={filtrado}
          />
          <ListaColaboradores data={filtro} setModal={setModal} />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper sx={{ p: 1.5 }} elevation={3}>
              <Subtitle1>
                {t("painel_de_notificacao.subtitleSendMessageBySector")}
              </Subtitle1>
              <AnimatePresence>
                {set.size > 0 && (
                  <motion.div
                    initial={{ scale: 0.01 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.2 }}
                    exit={{ scale: 0.01 }}
                    key="setores"
                  >
                    <EnviarNotificacaoPorSetor
                      key="setores"
                      setoresSelecionados={[...set]}
                      dados={data}
                      resetSelecionados={reset}
                      toggle={toggle}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              <List sx={{ height: "55vh", overflowY: "auto" }} disablePadding>
                {setor.map((ele, idx) => (
                  <ListaItemSetor
                    setor={ele}
                    key={idx}
                    has={has}
                    toggle={toggle}
                  />
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 1.5 }} elevation={4}>
              <EnviarNotificacaoPorPlanta planta={planta} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
//
const RenderMobile = ({ setModal, data, filtro, setFiltrado, filtrado }) => {
  const { t } = useTranslation();
  const planta = useSelector(selectPlanta); // A planta do usuario logado
  // representa os botoes de floating action buttom
  const listaFabs = [
    {
      icon: "Groups",
      title: t("painel_de_notificacao.titleSendNotificationBySector"),
      color: "primary",
      onClick: () => {
        const setor = _.union(_.map(data, (val) => val.setor));

        setModal({
          tipo: PainelDeNotificacaoModal.modal.SETOR,
          dados: {
            setor,
            dados: data,
          },
        });
      },
    },
    {
      icon: "PrecisionManufacturing",
      title: t("painel_de_notificacao.titleSendNotificationByPlant"),
      color: "primary",
      onClick: () => {
        setModal({
          tipo: PainelDeNotificacaoModal.modal.PLANTA,
          dados: {
            planta,
          },
        });
      },
    },
  ];

  return (
    <>
      <PainelSuperior
        data={data}
        onClick={setFiltrado}
        isValidator={filtrado}
      />
      <ListaColaboradores data={filtro} setModal={setModal} />
      {listaFabs?.length > 0 && <FabMulti fabs={listaFabs} color="primary" />}
    </>
  );
};

PainelDeNotificacao.rota = "/painel_de_notificacao";

export default PainelDeNotificacao;
