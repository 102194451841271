import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  galeriaDeFotosDelAlbum,
  galeriaDeFotosFecharDrawer,
} from "./galeria-de-fotos-actions";

// Componente para excluir album
const FormDelAlbum = ({ id_album }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Stack sx={{ p: 1 }} spacing={1}>
      <Typography variant="h6" align="center">
        {t("galeria_de_fotos.titleFormDelAlbum")}
      </Typography>
      <Typography variant="caption" sx={{ opacity: 0.7 }} align="center">
        {t("galeria_de_fotos.subjectFormDelAlbum")}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button
          sx={{ flex: 1 }}
          onClick={() => dispatch(galeriaDeFotosFecharDrawer())}
        >
          {t("galeria_de_fotos.labelBtnNo")}
        </Button>
        <Button
          sx={{ flex: 1 }}
          variant="contained"
          onClick={() =>
            dispatch(
              galeriaDeFotosDelAlbum({
                id_album,
              })
            )
          }
        >
          {t("galeria_de_fotos.labelBtnYes")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default FormDelAlbum;
