/**
 * Arquivo de traduções de strings de texto do sistema baseado no idioma informado.
 * Esta estrutura é padrão a todos os arquivos dentro desta pasta de deve seguir a
 * mesma sequencia de chave_valor dos demais.
 *
 * O padrão é link: { chave: valor }, sendo o "link" o nome da URL do menu em acesso
 * via lowercase.
 *
 * TRADUÇÃO ITA
 */

const translate = {
  components: {
    calendario: {
      sunday: "Domenica",
      monday: "Lunedì",
      tuesday: "Martedì",
      wednesday: "Mercoledì",
      thursday: "Giovedì",
      friday: "Venerdì",
      saturday: "Sabato",
      prev: "Indietro",
      next: "Avanti",
      beforeMonth: "Mese precedente",
      afterMonth: "Mese successivo",
      today: "Oggi",
    },
    filtro_por_periodo: {
      titleApplyFilter: "Applica filtro per i dati",
      fromToday: "da oggi.",
      lastDays: "degli ultimi",
      days: "giorni",
      today: "Oggi",
      perPeriod: "Per periodo",
      filterApplied: "Filtro applicato nel periodo",
      filterAppliedErrors: "Filtro applicato per errori per periodo definito",
      titleClearFilter: "Cancella filtro applicato",
      labelClearFilter: "Cancella filtro",
      labelFrom: "Da",
      labelTo: "A",
      errorDateFrom: "* Data da obbligatoria",
      errorDateTo: "* Data a obbligatoria",
      title: "Scegli tra le date da e a",
      subtitle:
        "Fai attenzione a non scegliere la data DA maggiore della A, altrimenti il tuo filtro sarà vuoto.",
    },
    filtro_avancado: {
      title: "Scegli il tipo di filtro da applicare",
    },
    row_paginate_template: {
      labelButtonAdd: "AGGIUNGI",
      titlePagination: "Clicca per cambiare tra le pagine",
      titleButtonOptions: "Clicca per visualizzare le opzioni",
      labelButtonOptions: "Opzioni",
      titlePage: "Modello di paginazione come record",
      titleButtonAdd: "Clicca per includere",
    },
    aniversariantes: {
      title: "Elenco di compleanni",
      titleCompany: "Compleanni dell'azienda",
      erroGetBirthDay: "Errore nel tentativo di recuperare i compleanni",
      altImg: "Immagine di compleanni",
      today: "Oggi",
      labelBtnNext: "Prossimi",
      greeting: "Fai gli auguri 🥳",
    },
    entrada_form: {
      labelBtnSend: "INVIA",
    },
    drawer_dialog: {
      title: "Clicca per chiudere",
      labelBtnClose: "CHIUDI",
    },
    fab_extend: {
      title: "Clicca per includere",
      text: "Aggiungi",
    },
  },

  lesson: {
    titleHeaderTable: "ELENCO MASTER DELLE LEZIONI APPRESE",
    nameAndEmail: "NOME / EMAIL",
    dateCreated: "DATA DI CREAZIONE",
    plant: "PLANT",
    pn: "NUMERO DI PARTE",
    descriptionPiece: "DESCRIZIONE DELLA PARTE",
    categoryAnomaly: "CATEGORIA DI ANOMALIA",
    anomalyFound: "ANOMALIA TROVATA",
    identifyLL: "IDENTIFICAZIONE LL",

    title: "MODULO LEZIONI APPRESE",
    labelButtonGoBack: "tornare",
    titleButtonBack: "Tornare la pagina Lezioni apprese",
    labelDownloadPDF: "Donwload in PDF",
    titleButtonDownloadPdf: "Clic per Donwload in PDF",
    placeholder: "Scrivilo...",
    labelImpact: "IMPATTO DI PROGETTO",
    labelPlant: "Stabilimento",
    labelPN: "PART NUMBER",
    labelProcessUnit: "UNITÀ DI PROCESSI",
    labelOperation: "OPERAZIONE",
    labelLine: "ISOLA",
    labelAreaAffected: "ZONA RICERCATO",
    labelDate: "DATA",
    labelDescAnomaly: "DESCRIZIONE DAL ANOMALIA",
    labelTitleEffect1: "QUALE É O SINTOMO / È FATTO DEL PROBLEMA ?",
    labelImageKO: "IMMAGINE KO",
    labelImageOK: "IMMAGINE OK",
    labelCauseRoot: "CAUSA RAIZ",
    labelTitleDescriptionProblem: "DESCRIZIONE DI PROBLEMA 5W2H",
    labelWhat: "What/ What happened?",
    labelWhy: "Why/ Why it is a problem? ",
    labelWhen: "When/ When did it happen ?",
    labelWhere: "Where/ Where has been detected ?",
    labelWho: "Who/ Who has detected it ?",
    labelHow: "How/ How has it been detected?",
    labelHowMany: "How many/ How many ?",
    labelTitleEffect: "Effetto",
    labelProblemImage: "PROBLEMA",
    labelSolutionImage: "SOLUZIONE",
    labelDescProblem: "DESCRIZIONE DEL PROBLEMA",
    labelDesSolution: "DESCRIZIONE DA SOLUZIONE",
    labelDrawingMathematicalImage: "PROGETTO MATEMATICA",
    labelFabricant: "FABRICANTE",
    labelTitleDocDefault: "AGGIORNAMENTO DEL DOCUMENTI STANDARD",
    labelTableTitleDesc: "DESCRIZIONE",
    labelTableTitleRevision: "NECESSARIO REVISIONE? - SÌ/NO",
    labelTableResponsible: "RESPONSABILE",
    labelTableData: "DATA",
    labelTableAccomplished: "COMPIUTO",
    labelObservation: "OSSERVAZIONE",
    labelCust: "CUSTO",
    labelBenef: "BENEFICIO",
    labelImageInsert: "accedere a Immagine Qui",
    labelBtnAddEffect: "Includere Efeito",
    labelBtnAddUpdateDoc: "CLIC QUI PER INSERIRE",
    titleRemoveImageUpload: "Clic per scegliere una imagem para upload",
    titleRemoveEffect: "Clic per rimuovere o Effetto ",
    titleFormAddTypeUpdate: "COMPILARE OS CAMPOS SOTTO",
    errorListUpdate: "* Campo obbligatorio",
    errorRevision: "* Campo obbligatorio",
    errorResponsible: "* Campo obbligatorio",
    errorDate: "* Campo obbligatorio",
    errorAccomplished: "* Campo obbligatorio",
    errorObservation: "* Minimo de 3 caracteres",
    labelBtnSend: "INVIARE",
    placeholderMoney: " 0,00",
    messageErrors: {
      idImpact: "* Scelta un impatto del progetto",
      plant: "* Scelta uno scaricare",
      idUnitProcess: "* Scelta una unità In processi.",
      data: "* Scelta uno data.",
      operacao: "* Scelta uno operazione",
      area: "* Scelta uno isola.",
      pn: "* Scelta uno Part Number",
      arvore: "* Scelta uno settore",
      anomaly: "* A anomalia deve essere informato",
      imageKO: "Immagine_ko",
      imageOK: "Immagine_ok",
      causeRoot: "* La causa fonte deve essere informato",
      what: '* A pergunta "Che cosa" deve ser informada',
      why: '* A pergunta "Perché" deve ser informada',
      when: '* A pergunta "chi" deve ser informada',
      where: '* A pergunta "Dove" deve ser informada',
      who: '* A pergunta "Chi" deve ser informada',
      how: '* A pergunta "Como" deve ser informada',
      how_many: '* A pergunta "Quanti" deve ser informada',
      imageProblem: "Immagine_problema",
      imageSolution: "Immagine_soluzione",
      descriptionProblem: "* A descrizione del problema ha bisogno informato",
      descriptionSolution:
        "* A descrizione della soluzione ha bisogno informato",
      imageDrawing: "Immagine_progetto",
      fabric: "* O produttore  ha bisogno informato",
      effect: "efeito_",
      updateDoc: "aggiornamento_documenti",
      coust: "* O custo ha bisogno essere informato",
      benef: "* O beneficio ha bisogno essere informato",
    },
  },
  ponto_eletronico: {
    title: "Registro de marcações",
    closeDrawer: "Fechar",
    btnEnviar: "Enviar",
    labelField: "Periodo",
    mobileFieldDay: "Dia",
    mobileFieldData: "Data",
    mobileFieldInput: "Entrada",
    mobileFieldOut: "Saída",
    mobileFieldView: "Ver",
    goBackEquip: "Retornar a equipe",
    titleUpdateCache: "Clique aqui para atualizar o cache do ponto eletrônico",
    resultPartial: "Resultados Parcial",
    headerOne: "DIA",
    headerTwo: "DATA_BATIDA",
    headerThree: "1E",
    headerFour: "1S",
    headerFive: "2E",
    headerSix: "2S",
    headerSeven: "EXTRA",
    headerEight: "ADICIONAL",
    headerNine: "FALTA",
    headerTen: "ABONO",
    headerEleven: "SITUACAO",
  },
  contra_cheque: {
    titulo: "Busta paga",
    campoAnos: "Anni",
    campoMeses: "Mesi",
    contraChequeTitulo: "Informazioni personali",
    contraChequeMatricula: "Matricola",
    contraChequeNome: "Nome",
    contraChequeFuncao: "Funzione",
    contraChequeAdmissao: "Data di assunzione",
    contraChequeVencimentos: "Stipendi",
    contraChequeDescontos: "Sconti",
    contraChequeLiquido: "Netto",
    contraChequeBaseDeCalculo: "Base di calcolo",
    contraChequeCod: "Cod",
    contraChequeDescricao: "Descrizione",
    contraChequeRef: "Ref",
    contraChequeValor: "Valore",
    btnBaixar: "Scarica",
    btnBaixarTitulo: "Genera PDF di questo busta paga",
    cnpj: "CNPJ",
    endereco: "Indirizzo",
    reciboSalario: "RICEVUTA DI PAGAMENTO DEL SALARIO",
    codigo: "Codice",
    descricao: "Descrizione",
    referencia: "Riferimento",
    vencimentos: "Stipendi",
    descontos: "Sconti",
    oneMonth: "GEN",
    twoMonth: "FEB",
    threeMonth: "MAR",
    fourMonth: "APR",
    fiveMonth: "MAG",
    sixMonth: "GIU",
    sevenMonth: "LUG",
    eightMonth: "AGO",
    nineMonth: "SET",
    tenMonth: "OTT",
    elevenMonth: "NOV",
    twelveMonth: "DIC",
    thirteenMonth: "13°",
    updateCache: "Aggiorna cache del foglio aperto",
  },
  galeria_de_fotos: {
    titleBtnAdd: "Aggiungi un nuovo album",
    labelBtnAddAlbum: "Nuovo Album",
    labelBtnAddPhoto: "Aggiungi Foto",
    titleEditAlbum: "Modifica soggetto e titolo dell'album",
    titleDelAlbum: "Elimina album, include foto, mi piace e commenti",
    labelEditAlbum: "Modifica Album",
    labelDelAlbum: "Elimina Album",
    titleViewOptions: "Clicca per vedere le opzioni",
    titleViewPhotosAlbum: "Clicca qui per vedere le foto",
    postedOn: "Pubblicato il",
    titleTotalOf: "Totale di",
    titleInAlbum: "foto nell'album",
    titleAlbumVisibleTo: "Album visibile a",
    titleFormAddAlbum: "Modulo per la registrazione dell'album",
    descriptionFormAddAlbum:
      "Compila i campi per l'intenzione di creare un album di foto descrivendo un titolo, un soggetto e la visibilità dell'album",
    labelFormTitleAlbum: "* Titolo",
    placeholderTitleAlbum: "Descrivi il titolo qui",
    errorTitleAlbum: "* Minimo di 3 caratteri",
    labelFormSubjectAlbum: "* Soggetto",
    placeholderSubjectAlbum: "Descrivi il soggetto qui",
    errorSubjectAlbum: "* Minimo di 3 caratteri",
    labelPlantAlbum: "* Album visibile a",
    errorPlantAlbum: "Scegli almeno una pianta",
    labelBtnSendAlbum: "SALVA",
    titleFormDelAlbum: "Sei sicuro di voler eliminare?",
    subjectFormDelAlbum:
      "Se scegli di eliminare, perderai foto, mi piace e commenti su questo album.",
    labelBtnNo: "No",
    labelBtnYes: "Sì",
    listOfPhotos: "Foto dell'album",
    titleImageOfAlbum: "Immagine dell'album",
    titleTotalOfLikes: "Totale di mi piace",
    titleClickViewLegend: "Clicca per modificare la didascalia della foto",
    titleClickDelPhoto: "Clicca per modificare la didascalia della foto",
    titleTotalOfComments: "Totale di commenti",
    altPhoto: "Immagine dell'album",
    titlePhotoOfAlbum: "Foto dell'album",
    goBack: "Indietro",
    downloadPhoto: "Scarica",
    closePhoto: "CHIUDI",
    nextPhoto: "AVANTI",
    thisFile: "Questo file",
    notAccept: "non è un tipo accettabile",
    labelFormFilesPhoto:
      "Scegli le foto che saranno inserite per far parte di questo album.",
    labelFormFile: "Foto",
    labelBtnSendPhoto: "INVIA",
    listOfComments: "Lista dei commenti",
    confirmationDelComment: "Vuoi davvero eliminare questo commento?",
    placeholderAddComment: "Scrivi il tuo commento",
    listOfLikes: "Lista dei Mi piace",
    titleClickChooseLike: "Clicca per scegliere il mi piace",
    youLike: "Ti piace",
    closeLike: "Chiudi",
    like: "Mi piace",
    titleFormUpdLegend: "Modulo per modificare la didascalia",
    writeNewLegendPhoto: "Scrivi la nuova didascalia per la foto",
    labelFormLegend: "* Didascalia",
    placeholderFormLegend: "Descrivi la didascalia qui",
    errorLegend: "Minimo di 3 caratteri",
    labelBtnSendLegend: "Minimo di 3 caratteri",
    titleFormDelPhoto: "Sei sicuro di voler eliminare?",
    subtitleFormDelPhoto:
      "Se scegli di eliminare, perderai tutti i mi piace e i commenti su questa foto",
  },
  feed_rh: {
    titleBirthDayCompany: "Compleanni aziendali",
    errStatusNotOK: "Errore nel tentativo di ottenere lo stato emotivo",
    errUnknown: "ERRORE SCONOSCIUTO",
    titleEmotionalClimate: "Clima aziendale",
    descriptionEmotionalClimate: "Come ti senti?",
    today: "Oggi",
    btnNewPost: "NUOVO POST",
    feedNotFound: "Nessun feed creato fino ad ora.",
    btnEdit: "Modifica",
    btnDelete: "Elimina",
    titlePostedOn: "Pubblicato il:",
    viewLikes: "Vedi mi piace",
    notComments: "nessun commento",
    notLikes: "nessun mi piace",
    viewMinus: "Vedi meno",
    viewMore: "Vedi di più",
    postedOn: "Pubblicato il ",
    titleWhenComments: "Quanti commenti",
    altImageFeed: "Immagine del logo nel feed",
    comment: "commento",
    comments: "commenti",
    titleLikedYou: "Ti è piaciuto questo",
    titleLike: "Mi piace il post",
    like: "mi piace",
    likes: "mi piace",
    animationLike: "Mi piace",
    animationHappy: "Felice",
    animationZen: "Entusiasta",
    animationLove: "Amore",
    animationConfused: "Confuso",
    animationSad: "Triste",
    animationUngry: "Arrabbiato",
    titleFeedLikes: "Quantità di mi piace per tipo",
    formDelTitleFeedDel: "Sei sicuro di questo?",
    formDelSubtitleFeedDel:
      "Se scegli di confermare l'eliminazione del feed, perderai tutti i commenti e i mi piace su questo feed.",
    formAddUpdLabelTitle: "Titolo",
    formAddUpdLabelSubject: "Soggetto",
    formAddUpdLabelObservation: "Osservazione",
    formAddUpdLabelFile: "File",
    formAddUpdLabelPlant: "Feed visibile per",
    formAddUpdPlaceholderTitle: "Descrivi il titolo qui",
    formAddUpdPlaceholderSubject: "Descrivi il soggetto qui",
    formAddUpdPlaceholderObservation: "Descrivi l'osservazione qui",
    formAddUpdPlaceholderFile: "File",
    formAddUpdPlaceholderPlant: "Feed visibile per",
    formAddUpdErrorTitle: "* Il campo del titolo deve avere almeno 2 caratteri",
    formAddUpdErrorSubject: "* Il campo soggetto deve avere almeno 2 caratteri",
    formAddUpdErrorFile: "* Il file non è stato inviato",
    formAddUpdErrorPlant: "* Scegli almeno un'unità da allegare",
  },
  ouvidoria_view: {
    title: "Segreteria",
    id: "ID",
    total: "Totale segreterie",
    subject: "Oggetto",
    situation: "Situazione",
    dateCreated: "Data di creazione",
    interaction: "Interazione: ",
    labelComponentPagination: "Clicca per alternare tra le pagine",
    themePanel: "Temi",
    typePanel: "Tipi",
    titleBtnAdd: "Clicca per aprire una nuova segreteria",
    titleBtnSearch: "Clicca per cercare una segreteria per Token",
    labelBtnAdd: "SEGRETERIA",
    labelBtnSearch: "TOKEN",
    noData: "Non sono state trovate segreterie",
    noDataInteraction: "Segreteria non trovata",
    titleAdd: "Apri segreteria",
    description: "Descrizione",
    plant: "Pianta",
    attach: "Allegato",
    placeholderSubject: "Scrivi l'oggetto",
    placeholderText: "Più dettagli sulla segreteria",
    errorId: "* Scegli almeno un ID",
    errorType: "* Necessario scegliere un tipo",
    errorTheme: "* Necessario scegliere un tema",
    errorSubject: "* Minimo di 3 caratteri",
    errorDescription: "* Descrivi la tua richiesta",
    errorSituation: "* Scegli il nuovo status",
    detailsOmbudsman: "Dettagli",
    titleBtnGoBack: "Torna alla schermata delle segreterie",
    labelBtnGoBack: "Ritorna",
    titleInteraction: "Interagire",
    labelBtnInteraction: "INTERAGIRE",
    ombudsman: "Segreteria: ",
    titleTab1: "Storico",
    titleTab2: "Dettagli",
    placeholderSearchToken: "Token",
    titleSearchToken: "Ricerca per Token",
    titleOmbudsmanCopyToken: "Segreteria anonima creata",
    descriptionToken:
      "Se desideri seguire questa segreteria clicca sul token per copiarlo.",
    tokenCopySuccess: "Token copiato con successo",
    labelBtnFilter: "Filtro Avanzato",
    titleBtnFilter: "Clicca per effettuare un filtro avanzato.",
    titleFilter: "Seleziona il filtro",
    titleFilterId: "Clicca per selezionare il filtro per ID",
    titleFilterTheme: "Clicca per selezionare il filtro per tema",
    titleFilterType: "Clicca per selezionare il filtro per tipo",
    titleFilterSituation: "Clicca per selezionare il filtro per status",
    statusOpen: "Aperto",
    statusClosed: "Chiuso",
    statusMonitoring: "In Analisi",
    ombudsmanStatus: "Stato dell'Ombudsman",
    ombudsmanNumber: "Numero dell'Ombudsman",
    createdAt: "Creato il",
    lastInteraction: "Ultima interazione",
    type: "Tipi",
    theme: "Temi",
    owner: "Proprietario",
    situacao: "Situazione",
  },
  colaboradores: {
    pageTitle: "Manutenzione collaboratori",
    fabTitle: "Aggiungi un nuovo collaboratore",
    fabLabel: "AGGIUNGI",
    labelMat: "Matricola",
    placeholderMat: "000000",
    labelThree: "ALBERO",
    placeholderThree: "Scegli l'albero",
    labelIsManager: "È un manager?",
    errorMat: "* Campo obbligatorio",
    errorThree: "* Campo obbligatorio",
    formAddTitle: "Registrazione collaboratore",
    formAddDescription:
      "Inserisci la matricola del collaboratore, il settore e se è un manager.",
    filterLabelManager: "Manager",
    filterLabelEligible: "Eleggibile",
    filterLabelActive: "Attivo",
    filterLabelInactive: "Inattivo",
    labelTotal: "Totale",
    optionsLabel: "Opzioni",
    optionLabelMenu: "CAMBIA MENU(S)",
    optionLabelManager: "MANAGER X SITUAZIONE X ELEGGIBILE",
    optionLabelInfoPerson: "CAMBIA INFORMAZIONI PERSONALI",
    dateOfContract: "Assunzione",
    labelManagerOfThree: "Manager dell'Albero",
    colabItemTitle: "Quantità di menu assegnati",
    menuOfAccessTitle: "Lista dei menu di accesso",
    menuOfAccessDescription:
      "Elenco di tutti i menu disponibili che possono essere (o sono) assegnati al collaboratore",
    placeholderFilterColab: "Filtro per i menu disponibili",
    labelActiveInactiveAll: "Attiva / Disattiva TUTTI",
    managerActiveEligibleTitle: "Manager X Attivo X Eleggibile",
    managerActiveEligibleDescription:
      "Attiva o disattiva il collaboratore per essere un manager, attiva o disattiva per essere eleggibile o se è autorizzato a accedere al sistema.",
    labelEnableDisable: "Abilita / Disabilita",
    labelPasswdTitle: "Password",
    labelPasswdDescription: "Inserisci una nuova password per il collaboratore",
    labelPasswdPlaceholder: "Inserisci la password",
    errorPasswd: "* La password deve avere almeno 6 caratteri",
    labelBtnSavePasswd: "Salva Nuova Password",
    labelThreeTitle: "Albero",
    labelThreeDescription: "Cambia l'albero del collaboratore se desiderato",
    labelThreePlaceholder: "Scegli l'albero",
    labelBtnSaveThree: "Aggiorna Albero",
    labelEmailTitle: "E-mail",
    labelEmailDescription: "Cambia o assegna una nuova e-mail al collaboratore",
    labelEmailPlaceholder: "Es: nome@email.com",
    errorEmail: "* Es: nome@email.com",
    labelBtnSaveEmail: "Cambia Email",
    labelName: "Nome",
    placeholderName: "Nome del collaboratore",
    errorName: "* Campo obbligatorio",
    labelPlant: "Pianta",
    placeholderPlant: "Scegli la pianta",
    errorPlant: "* Campo obbligatorio",
    labelFunction: "Funzione",
    placeholderFunction: "Scegli la funzione del collaboratore",
    errorFunction: "* Campo obbligatorio",
    labelDateAdmission: "Data di ammissione",
    placeholderDateAdmission: "Scegli la data di ammissione",
    errorDateAdmission: "* Campo obbligatorio",
    placeholderPassword: "Inserisci la password",
    labelPassword: "Password",
    errorPassword: "* La password deve avere almeno 6 caratteri",
    addColabTab1: "INSERIMENTO AUTOMATICO",
    addColabTab2: "INSERIMENTO MANUALE",
    formAddTitleManual: "Inserimento manuale del collaboratore",
    formAddDescriptionManual:
      "Inserisci i dati del collaboratore per l'inserimento manuale.",
  },
  minha_equipe: {
    noDataTitle1: "Scegli una delle opzioni",
    noDataTitle2: "laterali",
    noDataTitle3: "del pannello per visualizzare i dati",
    placeholderSearchEmployee: "Inserisci il nome o la posizione",
    optionsPanelPointTitle: "Punto elettronico",
    optionsPanelPointDescription: "Vedi il punto elettronico del team",
    optionsPanelBankOfHoursTitle: "Banca delle ore",
    optionsPanelBankOfHoursDescription: "Vedi la banca delle ore del team",
    optionsPanelBankOfHoursGeneralTitle: "Banca delle ore generale",
    optionsPanelBankOfHoursGeneralDescription:
      "Vedi la banca delle ore generale",
    optionsPanelPerformanceEvaluationTitle: "Valutazione delle prestazioni",
    optionsPanelPerformanceEvaluationDescription: "",
    myEquipBankOfHoursGeneralPageTitle: "Banca delle ore generale",
    myEquipBankOfHoursGeneralCredit: "Credito",
    myEquipBankOfHoursGeneralDebit: "Debito",
    myEquipBankOfHoursGeneralBalance: "Saldo",
    myEquipBankOfHoursPageTitle: "Banca delle ore del team",
    myEquipBankOfHoursFinalBalance: "SALDO FINALE",
    myEquipPointPageTitle: "Punto elettronico del team",
    myEquipPointLabelPeriod: "Periodo del punto",
    myEquipPointTotalResultPartial: "Risultati parziali",
    myEquipQuestionTitle: "Questionario di competenze comportamentali",
    myEquipQuestionView:
      "Clicca su un collaboratore per visualizzare la valutazione delle prestazioni.",
    myEquipQuestionErrorName: "* È necessario compilare",
    myEquipQuestionObsEvaluatorLabel: "Osservazione del valutatore",
    myEquipQuestionObsEvaluatorPlaceholder:
      "Inserisci le osservazioni del valutatore",
    myEquipQuestionObsEvaluatedLabel: "Osservazione del valutato",
    myEquipQuestionObsEvaluatedPlaceholder:
      "Inserisci le osservazioni del valutato",
    myEquipQuestionEvaluatorError: "* Inserisci dettagli sul valutatore",
    myEquipQuestionEvaluatedError: "* Inserisci dettagli sul valutato",
    myEquipQuestionErrorUnknown: "ERRORE SCONOSCIUTO",
    myEquipQuestionTitleObsQuestion: "Osservazioni sul questionario",
    myEquipQuestionEvaluationResponseTitle: "Valutazioni risposte",
    myEquipQuestionDownloadPdfTitle:
      "Clicca sul pulsante per scaricare la valutazione in PDF",
    myEquipQuestionDownloadPdfBtnLabel: "Scarica PDF",
    myEquipQuestionHeaderResponseName: "Nome",
    myEquipQuestionHeaderResponseMat: "Matricola",
    myEquipQuestionHeaderResponseFunction: "Funzione",
    myEquipQuestionHeaderResponseEvaluator: "Valutatore",
    myEquipQuestionHeaderResponseDateOfEvaluation: "Data della valutazione",
    myEquipQuestionHeaderResponsePageTitle: "Valutazione delle prestazioni",
    myEquipQuestionViewEvaluationResponsePageTitle: "Osservazioni",
    myEquipQuestionEvaluationResponseAskBody: "Risposta",
    myEquipQuestionEvaluationResponseObsEvaluatorTitle:
      "Risposta del valutatore",
    myEquipQuestionEvaluationResponseObsEvaluatedTitle: "Risposta del valutato",
  },
  cadastro_projetos: {
    titlePage: "Registrazione di progetti",
    title: "Titolo",
    includeProject: "Includi progetto",
    titleAdvancedFilter: "Filtri di ricerca avanzati",
    advancedFilter: "Filtro avanzato",
    titleILeader: "Elenca i progetti di cui sono leader",
    ILeader: "Sono leader",
    titleIMember: "Elenca i progetti di cui sono membro",
    IMember: "Sono membro",
    titleMyDeport: "Elenca i progetti del mio dipartimento",
    myDeport: "Il mio dipartimento",
    valueNotFound: "Non trovato",
    labelSituation: "Situazione",
    labelCreatorProject: "Creatore del progetto",
    labelLeader: "Leader",
    labelCode: "Codice",
    labelOpeningDate: "Data di apertura",
    labelExpectedDate: "Data prevista",
    labelDriver: "Guidatore",
    labelDescription: "Descrizione",
    labelExpansion: "Espansione",
    labelArea: "Area",
    labelOperation: "Operazione",
    labelLoss: "Perdita",
    labelLossType: "Tipo di perdita",
    labelProjectType: "Tipo di progetto",
    labelPillar: "Pilastro",
    labelIDMatrixDQA: "ID Matrice D / QA",
    labelSaving: "Risparmio",
    closeButton: "CHIUDI",
    modelsTitle: "Modelli",
    membersTitle: "Membri",
    attachmentsTitle: "Allegati",
    attach: "Allegato",
    wait: "Aspetta ...",
    dateHistory: "Data della storia",
    mat: "Matricola",
    fromSituation: "Dalla situazione",
    toSituation: "Alla situazione",
    observation: "Osservazione",
    titleJustify: "Giustificazioni definite nel progetto",
    subtitle:
      "Di seguito le giustificazioni che sono state definite nel progetto",
    descriptionLabel: "Descrizione",
    delayFactorLabel: "Fattore di ritardo",
    actionDescriptionLabel: "Descrizione dell'azione",
    executedActionLabel: "Azione eseguita",
    newExpectedDateLabel: "Nuova data prevista",
    justificationDateLabel: "Data della giustificazione",
    plant: "Pianta",
    leaderProject: "Leader del progetto",
    descriptionChecklist: "Descrizione della checklist",
    matEvaluator: "Matricola del valutatore",
    situation: "Situazione",
    descriptionProject: "Descrizione del progetto",
    dateAvailable: "Data di valutazione",
    overvallGrade: "Voto generale",
    responseChecklist: "Risposte della checklist",
    titleChecklist: "Titolo:",
    description: "Descrizione:",
    response: "Risposta:",
    comment: "Commento:",
    approved: "Approvato",
    reproved: "Respinto",
    pending: "In attesa",
    open: "Aperto",
    fi: "Validazione FI",
    cd: "Validazione CD",
    canceled: "Cancellato",
    justify: "Giustificare",
    seeJustify: "Vedi giustificazione",
    seeChecklist: "Vedi checklist",
    seeValuesCD: "Valori del CD",
    changeMembers: "Cambia membro(i)",
    returnCD: "Ritorna alla validazione CD",
    closed: "Chiuso",
    clearFilter: "Cancella filtro",
    titleCard: "Clicca per cancellare il filtro",
    days: "giorno(i)",
    leader: "Leader",
    late: "In ritardo da",
    members: "Membri",
    code: "Codice",
    area: "Area",
    openListProject: "Apertura",
    previst: "Previsto",
    newDatePrevist: "Nuova data prevista",
    labelOpen: "Apertura",
    labelNewDatePrevist: "Nuova data prevista",
    viewHist: "Visualizza storico",
    btnOptions: "Opzioni",
    alterStatus: "Cambio di stato",
    descriptionUpdStatus: "Descrivi (opzionale) perché stai cambiando lo stato",
    observationPlaceholder: "Un'osservazione da fare",
    attachFI: "Allegato FI (obbligatorio)",
    attachCD: "Allegato CD",
    attachLabel: "Allegato",
    labelDateClosedLabel: "Data di chiusura",
    placeholderDateClosed: "Definisci la data di chiusura",
    btnSend: "INVIA",
    errorAttachFI: "Per sottoporre la validazione FI è obbligatorio l'allegato",
    errorDateClosed: "La nuova data di chiusura deve essere informata",
    errorNoLimit: "Senza limite",
    formDateSaving: "Formulario dati Saving",
    valBenefic: "Valore del beneficio",
    valCust: "Valore del costo",
    valBC: "Valore BC",
    valSaving: "Valore Saving",
    typeSaving: "Tipo di Saving",
    typeBC: "Tipo BC",
    fieldRequired: "Campo obbligatorio",
    register: "REGISTRA",
    chooseChecklist: "Scegli la checklist",
    chooseChecklistPlaceholder: "Scegli una checklist",
    responseChecklistInfo: "RISPOSTA",
    commentPlaceholder: "Commenta la tua risposta",
    optionError: "Scegli un'opzione",
    commentError: "Non deve essere vuoto",
    titleFilterAdvanced: "FILTRO APPLICATO",
    titleCancelMember: "Annulla l'inclusione del membro",
    titleRemoveMember: "Rimuovi il membro dal progetto",
    titleAlterMember: "Modifica dei membri del progetto",
    projectMembers: "Membri del progetto",
    newMember: "Nuovo membro",
    membersParticipants: "Membri partecipanti",
    save: "SALVA",
    addMember: "AGGIUNGI MEMBRO",
    collaborator: "Collaboratore",
    cancel: "Annulla l'inclusione del membro",
    chooseCollaborator: "Si prega di scegliere il collaboratore",
    defineParticipation: "Mancano {0} % di partecipazione da definire",
    removeMember: "Rimuovi il membro dal progetto",
    changeProjectMembers: "Cambio dei membri del progetto",
    participantMembers: "Membri partecipanti",
    error100Percent:
      "100% di partecipazione raggiunta. Impossibile includere membri",
    errorBefore100Percent1: "Mancano ",
    errorBefore100Percent2: " % di partecipazione da definire",
    pilar: "Pilastro",
    sector: "Settore",
    lateFilterAdvanced: "In ritardo",
    typeOfProject: "Tipo di progetto",
    applyFilter: "Applica filtro",
    errorProjectCode: "È necessario scegliere almeno 1 codice progetto",
    errorPlant: "È necessario scegliere almeno 1 impianto",
    errorPilar: "È necessario scegliere almeno 1 pilastro",
    errorSector: "È necessario scegliere almeno 1 settore",
    errorLate: "È necessario scegliere almeno 1 settore",
    errorTypeOfProject: "È necessario scegliere almeno 1 tipo di progetto",
    descricao: "Descrizione",
    expansao: "È un progetto di espansione?",
    placeholderDescription: "Inserisci una descrizione",
    errorDateOpen: "Formato GG/MM/AAAA",
    errorDirection: "Scegli un direttore",
    errorDescription1: "Non deve essere vuoto (massimo ",
    errorDescription2: " caratteri)",
    errorExpansion: "Scegli un'opzione",
    errorArea: "Scegli un'area",
    errorModel: "Scegli almeno un modello",
    errorOperation: "Scegli un'operazione",
    errorLose: "Scegli una perdita",
    errorTypeLose: "Scegli un'opzione",
    errorTypeProject: "Scegli un'opzione",
    errorMatriz: "Non deve essere vuoto",
    errorSaving: "Inserisci nel formato 0,00",
    errorLider: "Scegli un'opzione",
    errorMemberNull: "🤔 Compila il membro",
    errorPercentNot100: "Il totale delle percentuali è inferiore al 100%",
    errorPercentMore100: "Il totale delle percentuali è superiore al 100%",
    errorNotLeader: "🤔 Inserisci il leader del progetto.",
    errorMemberLast: "🤔 Compila l'ultimo membro incluso.",
    errorTotal100: "100% di partecipazione raggiunta",
    btnClose: "CHIUDI",
    btnSave: "Salva",
    titleLaunchProject: "Lancio del progetto",
    labelDateOpen: "Data di apertura",
    labelDirection: "Direttore",
    labelExpansionProject: "È un progetto di espansione ?",
    labelModel: "Modelli",
    labelLose: "Perdita",
    labelTypeLose: "Tipo di perdita",
    labelTypeOfProject: "Tipo di progetto",
    labelPilar: "Pilastro",
    labelMatriz: "ID Matrice D / QA",
    labelSavingAdd: "Saving previsto",
    labelLeaderAdd: "Leader del progetto",
    labelMember: "Membro",
    labelPercent: "Partecipazione del membro",
    labelAnexo: "Allegato(i)",
    labelMemberNumber: "Membro",
    labelPercentMember: "Partecipazione del membro",
    labelSavingInfo:
      "Attenzione inserisci un valore di saving previsto, se non lo sai digita il numero 0,00",
    btnNewMember: "Nuovo Membro",
    optYes: "Sì",
    optNo: "No",
    optCronic: "Cronico",
    optExpordic: "Sporadico",
    titleRemoveMemberAdd: "Rimuovi membro",
    titleFormAdd: "Modulo di giustificazione",
    descriptionAddJustify: "Descrivi i dettagli del ritardo del progetto",
    descricao_da_acao: "Descrizione della soluzione",
    fator_atraso: "Fattore di ritardo",
    acao: "Azione",
    registrar: "Registrare",
    placeholderDescriptionAddJustify: "Descrivi il motivo del ritardo qui",
    placeholderDescriptionAction:
      "Descrivi l'azione per non ricorrere a questo fattore di ritardo",
    errorDescription: "Minimo di 3 caratteri",
    errorDescriptionAction: "Minimo di 3 caratteri",
    errorFactor: "Scegli almeno un fattore",
    errorAction: "Scegli un'azione",
    pointTitle: "Punteggio raggiunto",
    considerAcceptCd:
      "Consideri accettabile per avanzare il progetto la VALIDAZIONE CD ?",
    btnResponseAfter: "RISPONDI DOPO",
    btnReproved: "RESPINTO",
    btnApproved: "APPROVATO",
  },
  cartao_seguranca: {
    errorOpenDate: "* Deve essere compilato",
    errorTypeCard: "* Deve essere scelto uno",
    errorCategory: "* Indica la categoria",
    errorDescriptionCard:
      "* Non deve essere vuoto e non deve superare i 230 caratteri",
    errorMat: "* Scegli una matricola",
    errorOperation: "* Indica l'operazione o N/A",
    errorTurn: "* Indica il turno",
    errorArea: "* Indica un'area",
    errorModel: "* Indica il modello della carta",
    labelTypeCard: "Scegli il tipo di carta",
    nameLeader: "NOME LEADER",
    mat: "MATRICOLA",
    labelArea: "AREA",
    labelModel: "MODELLO",
    labelCategory: "CATEGORIA",
    labelOperation: "OPERAZIONE",
    labelTurn: "TURNO",
    labelDateOpen: "DATA DI APERTURA",
    labelDescription: "DESCRIZIONE",
    placeholderDescription: "Descrivi il problema...",
    labelAttachment: "ALLEGATO(I)",
    textBtnSave: "SALVA",
    titleFormJustify: "Giustificazione / Osservazione",
    labelJustify: "* Giustificazione",
    placeholderJustify: "Inserisci la tua giustificazione...",
    errorMinChar: "Minimo di 3 caratteri",
    textBtnReprove: "RIFIUTA",
    textBtnApprove: "APPROVA",
    titleMoveToAnalitycs: "Sposta per analisi",
    subtitleMoveToAnalitycs:
      "Compila i campi del modulo per determinare la causa radice e l'azione prevista.",
    labelRootCause: "Causa radice",
    placeholderRootCause: "Inserisci la causa radice del problema...",
    labelAction: "Azione prevista",
    placeholderAction: "Inserisci l'azione prevista per risolvere il problema",
    labelDatePrev: "Data prevista",
    labelResponsible: "Responsabile",
    titleJustifyAndObservation: "Giustificazione / Osservazione",
    subtitleJustifyAndObservation:
      "Decidi se la carta deve passare allo stato Aperto o essere Annullata.",
    textBtnCancel: "ANNULLA",
    textBtnValidate: "VALIDA",
    attachment: "Allegato",
    titleCompleteEnvironment: "VALIDAZIONE DELL'AMBIENTE",
    titleCompleteHealth: "VALIDAZIONE DELLA SALUTE",
    titleCompleteSafety: "VALIDAZIONE DELLA SICUREZZA",
    titleCompleteValidation: "VALIDAZIONE DEL",
    card: "CARTA",
    date: "DATA",
    identify: "IDENTIFICAZIONE",
    name: "NOME",
    leaderName: "NOME LEADER",
    areaAndTurn: "AREA/TURNI",
    descriptionProblem: "DESCRIZIONE PROBLEMA",
    local: "LOCALITÀ",
    model: "MODELLO",
    activity: "ATTIVITÀ",
    operation: "OPERAZIONE",
    description: "DESCRIZIONE",
    justifyAndObservation: "GIUSTIFICAZIONE / OSSERVAZIONE",
    planType: "PIANO DI AZIONE (SOLUZIONE)",
    rootCause: "CAUSA RADICE",
    actions: "AZIONI PREVISTE",
    dateInclude: "DATA DI INCLUSIONE",
    datePreview: "DATA PREVISTA",
    analyticsAndEfficiency: "ANALISI DELL'EFFICACIA",
    statusInclude: "Incluso (Da validare)",
    statusCancel: "Annullato",
    statusOpen: "Aperto",
    statusAnalisys: "In analisi",
    statusClosed: "Chiuso",
    statusOpenTitle: "Carta con stato aperto",
    statusIncludeTitle: "Carta con stato incluso da validare",
    statusCancelTitle: "Carta con stato annullato",
    statusAnalisysTitle: "Carta con stato in analisi",
    statusClosedTitle: "Carta con stato chiuso",
    searchTitleLeader: "Ricerca per leader",
    searchTitleCode: "Ricerca per codice",
    searchTitleTurn: "Ricerca per turno",
    searchTitleArea: "Ricerca per area",
    titlePage: "Carta di Sicurezza",
    textCardTypeSecurity: "Sicurezza",
    textCardTypeHealth: "Salute",
    textCardTypeEnvironmental: "Ambientale",
    titleFabAdd: "Aggiungi nuova carta",
    titleButtonCleanFilter: "Pulisci filtro di stato applicato",
    textButtonCleanFilter: "Pulisci filtro",
    textOptionViewCard: "Visualizza carta",
    textOptionValidateReject: "Valida / Rifiuta",
    textOptionSendAnalysis: "Invia per analisi",
    textOptionJustify: "Giustifica",
    textOptions: "Opzioni",
    textType: "Tipo",
    textLeader: "Leader",
    textDescription: "Descrizione",
    textOpenDate: "Data apertura",
    textFabAdd: "Aggiungi",
    view: "Visualizza",
    front: "Fronte",
    back: "Retro",
  },
  helpdesk: {
    titleMoreOptions: "Clicca per vedere le altre opzioni.",
    titleInfoRequester: "Tutti i ticket aperti da questo richiedente",
    placeholderGetRequestTicket: "Inserisci il nome",
    labelFilterAgent: "Sono un agente",
    titleFilterAgent: "Mostra solo i ticket in cui sono l'agente",
    labelFilterRequester: "Richiedenti",
    titleFilterRequester: "Determina da quale richiedente vuoi vedere i ticket",
    labelMaintenanceSubjectSuperAgent: "MANUTENZIONE ARGOMENTO",
    labelMaintenanceStatusSuperAgent: "MANUTENZIONE STATO",
    labelMaintenanceCriticalitySuperAgent: "MANUTENZIONE CRITICITÀ",
    labelBtnNewTicket: "NUOVO TICKET",
    titleSubnects: "Argomenti",
    titleStatus: "Stato",
    titleAnimationNoData: "Non ci sono ticket nel filtro specificato",
    titleComponentPagination: "Clicca per passare tra le pagine",
    titleFieldId: "ID",
    titleFieldPlant: "Pianta",
    titleFieldRequester: "Richiedente",
    titleFieldCriticality: "Criticità",
    titleFieldAgent: "Agente",
    titleFieldStatus: "Stato",
    titleFieldTitle: "Titolo",
    titleFieldLastInteraction: "Interazione / Scadenza",
    titleBtnOrderTicket: "Clicca per ordinare.",
    titleTotalTickets: "Totale dei ticket creati",
    titleBtnNewTicket: "Clicca per aprire un nuovo ticket",
    titleTicket: "Clicca per vedere i dettagli del ticket",
    titleTicketNumber: "Ticket N°",
    titleTicketNotAssigned: "Non assegnato",
    titleSubject: "Argomento",
    titleInteraction: "Interazione",
    titlePlant: "Pianta",
    titleAgent: "Agente",
    titleIndicatorLate: "Questo ticket è in ritardo (la scadenza è passata)",
    labelIndicatorLate: "In ritardo",
    titleBtnDelete: "Clicca per eliminare lo stato",
    titleBtnMove: "Clicca per definire a quale stato questo può portare",
    title: "Manutenzione dello stato",
    subtitle:
      "Crea, modifica, elimina e attiva/disattiva gli stati utilizzati nell'helpdesk.",
    headerName: "Stato",
    headerAuthorized: "Autorizzato a interagire",
    headerColor: "Colore",
    headerActive: "Situazione",
    headerDelete: "Elimina",
    headerMove: "Da / A",
    labelActive: "Attivo",
    labelInactive: "Inattivo",
    labelAgent: "Agente",
    labelRequester: "Richiedente",
    titleBtnAdd: "Includi",
    titleFromTo: "Definizione dello stato di destinazione",
    subtitleFromTo:
      "Scegli le destinazioni in cui lo stato che è a sinistra può andare quando deve essere spostato.",
    canInteract: " può interagire in questo stato.",
    labelBtn: "SALVA",
    fromStatus: "HELPDESK IN QUESTO STATO",
    toStatus: "PUÒ ANDARE A QUESTI STATI",
    titleDel: "Eliminazione dello stato",
    subtitleDel: "Vuoi davvero eliminare lo stato?",
    titleAddStatus: "Aggiungi stato",
    labelName: "Nome dello stato",
    labelAuthorized: "Autorizzato a interagire",
    labelSituation: "Situazione",
    labelColor: "Colore",
    errorName: "* Il nome deve avere almeno 3 caratteri",
    errorAuthorized: "* Definisci chi può interagire in questo stato",
    erroSituation: "* Scegli tra attivare o disattivare l'argomento",
    errorColor: "* Scegli un colore per lo stato",
    placeholderName: "Nome dello stato",
    placeholderAuthorized: "Autorizzato a interagire",
    placeholderSituation: "Situazione",
    placeholderColor: "Colore",
    labelActivated: "Attivato",
    labelDeactivated: "Disattivato",
    titleBtnGoBack: "Torna alla schermata delle chiamate",
    labelBtnGoBack: "RITORNA",
    labelFilterAdvancedTicket: "Ticket",
    labelFilterAdvancedSubject: "Argomento",
    labelFilterAdvancedAgent: "Agente",
    labelFilterAdvancedStatus: "Stato",
    labelFilterAdvancedDelayed: "In ritardo",
    labelFilterAdvancedApplicant: "Richiedente",
    labelFilterAdvancedSeverity: "Criticità",
    labelFilterAdvancedExclude: "Vuoi davvero eliminare il filtro?",
    titleFilterAdvancedMyFilters: "I miei filtri salvati",
    labelNoAccessToLocalStorage:
      "Nessun accesso alla funzione di registrazione locale",
    titleFilterAdvancedChooseFilter: "Scegli il filtro da applicare",

    titleFilterAdvancedNoFilterSelected: "Nessun filtro selezionato",
    labelFilterAdvancedSaveFilter: "Salva filtro",
    labelFilterAdvancedQuestionSaveFilter: "Dai un nome al filtro",
    errorFilterAdvancedTicket: "* Per favore scegli almeno 1",
    errorFilterAdvancedSubject: "* Per favore scegli almeno 1",
    errorFilterAdvancedAgent: "* Per favore scegli almeno 1 agente",
    errorFilterAdvancedStatus: "* Per favore scegli almeno 1 stato",
    errorFilterAdvancedApplicant: "* Per favore scegli almeno 1 richiedente",
    errorFilterAdvancedSeverity: "* Per favore scegli almeno 1 criticità",
    titleFilterAdvancedDeleteFilter: "Vuoi davvero eliminare il filtro?",
    titleDetails: "Dettagli",
    titleInteractions: "Interazioni",
    labelStatus: "Stato",
    labelTicketNumber: "Numero del ticket",
    labelCreated: "Creato il",
    labelLastInteraction: "Ultima interazione",
    labelDeadline: "Scadenza",
    labelSubject: "Argomento",
    titleCriticality: "Livello di criticità",
    titleAlterCriticality: "Modifica criticità",
    labelNoAgent: "Non assegnato",
    titleRequestersInCopy: "Collaboratori in copia",
    labelBtnEdit: "Modifica",
    labelAgentResponsible: "Agente responsabile",
    titleClickHereToStartAnInteraction:
      "Clicca qui per iniziare un'interazione",
    titleClickToIncrement:
      "Clicca per aggiungere più dettagli senza aggiornare lo stato",
    labelClose: "CHIUDI",
    labelIncrement: "INCREMENTA",
    labelAttachment: "ALLEGATO",
    placeholderDescription: "Inserisci la descrizione",
    errorDescription: "È necessario compilare la descrizione dell'evento",
    placeholderStatusTo: "Cambia lo stato del ticket...",
    errorStatusTo: "Scegli lo stato che riceverà la richiesta",
    labelSendEmail: "Invia notifica via email",
    titleAlterAgent: "Modifica agente responsabile",
    titleMainCritical: "Manutenzione di criticità",
    subtitleMainCritical:
      "Crea, modifica, elimina e attiva/disattiva le criticità utilizzate nell'helpdesk.",
    headerNameCritical: "Criticità",
    headerIcon: "Icona",
    labelDeactive: "Inattivo",
    titleBtnAddActive: "Attivo",
    titleAddCritical: "Aggiungi Criticità",
    titleAddCriticalUpd: "Aggiorna criticità",
    labelNameAddCritical: "Nome della criticità",
    labelIcon: "Icona",
    errorNameAddCritical: "* Il nome deve avere almeno 3 caratteri",
    errorIcon: "* L'icona deve avere almeno 1 carattere",
    errorSituationAddCritical:
      "* Scegli tra attivare o disattivare la criticità",
    placeholderNameAddCritical: "Nome della criticità",
    placeholderIcon: "Icona",
    placeholderSituationAddCritical: "Situazione",
    labelDeactiveAddCritical: "Disattivato",
    titleSubjectMain: "Manutenzione degli argomenti",
    subtitleSubject:
      "Crea, modifica, elimina e attiva/disattiva gli argomenti utilizzati nell'helpdesk.",
    headerNameSubject: "Argomento",
    headerPraz: "Termine (giorni)",
    titleSubjectBtnAdd: "Aggiungi",
    titleSubjectDel: "Eliminazione dell'argomento",
    subtitleSubjectDel: "Vuoi davvero eliminare l'argomento?",
    titleSubjectAdd: "Aggiungi Argomento",
    titleSubjectAddUpd: "Aggiorna argomento",
    labelNameSubjectAdd: "Nome dell'argomento",
    labelPraz: "Termine (in giorni)",
    errorNameSubjectAdd: "* Il nome deve avere almeno 3 caratteri",
    errorPraz: "* Il termine deve essere di almeno 1 giorno",
    errorSituationSubjectAdd: "* Scegli tra attivare o disattivare l'argomento",
    placeholderNameSubjectAdd: "Nome dell'argomento",
    placeholderPraz: "Termine (in giorni)",
    placeholderSituationSubjectAdd: "Situazione",
    titleAlterEnvolved: "Modifica l'elenco dei collaboratori in copia",
    labelInvolved: "Collaboratori in Copia",
    errorInvolved: "* Scegli almeno 1 collaboratore",
    titleAlterCritical: "Modifica criticità del ticket",
    labelCriticality: "Criticità del ticket",
    errorCriticality: "* Scegli una criticità valida",
    labelTitleAdd: "Titolo",
    labelFiles: "File",
    labelBtnInteraction: "Interazione",
    labelColabCopy: "Collaboratori in copia",
    placeholderTitle: "Descrivi un titolo per il problema",
    errorTitle: "* Minimo di 3 caratteri",
    errorSubject: "* Scegli un argomento",
    errorMat: "* Scegli la matricola del richiedente",
    titleNewTicket: "Apertura di un ticket",
    advancedFilter: "Filtro avanzato",
  },
  rnci_ncp: {
    titleCancelStatus: "Vuoi davvero annullare questo stato?",
    subtitleCancelStatus:
      "Il RNCI verrà eliminato e non potrà più essere recuperato",
    subtitleCancelStatus2: "Il RNCI tornerà allo stato APERTO",
    subtitleCancelStatus3: "Il RNCI tornerà allo stato LINEA DI SALITA",
    tituloBuscaAvancada: "Ricerca avanzata",
    descricaoBuscaAvancada:
      "Scegli tra le opzioni e crea una ricerca avanzata.",
    wait_generate_pdf: "Attendere, generando PDF...",
    rotuloBtnFiltro: "RICERCA AVANZATA",
    tituloSemDados: "Nessun dato è stato trovato",
    tituloSemDadosRetorno: "Tentativo di caricare i dati necessari...",
    titulo: "RCNI",
    tituloPaginacao: "Clicca per alternare tra le pagine",
    codigo: "Codice",
    anomalia: "Anomalia: ",
    data: "Data di creazione: ",
    area: "Area: ",
    rotuloBotaoAdd: "Nuovo RNCI",
    tituloBotaoAdd: "Crea nuovo RNCI",
    tituloForm: "ALLERTA DI QUALITÀ INTERNA",
    formAuditor: "AUDITORE",
    formConjunto: "INSIEME",
    formModelo: "MODELLO",
    formTurno: "TURNO",
    formQtdKO: "QUANTITÀ KO",
    formQtdSuspeita: "QUANTITÀ SOSPETTA",
    formOperacao: "OPERAZIONE",
    formRNCI: "RNCI N°",
    formPN: "PN",
    formRastreabilidade: "TRACCIABILITÀ",
    formSubidaDeLinha: "LINEA DI SALITA",
    formFalhaForaControle: "FALLIMENTO FUORI CONTROLLO",
    formTwttp: "TWTTP",
    formData: "DATA",
    formHora: "ORA",
    formErrorAuditor: "Inserisci almeno un collaboratore",
    formErrorConjunto: "Scegli almeno un'area",
    formErrorModelo: "Scegli almeno un modello",
    formErrorTurno: "Scegli almeno un turno",
    formErrorQtdKO: "Minimo 0",
    formErrorQtdSuspeita: "Minimo 0",
    formErrorOperacao: "Operazione",
    formErrorRNCI: "RNCI N°",
    formErrorPN: "Inserisci il PN",
    formErrorRastreabilidade: "Inserisci la tracciabilità",
    formErrorSubidaDeLinha: "È salito di linea?",
    formErrorFalhaForaControle: "È stato un fallimento fuori controllo?",
    formErrorTwttp: "Obbligatorio",
    formErrorData: "Inserisci la data",
    formErrorHora: "Inserisci l'ora",
    formErrorAnomalia: "Inserisci l'anomalia",
    formAuditorPlaceholder: "Inserisci l'auditor",
    formConjuntoPlaceholder: "Area",
    formModeloPlaceholder: "Modello",
    formTurnoPlaceholder: "Turno",
    formQtdKOPlaceholder: "ES: 1",
    formQtdSuspeitaPlaceholder: "ES: 1",
    formOperacaoPlaceholder: "Operazione",
    formRNCIPlaceholder: "RNCI N°",
    formPNPlaceholder: "PN",
    formRastreabilidadePlaceholder: "Tracciabilità",
    formSubidaDeLinhaPlaceholder: "Linea di salita",
    formFalhaForaControlePlaceholder: "Fallimento fuori controllo",
    formTwttpPlaceholder: "Twttp",
    formDataPlaceholder: "Data",
    formHoraPlaceholder: "Ora",
    formAnomaliaPlaceholder: "Descrivi l'anomalia...",
    formAnomalia: "ANOMALIA",
    formSim: "SÌ",
    formNao: "NO",
    twttpGerou: "Ha generato il problema",
    twttpDeixou: "Ha lasciato passare",
    twttpNaoAplicavel: "Non applicabile",
    tituloPergs: "5W+1H",
    tituloKO: "KO",
    tituloOK: "OK",
    tituloImagemIdent: "IDENTIFICAZIONE DI RACK O SCATOLA",
    formOque: "COSA ?",
    formQuando: "QUANDO ?",
    formOnde: "DOVE ?",
    formQuem: "CHI ?",
    formQual: "QUAL ?",
    formComo: "COME ?",
    formErrorOque: "Obbligatorio",
    formErrorQuando: "Obbligatorio",
    formErrorOnde: "Obbligatorio",
    formErrorQuem: "Obbligatorio",
    formErrorQual: "Obbligatorio",
    formErrorComo: "Obbligatorio",
    formOquePlaceholder: "Cosa è successo ?",
    formQuandoPlaceholder: "Quando è successo?",
    formOndePlaceholder: "Dove è successo?",
    formQuemPlaceholder: "Chi ha identificato?",
    formQualPlaceholder: "Qual è il livello del problema?",
    formComoPlaceholder: "Come è successo?",
    formFenomenoRevisado: "FENOMENO RIVEDUTO",
    formErrorFenomenoRevisado: "Obbligatorio",
    formFenomenoRevisadoPlaceholder: "Descrivi il fenomeno.",
    formCausaRaiz: "POSSIBILE CAUSA RADICE",
    formCausaRaizPlaceholder: "Descrivi qui...",
    formErrorCausaRaiz: "Obbligatorio",
    tituloBotaoVoltar: "Clicca per tornare",
    rotuloBotaoFormSalvar: "SALVA",
    tituloBotaoRemoverImagem: "Clicca per rimuovere l'immagine",
    formRotuloImagem: "Inserisci qui l'immagine",
    formErrorImagemOK: "Immagine obbligatoria",
    formErrorImagemKO: "Immagine obbligatoria",
    formErrorImagemIdent: "Errore nell'invio dell'immagine",
    situacaoQualidade: "QUALITÀ",
    situacaoSubida: "LINEA DI SALITA",
    situacaoAberto: "APERTO",
    opcaoDetalhes: "Dettagli",
    opcaoSubida: "Linea di salita",
    opcaoDownloadPdf: "Scarica in PDF",
    opcaoQualidade: "Sposta Qualità",
    rotuloBotaoVerOpcoes: "OPZIONI",
    tituloBotaoVerOpcoes: "Mostra le opzioni di manipolazione del RNCI",
    tituloFormSubida: "Modulo di linea di salita",
    formPlaceholderTempoGasto: "Inserisci il tempo trascorso",
    formTempoGasto: "Tempo trascorso",
    formPlaceholderMod: "Inserisci la manodopera diretta",
    formMod: "MOD",
    formPlaceholderMoi: "Inserisci la manodopera indiretta",
    formMoi: "MOI",
    formPlaceholderOK: "Inserisci la quantità OK",
    formOK: "Quantità OK",
    formPlaceholderKO: "Inserisci la quantità KO",
    formKO: "Quantità KO",
    formPlaceholderObservacao: "Descrivi un'osservazione",
    formObservacao: "Osservazione",
    formErrorTempoGasto: "* Per favore inserisci il tempo trascorso HH:MM",
    formErrorMod: "* Valore minimo 0",
    formErrorMoi: "* Valore minimo 0",
    formErrorOK: "* Valore minimo 0",
    formErrorKO: "* Valore minimo 0",
    formErrorObservacao: "* Descrivi un'osservazione minimo 3 caratteri",
    tituloFormQualidade: "Modulo di Qualità",
    formPlaceholderCodigoProjeto: "Inserisci il codice del progetto",
    formCodigoProjeto: "Codice del Progetto",
    formPlaceholderProjeto: "Questo RNCI ha un legame con un progetto?",
    formProjeto: "Questo RNCI ha un legame con un progetto?",
    formPlaceholderAcoes: "Descrivi le azioni",
    formAcoes: "Azione",
    formErrorProjeto: "* Scegli Sì o No",
    formErrorCodigoProjeto: "* Inserisci il codice del progetto",
    formErrorAcoes: "* Descrivi l'azione minimo 3 caratteri",
    abaForm1: "RNCI",
    abaSubida: "LINEA DI SALITA",
    abaQualidade: "QUALITÀ",
    rotuloBotaoVoltar: "TORNA INDIETRO",
    subidaLinhaMod: "Manodopera diretta",
    subidaLinhaMoi: "Manodopera indiretta",
    subidaLinhaTempo: "Tempo di lavoro",
    subidaLinhaOK: "Quantità pezzi OK",
    subidaLinhaKO: "Quantità pezzi KO",
    subidaLinhaObs: "Osservazione sulla manodopera",
    qualidadeCodProjeto: "Numero del Progetto",
    qualidadeObs: "Azioni",
    filtroNomeStatus: "STATO",
    filtroNomeStatusErro: "* Scegli almeno uno",
    filtroNomeMatricula: "MATRICOLA",
    filtroNomeMatriculaErro: "* Scegli almeno uno",
    filtroNomeArea: "AREA",
    filtroNomeAreaErro: "* Scegli almeno uno",
    filtroNomeTurno: "TURNO",
    filtroNomeTurnoErro: "* Scegli almeno uno",
    filtroNomeRnci: "CODICE RNCI",
    filtroNomeRnciErro: "* Scegli almeno uno",
    filtroNomeOperacao: "OPERAZIONE",
    filtroNomeOperacaoErro: "* Scegli almeno uno",
    filtroNomePN: "PN",
    filtroNomePNErro: "* Scegli almeno uno",
    opcaoExcluirRnci: "Elimina RNCI",
    opcaoRetornarAbertoRnci: "Torna aperto",
    opcaoRetornarSubidaDeLinhaRnci: "Torna alla linea di salita",
  },
  manutencao_variaveis: {
    titlePage: "Manutenzione delle variabili",
    tabOne: "Collaboratori",
    tabTwo: "Variabili",
    tabThree: "Variabili globali",
    labelBtnAddVar: "Aggiungi",
    labelBtnEditVar: "Modifica",
    labelBtnDelVar: "Elimina",
    labelBtnAddVarGlobal: "Aggiungi",
    titleEditVarGlobal:
      "Modifica la variabile sia nella sua descrizione che nel suo valore",
    labelBtnEditVarGlobal: "Modifica",
    titleDelVarGlobal: "Elimina questa variabile globale (Solo autorizzato)",
    labelBtnDelVarGlobal: "Elimina",
    labelValueActualy: "Valore attuale:",
    labelVarName: "Nome della variabile",
    placeholderVarName: "Inserisci il nome della variabile",
    labelVarDescription: "Descrizione",
    placeholderVarDescription: "Inserisci la descrizione della variabile",
    labelVarValue: "Valore",
    placeholderVarValue: "Inserisci il valore iniziale della variabile",
    errorVarDescription: "* Minimo di 3 caratteri",
    errorVarName: "* Minimo di 3 caratteri",
    errorVarValue: "* Campo obbligatorio",
    titleNewVar: "Registrazione di una nuova variabile globale",
    descriptionNewVar:
      "Compila i valori sottostanti per registrare una nuova variabile globale nel sistema.",
    titleFormAddVarUser: "MANUTENZIONE DELLE VARIABILI DELL'UTENTE",
    labelVarUserName: "Variabile",
    placeholderVarUserName: "Inserisci il nome della variabile",
    errorVarUserName: "* Minimo di 3 caratteri",
    labelVarUserDescription: "Descrizione",
    placeholderVarUserDescription:
      "Una descrizione di ciò che si prevede di soddisfare",
    errorVarUserDescription: "* Minimo 3 caratteri",
    labelBtnVarUserSend: "INVIA",
    titleFormDelVarGlobal: "Vuoi davvero eliminare la variabile?",
    descriptionFormDelVarGlobal1: "Se vuoi davvero eliminare la variabile",
    descriptionFormDelVarGlobal2: " clicca su SÌ.",
    tabVarGlobal1: "Modifica valore",
    tabVarGlobal2: "Modifica descrizione",
    labelEditVarGlobalValue: "Valore",
    placeholderEditVarGlobalValue: "Inserisci il nuovo valore della variabile",
    errorEditVarGlobalValue: "* Campo obbligatorio",
    descriptionEditVarGlobal:
      "Inserisci sotto il valore che deve essere assegnato alla variabile",
    labelEditVarGlobalDescription: "Descrizione",
    placeholderEditVarGlobalDescription:
      "Inserisci la descrizione della variabile",
    errorEditVarGlobalDescription: "* Minimo di 3 caratteri",
    descriptionEditVarGlobalDesc:
      "Inserisci sotto la descrizione da assegnare alla variabile",
    titleListVarColab: "Elenco delle variabili dell'utente",
  },
  absenteismo: {
    title: "Assenteismo",
    labelDateFrom: "Data da",
    labelDateTo: "Data a",
    errorDateFormat: "Formato non valido",
    labelBtnSearch: "Ricerca",
    labelViewAll: "Visualizza tutti",
  },
  twttp: {
    labelSituationViewDetails: "Situazione",
    labelSectorViewDetails: "Settore",
    labelInterviewedViewDetails: "Intervistato",
    labelInterviewerViewDetails: "Intervistatore",
    labelInterviewDateViewDetails: "Data dell'intervista",
    labelPrevDateViewDetails: "Data prevista",
    labelActionDateViewDetails: "Termine per l'azione",
    labelTypeViewDetails: "Tipo di TWTTP",
    labelProblemViewDetails: "Problema",
    labelProcessViewDetails: "Processo / Macchina",
    labelModelViewDetails: "Modello(i)",
    labelErrorFeatureViewDetails: "Caratteristica dell'errore",
    labelErrorDetailViewDetails: "Dettaglio sull'errore",
    labelProblemOriginViewDetails: "Origine del problema",
    labelFactorViewDetails: "Fattore",
    labelShiftViewDetails: "Turno",
    labelRejectionJustifyViewDetails: "Giustificazione del rifiuto",
    labelTimeViewDetails: "Tempo necessario per l'analisi",
    labelAttachmentViewDetails: "Allegato",
    labelAttachmentValidationViewDetails: "Allegato(i) Validazione",
    labelCodeFilterAdvanced: "Codice",
    labelTypeFilterAdvanced: "Tipo",
    labelOriginFilterAdvanced: "Origine",
    labelInterviewerFilterAdvanced: "Intervistatore",
    labelInterviewedFilterAdvanced: "Intervistato",
    labelSectorFilterAdvanced: "Settore",
    labelAreaFilterAdvanced: "Area",
    labelLateFilterAdvanced: "In ritardo",
    labelColabInterviewToInterview: "Collaboratore Intervistato",
    labelInterviewerToInterview: "Intervistatore",
    errorValueRequiredToInterview: "* Valore obbligatorio",
    labelJustifyToInterview: "Nota esplicativa",
    placeholderJustifyToInterview: "Scrivi una nota esplicativa...",
    errorJustifyRequiredToInterview: "* Minimo di 3 caratteri",
    labelActionToInterview: "Azione da intraprendere",
    descriptionColabToInterview: "Collaboratore da intervistare",
    descriptionToInterview: "Intervistatore",
    titleTwttpResponseQuestion: "TWTTP ID -",
    titleAvatarToInterview: "Intervistato",
    titleAvatarToInterviewer: "Intervistatore (valutatore)",
    titleTwttpResponseDateOpen: "Data dell'intervista",
    labelTwttpResponseQuestion: "Domanda",
    labelTwttpResponse: "Risposta",
    labelTwttpResponseNote: "Nota esplicativa",
    labelJustifyFormMove: "* Giustificazione",
    placeholderJustifyFOrmMove: "Inserisci una giustificazione...",
    errorJustifyRequiredFormMove: "* Minimo 3 caratteri",
    titleReprovedFormMove: "Rifiutare",
    subtitleReprovedFormMove:
      "Indica il motivo per cui desideri rifiutare questo TWTTP.",
    titleConfirmFormMove1: "Vuoi davvero aggiornare a",
    subtitleConfirmFormMove:
      "Questa modifica non potrà essere annullata. Se confermi, lo stato del twttp sarà modificato a",
    errorAttachmentRequiredFormMoveValidation: "Allegato obbligatorio",
    titleFormMoveValidation: "Sposta TWTTP per la validazione",
    subtitleFormMoveValidation:
      "Per spostare questo TWTTP per la validazione, è necessario includere un allegato nel campo sottostante",
    errorUnknown: "Errore sconosciuto",
    labelOpenDateFormAdd: "Data di apertura",
    labelTwttpTypeFormAdd: "Tipo di TWTTP",
    labelProblemFormAdd: "Problema",
    labelProcessMachineFormAdd: "Processo / Macchina",
    labelModelsFormAdd: "Modelli",
    labelSectorInterviewedFormAdd: "Settore da intervistare",
    labelErrorFeatureFormAdd: "Caratteristica dell'errore",
    labelErrorDetailFormAdd: "Descrivi l'errore in dettaglio",
    labelProblemOriginFormAdd: "Origine del problema",
    labelFactorFormAdd: "Fattore",
    labelShiftFormAdd: "Turno",
    labelAnalysisTeamFormAdd: "Tempo necessario per l'analisi",
    labelAttachmentFormAdd: "Allegato",
    titleTwttpFormAdd: "TWTTP",
    placeholderDetailErrorFormAdd: "Descrivi l'errore in dettaglio",
    errorAttachmentRequiredFormAdd: "Allegato obbligatorio",
    errorOpenDateRequiredFormAdd: "Si prega di inserire la data",
    errorTwttpTypeRequiredFormAdd: "Si prega di inserire il tipo di TWTTP",
    errorProblemRequiredFormAdd: "Si prega di inserire il problema",
    errorAreaRequiredFormAdd: "Si prega di inserire l'area",
    errorModelRequiredFormAdd: "È necessario selezionare almeno un modello",
    errorSectorRequiredFormAdd: "Si prega di inserire il settore",
    errorDetailErrorRequiredFormAdd:
      "Si prega di inserire i dettagli dell'errore",
    errorProblemOriginRequiredFormAdd:
      "Si prega di inserire l'origine del problema",
    errorErrorFeatureRequiredFormAdd: "Si prega di inserire la caratteristica",
    errorAnalysisTeamRequiredFormAdd:
      "Si prega di inserire il tempo per l'analisi",
    errorFactorRequiredFormAdd: "Si prega di inserire il fattore",
    errorShiftRequiredFormAdd: "Si prega di inserire il turno",
    titleTwttpConfirm: "Vuoi davvero annullare questo TWTTP?",
    subtitleTwttpConfirm:
      "Una volta effettuata questa modifica, non sarà più possibile annullare questa procedura",
    labelWaitToPdf: "Attendere, generazione PDF...",
    errorInternal: "ERRORE INTERNO DEL SERVER",
    titleTwttp: "TWTTP - HERCA",
    labelCleanFilter: "Pulisci filtro",
    titleNoData: "Nessun dato da mostrare",
    labelToInterview: "Intervista",
    labelFinishInterview: "Termina intervista",
    labelToValidation: "Invia per validazione",
    labelValidation: "Approva",
    labelMoveToExecution: "Sposta per esecuzione",
    labelCancel: "Annulla",
    labelCode: "Codice",
    labelSector: "Settore",
    labelArea: "Area",
    labelProblem: "Problema",
    labelOptions: "Opzioni",
    titleOpenDateTwttp: "Data di apertura del Twttp",
    titleOpenDate: "Data di apertura",
    titlePrazActionTwttp: "Termine per l'azione del Twttp",
    titlePrazAction: "Termine per l'azione",
    labelFilterAdvanced: "Filtro avanzato",
    titleAddNewTwttp: "Aggiungi nuovo Twttp",
    labelBtnAddTwttp: "AGGIUNGI",
    twttpStatusA: "Aperto",
    twttpStatusV: "Validazione",
    twttpStatusP: "In Intervista",
    twttpStatusS: "In Esecuzione",
    twttpStatusE: "Chiuso",
    twttpStatusVR: "Vedi Risposte",
    twttpStatusC: "Annullato",
    twttpStatusBPDF: "Scarica TWTTP PDF",
    twttpStatusR: "Respinto",
    labelViewAnswers: "Visualizza risposte",
    labelReproved: "Rifiutato",
    twttpStatusY: "V. Produzione",
    labelToValidationHigher: "Invia per validazione Produzione",
    titleFormMoveValidationProd:
      "Invio del TWTTP dallo stato Validazione Produzione a Validazione",
    subtitleFormMoveValidationProd:
      "Se sei sicuro di voler spostare il TWTTP alla validazione, fai clic su SÌ",
  },
  registro_emocional: {
    errorInternal: "Errore interno del server",
    errorUnknown:
      "Errore sconosciuto durante il tentativo di recuperare le emozioni",
    title: "Registro del clima aziendale",
    labelQuantity: "Quantità",
    subtitleNotFound: "Niente trovato",
    subtitleNoFeelings: "Nessuna emozione registrata",
    subtitleBiggestFeeling: "Maggiore emozione nel periodo",
    titleCompanyFeels: "Come si sente l'azienda in generale",
    titleEmotionGraph: "Clima aziendale per giorno",
    subtitleEmotionGraph: "Maggiore emozione registrata ogni giorno",
    titleEmotionGraphLottie1: "Abbiamo avuto più collaboratori",
    titleEmotionGraphLottie2: "in questo giorno",
    titleEmotionGraphToday:
      "Elenco delle emozioni selezionate in questo giorno",
    labelSector: "Settore",
    subtitleOtherReaction: "Altre reazioni presentate nel periodo",
    titleOtherReactionLottie: "Come si sente l'azienda in generale",
    labelPerQuantity: "(per quantità)",
  },
  gestao_helpdesk: {
    labelFilter: "Filtro per periodo",
    labelFilterAdvanced: "Filtro avanzato",
    tickets: "Biglietti",
    titleChartStatus: "Biglietti per stato",
    titleChartSubject: "Biglietti per argomento",
    titleChartAgent: "Biglietti per agente",
    titleChartSector: "Biglietti per settore",
    titleChartUser: "Biglietti per utente",
    titleChartMonth: "Biglietti per mese",
    timeMedium: "Tempo medio di servizio (generale)",
    titleChartTimeMedium: "Tempo medio di servizio per Argomento",
    filterNameStatus: "Stato",
    filterErrorStatus: "* Scegli almeno uno Stato",
    filterNameAgent: "Agente",
    filterErrorAgent: "* Scegli almeno un Agente",
    filterNameSubject: "Argomento",
    filterErrorSubject: "* Scegli almeno un Argomento",
    filterNameSector: "Settore",
    filterErrorSector: "* Scegli almeno un Settore",
    filterNameUser: "Utente",
    filterErrorUser: "* Scegli almeno un Utente",
    filterNamePlant: "Pianta",
    filterErrorPlant: "* Scegli almeno una Pianta",
    titleSearchAdvanced: "Ricerca avanzata",
    descriptionSearchAdvanced:
      "Scegli tra le opzioni e crea una ricerca avanzata.",
  },
  ferias: {
    labelGeoAccept: "Hai già fornito la tua posizione!",
    labelGeo: "Permetti Localizzazione",
    titleSignature: "La tua firma",
    clearSignature: "Cancella",
    saveSignature: "Salva",
    titleImageLoad: "Immagine dell'utente caricata",
    labelPhoto: "Scatta Foto",
    labelPhotoInsert: "Foto inserita",
    labelPhotoRemove: "Rimuovi foto",
    alertRemoveImage: "Vuoi davvero rimuovere la foto?",
    labelTerm: "Segna qui se sei d'accordo con i valori sopra indicati.",
    labelSaveBtn: "CONFERMA RICEZIONE",
    labelBtnDownloadWarningVacation: "Ricevuta di Vacanza",
    errorNotFoundIP: "Errore nel tentativo di recuperare l'IP",
    errorNotExistsWarningVacation:
      "Non esiste un avviso di vacanza da scaricare",
    title: "Ricevute di vacanza",
    labelBtn: "Firma",
    initialVacation: "Inizio",
    finalVacation: "Fine",
    returnOfVacation: "Ritorno dalle vacanze",
    quantityOfDays: "Quantità di giorni",
    dateOfPayment: "Data di pagamento",
    signature: "Firmato",
    notSignature: "Non firmato",
    labelBtnVacation: "Ricevuta Vacanza",
    labelBtnSalaryBonus: "Ricevuta Bonus",
    titleSalaryBonus: "BONUS (TOTALE)",
    titleVacation: "VACANZA (Totale)",
    verb: "Voce",
    provent: "PROVENTO",
    discount: "SCONTO",
  },
  estatisticas: {
    labelToday: "Oggi",
    labelThisMonth: "Questo mese",
    labelLastSixMonths: "Ult. 6 mesi",
    labelByPeriod: "Per periodo",
    labelAccessBySO: "Accessi per S.O",
    labelAccessByBrowser: "Accessi per browser",
    labelAccessByRoute: "Accessi per percorso",
    title: "Statistiche di accesso",
    labelQuantity: "Quantità",
    periodOf: "Periodo di",
    periodTo: "fino a",
    access: "accesso(i)",
    titleAccessByMenu: "Accessi per pagina",
    titleTopSevenUser: "Top 7 Utenti",
    titleAccessByDay: "Accessi per giorno",
    dateFrom: "Data da",
    dateTo: "Data a",
    titleFilterByPeriod: "Filtro per periodo 🧐",
    subtitleFilterByPeriod: "🔎 Scegli una data in cui desideri vedere i dati",
    format: "Formato",
    btnSend: "INVIA",
  },

  manutencao_ativos: {
    titlePage: "Manutenzione degli attivi",
    subtitlePage:
      "Scegli tra le opzioni disponibili per eseguire la manutenzione degli attivi utilizzati in diverse pagine.",
    labelArea: "Area",
    titleLabelArea: "Clicca qui per eseguire la manutenzione dell'Area",
    labelModelo: "Modello",
    titleLabelModelo: "Clicca qui per eseguire la manutenzione del modello",
    labelPerda: "Perdita",
    titleLabelPerda: "Clicca qui per eseguire la manutenzione della perdita",
    labelPilar: "Pilastro",
    titleLabelPilar: "Clicca qui per eseguire la manutenzione del pilastro",
  },
  manutencao_area: {
    titleArea: "Manutenzione dell'Area",
    searchAreaPlaceholder: "Inserisci il nome dell'area",
    modelInactive: "Modello inattivo (non può essere utilizzato)",
    modelActive: "Modello attivo",
    active: "ATTIVA",
    inactive: "INATTIVA",
    titleBtnIconCancel: "Annulla modifica dell'area",
    titleBtnIconEdit: "Clicca per modificare l'area",
    titleSituationArea: "Situazione dell'area",
    titleSituationAreaActive: "Attiva per l'uso",
    titleSituationAreaInactive: "Disattivata per l'uso",
    titlePlant: "Pianta",
    listOfModels: "Elenco dei modelli",
    labelModelName: "Modello",
    titleModelThis: "Questo modello è",
    titleModelActive: " attivato ",
    titleModelInactive: " disattivato ",
    titleModelThis2: "per questa area",
    placeholderAreaName: "Inserisci il nome dell'area",
    errorAreaName: "* Campo obbligatorio",
    labelArea: "Area",
    titleName: "Nome",
    errorAreaMin: "* Minimo di 3 caratteri",
    titleAddArea: "Inserimento di una nuova area",
    subtitleAddArea: "Inserisci il nome della nuova area che sarà inclusa",
  },
  manutencao_modelo: {
    situationA: "Situazione: ATTIVO",
    situationDefault: "Situazione: INATTIVO",
    labelSituationActive: "ATTIVO",
    titleSituationActive: "Mostra solo modelli attivi",
    labelSituationInactive: "INATTIVO",
    titleSituationInactive: "Mostra solo modelli inattivi",
    placeholderModelName: "Inserisci il nome del modello",
    labelNoDataModel: "Nessun modello trovato nel filtro fornito",
    labelModelName: "Modello",
    errorModelName: "* Obbligatorio",
    titleModelAdd: "Registrazione del modello",
    subtitleModelAdd: "Inserisci sotto il nome del nuovo modello da inserire",
    titleName: "Nome",
    titleBtnCancel: "Annulla modifica del modello",
    titleBtnEdit: "Clicca per modificare il modello",
    titleModelEdit: "Visualizzazione e modifica del modello",
    titleModel: "Manutenzione dei modelli",
    subtitleModel:
      "Clicca su uno dei modelli per vedere le opzioni disponibili",
  },

  manutencao_perda: {
    labelWaitDesenv: "Attendere... Pagina in sviluppo",
  },
  manutencao_pilar: {
    labelWaitDesenv: "Attendere... Pagina in sviluppo",
  },

  dados_cadastrais: {
    errorServer: "Errore interno del server",
    male: "Maschile",
    female: "Femminile",
    depLabelNameDepend: "Nome del dipendente",
    depPlaceholerNameDepend: "Nome del dipendente",
    depLabelCpfDepend: "CPF del dipendente",
    depPlaceholerCpfDepend: "EX: 000.000.000-00",
    depLabelBirthDepend: "Data di nascita",
    depPlaceholerBirthDepend: "gg/mm/aaaa",
    depLabelSexDepend: "Sesso",
    depLabelSchoolingDepend: "Istruzione",
    depLabelDegreeDepend: "Grado di parentela",
    depErrorNameDepend: "* Minimo di 3 caratteri",
    depErrorSexDepend: "* Obbligatorio",
    depErrorCpfDepend: "* EX: 000.000.000-00",
    depErrorBirthDepend: "* EX: 00/00/0000",
    depErrorSchoolingDepend: "* Obbligatorio",
    depErrorDegreeDepend: "* Nome del dipendente",
    titleDepend: "Modulo per la manutenzione del dipendente",
    depConfirmDelete: "Vuoi davvero cancellare questo dipendente?",
    depTitleBtnCConfigm:
      "Clicca per confermare la registrazione del dipendente",
    depLabelBtnConfigm: "Conferma",
    depTitleBtnEdit: "Clicca per modificare un campo del dipendente",
    depLabelBtnEdit: "Modifica",
    depTitleBtnDelete: "Clicca per rimuovere questo dipendente",
    depLabelBtnDelete: "Cancella",
    depLabelBirthNasDepend: "Nascita",
    depLabelCpfDepend2: "CPF",
    titlePage: "Dipendenti",
    titleFabAddDepend: "Clicca per aggiungere un dipendente",
    labelFabAddDepend: "Aggiungi dipendente",
    subtitleListOfDependent:
      "Elenco dei dipendenti collegati alla tua registrazione. Controlla le informazioni e confermale (se necessario) o modifica il dipendente cancellandolo.",
    titleNotDependent: "Non sono stati trovati dipendenti per te",

    labeName: "Nome",
    placeholderName: "Nome completo",
    labelCpf: "CPF",
    placeholderCpf: "EX: 000.000.000-00",
    labelPhone: "Telefono",
    placeholderPhone: "EX: (00) 00000-0000",
    labelRg: "RG",
    placeholderRg: "Scrivi qui",
    labelEmail: "E-mail",
    placeholderEmail: "email@esempio.com",
    labelBirth: "Nascita",
    placeholderBirth: "GG/MM/AAAA",
    labelCourse: "Corso",
    placeholderCourse: "Corso ...",
    labelCourseDate: "Data del corso",
    labelMaritalStatus: "Stato civile",
    labelSchooling: "Istruzione",
    labelDisability: "Disabilità",
    labelDisabilityType: "Tipo di disabilità",
    labelCep: "CAP",
    placeholderCep: "EX: 00000-000",
    labelAddress: "Indirizzo",
    placeholderAddress: "Via di esempio",
    labelNumber: "Numero",
    placeholderNumber: "Ex: 100",
    labelComplement: "Complemento",
    placeholderComplement: "complemento",
    labelCityCode: "Codice del comune",
    placeholderCityCode: "Codice del comune",
    labelDistrict: "Quartiere",
    placeholderDistrict: "Quartiere di esempio",
    labelCity: "Città",
    placeholderCity: "Città",
    labelState: "Stato",
    placeholderState: "Stato",
    labelReferencePoint: "Punto di riferimento",
    placeholderReferencePoint: "Punto di riferimento",
    labelEmergencyName: "Nome di emergenza",
    placeholderEmergencyName: "Nome di emergenza",
    labelEmergencyContact: "Contatto di emergenza",
    placeholderEmergencyContact: "EX: (00) 00000-0000",
    labelAttachments: "Allegato(i)",
    errorName: "* Obbligatorio",
    errorCpf: "Ex: 000.000.000-11",
    errorPhone: "Ex: (00) 00000-0000",
    errorRg: "* Minimo 3 caratteri",
    errorEmail: "Ex: esempio@email.com",
    errorBirth: "Ex: 00/00/0000",
    errorMaritalStatus: "* Scegli uno stato civile",
    errorSchooling: "* Scegli un'istruzione",
    errorDisability: "* Obbligatorio",
    errorDisabilityType: "* Obbligatorio",
    errorCep: "* EX: 00000-000",
    errorAddress: "* Obbligatorio",
    errorNumber: "* Obbligatorio",
    errorCityCode: "* Obbligatorio",
    errorDistrict: "* Obbligatorio",
    errorCity: "* Obbligatorio",
    errorState: "* Obbligatorio",
    errorReferencePoint: "* Obbligatorio",
    errorEmergencyName: "* Obbligatorio",
    errorEmergencyContact: "* Ex: (00) 00000-0000",
    errorCepNot200:
      "Errore durante la consultazione del CAP. Riprova più tardi.",
    errorCepNotFound: "CAP non esistente",
    titleFormUpd: "Dati di registrazione",
    titleUpdPasswd: "Impostazioni utente",
    titleForm: "Aggiornamento della password",
    titleField1: "Nuova password",
    titleField2: "Conferma password",
    placeholderField1: "Inserisci la nuova password",
    placeholderField2: "Conferma la nuova password",
    btnUpdate: "Aggiorna Password",
    msgError: "LE PASSWORD NON CORRISPONDONO",
    msgFieldError: "Minimo di 3 caratteri",
    cardTitleName: "Nome",
    cardTitleCpf: "CPF",
    cardTitlePhone: "Telefono",
    cardTitleBirth: "Nascita",
    cardTitleRg: "RG",
    cardTitleEmail: "E-mail",
    cardTitleMaritalStatus: "Stato civile",
    cardTitleSchooling: "Istruzione",
    cardTitleCourse: "Corso",
    cardTitleCourseDate: "Data del corso",
    cardTitleDisability: "Disabilità",
    cardTitleDisabilityType: "Tipo di disabilità",
    cardTitleCep: "CAP",
    cardTitleStreet: "Indirizzo",
    cardTitleNumber: "N°",
    cardTitleComplement: "Complemento",
    cardTitleReferencePoint: "Punto di riferimento",
    cardTitleNeighborhood: "Quartiere",
    cardTitleCity: "Città",
    cardTitleState: "Stato",
    cardTitleMunicipalityCode: "Codice del comune",
    cardTitleEmergencyName: "Nome di emergenza",
    cardTitleEmergencyContact: "Contatto di emergenza",
    cardTitleAttachment: "Allegato",
    cardTitleAttachmentClickHere: "Clicca qui",
    approved: "Approvato",
    inAnalysis: "In analisi",
    pending: "In attesa",
    titleApproved: "Informazione ricevuta e confermata",
    titleInAnalysis: "Informazione in attesa di analisi",
    titlePending: "In attesa di definizione della situazione",
    tab1: "Personali",
    tab2: "Dipendenti",
    tab3: "Password",
  },
  dados_cadastrais_page: {
    titlePage: "Relazione dei dati di registrazione",
    subtitlePage:
      "Clicca su un record per modificare il suo stato, o vedere i dipendenti.",
    titleViewAttachments: "Allegato(i) relativi alla registrazione",
    subtitleViewAttachments:
      "Di seguito è presentata una lista di tutti gli allegati che sono stati inviati dal collaboratore e che fanno parte di questa registrazione dei dati",
    attachment: "Allegato",
    titleBtnAttach1: "Ci sono",
    titleBtnAttach2: "allegato(i) in questo record",
    titleConfirm: "Conferma la ricezione della registrazione",
    titleReject: "Rifiuta i dati di registrazione inviati",
    titleActiveDeactivePend:
      "Attiva/Disattiva la visualizzazione dei record in sospeso",
    titleActiveDeactiveOk:
      "Attiva/Disattiva la visualizzazione dei record confermati",
    titleViewDependents: "Vedi i dipendenti collegati al collaboratore",
    titleDependents: "Dipendenti collegati al collaboratore",
    errorInternal: "ERRORE INTERNO DEL SERVER",
    errorUnknown: "Errore sconosciuto",
    labelFilterPendent: "IN ATTESA",
    labelFilterOK: "OK",
  },
  banco_de_horas: {
    titlePage: "Banca delle ore",
    titleUpdateCache: "Aggiorna la cache della banca delle ore",
    saldoFinal: "SALDO FINALE",
  },
  documentos: {
    detailsHeaderFileVisibleForPlant: "Visibile per tutti da",
    detailsHeaderFileOwner: "Proprietario del file",
    editNameFileNewName: "Nuovo nome",
    editNameFileNewNamePlaceholder: "Inserisci il nuovo nome del file",
    editNameFileNewNameError: "* Minimo di 3 caratteri",
    editNameFileTitle: "Inserisci il nuovo nome",
    labelEditVisibilityBy: "Visibile da",
    labelEditFuncPermissionView:
      "Dipendenti con permesso di visualizzare (lasciare vuoto per nessuna restrizione)",
    errorVisibilityBy: "* Scegli almeno una pianta da visualizzare",
    titleEditVisibility: "Imposta livello di visibilità",
    labelItemFileMobileCreated: "Creato",
    labelItemFIleMobileFile: "File",
    labelItemCreated: "Creato",
    labelItemFile: "File",
    withPermissionToView: "ha il permesso di visualizzare",
    labelItemMenuDetails: "Dettagli",
    labelItemMenuRename: "Rinomina",
    labelItemMenuCut: "Taglia",
    labelItemMenuAlterVisibility: "Modifica Visibilità",
    labelItemMenuDelete: "Elimina",
    labelItemTrashDetails: "Dettagli",
    labelItemTrashRecover: "Recupera dal cestino",
    labelItemTrashDeleteForever: "Elimina definitivamente",
    titleConfirmDelete: "Vuoi davvero eliminare?",
    subtitleConfirmDelete:
      "Questa azione non può essere annullata. Se è una directory, tutti i file sottostanti verranno rimossi anche.",
    subtitleConfirmDeleteFile:
      "Questa azione non può essere annullata. L'elemento verrà eliminato definitivamente dal cestino",
    labelNewFolder: "Nome della cartella",
    placeholderNewFolder: "Inserisci il nome della cartella",
    labelVisibilityBy: "Visibile da",
    labelFuncPermittedView:
      "Dipendenti con permesso di visualizzare (lasciare vuoto per nessuna restrizione)",
    errorNewFolder: "* Il nome deve avere almeno 2 caratteri.",
    errorNotFiles: "Deve esistere file",
    titleNewFolder: "Creazione di una nuova cartella",
    titleTrash: "Cestino degli elementi eliminati",
    labelNewFiles: "Nuovi file",
    placehlderNewFiles: "Scegli i file da inviare",
    titleUploadNewFiles: "Nuovi file nella cartella",
    errorInternalServer: "ERRORE INTERNO DEL SERVER",
    titleViewFile: "Dettagli sull'elemento",
    file: "File",
    fromFile: "dal file",
    fromFolder: "dalla cartella",
    created: "Creato",
    createdBy: "Creato da",
    linkToDownload: "Link per il download",
    secondaryLinkToDownload: "Clicca per scaricare il file",
    titleAccessRestricted: "Accesso limitato solo a",
    titleAccessPermitted: "Accesso consentito a",
    labelAllPlantsView: "Tutti delle piante con visualizzazione possono vedere",
    labelBtnGoBack: "INDIETRO",
    labelBtnZoomOut: "ZOOM OUT",
    labelBtnZoomIn: "ZOOM IN",
    labelBtnNext: "AVANTI",
    labelBtnClose: "CHIUDI",
    descPageOf1: "Pagina",
    descPageOf2: "di",
    titleAccessTrash: "Accesso al cestino",
    titleUploadFiles: "Carica file",
    titleCreateFolder: "Crea una nuova cartella",
    labelBtnCancel: "Annulla",
    labelBtnPaste: "Incolla qui",
    titleAnimationNoData: "Non ci sono file in questa cartella",
  },
  oportunidades: {
    titleHistVacation:
      "Storico delle movimentazioni del candidato per il posto",
    fromPhase: "Dalla fase",
    toPhase: "Alla fase",
    observation: "Osservazione",
    btnHistAttachment: "Allegato",
    histData: "Data",
    errorInternal: "ERRORE INTERNO DEL SERVER",
    errorUnknown: "ERRORE SCONOSCIUTO",
    illiterate: "ANALFABETA",
    incompletePrimary: "FINO ALLA 4ª SERIE INCOMPLETA (PRIMARIA INCOMPLETA)",
    completePrimary: "CON 4ª SERIE COMPLETA DEL 1º GRADO (PRIMARIA COMPLETA)",
    incompleteSecondary: "PRIMO GRADO (SCUOLA MEDIA) INCOMPLETO",
    completeSecondary: "PRIMO GRADO (SCUOLA MEDIA) COMPLETO",
    incompleteHighSchool: "SECONDO GRADO (SCUOLA SUPERIORE) INCOMPLETO",
    completeHighSchool: "SECONDO GRADO (SCUOLA SUPERIORE) COMPLETO",
    incompleteCollege: "LAUREA INCOMPLETA",
    completeCollege: "LAUREA COMPLETA",
    completeMaster: "MASTER COMPLETO",
    completeDoctorate: "DOTTORATO COMPLETO",
    specialization: "POST-LAUREA/SPECIALIZZAZIONE",
    postDoctorate: "POST-DOTTORATO",
    labelWaitGeneratePDF: "Attendere, generazione del PDF...",
    isOk: "Compilata",
    isOpen: "Aperta",
    labelBtnClose: "Chiudi",
    titleScholarship: "Istruzione",
    titlePhone: "Cellulare",
    titleDateInscription: "Data di iscrizione",
    titleSector: "Settore",
    titleEmail: "E-mail",
    titleAttachment: "Allegato(i) del candidato",
    attachment: "Allegato",
    titleAnswerQuestionnaire: "Risposte al questionario",
    titleBtnDownloadPdf: "Clicca qui per scaricare una versione in PDF",
    labelBtnDownloadPdf: "SCARICA RISPOSTE",
    hidden: "Nascondi",
    show: "Mostra Candidati Registrati per il posto",
    labelPhaseActualy: "Fase attuale",
    titleVacationPage: "Candidati per il posto",
    phase: "FASE",
    titleBtnViewHistory: "Guarda lo storico delle movimentazioni",
    labelBtnViewHistory: "Storico",
    titleBtnViewAnswers: "Guarda le risposte al questionario",
    labelBtnViewAnswers: "Risposte",
    titleBtnChangePhase: "Cambia il candidato alla prossima fase",
    labelBtnChangePhase: "Cambia Fase",
    labelEmail: "E-mail",
    placeholderEmail: "La tua email",
    labelPhone: "Cellulare",
    placeholderPhone: "(00) 00000-0000",
    labelSchooling: "Istruzione *",
    placeholderWriteHere: "Scrivi qui",
    errorQuestion: "* Si prega di rispondere alla domanda",
    labelToPhase: "Prossima fase da spostare",
    labelObservation: "Osservazione",
    placeholderObservation: "Inserisci qui la prossima fase",
    labelFile: "File",
    placeholderFile: "Inserisci un file",
    errorObservation: "* Si prega di inserire almeno 3 caratteri",
    errorToPhase:
      "* Scegli la fase in cui il candidato sarà spostato alla prossima fase",
    titleAlterPhase: "Cambio di fase",
    subtitleNewPhase:
      "Indica la nuova fase in cui il candidato è stato trasferito.",
    labelOpenDate: "Data di apertura",
    labelVacation: "Posto",
    labelFunction: "Funzione",
    labelDescription: "Descrizione",
    labelActivity: "Attività",
    labelIamCandidate: "Sono già un candidato",
    labelRegister: "CANDIDARSI",
    titleOpenDate: "Data di inclusione del posto",
    titlePage: "Opportunità",
    titleNoData: "Nessuna opportunità al momento",
    titleSituationActualy: "Situazione attuale del posto",
  },
  painel_de_notificacao: {
    titlePage: "Pannello di notifiche",
    subtitleSendMessageBySector: "Invio di messaggi per settore",
    titleSendNotificationBySector: "Invio di notifiche per settore",
    titleSendNotificationByPlant: "Invio di notifiche per impianto",
    errorTitle: "Minimo di 3 caratteri",
    errorDescription: "Minimo di 3 caratteri",
    errorSector: "Seleziona almeno un settore",
    labelSector: "Scegli il settore",
    labelTitle: "Titolo",
    placeholderTitle: "Scrivi un titolo",
    labelDescription: "Descrizione",
    placeholderDescription: "Scrivi una descrizione",
    labelBtnRemoveSector: "Rimuovi settori selezionati",
    titleCanReceiveNotifications: "Possono ricevere notifiche",
    titleCannotReceiveNotifications: "Non possono ricevere notifiche",
    labelClearFilter: "Cancella filtro",
    titlePageSendOneNotification: "Invio di una notifica individuale",
    subtitlePageSendOneNotification1: "Notificando l'utente",
    subtitlePageSendOneNotification2: "del settore",
    titlePageSendPlantNotification: "Invio di una notifica individuale",
    subtitlePageSendPlantNotification:
      "Compila i campi per inviare una notifica a tutto l'impianto di",
  },
  manutencao_componentes: {
    titlePage: "Manutenzione delle strutture",
    titleFab: "Inserimento di un nuovo componente",
    labelFab: "NUOVO COMPONENTE",
    components: "componenti",
    descriptionPortuguese: "Descrizione Portoghese",
    titlePageFormAdd: "INSERIMENTO DI UN NUOVO COMPONENTE",
    labelCode: "Codice",
    placeholderCode: "Codice",
    labelDescriptionPortuguese: "Descrizione Portoghese",
    placeholderDescriptionPortuguese: "Descrizione in portoghese",
    labelDescriptionEnglish: "Descrizione Inglese",
    placeholderDescriptionEnglish: "Descrizione in inglese",
    labelDescriptionItalian: "Descrizione Italiano",
    placeholderDescriptionItalian: "Descrizione in italiano",
    labelComponentMath: "Matematica del componente",
    placeholderComponentMath: "Matematica del componente",
    labelSupplier: "Fornitore",
    placeholderSupplier: "Fornitore",
    labelType: "Tipo",
    placeholderType: "Tipo",
    labelMass: "Massa",
    placeholderMass: "Massa",
    labelMaterialStandard: "Norma materiale",
    placeholderMaterialStandard: "Specificazione della verniciatura",
    labelWeldingAreaSpecification: "Specificazione area di saldatura",
    placeholderWeldingAreaSpecification: "Specificazione area di saldatura",
    labelUnitMeasure: "Unità di misura",
    placeholderUnitMeasure: "Unità di misura",
    labelThreadProtectiveApplication:
      "Applicazione protettiva della filettatura",
    placeholderThreadProtectiveApplication:
      "Applicazione protettiva della filettatura",
    labelComponentImage: "Immagine del componente",
    placeholderComponentImage: "file",
    expectMax: "* Si prevede al massimo",
    characters: "caratteri",
  },
  informe_rendimentos: {
    titlePage: "Dichiarazione dei redditi",
    titleNoData: "Clicca su un anno per scaricare la dichiarazione dei redditi",
    titleChip: "Anno di",
    titleAnimation:
      "Dichiarazione dei redditi. Clicca sul pulsante per scaricare",
    titleButton: "Clicca per scaricare la dichiarazione dell'anno di",
    labelButton: "SCARICA ANNO",
  },
  budget: {
    titleClickValue: "Clicca per vedere i dettagli",
    infoTable: "* Clicca sui valori di VLR REALE per vedere i dettagli",
    month: "",
    nothing: "Non ci sono dati da restituire",
    tab1: "Sintetico",
    tab2: "Analitico",
    title: "Dettagli",
    headerDescription: "DESCRIZIONE DEL CONTO",
    headerHistory: "STORICO",
    headerClientSupplier: "CLIENTE / FORNITORE",
    headerMonth: "MESE",
    headerAccount: "CONTO",
    headerValue: "VALORE",
    titleNoData: "Non ci sono dati",
  },
  ncp: {
    tituloBuscaAvancada: "Ricerca avanzata",
    descricaoBuscaAvancada:
      "Scegli tra le opzioni e crea una ricerca avanzata.",
    tituloNaoConformidade: "EVIDENZE DI NON CONFORMITÀ",
    rotuloBtnFiltro: "RICERCA AVANZATA",
    tituloSemDados: "Nessun dato trovato",
    tituloSemDadosRetorno: "Tentativo di caricare i dati necessari...",
    titulo: "NCP",
    tituloPaginacao: "Clicca per passare tra le pagine",
    codigo: "Codice",
    anomalia: "Anomalia: ",
    data: "Data Creazione: ",
    area: "Area: ",
    rotuloBotaoAdd: "Nuovo NCP",
    tituloBotaoAdd: "Crea nuovo NCP",
    tituloForm: "ALLERTA DI NON CONFORMITÀ DEL PROCESSO",
    formAuditor: "AUDITOR",
    formConjunto: "SET",
    formModelo: "MODELLO",
    formTurno: "TURNO",
    formQtdKO: "QUANTITÀ KO",
    formQtdSuspeita: "QUANTITÀ SOSPETTA",
    formOperacao: "OPERAZIONE",
    formNCP: "NCP N°",
    formPN: "PN",
    formRastreabilidade: "TRACCIABILITÀ",
    formData: "DATA",
    formHora: "ORA",
    formErrorAuditor: "Inserisci almeno un collaboratore",
    formErrorConjunto: "Scegli almeno un'area",
    formErrorModelo: "Scegli almeno un modello",
    formErrorTurno: "Scegli almeno un turno",
    formErrorQtdKO: "Minimo 0",
    formErrorQtdSuspeita: "Minimo 0",
    formErrorOperacao: "Operazione",
    formErrorNCP: "NCP N°",
    formErrorPN: "Inserisci il PN",
    formErrorRastreabilidade: "Inserisci la tracciabilità",
    formErrorSubidaDeLinha: "È salito di linea?",
    formErrorFalhaForaControle: "È stato un fallimento fuori controllo?",
    formErrorTwttp: "Obbligatorio",
    formErrorData: "Inserisci la data",
    formErrorHora: "Inserisci l'ora",
    formErrorAnomalia: "Inserisci l'anomalia",
    formAuditorPlaceholder: "Inserisci l'auditor",
    formConjuntoPlaceholder: "Area",
    formModeloPlaceholder: "Modello",
    formTurnoPlaceholder: "Turno",
    formQtdKOPlaceholder: "ES: 1",
    formQtdSuspeitaPlaceholder: "ES: 1",
    formOperacaoPlaceholder: "Operazione",
    formNCPPlaceholder: "NCP N°",
    formPNPlaceholder: "PN",
    formRastreabilidadePlaceholder: "Tracciabilità",
    formDataPlaceholder: "Data",
    formHoraPlaceholder: "Ora",
    formAnomaliaPlaceholder: "Descrivi l'anomalia...",
    formAnomalia: "ANOMALIA",
    tituloPergs: "5W+1H",
    tituloKO: "KO",
    tituloOK: "OK",
    tituloImagemIdent: "IDENTIFICAZIONE DI RACK O SCATOLA",
    formOque: "COSA?",
    formQuando: "QUANDO?",
    formOnde: "DOVE?",
    formQuem: "CHI?",
    formQual: "QUAL?",
    formComo: "COME?",
    formErrorOque: "Obbligatorio",
    formErrorQuando: "Obbligatorio",
    formErrorOnde: "Obbligatorio",
    formErrorQuem: "Obbligatorio",
    formErrorQual: "Obbligatorio",
    formErrorComo: "Obbligatorio",
    formOquePlaceholder: "Cosa è successo?",
    formQuandoPlaceholder: "Quando è accaduto?",
    formOndePlaceholder: "Dove è accaduto?",
    formQuemPlaceholder: "Chi ha identificato?",
    formQualPlaceholder: "Qual è il livello del problema?",
    formComoPlaceholder: "Come è accaduto?",
    formFenomenoRevisado: "FENOMENO REVISATO",
    formErrorFenomenoRevisado: "Obbligatorio",
    formFenomenoRevisadoPlaceholder: "Descrivi il fenomeno.",
    formCausaRaiz: "POSSIBILE CAUSA RADICE",
    formCausaRaizPlaceholder: "Descrivi qui...",
    formErrorCausaRaiz: "Obbligatorio",
    tituloBotaoVoltar: "Clicca per tornare",
    rotuloBotaoFormSalvar: "SALVA",
    tituloBotaoRemoverImagem: "Clicca per rimuovere l'immagine",
    formRotuloImagem: "Inserisci qui l'immagine",
    formErrorImagemOK: "Immagine obbligatoria",
    formErrorImagemKO: "Immagine obbligatoria",
    formErrorImagemIdent: "Errore nell'invio dell'immagine",
    situacaoQualidade: "QUALITÀ",
    situacaoSubida: "SALITA DI LINEA",
    situacaoAberto: "APERTO",
    opcaoDetalhes: "Dettagli",
    opcaoSubida: "Salita di Linea",
    opcaoDownloadPdf: "Scarica in PDF",
    opcaoQualidade: "Muovi alla Qualità",
    rotuloBotaoVerOpcoes: "OPZIONI",
    tituloBotaoVerOpcoes: "Mostra le opzioni di manipolazione dell'NCP",
    tituloFormSubida: "Modulo di salita di linea",
    formPlaceholderTempoGasto: "Inserisci il tempo speso",
    formTempoGasto: "Tempo speso",
    formPlaceholderMod: "Inserisci la manodopera diretta",
    formMod: "MOD",
    formPlaceholderMoi: "Inserisci la manodopera indiretta",
    formMoi: "MOI",
    formPlaceholderOK: "Inserisci la quantità OK",
    formOK: "Quantità OK",
    formPlaceholderKO: "Inserisci la quantità KO",
    formKO: "Quantità KO",
    formPlaceholderObservacao: "Descrivi un'osservazione",
    formObservacao: "Osservazione",
    formErrorTempoGasto: "* Si prega di informare il tempo speso HH:MM",
    formErrorMod: "* Valore minimo 0",
    formErrorMoi: "* Valore minimo 0",
    formErrorOK: "* Valore minimo 0",
    formErrorKO: "* Valore minimo 0",
    formErrorObservacao: "* Descrivi un'osservazione minimo 3 caratteri",
    tituloFormQualidade: "Modulo di Qualità",
    formPlaceholderCodigoProjeto: "Inserisci il codice del progetto",
    formCodigoProjeto: "Codice del Progetto",
    formPlaceholderProjeto: "Questo NCP ha un legame con qualche progetto?",
    formProjeto: "Questo NCP ha un legame con qualche progetto?",
    formPlaceholderAcoes: "Descrivi le azioni",
    formAcoes: "Azione",
    formErrorProjeto: "* Scegli Sì o No",
    formErrorCodigoProjeto: "* Inserisci il codice del progetto",
    formErrorAcoes: "* Descrivi l'azione minimo 3 caratteri",
    abaForm1: "NCP",
    abaSubida: "SALITA DI LINEA",
    abaQualidade: "QUALITÀ",
    rotuloBotaoVoltar: "TORNA",
    subidaLinhaMod: "Manodopera Diretta",
    subidaLinhaMoi: "Manodopera Indiretta",
    subidaLinhaTempo: "Tempo di lavoro",
    subidaLinhaOK: "Quantità pezzi OK",
    subidaLinhaKO: "Quantità pezzi KO",
    subidaLinhaObs: "Osservazione della manodopera",
    qualidadeCodProjeto: "Numero del Progetto",
    qualidadeObs: "Azioni",
    filtroNomeStatus: "STATUS",
    filtroNomeStatusErro: "* Scegli almeno uno",
    filtroNomeMatricula: "MATRICOLA",
    filtroNomeMatriculaErro: "* Scegli almeno uno",
    filtroNomeArea: "AREA",
    filtroNomeAreaErro: "* Scegli almeno uno",
    filtroNomeTurno: "TURNO",
    filtroNomeTurnoErro: "* Scegli almeno uno",
    filtroNomeNcp: "CODICE NCP",
    filtroNomeNcpErro: "* Scegli almeno uno",
    filtroNomeOperacao: "OPERAZIONE",
    filtroNomeOperacaoErro: "* Scegli almeno uno",
    filtroNomePN: "PN",
    filtroNomePNErro: "* Scegli almeno uno",
    opcaoExcluirNcp: "Elimina NCP",
    opcaoRetornarAbertoNcp: "Ritorna a aperto",
    opcaoRetornarSubidaDeLinhaNcp: "Ritorna a salita di linea",
    titlePageCancel: "Vuoi davvero annullare questo stato?",
    subtitleCancel: "L'NCP verrà eliminato e non potrà essere recuperato",
    subtitleCancel2: "L'NCP tornerà allo stato APERTO",
    subtitleCancel3: "L'NCP tornerà allo stato SALITA DI LINEA",
  },
  treinamento: {
    titleViewTrainning: "Visualizza informazioni sulla formazione",
    labelViewTrainning: "VISUALIZZA FORMAZIONE",
    labelBtnOptions: "Opzioni",
    titleEditTrainning: "Modifica informazioni sulla formazione",
    labelEditTrainning: "MODIFICA FORMAZIONE",
    titleAddParticipant: "Aggiungi studenti alla formazione",
    labelAddParticipant: "AGGIUNGI STUDENTI",
    labelViewHistory: "VISUALIZZA STORIA",
    titleViewHistory: "VISUALIZZA STORIA DELLA FORMAZIONE",
    labelAlterStatus: "MODIFICA STATO",
    titleAlterStatus: "Modifica stato della formazione",
    optionTextNewTrainning: "Nuova Formazione",
    optionTitleNewTrainning: "Clicca qui per registrare nuove formazioni",
    optionTextMaintenceCouse: "Manutenzione dei Corsi",
    optionTitleMaintenceCouse: "Clicca qui per la manutenzione dei corsi",
    optionTextProfessor: "Manutenzione degli Istruttori",
    optionTitleProfessor: "Clicca qui per la manutenzione degli istruttori",
    titlePage: "Formazione",
    trainningDateTraining: "Data della Formazione",
    instructor: "Istruttore",
    local: "Luogo",
    titlePaginationFilter: "Visualizzazione paginata.",
    trainningNoData: "Nessun dato da visualizzare",
    labelDescription: "Descrizione",
    placeholderDescription: "Descrizione del corso",
    labelDuration: "Durata",
    placeholderDuration: "Durata del corso",
    labelModel: "Modello",
    placeholderModel: "Modello del corso",
    errorDescription: "Minimo 1 e massimo 150 caratteri",
    errorDuration: "Formato non valido. Esempio: 00:00",
    trainningAddUpdInstTitleAdd: "Aggiungi Istruttore",
    trainningAddUpdInstTitleUpd: "Aggiorna Istruttore",
    trainningAddUpdInsLabelName: "Nome",
    trainningAddUpdInsErrorName:
      "Il nome dell'istruttore deve avere tra 1 e 250 caratteri",
    trainningAddUpdInsErrorMat:
      "Il numero di matricola dell'istruttore deve avere 6 caratteri",
    trainningAddUpdInsErrorPlant: "Seleziona la pianta",
    trainningAddUpdInsErrorOrigin: "Seleziona l'origine dell'istruttore",
    trainningAddUpdInsErrorEntity:
      "L'entità dell'istruttore deve avere tra 1 e 150 caratteri",
    trainningAddUpdInsPlaceholderName: "Nome dell'istruttore",
    trainningAddUpdInsPlaceholderMat: "Numero di matricola dell'istruttore",
    trainningAddUpdInsPlaceholderPlant: "Seleziona la pianta",
    trainningAddUpdInsPlaceholderEntity: "Entità dell'istruttore",
    trainningAddUpdInsLabelMat: "Numero di matricola",
    trainningAddUpdInsLabelPlant: "Pianta",
    trainningAddUpdInsLabelOrigin: "Origine dell'istruttore",
    trainningAddUpdInsLabelEntity: "Entità",
    trainningAddUpdInsLabelInstructor: "Istruttore interno",
    titleTrainningCourse: "Manutenzione dei Corsi",
    textFabNewCouse: "Nuovo Corso",
    titleFabNewCouse: "Clicca qui per registrare nuovi corsi",
    labelCourseBtnOptions: "Opzioni",
    titleEdtCourse: "Modifica Corso",
    labelEdtCourse: "Modifica",
    titleDelCourse: "Elimina Corso",
    labelDelCourse: "Elimina",
    subtitleDelCourse: "Vuoi davvero eliminare il corso?",
    titleDelInstructor: "Elimina Istruttore",
    subtitleDelInstructor: "Vuoi davvero eliminare l'istruttore?",
    titleFormAddNewCourse: "Aggiungi nuova formazione",
    labelCourse: "* Corso",
    labelInstructor: "* Istruttore",
    labelDateInit: "* Data di inizio",
    labelLocal: "* Luogo",
    placeholderCourse: "Seleziona un corso",
    placeholderInstructor: "Seleziona un istruttore",
    placeholderDateInit: "Inserisci la data di inizio",
    placeholderLocal: "Inserisci il luogo",
    errorCourse: "Seleziona un corso.",
    errorInstructor: "Seleziona un istruttore.",
    errorDateInit: "Inserisci la data di inizio.",
    errorLocal: "Inserisci il luogo.",
    labelTypeOfCourse: "Tipo di corso",
    labelEfficacy: "Efficacia",

    labelTheme: "Temi",
    labelApply: "Sviluppo (dove applicabile)",
    labelDevelopment: "Sviluppo della Formazione",
    labelObservation: "Osservazioni",
    labelDocument: "Documenti",
    labelMoreTimeEfficiency:
      "Hai bisogno di più tempo per verificare l'efficacia?",
    labelMoreTrainning: "Hai bisogno di più formazione?",
    labelApproved: "Per l'approvazione del corso",
    labelFinish: "Per la chiusura della relazione",
    placeholderTypeOfCourse: "Inserisci il tipo di corso",
    placeholderEfficacy: "Inserisci l'efficacia",

    placeholderTheme: "Inserisci i temi",
    placeholderApply: "Inserisci lo sviluppo dove applicabile",
    placeholderDevelopment: "Inserisci lo sviluppo della formazione",
    placeholderObservation: "Inserisci le osservazioni",
    placeholderDocument: "Inserisci i documenti",
    placeholderMoreTimeEfficiency:
      "Inserisci se hai bisogno di più tempo per verificare l'efficacia",
    placeholderMoreTrainning: "Inserisci se hai bisogno di più formazione",
    placeholderApproved: "Inserisci per l'approvazione del corso",
    placeholderFinish: "Inserisci per la chiusura della relazione",
    labelReponsibility: "* Responsabile",
    placeholderResponsibility: "Inserisci il responsabile",
    labelDateRealized: "Data di realizzazione",
    placeholderDateRealized: "Inserisci la data di realizzazione",
    labelPresence: "Presenza",
    placeholderPresence: "Inserisci la presenza",
    labelCertificate: "Certificato",
    placeholderCertificate: "Inserisci il certificato",
    errorResponsibility: "Inserisci il responsabile.",
    trainningFormAlterStatusTitle: "Modifica stato della formazione",
    trainningFormAlterStatusObservation: "Osservazione",
    trainningFormAlterStatusErrorObservation: "L'osservazione è obbligatoria",
    trainningFormAlterStatusFrom: "Da",
    trainningFormAlterStatusTo: "A",
    trainningInstructorTitle: "Manutenzione degli Istruttori",
    textFabNewInstructor: "Nuovo Istruttore",
    titleFabNewInstructor: "Aggiungi nuovo istruttore",
    tilteEdtInstructor: "Modifica Istruttore",
    labelEdtInstructor: "Modifica",

    labelDelInstructor: "Elimina",
    titleFormStudents: "Aggiungi studenti",
    titleFormStudentsSubtitle: "Aggiungi studenti alla formazione",
    studentNumber: "Studente N°",
    studentNewAdd: "Aggiungi studente alla formazione",
    studentRemove: "Rimuovi studente dalla formazione",
    labelBtnSaveStudents: "SALVA",
    labelBtnClearAllStudents: "RIMUOVI TUTTI",
    statusOpen: "APERTO",
    statusInTraining: "IN FORMAZIONE",
    statusDelivered: "ATA CONSEGNATA",
    trainningViewHistoryTitle: "Visualizza storia della formazione",
    trainningViewHistoryLabelMat: "Numero di matricola",
    trainningViewHistoryLabelName: "Nome",
    trainningViewHistoryLabelFromStatus: "Dallo stato",
    trainningViewHistoryLabelToStatus: "Allo stato",
    trainningViewHistoryLabelDate: "Data di registrazione",
    trainningViewHistoryLabelObservation: "Osservazione",
    titleBtnGoBack: "Clicca per tornare indietro",
    labelBtnGoBack: "TORNA INDIETRO",
    labelNoDataTrainning: "Nessuna formazione trovata",
    titleListOfTrainning: "LISTA DELLA FORMAZIONE",
    labelCodeN: "RAD N°",
    labelData: "DATA",
    titleTypeOfTrainning: "TIPO DI FORMAZIONE",
    titleEfficiency: "EFFICACIA, da valutare da:",
    labelEfficiencyInfo:
      "Per favore, alla fine del RAD, descrivi la metodologia di valutazione dell'efficacia e allega prove quando applicabile",
    labelLocale: "LUOGO",

    themeAndAttachs: "TEMI E ALLEGATI",
    trainningName: "(Nome)",
    trainningAssigned: "(Firma)",
    trainningProffessor: "PROFESSORE(I)",
    trainningPersonal: "ALLENATORE(I)",
    titleDevelop: "Dove applicabile: SVILUPPO ATTESO DELLA FORMAZIONE",
    tableCellNumber: "NUMERO DEL BADGE",
    tableName: "NOME E COGNOME",
    tableAssigned: "FIRMA",
    tableAvailable: "VALUTAZIONE DELL'EFFICACIA\n(risultato, data)",
    titleDevelopFormattion:
      "SVILUPPO DELLA FORMAZIONE RAGGIUNTO (dopo verifica dell'efficacia)",
    subtitleDevelopFormattion1: "Livello",
    subtitleDevelopFormattion2:
      "ha raggiunto il livello? Inserisci il numero (#) associato alla funzione.",
    labelObservationInfo: "OSSERVAZIONE",
    labelDocumentDistribution: "DOCUMENTI DISTRIBUITI",
    titleMoreEfficiency: "Hai bisogno di più tempo per verificare l'efficacia?",
    titleMoreTrainning:
      "In caso di risultato negativo o parzialmente positivo della valutazione dell'efficacia di uno o più partecipanti:",
    titleMoreTrainning2: "Hai bisogno di più formazione?",
    toApproved: "PER L'APPROVAZIONE DEL CORSO (HR/EHS/QS/PLM)",
    toFinish: "PER LA CHIUSURA DELLA RELAZIONE (HR/EHS/QS/PLM)",
    downloadPDF: "Scarica PDF",
    clearFilter: "Pulisci filtro",
    optionTextDownloadExcel: "Scarica Excel",
    optionTitleDownloadExcel: "Clicca qui per scaricare i dati in Excel",
    trainningDownloadExcelTitle: "Scarica Excel",
    trainningDownloadExcelLabelDateFrom: "Data di formazione da",
    trainningDownloadExcelLabelDateTo: "Data di formazione a",
    trainningDownloadExcelErrorDateFrom:
      "La data di inizio della formazione è obbligatoria",
    trainningDownloadExcelErrorDateTo:
      "La data di fine della formazione è obbligatoria",
  },
};

export default translate;
