import {
  Container,
  FormControlLabel,
  Grid,
  Hidden,
  Paper,
  Switch,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { FiltroPorPeriodo, H6 } from "../../../components";
import BackgroundRouter from "../../background-router";
import { format, parseISO } from "date-fns";
import { differenceInDays } from "date-fns";
import axios from "axios";
import { ToastErro } from "../../../utils/utils";
import { getAnimations } from "../feed_rh";
import { useToggle } from "react-use";
import { useTranslation } from "react-i18next";
import ClimaEsteMes from "./registro-emocional-clima-este-mes";
import OutrasReacoes from "./registro-emocional-outras-reacoes";
import GraficoEmocional from "./registro-emocional-grafico";

const getEmotions = (t) => {
  const ANIMACOES = getAnimations(t);
  const emocoes = {};
  _.forEach(ANIMACOES, (val) => {
    emocoes[val.tipo] = 0;
  });
  const tipoEmocaoXNome = {};
  _.forEach(ANIMACOES, (val) => {
    tipoEmocaoXNome[val.tipo] = val.titulo;
  });
  return { emocoes, tipoEmocaoXNome };
};

//

// Calcula os dias do mes
const dias =
  differenceInDays(new Date(), parseISO(format(new Date(), "yyyy-MM-01"))) + 1;

let periodos = [];

if (dias > 15) {
  periodos = [1, 7, 15];
} else if (dias > 7) {
  periodos = [1, 7];
} else if (dias > 1) {
  periodos = [1];
}
periodos.push(dias);
// Funcao para atualizar os dados relacionados as emocoes
async function atualizaDadosEmocoes(formData, setDados, setAguardar, t) {
  try {
    if (setAguardar) setAguardar(true);

    const resp = await axios.post(window.location.pathname, formData);
    if (resp.status !== 200) {
      ToastErro(t("registro_emocional.errorInternal"));
      return false;
    }
    if (resp.data.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    setDados(resp.data);
  } catch (error) {
    console.log(error);
    ToastErro(t("registro_emocional.errorUnknown"));
  } finally {
    if (setAguardar) setAguardar(false);
  }
}
//
function RegistroEmocional() {
  const { t } = useTranslation();
  const [dados, setDados] = useState(null);
  //const [aguardar, setAguardar] = useState(false);

  useEffect(() => {
    const formData = new FormData();
    formData.append(
      "dados",
      JSON.stringify({
        de: format(new Date(), "yyyy-MM-dd"),
        ate: format(new Date(), "yyyy-MM-dd"),
      })
    );
    atualizaDadosEmocoes(formData, setDados, null, t);
  }, [setDados, t]);
  return (
    <BackgroundRouter>
      {dados ? <Corpo dados={dados} atualizaDados={setDados} /> : null}
    </BackgroundRouter>
  );
}
const Corpo = ({ dados, atualizaDados }) => {
  const { t } = useTranslation();
  const { emocoes, tipoEmocaoXNome } = getEmotions(t);
  const ANIMACOES = getAnimations(t);
  const [verQuantidade, setVerQuantidade] = useToggle(false);
  // Chaveia e contabiliza cada emocao
  const emocoesTotal = _.transform(
    {
      ...emocoes,
      ..._.countBy(dados, (val) => val.id_emocao),
    },
    (result, value, key) => {
      result[tipoEmocaoXNome[key]] = value;
    },
    {}
  );
  // Maior valor dentre as emocoes
  const maiorEmocao = _.maxBy(
    Object.keys(emocoesTotal),
    (k) => emocoesTotal[k]
  );
  // Cria um objeto para enviar como representação ao maior sentimento registrado no periodo
  const objMaiorSentimento = {
    titulo: maiorEmocao,
    animacao: _.filter(ANIMACOES, (val) => val.titulo === maiorEmocao)[0]
      .animacao,
    percentual: verQuantidade
      ? parseInt(emocoesTotal[maiorEmocao])
      : parseInt((emocoesTotal[maiorEmocao] / dados.length) * 100),
  };
  // Cria um array com todas as emocoes e seus totais
  const emocoesPorPercentual = _.map(ANIMACOES, (val) => {
    return {
      titulo: val.titulo,
      animacao: val.animacao,
      percentual: verQuantidade
        ? parseInt(emocoesTotal[val.titulo])
        : parseInt((emocoesTotal[val.titulo] / dados.length) * 100),
    };
  });
  // FUncao de callback para atualizar as emocoes
  const atualizaPeriodo = useCallback(
    (periodo) => {
      if (periodo) {
        const [de, ate] = periodo.split("_");
        const formData = new FormData();
        formData.append("dados", JSON.stringify({ de, ate }));

        atualizaDadosEmocoes(formData, atualizaDados, null, t);
      }
    },
    [atualizaDados, t]
  );

  return (
    <Container maxWidth={false}>
      <H6>{t("registro_emocional.title")}</H6>
      <Grid spacing={1} container>
        <Grid item xs={12} md={3}>
          <ClimaEsteMes {...objMaiorSentimento} verQuantidade={verQuantidade} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper elevation={4} sx={{ display: "flex", overflow: "auto" }}>
            <FiltroPorPeriodo
              periodoDefault={1}
              onClick={atualizaPeriodo}
              periodos={periodos}
              grid={{ sx: 12, md: 12 }}
            />
            <Hidden mdDown>
              <FormControlLabel
                sx={{ ml: 1 }}
                onChange={setVerQuantidade}
                control={<Switch checked={verQuantidade} />}
                label={t("registro_emocional.labelQuantity")}
              />
            </Hidden>
          </Paper>
          <Hidden mdUp>
            <FormControlLabel
              onChange={setVerQuantidade}
              control={<Switch checked={verQuantidade} />}
              label={t("registro_emocional.labelQuantity")}
            />
          </Hidden>
          <OutrasReacoes
            verQuantidade={verQuantidade}
            reacoes={emocoesPorPercentual}
          />
        </Grid>

        <Grid item xs={12}>
          <GraficoEmocional />
        </Grid>
      </Grid>
      <Grid container></Grid>
    </Container>
  );
};

RegistroEmocional.rota = "/registro_emocional";

export default RegistroEmocional;
