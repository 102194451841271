import axios from "axios";
import { memo, useCallback, useState } from "react";
import { ToastErro, formatarParaSelect } from "../../../utils/utils";
import * as yup from "yup";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AnimacaoSemDados,
  Body1,
  Body2,
  Caption,
  DrawerExibicao,
  EntradaForm,
  FabExtend,
  H6,
  Icone,
} from "../../../components";
import Dialog from "../../../components/dialog";
import {
  getSituacao,
  getSituacaoCor,
  getSituacaoIcone,
  getSituacaoTitulo,
} from "./configuracoes-utils";
import { useTranslation } from "react-i18next";

const URL_DEPENDENTES = "/dados_pessoais_dependentes";
//
const MODAL = {
  FORM_ADD: "FORM_ADD",
};

// Funcao de callback que confirma um dependente
const confirmarDadosDependente = (cpf, tipo, t) => {
  let formData = { cpf };
  //
  let fn;

  if (tipo === "DELETE") {
    fn = axios.delete;
    formData = { data: formData };
  } else {
    fn = axios.patch;
  }

  return new Promise(async (resolve, reject) => {
    const resp = await fn(URL_DEPENDENTES, formData);
    //
    if (resp.status !== 200) {
      reject(t("dados_cadastrais.errorServer"));
      return false;
    }
    if (resp.data?.erro) {
      reject(resp.data.erro);
      return false;
    }
    // Deu certo retorne o resolve
    resolve(resp.data.sucesso);
  });
};

// Formulario para inclusão/edição um dependente
const FormUpdAddDependente = memo(
  ({ dados, dependente, fnAtualizaDependente, fnFecharModal }) => {
    const { t } = useTranslation();
    const [aguardar, setAguardar] = useState(null);
    const _sexos = [
      ["M", t("dados_cadastrais.male")],
      ["F", t("dados_cadastrais.female")],
    ];

    const schema = [
      {
        grid: { xs: 12, md: 5 },
        name: "nome",
        type: "text",
        placeholder: t("dados_cadastrais.depPlaceholerNameDepend"),
        defaultValue: "",
        label: t("dados_cadastrais.depLabelNameDepend"),
      },
      {
        grid: { xs: 12, md: 4 },
        name: "cpf",
        type: "text",
        placeholder: t("dados_cadastrais.depPlaceholerCpfDepend"),
        defaultValue: "",
        label: t("dados_cadastrais.depLabelCpfDepend"),
        mask: [
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ],
      },
      {
        grid: { xs: 12, md: 3 },
        name: "nascimento",
        type: "date",
        placeholder: t("dados_cadastrais.depPlaceholerBirthDepend"),
        defaultValue: "",
        label: t("dados_cadastrais.depLabelBirthDepend"),
      },
      {
        grid: { xs: 12, md: 3 },
        name: "sexo",
        type: "select",
        itens: _sexos,
        autoFormat: true,
        defaultValue: "",
        label: t("dados_cadastrais.depLabelSexDepend"),
      },
      {
        grid: { xs: 12, md: 9 },
        name: "escolaridade",
        type: "select",
        itens: dados.escolaridade,
        autoFormat: true,
        defaultValue: "",
        label: t("dados_cadastrais.depLabelSchoolingDepend"),
      },
      {
        grid: { xs: 12, md: 12 },
        name: "grau",
        type: "select",
        itens: dados.grau,
        autoFormat: true,
        defaultValue: "",
        label: t("dados_cadastrais.depLabelDegreeDepend"),
      },
    ];
    // Se tiver sido enviado os dados do dependente preencha os valores default
    if (dependente) {
      schema[0].defaultValue = dependente.nome;
      schema[1].defaultValue = dependente.cpf;
      schema[2].defaultValue = dependente.nascimento;
      schema[3].defaultValue = formatarParaSelect(
        _sexos.filter((ele) => ele[0] === dependente.sexo)
      )[0];
      schema[4].defaultValue = dependente.escolaridade
        ? formatarParaSelect(
            dados.escolaridade.filter(
              (ele) => ele[0] === dependente.escolaridade
            )
          )[0]
        : "";
      schema[5].defaultValue = formatarParaSelect(
        dados.grau.filter((ele) => ele[0] === dependente.grau)
      )[0];
    }
    //
    const schemaValidator = yup.object().shape({
      nome: yup.string().min(3).required(),
      sexo: yup
        .object()
        .shape({
          label: yup.string().min(1).required(),
          value: yup.string().min(1).required(),
        })
        .required(),
      cpf: yup
        .string()
        .matches(/[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/)
        .required(),
      nascimento: yup.date().required(),
      escolaridade: yup
        .object()
        .shape({
          label: yup.string().min(1).required(),
          value: yup.string().min(1).required(),
        })
        .required(),
      grau: yup
        .object()
        .shape({
          label: yup.string().min(1).required(),
          value: yup.string().min(1).required(),
        })
        .required(),
    });

    const schemaMessageError = {
      nome: t("dados_cadastrais.depErrorNameDepend"),
      sexo: t("dados_cadastrais.depErrorSexDepend"),
      cpf: t("dados_cadastrais.depErrorCpfDepend"),
      nascimento: t("dados_cadastrais.depErrorBirthDepend"),
      escolaridade: t("dados_cadastrais.depErrorSchoolingDepend"),
      grau: t("dados_cadastrais.depErrorDegreeDepend"),
    };
    //
    const fn = async (val) => {
      const obj = {
        nome: val.nome,
        nascimento: format(val.nascimento, "yyyy-MM-dd"),
        grau: val.grau.value,
        cpf: val.cpf.replace(/\./g, "").replace("-", ""),
        escolaridade: val.escolaridade.value,
        sexo: val.sexo.value,
      };
      //
      setAguardar(true);

      try {
        const resp = await axios.put(URL_DEPENDENTES, obj);
        fnAtualizaDependente(dependente?.cpf || val.cpf, {
          ...obj,
          cpf: val.cpf,
          situacao: "R",
        });
        toast.dark(resp.data.sucesso, { type: "success" });

        fnFecharModal();
      } catch (error) {
      } finally {
        setAguardar(false);
      }
    };
    //
    return (
      <Container
        sx={{ minHeight: "50vh", maxHeight: "75vh", overflow: "auto" }}
        maxWidth="md"
      >
        <H6>{t("dados_cadastrais.titleDepend")}</H6>
        <EntradaForm
          schema={schema}
          schemaValidator={schemaValidator}
          schemaMessageError={schemaMessageError}
          onSubmit={fn}
          wait={aguardar}
        />
      </Container>
    );
  }
);

//
const ModalDependentes = ({
  modal,
  fecharModal,
  fnAtualizaDependente,
  dados_auxiliares,
}) => {
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  let corpo;

  switch (modal.tipo) {
    case MODAL.FORM_ADD:
      corpo = (
        <FormUpdAddDependente
          dados={dados_auxiliares}
          fnAtualizaDependente={fnAtualizaDependente}
          dependente={modal.dados}
          fnFecharModal={fecharModal}
        />
      );
      break;
    default:
      break;
  }

  return (
    <>
      {isMobile ? (
        <DrawerExibicao corpo={corpo} fecharDrawer={fecharModal} />
      ) : (
        <Dialog corpo={corpo} comoSlide fecharDialogo={fecharModal} />
      )}
    </>
  );
};

//
const DependenteItemDetalhes = memo(
  ({
    original,
    grau,
    nascimento,
    escolaridade,
    cpf,
    situacao,
    fnAtualizarDados,
    fnEditarDependente,
  }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
    //
    const [aguardar, setAguardar] = useState(null);
    // Cria um useCallback para confirmar o cadastro do dependente
    const confirmaDependente = useCallback(() => {
      setAguardar(true);
      confirmarDadosDependente(cpf.replace(/\./g, "").replace("-", ""))
        .then((resp) => {
          fnAtualizarDados(cpf, {
            ...original,
            situacao: "R",
          });
        })
        .catch((err) => {
          ToastErro(err);
        })
        .finally(() => {
          setAguardar(false);
        });
    }, [setAguardar, cpf, fnAtualizarDados, original]);
    // Callback para confirmar a exclusão de um dependente
    const confirmaExclusaoDependente = useCallback(() => {
      if (!window.confirm(t("dados_cadastrais.depConfirmDelete"))) {
        return false;
      }
      setAguardar(true);
      confirmarDadosDependente(
        cpf.replace(/\./g, "").replace("-", ""),
        "DELETE"
      )
        .then((resp) => {
          fnAtualizarDados(cpf);
        })
        .catch((err) => {
          ToastErro(err);
        })
        .finally(() => {
          setAguardar(false);
        });
    }, [setAguardar, t, cpf, fnAtualizarDados]);

    const botoes = [
      {
        titulo: t("dados_cadastrais.depTitleBtnConfigm"),
        onClick: confirmaDependente,
        texto: t("dados_cadastrais.depLabelBtnConfigm"),
        icone: "Check",
        variant: "contained",
      },
      {
        titulo: t("dados_cadastrais.depTitleBtnEdit"),
        onClick: () =>
          fnEditarDependente({ tipo: MODAL.FORM_ADD, dados: original }),
        texto: t("dados_cadastrais.depLabelBtnEdit"),
        icone: "Edit",
        variant: "outlined",
      },
      {
        titulo: t("dados_cadastrais.depTitleBtnDelete"),
        onClick: confirmaExclusaoDependente,
        texto: t("dados_cadastrais.depLabelBtnDelete"),
        icone: "Delete",
        variant: "text",
      },
    ];
    return (
      <Stack spacing={0.5}>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Body2>{t("dados_cadastrais.depLabelCpfDepend2")} :</Body2>
            <Body1>{cpf}</Body1>
          </Stack>
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={isMobile ? 0.5 : 3}
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Body2>{t("dados_cadastrais.depLabelBirthNasDepend")} :</Body2>
            <Body1>{format(parseISO(nascimento), "dd/MM/yyyy")}</Body1>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {isMobile ? (
              <Icone icone="School" />
            ) : (
              <Body2>{t("dados_cadastrais.depLabelSchoolingDepend")} :</Body2>
            )}
            <Body1>{escolaridade ? escolaridade : "Nenhum"}</Body1>
          </Stack>
        </Stack>
        {isMobile && <Divider />}
        <Stack direction="row">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {isMobile ? null : (
              <Body2>{t("dados_cadastrais.depLabelDegreeDepend")} :</Body2>
            )}
            <Body1>{grau}</Body1>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          {situacao === "B" &&
            botoes.map((ele, idx) => (
              <Button
                disabled={aguardar}
                key={idx}
                onClick={ele.onClick}
                title={ele.titulo}
                size="small"
                startIcon={
                  aguardar ? <CircularProgress /> : <Icone icone={ele.icone} />
                }
                variant={ele.variant}
              >
                {ele.texto}
              </Button>
            ))}
        </Stack>
      </Stack>
    );
  }
);

//
const DependenteItem = memo(
  ({
    original,
    cpf,
    nome,
    grau,
    nascimento,
    escolaridade,
    sexo,
    situacao,
    fnAtualizarDados,
    fnEditarDependente,
  }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

    return (
      <Paper
        elevation={4}
        sx={{
          my: 1,
        }}
      >
        <ListItem>
          {!isMobile && (
            <ListItemIcon
              title={
                sexo === "M"
                  ? `${t("dados_cadastrais.depLabelSexDepend")} ${t(
                      "dados_cadastrais.male"
                    )}`
                  : `${t("dados_cadastrais.depLabelSexDepend")} ${t(
                      "dados_cadastrais.female"
                    )}`
              }
            >
              {sexo === "M" ? <Icone icone="Male" /> : <Icone icone="Female" />}
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Chip
                  title={getSituacaoTitulo(situacao, t)}
                  size="small"
                  icon={getSituacaoIcone(situacao)}
                  label={getSituacao(situacao, t)}
                  color={getSituacaoCor(situacao)}
                />

                <Body1>{nome}</Body1>
              </Stack>
            }
            secondary={
              <DependenteItemDetalhes
                grau={grau}
                nascimento={nascimento}
                escolaridade={escolaridade}
                cpf={cpf}
                situacao={situacao}
                fnAtualizarDados={fnAtualizarDados}
                original={original}
                fnEditarDependente={fnEditarDependente}
              />
            }
          />
        </ListItem>
      </Paper>
    );
  }
);

// Componente que exibe a lista de dependentes do usuario
const Dependentes = ({ pessoais, dados_auxiliares }) => {
  const { t } = useTranslation();
  const [dependentes, setDependentes] = useState(pessoais?.dependentes || []);
  const [modalDependentes, setModalDependentes] = useState(null);

  // Funcao de callback para atualizar um dependente
  const atualizarDados = useCallback(
    (cpf, novosDados) => {
      // Se nao tiver novosDados e uma exclusao
      let novos;
      // Se o CPF nao existir inclua em novos
      if (_.filter(dependentes, (val) => val.cpf === cpf).length === 0) {
        novos = _.clone(dependentes);
        novos.push(novosDados);
      } else if (!novosDados) {
        novos = _.filter(dependentes, (val) => val.cpf !== cpf);
      } else {
        novos = _.map(dependentes, (val) => {
          if (val.cpf === cpf) {
            return novosDados;
          }
          return { ...val };
        });
      }
      setDependentes([...novos]);
    },
    [dependentes, setDependentes]
  );

  return (
    <>
      <H6>{t("dados_cadastrais.titlePage")}</H6>
      <FabExtend
        onClick={() => setModalDependentes({ tipo: MODAL.FORM_ADD })}
        title={t("dados_cadastrais.titleFabAddDepend")}
        text={<Body2>&nbsp; {t("dados_cadastrais.labelFabAddDepend")}</Body2>}
        icone="Groups"
      />
      {modalDependentes && (
        <ModalDependentes
          fnAtualizaDependente={atualizarDados}
          dados_auxiliares={dados_auxiliares}
          modal={modalDependentes}
          fecharModal={() => setModalDependentes(null)}
        />
      )}
      <Caption component="p">
        {t("dados_cadastrais.subtitleListOfDependent")}
      </Caption>
      {dependentes.length === 0 ? (
        <AnimacaoSemDados titulo={t("dados_cadastrais.titleNotDependent")} />
      ) : (
        <List>
          {dependentes.map((ele, idx) => {
            // Verifica o grau de parentesco
            const escolaridade = ele.escolaridade
              ? _.filter(
                  dados_auxiliares.escolaridade,
                  (val) => val[0] === _.trim(ele.escolaridade)
                )[0][1]
              : null;
            //
            const grau = ele.grau
              ? _.filter(
                  dados_auxiliares.grau,
                  (val) => val[0] === _.trim(ele.grau)
                )[0][1]
              : null;

            return (
              <DependenteItem
                {...ele}
                original={ele}
                escolaridade={escolaridade}
                grau={grau}
                fnAtualizarDados={atualizarDados}
                key={idx}
                fnEditarDependente={setModalDependentes}
              />
            );
          })}
        </List>
      )}
    </>
  );
};

export default Dependentes;
