import React, { useCallback, useState } from "react";
import * as yup from "yup";
import { Body1, EntradaForm, H6, Select } from "../../../components";
import { useToggle } from "react-use";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectStudents } from "./treinamento-selectors";
import { PLANTAS } from "../../../config";
import { obterValidador, VALIDADOR_TIPO } from "../../../utils/validadores";
import { trainningInstructorAddOrEdit } from "./treinamento-actions";
import { useTranslation } from "react-i18next";

const KEYS = {
  name: "nome",
  mat: "mat",
  plant: "planta",
  origin: "origem",
  entity: "entidade",
};

const originInternalExternal = [
  ["I", "Interno"],
  ["E", "Externo"],
];

const extractValuesInstructor = (instructor) => {
  return {
    [KEYS.name]: instructor.nome,
    [KEYS.mat]: instructor.mat || "",
    [KEYS.plant]: {
      key: instructor.planta || "",
      label: instructor.planta || "",
      value: instructor.planta || "",
    },
    [KEYS.origin]:
      instructor.origem === "I"
        ? {
            key: "I",
            label: "Interno",
            value: "I",
          }
        : {
            key: "E",
            label: "Externo",
            value: "E",
          },
    [KEYS.entity]: instructor.entidade,
  };
};

const createInstructor = (instructor) => {
  if (!instructor) return null;
  return {
    key: instructor[KEYS.plant].value + "_" + instructor[KEYS.mat],
    label: `${instructor[KEYS.mat]} - ${instructor[KEYS.name]}`,
    value: instructor[KEYS.plant].value + "_" + instructor[KEYS.mat],
  };
};

function TreinamentoAddUpdInstrutor({ instructor }) {
  const { t } = useTranslation(); // Destructure t from useTranslation
  const instructorValues = instructor
    ? extractValuesInstructor(instructor)
    : null;
  const [optionOrigin, setOptionOrigin] = useState(() => {
    if (instructorValues && instructorValues[KEYS.origin]) {
      return instructorValues[KEYS.origin];
    }
    return null;
  });
  const [instructorSelected, setInstructorSelected] = useState(() => {
    if (instructorValues && instructorValues[KEYS.origin].value === "I") {
      return createInstructor(instructorValues);
    }
    return null;
  });

  const onSetOptionOrigin = useCallback(
    (value) => {
      if (value.value === "E") {
        setInstructorSelected(null);
      }
      setOptionOrigin(value);
    },
    [setInstructorSelected, setOptionOrigin]
  );

  return (
    <Stack>
      <H6>{t("treinamento.trainningAddUpdInstTitleAdd")}</H6>
      <Stack>
        <Body1>{t("treinamento.trainningAddUpdInsLabelOrigin")}</Body1>
        <Select
          defaultValue={optionOrigin}
          options={originInternalExternal}
          disabled={Boolean(instructorValues)}
          autoFormat
          onChange={onSetOptionOrigin}
        />
        <Stack sx={{ my: 1 }}>
          {optionOrigin ? (
            optionOrigin.value === "I" ? (
              <>
                {!instructorSelected && (
                  <OptionSelectInstructor
                    instructorSelected={instructorSelected}
                    setInstructorSelected={setInstructorSelected}
                  />
                )}
                {instructorSelected && (
                  <FormAddUpdInstrutor
                    origin={optionOrigin}
                    instructor={instructorSelected}
                    isInternal
                    id={instructor?.id}
                    entity={instructorValues && instructorValues[KEYS.entity]}
                  />
                )}
              </>
            ) : (
              <FormAddUpdInstrutor
                key={optionOrigin.value}
                origin={optionOrigin}
                instructor={createInstructor(instructorValues)}
                entity={instructorValues && instructorValues[KEYS.entity]}
                isInternal={false}
                id={instructor?.id}
              />
            )
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}

const OptionSelectInstructor = ({
  instructorSelected,
  setInstructorSelected,
}) => {
  const { t } = useTranslation();
  const instructors = useSelector(selectStudents);
  return (
    <Stack>
      <Body1>{t("treinamento.trainningAddUpdInsLabelInstructor")}</Body1>
      <Select
        options={instructors}
        autoFormat
        defaultValue={instructorSelected}
        onChange={setInstructorSelected}
      />
    </Stack>
  );
};
const FormAddUpdInstrutor = ({
  id,
  origin,
  instructor,
  isInternal,
  entity,
}) => {
  const { t } = useTranslation();
  const plants = PLANTAS.map((item) => [item, item]);
  const [wait, setWait] = useToggle(false);
  const dispatch = useDispatch();
  const plantDefault = instructor?.value?.split("_")[0]?.trim();
  const matDefault = instructor?.value?.split("_")[1]?.trim();

  const schema = [
    {
      type: "text",
      name: KEYS.name,
      label: t("treinamento.trainningAddUpdInsLabelName"),
      placeholder: t("treinamento.trainningAddUpdInsPlaceholderName"),
      required: true,
      defaultValue: instructor?.label?.split("-")[1]?.trim(),
      disabled: isInternal,
    },
    {
      type: "text",
      name: KEYS.mat,
      label: t("treinamento.trainningAddUpdInsLabelMat"),
      placeholder: t("treinamento.trainningAddUpdInsPlaceholderMat"),
      required: true,
      defaultValue: matDefault,
      disabled: isInternal,
    },
    {
      type: "select",
      name: KEYS.plant,
      label: t("treinamento.trainningAddUpdInsLabelPlant"),
      placeholder: t("treinamento.trainningAddUpdInsPlaceholderPlant"),
      itens: plants,
      required: true,
      autoFormat: true,
      defaultValue: plantDefault
        ? { key: plantDefault, label: plantDefault, value: plantDefault }
        : null,
      disabled: isInternal,
    },
    {
      type: "text",
      name: KEYS.entity,
      label: t("treinamento.trainningAddUpdInsLabelEntity"),
      placeholder: t("treinamento.trainningAddUpdInsPlaceholderEntity"),
      defaultValue: entity,
      required: true,
    },
  ];
  const schemaMessageError = {
    [KEYS.name]: t("treinamento.trainningAddUpdInsErrorName"),
    [KEYS.mat]: t("treinamento.trainningAddUpdInsErrorMat"),
    [KEYS.plant]: t("treinamento.trainningAddUpdInsErrorPlant"),
    [KEYS.entity]: t("treinamento.trainningAddUpdInsErrorEntity"),
  };

  const schemaValidator = yup.object().shape({
    [KEYS.name]: yup.string().min(1).max(250).required(),
    [KEYS.mat]: yup.string().min(6).max(6).required(),
    [KEYS.plant]: obterValidador(VALIDADOR_TIPO.selectUnico, 1),
    [KEYS.entity]: yup.string().min(1).max(150).required(),
  });

  const onSubmit = useCallback(
    (data) => {
      const obj = {
        [KEYS.name]: data[KEYS.name],
        [KEYS.mat]: data[KEYS.mat],
        [KEYS.plant]: data[KEYS.plant].value,
        [KEYS.origin]: origin.value,
        [KEYS.entity]: data[KEYS.entity],
      };
      if (id) {
        obj.id = id;
      }

      dispatch(trainningInstructorAddOrEdit(obj, setWait));
    },
    [setWait, id, origin, dispatch]
  );

  return (
    <EntradaForm
      schema={schema}
      schemaValidator={schemaValidator}
      schemaMessageError={schemaMessageError}
      wait={wait}
      onSubmit={onSubmit}
    />
  );
};

export default TreinamentoAddUpdInstrutor;
