import React, { useCallback } from "react";
import { Confirmar } from "../../../components";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { rnciNcpCancelStatus, rnciNcpFecharModal } from "./rnci-ncp-actions";
import { useTranslation } from "react-i18next";

const getSubTitle = (status, t) => {
  switch (status) {
    case "A":
      return t("rnci_ncp.subtitleCancelStatus");
    case "S":
      return t("rnci_ncp.subtitleCancelStatus2");
    default:
    case "Q":
      return t("rnci_ncp.subtitleCancelStatus3");
  }
};

function RnciCancelStatus({ id, statusActualy }) {
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onConfirm = useCallback(() => {
    dispatch(rnciNcpCancelStatus({ codigo_rnci: id }, setWait));
  }, [dispatch, id, setWait]);
  //
  const onCancel = useCallback(() => {
    dispatch(rnciNcpFecharModal());
  }, [dispatch]);
  return (
    <Confirmar
      aguardar={wait}
      titulo={t("rnci_ncp.titleCancelStatus")}
      subtitulo={getSubTitle(statusActualy, t)}
      fnCancelar={onCancel}
      fnConfirmar={onConfirm}
    />
  );
}

export default RnciCancelStatus;
