import {
  Grow,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Icone } from "../components";
import { setIdxMenu } from "../redux/actions/menu-actions";

function ListMenusMobile() {
  const dispatch = useDispatch();

  const history = useHistory();
  const menus = history?.location?.state || [];
  //
  const onSelecionarMenu = useCallback(
    (rota) => {
      dispatch(setIdxMenu(5));
      history.replace(rota);
    },
    [history, dispatch]
  );
  return (
    <Grow in unmountOnExit>
      <List>
        {menus.map((ele, idx) => (
          <ListItemButton
            divider
            key={idx}
            onClick={() => onSelecionarMenu(ele[0])}
          >
            <ListItemIcon>
              <Icone icone={ele[2]} />
            </ListItemIcon>
            <ListItemText primary={ele[1]} />
          </ListItemButton>
        ))}
      </List>
    </Grow>
  );
}

export default ListMenusMobile;
