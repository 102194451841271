import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import BackgroundRouter from "../../background-router";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Grow,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  Body1,
  Body2,
  EntradaForm,
  H4,
  H6,
  Icone,
  Logo,
  Select,
} from "../../../components";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMat,
  selectPlant,
  seletorArea,
  seletorColab,
  seletorImpact,
  seletorPN,
  seletorProcess,
  // seletorThree,
  seletorTypeUpdate,
} from "./lesson_selectors";
import { format } from "date-fns";
import _ from "lodash";
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { DrawerDialog } from "../../../extra-components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { obterValidador, VALIDADOR_TIPO } from "../../../utils/validadores";
import Utils, { ToastErro } from "../../../utils/utils";
import axios from "axios";
import { lessonAdd } from "./lesson_action";
import { useToggle } from "react-use";
import { useTranslation } from "react-i18next";

const STR = {
  title: "FORMULÁRIO DE LIÇÕES APRENDIDAS",
  labelButtonGoBack: "Voltar",
  titleButtonBack: "Retornar a página de Lições Aprendidas",
  labelDownloadPDF: "Baixar Em PDF",
  titleButtonDownloadPdf: "Clique para baixar em PDF",
  placeholder: "Digite aqui...",
  labelImpact: "IMPACTO DO PROJETO",
  labelPlant: "PLANTA",
  labelPN: "PART NUMBER",
  labelProcessUnit: "UNIDADE DO PROCESSO",
  labelOperation: "OPERAÇÃO",
  labelLine: "LINHA",
  labelAreaAffected: "AREA AFETADA",
  labelDate: "DATA",
  labelDescAnomaly: "DESCRIÇÃO DA ANOMALIA",
  labelTitleEffect1: "QUAL É O SINTOMA efeito do problema ?",
  labelImageKO: "IMAGEM KO",
  labelImageOK: "IMAGEM OK",
  labelCauseRoot: "CAUSA RAIZ",
  labelTitleDescriptionProblem: "DESCRIÇÃO DO PROBLEMA 5W2H",
  labelWhat: "What/ What happened?",
  labelWhy: "Why/ Why it is a problem? ",
  labelWhen: "When/ When did it happen ?",
  labelWhere: "Where/ Where has been detected ?",
  labelWho: "Who/ Who has detected it ?",
  labelHow: "How/ How has it been detected?",
  labelHowMany: "How many/ How many ?",
  labelTitleEffect: "EFEITO",
  labelProblemImage: "PROBLEMA",
  labelSolutionImage: "SOLUÇÃO",
  labelDescProblem: "DESCRIÇÃO DO PROBLEMA",
  labelDesSolution: "DESCRIÇÃO DA SOLUÇÃO",
  labelDrawingMathematicalImage: "DESENHO MATEMÁTICA",
  labelFabricant: "FABRICANTE",
  labelTitleDocDefault: "ATUALIZAÇÃO DOS DOCUMENTOS PADRÃO",
  labelTableTitleDesc: "DESCRIÇÃO",
  labelTableTitleRevision: "NECESSÁRIO REVISAR? - SIM/NÃO",
  labelTableResponsible: "RESPONSÁVEL",
  labelTableData: "DATA",
  labelTableAccomplished: "REALIZADO",
  labelObservation: "OBSERVAÇÃO",
  labelCust: "CUSTO",
  labelBenef: "BENEFICIO",
  labelImageInsert: "Insira a imagem aqui",
  labelBtnAddEffect: "Incluir Efeito",
  labelBtnAddUpdateDoc: "CLIQUE AQUI PARA INSERIR",
  titleRemoveImageUpload: "Clique para escolher uma imagem para upload",
  titleRemoveEffect: "Clique para remover o efeito",
  titleFormAddTypeUpdate: "PREENCHA OS CAMPOS ABAIXO",
  errorListUpdate: "* Campo obrigatório",
  errorRevision: "* Campo obrigatório",
  errorResponsible: "* Campo obrigatório",
  errorDate: "* Campo obrigatório",
  errorAccomplished: "* Campo obrigatório",
  errorObservation: "* Mínimo de 3 caracteres",
  labelBtnSend: "ENVIAR",
  placeholderMoney: " 0,00",
};
// Nomes dos campos do form para a lista de tipos de documentos
const namesListType = {
  idTipoAtualizacao: "id_tipo_atualizacao",
  revisar: "revisar",
  matPlanta: "mat_planta",
  data: "data",
  realizado: "realizado",
  observacao: "observacao",
};
// Nomes dos campos do formulario geral
const namesForm = {
  idImpact: "id_impacto",
  plant: "planta",
  idUnitProcess: "id_unidadeprocesso",
  data: "data",
  operacao: "operacao",
  area: "area",
  pn: "pn",
  arvore: "arvore",
  anomaly: "descricao_anomalia",
  imageKO: "imagem_ko",
  imageOK: "imagem_ok",
  causeRoot: "causa_raiz",
  what: "what",
  why: "why",
  when: "when",
  where: "where",
  who: "who",
  how: "how",
  how_many: "how_many",
  imageProblem: "imagem_problema",
  imageSolution: "imagem_solucao",
  descriptionProblem: "descricao_problema",
  descriptionSolution: "descricao_solucao",
  imageDrawing: "imagem_desenho",
  fabric: "fabricante",
  effect: "efeito_",
  updateDoc: "atualizacao_documentos",
  coust: "custo",
  benef: "beneficio",
};
//
const validadorYup = yup.object().shape({
  [namesForm.idImpact]: obterValidador(VALIDADOR_TIPO.selectUnico),
  [namesForm.plant]: obterValidador(VALIDADOR_TIPO.texto, 3),
  [namesForm.idUnitProcess]: obterValidador(VALIDADOR_TIPO.selectUnico),
  [namesForm.data]: yup.date().required(),
  [namesForm.operacao]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.area]: obterValidador(VALIDADOR_TIPO.selectUnico),
  [namesForm.pn]: obterValidador(VALIDADOR_TIPO.selectUnico),
  // [namesForm.arvore]: obterValidador(VALIDADOR_TIPO.selectUnico),
  [namesForm.anomaly]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.causeRoot]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.what]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.why]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.when]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.where]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.who]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.how]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.how_many]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.descriptionProblem]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.descriptionSolution]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.fabric]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.coust]: obterValidador(VALIDADOR_TIPO.texto, 1),
  [namesForm.benef]: obterValidador(VALIDADOR_TIPO.texto, 1),
});
//

// Props para a area de visualizacao do corpo
const propsBodyView = {
  sx: { p: 1 },
  align: "center",
};
// Cor default dos cabecalhos
const colorHeaderField = "#8faadc"; //#8faadc
// Propriedades para o textfield
const propsTextField = {
  InputProps: {
    autoComplete: "off",
  },
  inputProps: {
    style: {
      fontSize: "1.2rem",
      textAlign: "center",
      border: "none",
    },
  },
};
// Estilo para o campo de imagem
const sxFieldImage = {
  flex: 1,
  height: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid #d9d9d9",
};
// Caixa para normalizar cabecalhos dos camos
const BoxHeader = ({ label, sx }) => {
  return (
    <Box
      sx={{
        height: 32,
        p: 1,
        background: ({ palette }) =>
          palette.mode === "dark" ? "#0f2a5f" : colorHeaderField,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Body2 align="center">{label}</Body2>
    </Box>
  );
};
// Caixa para normalizar campos de entrada de texto
const BoxField = ({ sx, children }) => {
  return (
    <Box
      sx={{
        border: "1px solid #d9d9d9",
        height: 48,
        display: "flex",
        alignItems: "flex-end",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

function LessonForm() {
  const mat = useSelector(selectMat);
  const [wait, setWait] = useToggle();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const messageErrors = useMemo(
    () => ({
      [namesForm.idImpact]: t("lesson.messageErrors.idImpact"),
      [namesForm.plant]: t("lesson.messageErrors.plant"),
      [namesForm.idUnitProcess]: t("lesson.messageErrors.idUnitProcess"),
      [namesForm.data]: t("lesson.messageErrors.data"),
      [namesForm.operacao]: t("lesson.messageErrors.operacao"),
      [namesForm.area]: t("lesson.messageErrors.area"),
      [namesForm.pn]: t("lesson.messageErrors.pn"),
      // [namesForm.arvore]: t("lesson.messageErrors."",
      [namesForm.anomaly]: t("lesson.messageErrors.anomaly"),
      [namesForm.causeRoot]: t("lesson.messageErrors.causeRoot"),
      [namesForm.what]: t("lesson.messageErrors.what"),
      [namesForm.why]: t("lesson.messageErrors.why"),
      [namesForm.when]: t("lesson.messageErrors.when"),
      [namesForm.where]: t("lesson.messageErrors.where"),
      [namesForm.who]: t("lesson.messageErrors.who"),
      [namesForm.how]: t("lesson.messageErrors.how"),
      [namesForm.how_many]: t("lesson.messageErrors.how_many"),
      [namesForm.descriptionProblem]: t(
        "lesson.messageErrors.descriptionProblem"
      ),
      [namesForm.descriptionSolution]: t(
        "lesson.messageErrors..descriptionSolution"
      ),
      [namesForm.fabric]: t("lesson.messageErrors..fabric"),
      [namesForm.coust]: t("lesson.messageErrors.coust"),
      [namesForm.benef]: t("lesson.messageErrors.benef"),
    }),
    [t]
  );

  const {
    formState: { errors },
    handleSubmit,
    unregister,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(validadorYup),
  });
  useEffect(() => {
    if (_.keys(errors).length > 0) {
      const key = _.keys(errors)[0];
      ToastErro(messageErrors[key]);
    }
  }, [errors, messageErrors]);
  // Realiza o envio do registro
  const onSubmit = useCallback(
    async (val) => {
      // Preencher os efeitos
      let effects = [];
      _.forEach(
        _.filter(_.keys(val), (k) => k.search(namesForm.effect) !== -1),
        (kEffect) => {
          effects.push(val[kEffect]);
        }
      );

      // Preencher o array de atualizacao de documentos
      let updateDocuments = [];

      if (val[namesForm.updateDoc] && val[namesForm.updateDoc].length > 0) {
        updateDocuments = _.map(val[namesForm.updateDoc], (item) => ({
          id_tipo_atualizacao: item[namesListType.idTipoAtualizacao].value,
          revisar: item[namesListType.revisar].value,
          mat: item[namesListType.matPlanta].value.split("_")[0],
          planta: item[namesListType.matPlanta].value.split("_")[1],
          data: item[namesListType.data].value,
          realizado: item[namesListType.realizado].value,
          observacao: item[namesListType.observacao].value,
        }));
      }

      // Preencher o objeto
      const obj = {
        matricula: mat,
        planta: val[namesForm.plant],
        [namesForm.idImpact]: val[namesForm.idImpact].value,
        [namesForm.idUnitProcess]: val[namesForm.idUnitProcess].value,
        [namesForm.pn]: val[namesForm.pn].value,
        [namesForm.operacao]: val[namesForm.operacao],
        [namesForm.area]: val[namesForm.area].value,
        // [namesForm.arvore]: val[namesForm.arvore].value,
        [namesForm.data]: format(val[namesForm.data], "yyyy-MM-dd HH:mm"),
        [namesForm.anomaly]: val[namesForm.anomaly],
        [namesForm.causeRoot]: val[namesForm.causeRoot],
        [namesForm.what]: val[namesForm.what],
        [namesForm.why]: val[namesForm.why],
        [namesForm.when]: val[namesForm.when],
        [namesForm.where]: val[namesForm.where],
        [namesForm.who]: val[namesForm.who],
        [namesForm.how]: val[namesForm.how],
        [namesForm.how_many]: val[namesForm.how_many],
        [namesForm.descriptionProblem]: val[namesForm.descriptionProblem],
        [namesForm.descriptionSolution]: val[namesForm.descriptionSolution],
        [namesForm.fabric]: val[namesForm.fabric],
        [namesForm.coust]: parseFloat(
          parseFloat(Utils.desconverter(val[namesForm.coust])).toFixed(2)
        ),
        [namesForm.benef]: parseFloat(
          parseFloat(Utils.desconverter(val[namesForm.benef])).toFixed(2)
        ),
        [namesForm.updateDoc]: updateDocuments,
        efeitos: effects,
      };

      // No caso dos campos de imagem, validar todos e enviar ao bucket
      // imagem_ok, imagem_ko, imagem_desenho, imagem_problema, imagem_solucao
      if (val[namesForm.imageOK]?.length < 1) {
        ToastErro(`Necessário informar a ${namesForm.imageOK}`);
        return;
      }
      if (val[namesForm.imageKO]?.length < 1) {
        ToastErro(`Necessário informar a ${namesForm.imageKO}`);
        return;
      }
      if (val[namesForm.imageProblem]?.length < 1) {
        ToastErro(`Necessário informar a ${namesForm.imageProblem}`);
        return;
      }
      if (val[namesForm.imageSolution]?.length < 1) {
        ToastErro(`Necessário informar a ${namesForm.imageSolution}`);
        return;
      }
      // if (val[namesForm.imageDrawing]?.length < 1) {
      //   ToastErro(`Necessário informar a ${namesForm.imageDrawing}`);
      //   return;
      // }
      // Todas as imagens foram validadas, vamos envia-las
      const formDataImages = new FormData();
      formDataImages.append(namesForm.imageKO, val[namesForm.imageKO]);
      formDataImages.append(namesForm.imageOK, val[namesForm.imageOK]);
      formDataImages.append(
        namesForm.imageSolution,
        val[namesForm.imageSolution]
      );
      formDataImages.append(
        namesForm.imageProblem,
        val[namesForm.imageProblem]
      );
      // Esta imagem é opcional, caso não enviada ela não faz parte dos inputs ao bucket
      if (val[namesForm.imageDrawing]) {
        formDataImages.append(
          namesForm.imageDrawing,
          val[namesForm.imageDrawing]
        );
      }
      try {
        const response = await axios.post("/bucket", formDataImages, {
          headers: {
            ContentType: "multipart/form-data",
          },
        });
        obj[namesForm.imageKO] = response.data[namesForm.imageKO][0].url;
        obj[namesForm.imageOK] = response.data[namesForm.imageOK][0].url;
        obj[namesForm.imageSolution] =
          response.data[namesForm.imageSolution][0].url;
        obj[namesForm.imageProblem] =
          response.data[namesForm.imageProblem][0].url;
        obj[namesForm.imageDrawing] = val[namesForm.imageDrawing]
          ? response.data[namesForm.imageDrawing][0].url
          : "";
      } catch (error) {
        if (error?.message) {
          ToastErro(error.message);
        } else {
          ToastErro("Erro ao enviar arquivos");
        }

        return false;
      }
      // Tudo OK pode inserir os dados no servidor
      dispatch(lessonAdd(obj, setWait, history.goBack));
    },
    [mat, dispatch, setWait, history]
  );

  return (
    <BackgroundRouter>
      <Container maxWidth={false}>
        <LessonGoBack />
        <LessonFormViewer
          control={control}
          unregister={unregister}
          setValue={setValue}
        />
        <Stack direction="row" justifyContent="center">
          <Button
            variant="contained"
            sx={{ my: 2, width: { xs: "100%", md: "50%" } }}
            onClick={handleSubmit(onSubmit)}
            disabled={wait}
          >
            {t("lesson.labelBtnSend")}
          </Button>
        </Stack>
      </Container>
    </BackgroundRouter>
  );
}
export const LessonFormViewer = ({
  control,
  unregister,
  setValue,
  viewData,
}) => {
  return (
    <Grid id="lesson_form_id" container spacing={0.1}>
      <Grid item xs={12}>
        <LessonFormHeader />
      </Grid>
      <Grid item xs={12}>
        <LessonFormInfoHeader viewData={viewData} control={control} />
      </Grid>
      <Grid item xs={12}>
        <LessonFormDescAnomaly viewData={viewData} control={control} />
      </Grid>

      <Grid item xs={12}>
        <LessonFormEffectProblem viewData={viewData} control={control} />
      </Grid>

      <Grid item xs={12}>
        <LessonFormDescriptionProblem viewData={viewData} control={control} />
        <LessonEffectAndDescriptionProblem
          viewData={viewData}
          unregister={unregister}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <LessonUpdateDocDefault
          viewData={viewData}
          setValue={setValue}
          control={control}
        />
      </Grid>

      <Grid item xs={12}>
        <LessonFormCustBenef viewData={viewData} control={control} />
      </Grid>
    </Grid>
  );
};

export const LessonGoBack = ({ downloadPDF }) => {
  const [loading, setLoading] = useToggle();
  const history = useHistory();
  const { t } = useTranslation();
  //
  const onDownloadPDF = useCallback(() => {
    (async function () {
      try {
        setLoading();
        const response = await axios.get(`/lesson_api/download/${downloadPDF}`);
        const a = document.createElement("a");
        a.href = response.data.url;
        a.target = "_blank";
        a.setAttribute(
          "download",
          response.data.url.substring(response.data.url.lastIndexOf("/") + 1)
        );
        a.click();
      } catch (error) {
        if (error?.data?.message) {
          ToastErro(error.data.message);
        } else if (error?.message) {
          ToastErro(error.message);
        } else {
          ToastErro(error);
        }
      } finally {
        setLoading();
      }
    })();
  }, [downloadPDF, setLoading]);
  //
  return (
    <Stack direction="row" sx={{ my: 1 }} justifyContent="space-between">
      <Button
        color="primary"
        title={t("lesson.titleButtonBack")}
        onClick={() => history.goBack()}
        startIcon={<Icone icone="Undo" />}
        variant="text"
      >
        {t("lesson.labelButtonGoBack")}
      </Button>
      {downloadPDF && (
        <Button
          title={t("lesson.titleButtonDownloadPdf")}
          variant="contained"
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <Icone icone="PictureAsPdf" />
            )
          }
          onClick={onDownloadPDF}
        >
          {t("lesson.labelDownloadPDF")}
        </Button>
      )}

      <div />
    </Stack>
  );
};
//
const LessonFormHeader = () => {
  const isMobile = useTheme()?.isMobile;
  const { t } = useTranslation();
  //
  return (
    <Paper elevation={1} sx={{ borderRadius: 0 }}>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        alignItems={{ xs: "stretch", lg: "center" }}
        gap={1}
        sx={{ height: { xs: "auto", lg: 48 } }}
      >
        <Box sx={{ p: 1 }}>
          <Logo />
        </Box>
        <Box
          sx={{
            p: { xs: 2, lg: 0 },
            height: "100%",
            background: "#020060",
            color: ({ palette }) => palette.primary.contrastText,
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isMobile ? (
            <Body1>{t("lesson.title")}</Body1>
          ) : (
            <H4>{t("lesson.title")}</H4>
          )}
        </Box>
      </Stack>
    </Paper>
  );
};
//
const LessonFormInfoHeader = ({ viewData, control }) => {
  const impacts = useSelector(seletorImpact);
  const plant = useSelector(selectPlant);
  const process = useSelector(seletorProcess);
  const pn = useSelector(seletorPN);
  const area = useSelector(seletorArea);
  // const three = useSelector(seletorThree);
  const { t } = useTranslation();

  const options = [
    {
      name: namesForm.idImpact,
      placeholder: t("lesson.placeholder"),
      label: t("lesson.labelImpact"),
      defaultValue: viewData ? viewData.impacto_descricao : "",
      items: impacts,
      type: "select",
    },
    {
      name: namesForm.plant,
      placeholder: t("lesson.placeholder"),
      label: t("lesson.labelPlant"),
      defaultValue: viewData ? viewData.planta : plant,
      disabled: true,
      type: "text",
    },
    {
      name: namesForm.idUnitProcess,
      placeholder: t("lesson.placeholder"),
      label: t("lesson.labelProcessUnit"),
      defaultValue: viewData ? viewData.unidadeprocesso_descricao : "",
      items: process,
      type: "select",
    },
    {
      name: namesForm.data,
      placeholder: t("lesson.placeholder"),
      label: t("lesson.labelDate"),
      defaultValue: viewData
        ? Utils.converterDataHora(viewData.data)
        : format(new Date(), "yyyy-MM-dd"),
      // disabled: true,
      type: "date",
    },

    {
      name: namesForm.operacao,
      placeholder: t("lesson.placeholder"),
      label: t("lesson.labelOperation"),
      defaultValue: viewData ? viewData.operacao : "",
      type: "text",
    },
  ];
  const options2 = [
    {
      name: namesForm.area,
      placeholder: t("lesson.placeholder"),
      label: t("lesson.labelLine"),
      defaultValue: viewData ? viewData.area_descricao : "",
      type: "select",
      items: area,
    },
    {
      flex: 2,
      name: namesForm.pn,
      placeholder: t("lesson.placeholder"),
      label: t("lesson.labelPN"),
      defaultValue: viewData ? viewData.pn : "",
      type: "select",
      items: pn,
    },

    // {
    //   name: namesForm.arvore,
    //   placeholder: t("lesson.placeholder"),
    //   label: t("lesson.labelAreaAffected"),
    //   defaultValue: viewData ? viewData.arvore_descricao : "",
    //   type: "select",
    //   items: three,
    // },
  ];

  return (
    <Paper sx={{ borderRadius: 0 }}>
      <Stack direction={{ xs: "column", lg: "row" }} gap={0.2}>
        {options.map(
          ({
            disabled,
            label,
            defaultValue,
            placeholder,
            type,
            name,
            items,
            flex,
          }) => (
            <Stack sx={{ flex: flex || 1 }} key={label}>
              <BoxHeader label={label} />
              <BoxField sx={{ display: "block", height: "auto" }}>
                {viewData ? (
                  <Body1 {...propsBodyView}>{defaultValue}</Body1>
                ) : (
                  <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field }) =>
                      type === "select" ? (
                        <Select
                          {...field}
                          options={items}
                          autoFormat
                          disabled={disabled}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          disabled={disabled}
                          type={type}
                          variant="standard"
                          placeholder={placeholder}
                          {...propsTextField}
                          {...field}
                        />
                      )
                    }
                  />
                )}
              </BoxField>
            </Stack>
          )
        )}
      </Stack>
      <Stack direction={{ xs: "column", lg: "row" }} gap={0.2}>
        {options2.map(
          ({
            disabled,
            label,
            defaultValue,
            placeholder,
            type,
            name,
            flex,
            items,
          }) => (
            <Stack sx={{ flex: flex || 1 }} key={label}>
              <BoxHeader label={label} />
              <BoxField sx={{ display: "block", height: "auto" }}>
                {viewData ? (
                  <Body1 {...propsBodyView}>{defaultValue}</Body1>
                ) : (
                  <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field }) =>
                      type === "select" ? (
                        <Select
                          {...field}
                          options={items}
                          autoFormat
                          disabled={disabled}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          disabled={disabled}
                          type={type}
                          variant="standard"
                          placeholder={placeholder}
                          {...propsTextField}
                          {...field}
                        />
                      )
                    }
                  />
                )}
              </BoxField>
            </Stack>
          )
        )}
      </Stack>
    </Paper>
  );
};
//
const LessonFormDescAnomaly = ({ viewData, control }) => {
  const { t } = useTranslation();

  return (
    <Paper elevation={0} sx={{ borderRadius: 0 }}>
      <Stack direction={{ xs: "column", lg: "row" }} alignItems="" gap={0.2}>
        <BoxHeader label={t("lesson.labelDescAnomaly")} sx={{ height: 48 }} />
        <BoxField sx={{ flex: 1, height: "auto" }}>
          {viewData ? (
            <Body1 {...propsBodyView}>{viewData.descricao_anomalia}</Body1>
          ) : (
            <Controller
              name={namesForm.anomaly}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder={t("lesson.placeholder")}
                  {...propsTextField}
                  {...field}
                />
              )}
            />
          )}
        </BoxField>
      </Stack>
    </Paper>
  );
};
//
const LessonFormEffectProblem = ({ viewData, control }) => {
  const { t } = useTranslation();

  const isMobile = useTheme()?.isMobile;
  let optionsText = [
    {
      defaultValue: viewData ? viewData.causa_raiz : "",
      name: namesForm.causeRoot,
      type: "text",
      label: t("lesson.labelCauseRoot"),
    },
  ];
  let options = [
    {
      defaultValue: viewData ? viewData.imagem_ko : "",
      name: namesForm.imageKO,
      type: "image",
      label: t("lesson.labelImageKO"),
    },
    {
      defaultValue: viewData ? viewData.imagem_ok : "",
      name: namesForm.imageOK,
      type: "image",
      label: t("lesson.labelImageOK"),
    },
  ];
  // Se nao for mobile e qualquer tela
  if (!isMobile) {
    options = options.concat(optionsText);
  }

  return (
    <Paper elevation={0} sx={{ borderRadius: 0 }}>
      {isMobile ? (
        <Stack>
          <Stack direction="row">
            <BoxHeader sx={{ flex: 2 }} label={t("lesson.labelTitleEffect1")} />
          </Stack>
          <Stack direction="row">
            {options.map(({ name, defaultValue, type, label }) => (
              <BoxField
                key={label}
                sx={{
                  flex: 1,
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  border: "1px solid #d9d9d9",
                }}
              >
                {viewData ? (
                  <LessonFormImageUpload
                    defaultValue={defaultValue}
                    label={t("lesson.labelImageInsert")}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field }) => (
                      <LessonFormImageUpload
                        onChange={field.onChange}
                        defaultValue={defaultValue}
                        label={t("lesson.labelImageInsert")}
                      />
                    )}
                  />
                )}
              </BoxField>
            ))}
          </Stack>
          <Stack>
            <BoxHeader sx={{ flex: 1 }} label={t("lesson.labelCauseRoot")} />
            {optionsText.map(({ name, defaultValue, type, label }) => (
              <BoxField
                key={label}
                sx={{
                  flex: 1,
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  border: "1px solid #d9d9d9",
                }}
              >
                {viewData ? (
                  <Body1 {...propsBodyView}>{defaultValue}</Body1>
                ) : (
                  <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type="textarea"
                        multiline
                        minRows={3}
                        variant="standard"
                        placeholder={t("lesson.placeholder")}
                        {...propsTextField}
                        {...field}
                      />
                    )}
                  />
                )}
              </BoxField>
            ))}
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <Stack direction="row">
            <BoxHeader sx={{ flex: 2 }} label={t("lesson.labelTitleEffect1")} />
            <BoxHeader sx={{ flex: 1 }} label={t("lesson.labelCauseRoot")} />
          </Stack>
          <Stack direction="row">
            {options.map(({ name, defaultValue, type, label }) => (
              <BoxField
                key={label}
                sx={{
                  flex: 1,
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  border: "1px solid #d9d9d9",
                }}
              >
                {type === "image" ? (
                  viewData ? (
                    <LessonFormImageUpload
                      defaultValue={defaultValue}
                      label={t("lesson.labelImageInsert")}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={name}
                      defaultValue={defaultValue}
                      render={({ field }) => (
                        <LessonFormImageUpload
                          onChange={field.onChange}
                          defaultValue={defaultValue}
                          label={t("lesson.labelImageInsert")}
                        />
                      )}
                    />
                  )
                ) : viewData ? (
                  <Body1 {...propsBodyView}>{defaultValue}</Body1>
                ) : (
                  <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type="textarea"
                        multiline
                        minRows={3}
                        variant="standard"
                        placeholder={t("lesson.placeholder")}
                        {...propsTextField}
                        {...field}
                      />
                    )}
                  />
                )}
              </BoxField>
            ))}
          </Stack>
        </Stack>
      )}
    </Paper>
  );
};
//
const LessonFormImageUpload = ({ label, onChange, defaultValue }) => {
  const [valueLoadImage, setValueLoadImage] = useState(defaultValue);
  const ref = useRef(null);
  const { t } = useTranslation();

  const onIntentLoadImage = useCallback(() => {
    ref.current.click();
  }, [ref]);
  // Veja se a imagem foi upada
  const onLoadImage = useCallback(
    (e) => {
      let input = e.target;
      if (input.files && input.files[0]) {
        // Insere o valor da imagem no form
        if (onChange) {
          onChange(input.files[0]);
        }
        let leitor = new FileReader();

        leitor.onload = function (e) {
          setValueLoadImage(e.target.result);
        };

        leitor.readAsDataURL(input.files[0]);
      }
    },
    [setValueLoadImage, onChange]
  );
  // Não inserir a imagem no form
  const onDeleteImage = useCallback(() => {
    if (onChange) {
      onChange(null);
    }
    setValueLoadImage(null);
  }, [onChange, setValueLoadImage]);
  //
  return (
    <>
      <input
        accept=".png, .jpg"
        ref={ref}
        onChange={onLoadImage}
        type="file"
        style={{ display: "none" }}
      />
      {valueLoadImage ? (
        <div style={{ position: "relative" }}>
          {!defaultValue && (
            <Chip
              title={t("lesson.titleRemoveImageUpload")}
              clickable
              onClick={onDeleteImage}
              variant="filled"
              color="error"
              sx={{
                zIndex: 10,
                position: "absolute",
                top: 4,
                right: 4,
              }}
              label={<Icone sx={{ fontSize: 28 }} icone="Close" />}
            />
          )}
          <img
            alt={label}
            style={{
              width: "100%",
              minHeight: 194,
            }}
            src={valueLoadImage}
          />
        </div>
      ) : (
        <ButtonBase sx={{ width: "100%" }} onClick={onIntentLoadImage}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 196 }}
          >
            <Icone color="primary" icone="FileUpload" sx={{ fontSize: 72 }} />
            <Body1 color="primary">{label}</Body1>
          </Stack>
        </ButtonBase>
      )}
    </>
  );
};
//
const LessonFormDescriptionProblem = ({ viewData, control }) => {
  const isMobile = useTheme()?.isMobile;
  const { t } = useTranslation();
  //
  const questions = [
    {
      name: namesForm.what,
      defaultValue: viewData ? viewData.what : "",
      label: t("lesson.labelWhat"),
    },
    {
      name: namesForm.why,
      defaultValue: viewData ? viewData.why : "",
      label: t("lesson.labelWhy"),
    },
    {
      name: namesForm.when,
      defaultValue: viewData ? viewData.when : "",
      label: t("lesson.labelWhen"),
    },
    {
      name: namesForm.where,
      defaultValue: viewData ? viewData.where : "",
      label: t("lesson.labelWhere"),
    },
    {
      name: namesForm.who,
      defaultValue: viewData ? viewData.who : "",
      label: t("lesson.labelWho"),
    },
    {
      name: namesForm.how,
      defaultValue: viewData ? viewData.how : "",
      label: t("lesson.labelHow"),
    },
    {
      name: namesForm.how_many,
      defaultValue: viewData ? viewData.how_many : "",
      label: t("lesson.labelHowMany"),
    },
  ];
  //
  const images = [
    {
      name: namesForm.imageProblem,
      defaultValue: viewData ? viewData.imagem_problema : "",
      label: t("lesson.labelImageInsert"),
    },
    {
      name: namesForm.imageSolution,
      defaultValue: viewData ? viewData.imagem_solucao : "",
      label: t("lesson.labelImageInsert"),
    },
  ];

  return (
    <Paper elevation={0} sx={{ borderRadius: 0 }}>
      <Stack>
        {isMobile ? (
          <Stack>
            <BoxHeader label={t("lesson.labelTitleDescriptionProblem")} />
            <Stack gap={0.2}>
              {questions.map(({ label, defaultValue, name }) => (
                <Stack key={label} direction="column">
                  <BoxHeader label={label} sx={{ height: "auto" }} />
                  <BoxField sx={{ height: "auto" }}>
                    {viewData ? (
                      <Body1 {...propsBodyView}>{defaultValue}</Body1>
                    ) : (
                      <Controller
                        name={name}
                        control={control}
                        defaultValue={defaultValue}
                        render={({ field }) => (
                          <TextField
                            type="textarea"
                            fullWidth
                            multiline
                            minRows={2}
                            variant="standard"
                            placeholder={t("lesson.placeholder")}
                            {...propsTextField}
                            {...field}
                          />
                        )}
                      />
                    )}
                  </BoxField>
                </Stack>
              ))}
            </Stack>
          </Stack>
        ) : (
          <>
            <Stack direction="row">
              <BoxHeader
                sx={{ flex: 2 }}
                label={t("lesson.labelTitleDescriptionProblem")}
              />
              <BoxHeader
                sx={{ flex: 1 }}
                label={t("lesson.labelProblemImage")}
              />
              <BoxHeader
                sx={{ flex: 1 }}
                label={t("lesson.labelSolutionImage")}
              />
            </Stack>
            <Stack direction="row">
              <Stack sx={{ flex: 2 }} gap={0.2}>
                {questions.map(({ label, defaultValue, name }) => (
                  <Stack key={label} direction="row">
                    <BoxHeader label={label} sx={{ flex: 1, height: "auto" }} />
                    <BoxField sx={{ flex: 1, height: "auto" }}>
                      {viewData ? (
                        <Body1 {...propsBodyView}>{defaultValue}</Body1>
                      ) : (
                        <Controller
                          name={name}
                          control={control}
                          defaultValue={defaultValue}
                          render={({ field }) => (
                            <TextField
                              type="textarea"
                              fullWidth
                              multiline
                              minRows={2}
                              variant="standard"
                              placeholder={t("lesson.placeholder")}
                              {...propsTextField}
                              {...field}
                            />
                          )}
                        />
                      )}
                    </BoxField>
                  </Stack>
                ))}
              </Stack>
              {images.map(({ name, defaultValue, label }) =>
                viewData ? (
                  <BoxField sx={sxFieldImage}>
                    <LessonFormImageUpload
                      defaultValue={defaultValue}
                      label={label}
                    />
                  </BoxField>
                ) : (
                  <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field }) => (
                      <BoxField sx={sxFieldImage}>
                        <LessonFormImageUpload
                          defaultValue={defaultValue}
                          onChange={field.onChange}
                          label={label}
                        />
                      </BoxField>
                    )}
                  />
                )
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Paper>
  );
};
//
const LessonEffectAndDescriptionProblem = ({
  viewData,
  unregister,
  control,
}) => {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;
  const [effect, setEffect] = useState(
    viewData ? _.map(viewData.efeitos, (item) => item.descricao) : []
  );
  const options = [
    {
      labelHeader: t("lesson.labelDescProblem"),
      defaultValue: viewData ? viewData.descricao_problema : "",
      name: namesForm.descriptionProblem,
    },
    {
      labelHeader: t("lesson.labelDesSolution"),
      defaultValue: viewData ? viewData.descricao_solucao : "",
      name: namesForm.descriptionSolution,
    },
  ];
  // Campo para imagem do desenho e o campo do fabricante
  const drawingAndFabric = [
    {
      defaultValue: viewData ? viewData.imagem_desenho : "",
      sx: { ...sxFieldImage, flex: 2 },
      type: "image",
      name: namesForm.imageDrawing,
      label: t("lesson.labelDrawingMathematicalImage"),
      labelHeader: t("lesson.labelDrawingMathematicalImage"),
    },
    {
      defaultValue: viewData ? viewData.fabricante : "",
      sx: { flex: 1, height: "auto" },
      type: "text",
      name: namesForm.fabric,
      labelHeader: t("lesson.labelFabricant"),
    },
  ];
  // Exclui um efeito da inserção manual dos mesmos
  const onDeleteEffect = useCallback(
    (idx) => {
      const index = effect.indexOf(idx);
      const clone = _.clone(effect);
      const nameEffect = clone.splice(index, 1);
      // Remove ele do sistema de registros
      unregister(`${namesForm.effect}${nameEffect}`);
      setEffect(clone);
    },
    [setEffect, unregister, effect]
  );
  // Adiciona um novo registro relacionado ao efeito
  const onAddEffect = useCallback(() => {
    setEffect((val) => [...val, _.uniqueId()]);
  }, [setEffect]);

  //
  return (
    <Paper elevation={0} sx={{ borderRadius: 0 }}>
      {isMobile ? (
        <Stack sx={{ mt: 0.2 }}>
          <BoxHeader sx={{ flex: 2 }} label={t("lesson.labelTitleEffect")} />
          <BoxField
            sx={{
              flex: 2,
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              border: "none",
            }}
          >
            {effect.map((value, idx) => (
              <Grow in key={value} unmountOnExit>
                <Stack direction="column" key={value} gap={0.2}>
                  <BoxHeader label={`EFEITO ${idx + 1}`} />
                  <BoxField sx={{ flex: 1 }}>
                    {viewData ? (
                      <Body1 {...propsBodyView}>{value}</Body1>
                    ) : (
                      <Controller
                        name={`${namesForm.effect}${value}`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            type="textarea"
                            multiline
                            minRows={1}
                            variant="standard"
                            placeholder={t("lesson.placeholder")}
                            {...propsTextField}
                            {...field}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  title={t("lesson.titleRemoveEffect")}
                                  color="error"
                                  onClick={() => onDeleteEffect(value)}
                                >
                                  <Icone icone="RemoveCircle" />
                                </IconButton>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  </BoxField>
                </Stack>
              </Grow>
            ))}
            {!viewData && (
              <Button
                variant="text"
                size="small"
                fullWidth
                sx={{ height: "100%" }}
                onClick={onAddEffect}
                startIcon={<Icone icone="Add" />}
              >
                {t("lesson.labelBtnAddEffect")}
              </Button>
            )}
          </BoxField>
          {options.map(({ labelHeader, name, defaultValue }) => (
            <Stack key={name}>
              <BoxHeader label={labelHeader} />

              <BoxField sx={{ height: "auto" }}>
                {viewData ? (
                  <Body1 {...propsBodyView}>{defaultValue}</Body1>
                ) : (
                  <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type="textarea"
                        multiline
                        minRows={4}
                        variant="standard"
                        placeholder={t("lesson.placeholder")}
                        {...propsTextField}
                        {...field}
                      />
                    )}
                  />
                )}
              </BoxField>
            </Stack>
          ))}

          {drawingAndFabric.map(
            ({ labelHeader, type, sx, name, defaultValue }) => (
              <Stack key={name}>
                <BoxHeader label={labelHeader} />
                <BoxField key={name} sx={{ ...sx }}>
                  {viewData ? (
                    type === "image" ? (
                      <LessonFormImageUpload
                        label={t("lesson.labelImageInsert")}
                        defaultValue={defaultValue}
                      />
                    ) : (
                      <Body1 {...propsBodyView}>{defaultValue}</Body1>
                    )
                  ) : (
                    <Controller
                      name={name}
                      control={control}
                      defaultValue={defaultValue}
                      render={({ field }) =>
                        type === "image" ? (
                          <LessonFormImageUpload
                            onChange={field.onChange}
                            label={t("lesson.labelImageInsert")}
                            defaultValue={defaultValue}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            type="textarea"
                            multiline
                            minRows={2}
                            variant="standard"
                            placeholder={t("lesson.placeholder")}
                            {...propsTextField}
                            {...field}
                          />
                        )
                      }
                    />
                  )}
                </BoxField>
              </Stack>
            )
          )}
        </Stack>
      ) : (
        <Stack sx={{ mt: 0.2 }}>
          {/*  */}
          <Stack direction="row">
            <BoxHeader sx={{ flex: 2 }} label={t("lesson.labelTitleEffect")} />
            <BoxHeader sx={{ flex: 1 }} label={t("lesson.labelDescProblem")} />
            <BoxHeader sx={{ flex: 1 }} label={t("lesson.labelDesSolution")} />
          </Stack>
          {/*  */}
          <Stack direction="row">
            <BoxField
              sx={{
                flex: 2,
                height: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                border: "none",
              }}
            >
              {effect.map((value, idx) => (
                <Grow in key={value} unmountOnExit>
                  <Stack direction="row" key={value} gap={0.2}>
                    <BoxHeader label={`EFEITO ${idx + 1}`} />
                    <BoxField sx={{ flex: 1 }}>
                      {viewData ? (
                        <Body1 {...propsBodyView}>{value}</Body1>
                      ) : (
                        <Controller
                          name={`${namesForm.effect}${value}`}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              type="textarea"
                              multiline
                              minRows={1}
                              variant="standard"
                              placeholder={t("lesson.placeholder")}
                              {...propsTextField}
                              {...field}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    title={t("lesson.titleRemoveEffect")}
                                    color="error"
                                    onClick={() => onDeleteEffect(value)}
                                  >
                                    <Icone icone="RemoveCircle" />
                                  </IconButton>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    </BoxField>
                  </Stack>
                </Grow>
              ))}
              {!viewData && (
                <Button
                  variant="text"
                  size="small"
                  fullWidth
                  sx={{ height: "100%" }}
                  onClick={onAddEffect}
                  startIcon={<Icone icone="Add" />}
                >
                  {t("lesson.labelBtnAddEffect")}
                </Button>
              )}
            </BoxField>
            {options.map(({ name, defaultValue }) => (
              <BoxField key={name} sx={{ flex: 1, height: "auto" }}>
                {viewData ? (
                  <Body1 {...propsBodyView}>{defaultValue}</Body1>
                ) : (
                  <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type="textarea"
                        multiline
                        minRows={4}
                        variant="standard"
                        placeholder={t("lesson.placeholder")}
                        {...propsTextField}
                        {...field}
                      />
                    )}
                  />
                )}
              </BoxField>
            ))}
          </Stack>
          {/*  */}
          <Stack direction="row">
            <BoxHeader
              sx={{ flex: 2 }}
              label={t("lesson.labelDrawingMathematicalImage")}
            />
            <BoxHeader sx={{ flex: 1 }} label={t("lesson.labelFabricant")} />
          </Stack>
          <Stack direction="row">
            {drawingAndFabric.map(({ type, sx, name, defaultValue }) => (
              <BoxField key={name} sx={{ ...sx }}>
                {viewData ? (
                  type === "image" ? (
                    <LessonFormImageUpload
                      label={t("lesson.labelImageInsert")}
                      defaultValue={defaultValue}
                    />
                  ) : (
                    <Body1 {...propsBodyView}>{defaultValue}</Body1>
                  )
                ) : (
                  <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field }) =>
                      type === "image" ? (
                        <LessonFormImageUpload
                          onChange={field.onChange}
                          label={STR.labelImageInsert}
                          defaultValue={defaultValue}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          type="textarea"
                          multiline
                          minRows={2}
                          variant="standard"
                          placeholder={STR.placeholder}
                          {...propsTextField}
                          {...field}
                        />
                      )
                    }
                  />
                )}
              </BoxField>
            ))}
          </Stack>
        </Stack>
      )}
    </Paper>
  );
};
//
const LessonUpdateDocDefault = ({ viewData, control, setValue }) => {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;
  const typeUpdate = useSelector(seletorTypeUpdate);
  const [listTypeUpdate, setListTypeUpdate] = useState(typeUpdate);
  const [rows, setRows] = useState([]);
  const [modal, setModal] = useState(null);
  // Orodem dos campos (para ajudar na organizacao da tabela)
  const namesListOrder = useMemo(
    () => [
      namesListType.idTipoAtualizacao,
      namesListType.revisar,
      namesListType.matPlanta,
      namesListType.data,
      namesListType.realizado,
      namesListType.observacao,
    ],
    []
  );

  // Verifica se tem viewData, se sim padronizar os campos preenchendo os registros
  useEffect(() => {
    // Se for dados modo leitura, recuperar os valores registrados para criar
    // Um objeto compativel com o que a tabela de visualização possa aceitar
    if (viewData) {
      const newRowsView = [];
      _.forEach(viewData.atualizacao_documentos, (item) => {
        const obj = {
          [namesListOrder[0]]: {
            label: item.descricao_atualizacao,
          },
          [namesListOrder[1]]: {
            label: item.revisar,
          },
          [namesListOrder[2]]: {
            label: item.nome,
          },
          [namesListOrder[3]]: {
            label: Utils.converterDataHora(item.data_atualizacao),
          },
          [namesListOrder[4]]: {
            label: item.realizado,
          },
          [namesListOrder[5]]: {
            label: item.observacao,
          },
        };
        newRowsView.push(obj);
      });

      setRows(newRowsView);
    }
  }, [viewData, namesListOrder, setRows]);

  // Cabecalho da tabela
  const headers = [
    t("lesson.labelTableTitleDesc"),
    t("lesson.labelTableTitleRevision"),
    t("lesson.labelTableResponsible"),
    t("lesson.labelTableData"),
    t("lesson.labelTableAccomplished"),
    t("lesson.labelObservation"),
  ];

  // Effeito colateral que executa sempre que um novo registro for inserido
  useEffect(() => {
    // Todas as vezes que o rows mudar registra o novo valor
    if (rows.length > 0 && !viewData) {
      setValue(namesForm.updateDoc, rows);
    }
  }, [setValue, rows, viewData]);

  // Funcao que é executada a cada inserção de nova atualizacao de documento
  const onSetRow = useCallback(
    (row) => {
      // Registra o campo para receber os valores da atualizacao de documentos
      if (rows.length === 0) {
        control.register(namesForm.updateDoc);
      }
      setRows((val) => [...val, row]);
      setListTypeUpdate((val) =>
        val.filter(
          (item) =>
            item[0] !== parseInt(row[namesListType.idTipoAtualizacao].value)
        )
      );
      setModal(null);
    },
    [control, rows, setRows, setListTypeUpdate, setModal]
  );

  //
  return (
    <Stack>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => (
            <LessonFormTypeUpdateAdd
              onSetRow={onSetRow}
              setListTypeUpdate={setListTypeUpdate}
              listTypeUpdate={listTypeUpdate}
            />
          )}
          fecharModal={() => setModal(null)}
        />
      )}
      <BoxHeader label={t("lesson.labelTitleDocDefault")} />
      {!viewData && (
        <Button
          startIcon={<Icone icone="Add" />}
          onClick={() => setModal(true)}
          fullWidth
        >
          {t("lesson.labelBtnAddUpdateDoc")}
        </Button>
      )}
      {isMobile ? (
        rows.map((item, idx) => (
          <Accordion key={idx}>
            <AccordionSummary expandIcon={<Icone icone="ExpandMore" />}>
              <Body1>{`${headers[0]} - ${
                item[namesListOrder[0]].label
              }`}</Body1>
            </AccordionSummary>
            <AccordionDetails>
              {_.map(
                namesListOrder,
                (k, ix) =>
                  ix > 0 && (
                    <ListItem key={k} disableGutters divider>
                      <ListItemText
                        key={k}
                        primary={headers[ix]}
                        secondary={item[k].label}
                      />
                    </ListItem>
                  )
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((value) => (
                  <TableCell key={value}>{value}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((item, idx) => (
                <TableRow key={idx}>
                  {_.map(namesListOrder, (k) => (
                    <TableCell key={k}>{item[k].label}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};
//
const LessonFormCustBenef = ({ viewData, control }) => {
  const isMobile = useTheme()?.isMobile;
  const { t } = useTranslation();

  const options = [
    {
      labelHeader: t("lesson.labelCust"),
      placeholder: t("lesson.placeholderMoney"),
      defaultValue: viewData
        ? Utils.converter(parseFloat(viewData.custo).toFixed(2))
        : "",
      name: namesForm.coust,
      type: "text",
    },
    {
      labelHeader: t("lesson.labelBenef"),
      placeholder: t("lesson.placeholderMoney"),
      defaultValue: viewData
        ? Utils.converter(parseFloat(viewData.beneficio).toFixed(2))
        : "",
      name: namesForm.benef,
      type: "text",
    },
  ];
  // Criando mascara monetaria
  const maskToMoney = createNumberMask({
    prefix: " ",
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    decimalLimit: 2,
    integerLimit: 10,
  });
  //
  return (
    <Paper elevation={0} sx={{ borderRadius: 0 }}>
      <Stack sx={{ mt: 0.2 }}>
        {isMobile ? (
          <Stack>
            {options.map(
              ({ labelHeader, defaultValue, placeholder, name, type }) => (
                <Stack key={name}>
                  <BoxHeader label={labelHeader} />

                  <BoxField sx={{ px: 1, flex: 1 }}>
                    {viewData ? (
                      <Body1 {...propsBodyView}>{defaultValue}</Body1>
                    ) : (
                      <Controller
                        name={name}
                        defaultValue={defaultValue}
                        control={control}
                        render={({ field }) => (
                          <MaskedInput
                            mask={maskToMoney}
                            {...field}
                            inputMode="numeric"
                            render={(ref, props) => (
                              <TextField
                                inputRef={ref}
                                {...props}
                                fullWidth
                                type={type}
                                placeholder={placeholder}
                                variant="standard"
                                {...propsTextField}
                                InputProps={{ startAdornment: "R$" }}
                                inputProps={{ autoComplete: "off" }}
                              />
                            )}
                          />
                        )}
                      />
                    )}
                  </BoxField>
                </Stack>
              )
            )}
          </Stack>
        ) : (
          <>
            <Stack direction="row">
              <BoxHeader label="" sx={{ flex: 8 }} />
              <BoxHeader label={t("lesson.labelCust")} sx={{ flex: 1 }} />
              <BoxHeader label={t("lesson.labelBenef")} sx={{ flex: 1 }} />
            </Stack>
            <Stack direction="row">
              <BoxField sx={{ flex: 8 }} />
              {options.map(({ defaultValue, placeholder, name, type }) => (
                <BoxField key={name} sx={{ px: 1, flex: 1 }}>
                  {viewData ? (
                    <Body1 {...propsBodyView}>{defaultValue}</Body1>
                  ) : (
                    <Controller
                      name={name}
                      defaultValue={defaultValue}
                      control={control}
                      render={({ field }) => (
                        <MaskedInput
                          mask={maskToMoney}
                          {...field}
                          inputMode="numeric"
                          render={(ref, props) => (
                            <TextField
                              inputRef={ref}
                              {...props}
                              fullWidth
                              type={type}
                              placeholder={placeholder}
                              variant="standard"
                              {...propsTextField}
                              InputProps={{ startAdornment: "R$" }}
                              inputProps={{ autoComplete: "off" }}
                            />
                          )}
                        />
                      )}
                    />
                  )}
                </BoxField>
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Paper>
  );
};
// Aciona o modal que vai exibir um formulário para inclusão de uma atualização de documento
const LessonFormTypeUpdateAdd = ({ listTypeUpdate, onSetRow }) => {
  const listColab = useSelector(seletorColab);
  const { t } = useTranslation();
  //
  const schema = [
    {
      name: namesListType.idTipoAtualizacao,
      type: "select",
      itens: listTypeUpdate,
      autoFormat: true,
      label: t("lesson.labelTableTitleDesc"),
    },
    {
      name: namesListType.revisar,
      type: "select",
      itens: [
        ["S", "SIM"],
        ["N", "NÃO"],
      ],
      autoFormat: true,
      label: t("lesson.labelTableTitleRevision"),
    },
    {
      name: namesListType.matPlanta,
      type: "select",
      itens: listColab,
      autoFormat: true,
      label: t("lesson.labelTableResponsible"),
    },
    {
      name: namesListType.data,
      type: "date",
      defaultValue: format(new Date(), "yyyy-MM-dd"),
      disabled: true,
      label: t("lesson.labelTableData"),
    },
    {
      name: namesListType.realizado,
      type: "select",
      itens: [
        ["S", "SIM"],
        ["N", "NÃO"],
      ],
      autoFormat: true,

      label: t("lesson.labelTableAccomplished"),
    },
    {
      name: namesListType.observacao,
      type: "textarea",
      multiline: true,
      minRows: 3,
      label: t("lesson.labelObservation"),
    },
  ];

  const schemaMessageError = {
    [namesListType.idTipoAtualizacao]: t("lesson.errorListUpdate"),
    [namesListType.revisar]: t("lesson.errorRevision"),
    [namesListType.matPlanta]: t("lesson.errorResponsible"),
    [namesListType.observacao]: t("lesson.errorObservation"),
    [namesListType.realizado]: t("lesson.errorAccomplished"),
    [namesListType.data]: t("lesson.errorDate"),
  };
  const schemaValidator = yup.object().shape({
    [namesListType.idTipoAtualizacao]: obterValidador(
      VALIDADOR_TIPO.selectUnico
    ),
    [namesListType.revisar]: obterValidador(VALIDADOR_TIPO.selectUnico),
    [namesListType.matPlanta]: obterValidador(VALIDADOR_TIPO.selectUnico),
    [namesListType.observacao]: obterValidador(VALIDADOR_TIPO.texto, 3),
    [namesListType.realizado]: obterValidador(VALIDADOR_TIPO.selectUnico),
    [namesListType.data]: obterValidador(VALIDADOR_TIPO.data),
  });
  //
  const onSubmit = useCallback(
    (val) => {
      const obj = {
        [namesListType.idTipoAtualizacao]: val[namesListType.idTipoAtualizacao],
        [namesListType.revisar]: val[namesListType.revisar],
        [namesListType.matPlanta]: val[namesListType.matPlanta],
        [namesListType.observacao]: {
          label: val[namesListType.observacao],
          value: val[namesListType.observacao],
        },
        [namesListType.realizado]: val[namesListType.realizado],
        [namesListType.data]: {
          // label: format(val[namesListType.data], "dd/MM/yyyy"),
          // value: format(val[namesListType.data], "yyyy-MM-dd"),
          label: format(new Date(), "dd/MM/yyyy"),
          value: format(new Date(), "yyyy-MM-dd HH:mm"),
        },
      };

      onSetRow(obj);
    },
    [onSetRow]
  );

  return (
    <Stack sx={{ p: 1 }}>
      <H6>{t("lesson.titleFormAddTypeUpdate")}</H6>
      <EntradaForm
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
        onSubmit={onSubmit}
      />
    </Stack>
  );
};

LessonForm.rota = "/lesson_form";

export default LessonForm;
