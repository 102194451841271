import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { EntradaForm, H6 } from "../../../components";
import { useDispatch } from "react-redux";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { useToggle } from "react-use";
import * as yup from "yup";
import { helpdeskAddUpdAssunto } from "./helpdesk-actions";
import { useTranslation } from "react-i18next";

const FIELDS = {
  name: "nome",
  praz: "prazo",
  situation: "situacao",
};

function HelpdeskAssuntoAddUpd({ id, descricao, prazo, situacao }) {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  //
  const situationOptions = [
    ["A", t("helpdesk.labelActive")],
    ["B", t("helpdesk.labelDeactive")],
  ];
  //
  const schema = [
    {
      type: "text",
      name: FIELDS.name,
      label: t("helpdesk.labelNameSubjectAdd"),
      defaultValue: descricao || "",
      placeholder: t("helpdesk.placeholderNameSubjectAdd"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "number",
      label: t("helpdesk.labelPraz"),
      name: FIELDS.praz,
      min: 1,
      defaultValue: prazo || "",
      placeholder: t("helpdesk.placeholderPraz"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "radio",
      name: FIELDS.situation,
      itens: situationOptions,
      defaultValue: situacao || "",
      placeholder: t("helpdesk.placeholderSituationSubjectAdd"),
    },
  ];
  //
  const onSubmit = useCallback(
    (val) => {
      const obj = {
        [FIELDS.name]: val[FIELDS.name],
        [FIELDS.praz]: val[FIELDS.praz],
        [FIELDS.situation]: val[FIELDS.situation],
      };
      if (id) {
        obj.id_assunto = id;
      }
      //
      dispatch(helpdeskAddUpdAssunto(obj, setWait));
    },
    [dispatch, setWait, id]
  );
  //
  const schemaValidator = yup.object().shape({
    [FIELDS.name]: obterValidador(VALIDADOR_TIPO.texto, 3),
    [FIELDS.praz]: yup.number().min(1).required(),
    [FIELDS.situation]: obterValidador(VALIDADOR_TIPO.texto, 1),
  });

  const schemaMessageError = {
    [FIELDS.name]: t("helpdesk.errorNameSubjectAdd"),
    [FIELDS.praz]: t("helpdesk.errorPraz"),
    [FIELDS.situation]: t("helpdesk.errorSituationSubjectAdd"),
  };
  return (
    <Stack>
      <H6>{t("helpdesk.titleSubjectAdd")}</H6>
      <EntradaForm
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
        wait={wait}
        onSubmit={onSubmit}
      />
    </Stack>
  );
}

export default HelpdeskAssuntoAddUpd;
