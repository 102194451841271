// Funcao para formatar dados
const FormatarDados = (coluna, dados) => {
  // Usa a data como chave
  const obj = {};

  // Passa pelo array na coluna indexada e contabiliza
  dados.forEach((ele, idx) => {
    const key = ele[coluna];
    // Verifica se a chave existe
    if (!obj.hasOwnProperty(key)) obj[key] = 0;
    // Incrementa
    obj[key] += 1;
  });

  return obj;
};

export default FormatarDados;
