import {
  GALERIA_DE_FOTOS_GET_ALBUNS,
  GALERIA_DE_FOTOS_ADD_ALBUM,
  GALERIA_DE_FOTOS_LIMPAR_DADOS,
  GALERIA_DE_FOTOS_SET_DRAWER,
  GALERIA_DE_FOTOS_FECHAR_DRAWER,
  GALERIA_DE_FOTOS_UPD_ALBUM,
  GALERIA_DE_FOTOS_DEL_ALBUM,
  GALERIA_DE_FOTOS_GET_FOTOS,
  GALERIA_DE_FOTOS_LIMPAR_LISTA_FOTOS,
  GALERIA_DE_FOTOS_ADD_FOTO,
  GALERIA_DE_FOTOS_GET_COMENTARIOS,
  GALERIA_DE_FOTOS_ADD_COMENTARIO,
  GALERIA_DE_FOTOS_UPD_COMENTARIO,
  GALERIA_DE_FOTOS_LIMPAR_LISTA_COMENTARIOS,
  GALERIA_DE_FOTOS_DEL_COMENTARIO,
  GALERIA_DE_FOTOS_GET_CURTIDAS,
  GALERIA_DE_FOTOS_DEL_CURTIDA,
  GALERIA_DE_FOTOS_ADD_CURTIDA,
  GALERIA_DE_FOTOS_UPD_CURTIDA,
  GALERIA_DE_FOTOS_LIMPAR_LISTA_CURTIDAS,
  GALERIA_DE_FOTOS_EDITAR_LEGENDA_FOTO,
  GALERIA_DE_FOTOS_DEL_FOTO,
} from "./galeria-de-fotos-actions";

const INIT_STATE = {
  album: [],
};

const galeriaDeFotosReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GALERIA_DE_FOTOS_GET_ALBUNS: // Recuperacao de novos albuns
      return {
        ...state,
        album: [...state.album, ...action.data],
      };
    case GALERIA_DE_FOTOS_ADD_ALBUM: // Para adicionar um novo album
      return {
        ...state,
        album: [action.data, ...state.album],
      };
    case GALERIA_DE_FOTOS_UPD_ALBUM: // Para editar um album
      return (() => {
        const novoAlbum = state.album.map((ele) => {
          if (String(ele.id_album) === String(action.data.id_album)) {
            return action.data;
          }
          return ele;
        });

        return {
          ...state,
          album: novoAlbum,
        };
      })();
    case GALERIA_DE_FOTOS_DEL_ALBUM: // Para excluir um album
      return {
        ...state,
        album: state.album.filter(
          (ele) => String(ele.id_album) !== String(action.data)
        ),
      };
    case GALERIA_DE_FOTOS_LIMPAR_DADOS: // Para limpar os dados
      return INIT_STATE;
    case GALERIA_DE_FOTOS_SET_DRAWER: // Para setar o drawer
      return {
        ...state,
        drawer: action.data,
      };
    case GALERIA_DE_FOTOS_FECHAR_DRAWER: // Para fechar o drawer
      return {
        ...state,
        drawer: null,
      };
    case GALERIA_DE_FOTOS_GET_FOTOS: // Para recuperar as fotos
      return {
        ...state,
        fotos: (state?.fotos ? state.fotos : []).concat(action.data),
      };
    case GALERIA_DE_FOTOS_LIMPAR_LISTA_FOTOS: // Para limpar a lista de fotos
      return {
        ...state,
        fotos: null,
      };
    case GALERIA_DE_FOTOS_ADD_FOTO: // Para adicionar fotos
      return {
        ...state,
        fotos: [...state.fotos, ...action.data],
      };
    case GALERIA_DE_FOTOS_GET_COMENTARIOS: // Para recupera comentarios
      return {
        ...state,
        comentarios: action.data,
      };
    case GALERIA_DE_FOTOS_ADD_COMENTARIO: // Para adicionar um novo comentario
      return {
        ...state,
        fotos: state.fotos.map((ele) => {
          if (String(ele.id_foto) === String(action.data.id_foto)) {
            // Incrementa a quantidade de comentarios em 1
            ele.total_comentarios++;
          }
          return ele;
        }),
        comentarios: state?.comentarios
          ? [action.data, ...state.comentarios]
          : [action.data],
      };
    case GALERIA_DE_FOTOS_UPD_COMENTARIO: // Para editar um comentario
      return (() => {
        const novoComentarios = state.comentarios.map((ele) => {
          if (String(ele.id_comentario) === String(action.data.id_comentario)) {
            return action.data;
          }
          return ele;
        });
        return {
          ...state,
          comentarios: novoComentarios,
        };
      })();
    case GALERIA_DE_FOTOS_DEL_COMENTARIO: // Para excluir um comentario
      return {
        ...state,
        fotos: state.fotos.map((ele) => {
          if (String(ele.id_foto) === String(action.data.id_foto)) {
            // Reduz a quantidade de comentarios em 1
            ele.total_comentarios--;
          }
          return ele;
        }),
        comentarios: state.comentarios.filter(
          (ele) =>
            String(ele.id_comentario) !== String(action.data.id_comentario)
        ),
      };
    case GALERIA_DE_FOTOS_LIMPAR_LISTA_COMENTARIOS: // Para limpar a lista de comentarios
      return {
        ...state,
        comentarios: null,
      };
    case GALERIA_DE_FOTOS_GET_CURTIDAS: // Para obter as curtidas
      return {
        ...state,
        curtidas: action.data,
      };
    case GALERIA_DE_FOTOS_ADD_CURTIDA: // Para adicionar uma curtida
      return {
        ...state,
        fotos: state.fotos.map((ele) => {
          if (String(ele.id_foto) === String(action.data.id_foto)) {
            // Incrementa a quantidade de curtidas
            ele.total_curtidas++;
          }
          return ele;
        }),
        curtidas: [...state.curtidas, action.data],
      };
    case GALERIA_DE_FOTOS_UPD_CURTIDA: // Para editar uma curtida
      return (() => {
        const novasCurtidas = state.curtidas.map((ele) => {
          if (String(ele.id_curtida) === String(action.data.id_curtida)) {
            return action.data;
          }
          return ele;
        });
        return {
          ...state,
          curtidas: novasCurtidas,
        };
      })();
    case GALERIA_DE_FOTOS_DEL_CURTIDA: // Para excluir uma curtida
      return {
        ...state,
        fotos: state.fotos.map((ele) => {
          if (String(ele.id_foto) === String(action.data.id_foto)) {
            // Reduz a quantidade de curtidas em 1
            ele.total_curtidas--;
          }
          return ele;
        }),
        curtidas: state.curtidas.filter(
          (ele) => String(ele.id_curtida) !== String(action.data.id_curtida)
        ),
      };
    case GALERIA_DE_FOTOS_LIMPAR_LISTA_CURTIDAS: // Para limpar a lista de curtidas
      return {
        ...state,
        curtidas: null,
      };
    case GALERIA_DE_FOTOS_EDITAR_LEGENDA_FOTO: // Para editar a legenda da foto
      return {
        ...state,
        fotos: state.fotos.map((ele) => {
          if (String(ele.id_foto) === String(action.data.id_foto)) {
            return action.data;
          }
          return ele;
        }),
      };
    case GALERIA_DE_FOTOS_DEL_FOTO: // Para excluir a foto
      return {
        ...state,
        fotos: state.fotos.filter(
          (ele) => String(ele.id_foto) !== String(action.data)
        ),
      };
    default:
      return state;
  }
};

export default galeriaDeFotosReducer;
