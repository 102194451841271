import { Grid, Button, Divider, Paper, Stack } from "@mui/material";
import { Body1, H6 } from "../../../components";
import { format, parseISO } from "date-fns";
import _ from "lodash";
import { green, yellow } from "@mui/material/colors";
import Icone from "../../../components/icone";
import { useTranslation } from "react-i18next";

// Componente que exibe um card flutuante
const CardFlutuante = ({ titulo, descricao, cor = "#005187" }) => {
  return (
    <Paper elevation={4} sx={{ py: 0 }}>
      <Stack direction="row">
        <Stack
          sx={{
            minWidth: "8px",
            height: "72px",
            backgroundColor: cor,
            mr: 1,
          }}
        />
        <Stack sx={{ mt: 0.5 }}>
          <H6 align="left">{titulo}</H6>
          <Body1>{descricao}</Body1>
        </Stack>
      </Stack>
    </Paper>
  );
};

// modelo de exibição dos dados preenchidos (somente leitura)
const VerDadosPessoais = ({ data }) => {
  const { t } = useTranslation();
  const { pessoais } = data;

  return (
    <Paper elevation={0} sx={{ my: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleName")}
            descricao={pessoais.nome}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleCpf")}
            descricao={pessoais.cpf}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitlePhone")}
            descricao={pessoais.telefone}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          {/* Nascimento */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleBirth")}
            descricao={
              pessoais.nascimento
                ? format(parseISO(pessoais.nascimento), "dd/MM/yyyy")
                : pessoais.nascimento
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          {/* RG */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleRg")}
            descricao={pessoais.rg}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          {/* email */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleEmail")}
            descricao={pessoais.email}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleMaritalStatus")}
            descricao={
              _.filter(
                data.dados_auxiliares.estado_civil,
                (val) => val[0] === _.trim(pessoais.estado_civil)
              )[0][1]
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider component="br" sx={{ my: 1 }} />
        </Grid>

        <Grid item xs={12} md={4}>
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleSchooling")}
            descricao={
              _.filter(
                data.dados_auxiliares.escolaridade,
                (val) => val[0] === _.trim(pessoais.escolaridade)
              )[0][1]
            }
            cor={yellow[800]}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          {/* NOME CURSO */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleCourse")}
            descricao={pessoais.curso}
            cor={yellow[800]}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          {/* DATA CURSO */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleCourseDate")}
            descricao={
              pessoais.data_curso
                ? format(parseISO(pessoais.data_curso), "dd/MM/yyyy")
                : pessoais.data_curso
            }
            cor={yellow[800]}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider component="br" sx={{ my: 1 }} />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Deficiencia */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleDisability")}
            descricao={
              _.filter(
                data.dados_auxiliares.deficiencia,
                (val) => val[0] === _.trim(pessoais.deficiencia)
              )[0][1]
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* tipo deficienci */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleDisabilityType")}
            descricao={
              _.filter(
                data.dados_auxiliares.desc_deficiencia,
                (val) => val[0] === _.trim(pessoais.descricao_deficiencia)
              )[0][1]
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider component="br" sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} md={3}>
          {/* CEP */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleCep")}
            descricao={pessoais.cep}
            cor={green[500]}
          />
        </Grid>

        <Grid item xs={12} md={7}>
          {/* logradouro */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleStreet")}
            descricao={pessoais.logradouro}
            cor={green[500]}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          {/* numero */}
          <CardFlutuante
            titulo="N°"
            descricao={pessoais.numero}
            cor={green[500]}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          {/* complemento */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleComplement")}
            descricao={pessoais.complemento}
            cor={green[500]}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          {/* ponto referencia */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleReferencePoint")}
            descricao={pessoais.ponto_de_referencia}
            cor={green[500]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {/* bairro */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleNeighborhood")}
            descricao={pessoais.bairro}
            cor={green[500]}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          {/* CIDADE */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleCity")}
            descricao={pessoais.cidade}
            cor={green[500]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {/* Estado */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleState")}
            descricao={pessoais.estado}
            cor={green[500]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {/* codigo_municipio */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleMunicipalityCode")}
            descricao={pessoais.codigo_municipio}
            cor={green[500]}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider component="br" sx={{ my: 1 }} />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* nome_emergencia */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleEmergencyName")}
            descricao={pessoais.nome_emergencia}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* contato_emergencia */}
          <CardFlutuante
            titulo={t("dados_cadastrais.cardTitleEmergencyContact")}
            descricao={pessoais.telefone_emergencia}
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Stack direction="row" flexWrap="wrap" alignItems="center" spacing={1}>
        {pessoais?.anexos?.map((ele, idx) => (
          <CardFlutuante
            titulo={`${idx + 1}° ${t("dados_cadastrais.cardTitleAttachment")}`}
            key={idx}
            descricao={
              <Button
                href={ele}
                startIcon={<Icone icone="AttachFile" />}
                target="_blank"
              >
                {t("dados_cadastrais.cardTitleAttachmentClickHere")}
              </Button>
            }
          />
        ))}
      </Stack>
    </Paper>
  );
};

export default VerDadosPessoais;
