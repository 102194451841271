import React from 'react'
import Logo from './logo';

export default function CabeModal(props){
    return (
        <div className="bg-red bor-rad-15 pad-5 d-flex flex-ai-center flex-jc-between">
            <Logo />
            {props.children}
        </div>
    )
}