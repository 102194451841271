import _ from "lodash";

import {
  COLABORADORES_GET_COLABORADORES,
  COLABORADORES_MENU,
  COLABORADORES_DEL_MENU,
  COLABORADORES_ADD_MENU,
  COLABORADORES_UPD_ELEGIVEL,
  COLABORADORES_UPD_EMAIL,
  COLABORADORES_ADD_COLABORADOR,
  COLABORADORES_UPD_ARVORE,
  COLABORADORES_UPD_GESTOR_SITUACAO,
  COLABORADORES_DEL_COLABORADOR,
  COLABORADORES_SET_DRAWER,
  COLABORADORES_FECHAR_DRAWER,
  COLABORADORES_ADD_MENUS,
  COLABORADORES_DEL_MENUS,
} from "./colaboradores-actions";

export default function colaboradoresReducer(state = null, action) {
  switch (action.type) {
    case COLABORADORES_GET_COLABORADORES: //
      return {
        ...state,
        ...action.data,
      };
    case COLABORADORES_MENU: // Para obter os menus
      return {
        ...state,
        ...action.data,
      };
    case COLABORADORES_DEL_MENU: // Para remover um menu de um usuario
      return (() => {
        const { matricula, id_menu } = action.data;

        let novoColaboradorXMenu = { ...state.colaborador_x_menu };
        // Veja se o colaborador tem algum menu
        if (state.colaborador_x_menu.hasOwnProperty(matricula)) {
          // Exclui o menu do usuario
          delete novoColaboradorXMenu[matricula][id_menu];
        }
        return {
          ...state,
          colaborador_x_menu: novoColaboradorXMenu,
        };
      })();
    case COLABORADORES_ADD_MENU: // Para adicionar um novo menu ao colaborador
      return (() => {
        const _colaborador = _.keys(action.data);

        return {
          ...state,
          colaborador_x_menu: {
            ...state.colaborador_x_menu,
            [_colaborador]: {
              ...state.colaborador_x_menu[_colaborador],
              ...action.data[_colaborador],
            },
          },
        };
      })();
    case COLABORADORES_ADD_MENUS: // Para adicionar todos os menus
      return {
        ...state,
        colaborador_x_menu: {
          ...state.colaborador_x_menu,
          ...action.data,
        },
      };
    case COLABORADORES_DEL_MENUS: // Para remove todos os menus
      return (() => {
        const { colaborador_x_menu } = state;
        delete colaborador_x_menu[action.data];
        return {
          ...state,
          colaborador_x_menu: {
            ...colaborador_x_menu,
          },
        };
      })();
    case COLABORADORES_UPD_ELEGIVEL: // Para atualização do elegivel
      return (() => {
        const { matricula, elegivel } = action.data;
        //
        const _copiaCorpo = state.corpo.map((ele) => {
          if (String(ele.id) === String(matricula)) {
            // Indice 9 elegivel
            ele.data[9] = elegivel;
          }
          return ele;
        });

        return {
          ...state,
          corpo: _copiaCorpo,
        };
      })();
    case COLABORADORES_UPD_EMAIL: // Para atualizar o email
      return (() => {
        const { matricula, email } = action.data;
        //
        const _copiaCorpo = state.corpo.map((ele) => {
          if (String(ele.id) === String(matricula)) {
            // Indice 2 email
            ele.data[2] = email;
          }
          return ele;
        });

        return {
          ...state,
          corpo: _copiaCorpo,
        };
      })();
    case COLABORADORES_ADD_COLABORADOR: // Para adicionar um novo colaborador
      return {
        ...state,
        corpo: [action.data, ...state.corpo],
      };
    case COLABORADORES_UPD_GESTOR_SITUACAO: // Para atualizar gestao ou situacao
    case COLABORADORES_UPD_ARVORE: // Mesma funcionalidade atualiza a arvore
      return (() => {
        const _copia = state.corpo.map((ele) => {
          if (String(ele.id) === String(action.data.id)) {
            return action.data;
          }
          return ele;
        });

        return {
          ...state,
          corpo: _copia,
        };
      })();
    case COLABORADORES_DEL_COLABORADOR: // Para excluir o colaborador
      return {
        ...state,
        corpo: state.corpo.filter(
          (ele) => String(ele.id) !== String(action.data.matricula)
        ),
      };
    case COLABORADORES_SET_DRAWER: // Para ativar o drawer
      return {
        ...state,
        drawer: action.data,
      };
    case COLABORADORES_FECHAR_DRAWER: // Para fechar o drawer
      return {
        ...state,
        drawer: null,
      };
    default:
      return state;
  }
}
