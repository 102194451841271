import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  List,
  Paper,
  Stack,
} from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Body1, Icone } from "../../../components";
import { useToggle } from "react-use";
import ValuesVacation from "./values-vacation";
import ValuesSalaryBonus from "./values-salary-bonus";
import axios from "axios";
import { ToastErro } from "../../../utils/utils";
import { toast } from "react-toastify";
import BackgroundRouter from "../../background-router";
import useFetch from "../../../hooks/use-fetch";
import { useTranslation } from "react-i18next";

const FIELDS = {
  latLong: "latLong",
  signature: "signature",
  photo: "photo",
  ip: "ip",
};

const ROUTES = ["/recibo_de_ferias"];

function FeriasAssinar() {
  const item = useLocation()?.state?.item;
  const keyVacation = `${item?.matricula}_${item?.planta}_${item?.pagamento}`;
  //
  return (
    <BackgroundRouter>
      <Container maxWidth="sm" sx={{ py: 2 }}>
        <GetStepComponent item={item} keyVacation={keyVacation} />
      </Container>
    </BackgroundRouter>
  );
}
//
const GetStepComponent = ({ item, keyVacation }) => {
  const { t } = useTranslation();
  const [valueSave, setValuesSave] = useState({
    [FIELDS.latLong]: "nao_informada",
    [FIELDS.signature]: null,
    [FIELDS.photo]: null,
    [FIELDS.ip]: null,
  });
  //
  const onSetValue = useCallback(
    (value) => {
      setValuesSave((state) => ({ ...state, ...value }));
    },
    [setValuesSave]
  );
  // Pegando o ip
  useEffect(() => {
    (async function () {
      // Tenta obter o ip de origem, se conseguir o envia
      try {
        const resp = await axios.get("https://httpbin.org/ip");
        let ip = resp.data.origin ? resp.data.origin.split(",")[0].trim() : "";
        onSetValue({ [FIELDS.ip]: ip });
      } catch (err) {
        ToastErro(t("ferias.errorNotFoundIP"));
      }
    })();
  }, [onSetValue, t]);

  return (
    <Stack gap={1}>
      <ViewValuesToReceive
        valuesVacation={item?.valores_ferias}
        valuesSalaryBonus={item?.valores_abono}
      />
      {/* <GeoLocalization onSetValue={onSetValue} /> */}

      <ReceiptVacation item={item} />
      <Photo onSetValue={onSetValue} />
      <TermOfAccept
        isTermAccept={Boolean(valueSave[FIELDS.signature])}
        onSetValue={onSetValue}
      />

      <ConfirmForm valuesToSave={valueSave} keyVacation={keyVacation} />
    </Stack>
  );
};
//
const ViewValuesToReceive = ({ valuesSalaryBonus, valuesVacation }) => {
  return (
    <List dense>
      <ValuesVacation valuesVacation={valuesVacation} />
      {valuesSalaryBonus?.length > 0 && (
        <ValuesSalaryBonus valuesSalaryBonus={valuesSalaryBonus} />
      )}
    </List>
  );
};

// Define que o usuário aceita os termos
const TermOfAccept = ({ isTermAccept, onSetValue }) => {
  const { t } = useTranslation();
  //
  const onChecked = useCallback(
    (e) => {
      if (e.target.checked) {
        onSetValue({ [FIELDS.signature]: "SIM" });
      } else {
        onSetValue({ [FIELDS.signature]: null });
      }
    },
    [onSetValue]
  );

  //
  return (
    <FormControlLabel
      control={<Checkbox />}
      onChange={onChecked}
      checked={isTermAccept}
      label={t("ferias.labelTerm")}
    />
  );
};
// Tirar a sua foto (e cropar)
const Photo = ({ onSetValue }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [loadImage, setLoadImage] = useState(null);

  const onIntentLoadImage = useCallback(() => {
    ref.current.click();
  }, [ref]);
  // Converter imagem para base64
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  // Veja se a imagem foi upada
  const onLoadImage = useCallback(
    async (e) => {
      let input = e.target;
      if (input.files && input.files[0]) {
        const valueBase64 = await toBase64(input.files[0]);
        // Insere o valor da imagem no form
        onSetValue({
          [FIELDS.photo]: valueBase64,
        });
        //
        let leitor = new FileReader();

        leitor.onload = function (e) {
          setLoadImage(e.target.result);
        };

        leitor.readAsDataURL(input.files[0]);
      }
    },
    [setLoadImage, onSetValue]
  );
  //
  const onRemoveImage = useCallback(() => {
    if (window.confirm(t("ferias.alertRemoveImage"))) {
      setLoadImage(null);
      onSetValue({ [FIELDS.photo]: null });
      // Limpa o campo do input
      ref.current.value = "";
    }
  }, [setLoadImage, t, ref, onSetValue]);

  return (
    <>
      <input
        accept=".png, .jpg"
        ref={ref}
        onChange={onLoadImage}
        type="file"
        style={{ display: "none" }}
      />
      {loadImage ? (
        <Paper elevation={3}>
          <Stack>
            <img src={loadImage} alt={t("ferias.titleImageLoad")} />
            <Body1 align="center">{t("ferias.labelPhotoInsert")}</Body1>
            <Button
              startIcon={<Icone icone="Close" />}
              size="small"
              onClick={onRemoveImage}
            >
              {t("ferias.labelPhotoRemove")}
            </Button>
          </Stack>
        </Paper>
      ) : (
        <Button
          variant="outlined"
          onClick={onIntentLoadImage}
          startIcon={<Icone icone="PhotoCamera" />}
        >
          {t("ferias.labelPhoto")}
        </Button>
      )}
    </>
  );
};
//
const ConfirmForm = ({ valuesToSave, keyVacation }) => {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const history = useHistory();
  //
  const isAllFields = _.every(_.keys(valuesToSave), (k) =>
    Boolean(valuesToSave[k])
  );
  //
  const onConfirmValues = useCallback(async () => {
    // Tenta obter o ip de origem, se conseguir o envia
    const obj = {
      ip_assinado: valuesToSave[FIELDS.ip],
      // geolocalizacao: valuesToSave[FIELDS.latLong],
      geolocalizacao: "nao_informada",
      foto: valuesToSave[FIELDS.photo],
      assinatura: valuesToSave[FIELDS.signature],
      id: keyVacation,
    };

    setWait();

    try {
      const resp = await axios.post(ROUTES[0], obj);
      toast.dark(resp.data?.sucesso);
      history.goBack();
    } catch (err) {
      if (err?.response?.data?.message) {
        ToastErro(err.response.data.message);
      } else {
        ToastErro(err.toString());
      }
    } finally {
      setWait();
    }
  }, [setWait, history, valuesToSave, keyVacation]);

  return (
    <Button
      variant="contained"
      startIcon={<Icone icone="Save" />}
      disabled={!isAllFields || wait}
      onClick={onConfirmValues}
    >
      {t("ferias.labelSaveBtn")}
    </Button>
  );
};

const onDownload = (url, t) => {
  if (!url) {
    ToastErro(t("ferias.errorNotExistsWarningVacation"));
    return;
  }
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";

  link.click();
};

const ReceiptVacation = ({ item }) => {
  const { t } = useTranslation();
  const { setFetch, data, error, wait } = useFetch(
    `/recibo_de_ferias_comprovante?id=${item?.matricula}_${item?.planta}_${item?.pagamento}&tipo=AV`,
    "GET"
  );

  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);

  useEffect(() => {
    setFetch({});
  }, [setFetch]);

  return (
    <Button
      startIcon={
        wait ? <CircularProgress size={20} /> : <Icone icone="Download" />
      }
      onClick={() => onDownload(data?.link, t)}
      variant="contained"
      disabled={wait}
    >
      {t("ferias.labelBtnDownloadWarningVacation")}
    </Button>
  );
};

FeriasAssinar.rota = "/ferias_assinar";

export default FeriasAssinar;
