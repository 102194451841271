import { 
    AREA_DO_GESTOR_INIT, AREA_DO_GESTOR_OBTER_QUESTIONARIO,
    AREA_DO_GESTOR_FECHAR_QUESTIONARIO,
    AREA_DO_GESTOR_ENVIAR_RESPOSTA,
} from '../actions'; 

export default function AreaDoGestorReducer(state = null, action){
    switch(action.type){
        case AREA_DO_GESTOR_INIT: // Inicializando objeto;
            return {
                'colaboradores': [...action.data],
            }
        case AREA_DO_GESTOR_OBTER_QUESTIONARIO: // Para obter o questionario
            return {
                ...state,
                'questionario': action.data, // E a recuperacao de um questionario para avaliacao
            }
        case AREA_DO_GESTOR_FECHAR_QUESTIONARIO: // Para fechar o questionario
            return {
                ...state,
                questionario: null,
            }
        case AREA_DO_GESTOR_ENVIAR_RESPOSTA: // Para concluir a resposta sobre o formulario
            return (()=>{
                const estadoAntigo = {...state};
                // Procura a matricula e retira a avaliacao
                const colaboradores = estadoAntigo.colaboradores.map(ele=>{
                    if(ele.matricula.toString() === action.data.matricula.toString()){
                        // Vai na avaliacao e remova ela
                        const avaliacoes = ele.avaliacoes.filter(aval=> aval.toString() !== action.data.id_avaliacao.toString() );
                        return {
                            ...ele,
                            'avaliacoes': avaliacoes,
                        }
                    }
                    return ele;
                });
                return {
                    ...state,
                    colaboradores: colaboradores,
                    questionario: null,
                }
            })()
        default:
            return state;
    }
}