import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  manutencaoVariaveisFecharDrawer,
  manutencaoVariaveisDelVariavelGlobal,
} from "./manutencao-variaveis-actions";
import { selectVariavelGlobalSelecionada } from "./manutencao-variaveis-selectors";
import { Confirmar } from "../../../components";
import { useTranslation } from "react-i18next";

// Componente para excluir variavel global
const FormDelVariavelGlobal = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const variavel = useSelector(selectVariavelGlobalSelecionada);
  //
  const fnCancelar = () => dispatch(manutencaoVariaveisFecharDrawer());
  const fnConfirmar = () =>
    dispatch(
      manutencaoVariaveisDelVariavelGlobal({
        id_variavel: variavel.id_variavel,
      })
    );

  return (
    <Confirmar
      titulo={t("manutencao_variaveis.titleFormDelVarGlobal")}
      subtitulo={`${t("manutencao_variaveis.descriptionFormDelVarGlobal1")} ${
        variavel.nome
      } ${t("manutencao_variaveis.descriptionFormDelVarGlobal2")}`}
      fnCancelar={fnCancelar}
      fnConfirmar={fnConfirmar}
    />
  );
});

export default FormDelVariavelGlobal;
