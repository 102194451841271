/**
 * Centralização dos seletores do RNCI
 */
import { createSelector } from "reselect";
import _ from "lodash";

//
const selectRnciGetFilterData = (state) => state?.rnciNcp?.filtroData;

export const selectRnciPermissionCancelStatus = (state) =>
  state?.usuario?.variaveis?.includes("EXCLUI_RNCI");

export const selectRnci = (state) => state?.rnciNcp;
export const selectRnciDadosPagina = (state) => state?.rnciNcp?.dados;
export const selectModal = (state) => state?.rnciNcp?.modal;
export const selectUsuario = (state) => state?.usuario;
export const selectRnciGetFilter = (state) => state?.rnciNcp?.filtro;
export const selectRnciTotalPerStatus = (state) =>
  state?.rnciNcp?.total_por_status;
export const selectRnciTotalPerPageFilter = (state) =>
  state?.rnciNcp?.total_pagina_filtro;
export const selectRnciPageActualyFilter = (state) =>
  state?.rnciNcp?.pagina_atual_filtro;

export const seletorIsArvore = createSelector(selectUsuario, (usuarioInfo) => {
  if (usuarioInfo) {
    const { variaveis_globais, usuario } = usuarioInfo;
    return variaveis_globais?.RNCI_MOVER_QUALIDADE?.split(",").includes(
      usuario?.arvore
    );
  }
  return false;
});

// Seletor de filtro da pagina
export const seletorPageFilter = createSelector(
  selectRnciTotalPerPageFilter,
  selectRnciPageActualyFilter,
  (totalPage, pageActualy) => {
    return {
      totalPaginaFiltro: totalPage,
      paginaAtualFiltro: pageActualy,
    };
  }
);
// Retorna os dados do filtro, com paginação
export const seletorRnciGetFilterData = createSelector(
  seletorPageFilter,
  selectRnciGetFilterData,
  (pageInfo, data) => {
    const { paginaAtualFiltro } = pageInfo;
    if (!paginaAtualFiltro) {
      return null;
    }
    const pageInitial = (paginaAtualFiltro - 1) * 10;
    const newData = _.clone(data).splice(pageInitial, 10);

    return newData;
  }
);

/// Retorna todos os tipos de filtro que podem ser utilizados no filtro avancado
export const seletorRnciOptionsFilter = createSelector(selectRnci, (obj) => {
  const { area, colaboradores, operacao, pn, turnos, codigo_rnci, status } =
    obj;

  if (!colaboradores) return null;

  const matriculas = _.map(colaboradores, (row) => [
    row[0].split("_")[0],
    row[1],
  ]);

  return {
    area,
    matriculas,
    operacao,
    pn,
    turnos,
    codigo_rnci,
    status,
  };
});
