import { blue, green, red } from "@mui/material/colors";

// Controla os status do treinamento
export const getStatusName = (status, t) => {
  switch (status) {
    case "1":
      return t("treinamento.statusOpen");
    case "2":
      return t("treinamento.statusInTraining");
    case "3":
    default:
      return t("treinamento.statusDelivered");
  }
};

// Determina a cor do status do serviço.
export const getStatusColor = (status, withRGB, t) => {
  switch (status) {
    case "1":
      return withRGB ? green[500] : "success";
    case "2":
      return withRGB ? blue[500] : "info";
    case "3":
    default:
      return withRGB ? red[500] : "error";
  }
};
