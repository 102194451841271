import React, { useCallback, useEffect } from "react";

import BackgroundRouter from "../../background-router";
import { CircularProgress, Stack } from "@mui/material";
import { AnimacaoSemDados } from "../../../components";
import { useDispatch } from "react-redux";
import { rnciNcpInit } from "./rnci-ncp-actions";
import { useTranslation } from "react-i18next";

function RnciWrapperSemDados({ isBgRouter }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(rnciNcpInit(() => {}));
    }, 2000);
  }, [dispatch]);

  const fnChidren = useCallback(() => {
    return (
      <Stack sx={{ width: "100%" }} alignItems="center">
        <AnimacaoSemDados titulo={t("rnci_ncp.tituloSemDadosRetorno")} />
        <CircularProgress />
      </Stack>
    );
  }, [t]);

  return isBgRouter ? (
    <BackgroundRouter>{fnChidren()}</BackgroundRouter>
  ) : (
    fnChidren()
  );
}

export default RnciWrapperSemDados;
