import React, { useCallback } from "react";
import { selectTrainningItems } from "./treinamento-selectors";
import { Stack } from "@mui/material";
import { Body1, EntradaForm, H6, Icone } from "../../../components";
import * as yup from "yup";
import { useToggle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { trainningAlterStatus } from "./treinamento-actions";
import TrainningStatusChip from "./components/trainning-status-chip";
import { useTranslation } from "react-i18next";

const KEYS = {
  observation: "observacao",
  fromStatus: "de_situacao",
  toStatus: "para_situacao",
  trainning: "treinamento",
};

const nextStatus = (status) => {
  switch (status) {
    case "1":
      return "2";
    case "2":
      return "3";
    case "3":
    default:
      return "3";
  }
};

function TreinamentoFormAlterarStatus({ id }) {
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const trainnings = useSelector(selectTrainningItems);
  const trainning = trainnings.find((item) => item.id === id);
  const { t } = useTranslation();

  const schema = [
    {
      type: "textarea",
      minRows: 3,
      multiline: true,
      name: KEYS.observation,
      label: t("treinamento.trainningFormAlterStatusObservation"),
      placeholder: t("treinamento.trainningFormAlterStatusErrorObservation"),
      required: true,
    },
  ];

  const schemaMessageError = {
    [KEYS.observation]: t(
      "treinamento.trainningFormAlterStatusErrorObservation"
    ),
  };

  const schemaValidator = yup.object().shape({
    [KEYS.observation]: yup
      .string()
      .required(t("treinamento.trainningFormAlterStatusErrorObservation")),
  });

  const onSubmit = useCallback(
    (val) => {
      const obs = {
        [KEYS.trainning]: trainning.id,
        [KEYS.observation]: val[KEYS.observation],
        [KEYS.fromStatus]: trainning.status,
        [KEYS.toStatus]: nextStatus(trainning.status),
      };

      dispatch(trainningAlterStatus(obs, setWait));
    },
    [dispatch, trainning, setWait]
  );

  return (
    <Stack>
      <H6>{t("treinamento.trainningFormAlterStatusTitle")}</H6>
      <Stack
        justifyContent="center"
        alignItems="center"
        direction="row"
        gap={2}
      >
        <Stack direction="row" gap={0.5}>
          <Body1>{t("treinamento.trainningFormAlterStatusFrom")}</Body1>
          <TrainningStatusChip status={trainning.status} />
        </Stack>

        <Icone icone="ArrowForward" />
        <Stack direction="row" gap={0.5}>
          <Body1>{t("treinamento.trainningFormAlterStatusTo")}</Body1>
          <TrainningStatusChip status={nextStatus(trainning.status)} />
        </Stack>
      </Stack>
      <br />
      <EntradaForm
        schema={schema}
        wait={wait}
        onSubmit={onSubmit}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
      />
    </Stack>
  );
}

export default TreinamentoFormAlterarStatus;
