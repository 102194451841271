import fetchRedux from "../../../api/fetch_redux";
import { ToastErro } from "../../../utils/utils";

export const BUDGET_INIT = "BUDGET_INIT";
export const BUDGET_SET_MODAL = "BUDGET_SET_MODAL";
export const BUDGET_CLOSE_MODAL = "BUDGET_CLOSE_MODAL";

const ROUTES = ["/budget"];
//
export const initBudget = (setWait) => (dispatch) => {
  fetchRedux(
    ROUTES[0],
    "POST",
    null,
    (response) => {
      dispatch({
        type: BUDGET_INIT,
        data: response,
      });
    },
    () => setWait(),
    (err) => ToastErro(err)
  );
};
//
export const budgetSetModal = (data) => ({
  type: BUDGET_SET_MODAL,
  data,
});
//
export const budgetCloseModal = () => ({
  type: BUDGET_CLOSE_MODAL,
});
