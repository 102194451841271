import { useDispatch, useSelector } from "react-redux";
import {
  selectAguardar,
  selectDados,
  selectFormEntrevista,
  selectPlantaUsuario,
} from "./twttp-selectors";
import { memo } from "react";
import { useEffect } from "react";
import { twttpFormEntrevistaValidar } from "./twttp-actions";
import {
  twttpGetFormEntrevista,
  twttpLimparFormEntrevista,
} from "./twttp-actions";
import * as yup from "yup";
import { Container, Divider, Paper, Stack } from "@mui/material";
import { EntradaForm, H6, Body1 } from "../../../components";
import _ from "lodash";
import AvatarEntrevistado from "./twttp-avatar-to-interview";
import { useTranslation } from "react-i18next";

//
const Entrevistar = memo(({ status, id_twttp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const colaboradoresGeral = useSelector(selectDados).colaboradores;
  const formEntrevista = useSelector(selectFormEntrevista);
  const formAcoes = useSelector(selectDados).twttp_acoes;
  const planta = useSelector(selectPlantaUsuario);
  const aguardar = useSelector(selectAguardar);

  // Busca os dados para o formulario da entrevista
  useEffect(() => {
    dispatch(twttpGetFormEntrevista(id_twttp));
    return () => dispatch(twttpLimparFormEntrevista());
  }, [id_twttp, dispatch]);
  // Funcao para lidar com formulario preenchido
  const fn = (val) => {
    const obj = {
      id_twttp: formEntrevista.id_twttp,
      id_entrevista: formEntrevista.id_entrevista,
      respostas: [],
    };
    // Se o status for diferente de P pode enviar as matriculas
    obj["mat_entrevistado"] =
      status !== "P"
        ? val.mat_entrevistado.value
        : formEntrevista?.mat_entrevistado;
    obj["mat_entrevistador"] =
      status !== "P"
        ? val.mat_entrevistador.value
        : formEntrevista?.mat_entrevistador;

    // Se o satus for P precisamos recuperar o acao_prazo
    if (status === "P") {
      obj["acao_prazo"] = parseInt(val.acao_prazo.value);
      // Tambem recuperar o valor do campo da nota explicativa
      obj["nota_explicativa"] = val.nota_explicativa;
    }

    // Monta o array de respostas
    Object.keys(val).forEach((key) => {
      if (key.search("perg_") !== -1) {
        obj.respostas.push({
          id_pergunta: parseInt(key.replace("perg_", "")),
          id_opcao: parseInt(val[key]),
          texto: "",
        });
      }
    });
    //
    dispatch(twttpFormEntrevistaValidar(obj));
  };

  //
  if (!formEntrevista) return null;

  // Cria o schema das perguntas dinamicamente
  const schema = [];
  let schemaMessageError = {},
    schemaValidator = {};
  // Quer dizer que ainda não passou por pre-validacao
  if (status !== "P") {
    schema.push({
      name: "mat_entrevistado",
      label: t("twttp.labelColabInterviewToInterview"),
      autoFormat: true,
      type: "select",
      grid: { xs: 12, md: 6 },
      itens: formEntrevista?.colaboradores,
    });
    schema.push({
      name: "mat_entrevistador",
      label: t("twttp.labelInterviewerToInterview"),
      autoFormat: true,
      type: "select",
      grid: { xs: 12, md: 6 },
      itens: colaboradoresGeral,
    });
    // Gera avisos de erro
    schemaMessageError = {
      mat_entrevistado: t("twttp.errorValueRequiredToInterview"),
      mat_entrevistador: t("twttp.errorValueRequiredToInterview"),
    };
    // Cria o schema para validacao
    schemaValidator = {
      mat_entrevistado: yup
        .object()
        .shape({
          label: yup.string().min(1).required(),
          value: yup.string().min(1).required(),
        })
        .required(),

      mat_entrevistador: yup
        .object()
        .shape({
          label: yup.string().min(1).required(),
          value: yup.string().min(1).required(),
        })
        .required(),
    };
  }

  //
  formEntrevista?.perguntas?.forEach((ele) => {
    // nome do campo
    const nomeCampo = `perg_${ele.id_pergunta}`;
    schema.push({
      name: nomeCampo,
      titulo: ele.descricao,
      // titulo: ele.titulo,
      // descricao: ele.descricao,
      type: "radio",
      itens: ele.opcoes,
      extraProps: { size: "small" },
      wrapperPaperProps: { elevation: 0, sx: { py: 1 } },
    });
    // Preenche o schema de validacao
    schemaValidator[nomeCampo] = yup.string().min(1).required();
    // Preenche o schema de mensagens
    schemaMessageError[nomeCampo] = t("twttp.errorValueRequiredToInterview");
  });

  // Veja se ja passou por prevalidacao, assim como este é o segundo
  // questionario deve-se colocar mais um input para as acoes
  if (status === "P") {
    // Incluir no final textArea para notaExplicativa
    schema.push({
      name: "nota_explicativa",
      label: t("twttp.labelJustifyToInterview"),
      type: "textarea",
      placeholder: t("twttp.placeholderJustifyToInterview"),
      grid: { xs: 12 },
      extraProps: {
        multiline: true,
        minRows: 2,
        sx: { mt: 1 },
      },
      counter: true,
    });
    schema.push({
      name: "acao_prazo",
      label: t("twttp.labelActionToInterview"),
      type: "select",
      itens: formAcoes,
      autoFormat: true,
    });
    //
    schemaValidator["acao_prazo"] = yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required();
    //
    schemaValidator["nota_explicativa"] = yup.string().min(3).required();
    //
    schemaMessageError["acao_prazo"] = t("twttp.errorValueRequiredToInterview");
    schemaMessageError["nota_explicativa"] = t(
      "twttp.errorJustifyRequiredToInterview"
    );
  }
  // Envolve o schemaValidador em um shape
  schemaValidator = yup.object().shape(schemaValidator);

  return (
    <Container
      disableGutters
      maxWidth="md"
      sx={{ px: 0.5, maxHeight: "80vh", py: 1 }}
    >
      {status === "P" && (
        <EntrevistaVerEntrevistadorEntrevistado
          mat_entrevistado={
            _.filter(
              colaboradoresGeral,
              (val) =>
                String(val[0]) === String(formEntrevista.mat_entrevistado)
            )[0]
          }
          mat_entrevistador={
            _.filter(
              colaboradoresGeral,
              (val) =>
                String(val[0]) === String(formEntrevista.mat_entrevistador)
            )[0]
          }
          planta={planta}
        />
      )}
      <Paper sx={{ p: 1, my: 1 }} elevation={8}>
        <H6>{formEntrevista?.descricao}</H6>
        <Divider sx={{ my: 1 }} />
        <EntradaForm
          onSubmit={fn}
          wait={aguardar}
          schema={schema}
          schemaMessageError={schemaMessageError}
          schemaValidator={schemaValidator}
        />
        <br />
      </Paper>
    </Container>
  );
});

// Componente que vai exibir o nome do entrevistador e do entrevistado
const EntrevistaVerEntrevistadorEntrevistado = memo(
  ({ mat_entrevistado, mat_entrevistador, planta }) => {
    const { t } = useTranslation();
    return (
      <Container maxWidth="md">
        <Stack
          sx={{ mb: 1 }}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-evenly"
          spacing={1}
        >
          <Paper
            sx={{
              p: 1,
              color: (theme) => theme.palette.info.contrastText,
              backgroundColor: (theme) => theme.palette.info.dark,
            }}
          >
            <Stack>
              <Body1>{t("twttp.descriptionColabToInterview")}: </Body1>
              <AvatarEntrevistado
                nome_entrevistado={mat_entrevistado[1].trim()}
                mat_entrevistado={mat_entrevistado[0].trim()}
                planta={planta}
              />
            </Stack>
          </Paper>
          <Paper
            sx={{
              p: 1,
            }}
          >
            <Stack>
              <Body1>{t("twttp.descriptionToInterview")}: </Body1>
              <AvatarEntrevistado
                nome_entrevistado={mat_entrevistador[1].trim()}
                mat_entrevistado={mat_entrevistador[0].trim()}
                planta={planta}
              />
            </Stack>
          </Paper>
        </Stack>
      </Container>
    );
  }
);

export default Entrevistar;
