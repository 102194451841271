import React, { useCallback, useEffect } from "react";
import BackgroundRouter from "../../background-router";
import { useDispatch, useSelector } from "react-redux";
import { selectCoursesWithoutFormatting } from "./treinamento-selectors";
import { Body2, H6, Icone } from "../../../components";
import {
  Container,
  Fab,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import TrainningSideBar from "./components/trainning-side-bar";
import TrainningModalViewer from "./components/trainning-modal-viewer";
import { trainningInit, trainningSetModal } from "./treinamento-actions";
import { green } from "@mui/material/colors";
import TreinamentoModal from "./treinamento-modal";
import OptionsMenu from "../../../components/options-menu";
import { useTranslation } from "react-i18next";

const TreinamentoCursos = () => {
  const dispatch = useDispatch();
  const coursesList = useSelector(selectCoursesWithoutFormatting);
  const { t } = useTranslation();

  useEffect(() => {
    if (!coursesList || coursesList.length === 0) {
      dispatch(trainningInit(() => {}));
    }
  }, [coursesList, dispatch]);

  return (
    <BackgroundRouter>
      <TrainningModalViewer />
      <TrainningSideBar isGoBack />
      <Container maxWidth="md">
        <H6>{t("treinamento.titleTrainningCourse")}</H6>
        <FabEdit t={t} />

        <List>
          {coursesList.map((item) => (
            <CourseItem {...item} key={item.id} t={t} />
          ))}
        </List>
      </Container>
    </BackgroundRouter>
  );
};

const FabEdit = ({ t }) => {
  const dispatch = useDispatch();
  const isMobile = useTheme()?.isMobile;
  const sxFab = {};
  if (isMobile) {
    sxFab.position = "fixed";
    sxFab.right = 16;
    sxFab.bottom = 72;
  } else {
    sxFab.mb = 1;
  }
  const newCourse = {
    icon: <Icone icone="Add" />,
    text: t("treinamento.textFabNewCouse"),
    title: t("treinamento.titleFabNewCouse"),
    background: green[700],
    foreground: "white",
    onClick: () => {
      dispatch(
        trainningSetModal({
          type: TreinamentoModal.modal.FORM_ADD_COURSE,
        })
      );
    },
  };
  return (
    <Stack direction="row-reverse">
      <Fab
        onClick={newCourse.onClick}
        title={newCourse.title}
        color="success"
        sx={sxFab}
        variant={isMobile ? "circular" : "extended"}
      >
        {newCourse.icon}
        {!isMobile && <Body2>{newCourse.text}</Body2>}
      </Fab>
    </Stack>
  );
};

const CourseItem = ({ id, duracao, descricao, modelo, t }) => {
  const dispatch = useDispatch();

  const onEditCourse = useCallback(() => {
    dispatch(
      trainningSetModal({
        type: TreinamentoModal.modal.FORM_EDT_COURSE,
        data: {
          id,
          description: descricao,
          duration: duracao,
          model: modelo,
        },
      })
    );
  }, [dispatch, descricao, duracao, modelo, id]);

  const onDelCourse = useCallback(() => {
    dispatch(
      trainningSetModal({
        type: TreinamentoModal.modal.FORM_DEL_COURSE,
        data: id,
      })
    );
  }, [dispatch, id]);

  const optionsCourse = [
    {
      icon: "Edit",
      title: t("treinamento.titleEdtCourse"),
      label: t("treinamento.labelEdtCourse"),
      onClick: onEditCourse,
    },
    {
      icon: "Delete",
      title: t("treinamento.titleDelCourse"),
      label: t("treinamento.labelDelCourse"),
      onClick: onDelCourse,
    },
  ];

  return (
    <Paper elevation={3} sx={{ my: 1 }}>
      <ListItem>
        <ListItemText primary={descricao} secondary={duracao} />
        <ListItemSecondaryAction>
          <OptionsMenu options={optionsCourse} />
        </ListItemSecondaryAction>
      </ListItem>
    </Paper>
  );
};

TreinamentoCursos.rota = "/treinamento_curso";

export default TreinamentoCursos;
