import { ContainerAdaptavel } from "../../../extra-components";
import FormAddColaborador from "./form-add-colab";
import RenderFormAtivoGestor from "./form-alt-passwd-three";
import RenderFormAlterarEmailSenhaArvore from "./form-upd-passwd-colab";
import RenderVerMenu from "./render-menu-colab";

const ColaboradoresModal = ({ modal }) => {
  let corpo;

  if (modal) {
    switch (modal.tipo) {
      case ColaboradoresModal.modal.ADD_COLABORADOR:
        corpo = <FormAddColaborador />;
        break;
      case ColaboradoresModal.modal.VER_MENU_COLABORADOR: // VER MENU DO COLABORADOR
        corpo = <RenderVerMenu />;
        break;
      case ColaboradoresModal.modal.ALTERAR_GESTOR_SITUACAO: // ATIVA OU DESATIVA GESTOR OU SITUACAO
        corpo = <RenderFormAtivoGestor />;
        break;
      case ColaboradoresModal.modal.ALTERAR_INFO_PESSOAIS: // ALTERAR INFORMACOES PESSOAIS
        corpo = <RenderFormAlterarEmailSenhaArvore />;
        break;
      default:
        break;
    }
  }

  return <ContainerAdaptavel>{corpo}</ContainerAdaptavel>;
};

ColaboradoresModal.modal = {
  ADD_COLABORADOR: "ADD_COLABORADOR",
  VER_MENU_COLABORADOR: "VER_MENU_COLABORADOR",
  ALTERAR_GESTOR_SITUACAO: "ALTERAR_GESTOR_SITUACAO",
  ALTERAR_INFO_PESSOAIS: "ALTERAR_INFO_PESSOAIS",
};

export default ColaboradoresModal;
