import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Stack,
  CircularProgress,
  Typography,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { format, parseISO } from "date-fns";
import { cadastroProjetosGetHistorico } from "./kaizen-actions";
import RetornoEmBranco from "../../../components/retorno-em-branco";
import {
  fnGetIcon,
  fnKaizenGetText,
  fnKaizenGetTextSigla,
} from "./kaizen-utils";
import { Icone } from "../../../components";
import { useTranslation } from "react-i18next";

// Componente para ver o historico do projeto
const VerHistoricoProjeto = ({ verHistorico }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { aguardar } = useSelector((state) => state);
  const historico = useSelector((state) => state?.cadastroProjetos?.historico);

  useEffect(() => {
    dispatch(cadastroProjetosGetHistorico(verHistorico));
  }, [dispatch, verHistorico]);

  return (
    <Container
      maxWidth="md"
      sx={{ p: 1, minHeight: "50vh", maxHeight: "80vh" }}
    >
      {aguardar ? (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress />
          <Typography align="center">{t("cadastro_projetos.wait")}</Typography>
        </Stack>
      ) : historico && historico.length === 0 ? (
        <RetornoEmBranco />
      ) : (
        historico?.map((ele, idx) => {
          const { data_movimento, alterador, de, ate, observacao, anexo } = ele;
          return (
            <Paper
              sx={{
                backgroundColor: ate === "R" ? red["A100"] : "auto",
                p: 2,
                my: 2,
              }}
              key={idx}
              elevation={2}
            >
              <Stack spacing={2}>
                <TextField
                  label={t("cadastro_projetos.dateHistory")}
                  type="datetime"
                  size="small"
                  value={format(parseISO(data_movimento), "dd/MM/yyy HH:mm:ss")}
                />
                <TextField
                  label={t("cadastro_projetos.mat")}
                  type="text"
                  value={alterador}
                  size="small"
                />
                <TextField
                  label={t("cadastro_projetos.fromSituation")}
                  type="text"
                  InputProps={{ startAdornment: fnGetIcon(de) }}
                  value={fnKaizenGetText(de, t)}
                  size="small"
                />
                <TextField
                  label={t("cadastro_projetos.toSituation")}
                  type="text"
                  InputProps={{ startAdornment: fnGetIcon(ate) }}
                  value={fnKaizenGetText(ate, t)}
                  size="small"
                />
                {observacao?.length > 0 && (
                  <TextField
                    label={t("cadastro_projetos.observation")}
                    type="textarea"
                    value={observacao}
                    multiline
                    rows={3}
                    size="small"
                  />
                )}
                <Stack flexWrap="wrap" direction="row">
                  {anexo.map((anx, idxan) => (
                    <Button
                      sx={{ mx: 1, my: 0.5 }}
                      size="small"
                      key={idxan}
                      variant="outlined"
                      startIcon={<Icone icone="AttachFile" />}
                      href={anx[1]}
                      target="_blank"
                    >
                      {idxan + 1}° {t("cadastro_projetos.attach")} -{" "}
                      {fnKaizenGetTextSigla(anx[0])}
                    </Button>
                  ))}
                </Stack>
              </Stack>
            </Paper>
          );
        })
      )}
      <br />
    </Container>
  );
};

export default VerHistoricoProjeto;
