import React from "react";
import {
  Button,
  Container,
  Stack,
  List,
  Typography,
  Chip,
  Divider,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { fnKaizenGetText } from "./kaizen-utils";
import Utils from "../../../utils/utils";
import { ListItem, ListItemText, LinearProgress } from "@mui/material";
import { Icone } from "../../../components";
import { useTranslation } from "react-i18next";

// Componente para listar todo o projeto
const VerProjeto = ({
  fecharVisualizacao,
  projeto,
  anexos,
  membros,
  modelos,
}) => {
  const { t } = useTranslation();
  const itens = [
    {
      descricao: t("cadastro_projetos.labelSituation"),
      valor: fnKaizenGetText(projeto.situacao, t),
    },
    {
      descricao: t("cadastro_projetos.labelCreatorProject"),
      valor: projeto.mat_criador || t("cadastro_projetos.valueNotFound"),
    },
    { descricao: t("cadastro_projetos.labelLeader"), valor: projeto.lider },
    { descricao: t("cadastro_projetos.labelCode"), valor: projeto.codigo },
    {
      descricao: t("cadastro_projetos.labelOpeningDate"),
      valor: format(parseISO(projeto.abertura), "dd/MM/yy"),
    },
    {
      descricao: t("cadastro_projetos.labelExpectedDate"),
      valor: format(parseISO(projeto.data_prevista), "dd/MM/yy"),
    },
    {
      descricao: t("cadastro_projetos.labelDriver"),
      valor: projeto.direcionador,
    },
    {
      descricao: t("cadastro_projetos.labelDescription"),
      valor: projeto.descricao,
    },
    {
      descricao: t("cadastro_projetos.labelExpansion"),
      valor: projeto.expansao,
    },
    { descricao: t("cadastro_projetos.labelArea"), valor: projeto.area },
    {
      descricao: t("cadastro_projetos.labelOperation"),
      valor: projeto.operacao,
    },
    { descricao: t("cadastro_projetos.labelLoss"), valor: projeto.perda },
    {
      descricao: t("cadastro_projetos.labelLossType"),
      valor: projeto.tipo_perda,
    },
    {
      descricao: t("cadastro_projetos.labelProjectType"),
      valor: projeto.tipo_projeto,
    },
    { descricao: t("cadastro_projetos.labelPillar"), valor: projeto.pilar },
    {
      descricao: t("cadastro_projetos.labelIDMatrixDQA"),
      valor: projeto.id_matrizd,
    },
    {
      descricao: t("cadastro_projetos.labelSaving"),
      valor: Utils.converter(parseFloat(projeto.saving).toFixed(2)),
    },
  ];

  return (
    <>
      <Button
        onClick={fecharVisualizacao}
        size="small"
        startIcon={<Icone icone="Close" />}
        fullWidth
        variant="contained"
        sx={{ zIndex: 10, position: "sticky", top: 0 }}
      >
        {t("cadastro_projetos.closeButton")}
      </Button>
      <Container sx={{ height: "85vh" }} maxWidth="lg">
        <Stack spacing={2}>
          <List>
            {itens.map((ele, idx) => (
              <ItemVerProjeto
                key={idx}
                descricao={ele.descricao}
                valor={ele.valor}
              />
            ))}
          </List>
          <br />
          <Typography variant="h6" align="center">
            {t("cadastro_projetos.modelsTitle")}
          </Typography>
          <Stack direction="row" flexWrap="wrap">
            {modelos?.map((ele, idx) => (
              <Chip key={idx} sx={{ mx: 1 }} color="primary" label={ele[1]} />
            ))}
          </Stack>
          <Divider />
          <Typography variant="h6" align="center">
            {t("cadastro_projetos.membersTitle")}
          </Typography>
          {membros.map((ele, idx) => (
            <ItemVerProjetoMembros
              key={idx}
              matricula={ele[0]}
              percentual={ele[1]}
            />
          ))}
          <Divider />
          {anexos && anexos?.length > 0 && (
            <>
              <Typography variant="h6" align="center">
                {t("cadastro_projetos.attachmentsTitle")}
              </Typography>
              <Stack direction="row" flexWrap="wrap">
                {anexos?.map((ele, idx) => (
                  <Button
                    sx={{ m: 1 }}
                    startIcon={<Icone icone="AttachFile" />}
                    href={ele}
                    key={idx}
                    target="_blank"
                  >
                    {t("cadastro_projetos.attach")} {idx + 1}
                  </Button>
                ))}
              </Stack>
            </>
          )}

          <br />
        </Stack>
      </Container>
    </>
  );
};

// Componente para ver membros do projeto
const ItemVerProjetoMembros = ({ matricula, percentual }) => (
  <Stack>
    <LinearProgress
      variant="determinate"
      value={parseInt(percentual.replace("%", ""))}
    />
    <Typography variant="caption">
      {matricula} - {percentual}
    </Typography>
  </Stack>
);

// Componente para criar o item a ser exibido, recebe valor e nome
const ItemVerProjeto = ({ descricao, valor }) => (
  <ListItem divider sx={{ py: 0 }}>
    <ListItemText sx={{ mb: 2 }} primary={descricao} secondary={valor} />
  </ListItem>
);

export default VerProjeto;
