import React, { useCallback, useEffect } from "react";
import BackgroundRouter from "../../background-router";
import {
  Box,
  Chip,
  Container,
  Grow,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  helpdeskDelCriticality,
  helpdeskFecharModal,
  helpdeskGetCriticality,
  helpdeskSetModal,
} from "./helpdesk-actions";
import { selectCrticality, selectModal } from "./helpdesk-seletores";
import HelpdeskModal, { MODAL } from "./helpdesk-modal";
import { Body1, H6, Icone } from "../../../components";
import { DrawerDialog } from "../../../extra-components";
import RowPaginateTemplate from "../../../templates/row_paginate_template";
import HelpdeskGoBack from "./helpdesk-goback";
import { useTranslation } from "react-i18next";

const getSituationName = (situation, t) => {
  switch (situation) {
    case "A":
      return t("helpdesk.labelActive");
    case "B":
    default:
      return t("helpdesk.labelDeactive");
  }
};

const getIconSituation = (situation) => {
  switch (situation) {
    case "A":
      return "Check";
    case "B":
    default:
      return "Block";
  }
};

const getColorSituation = (situation) => {
  switch (situation) {
    case "A":
      return "success";
    case "B":
    default:
      return "error";
  }
};

const HelpdeskCriticidade = () => {
  const { t } = useTranslation();
  const isMobile = useTheme()?.isMobile;
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const criticality = useSelector(selectCrticality);

  useEffect(() => {
    if (!criticality) {
      dispatch(helpdeskGetCriticality());
    }
  }, [dispatch, criticality]);
  const header = [
    t("helpdesk.headerNameCritical"),
    t("helpdesk.headerIcon"),
    t("helpdesk.headerActive"),
    t("helpdesk.headerDelete"),
  ];
  const mapKeyToTitle = {
    [t("helpdesk.headerNameCritical")]: "descricao",
    [t("helpdesk.headerIcon")]: "icone",
    [t("helpdesk.headerActive")]: "situacao",
    [t("helpdesk.headerDelete")]: "id",
  };

  let rows = [];

  if (criticality) {
    rows = criticality.map((item) => {
      const row = {
        onClick: () => {
          dispatch(
            helpdeskSetModal({
              tipo: MODAL.ADD_CRITICALITY,
              dados: item,
            })
          );
        },
      };

      _.forEach(mapKeyToTitle, (value, key) => {
        if (key === t("helpdesk.headerActive")) {
          row[key] = (
            <Chip
              label={getSituationName(item[value], t)}
              color={getColorSituation(item[value])}
              icon={getIconSituation(item[value])}
              size="small"
            />
          );
        } else if (key === t("helpdesk.headerIcon")) {
          row[key] = (
            <span title={item[value]}>
              <Icone title={item[value]} icone={item[value]} />
            </span>
          );
        } else if (key === t("helpdesk.headerDelete")) {
          row[key] = (
            <IconButton
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(helpdeskDelCriticality(item));
              }}
            >
              <Icone icone="Delete" />
            </IconButton>
          );
        } else {
          row[key] = item[value];
        }
      });
      return row;
    });
  }

  const onAddCriticality = useCallback(() => {
    dispatch(
      helpdeskSetModal({
        tipo: MODAL.ADD_CRITICALITY,
        dados: null,
      })
    );
  }, [dispatch]);

  const fecharModal = useCallback(() => {
    dispatch(helpdeskFecharModal());
  }, [dispatch]);

  return (
    <BackgroundRouter>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => <HelpdeskModal modal={modal} />}
          fecharModal={fecharModal}
        />
      )}
      <Grow in unmountOnExit>
        <Stack sx={{ width: "100vw" }} direction="row">
          {!isMobile && (
            <Box sx={{ mt: 2, flex: 0 }}>
              <HelpdeskGoBack />
            </Box>
          )}
          <Box sx={{ flex: 1 }}>
            <Container maxWidth="md">
              <H6>{t("helpdesk.titleMainCritical")}</H6>
              <Body1 align="center">{t("helpdesk.subtitleMainCritical")}</Body1>
              <RowPaginateTemplate
                pageCurrent={1}
                totalPages={1}
                onSetPage={() => {}}
                titlePage=""
                onClickAdd={onAddCriticality}
                headerAboveTable={null}
                titleButtonAdd={t("helpdesk.titleBtnAdd")}
                header={header}
                backgroundPage="transparent"
                sxHeader={{
                  background: ({ palette }) =>
                    palette.mode === "dark"
                      ? palette.background.paper
                      : palette.primary.main,
                  color: ({ palette }) =>
                    palette.mode === "dark"
                      ? palette.background.paper.contrastText
                      : palette.primary.contrastText,
                }}
                rows={rows}
              />
            </Container>
          </Box>
        </Stack>
      </Grow>
    </BackgroundRouter>
  );
};

HelpdeskCriticidade.rota = "/helpdesk_criticidade_pg";

export default HelpdeskCriticidade;
