import { useToggle } from "react-use";
import { fnCriarPasta, formatColabForSelect } from "./documentos-utils";
import { PLANTAS } from "../../../config";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { useCallback } from "react";
import * as yup from "yup";
import { EntradaForm, H6 } from "../../../components";
import { useTranslation } from "react-i18next";

//
const CriarPasta = ({
  path,
  onAddItem,
  colaboradores,
  permissao_pasta_visualizacao,
  mat_planta_proprietario,
}) => {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  let listColabs = formatColabForSelect(colaboradores);
  // Tem algum tipo de restrição de visualizacao
  if (permissao_pasta_visualizacao?.length > 0) {
    // Se tiver mais que um usuário então exiba somente os acessiveis
    // Caso contrario todos são exibidos
    if (permissao_pasta_visualizacao.length > 1) {
      listColabs = listColabs.filter(
        (ele) =>
          permissao_pasta_visualizacao.includes(ele[0]) &&
          mat_planta_proprietario !== ele[0]
      );
    }
  }

  const schema = [
    {
      type: "text",
      name: "nome",
      label: t("documentos.labelNewFolder"),
      placeholder: t("documentos.placeholderNewFolder"),
    },
    {
      type: "select",
      name: "visivel_por",
      label: t("documentos.labelVisibilityBy"),
      defaultValue: [],
      isMulti: true,
      autoFormat: true,
      itens: PLANTAS,
    },
    {
      type: "select",
      name: "mat_planta_view",
      label: t("documentos.labelFuncPermittedView"),
      defaultValue: [],
      isMulti: true,
      autoFormat: true,
      itens: listColabs,
    },
  ];
  const schemaValidator = yup.object().shape({
    nome: obterValidador(VALIDADOR_TIPO.texto, 2),
    visivel_por: obterValidador(VALIDADOR_TIPO.selectMultiplo),
  });

  const schemaMessageError = {
    nome: t("documentos.errorNewFolder"),
    visivel_por: t("documentos.errorVisibilityBy"),
  };
  //
  const onSubmit = useCallback(
    async (val) => {
      const visivelPor = val.visivel_por.map((ele) => ele.value).join(",");
      const mat_planta_view = val?.mat_planta_view
        ? val.mat_planta_view.map((ele) => ele.value).join(",")
        : "";
      const nome = val.nome;
      const formData = new FormData();
      formData.append("nome", nome);
      formData.append("diretorio_pai", path.split("/").pop());
      formData.append("tipo_arquivo", "D");
      formData.append("visivel_por", visivelPor);
      formData.append("mat_planta_view", mat_planta_view);
      fnCriarPasta(formData, onAddItem, setWait, t);
    },
    [setWait, path, t, onAddItem]
  );

  return (
    <>
      <H6>{t("documentos.titleNewFolder")}</H6>
      <EntradaForm
        schema={schema}
        schemaValidator={schemaValidator}
        schemaMessageError={schemaMessageError}
        wait={wait}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default CriarPasta;
