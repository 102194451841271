import React, { memo, useEffect, useState } from "react";
import BackgroundRouter from "../../background-router";
import {
  helpdeskFecharModal,
  helpdeskFiltroAdd,
  helpdeskFiltroLimpar,
  helpdeskInit,
  helpdeskInitEstatisticosUpdate,
  helpdeskPagina,
  helpdeskPaginaUpdate,
  helpdeskSetModal,
  ROTAS,
} from "./helpdesk-actions";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Fab,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Slide,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AnimacaoSemDados,
  Body1,
  Body2,
  Caption,
  H6,
  Icone,
  InputBusca,
  Pesquisando,
  ScrollInfinito,
} from "../../../components";
import { useCallback } from "react";
import _ from "lodash";
import { blue, green, grey, orange } from "@mui/material/colors";
import { useToggle } from "react-use";
import { motion, AnimatePresence } from "framer-motion";
import { DrawerDialog } from "../../../extra-components";
import { format, isDate, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

import {
  rotaHelpdeskAdicionar,
  rotaHelpdeskDetalhes,
  rotaHelpdeskFiltroAvancado,
} from "../../routes";
import fetchGet from "../../../api/fetch_mensagem";
import { useTranslation } from "react-i18next";

import useSWR from "swr";
import {
  selectDados,
  selectIdPagina,
  selectIsAgente,
  selectIsSuperAgente,
  selectModal,
  selectSolicitante,
  selectStatus,
  selectUsuario,
} from "./helpdesk-seletores";
import HelpdeskAssunto from "./helpdesk-assunto";
import HelpdeskModal, { MODAL } from "./helpdesk-modal";
import HelpdeskStatus from "./helpdesk-status";
import HelpdeskCriticidade from "./helpdesk-criticidade";
//

//
const animacaoTickets = {
  initial: {
    opacity: 0.01,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0.01,
    transition: { duration: 0.01 },
  },
};
//
const alturaPagina = "calc(100vh - 72px)";
//

//
function HelpDesk() {
  const dispatch = useDispatch();
  const dados = useSelector(selectDados);
  const modal = useSelector(selectModal);
  const idPagina = useSelector(selectIdPagina);
  //
  const { data } = useSWR(`${ROTAS[0]}?pagina=${idPagina}`, fetchGet, {
    refreshInterval: 5e3,
  });
  //
  useEffect(() => {
    if (data) {
      dispatch(helpdeskPaginaUpdate(data));
    }
  }, [dispatch, data]);

  useEffect(() => {
    // Se ainda não tiver sido carregada a primeira pagina carregue-a
    if (!idPagina) {
      dispatch(helpdeskInit());
      dispatch(helpdeskPagina(1, () => {}));
    }
  }, [dispatch, idPagina]);
  //
  const fecharModal = useCallback(
    () => dispatch(helpdeskFecharModal()),
    [dispatch]
  );
  return (
    <BackgroundRouter>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => <HelpdeskModal modal={modal} />}
          fecharModal={fecharModal}
        />
      )}
      {dados && <Corpo />}
    </BackgroundRouter>
  );
}
//
const Corpo = () => {
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  return isMobile ? <CorpoMobile /> : <CorpoDesktop />;
};
const CorpoMobile = () => {
  const history = useHistory();
  const dados = useSelector(selectDados);
  const onClickAddTicket = useCallback(() => {
    history.push(rotaHelpdeskAdicionar);
  }, [history]);

  return (
    <>
      {dados && <PainelPrincipalTicket isMobile />}
      <Fab
        onClick={onClickAddTicket}
        sx={{ position: "fixed", right: 16, bottom: 72 }}
        color="primary"
      >
        <Icone icone="Add" />
      </Fab>
    </>
  );
};

const CorpoDesktop = () => {
  const { t } = useTranslation();
  const [viewPanel, setViewPanel] = useState(false);
  const dados = useSelector(selectDados);
  const isDark = useTheme().palette.mode === "dark";

  return (
    <Stack direction="row" sx={{ width: "100%" }} gap={1}>
      <Paper
        elevation={8}
        sx={{
          background: !isDark && blue[50],
          color: !isDark && "black",
        }}
      >
        <Stack
          sx={{ flex: 0 }}
          alignItems={!viewPanel ? "center" : "flex-start"}
          gap={1.5}
        >
          <IconButton color="primary" onClick={() => setViewPanel(!viewPanel)}>
            <Icone icone="Menu" />
          </IconButton>
          <motion.div
            key="motion"
            initial={{ width: "0%" }}
            animate={{
              width: viewPanel ? "100%" : "64px",
            }}
            exit={{ width: "64px" }}
            transition={{ duration: 0.3 }}
            title={t("helpdesk.titleMoreOptions")}
          >
            {viewPanel ? <BarraLateral /> : <BarraLateralOculta />}
          </motion.div>
        </Stack>
      </Paper>
      <Box flex={1}>{dados && <PainelPrincipalTicket />}</Box>
    </Stack>
  );
};

// Escolha pelo solicitante que você irá ver os tickets
const SolicitanteTicket = ({ anchorEl, setAnchorEl }) => {
  const { t } = useTranslation();
  const tituloInfoSolicitante = t("helpdesk.titleInfoRequester");
  const placeholderCampoBuscaSolicitanteTicket = t(
    "helpdesk.placeholderGetRequestTicket"
  );
  //
  const solicitantes = useSelector(selectSolicitante);
  const [filtro, setFiltro] = useState("");
  //
  const isOpen = Boolean(anchorEl);
  const onFecharMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  let exibicaoSolicitantes;
  if (filtro?.length > 0) {
    exibicaoSolicitantes = _.filter(
      solicitantes,
      (val) => _.toUpper(val.nome).search(_.toUpper(filtro)) !== -1
    );
  } else {
    exibicaoSolicitantes = solicitantes;
  }

  return (
    <Menu
      sx={{ maxHeight: "70vh", overflowY: "auto" }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onFecharMenu}
    >
      <Paper sx={{ mx: 1, position: "sticky", top: 0 }}>
        <InputBusca
          filtro={filtro}
          setFiltro={setFiltro}
          fullWidth
          desativarPesquisaLenta
          label=""
          placeholder={placeholderCampoBuscaSolicitanteTicket}
          sx={{ mb: 0 }}
        />
      </Paper>
      {exibicaoSolicitantes?.map((ele, idx) => (
        <MenuItem dense key={idx} title={tituloInfoSolicitante}>
          <SolicitanteTicketItem {...ele} onFecharMenu={onFecharMenu} />
        </MenuItem>
      ))}
    </Menu>
  );
};
const SolicitanteTicketItem = memo(
  ({ matricula, planta, avatar, nome, onFecharMenu }) => {
    const dispatch = useDispatch();
    //
    const funcaoAplicarFiltroSolicitante = useCallback(() => {
      const parametros = new URLSearchParams();
      parametros.append("solicitante", [matricula, planta].join("_"));
      dispatch(helpdeskFiltroAdd(parametros, "solicitante"));
      onFecharMenu();
    }, [dispatch, matricula, planta, onFecharMenu]);
    //
    return (
      <ListItem dense divider onClick={funcaoAplicarFiltroSolicitante}>
        <ListItemAvatar>
          <Avatar src={avatar} />
        </ListItemAvatar>
        <ListItemText primary={`${matricula} - ${planta}`} secondary={nome} />
      </ListItem>
    );
  }
);
// Filtros do agente
const FiltrosAgente = () => {
  const { t } = useTranslation();
  const rotuloFiltroAgente = t("helpdesk.labelFilterAgent");
  const tituloFiltroAgente = t("helpdesk.titleFilterAgent");
  const rotuloFiltroSolicitante = t("helpdesk.labelFilterRequester");
  const tituloFiltroSolicitante = t("helpdesk.titleFilterRequester");
  //
  const dispatch = useDispatch();
  const { tipoDeFiltro } = useSelector(selectDados);
  const usuario = useSelector(selectUsuario);
  const [anchorEl, setAnchorEl] = useState();
  const onClickMenu = useCallback(
    (event) => {
      if (tipoDeFiltro === "solicitante") {
        dispatch(helpdeskFiltroLimpar());
        return false;
      }
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl, tipoDeFiltro, dispatch]
  );
  //

  // Funcao para aplicar filtro para o agente (vê somente tickets dele)
  const funcaoFiltroAgente = useCallback(() => {
    if (tipoDeFiltro === "agente") {
      dispatch(helpdeskFiltroLimpar());
    } else {
      const parametros = new URLSearchParams();
      const matPlanta = [usuario.matricula, usuario.planta].join("_");
      parametros.append("agente", matPlanta);
      dispatch(helpdeskFiltroAdd(parametros, "agente"));
    }
  }, [usuario, dispatch, tipoDeFiltro]);

  const filtrosRapidos = [
    {
      titulo: tituloFiltroAgente,
      rotulo: rotuloFiltroAgente,
      value: "agente",
      icone: <Icone icone="Engineering" />,
      onClick: funcaoFiltroAgente,
    },
    {
      titulo: tituloFiltroSolicitante,
      rotulo: rotuloFiltroSolicitante,
      value: "solicitante",
      icone: <Icone icone="Person" />,
      onClick: onClickMenu,
    },
  ];

  return (
    <>
      <SolicitanteTicket anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      {filtrosRapidos?.map((ele, idx) => (
        <Chip
          title={ele.titulo}
          onClick={ele.onClick}
          key={idx}
          label={ele.rotulo}
          icon={ele.icone}
          clickable
          variant={ele.value === tipoDeFiltro ? "filled" : "outlined"}
          color="primary"
        />
      ))}
    </>
  );
};
//
const BarraLateralOculta = () => {
  const { t } = useTranslation();

  const tituloBotaoNovoTicket = t("helpdesk.titleBtnNewTicket");
  const isDark = useTheme().palette.mode === "dark";
  const dispatch = useDispatch();
  const history = useHistory();
  const isSuperAgent = useSelector(selectIsSuperAgente);
  //
  //
  const { data } = useSWR(`${ROTAS[0]}?dados_estatisticos=true`, fetchGet, {
    refreshInterval: 1e3,
  });
  //
  useEffect(() => {
    if (data) {
      dispatch(helpdeskInitEstatisticosUpdate(data));
    }
  }, [dispatch, data]);
  //
  const onClickAddTicket = useCallback(() => {
    dispatch(
      helpdeskSetModal({
        tipo: MODAL.ADD_TICKET,
      })
    );
  }, [dispatch]);
  // Funcao de callback para manutencao de assunto
  const onClickManuSubject = useCallback(() => {
    history.push(HelpdeskAssunto.rota);
  }, [history]);
  // Funcao de callback para manutencao de status
  const onClickManuStatus = useCallback(() => {
    history.push(HelpdeskStatus.rota);
  }, [history]);

  // Funcao de callback para manutencao de criticidade
  const onClickManuCriticality = useCallback(() => {
    history.push(HelpdeskCriticidade.rota);
  }, [history]);
  //
  let opcoesSuperAgente = [];

  if (isSuperAgent) {
    opcoesSuperAgente = [
      {
        icon: "Comment",
        text: t("helpdesk.labelMaintenanceSubjectSuperAgent"),
        onClick: onClickManuSubject,
        color: green[500],
        colorDark: green[800],
      },
      {
        icon: "SwapHoriz",
        text: t("helpdesk.labelMaintenanceStatusSuperAgent"),
        onClick: onClickManuStatus,
        color: orange[500],
        colorDark: orange[800],
      },

      {
        icon: "Report",
        text: t("helpdesk.labelMaintenanceCriticalitySuperAgent"),
        onClick: onClickManuCriticality,
        color: blue[500],
        colorDark: blue[800],
      },
    ];
  }

  return (
    <Paper
      sx={{
        borderRadius: 0,
        p: 1,
        height: alturaPagina,
        overflowY: "auto",
        background: !isDark && blue[50],
        color: !isDark && "black",
      }}
      elevation={isDark ? 4 : 0}
    >
      <Stack>
        <IconButton
          onClick={onClickAddTicket}
          title={tituloBotaoNovoTicket}
          sx={{
            borderRadius: 2,
            mb: 1,
            color: "white",
            backgroundColor: blue[800],
            "&:hover": {
              backgroundColor: blue[900],
            },
          }}
        >
          <Icone icone="ConfirmationNumber" />
        </IconButton>
        {isSuperAgent && (
          <>
            {opcoesSuperAgente.map((ele, idx) => (
              <IconButton
                key={idx}
                onClick={ele.onClick}
                title={ele.text}
                sx={{
                  borderRadius: 2,
                  mb: 1,
                  color: "white",
                  backgroundColor: ele.color,
                  "&:hover": {
                    backgroundColor: ele.colorDark,
                  },
                }}
              >
                <Icone icone={ele.icon} />
              </IconButton>
            ))}
          </>
        )}

        <Divider sx={{ mb: 1, mt: 2 }} />
      </Stack>
    </Paper>
  );
};
//
const BarraLateral = () => {
  const { t } = useTranslation();

  const tituloBotaoNovoTicket = t("helpdesk.titleBtnNewTicket");
  const rotuloBotaoNovoTicket = t("helpdesk.labelBtnNewTicket");
  const tituloAssuntos = t("helpdesk.titleSubnects");
  const tituloStatus = t("helpdesk.titleStatus");
  //
  const isDark = useTheme().palette.mode === "dark";
  const dispatch = useDispatch();
  const history = useHistory();
  const { assunto, status, tipoDeFiltro } = useSelector(selectDados);
  const isSuperAgent = useSelector(selectIsSuperAgente);
  //
  //
  const { data } = useSWR(`${ROTAS[0]}?dados_estatisticos=true`, fetchGet, {
    refreshInterval: 1e3,
  });
  //
  useEffect(() => {
    if (data) {
      dispatch(helpdeskInitEstatisticosUpdate(data));
    }
  }, [dispatch, data]);
  //
  const onClickAddTicket = useCallback(() => {
    dispatch(
      helpdeskSetModal({
        tipo: MODAL.ADD_TICKET,
      })
    );
  }, [dispatch]);
  // Funcao de callback para manutencao de assunto
  const onClickManuSubject = useCallback(() => {
    history.push(HelpdeskAssunto.rota);
  }, [history]);
  // Funcao de callback para manutencao de status
  const onClickManuStatus = useCallback(() => {
    history.push(HelpdeskStatus.rota);
  }, [history]);
  // Funcao de callback para manutencao de criticidade
  const onClickManuCriticality = useCallback(() => {
    history.push(HelpdeskCriticidade.rota);
  }, [history]);
  // Funcao de callback que implementa o filtro
  const onClickAddFiltro = useCallback(
    (tipo, filtro, rotulo) => {
      // Limpa o filtro
      if (tipoDeFiltro === rotulo) {
        dispatch(helpdeskFiltroLimpar());
        return false;
      }
      const parametros = new URLSearchParams();
      parametros.append(tipo, filtro);
      dispatch(helpdeskFiltroAdd(parametros, rotulo));
    },
    [dispatch, tipoDeFiltro]
  );
  //
  const botoesAssuntos = _.map(assunto, (val) => ({
    titulo: val.descricao,
    rotulo: val.descricao,
    total: val.total,
    onClick: () => onClickAddFiltro("assunto", val.id, val.descricao),
  }));
  //
  const botoesStatus = _.map(status, (val) => ({
    titulo: val.descricao,
    rotulo: val.descricao,
    total: val.total,
    onClick: () => onClickAddFiltro("status", val.id, val.descricao),
  }));
  //
  let opcoesSuperAgente = [];

  if (isSuperAgent) {
    opcoesSuperAgente = [
      {
        icon: "Comment",
        text: t("helpdesk.labelMaintenanceSubjectSuperAgent"),
        onClick: onClickManuSubject,
        color: "success",
      },
      {
        icon: "SwapHoriz",
        text: t("helpdesk.labelMaintenanceStatusSuperAgent"),
        onClick: onClickManuStatus,
        color: "warning",
      },

      {
        icon: "Report",
        text: t("helpdesk.labelMaintenanceCriticalitySuperAgent"),
        onClick: onClickManuCriticality,
        color: "info",
      },
    ];
  }

  return (
    <Paper
      sx={{
        borderRadius: 0,
        p: 1,
        height: alturaPagina,
        overflowY: "auto",
        background: !isDark && blue[50],
        color: !isDark && "black",
      }}
      elevation={isDark ? 4 : 0}
    >
      <Stack>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={onClickAddTicket}
          title={tituloBotaoNovoTicket}
          startIcon={<Icone icone="ConfirmationNumber" />}
          sx={{ mb: 1 }}
        >
          {rotuloBotaoNovoTicket}
        </Button>
        {isSuperAgent && (
          <>
            {opcoesSuperAgente.map((ele, idx) => (
              <Button
                key={idx}
                size="small"
                fullWidth
                variant="contained"
                color={ele.color}
                onClick={ele.onClick}
                title={tituloBotaoNovoTicket}
                startIcon={<Icone icone={ele.icon} />}
                sx={{ mb: 1 }}
              >
                {ele.text}
              </Button>
            ))}
          </>
        )}

        <Divider sx={{ mb: 1, mt: 2 }} />

        <H6>{tituloAssuntos}</H6>
        {botoesAssuntos.map((ele, idx) => (
          <BotaoFiltroPainelLateral
            {...ele}
            key={idx}
            isSelecionado={ele.rotulo === tipoDeFiltro}
          />
        ))}
        <Divider sx={{ my: 1 }} />
        <H6>{tituloStatus}</H6>
        {botoesStatus.map((ele, idx) => (
          <BotaoFiltroPainelLateral
            {...ele}
            key={idx}
            isSelecionado={ele.rotulo === tipoDeFiltro}
          />
        ))}
        <Divider sx={{ my: 1 }} />
      </Stack>
    </Paper>
  );
};
// Botoes para filtros
const BotaoFiltroPainelLateral = ({
  isSelecionado,
  titulo,
  total,
  rotulo,
  onClick,
}) => {
  return (
    <Button
      size="small"
      variant={isSelecionado ? "contained" : "text"}
      sx={{ my: 0.5, textTransform: "none" }}
      onClick={onClick}
    >
      <Stack sx={{ minWidth: "100%" }} title={titulo}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Body1>{rotulo}</Body1>
          <Chip
            sx={{ fontWeight: 500, background: grey[300], color: "black" }}
            label={total ? total : 0}
          />
        </Stack>
      </Stack>
    </Button>
  );
};
// Painel principal para exibicao dos chamados
const PainelPrincipalTicket = memo(({ isMobile }) => {
  const { t } = useTranslation();
  const tituloAnimacaoSemDados = t("helpdesk.titleAnimationNoData");
  const rotuloFiltroAtrasado = t("helpdesk.labelIndicatorLate");
  const rotuloFiltroAvancado = t("helpdesk.advancedFilter");

  //
  const isAgente = useSelector(selectIsAgente);
  const history = useHistory();
  const isDark = useTheme().palette.mode === "dark";
  const [aguardar] = useToggle();
  const dispatch = useDispatch();
  const {
    helpdesk,
    helpdeskFiltro,
    total_tickets,
    tipoDeFiltro,
    coluna,
    ordenar,
  } = useSelector(selectDados);
  //
  const funcaoFiltroAvancado = useCallback(() => {
    if (tipoDeFiltro === "filtro_avancado") {
      dispatch(helpdeskFiltroLimpar());
    } else {
      // Se for mobile abre em uma nova tela
      if (isMobile) {
        history.push(rotaHelpdeskFiltroAvancado);
      } else {
        dispatch(
          helpdeskSetModal({
            tipo: MODAL.ADVANCED_FILTER,
          })
        );
      }
    }
  }, [history, isMobile, dispatch, tipoDeFiltro]);
  //
  let corpo = helpdeskFiltro || helpdesk || [];
  const totalDeTickets = helpdeskFiltro ? helpdeskFiltro.length : total_tickets;
  // Veja se é para ordenar por aqui (caso de helpdeskFiltro)
  if (coluna && ordenar && helpdeskFiltro) {
    corpo = _.orderBy(
      corpo,
      (val) => {
        let inColunaFiltro;
        switch (coluna) {
          case "ultima_alteracao":
            inColunaFiltro = "ultima_interacao";
            break;
          default:
            inColunaFiltro = coluna;
            break;
        }
        return val[inColunaFiltro];
      },
      ordenar
    );
  }
  // Funcao para aplicar filtro para os atrasados
  const funcaoFiltroAtrasado = useCallback(() => {
    if (tipoDeFiltro === rotuloFiltroAtrasado) {
      dispatch(helpdeskFiltroLimpar());
    } else {
      const parametros = new URLSearchParams();
      parametros.append("atrasado", true);
      dispatch(helpdeskFiltroAdd(parametros, rotuloFiltroAtrasado));
    }
  }, [dispatch, tipoDeFiltro, rotuloFiltroAtrasado]);

  return (
    <Stack
      sx={{
        width: isMobile && "100%",
        minHeight: alturaPagina,
        background: !isDark && grey[200],
        mt: 1,
      }}
    >
      <Stack
        direction="row"
        sx={{
          width: "calc(100% - 16px)",
          overflowX: "auto",
          px: 1,
        }}
        spacing={1}
      >
        {isAgente && <FiltrosAgente />}

        <Chip
          label={rotuloFiltroAtrasado}
          icon={<Icone icone="Schedule" />}
          clickable
          color="primary"
          variant={
            rotuloFiltroAtrasado === tipoDeFiltro ? "filled" : "outlined"
          }
          onClick={funcaoFiltroAtrasado}
        />
        <Chip
          label={rotuloFiltroAvancado}
          icon={<Icone icone="FilterAlt" />}
          clickable
          color="primary"
          variant={"filtro_avancado" === tipoDeFiltro ? "filled" : "outlined"}
          onClick={funcaoFiltroAvancado}
        />
      </Stack>

      <PainelPaginacao total={totalDeTickets} />
      {aguardar ? (
        <Pesquisando />
      ) : (
        <>
          {!isMobile && <CabecalhoTickets />}
          <AnimatePresence>
            {corpo && corpo.length > 0 ? (
              <motion.div {...animacaoTickets} key="ScrollFiltro">
                <ScrollInfinito
                  itens={corpo}
                  itensPorPagina={10}
                  tamanho="80vh"
                  render={(ele) => (
                    <Slide
                      direction="left"
                      key={ele.id}
                      in
                      mountOnEnter
                      unmountOnExit
                    >
                      <Box>
                        <TicketItem {...ele} isMobile={isMobile} />
                      </Box>
                    </Slide>
                  )}
                />
                {isMobile && (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}
              </motion.div>
            ) : (
              <motion.div {...animacaoTickets} key="animacao">
                <AnimacaoSemDados titulo={tituloAnimacaoSemDados} />
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
    </Stack>
  );
});
//
const PainelPaginacao = memo(({ total }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const rotuloComponentePaginacao = t("helpdesk.titleComponentPagination");
  //
  const dispatch = useDispatch();
  const { total_paginas, idPagina, tipoDeFiltro, coluna, ordenar } =
    useSelector(selectDados);
  const onChangePagina = useCallback(
    (evt, pageID) => {
      let objOrdenar;
      if (coluna && ordenar) {
        objOrdenar = {
          coluna,
          ordenar,
        };
      }
      dispatch(helpdeskPagina(pageID, () => {}, objOrdenar));
    },
    [dispatch, coluna, ordenar]
  );

  return (
    <>
      <Divider sx={{ mb: 1, mt: 2 }} />
      <Stack direction="row" justifyContent="space-between">
        <TotalizadorTicket total={total} />
        {!tipoDeFiltro && (
          <Pagination
            size={isMobile ? "small" : "medium"}
            title={rotuloComponentePaginacao}
            color="primary"
            count={total_paginas}
            page={idPagina}
            onChange={onChangePagina}
          />
        )}
      </Stack>
      <Divider sx={{ my: 1 }} />
    </>
  );
});
//
const CabecalhoTickets = memo(() => {
  const { t } = useTranslation();
  const alinhamentoTexto = "center";
  const tituloCampoID = t("helpdesk.titleFieldId");
  const tituloCampoPlanta = t("helpdesk.titleFieldPlant");
  const tituloCampoSolcitante = t("helpdesk.titleFieldRequester");

  const tituloCampoCriticidade = t("helpdesk.titleFieldCriticality");
  const tituloCampoAgente = t("helpdesk.titleFieldAgent");
  const tituloCampoStatus = t("helpdesk.titleFieldStatus");
  const tituloCampoTitulo = t("helpdesk.titleFieldTitle");
  const tituloCampoUltimaInteracao = t("helpdesk.titleFieldLastInteraction");
  //
  const dispatch = useDispatch();
  const { ordenar, idPagina, coluna } = useSelector(selectDados);

  const funcaoOrdenarTickets = useCallback(
    (inColuna) => {
      let inOrdenar = "desc";
      // E a mesma coluna
      if (coluna === inColuna) {
        switch (ordenar) {
          case "desc":
            inOrdenar = "asc";
            break;
          case "asc":
            inOrdenar = null;
            break;
          default:
            inOrdenar = "desc";
        }
      }

      // Desativar a ordenacao de coluna
      if (!inOrdenar) {
        dispatch(helpdeskPagina(idPagina, () => {}));
      } else {
        // Aplicar a ordenacao
        dispatch(
          helpdeskPagina(idPagina, () => {}, {
            coluna: inColuna,
            ordenar: inOrdenar,
          })
        );
      }
    },
    [dispatch, ordenar, coluna, idPagina]
  );
  //
  const cabecalhos = [
    {
      titulo: tituloCampoID,
      onClick: () => funcaoOrdenarTickets("id"),
      isOrdem: coluna === "id" && ordenar,
      sx: {},
    },
    {
      titulo: tituloCampoPlanta,
      onClick: () => funcaoOrdenarTickets("planta"),
      isOrdem: coluna === "planta" && ordenar,
      sx: {},
    },
    {
      titulo: tituloCampoSolcitante,
      onClick: () => funcaoOrdenarTickets("solicitante"),
      isOrdem: coluna === "solicitante" && ordenar,
      sx: {
        textAlign: "left",
        flex: 2,
      },
    },
    {
      titulo: tituloCampoCriticidade,
      onClick: () => funcaoOrdenarTickets("criticidade"),
      isOrdem: coluna === "criticidade" && ordenar,
      sx: {
        textAlign: "left",
        flex: 1,
      },
    },
    {
      titulo: tituloCampoTitulo,
      onClick: () => funcaoOrdenarTickets("titulo"),
      isOrdem: coluna === "titulo" && ordenar,
      sx: {
        textAlign: alinhamentoTexto,
        flex: 1,
      },
    },
    {
      titulo: tituloCampoAgente,
      onClick: () => funcaoOrdenarTickets("agente"),
      isOrdem: coluna === "agente" && ordenar,
      sx: {
        textAlign: alinhamentoTexto,
        flex: 1,
      },
    },
    {
      titulo: tituloCampoStatus,
      onClick: () => funcaoOrdenarTickets("status"),
      isOrdem: coluna === "status" && ordenar,
      sx: {
        textAlign: alinhamentoTexto,
        flex: 1,
      },
    },
    {
      titulo: tituloCampoUltimaInteracao,
      onClick: () => funcaoOrdenarTickets("ultima_alteracao"),
      isOrdem: coluna === "ultima_alteracao" && ordenar,
      sx: {
        textAlign: alinhamentoTexto,
        flex: 1,
      },
    },
  ];

  return (
    <Paper elevation={0} sx={{ mb: 1 }}>
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        alignItems="center"
        flexWrap="nowrap"
      >
        {cabecalhos.map((ele, idx) => (
          <CabecalhoTicketBotao
            key={idx}
            onClick={ele.onClick}
            isOrder={ele.isOrdem}
            titulo={ele.titulo}
            sx={ele.sx}
          />
        ))}
      </Stack>
    </Paper>
  );
});
//
const CabecalhoTicketBotao = memo(({ titulo, sx, onClick, isOrder }) => {
  const { t } = useTranslation();
  const tituloBotaoTicketOrdenacao = t("helpdesk.titleBtnOrderTicket");
  return (
    <Button
      onClick={onClick}
      title={tituloBotaoTicketOrdenacao}
      sx={{ ...sx, textTransform: "none" }}
      startIcon={
        <Icone
          icone={
            isOrder
              ? isOrder === "asc"
                ? "KeyboardArrowUp"
                : "KeyboardArrowDown"
              : "UnfoldMore"
          }
        />
      }
    >
      {titulo}
    </Button>
  );
});
//
const TotalizadorTicket = memo(({ total }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const tituloTotalDeTickets = t("helpdesk.titleTotalTickets");
  const tituloTickets = `${total === 0 ? "Nenhum" : total} ${
    total > 1 ? "Tickets" : "Ticket"
  }`;

  return (
    <Stack direction="row" spacing={1} title={tituloTotalDeTickets}>
      {!isMobile && <Icone icone="ConfirmationNumber" />}
      {isMobile ? (
        <Body2 sx={{ ml: 1 }}>{tituloTickets}</Body2>
      ) : (
        <Body1>{tituloTickets}</Body1>
      )}
    </Stack>
  );
});
//
const TicketItem = memo(
  ({
    id,
    solicitante,
    avatar,
    email,
    status,
    prazo,
    atrasado,
    assunto,
    agente,
    planta,
    ultima_interacao,
    isMobile,
    titulo,
    criticidade,
    icone,
  }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const alinhamentoTexto = "center";
    const tituloTicket = t("helpdesk.titleTicket");
    const rotuloTicketNumero = t("helpdesk.titleTicketNumber");
    const rotuloTicketNaoAtribuido = t("helpdesk.titleTicketNotAssigned");
    const rotuloAssunto = t("helpdesk.titleSubject");
    const rotuloUltimaInteracao = t("helpdesk.titleLastInteraction");
    const rotuloPlanta = t("helpdesk.titlePlant");
    const rotuloAgente = t("helpdesk.titleAgent");
    const tituloIndicadorAtrasado = t("helpdesk.titleIndicatorLate");
    const rotuloIndicadorAtrasado = t("helpdesk.labelIndicatorLate");
    //
    const verDetalhesTicket = useCallback(() => {
      history.push(`${rotaHelpdeskDetalhes}?ticket=${id}`);
    }, [id, history]);

    const ultimaInteracao = format(
      isDate(ultima_interacao) ? ultima_interacao : parseISO(ultima_interacao),
      "dd/MM/yy HH:mm"
    );
    const dataPrazo = `Prazo final: ${format(
      isDate(prazo) ? prazo : parseISO(prazo),
      "dd/MM/yy"
    )}`;

    return (
      <Paper sx={{ my: 1 }} title={tituloTicket} elevation={1}>
        <ListItemButton dense onClick={verDetalhesTicket}>
          {isMobile ? (
            <ListItem dense disableGutters disablePadding>
              <ListItemAvatar>
                <Avatar alt={solicitante} src={avatar} />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    mb: 1,
                  },
                }}
                primary={solicitante}
                secondary={
                  <Stack>
                    <Stack
                      sx={{ mb: 1 }}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Body1 fontWeight="bold">
                        {rotuloTicketNumero} {id}
                      </Body1>
                      <ChipStatus status={status} />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Caption>
                        {rotuloAssunto}: {assunto}
                      </Caption>
                      <Caption>
                        {rotuloAgente}:{" "}
                        {agente
                          ? agente.split(" ")[0]
                          : rotuloTicketNaoAtribuido}
                      </Caption>
                    </Stack>
                    <Stack
                      sx={{ mt: 0.5 }}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Caption fontWeight="bold">
                        {rotuloPlanta}: {planta}
                      </Caption>
                      <Caption>
                        {rotuloUltimaInteracao}: {ultimaInteracao}
                      </Caption>
                    </Stack>
                  </Stack>
                }
              />
            </ListItem>
          ) : (
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              alignItems="center"
              flexWrap="nowrap"
              spacing={1}
            >
              <H6
                sx={{ pr: 3 - id.toString().length }}
                align={alinhamentoTexto}
              >
                {id}
              </H6>
              <Chip
                label={planta}
                title={planta}
                variant="outlined"
                size="small"
                color="primary"
              />
              <ListItem dense disableGutters sx={{ flex: 2 }}>
                <ListItemAvatar>
                  <Avatar alt={solicitante} src={avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Body2>{solicitante}</Body2>

                      {atrasado && (
                        <Chip
                          title={tituloIndicadorAtrasado}
                          size="small"
                          label={rotuloIndicadorAtrasado}
                          color="error"
                          variant="outlined"
                        />
                      )}
                    </Stack>
                  }
                  secondary={email}
                />
              </ListItem>
              <Box sx={{ flex: 1, textAlign: "center" }}>
                {criticidade ? (
                  <Chip
                    icon={<Icone icone={icone} />}
                    label={criticidade}
                    color="primary"
                    variant="outlined"
                  />
                ) : (
                  <Chip label={"Nenhum"} size="small" />
                )}
              </Box>
              <Body1 sx={{ flex: 2 }}>{titulo}</Body1>

              <Body1 sx={{ flex: 1 }} align={alinhamentoTexto}>
                {agente || rotuloTicketNaoAtribuido}
              </Body1>
              <Box sx={{ flex: 1 }} align={alinhamentoTexto}>
                {status && <ChipStatus status={status} />}
              </Box>
              <ListItem dense disableGutters sx={{ flex: 1 }}>
                <ListItemText primary={ultimaInteracao} secondary={dataPrazo} />
              </ListItem>
            </Stack>
          )}
        </ListItemButton>
      </Paper>
    );
  }
);
export const ChipStatus = ({ status, minimal }) => {
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const listaStatus = useSelector(selectStatus);
  const corStatus = _.filter(listaStatus, (val) => val.descricao === status);

  return (
    <Chip
      size={isMobile || minimal ? "small" : "medium"}
      label={status}
      sx={{
        background: corStatus[0]?.cor,
        color: "white",
      }}
    />
  );
};

export default HelpDesk;
