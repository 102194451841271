import React, { memo, useState, } from 'react';
import { TextField, CircularProgress } from '@mui/material';
//
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce } from 'react-use';

const Filtro = memo(({ sx, fullWidth, placeholder, label, size,  desativarPesquisaLenta, filtro, setFiltro })=>{
   
    const [ aguardar, setAguardar ] = useState(false);
    // Cria um estado para determinar quando o usuario deixou de digitar
    const [valor, setValor ] = useState(filtro || '');
    // Utiliza o useDebounce para determinar que depois de 500ms podemos aplicar o filtro
    const [ , ] = useDebounce(
        ()=>{
            // Valor pode ser atualizado
            setFiltro(valor);
            setAguardar(false);

        }, desativarPesquisaLenta ? 1 : 500, [valor]
    );

    return (
        
        <TextField size={size} fullWidth={fullWidth}
            placeholder={placeholder}
            label={label} type="search"
            value={valor} sx={sx}
            onChange={e=> {
                setValor(e.target.value);
                !desativarPesquisaLenta && setAguardar(true);
            }}
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            InputProps={{
                startAdornment: aguardar ? <CircularProgress sx={{mr: 1}} size={20} /> : <SearchIcon color='primary' />
            }}
        />
        
    )
});
//
Filtro.defaultProps = {
    sx: {mb: 5},
    label: 'Filtro',
    placeholder: 'Digite o que procura',
    size: 'small',
    fullWidth: false,
    desativarPesquisaLenta: false,

}

export default Filtro;