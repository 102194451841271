import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
/**
Um lindo botão que pode ser usado em várias partes da aplicação. 

*/

const Wrapper = styled.button`
    outline: none !important;
    cursor: pointer;
    border-radius: 4px;
    border-width: ${props => props.outline ? '1px' : 0};
    border-color: ${props => props.outline ? props.theme && props.theme.bg ? props.theme.bg : '#b00020' : 'transparent'};
    box-shadow: 0 0px 4px rgba(0,0,0, .4);
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428557143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background: ${props => props.outline ? '#fff' : props.error ? props.theme && props.theme.bgError ? props.theme.bgError : '#b00020' : props.theme ? props.theme.bg : '#ccc'};
    color: ${props => props.outline ? props.theme && props.theme.bg : props.error ? props.theme && props.theme.textError ? props.theme.textError : '#fff' : props.theme ? props.theme.text : 'black'};
    transition: all .05s;

    ${this}:hover {
        box-shadow: 0 0px 8px rgba(0,0,0,.4);
        transform: scale(1.01);
    }
    
    // @media(max-width: 640px){
    //     width: 98%;
    //     margin: 8px 1%;
    // }
`;

const Botao = props=>(
    <Wrapper {...props} />
)

Botao.propTypes = {
    /** Um tema para o botao. Usado com ThemeProvider do styled Component  {bg: 'red', text: 'white' } */
    theme: PropTypes.shape({
        bg: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    }),
    /** Esta props define que o botao terá a borda com a cor da bg (ou vermelha quando nao se tem theme aplicado) */
    outline: PropTypes.bool,
    /** Esta props quando repassada coloca a cor de erro no botão { theme.bgError, theme.textError } */
    error: PropTypes.bool,
    /** Esta funcao determina algo que deve acontecer quando o botão for clicado */
    onClick: PropTypes.func,
    /** Define um titulo para o botão. Uma mensagem que aparece quando o mouse fica sobre o botão */
    title: PropTypes.string,
    /** Determina se ele deve ficar desabilitado (impedindo o clique) ou não */
    disabled: PropTypes.bool,
    /** Passa um estilo inline para o botão */
    style: PropTypes.object,
    /** Também aceita nomes de classes a serem passadas. */
    className: PropTypes.string,
}

Botao.defaultProps = {
    onClick: ()=>{},
    title: 'CLIQUE AQUI',
    disabled: false,
    style: {},
    className: 'Botao',
}

export default Botao;