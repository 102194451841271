import { Stack } from "@mui/material";
import React from "react";
import { AnimacaoSemDados, H6, TabelaV2 } from "../../../components";
import { useSelector } from "react-redux";
import { filterBudgetAnalyticSelector } from "./budget-selectors";
import { optTabela } from "../../../utils/utils";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const getItemsFormat = (resultArray) => {
  return _.map(resultArray, (reg) => [
    reg.data[3],
    reg.data[4],
    reg.data[5],
    reg.data[10],
    reg.data[11],
    reg.data[13],
  ]);
};

function BudgetDetailsAccount({ keyFilter }) {
  const { t } = useTranslation();
  const headerTable = [
    t("budget.headerDescription"),
    t("budget.headerHistory"),
    t("budget.headerClientSupplier"),
    t("budget.headerMonth"),
    t("budget.headerAccount"),
    t("budget.headerValue"),
  ];
  const result = useSelector(filterBudgetAnalyticSelector(keyFilter));
  //
  let items = [];
  if (result?.length > 0) {
    items = getItemsFormat(result);
  }

  const optTable2 = {
    ...optTabela,
    monetario: [5],
    baixar_em_excel: false,
  };

  return (
    <Stack>
      <H6>{t("budget.title")}</H6>
      {items.length === 0 ? (
        <AnimacaoSemDados titulo={t("budget.titleNoData")} />
      ) : (
        <TabelaV2 {...optTable2} cabe={headerTable} corpo={items} />
      )}
    </Stack>
  );
}

export default BudgetDetailsAccount;
