import { ButtonBase, Paper, Stack } from "@mui/material";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRnciGetFilter,
  selectRnciTotalPerStatus,
} from "./rnci-selectors";
import { Caption, H6 } from "../../../components";
import { blue, green, red } from "@mui/material/colors";
import { rnciNcpSetFilter } from "./rnci-ncp-actions";

//
export const obterCorStatus = (situacao, isHex) => {
  switch (situacao) {
    case "Q": // Setor de qualidade
      return isHex ? red[400] : "error";
    case "S": // Subida de linha
      return isHex ? blue[200] : "info";
    default: // Situacao aberta
      return isHex ? green[200] : "success";
  }
};

function RnciPanelInfo() {
  const dispatch = useDispatch();
  const status = useSelector(selectRnciTotalPerStatus);
  const filter = useSelector(selectRnciGetFilter);
  //
  const onFilter = useCallback(
    (statusFilter) => {
      let newFilter = {};
      if (filter && filter.status && filter.status.includes(statusFilter)) {
        filter.status = [];
      } else {
        //
        if (!filter) {
          newFilter["status"] = [statusFilter];
        } else {
          newFilter = {
            ...filter,
            status: [statusFilter],
          };
        }
      }

      dispatch(rnciNcpSetFilter(newFilter));
    },
    [dispatch, filter]
  );

  return (
    <Stack direction="row" sx={{ mb: 1 }} gap={1}>
      {status?.map((ele, idx) => (
        <ButtonBase
          onClick={() => onFilter(ele.status)}
          sx={{ flex: 1, height: 72 }}
          key={idx}
        >
          <Paper
            elevation={
              filter && filter.status && filter.status.includes(ele.status)
                ? 8
                : 1
            }
            sx={{
              flex: 1,
              height: 72,
              background: obterCorStatus(ele.status, true),
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                alignSelf: "center",
                justifySelf: "center",
                height: 72,
              }}
            >
              <H6>{ele.total}</H6>
              <Caption>{ele.name}</Caption>
            </Stack>
          </Paper>
        </ButtonBase>
      ))}
    </Stack>
  );
}

export default RnciPanelInfo;
