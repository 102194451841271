import React, { memo } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { AnimatePresence, motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import { fnGetColor, fnGetIcon, fnKaizenGetText } from "./kaizen-utils";
import { Icone } from "../../../components";
import { useTranslation } from "react-i18next";

// Componente que exibe o painel com os status
const PainelDeStatus = memo(
  ({ qtd_por_status, onClick, statusSelecionado, isMobile }) => {
    const { t } = useTranslation();
    //const qtd_por_status = useSelector(state=> state.cadastroProjetos?.qtd_por_status);
    const totalDeSituacoes = {
      A: 0,
      R: 0,
      S: 0,
      C: 0,
      I: 0,
      E: 0,
    };
    // Conte quantas situacoes
    if (qtd_por_status)
      Object.keys(qtd_por_status).forEach(
        (key) => (totalDeSituacoes[key] += qtd_por_status[key])
      );

    // Verificar se for mobile, alterar o objeto sx do paper
    const sxPaper = isMobile
      ? {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }
      : {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1.1,
        };

    return (
      <Grid sx={{ mb: 1 }} container spacing={1}>
        {Object.keys(totalDeSituacoes).map((key, idx) => {
          const elevacao = statusSelecionado === key ? 8 : 1;
          return (
            <ItemDoPainel
              key={idx}
              elevacao={elevacao}
              totalStatus={totalDeSituacoes[key]}
              siglaStatus={key}
              onClick={onClick}
            />
          );
        })}
        <AnimatePresence>
          {statusSelecionado && (
            <motion.div
              key="delete"
              style={{ marginTop: "8px", width: "inherit" }}
              initial={{ scale: 0.01, opacity: 0.01 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.01, opacity: 0.01 }}
              transition={{ duration: 0.1 }}
            >
              <Grid
                item
                xs={isMobile ? 12 : 4}
                sx={{
                  mx: "auto",
                  cursor: "pointer",
                }}
              >
                <Paper
                  sx={{
                    backgroundColor: red[700],
                    color: "white",
                    pt: 0.5,
                    ...sxPaper,
                  }}
                  onClick={() => onClick(null)}
                  title={t("cadastro_projetos.titleCard")}
                >
                  <Icone icone="RestoreFromTrash" />
                  <Typography
                    component="span"
                    variant="subtitle2"
                    align="center"
                  >
                    {t("cadastro_projetos.clearFilter")}
                  </Typography>
                  <div />
                </Paper>
              </Grid>
            </motion.div>
          )}
        </AnimatePresence>
      </Grid>
    );
  }
);

// Componente que cria o item do painel superior
const ItemDoPainel = memo(({ onClick, elevacao, siglaStatus, totalStatus }) => {
  const {
    breakpoints: { values },
  } = useTheme();
  const { t } = useTranslation();
  const isMobile = values["sm"] > window.innerWidth;
  // Verificar se for mobile, alterar o objeto sx do paper
  const sxPaper = isMobile
    ? {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }
    : {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 1.1,
      };

  return (
    <Grid item xs={!isMobile ? 2 : 4}>
      <Paper
        sx={{
          ...fnGetColor(siglaStatus),
          pt: 0.5,
          ...sxPaper,
          cursor: "pointer",
        }}
        onClick={() => onClick(siglaStatus)}
        elevation={elevacao}
      >
        {fnGetIcon(siglaStatus)}
        <Typography component="span" variant="subtitle2" align="center">
          {fnKaizenGetText(siglaStatus, t)}
        </Typography>
        <Typography component="span" variant="subtitle2" align="center">
          {totalStatus}
        </Typography>
      </Paper>
    </Grid>
  );
});

export default PainelDeStatus;
