import { useDispatch, useSelector } from "react-redux";
import { selectorMatUser, selectorPlantUser } from "../selectors";
import {
  Avatar,
  Button,
  Collapse,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Icone, RetornoEmBranco } from "../../../../components";
import { memo, useState } from "react";
import { feedRHDelComentario } from "../feed-actions";
import { format, parseISO } from "date-fns";
import PainelComentario from "./feed-painel-comentario";

const STR = {
  labelComments: "Comentário(s)",
  titleDelComment: "Deseja realmente excluir este comentário ?",
  btnConfirmDelComment: "SIM",
  btnCancelDelComment: "NÃO",
};

const selectTamanhoCampoComentario = (state) =>
  state?.tamanhoCampos?.comentarios?.comentario;

// Componente para exibir a lista de comentarios
const ListaComentarios = ({ comentarios }) => {
  const matricula = useSelector(selectorMatUser);
  const planta = useSelector(selectorPlantUser);

  return (
    <Container
      maxWidth="md"
      disableGutters
      sx={{ py: 1, minHeight: "50vh", maxHeight: "80vh" }}
    >
      <Paper
        elevation={2}
        sx={{
          p: 1,
          zIndex: 100,
          background: "white",
          position: "sticky",
          top: 0,
        }}
      >
        {comentarios?.length > 0 && (
          <Typography
            fontWeight="bold"
            align="center"
            component="p"
            variant="caption"
          >
            ({comentarios.length}) {STR.labelComments}
          </Typography>
        )}
      </Paper>
      {comentarios?.length > 0 ? (
        <List>
          {comentarios.map((ele) => (
            <ListaItemComentarios
              key={ele.id_comentario}
              item={ele}
              meuComentario={ele.mat === matricula && planta === ele.planta}
            />
          ))}
        </List>
      ) : (
        <RetornoEmBranco />
      )}
    </Container>
  );
};

// Componente para exibir os comentarios
const ListaItemComentarios = memo(({ meuComentario, item }) => {
  const tamanhoCampoComentario = useSelector(selectTamanhoCampoComentario);
  const dispatch = useDispatch();
  //
  const { id_comentario, id_feed, nome, comentario, data, avatar } = item;
  const [editar, setEditar] = useState(false);
  const [excluir, setExcluir] = useState(false);

  // Funcao de callback para editar um comentario
  const fnEditar = () => {
    setExcluir(false);
    setEditar(!editar);
  };
  // Funcao que controla a exibicao das opcoes de exclusao
  const fnExcluir = () => {
    setEditar(false);
    setExcluir(!excluir);
  };
  // Funcao para enviar o comentario para exclusao
  const fnEnviarParaExclusao = () => {
    dispatch(
      feedRHDelComentario({
        id_comentario,
        id_feed,
      })
    );
  };

  return (
    <>
      <ListItem divider>
        <ListItemAvatar>
          <Avatar src={avatar}>{nome.substring(0, 1)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={nome}
          secondary={
            <Stack>
              <Typography variant="caption">{comentario}</Typography>
              {meuComentario ? (
                <Stack direction="row" alignItems="center" spacing={2}>
                  <IconButton onClick={fnEditar}>
                    <Icone icone="Edit" />
                    <Typography variant="caption"></Typography>
                  </IconButton>
                  <IconButton onClick={fnExcluir}>
                    <Icone icone="Delete" color="error" />
                    <Typography variant="caption"></Typography>
                  </IconButton>
                  <Typography
                    sx={{ opacity: 0.7, flex: 1 }}
                    align="right"
                    variant="caption"
                  >
                    {format(parseISO(data), "dd/MM/yy HH:mm")}
                  </Typography>
                </Stack>
              ) : (
                <Typography
                  sx={{ opacity: 0.7 }}
                  align="right"
                  variant="caption"
                >
                  {format(parseISO(data), "dd/MM/yy HH:mm")}
                </Typography>
              )}
            </Stack>
          }
        />
      </ListItem>
      <Collapse in={excluir || editar} unmountOnExit>
        {editar ? (
          <PainelComentario
            id_feed={id_comentario}
            defaultComentario={comentario}
            maxLength={tamanhoCampoComentario}
            editar
          />
        ) : excluir ? (
          <Stack sx={{ px: 2 }} spacing={1}>
            <Typography variant="subtitle2" align="center">
              {STR.titleDelComment}
            </Typography>
            <Stack spacing={1} direction="row-reverse">
              <Button
                size="small"
                variant="contained"
                onClick={fnEnviarParaExclusao}
              >
                {STR.btnConfirmDelComment}
              </Button>
              <Button size="small" onClick={() => setExcluir(false)}>
                {STR.btnCancelDelComment}
              </Button>
            </Stack>
          </Stack>
        ) : null}
      </Collapse>
    </>
  );
});

export default ListaComentarios;
