import _ from "lodash";
import { createSelector } from "reselect";

export const selectColaboradores = (state) =>
  state.manutencaoVariaveis?.colaboradores;
export const selectFiltroColaborador = (state) =>
  state.manutencaoVariaveis?.filtroColaborador;
export const selectFiltroPlanta = (state) =>
  state.manutencaoVariaveis?.filtroPlanta;
export const selectVariaveis = (state) => state.manutencaoVariaveis?.variaveis;
export const selectVariavelXColaborador = (state) =>
  state.manutencaoVariaveis?.colaborador_x_variavel;
export const selectDrawer = (state) => state.manutencaoVariaveis?.drawer;
export const selectVariaveisGlobais = (state) =>
  state.manutencaoVariaveis?.variaveis_globais;

// Colaboradores filtrados
export const selectColaboradoresFiltro = createSelector(
  selectColaboradores,
  selectFiltroColaborador,
  selectFiltroPlanta,
  (colaboradores, filtro, filtroPlanta) => {
    // Se o filtro de planta estiver ativo aplica oprimeiro filtro
    let item;

    if (filtroPlanta && filtroPlanta.length > 0)
      item = _.filter(colaboradores, (obj) =>
        filtroPlanta?.includes(obj.planta)
      );

    if (!filtro || filtro?.length < 1) return item || colaboradores;

    // Realizando o filtro
    const filtroLower = _.toLower(filtro);
    //
    const filtrado = _.filter(
      item,
      (obj) =>
        _.toLower(obj.mat).search(filtroLower) > -1 ||
        _.toLower(obj.nome).search(filtroLower) > -1 ||
        _.toLower(obj.cargo).search(filtroLower) > -1 ||
        _.toLower(obj.planta).search(filtroLower) > -1
    );
    return filtrado;
  }
);
// Variaveis do colaborador selecionadas
export const selectVariaveisColaborador = createSelector(
  selectVariavelXColaborador,
  selectDrawer,
  (variavelXColaborador, drawer) => {
    const { dados } = drawer;
    // Retorna as variaveis do colaborador ou nada (precisa gerar novo objeto para que os dados sejam atualizados)
    return variavelXColaborador[dados] ? [...variavelXColaborador[dados]] : [];
  }
);
// Seletor que recupera o valor da variavelGlobal selecionada no drawer
export const selectVariavelGlobalSelecionada = createSelector(
  selectDrawer,
  selectVariaveisGlobais,
  (drawer, varGlobal) => {
    const { dados } = drawer; // Variavel global
    return varGlobal.filter(
      (ele) => String(ele.id_variavel) === String(dados)
    )[0];
  }
);
