import {
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Icone } from "../../../components";

const DetalheCandidatoAVaga = ({ icone, titulo, descricao }) => {
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  return (
    <ListItem disablePadding divider>
      {isMobile && (
        <ListItemIcon>
          <Icone icone={icone} />
        </ListItemIcon>
      )}
      <ListItemText primary={titulo} secondary={descricao} />
    </ListItem>
  );
};

export default DetalheCandidatoAVaga;
