import axios from "axios";
import { toast } from "react-toastify";
import { ToastErro } from "../../../utils/utils";
import { setAguardar } from "../../../redux/actions/aguardar-actions";
import {
  ERRO_NAO_ENVIADO,
  ERRO_STATUS_NAO_200,
} from "../../../redux/actions/erro-actions";

export const COLABORADORES_GET_COLABORADORES =
  "COLABORADORES_GET_COLABORADORES";
export const COLABORADORES_MENU = "COLABORADORES_MENU";
export const COLABORADORES_ADD_MENU = "COLABORADORES_ADD_MENU";
export const COLABORADORES_DEL_MENU = "COLABORADORES_DEL_MENU";
export const COLABORADORES_UPD_ELEGIVEL = "COLABORADORES_UPD_ELEGIVEL";
export const COLABORADORES_UPD_EMAIL = "COLABORADORES_UPD_EMAIL"; // Para atualizar o email
export const COLABORADORES_ADD_COLABORADOR = "COLABORADORES_ADD_COLABORADOR"; // Colaborador adicionado
export const COLABORADORES_UPD_GESTOR_SITUACAO =
  "COLABORADORES_UPD_GESTOR_SITUACAO";
export const COLABORADORES_UPD_ARVORE = "COLABORADORES_UPD_ARVORE";
export const COLABORADORES_DEL_COLABORADOR = "COLABORADORES_DEL_COLABORADOR";
export const COLABORADORES_SET_DRAWER = "COLABORADORES_SET_DRAWER";
export const COLABORADORES_FECHAR_DRAWER = "COLABORADORES_FECHAR_DRAWER";
export const COLABORADORES_DEL_MENUS = "COLABORADORES_DEL_MENUS"; // Para excluir todos os menus
export const COLABORADORES_ADD_MENUS = "COLABORADORES_ADD_MENUS"; // Para incluir todos os menus

//
const ROTAS = [
  "/get_colaboradores",
  "/colaborador_menu",
  "/colaborador_atualizar_senha",
  "/colaborador_elegivel",
  "/colaborador_email",
  "/colaboradores",
  "/colaboradores_manual",
];

// Action para obter os colaboradores e menus
export const colaboradoresGetColaboradores = () => async (dispatch) => {
  try {
    dispatch(setAguardar(true));
    const resp = await axios.get(ROTAS[0]);
    if (resp.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp.data.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Tudo certo atualiza a estrutura principal
    dispatch({
      type: COLABORADORES_GET_COLABORADORES,
      data: resp.data,
    });

    // Chamada para obter a listagem dos menus
    const resp2 = await axios.get(ROTAS[1]);
    if (resp2.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp2.data.erro) {
      ToastErro(resp2.data.erro);
      return false;
    }
    // Tudo certo atualiza a estrutura principal
    dispatch({
      type: COLABORADORES_MENU,
      data: resp2.data,
    });
  } catch (error) {
    ToastErro(ERRO_NAO_ENVIADO);
    return false;
  } finally {
    dispatch(setAguardar(false));
  }
};
// Action para atualizar o elegivel
export const colaboradoresUpdElegivel = (obj) => async (dispatch) => {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  try {
    const resp = await axios.put(ROTAS[3], formData);
    if (resp.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp.data.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    //
    dispatch({
      type: COLABORADORES_UPD_ELEGIVEL,
      data: obj,
    });
  } catch (error) {
    ToastErro(ERRO_NAO_ENVIADO);
    return false;
  }
};

// Action para atualizar o email
export const colaboradoresUpdEmail = (obj) => async (dispatch) => {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  try {
    //dispatch(setAguardar(true));
    const resp = await axios.put(ROTAS[4], formData);
    if (resp.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp.data.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    //
    dispatch({
      type: COLABORADORES_UPD_EMAIL,
      data: obj,
    });
    toast.dark(resp.data.sucesso, {
      type: "success",
    });
  } catch (error) {
    ToastErro(ERRO_NAO_ENVIADO);
    return false;
  }
};

// Action para atribuir/retirar menus
export const colaboradoresUpdDelMenu = (obj, isDelete) => async (dispatch) => {
  //
  let formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  const fn = isDelete ? axios.delete : axios.put;
  //
  if (isDelete) formData = { data: formData };

  try {
    const resp = await fn(ROTAS[1], formData);
    if (resp.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp.data.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Tudo certo atualiza a estrutura principal
    dispatch({
      type: isDelete ? COLABORADORES_DEL_MENU : COLABORADORES_ADD_MENU,
      data: isDelete ? obj : resp.data.data,
    });
  } catch (error) {
    ToastErro(ERRO_NAO_ENVIADO);
    return false;
  }
};
// Action para atribuir/retirar todos os menus enviados
export const colaboradoresUpdDelMenus = (obj, isDelete) => async (dispatch) => {
  //
  let formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  try {
    const resp = await axios.patch(ROTAS[1], formData);
    if (resp.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp.data.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Tudo certo atualiza a estrutura principal
    dispatch({
      type: isDelete ? COLABORADORES_DEL_MENUS : COLABORADORES_ADD_MENUS,
      data: isDelete ? obj.matricula : resp.data.data,
    });
  } catch (error) {
    ToastErro(ERRO_NAO_ENVIADO);
    return false;
  }
};

// Action para atualizar senhas
export const colaboradoresUpdSenha = (obj) => async (dispatch) => {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  try {
    dispatch(setAguardar(true));
    const resp = await axios.post(ROTAS[2], formData);
    if (resp.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp.data.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Exibe mensagem de sucesso sobre atualização de senha
    toast.dark(resp.data.sucesso, {
      type: "success",
    });
  } catch (error) {
    ToastErro(ERRO_NAO_ENVIADO);
    return false;
  } finally {
    dispatch(setAguardar(false));
  }
};
// Action para cadastrar usuario
export const colaboradoresAddUsuario = (obj) => async (dispatch) => {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  try {
    dispatch(setAguardar(true));
    const resp = await axios.post(ROTAS[5], formData);
    if (resp.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp.data.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Mensagem dizendo que usuario foi cadastrados com sucesso
    toast.dark(resp.data.sucesso, {
      type: "success",
    });

    // Chamada para obter a listagem dos menus
    const resp2 = await axios.get(ROTAS[1]);
    if (resp2.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp2.data.erro) {
      ToastErro(resp2.data.erro);
      return false;
    }
    // Tudo certo atualiza a estrutura principal
    dispatch({
      type: COLABORADORES_MENU,
      data: resp2.data,
    });

    //
    dispatch({
      type: COLABORADORES_ADD_COLABORADOR,
      data: resp.data.data,
    });

    dispatch(colaboradoresFecharDrawer());
  } catch (error) {
    ToastErro(ERRO_NAO_ENVIADO);
    return false;
  } finally {
    dispatch(setAguardar(false));
  }
};

// Action para cadastrar usuario de forma manual
export const colaboradoresAddUsuarioManual = (obj) => async (dispatch) => {
  const formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  try {
    dispatch(setAguardar(true));
    const resp = await axios.post(ROTAS[6], formData);
    if (resp.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp.data.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Mensagem dizendo que usuario foi cadastrados com sucesso
    toast.dark(resp.data.sucesso, {
      type: "success",
    });

    // Chamada para obter a listagem dos menus
    const resp2 = await axios.get(ROTAS[1]);
    if (resp2.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp2.data.erro) {
      ToastErro(resp2.data.erro);
      return false;
    }
    // Tudo certo atualiza a estrutura principal
    dispatch({
      type: COLABORADORES_MENU,
      data: resp2.data,
    });

    //
    dispatch({
      type: COLABORADORES_ADD_COLABORADOR,
      data: resp.data.data,
    });

    dispatch(colaboradoresFecharDrawer());
  } catch (error) {
    ToastErro(ERRO_NAO_ENVIADO);
    return false;
  } finally {
    dispatch(setAguardar(false));
  }
};

// Action para atualizar sua situacao/gestao ou arvore
export const colaboradoresUpdGestorArvoreSituacao =
  (obj, updateGestor) => async (dispatch) => {
    //
    let formData = new FormData();
    formData.append("dados", JSON.stringify(obj));
    // gestor atualiza para put, arvore é patch
    const fn = updateGestor ? axios.put : axios.patch;
    //

    try {
      const resp = await fn(ROTAS[5], formData);
      if (resp.status !== 200) {
        ToastErro(ERRO_STATUS_NAO_200);
        return false;
      }
      // Veja se tem alguma mensagem de erro
      if (resp.data.erro) {
        ToastErro(resp.data.erro);
        return false;
      }
      if (!updateGestor) {
        toast.dark(resp.data.sucesso, {
          type: "success",
        });
      }
      // Tudo certo atualiza a estrutura principal
      dispatch({
        type: updateGestor
          ? COLABORADORES_UPD_GESTOR_SITUACAO
          : COLABORADORES_UPD_ARVORE,
        data: resp.data.data,
      });
    } catch (error) {
      ToastErro(ERRO_NAO_ENVIADO);
      return false;
    }
  };

// Action para excluir um usuario
export const colaboradoresDelUsuario = (obj) => async (dispatch) => {
  //
  let formData = new FormData();
  formData.append("dados", JSON.stringify(obj));

  try {
    dispatch(setAguardar(true));
    const resp = await axios.delete(ROTAS[5], { data: formData });
    if (resp.status !== 200) {
      ToastErro(ERRO_STATUS_NAO_200);
      return false;
    }
    // Veja se tem alguma mensagem de erro
    if (resp.data.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Mensagem dizendo que usuario foi cadastrados com sucesso
    toast.dark(resp.data.sucesso, {
      type: "success",
    });

    // Tudo certo atualiza a estrutura principal
    dispatch({
      type: COLABORADORES_DEL_COLABORADOR,
      data: obj,
    });
  } catch (error) {
    ToastErro(ERRO_NAO_ENVIADO);
    return false;
  } finally {
    dispatch(setAguardar(false));
  }
};
// Action para acionar drawer
export const colaboradoresSetDrawer = (obj) => ({
  type: COLABORADORES_SET_DRAWER,
  data: obj,
});
// Action para fechar o drawer
export const colaboradoresFecharDrawer = () => ({
  type: COLABORADORES_FECHAR_DRAWER,
});
