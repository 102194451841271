import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CabeModal from './cabe-modal';
// import '../css/Modal.css';
import styled, {css } from 'styled-components';
// Importar o framer-motion para auxiliar nos efeitos
import {motion} from 'framer-motion';
import { Button } from '@mui/material';
import $ from 'jquery';

/**
 * Este componente tem como objetivo exibir um modal com algum conteúdo que 
 * o desenvolvedor informe. É obrigatório passar uma função para fechar o modal.
 */

 const variantsPai = {
     hidden: {
         opacity: 0
     },
     visible: {
         opacity: 1,
         transition: {
             duration: .5,
             when: "beforeChildren",
         }
     },
     exit: {
         opacity: 0,
         transition: {
             delay: .5,
             when: "afterChildren",
         }
     }
 }

 const variantsFilho = {
     hidden: {
        y: '-100vh',
     },
     visible: {
         y: '0vh',
         transition: {
             type: 'spring',
             stiffness: 80
         }
     },
     exit: {
         y: '-100vh',
         transition: {
             duration: .2
         }
     }
 }

const WrapperPai = styled(motion.div)`
    z-index: 1001;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
`;

const WrapperModal = styled(motion.div)` 
    z-index: 1000;
    width: 50%;
    top: 1%;
    left: 25%;
    background: white;
    position: absolute;
    box-shadow: 0 2px 8px black;
    padding: 10px;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    ${props=> props.css && css`${props.css}`};

    @media(max-width: 768px){
        width: 98%;
        left: 1%;
    }
`;
// Wrapper para o modal do corpo
const WrapperModalCorpo = styled.div`
  margin: 10px 0;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  height: 400px;
  overflow-y: auto;
`;
// Wrapper para o rodape do modal
const WrapperModalRodape = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
`;

class Modal extends React.Component {

    constructor(){
        super();
        this._fnFechar = this.fnFechar.bind(this);
    }
        
    render(){
        const {
            style, styleCorpo, styleTitulo, styleRodape,
            className, modalGrande, modalPequeno,
            modalFull, cabeModal
        } = this.props;

        let classNameT = classNames('Modal', className);
        // Se o modalFull esta ativo ele tem precedencia nas outras classes
        let css = `
            
        `;
        if(modalFull){
            // classNameT = classNames(classNameT, 'modal-full');
            css = `
                top: 0.5%;
                left: 1%;
                width: 98%;
                height: 90%;
            `;
        } else {
            css = (modalPequeno && !modalGrande) ? `width: 40%; left: 30%;` : (modalGrande && !modalPequeno) ? `width: 90%; left: 5%;` : ``;
        }

        return (
            <WrapperPai
                variants={variantsPai}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="Modal-geral"
            >
                <WrapperModal
                    initial="hidden"
                    animate="visible"
                    exit="exit" css={css}
                    variants={variantsFilho}
                    style={style} className={classNameT}
                >
                    <WrapperModalRodape className="modal-rodape">
                        {this.props.onFechar && (
                            <i title="FECHAR MODAL" style={{fontSize: '16px', position: 'absolute', top: 0, right: 0}}
                                className='material-icons icone-redondo' 
                                onClick={this.props.onFechar}>close</i>
                            )
                        }
                    </WrapperModalRodape>
                    {cabeModal ? <CabeModal /> : (
                        <div style={styleTitulo} className="modal-cabe">
                            {this.props.titulo}
                        </div>
                    )}

                    <WrapperModalCorpo style={styleCorpo} className="modal-corpo">
                        {this.props.children}
                    </WrapperModalCorpo>
                    
                    <WrapperModalRodape style={styleRodape} className='modal-rodape'>
                        
                        {this.props.onFechar && (
                            <Button variant='outlined' color='primary' 
                                onClick={this.props.onFechar}>FECHAR</Button>
                            )
                        }
                    </WrapperModalRodape>

                </WrapperModal>
            </WrapperPai>
        )
    }

    fnFechar(e){
        if(e.keyCode === 27) this.props.onFechar();
    }
    componentDidMount(){
        // Fazer a Janela dar um Scroll para o topo
        $(document).scrollTop(0);

        // Alterando o tamanho inicial de exibição do modal
       const tamanho = document.querySelector('.Modal').clientHeight + 56;
       document.querySelector('.Modal-geral').style.height = tamanho+'px';

       // Ligar evento de fechar modal com esc
       // Adicionar evento da tecla esc para fechar modal
       window.addEventListener('keyup', this._fnFechar);
    }

    componentWillUnmount(){
        // Retira o evento keyUp que foi incluso para atender o modal
        // Retirar o evento
        window.removeEventListener('keyup', this._fnFechar);
    }
}

Modal.defaultProps ={
    className: '',
    cabeModal: false,
    modalPequeno: false,
    modalGrande: false,
    titulo: 'MODAL',
    style: {},
    styleCorpo: {},
    styleTitulo: {},
    styleRodape: {},
}

Modal.propTypes = {
    /** Função de callback para fechar o modal */
    onFechar: PropTypes.func.isRequired,
    /** Um booleano que exibe o modal no tamanho máximo */
    modalFull: PropTypes.bool,
    /** Classes informadas por strings */
    className: PropTypes.string,
    /** Booleano que determina se o modal deve ser pequeno */
    modalPequeno: PropTypes.bool,
    /** Booleano que determina se o modal deve ser grande */
    modalGrande: PropTypes.bool,
    /** Um texto para ser exibido no titulo do modal */
    titulo: PropTypes.any,
    /** Um estilo para ser aplicado na moldura do modal */
    style: PropTypes.object,
    /** Um estilo para ser aplicado na moldura do cabecalho do modal */
    styleTitulo: PropTypes.object,
    /** Um estilo para ser aplicado no corpo do modal */
    styleCorpo: PropTypes.object,
    /** Um estilo para ser aplicado ao rodape do modal */
    styleRodape: PropTypes.object,
    /** Um boleano que determina a exibição do cabeModal em substituição a props titulo */
    cabeModal: PropTypes.bool,
}

export default Modal;