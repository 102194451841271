import { useSelector } from "react-redux";
import { useToggle } from "react-use";
import { selectTamanhoCampos } from "./manutencao-components-selectors";
import { Container } from "@mui/material";
import { H6 } from "../../../components";
import { EntradaForm } from "../../../components";
import * as yup from "yup";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

// Formulario de insercao de um novo componente
const ManutencaoComponentesFormAddComponente = () => {
  const { t } = useTranslation();
  const [aguardar] = useToggle();
  //
  const tamanhoCampos = useSelector(selectTamanhoCampos);

  const schema = [
    {
      label: t("manutencao_componentes.labelCode"),
      name: "codigo",
      type: "text",
      placeholder: t("manutencao_componentes.placeholderCode"),
    },
    {
      label: t("manutencao_componentes.labelDescriptionPortuguese"),
      name: "desc_portugues",
      type: "text",
      placeholder: t("manutencao_componentes.placeholderDescriptionPortuguese"),
    },
    {
      label: t("manutencao_componentes.labelDescriptionEnglish"),
      name: "desc_ingles",
      type: "text",
      placeholder: t("manutencao_componentes.placeholderDescriptionEnglish"),
    },
    {
      label: t("manutencao_componentes.labelDescriptionItalian"),
      name: "desc_italiano",
      type: "text",
      placeholder: t("manutencao_componentes.placeholderDescriptionItalian"),
    },
    {
      label: t("manutencao_componentes.labelComponentMath"),
      name: "matematica_componente",
      type: "text",
      placeholder: t("manutencao_componentes.placeholderComponentMath"),
    },
    {
      label: t("manutencao_componentes.labelSupplier"),
      type: "text",
      name: "fornecedor",
      placeholder: t("manutencao_componentes.placeholderSupplier"),
    },
    {
      label: t("manutencao_componentes.labelType"),
      type: "text",
      name: "tipo",
      placeholder: t("manutencao_componentes.placeholderType"),
    },
    {
      label: t("manutencao_componentes.labelMass"),
      type: "text",
      name: "massa",
      placeholder: t("manutencao_componentes.placeholderMass"),
    },
    {
      label: t("manutencao_componentes.labelMaterialStandard"),
      type: "text",
      name: "norma_material",
      placeholder: t("manutencao_componentes.placeholderMaterialStandard"),
    },
    {
      label: t("manutencao_componentes.labelWeldingAreaSpecification"),
      type: "text",
      name: "especificacao_areame_solda",
      placeholder: t(
        "manutencao_componentes.placeholderWeldingAreaSpecification"
      ),
    },
    {
      label: t("manutencao_componentes.labelUnitMeasure"),
      type: "text",
      name: "unidade_medida",
      placeholder: t("manutencao_componentes.placeholderUnitMeasure"),
    },
    {
      label: t("manutencao_componentes.labelThreadProtectiveApplication"),
      type: "text",
      name: "apliq_protetivo_rosca",
      placeholder: t(
        "manutencao_componentes.placeholderThreadProtectiveApplication"
      ),
    },
    {
      label: t("manutencao_componentes.labelComponentImage"),
      type: "file",
      name: "arquivo",
      placeholder: t("manutencao_componentes.placeholderComponentImage"),
    },
  ];
  //
  const schemaMessageError = {
    codigo: `${t("manutencao_componentes.expectMax")} ${
      tamanhoCampos.codigo
    } ${t("manutencao_componentes.characters")}`,
    desc_portugues: `${t("manutencao_componentes.expectMax")} ${
      tamanhoCampos.descricao_portugues
    } ${t("manutencao_componentes.characters")}`,
    desc_ingles: `${t("manutencao_componentes.expectMax")} ${
      tamanhoCampos.descricao_ingles
    } ${t("manutencao_componentes.characters")}`,
    desc_italiano: `${t("manutencao_componentes.expectMax")} ${
      tamanhoCampos.descricao_italiano
    } ${t("manutencao_componentes.characters")}`,
    matematica_componente: `${t("manutencao_componentes.expectMax")} ${
      tamanhoCampos.matematica_componente
    } ${t("manutencao_componentes.characters")}`,
    fornecedor: `${t("manutencao_componentes.expectMax")} ${
      tamanhoCampos.fornecedor
    } ${t("manutencao_componentes.characters")}`,
    tipo: `${t("manutencao_componentes.expectMax")} ${tamanhoCampos.tipo} ${t(
      "manutencao_componentes.characters"
    )}`,
    norma_material: `${t("manutencao_componentes.expectMax")} ${
      tamanhoCampos.norma_material
    } ${t("manutencao_componentes.characters")}`,
    especificacao_areame_solda: `${t("manutencao_componentes.expectMax")} ${
      tamanhoCampos.especificacao_areame_solda
    } ${t("manutencao_componentes.characters")}`,
    unidade_medida: `${t("manutencao_componentes.expectMax")} ${
      tamanhoCampos.unidade_medida
    } ${t("manutencao_componentes.characters")}`,
    apliq_protetivo_rosca: `${t("manutencao_componentes.expectMax")} ${
      tamanhoCampos.apliq_protetivo_rosca
    } ${t("manutencao_componentes.characters")}`,
  };
  //
  const schemaValidator = yup.object().shape({
    codigo: yup.string().max(tamanhoCampos.codigo).required(),
    desc_portugues: yup
      .string()
      .max(tamanhoCampos.descricao_portugues)
      .required(),
    desc_ingles: yup.string().max(tamanhoCampos.descricao_ingles).required(),
    desc_italiano: yup
      .string()
      .max(tamanhoCampos.descricao_italiano)
      .required(),
    matematica_componente: yup
      .string()
      .max(tamanhoCampos.matematica_componente)
      .required(),
    fornecedor: yup.string().max(tamanhoCampos.fornecedor).required(),
    tipo: yup.string().max(tamanhoCampos.tipo).required(),
    //massa: yup.string().max(tamanhoCampos.massa).required(),
    norma_material: yup.string().max(tamanhoCampos.norma_material).required(),
    especificacao_areame_solda: yup
      .string()
      .max(tamanhoCampos.especificacao_areame_solda)
      .required(),
    unidade_medida: yup.string().max(tamanhoCampos.unidade_medida).required(),
    apliq_protetivo_rosca: yup
      .string()
      .max(tamanhoCampos.apliq_protetivo_rosca)
      .required(),
  });
  //
  const onSubmit = useCallback((val) => {}, []);
  return (
    <Container maxWidth="md">
      <H6>{t("manutencao_componentes.titlePageFormAdd")}</H6>
      <EntradaForm
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
        wait={aguardar}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

export default ManutencaoComponentesFormAddComponente;
