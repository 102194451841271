import React, { useState, memo, useCallback, useEffect } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Icone from "./icone";
import { setIdxMenu } from "../redux/actions/menu-actions";

const selectMenus = (state) => state.usuario?.menu;
const selectIdxMenu = (state) => state?.idxMenu;

const WrapperBarraInferior = ({ rotaMenus }) => {
  const MENUS = useSelector(selectMenus) || [];
  //
  // Itens do menu
  const ITENS_MENU = [];
  // Conta a quantidade de menus disponiveis, se ultrapassar 5 o quarto será um icone de menu
  let contador = 1;
  const ITENS_EXCEDENTES = [];

  Object.keys(MENUS).forEach((key) => {
    MENUS[key].forEach((arr) => {
      if (contador < 5) {
        ITENS_MENU.push({
          icone: <Icone icone={arr[2]} />,
          texto: arr[1].split(" ")[0],
          rota: arr[0],
          idx: contador - 1,
        });
      } else if (contador === 5) {
        // Icone de menu pois o excesso começa agora
        ITENS_MENU.push({
          icone: <MenuIcon />,
          texto: "Menu",
        });
        // O item excedente incluso aqui
        ITENS_EXCEDENTES.push(arr);
      } else {
        // O contador ja excedeu o limite itens excedentes aqui
        ITENS_EXCEDENTES.push(arr);
      }
      // Atualizando o contador
      contador++;
    });
  });

  return (
    ITENS_MENU &&
    ITENS_MENU.length > 0 && (
      <BarraInferior
        itensMenus={ITENS_MENU}
        itensExcedentes={ITENS_EXCEDENTES}
        rotaMenus={rotaMenus}
      />
    )
  );
};

const BarraInferior = ({ rotaMenus, itensMenus, itensExcedentes }) => {
  // Para saltar entre as opcoes
  const history = useHistory();
  const dispatch = useDispatch();
  const idxMenu = useSelector(selectIdxMenu);
  //
  const [valorSelecionado, setValorSelecionado] = useState(idxMenu);
  //
  const onSelecionarMenu = useCallback(
    (evt, valor) => {
      if (valor < 4) {
        dispatch(setIdxMenu(valor));
        setValorSelecionado((val) => (val === valor ? null : valor));
        history.replace(itensMenus[valor].rota);
      } else {
        history.push(rotaMenus, itensExcedentes);
      }
    },
    [
      dispatch,
      setValorSelecionado,
      rotaMenus,
      itensExcedentes,
      itensMenus,
      history,
    ]
  );
  //
  // Ouvir alteracoes na navegacao para setar o menu selecionado corretamente
  useEffect(() => {
    const unlisten = history.listen(() => {
      const { location } = history;
      const item = itensMenus.filter((ele) => ele.rota === location.pathname);
      if (item.length > 0) {
        setValorSelecionado(item[0].idx);
      }
    });
    return unlisten;
  }, [itensMenus, history, setValorSelecionado]);

  return (
    <Paper
      sx={{
        zIndex: 10,
        width: "100%",
        position: "fixed",
        bottom: 1,
        left: 0,
        right: 0,
      }}
      elevation={3}
      square
    >
      <BottomNavigation
        showLabels
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        value={valorSelecionado}
        onChange={onSelecionarMenu}
      >
        {itensMenus.map((ele, idx) => (
          <BottomNavigationAction
            key={idx}
            label={ele.texto}
            icon={ele.icone}
            sx={{ whiteSpace: "nowrap" }}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default memo(WrapperBarraInferior);
