import axios from "axios";
import { ToastErro } from "./utils";

export async function bucketSend(files, nameField = "arquivo") {
  const formData = new FormData();

  const filesList = [];

  if (files && files.length > 0) {
    [...files].forEach((ele) => {
      formData.append(nameField, ele);
    });

    try {
      const resp = await axios.post("/bucket", formData);
      filesList.concat([...resp.data[nameField]]);
    } catch (e) {
      ToastErro(e);
    }
  }
  return filesList;
}
