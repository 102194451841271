import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectMenuXMenuColaborador } from "./colaboradores-select";
import {
  colaboradoresUpdDelMenus,
  colaboradoresUpdDelMenu,
} from "./colaboradores-actions";
import {
  Checkbox,
  Container,
  FormControlLabel,
  List,
  ListItem,
  Paper,
} from "@mui/material";
import { Caption, H6, InputBusca } from "../../../components";
import _ from "lodash";

//
const sxMinMaxHeight = {
  minHeight: "50vh",
  maxHeight: "80vh",
  overflow: "auto",
};

// Renderiza componente que exibe os menus (e permite atribui-los)
const RenderVerMenu = () => {
  const { t } = useTranslation();
  const [filtroMenu, setFiltroMenu] = useState("");
  const dispatch = useDispatch();
  const { menusUsuario, menusDisponiveis, matricula } = useSelector(
    selectMenuXMenuColaborador
  );
  //
  const fnOnClick = useCallback(
    (id_menu, isChecked) => {
      dispatch(
        colaboradoresUpdDelMenu(
          {
            matricula,
            id_menu: parseInt(id_menu),
          },
          isChecked
        )
      );
    },
    [dispatch, matricula]
  );
  //
  const isTodosAtivos = menusDisponiveis.length === menusUsuario.length;
  // Funcao de callback para ativar/desativar todos os menus
  const fnAtivaDesativaTodos = useCallback(() => {
    // Se todos estiverem ativos eles seram desativados
    const listaMenus = menusDisponiveis.map((ele) => ele.id_menu);

    dispatch(
      colaboradoresUpdDelMenus(
        {
          matricula,
          menus: listaMenus,
          excluir: isTodosAtivos,
        },
        isTodosAtivos
      )
    );
  }, [menusDisponiveis, matricula, dispatch, isTodosAtivos]);
  //
  return (
    <Container sx={sxMinMaxHeight} maxWidth="md">
      <H6>{t("colaboradores.menuOfAccessTitle")}</H6>
      <Caption>{t("colaboradores.menuOfAccessDescription")}</Caption>
      <InputBusca
        fullWidth
        sx={{ mt: 1 }}
        desativarPesquisaLenta
        filtro={filtroMenu}
        setFiltro={setFiltroMenu}
        placeholder={t("colaboradores.placeholderFilterColab")}
      />

      {filtroMenu?.length < 1 && (
        <Paper
          sx={{
            mt: 1,
            zIndex: 10,
            position: "sticky",
            top: 0,
            //pl: 0.5,
            //ml: 0.5,
            width: "100%",
          }}
          elevation={4}
        >
          <ListItem sx={{ pl: 1 }}>
            <FormControlLabel
              onChange={() => fnAtivaDesativaTodos()}
              label={t("colaboradores.labelActiveInactiveAll")}
              control={<Checkbox checked={isTodosAtivos} />}
            />
          </ListItem>
        </Paper>
      )}
      <List>
        {_.filter(
          menusDisponiveis,
          (val) => _.toLower(val.nome).search(_.toLower(filtroMenu)) !== -1
        )?.map((ele) => {
          const isChecked = menusUsuario.includes(ele.id_menu);

          return (
            <RenderVerMenuItens
              key={ele.id_menu}
              onClick={() => fnOnClick(ele.id_menu, isChecked)}
              nome={ele.nome}
              isChecked={isChecked}
            />
          );
        })}
      </List>
    </Container>
  );
};

//
const RenderVerMenuItens = memo(({ isChecked, onClick, nome }) => (
  <ListItem sx={{ pl: 1 }} disablePadding divider>
    <FormControlLabel
      onChange={onClick}
      label={nome}
      control={<Checkbox checked={isChecked} />}
    />
  </ListItem>
));

export default RenderVerMenu;
