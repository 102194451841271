import {
  RNCINCP_ADD,
  RNCINCP_DADOS,
  RNCINCP_FECHAR_MODAL,
  RNCINCP_LIMPAR_DADOS,
  RNCINCP_SET_MODAL,
  RNCINCP_PAGINA,
  RNCINCP_SUBIDA_LINHA,
  RNCINCP_QUALIDADE,
  RNCINCP_CLEAR_FILTER,
  RNCINCP_SET_FILTER,
  RNCINCP_SET_PAGE_FILTER,
  RNCINCP_CANCEL_STATUS,
} from "./rnci-ncp-actions";

export default function rnciNcpReducer(state = null, action) {
  switch (action.type) {
    case RNCINCP_DADOS: // Recuperando dados iniciais
      return {
        ...state,
        ...action.data,
      };
    case RNCINCP_FECHAR_MODAL: // Fechando modal
      return {
        ...state,
        modal: null,
      };
    case RNCINCP_LIMPAR_DADOS: /// Limpando dados da aplicacao
      return null;
    case RNCINCP_SET_MODAL: // Definindo modal
      return {
        ...state,
        modal: action.data,
      };
    case RNCINCP_PAGINA: // Definindo pagina
      return {
        ...state,
        pagina_atual: action.data.idPagina,
        dados: action.data.dados,
      };
    case RNCINCP_ADD: // Adicionando um novo rnci
      return {
        ...state,
        dados: [action.data, ...state.dados],
      };
    case RNCINCP_SUBIDA_LINHA: // Mudança da subida de linha
    case RNCINCP_QUALIDADE: // Mudança para qualidade
      return {
        ...state,
        dados: state.dados.map((ele) => {
          if (ele.id === action.data.id) {
            return action.data;
          }
          return ele;
        }),
      };
    case RNCINCP_CANCEL_STATUS: // Cancelamento de status
      const stateActualy = state.filtroData ? state.filtroData : state.dados;
      const nameField = state.filtroData ? "filtroData" : "dados";
      if (!action.data?.data) {
        return {
          ...state,
          [nameField]: stateActualy.filter((ele) => {
            return ele.id !== action.data.id;
          }),
        };
      }

      return {
        ...state,
        [nameField]: stateActualy.map((ele) => {
          if (ele.id === action.data.data.id) {
            return action.data.data;
          }
          return ele;
        }),
      };

    case RNCINCP_SET_FILTER: // Aplicação de filtro
      let totalOfPages = parseInt(action.data.data.length / 10);
      let rest = parseInt(action.data.data.length % 10);
      if (rest > 0) totalOfPages += 1;

      return {
        ...state,
        pagina_atual_filtro: 1,
        total_pagina_filtro: totalOfPages,
        filtroData: action.data.data,
        filtro: action.data.filter,
        modal: null,
      };
    case RNCINCP_CLEAR_FILTER: // Limpando o filtro
      return {
        ...state,
        pagina_atual_filtro: null,
        total_pagina_filtro: null,
        filtroData: null,
        filtro: null,
      };
    case RNCINCP_SET_PAGE_FILTER: // Setando a pagian do filtro
      return {
        ...state,
        pagina_atual_filtro: action.data,
      };
    default:
      return state;
  }
}
