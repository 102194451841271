import { useTranslation } from "react-i18next";
import FormAddColaboradorAutomatico from "./form-add-colab-auto";
import FormAddColaboradorManual from "./form-add-colab-manual";
import { Tab } from "../../../components";

// Renderiza formulario de insercao de colaborador
const FormAddColaborador = () => {
  const { t } = useTranslation();
  const header = [
    t("colaboradores.addColabTab1"),
    t("colaboradores.addColabTab2"),
  ];
  const body = [<FormAddColaboradorAutomatico />, <FormAddColaboradorManual />];

  return <Tab cabe={header} corpo={body} />;
};

export default FormAddColaborador;
