import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Stack,
  useTheme,
  Button,
  Paper,
  CircularProgress,
  Grid,
  Divider,
  Chip,
  Container,
  Typography,
} from "@mui/material";
import { withStyles } from "@material-ui/core";
import {
  Box,
  Hidden,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { parseISO, format } from "date-fns";
import { useFetch } from "../../../hooks";
import Utils from "../../../utils/utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// Icones
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import DownloadIcon from "@material-ui/icons/CloudDownload";

import { H5 } from "../../../components";
import BackgroundRouter from "../../background-router";
import { AtualizadorCache } from "../../../extra-components";
import { useTranslation } from "react-i18next";
import { getMonths, obterAnos } from "./contra-cheque-utils";
import { selectIsRH, selectUsuario } from "./contra-cheque-seletores";
import ViewContraCheque from "./contra-cheque-view";

// CUstomizacao de paper
const PaperCustom = withStyles(() => ({
  root: {
    padding: "8px",
    display: "flex",
    minHeight: "50vh",
    marginTop: "16px",
  },
}))(Paper);
// Componente criado para envolver o filtro de anos e meses
const WrapperFiltro = styled.div`
  max-width: 100vw;
  display: flex;
  overflow: auto;
`;

const ContraCheque = () => {
  const { t } = useTranslation();
  // Pegando a data de admissao do funcionario
  const { usuario } = useSelector((state) => state);
  const isRH = useSelector(selectIsRH);

  const MESES = getMonths(t);

  // Pegar o tema
  const {
    breakpoints: { values },
  } = useTheme();
  const isMobile = values["sm"] > window.innerWidth;

  // Se o usario tiver sido recuperado pega a data de emissao dele e cria o array dos anos
  let ANOS = ["2021"];
  if (usuario) {
    const { admissao } = usuario.usuario;
    ANOS = obterAnos(format(parseISO(admissao), "yyyy"));
  }

  const [mesSelecionado, setMesSelecionado] = useState(null); // useState( parseInt(format(new Date(), 'MM') ) - 1);
  const [anoSelecionado, setAnoSelecionado] = useState(
    parseInt(format(new Date(), "yyyy"))
  );
  // fetch para buscar os dados
  const { error, wait, setFetch, data } = useFetch(
    "/contra_cheque",
    "POST",
    "json"
  );
  //
  useEffect(() => {
    toast.dark(error, {
      type: "error",
    });
  }, [error]);

  // Funcoes para atualizar os dados sejam anos ou meses
  const fnSetAno = (ano) => {
    if (ano && mesSelecionado) {
      setFetch({ ano: String(ano), mes: String(mesSelecionado) });
    }
  };
  const fnSetMes = (mes) => {
    if (anoSelecionado && mes) {
      setFetch({ ano: String(anoSelecionado), mes: String(mes) });
    }
  };
  // Copia meses para exibir somente os meses que se encontram dentro do ano informado
  const COPIA_MESES = [
    ...(anoSelecionado === parseInt(format(new Date(), "yyyy"))
      ? MESES.filter(
          (ele) =>
            ele[0] <= parseInt(format(new Date(), "MM")) ||
            12 === parseInt(format(new Date(), "MM"))
        )
      : MESES),
    ["PLR1", "PLR 1° PARC"],
    ["PLR2", "PLR 2° PARC"],
  ];

  return (
    <BackgroundRouter>
      {isRH && <AtualizadorCache titulo={t("contra_cheque.updateCache")} />}
      <Stack sx={{ md: { px: 2 } }}>
        <H5>{t("contra_cheque.titulo")}</H5>
        <Typography variant="body2">{t("contra_cheque.campoAnos")}:</Typography>
        <WrapperFiltro>
          {ANOS.map((ele, idx) => (
            <Chip
              label={ele}
              key={idx}
              size="small"
              color={anoSelecionado === ele ? "primary" : "default"}
              icon={
                anoSelecionado === ele ? (
                  <EventAvailableIcon />
                ) : (
                  <CalendarTodayIcon />
                )
              }
              style={{ padding: "4px", margin: "4px" }}
              onClick={() => {
                setAnoSelecionado(ele);
                fnSetAno(ele);
              }}
            />
          ))}
        </WrapperFiltro>
        <Divider />
        <Typography variant="body2">
          {t("contra_cheque.campoMeses")}:
        </Typography>
        <WrapperFiltro>
          {COPIA_MESES.map((ele, idx) => (
            <Chip
              label={ele[1]}
              key={idx}
              size="small"
              color={mesSelecionado === ele[0] ? "primary" : "default"}
              icon={
                mesSelecionado === ele[0] ? (
                  <EventAvailableIcon />
                ) : (
                  <CalendarTodayIcon />
                )
              }
              style={{ padding: "4px", margin: "4px" }}
              onClick={() => {
                setMesSelecionado(ele[0]);
                fnSetMes(ele[0]);
              }}
            />
          ))}
        </WrapperFiltro>
        <Divider />
        <br />
        {isMobile && wait && (
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {data && !wait && isMobile && (
          <ViewContraChequeMobile
            {...data}
            mes={mesSelecionado}
            ano={anoSelecionado}
          />
        )}
        {!isMobile && (
          <Container maxWidth="md">
            <Hidden smDown>
              {data && !wait && data.valores.length > 0 && (
                <BtnBaixar
                  mesSelecionado={mesSelecionado}
                  anoSelecionado={anoSelecionado}
                  data={data}
                />
              )}
            </Hidden>
            <PaperCustom elevation={3}>
              {data && !wait && !isMobile ? (
                <div>
                  <ViewContraCheque
                    {...data}
                    mes={mesSelecionado}
                    ano={anoSelecionado}
                  />
                </div>
              ) : wait ? (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : null}
            </PaperCustom>
          </Container>
        )}
      </Stack>
    </BackgroundRouter>
  );
};

// Compoente responsavel por renderizar um botao para download do contracheque em pdf
const BtnBaixar = () => {
  const usuario = useSelector(selectUsuario);
  const { t } = useTranslation();

  return (
    <Button
      href={`/static/contra_cheque/${usuario.matricula}_${usuario.planta}.pdf`}
      target="_blank"
      title={t("contra_cheque.btnBaixarTitulo")}
      startIcon={<DownloadIcon />}
      variant="contained"
      size="small"
      color="primary"
    >
      {t("contra_cheque.btnBaixar")}
    </Button>
  );
};

// Componente que renderiza o corpo do contracheque mobile, seja em vencimentos ou descontos
const RenderCorpoContracheque = ({ arr }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography variant="subtitle2">
          {t("contra_cheque.contraChequeCod")}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="subtitle2">
          {t("contra_cheque.contraChequeDescricao")}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle2">
          {t("contra_cheque.contraChequeRef")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2">
          {t("contra_cheque.contraChequeValor")}
        </Typography>
      </Grid>
      {arr.map((ele, idx) => (
        <Grid container key={idx}>
          <Grid item xs={2}>
            <Typography variant="caption">{ele[0]}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="caption">{ele[1]}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">{ele[2]}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">
              {Utils.converter(ele[3].toFixed(2))}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {" "}
            <Divider component="br" />{" "}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
// View para o contracheque no estilo Mobile
const ViewContraChequeMobile = (props) => {
  const { t } = useTranslation();
  const { mes, ano, valores, info_pessoais, bases } = props;

  const MESES = getMonths(t);
  // Chame o tema, veja qual o tema escolhido para controlar o campo liquido
  const {
    palette: { type },
  } = useTheme();
  const corLiquido = type === "dark" ? "inherit" : "primary";

  // Array com as bases de calculo
  const arrBases = Object.keys(bases).map((key) => [key, bases[key]]);

  // Array com os vencimentos
  const arrVencimentos = valores
    .filter((ele) => parseInt(ele[3]) > 0)
    .map((ele) => [ele[0], ele[1], ele[2], ele[3]]);

  // Array com os descontos
  const arrDescontos = valores
    .filter((ele) => parseInt(ele[4]) > 0)
    .map((ele) => [ele[0], ele[1], ele[2], ele[4]]);

  // Funcao reduce para gerar o total dos vencimentos e descontos
  const totVencimentos = arrVencimentos.reduce(
    (atual, arr) => atual + arr[3],
    0
  );
  const totDescontos = arrDescontos.reduce((atual, arr) => atual + arr[3], 0);

  // Strings
  const STRINGS = [
    {
      titulo: t("contra_cheque.contraChequeTitulo"),
      mat: {
        titulo: t("contra_cheque.contraChequeMatricula"),
        texto: info_pessoais.mat,
      },
      nome: {
        titulo: t("contra_cheque.contraChequeNome"),
        texto: info_pessoais.nome,
      },
      funcao: {
        titulo: t("contra_cheque.contraChequeFuncao"),
        texto: info_pessoais.cargo,
      },
      admissao: {
        titulo: t("contra_cheque.contraChequeAdmissao"),
        texto: format(parseISO(info_pessoais.admissao), "dd/MM/yyyy"),
      },
    },
    {
      titulo: t("contra_cheque.contraChequeVencimentos"),
      texto: Utils.converter(totVencimentos.toFixed(2)),
    },
    {
      titulo: t("contra_cheque.contraChequeDescontos"),
      texto: Utils.converter(totDescontos.toFixed(2)),
    },
    {
      titulo: t("contra_cheque.contraChequeLiquido"),
      texto: Utils.converter((totVencimentos - totDescontos).toFixed(2)),
    },
    { titulo: t("contra_cheque.contraChequeBaseDeCalculo") },
  ];

  return (
    <div>
      <Typography align="right">
        <BtnBaixar />
      </Typography>
      <Typography align="center" variant="subtitle1">{`${t(
        "contra_cheque.reciboSalario"
      )} - ${
        String(mes).search("PLR") !== -1
          ? "PLR"
          : MESES.filter((ele) => ele[0] === mes)[0][1]
      }/${ano}`}</Typography>
      {/* INFORMACOES PESSOAIS */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{STRINGS[0].titulo}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {STRINGS[0].mat.titulo}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle2">
                  {STRINGS[0].nome.titulo}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption">
                  {STRINGS[0].mat.texto}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="caption">
                  {STRINGS[0].nome.texto}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Divider component="br" />{" "}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {STRINGS[0].funcao.titulo}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {STRINGS[0].admissao.titulo}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">
                  {STRINGS[0].funcao.texto}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">
                  {STRINGS[0].admissao.texto}
                </Typography>
              </Grid>
            </Grid>
          </>
        </AccordionDetails>
      </Accordion>
      {/* VENCIMENTOS */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={6}>
              <Box color="success.main" clone>
                <Typography variant="h6">{STRINGS[1].titulo}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box color="success.main" clone>
                <Typography variant="h6">{STRINGS[1].texto}</Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <>
            <RenderCorpoContracheque arr={arrVencimentos} />
          </>
        </AccordionDetails>
      </Accordion>
      {/* DESCONTOS */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={6}>
              <Typography color="error" variant="h6">
                {STRINGS[2].titulo}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="error" variant="h6">
                {STRINGS[2].texto}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <RenderCorpoContracheque arr={arrDescontos} />
        </AccordionDetails>
      </Accordion>
      {/* LIQUIDO */}
      <Container>
        <Grid container>
          <Grid item xs={5}>
            <Typography color={corLiquido} variant="h6">
              {STRINGS[3].titulo}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography color={corLiquido} align="right" variant="h6">
              {STRINGS[3].texto}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      {/* BASES DE CALCULO */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{STRINGS[4].titulo}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {arrBases.map((arr, idx) => (
              <Grid container key={idx}>
                <Grid item xs={7}>
                  <Typography variant="subtitle2">{arr[0]}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="caption">
                    {Utils.converter(arr[1].toFixed(2))}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider component="br" />{" "}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <br /> <br />
    </div>
  );
};

ContraCheque.rota = "/contra_cheque";

export default ContraCheque;
