import React, { useCallback, memo, useState, useEffect } from "react";
import _ from "lodash";
import * as yup from "yup";
import {
  Container,
  ListItemButton,
  ListItemText,
  Stack,
  Divider,
  useMediaQuery,
  useTheme,
  Chip,
  Paper,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import {
  Icone,
  InputBusca,
  FabExtend,
  H6,
  ScrollInfinito,
  DrawerExibicao,
  Dialog,
  SideBar,
  Caption,
  Body1,
  BotaoIcone,
  EntradaForm,
  AnimacaoSemDados,
} from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  manutencaoModeloInit,
  manutencaoModeloSetDrawer,
  manutencaoModeloFecharDrawer,
  manutencaoModeloAdd,
  manutencaoModeloUpd,
  manutencaoModeloUpdSituacao,
} from "../../../redux/actions/manutencao-modelo-actions";
import { useToggle } from "react-use";
import {
  selectModelos,
  selectDrawer,
  selectAguardar,
  selectModeloSelecionado,
} from "./manutencao-modelo-seletores";
import { getSituacaoCor, getSituacaoTexto } from "./manutencao-modelo-utils";
import { useTranslation } from "react-i18next";

//
const MODAL = {
  VER_ADD_MODELO: "VER_ADD_MODELO",
  MODELO_EDITAR: "MODELO_EDITAR",
};

function ManutencaoModelo() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dados = useSelector(selectModelos);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  // Fazendo primeiro loading
  useEffect(() => {
    dispatch(manutencaoModeloInit());
  }, [dispatch]);

  return (
    <Stack direction="row">
      <RenderDrawer />
      <SideBar barraIntegrada />
      <Container sx={{ mt: 0.5, mb: isMobile ? 7 : 2 }} maxWidth="lg">
        <H6>{t("manutencao_modelo.titleModel")}</H6>
        <Body1 align="center" component="p">
          {t("manutencao_modelo.subtitleModel")}
        </Body1>
        {dados && <RenderCorpo />}
      </Container>
    </Stack>
  );
}
// Renderiza o corpo
const RenderCorpo = () => {
  const modelos = useSelector(selectModelos);
  const [filtro, setFiltro] = useState(null);
  const [filtroAtivo, setFiltroAtivo] = useToggle(true);
  const [filtroInativo, setFiltroInativo] = useToggle(true);

  let modeloFiltro;
  if (filtro && filtro.length > 0) {
    modeloFiltro = _.filter(
      modelos,
      (val) => _.toUpper(val.nome).search(_.toUpper(filtro)) !== -1
    );
  } else {
    modeloFiltro = modelos;
  }

  if (!filtroAtivo || !filtroInativo) {
    // Filtros de ativo/inativo
    modeloFiltro = _.filter(modeloFiltro, (val) => {
      if (filtroAtivo && val.situacao === "A") {
        return true;
      }
      if (filtroInativo && val.situacao !== "A") {
        return true;
      }
      return false;
    });
  }

  return (
    <Stack spacing={1}>
      <PainelOpcoes
        filtro={filtro}
        setFiltro={setFiltro}
        filtroAtivo={filtroAtivo}
        setAtivo={setFiltroAtivo}
        filtroInativo={filtroInativo}
        setInativo={setFiltroInativo}
      />
      <RenderLista dados={modeloFiltro} />
    </Stack>
  );
};
// Renderiza o drawer
const RenderDrawer = memo(() => {
  const drawer = useSelector(selectDrawer);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const dispatch = useDispatch();
  let corpo;
  const fecharDrawer = useCallback(() => {
    dispatch(manutencaoModeloFecharDrawer());
  }, [dispatch]);

  if (drawer) {
    switch (drawer.tipo) {
      case MODAL.VER_ADD_MODELO: // Para adicionar uma novo modelo
        corpo = <FormAddModelo />;
        break;
      case MODAL.MODELO_EDITAR: // Para editar um modelo
        corpo = <RenderModeloEditavel />;
        break;
      default:
        break;
    }
  }

  return isMobile ? (
    <DrawerExibicao corpo={corpo} fecharDrawer={fecharDrawer} />
  ) : (
    <Dialog comoSlide corpo={corpo} fecharDialogo={fecharDrawer} />
  );
});
// Renderiza o painel de opcoes que e um filtro e um botao flutuante
const PainelOpcoes = ({
  filtro,
  setFiltro,
  filtroAtivo,
  filtroInativo,
  setAtivo,
  setInativo,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itens = [
    {
      texto: t("manutencao_modelo.labelSituationActive"),
      titulo: t("manutencao_modelo.titleSituationActive"),
      ativo: filtroAtivo,
      onClick: setAtivo,
    },
    {
      texto: t("manutencao_modelo.labelSituationInactive"),
      titulo: t("manutencao_modelo.titleSituationInactive"),
      ativo: filtroInativo,
      onClick: setInativo,
    },
  ];

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <InputBusca
        sx={{ flex: 1, ml: 1 }}
        label=""
        desativarPesquisaLenta
        placeholder={t("manutencao_modelo.placeholderModelName")}
        setFiltro={setFiltro}
        filtro={filtro}
      />
      {itens.map((ele, idx) => (
        <Chip
          key={idx}
          onClick={() => ele.onClick()}
          label={ele.texto}
          title={ele.titulo}
          color="secondary"
          icon={ele.ativo && <Icone icone="Check" />}
          variant={ele.ativo ? "filled" : "outlined"}
        />
      ))}
      <FabExtend
        onClick={() =>
          dispatch(manutencaoModeloSetDrawer({ tipo: MODAL.VER_ADD_MODELO }))
        }
      />
    </Stack>
  );
};
// Renderiza a lista
const RenderLista = ({ dados }) => {
  const { t } = useTranslation();
  if (dados?.length === 0) {
    return (
      <Container maxWidth="md">
        <AnimacaoSemDados titulo={t("manutencao_modelo.labelNoDataModel")} />
      </Container>
    );
  }

  return (
    <ScrollInfinito
      tamanho="83vh"
      itens={dados}
      itensPorPagina={20}
      render={(item) => {
        return <RenderModeloItem modelo={item} />;
      }}
    />
  );
};
// Renderiza o modelo do item
const RenderModeloItem = ({ modelo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Paper elevation={2} sx={{ m: 1 }}>
      <ListItemButton
        onClick={() =>
          dispatch(
            manutencaoModeloSetDrawer({
              tipo: MODAL.MODELO_EDITAR,
              dados: modelo.id,
            })
          )
        }
      >
        <ListItemText
          primary={`${t("manutencao_modelo.labelModelName")}: ${modelo.nome}`}
          secondary={
            <Chip
              sx={{ mt: 1 }}
              label={getSituacaoTexto(modelo.situacao, t)}
              color={getSituacaoCor(modelo.situacao)}
            />
          }
        />
      </ListItemButton>
    </Paper>
  );
};
// Renderiza o formulario para adicionar um novo modelo
const FormAddModelo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aguardar = useSelector(selectAguardar);
  //
  const schema = [
    {
      name: "nome",
      type: "text",
      label: t("manutencao_modelo.labelModelName"),
      icone: <Icone icone="CarRental" />,
      placeholder: t("manutencao_modelo.placeholderModelName"),
    },
  ];
  const schemaMessageError = {
    nome: t("manutencao_modelo.errorModelName"),
  };
  const schemaValidator = yup.object().shape({
    nome: yup.string().min(1).required(),
  });
  //
  const fn = (val) => {
    dispatch(
      manutencaoModeloAdd({
        nome: val.nome,
      })
    );
  };

  return (
    <Container maxWidth="md">
      <H6>{t("manutencao_modelo.titleModelAdd")}</H6>
      <Caption component="p">{t("manutencao_modelo.subtitleModelAdd")}</Caption>
      <Divider sx={{ my: 2 }} />
      <EntradaForm
        schema={schema}
        schemaValidator={schemaValidator}
        schemaMessageError={schemaMessageError}
        onSubmit={fn}
        wait={aguardar}
      />
    </Container>
  );
};
// Renderiza a tela para exibir o modelo e situacao onde pode-se edita-lo
const RenderModeloEditavel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [alterarNome, setAlterarNome] = useToggle(null);

  const modeloSelecionado = useSelector(selectModeloSelecionado);
  const { nome, situacao, id } = modeloSelecionado;
  const itens = [
    {
      titulo: t("manutencao_modelo.titleName"),
      secundario: (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {alterarNome ? (
            <Stack direction="row" spacing={0.5} flex={1}>
              <BotaoIcone
                title={t("manutencao_modelo.titleBtnCancel")}
                icone="EditOff"
                onClick={setAlterarNome}
              />
              <RenderFormAlterarNomeModelo
                id={id}
                fnAlterarEdicao={setAlterarNome}
                nome={nome}
              />
            </Stack>
          ) : (
            nome
          )}
          {!alterarNome && (
            <BotaoIcone
              title={t("manutencao_modelo.titleBtnEdit")}
              onClick={setAlterarNome}
              icone="Edit"
            />
          )}
        </Stack>
      ),
    },
  ];

  return (
    <Container maxWidth="lg">
      <H6>
        {t("manutencao_modelo.titleModelEdit")} ({nome})
      </H6>
      <Divider sx={{ my: 2 }} />
      <List disableGutters>
        {itens.map((ele, idx) => (
          <ListItem disablePadding divider key={`area_${idx.toString()}`}>
            <ListItemText primary={ele.titulo} secondary={ele.secundario} />
          </ListItem>
        ))}
      </List>
      <FormControlLabel
        label={getSituacaoTexto(situacao, t)}
        onChange={(e) => {
          dispatch(
            manutencaoModeloUpdSituacao({
              id_modelo: id,
              situacao: e.target.checked ? "A" : "B",
            })
          );
        }}
        control={<Switch checked={situacao === "A"} />}
      />
    </Container>
  );
};
// Este componente exibe  a opcao de salvar a edicao do nome do modelo
const RenderFormAlterarNomeModelo = ({ id, nome, fnAlterarEdicao }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schemaValidator = yup.object().shape({
    nome: yup.string().min(3).required(),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nome: nome,
    },
    resolver: yupResolver(schemaValidator),
  });
  //
  const fn = (val) => {
    dispatch(
      manutencaoModeloUpd({
        id_modelo: id,
        nome: val.nome,
      })
    );
    fnAlterarEdicao();
  };

  return (
    <Stack alignItems="center" flex={1} direction="row">
      <Controller
        //defaultValue={nome}
        name="nome"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            autoFocus
            sx={{ flex: 1 }}
            variant="standard"
            size="small"
            fullWidth
            type="text"
            {...field}
            placeholder={t("manutencao_modelo.placeholderModelName")}
            error={!!errors.nome}
            helperText={!!errors.nome && t("manutencao_modelo.errorModelName")}
          />
        )}
      />
      <BotaoIcone color="primary" onClick={handleSubmit(fn)} icone="Save" />
    </Stack>
  );
};

ManutencaoModelo.rota = "/manutencao_modelo";

export default ManutencaoModelo;
