import {SET_MODAL, FECHAR_MODAL} from '../actions';
//

const modalReducer = (state = null, action)=>{
    switch (action.type) {
        case SET_MODAL: // Objeto para definir o modal
            return state ? {...state, ...action.obj} : action.obj;
        case FECHAR_MODAL: // Objeto usado para limpar o modal
            return null;
        default:
            return state;
    }
}

export default modalReducer;