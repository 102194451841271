/**
 * Este arquivo contém todos os textos estaticos da aplicação.
 * No futuro poderemos utilizar recursos de tradução do App 
 * para outros idiomas. O simples fato de importar o idioma
 * correto fará com que o arquivo trabalhe naquele idioma
 * 
 * import { pt_br } from './strings/index'
 * 
 */
// Portugues
export const pt_br = {
    '/': {
        saudacao: 'Olá',
        textoAlterarSenha: 'Configurações',
        textoSair: 'Sair do App',
    },
    '/ponto_eletronico': {
        titulo: 'Registro de marcações',
        fecharDrawer: 'Fechar',
        btnEnviar: 'Enviar',
        rotuloCampo: 'Periodo',
        mobileCampoDia: 'Dia',
        mobileCampoData: 'Data',
        mobileCampoEntrada: 'Entrada',
        mobileCampoSaida: 'Saída',
        mobileCampoVer: 'Ver',
    },
    '/contra_cheque': {
        titulo: 'Contra-cheque',
        campoAnos: 'Anos',
        campoMeses: 'Meses,',
        contraChequeTitulo : 'Informações Pessoais',
        contraChequeMatricula : 'Matricula',
        contraChequeNome : 'Nome',
        contraChequeFuncao: 'Função',
        contraChequeAdmissao : 'Data de admissão',
        contraChequeVencimentos: 'Vencimentos',
        contraChequeDescontos: 'Descontos',
        contraChequeLiquido:  'Liquido',
        contraChequeBaseDeCalculo: 'Base de calculo',
        contraChequeCod: 'Cód',
        contraChequeDescricao: 'Descrição',
        contraChequeRef: 'Ref',
        contraChequeValor: 'Valor',
        btnBaixar: 'Baixar',
        btnBaixarTitulo: 'Gerar PDF deste Contra-cheque',
        cnpj: 'CNPJ',
        endereco: 'Endereco',
        reciboSalario: 'RECIBO DE PAGAMENTO DE SALÁRIO',
        codigo: 'Código',
        descricao: 'Descrição',
        referencia: 'Referência',
        vencimentos: 'Vencimentos',
        descontos: 'Descontos',
    },
    '/configuracoes': {
        titulo: 'Configurações do usuário',
        tituloForm: 'Alteração de senha',
        tituloCampo1: 'Senha',
        tituloCampo2: 'Confirmar Senha',
        btnAtualizar: 'Atualizar Senha',
        mensagemErro: 'Minimo de 3 caracteres',
    },
    '/ouvidoria': {
        titulo: 'Ouvidoria',
        corpoMobile: {
            tipo: 'Tipo',
            registro: 'Registro',
        },
        drawer: {
            btn: {
                rotulo: 'Fechar',
            }
        },
        btnAdd: {titulo: 'Clique para adicionar uma ouvidoria', rotulo: 'NOVA OUVIDORIA'},
        campos: {
            tema: {
                rotulo: 'Tema',
                placeholder: 'Escolha um tema',
                ajudaErro: 'Campo obrigatório',
            },
            tipo: {
                rotulo: 'Tipo',
                placeholder: 'Escolha algum tipo',
                ajudaErro: 'Campo obrigatório',
            },
            assunto: {
                rotulo: 'Assunto',
                placeholder: 'Digite sobre o que se trata'
            },
            texto: {
                rotulo: 'Descrição',
                placeholder: 'Digite uma descrição'
            },
            anexo: {
                rotulo: 'Arquivo',
                placeholder: 'Inclua algum anexo'
            }
        },
        btnLimparFiltro: {
            rotulo: 'Limpar filtro',
            tutulo: 'Clique para limpar o filtro',
        },
        btnSalvarOuvidoria: 'Salvar',
        opcoesOuvidoria: {
            textoVerOuvidoria: 'Ver Ouvidoria',
            textoInteragirOuvidoria: 'Interagir na ouvidoria',
        },
        detalhesOuvidoria: {
            assunto: 'Assunto',
            tipo: 'Tipo',
            tema: 'Tema',
            situacao: 'Situação',
            descricao: 'Descrição',
            solicitante: 'Solicitante',
            data_criacao: 'Data de criação',
            ultima_interacao: 'Ultima interação',
            tituloAnexo: 'Anexo(s)',
            anexo: 'Anexo',
            interacoesTitulo: 'Interações',
            interacoesBtn: 'VER INTERAÇÕES '
        },
        camposAlteraStatus: {
            situacao: {
                rotulo: 'Situação'
            },
            texto: {
                placeholder: 'Digite sua descrição',
                rotulo: 'Descrição',
            },
            btn: {
                rotulo: 'Alterar Status',
                titulo: 'Clique aqui para alterar o status',
            }
        },
        filtroData: {
            esteMes: {
                rotulo: 'Este mês',
                titulo: 'Determina o filtro dentro deste mês',
            },
            seisMeses: {
                rotulo: 'Ult. 6 meses',
                titulo: 'Determina o filtro nos ultimos seis meses',
            },
            esteAno: {
                rotulo: 'Este ano',
                titulo: 'Determina o filtro dentro deste ano',
            },
            periodo: {
                rotulo: 'Por periodo',
                titulo: 'Determina o filtro por periodo informado',
            }
        },
        filtroPorPeriodo : {
            titulo: 'Filtro das ouvidorias por periodo.',
            descricao: 'Defina uma data de inicio e final para ativar o filtro por periodo.',
            dataDe: 'Data de',
            dataAte: 'Data ate',
            btn: {
                rotulo: 'Pesquisar',
                titulo: 'Clique para pesquisar',
            }
        }
    },
    '/cadastro_projetos': {
        titulo: 'Cadastro de Projetos',
        
    }
    
}
// Espanhol
export const esp = {
    '/': {
        saudacao: 'Olá',
        textoAlterarSenha: 'Configurações',
        textoSair: 'Sair do App',
    },
    '/ponto_eletronico': {
        titulo: 'Registro de marcações',
        fecharDrawer: 'Fechar',
        btnEnviar: 'Enviar',
        rotuloCampo: 'Periodo',
        mobileCampoDia: 'Dia',
        mobileCampoData: 'Data',
        mobileCampoEntrada: 'Entrada',
        mobileCampoSaida: 'Saída',
        mobileCampoVer: 'Ver',
    },
    '/contra_cheque': {
        titulo: 'Contra-cheque',
        campoAnos: 'Anos',
        campoMeses: 'Meses,',
        contraChequeTitulo : 'Informações Pessoais',
        contraChequeMatricula : 'Matricula',
        contraChequeNome : 'Nome',
        contraChequeFuncao: 'Função',
        contraChequeAdmissao : 'Data de admissão',
        contraChequeVencimentos: 'Vencimentos',
        contraChequeDescontos: 'Descontos',
        contraChequeLiquido:  'Liquido',
        contraChequeBaseDeCalculo: 'Base de calculo',
        contraChequeCod: 'Cód',
        contraChequeDescricao: 'Descrição',
        contraChequeRef: 'Ref',
        contraChequeValor: 'Valor',
        btnBaixar: 'Baixar',
        btnBaixarTitulo: 'Gerar PDF deste Contra-cheque',
        cnpj: 'CNPJ',
        endereco: 'Endereco',
        reciboSalario: 'RECIBO DE PAGAMENTO DE SALÁRIO',
        codigo: 'Código',
        descricao: 'Descrição',
        referencia: 'Referência',
        vencimentos: 'Vencimentos',
        descontos: 'Descontos',
    },
    '/atualizar_senha': {
        titulo: 'Configurações do usuário',
        tituloForm: 'Alteração de senha',
        tituloCampo1: 'Senha',
        tituloCampo2: 'Confirmar Senha',
        btnAtualizar: 'Atualizar Senha',
        mensagemErro: 'Minimo de 3 caracteres',
    }
}
