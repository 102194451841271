import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  useTheme,
  Stack,
  Modal,
  Container,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
//
import { useSelector, useDispatch } from "react-redux";

//
import {
  galeriaDeFotosGetAlbuns,
  galeriaDeFotosLimparListaAlbum,
  galeriaDeFotosFecharDrawer,
} from "./galeria-de-fotos-actions";
import { registrarAcesso } from "../../../utils/utils";
import { SideBar } from "../../../components";
import VerFoto from "./galeria-de-fotos-ver-foto";
import ListaDeAlbuns from "./galeria-de-fotos-lista-de-albuns";
import ListaDeFotos from "./galeria-de-fotos-lista-de-fotos";
import PainelSuperior from "./galeria-de-fotos-painel-superior";
import { DrawerDialog } from "../../../extra-components";
import GaleriaDeFotosModal from "./galeria-de-fotos-modal";

function GaleriaDeFotos() {
  const [verModal, setVerModal] = useState(null);
  // Seta o caminho atual que o usuario esta posicionado na galeria
  const [areaAtual, setAreaAtual] = useState({
    id: 0,
    nome: "home",
  });
  const dispatch = useDispatch();

  const galeriaDeFotos = useSelector((state) => state?.galeriaDeFotos);
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const isXl = useMediaQuery(useTheme()?.breakpoints?.up("xl"));
  const modal = galeriaDeFotos?.drawer;

  // Verificar se o usuario pode ser editor de feed
  const usuario = useSelector((state) => state.usuario);
  const planta = usuario?.usuario?.planta;
  const isEditorFeed = usuario?.variaveis?.includes("MANUTENCAO_FEED");
  // Faz um filtro sobre os albuns para ver qual pode ser visivel
  const copiaGaleriaDeFotos = galeriaDeFotos
    ? galeriaDeFotos?.album.filter((ele) => ele.planta_visivel.includes(planta))
    : [];

  // Obtendo os dados iniciais
  useEffect(() => {
    dispatch(galeriaDeFotosGetAlbuns(1));
    //
    return () => dispatch(galeriaDeFotosLimparListaAlbum());
  }, [dispatch]);
  // Registrando acesso
  useEffect(() => {
    // Registrando acesso a pagina
    registrarAcesso(window.location.pathname);
  }, []);

  //
  let fnFecharModal = () => dispatch(galeriaDeFotosFecharDrawer());

  return (
    <Stack direction="row">
      <SideBar barraIntegrada />
      <Container
        sx={{ mt: 1 }}
        disableGutters={isMobile}
        maxWidth={isXl ? "xl" : "lg"}
      >
        <Modal
          keepMounted
          sx={{ backgroundColor: "rgba(0,0,0,.9)" }}
          open={Boolean(verModal)}
          onClose={() => setVerModal(null)}
        >
          <VerFoto {...verModal} callBack={setVerModal} />
        </Modal>
        <ToastContainer position="bottom-center" />
        <PainelSuperior
          isEditorFeed={isEditorFeed}
          areaAtual={areaAtual}
          setAreaAtual={setAreaAtual}
        />
        {modal && (
          <DrawerDialog
            fnGetCorpo={() => <GaleriaDeFotosModal modal={modal} />}
            fecharModal={fnFecharModal}
          />
        )}
        {areaAtual?.nome === "home" ? (
          <ListaDeAlbuns
            galeriaDeFotos={copiaGaleriaDeFotos}
            isEditorFeed={isEditorFeed}
            setAreaAtual={setAreaAtual}
          />
        ) : (
          <ListaDeFotos
            id_album={areaAtual?.id}
            nome={areaAtual?.nome}
            fnVerFoto={setVerModal}
            isEditor={isEditorFeed}
          />
        )}
        <br /> <br /> <br /> <br />
      </Container>
    </Stack>
  );
}

GaleriaDeFotos.rota = "/galeria_de_fotos";

export default GaleriaDeFotos;
