import React from "react";
import { Container } from "@mui/material";
import BackgroundRouter from "../../background-router";

function Manutencao() {
  const _html =
    '<iframe title="Report Section" style="width:100%; height:calc(100vh - 80px)" src="https://app.powerbi.com/view?r=eyJrIjoiMWU1ZWQ3MjAtMzk1NC00NjJjLTk2MzMtNGQ5YWMxZjZkZmM2IiwidCI6Ijc0YmNhYWI3LTE1NDYtNDY4Ny1iYTU0LTU1MWYwMTU3YjdiYiJ9" frameborder="0" allowFullScreen="true"></iframe>';
  return (
    <BackgroundRouter>
      <Container sx={{ mt: 1 }} maxWidth={false}>
        <div dangerouslySetInnerHTML={{ __html: _html }} />
      </Container>
    </BackgroundRouter>
  );
}

Manutencao.rota = "/manutencao";

export default Manutencao;
