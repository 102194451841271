import { Button, Container, Divider, Stack } from "@mui/material";
import { useToggle } from "react-use";
import { Body1, Body2, H6, Icone } from "../../../components";
import { format, parseISO } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import QuestionarioCandidato from "./oportunidades-questionario-candidato";
import { useTranslation } from "react-i18next";

// Renderiza modal que exibe detalhes da vaga
const DetalhesVaga = ({
  data_abertura,
  descricao,
  atividade,
  funcao,
  vaga,
  me_registrei,
  fecharModal,
}) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useToggle(null);

  return (
    <Container
      maxWidth="sm"
      sx={{ maxHeight: "75vh", overflow: "auto", py: 1 }}
    >
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Body1 align="left">
            {t("oportunidades.labelOpenDate")}:{" "}
            {format(parseISO(data_abertura), "dd/MM/yy")}
          </Body1>
          <H6 align="right" sx={{ flex: 1 }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {t("oportunidades.labelVacation")}: {vaga}{" "}
          </H6>
        </Stack>
        <H6>
          {t("oportunidades.labelFunction")}: {funcao}
        </H6>
        <Divider />
        <Body1>
          {t("oportunidades.labelDescription")}: {descricao}
        </Body1>
        <Body2>{t("oportunidades.labelActivity")}:</Body2>
        <Body2 sx={{ whiteSpace: "pre-line" }} align="left">
          {atividade}
        </Body2>
        <Divider />
        <Button
          disabled={toggle || me_registrei}
          variant="contained"
          startIcon={<Icone icone="WorkOutline" />}
          onClick={setToggle}
        >
          {me_registrei
            ? t("oportunidades.labelIamCandidate")
            : t("oportunidades.labelRegister")}
        </Button>
        <AnimatePresence>
          {toggle && (
            <motion.div
              initial={{ scale: 0.01 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.01 }}
            >
              <QuestionarioCandidato
                setToggle={setToggle}
                vaga={vaga}
                fecharModal={fecharModal}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Stack>
    </Container>
  );
};

export default DetalhesVaga;
