import React, { useState, useEffect } from "react";
import {
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  useMediaQuery,
  Stack,
  Divider,
  Grid,
  Container,
  Typography,
  Hidden,
  useTheme,
  CircularProgress,
  Button,
} from "@mui/material";
import BackgroundRouter from "../background-router";
import Select from "../../components/select-old";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { format, addMonths, subMonths, parseISO } from "date-fns";
// Customizados
import { Icone, TabelaV2 } from "../../components";
// Utils
import { useSelector } from "react-redux";
import { useFetch } from "../../hooks";
import { ToastErro } from "../../utils/utils";
import { AtualizadorCache } from "../../extra-components";
import { useTranslation } from "react-i18next";

const selectUsuario = (state) => state?.usuario;
const selectIsRH = (state) => state?.usuario?.variaveis.includes("GRUPO_RH");
// Gerar a estrutura de periodos
let periodoAtual;
const periodos = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((ele) => {
  // Pega o primeiro dia
  const periodo1 = format(subMonths(new Date(), ele), "yyyyMM21");
  const periodo2 = format(addMonths(parseISO(periodo1), 1), "yyyyMM20");
  // Verifica se a data atual esta no range
  const filtro = format(new Date(), "yyyyMMdd");
  if (periodo1 <= filtro && periodo2 >= filtro)
    periodoAtual = `${periodo1}-${periodo2}`;
  return [
    `${periodo1}-${periodo2}`,
    `${format(parseISO(periodo1), "dd/MM/yyyy")} - ${format(
      parseISO(periodo2),
      "dd/MM/yyyy"
    )}`,
  ];
});

const getHeaderTable = (t) => {
  return [
    t("ponto_eletronico.headerOne"),
    t("ponto_eletronico.headerTwo"),
    t("ponto_eletronico.headerThree"),
    t("ponto_eletronico.headerFour"),
    t("ponto_eletronico.headerFive"),
    t("ponto_eletronico.headerSix"),
    t("ponto_eletronico.headerSeven"),
    t("ponto_eletronico.headerEight"),
    t("ponto_eletronico.headerNine"),
    t("ponto_eletronico.headerTen"),
    t("ponto_eletronico.headerEleven"),
  ];
};

const PontoEletronico = () => {
  const [aberto, setAberto] = useState(null);
  const usuario = useSelector(selectUsuario);
  const isRH = useSelector(selectIsRH);
  const { t } = useTranslation();

  const { error, wait, setFetch, data } = useFetch(
    window.location.pathname,
    "POST",
    "json"
  );

  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  const history = useHistory();
  // Caso venha de minha_equipe vamos a usar o objeto usuario dele
  const location = useLocation();
  let usr, isGestor;
  // Obter o stado
  if (location.state) {
    usr = location.state;
    isGestor = true;
  } else {
    usr = usuario;
  }

  const {
    breakpoints: { values },
  } = useTheme();
  const isMobile = values["sm"] >= window.innerWidth;
  const isXl = useMediaQuery(useTheme()?.breakpoints?.up("xl"));

  return (
    <BackgroundRouter>
      <Container maxWidth={isXl ? "xl" : "lg"}>
        {isGestor && (
          <Button
            sx={{ my: 1 }}
            color="primary"
            size="small"
            startIcon={<Icone icone="ArrowBack" />}
            onClick={() => history.goBack()}
          >
            {t("ponto_eletronico.goBackEquip")}
          </Button>
        )}
        <Drawer
          onClose={() => setAberto(null)}
          anchor="bottom"
          open={!!aberto}
          transitionDuration={300}
        >
          <Detalhes data={data} dataDetalhes={aberto} />
          <Button
            onClick={() => setAberto(null)}
            startIcon={<Icone icone="KeyboardArrowDown" />}
            variant="text"
            color="primary"
          >
            {t("ponto_eletronico.closeDrawer")}
          </Button>
        </Drawer>

        <Typography align="center" variant="h6">
          {t("ponto_eletronico.title")}
        </Typography>
        {usr && usr.usuario && (
          <Typography variant={isMobile ? "caption" : "body2"}>
            {usr.usuario.matricula} - {usr.usuario.nome}
          </Typography>
        )}
        <Divider />
        <br />
        <RenderForm
          isGestor={isGestor && usr.usuario.matricula}
          aguardar={wait}
          setFetch={setFetch}
        />
        <Hidden smUp>
          <br />
        </Hidden>
        {isMobile && data ? (
          <RenderMobile data={data} onClick={(data) => setAberto(data)} />
        ) : (
          <RenderTabela data={data} />
        )}
        <br />
        {isRH && (
          <AtualizadorCache titulo={t("ponto_eletronico.titleUpdateCache")} />
        )}
      </Container>
    </BackgroundRouter>
  );
};
//
const RenderForm = ({ isGestor, setFetch, aguardar }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  // Utilizar useEffect para recuperar os registros assim que o App é aberto
  useEffect(() => {
    const [de, ate] = periodoAtual.split("-");
    const obj = {
      de: format(parseISO(de), "yyyy-MM-dd"),
      ate: format(parseISO(ate), "yyyy-MM-dd"),
    };

    if (isGestor) obj["matricula"] = isGestor;
    setFetch(obj);
  }, [isGestor, setFetch]);

  const fn = (val) => {
    const [de, ate] = val["PERIODO"].split("-");
    const obj = {
      de: format(parseISO(de), "yyyy-MM-dd"),
      ate: format(parseISO(ate), "yyyy-MM-dd"),
    };
    // Se for o gestor (ou seja isto veio de minha_equipe devemos enviar a matricula)
    if (isGestor) obj["matricula"] = isGestor;
    //
    setFetch(obj);
  };

  return (
    <Grid alignItems="flex-end" container spacing={2}>
      <Grid item md={3} xs={12}>
        <Controller
          control={control}
          rules={{ required: true }}
          defaultValue={periodoAtual}
          name="PERIODO"
          render={({ field }) => (
            <Select
              {...field}
              fullWidth
              options={periodos}
              label={t("ponto_eletronico.labelField")}
              error={!!errors.de}
            />
          )}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Button
          startIcon={
            aguardar ? (
              <CircularProgress size={20} />
            ) : (
              <Icone icone="FindReplace" />
            )
          }
          onClick={handleSubmit(fn)}
          disabled={aguardar}
          fullWidth
          variant="contained"
          color="primary"
        >
          {t("ponto_eletronico.btnEnviar")}
        </Button>
      </Grid>
    </Grid>
  );
};
//
const RenderTabela = ({ data }) => {
  const { t } = useTranslation();
  const { optTabela } = useSelector((state) => state);
  if (!data) return null;

  const { corpo } = data;
  // verifica quais campos sao data e os insere no array dtas
  const datas = [];
  if (corpo && corpo.length > 0) {
    corpo[0].forEach((ele, idx) => {
      if (ele.toString().search(/^[0-9]{8}$/g) !== -1) {
        datas.push(idx);
      }
    });
  }

  const cabe = getHeaderTable(t);

  const optTabelaT = {
    ...optTabela,
    baixar_em_excel: "/baixar_em_excel",
    ocultarColuna: true,
    data: datas,
    styleCabe: { fontSize: "90%" },
    styleCorpo: { fontSize: "85%", whiteSpace: "nowrap" },
  };

  return <TabelaV2 cabe={cabe} corpo={corpo} {...optTabelaT} />;
};
// Renderiza a visualizacao mobile
const RenderMobile = ({ onClick, data }) => {
  const { aguardar } = useSelector((state) => state);
  const { t } = useTranslation();

  // Retorna um objeto com itens {'data': '20210901', e1: 08:50, e2: 16:00 }
  const dados = data.corpo.map((ele) => ({
    dia: ele[0].substr(0, 3),
    data: ele[1],
    e1: ele[2],
    s2: ele[5] ? ele[5] : ele[3], // Caso o 2s esteja vazio usar o 1s
  }));

  return aguardar ? (
    <Stack alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>
  ) : (
    <>
      {data.acumulado && <Totalizador acumulado={data.acumulado} />}
      <List>
        <ListItem>
          <ListItemText align="left">
            {t("ponto_eletronico.mobileFieldDay")}
          </ListItemText>
          <ListItemText align="center">
            {t("ponto_eletronico.mobileFieldData")} &nbsp;&nbsp;&nbsp;
          </ListItemText>
          <ListItemText align="center">
            {t("ponto_eletronico.mobileFieldInput")}
          </ListItemText>
          <ListItemText align="center">
            {t("ponto_eletronico.mobileFieldOut")}
          </ListItemText>
          <ListItemText align="right">
            {t("ponto_eletronico.mobileFieldView")}
          </ListItemText>
        </ListItem>
        {dados.map((ele, idx) => {
          const { dia, data, e1, s2 } = ele;

          return (
            <React.Fragment key={idx}>
              <ListItem key={idx}>
                <ListItemText align="left">{dia}</ListItemText>
                <ListItemText align="center">
                  {format(parseISO(data), "dd/MM/yy")}
                </ListItemText>
                <ListItemText align="center">{e1}</ListItemText>
                <ListItemText align="center">{s2}</ListItemText>
                <ListItemText onClick={() => onClick(data)} align="right">
                  <Icone icone="MoreHoriz" />
                </ListItemText>
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </>
  );
};
// Renderiza o totalizador
const Totalizador = ({ acumulado }) => {
  const sxStyle = { my: 1, backgroundColor: "#005387", color: "white" };
  const { t } = useTranslation();

  return (
    <Accordion elevation={1} sx={sxStyle}>
      <AccordionSummary
        expandIcon={<Icone icone="ExpandMore" sx={{ color: "white" }} />}
      >
        <Icone icone="QueryBuilder" />
        &nbsp;&nbsp;
        <Typography variant="subtitle1">
          {t("ponto_eletronico.resultPartial")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List dense>
          {acumulado.map((ele, idx) => (
            <ListItemText
              key={idx}
              primary={
                <Typography variant="subtitle2">{`${ele[0]} - ${ele[1]} `}</Typography>
              }
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
// Renderiza a lista de detalhes
const Detalhes = ({ data, dataDetalhes }) => {
  // const { pontoEletronico } = useSelector(state => state);

  let filtro = JSON.parse(JSON.stringify(data)).corpo.filter(
    (ele) => ele[1] === dataDetalhes
  );
  //  Se nao tiver encontrado nada, retorne null
  if (filtro.length < 1) return null;
  // Pega somente os campos que não estao sendo exibidos ali
  filtro = filtro[0].splice(6, 5);

  return (
    <List sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 1 }}>
      {filtro.map((ele, idx) => (
        <ListItem divider key={idx}>
          <ListItemText>
            <Typography variant="subtitle1">{data.cabe[idx + 6]}</Typography>
          </ListItemText>
          <ListItemText>
            <Typography align="right" variant="subtitle2">
              {ele}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default PontoEletronico;
