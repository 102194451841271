import { pt_br } from '../strings/';

const getLanguage = (language = 'pt_br', rota)=>{
    const ROTA = rota || window.location.pathname;
    // Veja qual idioma foi escolhido e retorne o idioma correto
    let obj;
    switch(language){
        case 'pt_br':
            obj = pt_br[ROTA];// Recebe o idioma da rota atual
            break;
        default:
            obj = {};
            break;
    }
    
    return obj;
}

export default getLanguage;