import React, { useCallback, memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO, differenceInDays } from "date-fns";
import {
  Stack,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Chip,
  Divider,
  Button,
  Menu,
  MenuItem,
  Paper,
  Grow,
} from "@mui/material";

import {
  cadastroProjetosSetDrawer,
  cadastroProjetosGetProjetos,
  cadastroProjetosGetHistoricoChecklist,
  cadastroProjetosGetDadosSaving,
  cadastroProjetosGetMembros,
  cadastroProjetosSetChecklist,
  cadastroProjetosGetProjetosFiltroComplexoPaginado,
} from "./kaizen-actions";

import { fnKaizenGetText, fnGetIcon, fnGetColor } from "./kaizen-utils";
import { useTheme } from "@mui/material/styles";
import RetornoEmBranco from "../../../components/retorno-em-branco";
import Paginacao from "../../../components/paginacao";
import { Icone } from "../../../components";
import KaizenModal from "./kaizen-modal";
import ListaSkeletonProjetos from "./kaizen-skeleton-project";
import { useTranslation } from "react-i18next";

// Lista os projetos que o usuário tem ou faz parte
const ListaProjetos = ({
  isFiltro,
  justificativa_x_projeto,
  statusSelecionado,
  isMobile,
  membros,
  projetos,
  isFiltroAvancado,
  filtroAvancadoQtdPagina,
  filtroAvancadoPaginaAtual,
  filtroAvancadoSetPagina,
}) => {
  const dispatch = useDispatch();
  const [aguardar, setAguardar] = useState(false);
  const [paginaAtual, setPaginaAtual] = useState(
    filtroAvancadoPaginaAtual || 1
  );

  const qtdPaginaNormal = useSelector(
    (state) => state?.cadastroProjetos?.qtd_pagina
  );
  const qtdPaginaFiltro = useSelector(
    (state) => state?.cadastroProjetos?.filtro?.qtd_pagina
  );

  const filtroComplexo = useSelector(
    (state) => state?.cadastroProjetos?.filtro?.filtroComplexo
  );

  let qtdPagina = qtdPaginaNormal;

  if (isFiltro) {
    qtdPagina = qtdPaginaFiltro;
  }
  //
  const callbackUploadPage = useCallback(
    (pg, proximaPagina) => {
      setPaginaAtual(proximaPagina);

      dispatch(
        cadastroProjetosGetProjetosFiltroComplexoPaginado(
          filtroComplexo,
          setAguardar,
          proximaPagina
        )
      );
    },
    [setPaginaAtual, dispatch, filtroComplexo]
  );

  return projetos.length === 0 ? (
    <RetornoEmBranco />
  ) : (
    <Stack sx={{ mt: 1 }}>
      {isFiltroAvancado ? (
        <Paginacao
          callBackPagina={(paginaAtual, proximaPagina) => {
            filtroAvancadoSetPagina(proximaPagina);
          }}
          corpo={projetos}
          quantidadeDePaginas={filtroAvancadoQtdPagina}
          paginaAtual={paginaAtual}
          componentePai={(filho) => (
            <List
              sx={{ mt: 2, height: "70vh", overflow: "auto" }}
              disablePadding
            >
              {filho}
            </List>
          )}
          component={(props) => (
            <ListaProjetosItem
              key={props.id}
              {...props}
              justificativa_x_projeto={justificativa_x_projeto}
              membros={membros}
            />
          )}
        />
      ) : isFiltro ? (
        aguardar ? (
          <ListaSkeletonProjetos />
        ) : (
          <Paginacao
            callBackPagina={callbackUploadPage}
            corpo={projetos}
            quantidadeDePaginas={qtdPagina}
            paginaAtual={paginaAtual}
            componentePai={(filho) => (
              <List
                sx={{ mt: 2, height: "70vh", overflow: "auto" }}
                disablePadding
              >
                {filho}
              </List>
            )}
            component={(props) => (
              <ListaProjetosItem
                key={props.id}
                {...props}
                justificativa_x_projeto={justificativa_x_projeto}
                membros={membros}
              />
            )}
          />
        )
      ) : (
        <Paginacao
          callBackPagina={(paginaAtual, proximaPagina) => {
            dispatch(
              cadastroProjetosGetProjetos("pagina", proximaPagina, () => {})
            );
          }}
          corpo={projetos}
          quantidadeDePaginas={qtdPagina}
          componentePai={(filho) => (
            <List
              sx={{ mt: 2, height: "70vh", overflow: "auto" }}
              disablePadding
            >
              {filho}
            </List>
          )}
          component={(props) => (
            <ListaProjetosItem
              key={props.id}
              {...props}
              justificativa_x_projeto={justificativa_x_projeto}
              membros={membros}
            />
          )}
        />
      )}
    </Stack>
  );
};

// componente que exibe os itens da lista de projetos
const ListaProjetosItem = memo(
  ({
    mat_criador,
    tipo_projeto,
    nova_data_prevista,
    atrasado,
    justificativa_x_projeto,
    id,
    codigo,
    descricao,
    abertura,
    data_prevista,
    area,
    lider,
    situacao,
    encerramento,
    membros,
    situacao_anterior,
  }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Verifica os membros deste projeto
    const qtdMembros = membros?.hasOwnProperty(id) ? membros[id].length : 0;
    const {
      breakpoints: { values },
    } = useTheme();
    const isMobile = values["sm"] > window.innerWidth;

    return (
      <Grow in unmountOnExit>
        <Paper sx={{ my: 1, mx: 1, px: 1 }} elevation={2}>
          <Stack>
            <ListItemButton
              onClick={() =>
                dispatch(
                  cadastroProjetosSetDrawer({
                    tipo: KaizenModal.modal.VER_DETALHES,
                    valor: id,
                  })
                )
              }
            >
              <ListItemText
                primary={
                  <Stack
                    sx={{ mb: 1 }}
                    direction={isMobile ? "column" : "row"}
                    alignItems={isMobile ? "flex-start" : "center"}
                  >
                    <Typography variant="subtitle2">
                      {t("cadastro_projetos.leader")}: {lider}
                    </Typography>
                    {atrasado && (
                      <Chip
                        sx={{ ml: isMobile ? 0 : 1 }}
                        size="small"
                        color="error"
                        label={`${t(
                          "cadastro_projetos.late"
                        )} ${differenceInDays(
                          new Date(),
                          parseISO(data_prevista)
                        )} ${t("cadastro_projetos.days")}`}
                      />
                    )}
                  </Stack>
                }
                secondary={
                  <ListaSubtitulo
                    descricao={descricao}
                    area={area}
                    abertura={abertura}
                    data_prevista={data_prevista}
                    nova_data_prevista={nova_data_prevista}
                    qtdMembros={qtdMembros}
                    situacao={situacao}
                    codigo={codigo}
                    id={id}
                    atrasado={atrasado}
                    encerramento={encerramento}
                  />
                }
              />
            </ListItemButton>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center">
                <Icone icone="Groups" />
                <Typography component="span" variant="subtitle2">
                  &nbsp;&nbsp;{t("cadastro_projetos.members")}: {qtdMembros}
                </Typography>
                <OpcoesExtras
                  tipo_projeto={tipo_projeto}
                  idProjeto={id}
                  situacao_anterior={situacao_anterior}
                  situacao={situacao}
                  atrasado={atrasado}
                  mat_criador={mat_criador?.split("-")[0].trim()}
                  lider={lider.split("-")[0].trim()} // Recupera a matricula do lider
                  planta={codigo.split("_")[0]}
                  justificativa_x_projeto={justificativa_x_projeto}
                />
              </Stack>
              <StatusProjeto situacao={situacao} />
            </Stack>
            <Divider sx={{ mt: 1 }} />
          </Stack>
        </Paper>
      </Grow>
    );
  }
);

// Componente para subtiulo da llista
const ListaSubtitulo = ({
  encerramento,
  nova_data_prevista,
  codigo,
  abertura,
  descricao,
  data_prevista,
  area,
}) => {
  const { t } = useTranslation();
  // Para formatara string a ser exibida no campo previsto
  const campoPrevisto =
    encerramento ||
    `${t("cadastro_projetos.previst")} ${format(
      parseISO(data_prevista),
      "dd/MM/yy"
    )}`;

  return (
    <Stack>
      <Chip
        sx={{ mb: 1, alignSelf: "flex-start" }}
        color="primary"
        label={`${t("cadastro_projetos.code")}: ${codigo}`}
      />
      <Typography sx={{ mb: 1 }} variant="caption">
        {t("cadastro_projetos.area")}: {area}
      </Typography>
      <Typography sx={{ mb: 1 }} variant="caption">
        {t("cadastro_projetos.description")}: {descricao}
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography color="primary" variant="caption">
          {t("cadastro_projetos.openListProject")}:{" "}
          {format(parseISO(abertura), "dd/MM/yy")}
        </Typography>
        <Stack>
          <Typography color="error" variant="caption">
            {campoPrevisto}
          </Typography>
          {nova_data_prevista && (
            <Stack direction="row" alignItems="center">
              <Icone icone="FiberNew" color="primary" />
              <Typography color="primary" variant="caption">
                {t("cadastro_projetos.newDatePrevist")}:{" "}
                {format(parseISO(nova_data_prevista), "dd/MM/yy")}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
// Este componente e usado para criar o menu com as opcoes relacionadas ao projeto
const OpcoesExtras = ({
  mat_criador,
  lider,
  planta,
  justificativa_x_projeto,
  atrasado,
  idProjeto,
  situacao,
  tipo_projeto,
  situacao_anterior,
}) => {
  //
  const usuario = useSelector((state) => state?.usuario?.usuario);
  const isReabrirProjeto = useSelector((state) =>
    state?.usuario?.variaveis?.includes("REABRIR_PROJETO")
  );
  const { t } = useTranslation();

  //
  const dispatch = useDispatch();
  //
  const [anchorEl, setAnchorEl] = useState(null);
  // Esta funcao vai definir que o menu deve ser exibido
  const fnManipularMenu = (evt) => {
    setAnchorEl(evt.currentTarget);
  };
  // Funcao para fechar o menu
  const fnFecharMenu = () => setAnchorEl(null);
  // Array com as opções padrão do projeto
  const opcoes = [
    {
      texto: t("cadastro_projetos.viewHist"),
      icone: <Icone icone="History" size={20} />,
      onClick: () => {
        dispatch(
          cadastroProjetosSetDrawer({
            tipo: KaizenModal.modal.VER_HISTORICO,
            valor: {
              id_projeto: idProjeto,
            },
          })
        );
        fnFecharMenu();
      },
    },
    {
      texto: fnKaizenGetText("VC", t),
      icone: fnGetIcon("VC"),
      onClick: () => {
        // Ver a lista de checklists
        dispatch(cadastroProjetosGetHistoricoChecklist(idProjeto));
        fnFecharMenu();
      },
    },
  ];
  // Coloca o botao para validacao CD se o projeto nao esitve com status encerrado
  if (situacao !== "E") {
    opcoes.push({
      texto: fnKaizenGetText("VCD", t),
      icone: fnGetIcon("VCD"),
      onClick: () => {
        // tentar obter o saving
        dispatch(cadastroProjetosGetDadosSaving({ id_projeto: idProjeto }));
        fnFecharMenu();
      },
    });
  }
  // Coloca o botao para solicitar alteracao de membros se o projeto nao estiver encerrado
  if (
    situacao !== "E" &&
    (usuario?.matricula === lider || usuario?.matricula === mat_criador) &&
    usuario?.planta === planta
  ) {
    opcoes.push({
      texto: fnKaizenGetText("ATM", t),
      icone: fnGetIcon("ATM"),
      onClick: () => {
        // Para alteracao de membros
        dispatch(cadastroProjetosGetMembros(idProjeto));
        fnFecharMenu();
      },
    });
  }

  // Dependendo da situação do projeto teremos alternativas a serem acionadas
  switch (situacao) {
    case "A": // Status aberto
      opcoes.push({
        texto: fnKaizenGetText("S", t),
        icone: fnGetIcon("S"),
        onClick: () => {
          dispatch(
            cadastroProjetosSetDrawer({
              tipo: KaizenModal.modal.ALTERAR_STATUS,
              valor: {
                idProjeto,
                status: "S",
              },
            })
          );
          fnFecharMenu();
        },
      });
      // Opcao para cancelar
      opcoes.push({
        texto: fnKaizenGetText("I", t),
        icone: fnGetIcon("I"),
        onClick: () => {
          dispatch(
            cadastroProjetosSetDrawer({
              tipo: KaizenModal.modal.ALTERAR_STATUS,
              valor: {
                idProjeto,
                status: "I",
              },
            })
          );

          fnFecharMenu();
        },
      });
      break;
    case "S": // Status Validação FI
      // Para alterar este status para validacao cd
      opcoes.push({
        texto: fnKaizenGetText("C", t),
        icone: fnGetIcon("C"),
        onClick: () => {
          // Para chamar a rotina de checklist e depois chamar a tela para alterar para o status validacao CD

          dispatch(
            cadastroProjetosSetChecklist(tipo_projeto, idProjeto, () =>
              cadastroProjetosSetDrawer({
                tipo: KaizenModal.modal.ALTERAR_STATUS,
                valor: { idProjeto, status: "C" },
              })
            )
          );
          fnFecharMenu();
        },
      });
      // Botao para retornar o status também para reprovado
      opcoes.push({
        texto: fnKaizenGetText("R", t),
        icone: fnGetIcon("R"),
        onClick: () => {
          dispatch(
            cadastroProjetosSetDrawer({
              tipo: KaizenModal.modal.ALTERAR_STATUS,
              valor: {
                idProjeto,
                status: "R",
              },
            })
          );

          fnFecharMenu();
        },
      });
      // Opcao para cancelar
      opcoes.push({
        texto: fnKaizenGetText("I", t),
        icone: fnGetIcon("I"),
        onClick: () => {
          dispatch(
            cadastroProjetosSetDrawer({
              tipo: KaizenModal.modal.ALTERAR_STATUS,
              valor: {
                idProjeto,
                status: "I",
              },
            })
          );

          fnFecharMenu();
        },
      });
      break;
    case "C": // Status Validação CD
      opcoes.push({
        texto: fnKaizenGetText("E", t),
        icone: fnGetIcon("E"),
        onClick: () => {
          // Verificar primeiro se este projeto já esteve com status E e então perguntar qual a nova
          // data para o saving.
          //
          dispatch(
            cadastroProjetosSetDrawer({
              tipo: KaizenModal.modal.ALTERAR_STATUS,
              valor: {
                idProjeto,
                status: "E",
                situacaoAnterior: situacao_anterior,
              },
            })
          );

          fnFecharMenu();
        },
      });
      // Botao para retornar o status também para reprovado
      opcoes.push({
        texto: fnKaizenGetText("R", t),
        icone: fnGetIcon("R"),
        onClick: () => {
          dispatch(
            cadastroProjetosSetDrawer({
              tipo: KaizenModal.modal.ALTERAR_STATUS,
              valor: {
                idProjeto,
                status: "R",
              },
            })
          );

          fnFecharMenu();
        },
      });
      // Opcao para cancelar
      opcoes.push({
        texto: fnKaizenGetText("I", t),
        icone: fnGetIcon("I"),
        onClick: () => {
          dispatch(
            cadastroProjetosSetDrawer({
              tipo: KaizenModal.modal.ALTERAR_STATUS,
              valor: {
                idProjeto,
                status: "I",
              },
            })
          );
          fnFecharMenu();
        },
      });
      break;
    case "R": // Status de projeto reprovado
      opcoes.push({
        texto: fnKaizenGetText("S", t),
        icone: fnGetIcon("S"),
        onClick: () => {
          dispatch(
            cadastroProjetosSetDrawer({
              tipo: KaizenModal.modal.ALTERAR_STATUS,
              valor: {
                idProjeto,
                status: "S",
              },
            })
          );
          fnFecharMenu();
        },
      });
      // Opcao para cancelar
      opcoes.push({
        texto: fnKaizenGetText("I", t),
        icone: fnGetIcon("I"),
        onClick: () => {
          dispatch(
            cadastroProjetosSetDrawer({
              tipo: KaizenModal.modal.ALTERAR_STATUS,
              valor: {
                idProjeto,
                status: "I",
              },
            })
          );
          fnFecharMenu();
        },
      });
      break;
    case "E":
      // Verifica se o usuario tem a variavel que permite alterar este status
      if (isReabrirProjeto) {
        // Pode devolver para o status de validacao CD para alteração de valores
        opcoes.push({
          texto: fnKaizenGetText("RCD", t),
          icone: fnGetIcon("C"),
          onClick: () => {
            dispatch(
              cadastroProjetosSetDrawer({
                tipo: KaizenModal.modal.ALTERAR_STATUS,
                valor: {
                  idProjeto,
                  status: "C",
                },
              })
            );
            fnFecharMenu();
          },
        });
      }

      break;
    default:
      break;
  }
  // Se o projeto estiver em atraso o botao de justificativa deve ser exibido
  if (atrasado) {
    opcoes.push({
      texto: fnKaizenGetText("J", t),
      icone: fnGetIcon("J"),
      onClick: () => {
        dispatch(
          cadastroProjetosSetDrawer({
            tipo: KaizenModal.modal.DEFINR_JUSTIFICATIVA_ATRASO,
            valor: idProjeto,
          })
        );
        fnFecharMenu();
      },
    });
  }
  // Se tiver justificativa exibir a justificativa
  if (justificativa_x_projeto.hasOwnProperty(idProjeto)) {
    opcoes.push({
      texto: fnKaizenGetText("VJ", t),
      icone: fnGetIcon("VJ"),
      onClick: () => {
        dispatch(
          cadastroProjetosSetDrawer({
            tipo: KaizenModal.modal.VER_JUSTIFICATIVA_ATRASO,
            valor: idProjeto,
          })
        );

        fnFecharMenu();
      },
    });
  }

  return (
    <>
      <Button
        endIcon={<Icone icone="KeyboardArrowDown" />}
        sx={{ ml: 1 }}
        size="small"
        onClick={fnManipularMenu}
        variant="contained"
        color="primary"
      >
        {t("cadastro_projetos.btnOptions")}
      </Button>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={fnFecharMenu}>
        {opcoes.map((ele, idx) => (
          <MenuItem key={idx} onClick={ele.onClick} divider>
            {ele.icone}&nbsp;&nbsp;
            <Typography variant="body2">{ele.texto}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
// Componente para status do projeto
const StatusProjeto = ({ situacao }) => {
  const { t } = useTranslation();
  //
  const infoSituacao = fnKaizenGetText(situacao, t);
  const cor = fnGetColor(situacao);

  return (
    <Chip
      avatar={fnGetIcon(situacao)}
      size="small"
      sx={cor}
      label={infoSituacao}
    />
  );
};

export default ListaProjetos;
