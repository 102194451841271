import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import { fnSeletorTamanho } from "./kaizen-selectors";
import { addDays, format, subDays } from "date-fns";
import { toast } from "react-toastify";
import { cadastroProjetosCadastro } from "./kaizen-actions";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Caption, Icone } from "../../../components";
import Select from "react-select";
import { formatarParaSelect } from "../../../utils/utils";
import { selectStyles, selectStylesDark } from "./styles-select";
import MaskedInput from "react-text-mask";
import { maskToMoney } from "./kaizen-utils";
import { useTranslation } from "react-i18next";

const selectWaitUser = (state) => state;

const FormAddProjeto = ({ fecharForm, dadosForm }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { aguardar, usuario } = useSelector(selectWaitUser);
  const tamanhoCampos = useSelector(fnSeletorTamanho);

  // Criacao do schema de validacao
  const schema = yup.object().shape({
    data_abertura: yup.date().required(),
    //codigo: yup.string().min(1).required(),
    id_direcionador: yup.string().required(),
    descricao: yup
      .string()
      .min(1)
      .max(tamanhoCampos?.addProjDescricao)
      .required(),
    expansao: yup.string().required(),
    id_area: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    id_modelos: yup.array().min(1).required(),
    operacao: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    id_perda: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    tipo_perda: yup.string().min(1).required(),
    id_tipo_projeto: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    cod_pilar: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
    idmatrizd: yup.string().min(1).required(),
    //saving: yup.string().matches(/\d+,[0-9]{2}$/i).required(),
    saving: yup.string().matches(/\d+/i).required(),
    mat_lider: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .required(),
  });
  //
  // Criacao de objeto com as mensagens de erros
  const errosMensagem = {
    data_abertura: t("cadastro_projetos.errorDateOpen"),
    //codigo: 'Não deve ficar em branco',
    id_direcionador: t("cadastro_projetos.errorDirection"),
    descricao: `${t("cadastro_projetos.errorDescription1")} ${
      tamanhoCampos?.addProjDescricao
    } ${t("cadastro_projetos.errorDescription2")}`,
    expansao: t("cadastro_projetos.errorExpansion"),
    id_area: t("cadastro_projetos.errorArea"),
    id_modelos: t("cadastro_projetos.errorModel"),
    operacao: t("cadastro_projetos.errorOperation"),
    id_perda: t("cadastro_projetos.error"),
    tipo_perda: t("cadastro_projetos.errorTypeLose"),
    id_tipo_projeto: t("cadastro_projetos.errorTypeProject"),
    cod_pilar: t("cadastro_projetos.errorPilar"),
    idmatrizd: t("cadastro_projetos.errorMatriz"),
    saving: t("cadastro_projetos.errorSaving"),
    mat_lider: t("cadastro_projetos.errorLider"),
  };
  const [membros, setMembros] = useState(null);

  const LIDER = usuario.usuario; // Pega o objeto usuario para criar um autopreenchimento do lider

  const VARIAVEL_DIAS_AVANCO_RECUO =
    usuario?.variaveis_globais?.CADASTRO_PROJETO_DATA_ABERTURA || 0;

  // Extraindo os campos necessarios
  const {
    colaboradores,
    direcionador,
    area,
    operacoes,
    area_modelo,
    perda,
    perda_tipo_projeto,
    perda_pilar,
  } = dadosForm;
  //
  const {
    unregister,
    setError,
    clearErrors,
    setValue,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // Funcao que e usada para submeter os dados
  const fn = (val) => {
    // Extraindo todos os campos
    const {
      cod_pilar,
      mat_lider,
      // codigo,
      data_abertura,
      descricao,
      expansao,
      id_area,
      id_direcionador,
      id_modelos,
      id_perda,
      id_tipo_projeto,
      idmatrizd,
      operacao,
      saving,
      tipo_perda,
    } = val;
    const obj = {
      cod_pilar: cod_pilar.value,
      mat_lider: mat_lider.value,
      //codigo,
      data_abertura: format(data_abertura, "yyyy-MM-dd"),
      descricao,
      expansao,
      id_area: parseInt(id_area.value),
      id_direcionador: parseInt(id_direcionador),
      id_modelos: id_modelos.map((ele) => parseInt(ele.value)),
      id_perda: parseInt(id_perda.value),
      id_tipo_projeto: parseInt(id_tipo_projeto.value),
      idmatrizd: parseInt(idmatrizd),
      operacao: operacao.value,
      saving: parseFloat(
        parseFloat(
          saving.replace(" ", "").replace(/\./g, "").replace(",", ".")
        ).toFixed(2)
      ),
      tipo_perda,
      membros: [],
    };
    // Veja se tem membros e vai incluindo eles
    obj.membros = [
      {
        matricula: mat_lider.value,
        percentual: 50.0,
      },
    ];
    // Total de percentuais
    let totalPercent = 50;

    // Veja se temos membros
    if (membros && Object.keys(membros).length > 0) {
      let erroMembroNulo = false;

      Object.keys(val).forEach((key) => {
        if (key.search("membro_") !== -1) {
          const { value } = val[key];
          const [, idx] = key.split("_");
          // Precisa garantir que o membro tem matricula definida
          if (value) {
            obj.membros.push({
              matricula: value,
              percentual: parseFloat(val[`percent_m_${idx}`]),
            });

            totalPercent += parseFloat(val[`percent_m_${idx}`]);
          } else {
            erroMembroNulo = true;
          }
        }
      });
      if (erroMembroNulo) {
        toast(t("cadastro_projetos.errorMemberNull"));
        return false;
      }
    }
    // Se totalPercent for 50 quer dizer que nenhum membro tem valor entao atribua 100
    if (totalPercent === 50) {
      // Caso não tenha membros o lider tem 100%
      obj.membros[0].percentual = 100;
      totalPercent = 100;
    }
    // Se o total de percentual for diferente de 100 nao submeter form
    if (totalPercent < 100) {
      toast(t("cadastro_projetos.errorPercentNot100"));
      return false;
    } else if (totalPercent > 100) {
      toast(t("cadastro_projetos.errorPercentMore100"));
      return false;
    }

    dispatch(cadastroProjetosCadastro(obj));
  };
  // Funcao de callback para redefinir e renderizar a area
  const onChangeArea = (e) => {
    setValue("id_area", e);
    setValue("id_modelos", null);
    clearErrors("id_area");
  };
  // Funcao de callbac para definir a perda
  const onChangePerda = (e) => {
    setValue("id_perda", e);
    setValue("id_tipo_projeto", null);
    setValue("cod_pilar", null);
    clearErrors("id_perda");
  };
  // Funcao de callback para controlar os membros
  const addMembros = () => {
    // Antes de começar a incluir membros se faz necessário ver se tem lider preenchido
    const lider = watch("mat_lider");
    if (lider?.length === 0) {
      const fraseErro = `${t("cadastro_projetos.errorNotLeader")}`;
      toast(fraseErro);
      setError("mat_lider", { type: "focus" });
      return false;
    }
    // Verifique se membros já é um objeto
    if (!membros) {
      // Isto quer dizer que ele é null, então deve-se criar o objeto representando o primeiro membro
      setMembros({
        1: {
          membro: null,
          percent: 0,
        },
      });
      return true;
    }
    // Cria uma copia de membros para manipularmos
    const _copiaMembros = { ...membros };
    // Agora sabemos que é o membro 2 em diante
    // Pegue o ultimo membro
    const chaves = Object.keys(_copiaMembros);
    // Ordene para pegar a ultima chave
    let ultimaChave = chaves.sort((a, b) =>
      parseInt(a) < parseInt(b) ? 1 : -1
    )[0];
    // Pegue os valores lá preenchidos para este ultimo membro
    const valorUltimoMembroIncluso = watch(`membro_${ultimaChave}`)?.value;
    if (!valorUltimoMembroIncluso) {
      // Quer dizer que o ultimo nao foi preenchido, logo não deve-se permitir incluir outro membro
      toast(t("cadastro_projetos.errorMemberLast"));
      return false;
    }
    // Agora pegue o valor de percentual deste ultimo membro
    const percent = watch(`percent_m_${ultimaChave}`);

    // Ultima validacao antes de aceitar a insercao. Ver se ainda tem percentual a ser atribuido
    const TOTAL_PERCENT =
      Object.keys(_copiaMembros)
        .map((key) =>
          _copiaMembros[key].percent ? _copiaMembros[key].percent : 0
        )
        .reduce((a, b) => a + b, 50) + percent;

    if (TOTAL_PERCENT === 100) {
      toast(t("cadastro_projetos.errorTotal100"));
      return false;
    }
    // Certo, salve o valor no ultimo membro e atualize o estado com mais um membro de valores null
    _copiaMembros[ultimaChave] = {
      percent: percent,
      membro: valorUltimoMembroIncluso,
    };
    setMembros({
      ..._copiaMembros,
      [parseInt(ultimaChave) + 1]: {
        percent: null,
        membro: null,
      },
    });
  };

  // Funcao para remover o membro da lista de membros
  const removerMembro = (chave) => {
    unregister(`membro_${chave}`); // Removendo o campo dos registros
    unregister(`percent_m_${chave}`); // Removendo o campo dos registros
    // E Agora o retira de membros
    const _copia = { ...membros };
    delete _copia[chave];

    setMembros(Object.keys(_copia).length === 0 ? null : _copia);
  };
  // Cria um array com os membros escolhidos (matricula)
  const MEMBROS_ESCOLHIDOS = membros
    ? Object.keys(membros)
        .map((key) => membros[key].membro)
        .concat(watch("mat_lider")?.value)
    : [watch("mat_lider")?.value];
  // Total de percentual definido
  const TOT_PERCENT = membros
    ? Object.keys(membros)
        .map((key) => (membros[key].percent ? membros[key].percent : 0))
        .reduce((a, b) => a + b, 50)
    : 50;

  const isDarkMode = useTheme()?.palette?.mode === "dark";

  return (
    <Container sx={{ maxHeight: "85vh" }} maxWidth="sm">
      <Stack spacing={2} sx={{ p: 1 }}>
        <Button
          onClick={() => fecharForm()}
          startIcon={<Icone icone="Close" />}
        >
          {t("cadastro_projetos.btnClose")}
        </Button>
        <Typography align="center" variant="h6">
          {t("cadastro_projetos.titleLaunchProject")}
        </Typography>
        <Paper sx={{ p: 1 }}>
          <Controller
            name="data_abertura"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t("cadastro_projetos.labelDateOpen")}
                type="date"
                size="small"
                InputProps={{
                  inputProps: {
                    min: format(
                      subDays(new Date(), parseInt(VARIAVEL_DIAS_AVANCO_RECUO)),
                      "yyyy-MM-dd"
                    ),
                    max: format(
                      addDays(new Date(), parseInt(VARIAVEL_DIAS_AVANCO_RECUO)),
                      "yyyy-MM-dd"
                    ),
                  },
                }}
                InputLabelProps={{ shrink: true }}
                helperText={!!errors.data_abertura && "Formato DD/MM/AAAA"}
                error={!!errors.data_abertura}
              />
            )}
          />
        </Paper>
        <Paper sx={{ p: 1 }}>
          <Controller
            name="id_direcionador"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <Stack>
                <Typography align="center" variant="subtitle2">
                  {t("cadastro_projetos.labelDirection")}
                </Typography>
                <RadioGroup aria-label="direcionador" {...field}>
                  {direcionador.map((ele, idx) => (
                    <FormControlLabel
                      key={idx}
                      value={ele[0]}
                      label={
                        <Typography variant="caption">{ele[1]}</Typography>
                      }
                      control={<Radio size="small" />}
                    />
                  ))}
                </RadioGroup>
                {!!errors.id_direcionador && (
                  <Typography align="center" color="error" variant="caption">
                    {errosMensagem.id_direcionador}
                  </Typography>
                )}
              </Stack>
            )}
          />
        </Paper>
        <Controller
          control={control}
          name="descricao"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              maxWidth
              {...field}
              label={t("cadastro_projetos.labelDescription")}
              type="textarea"
              size="small"
              minRows={3}
              multiline
              placeholder={t("cadastro_projetos.placeholderDescription")}
              inputProps={{ maxLength: tamanhoCampos?.addProjDescricao }}
              InputLabelProps={{ shrink: true }}
              helperText={
                <Stack direction="row-reverse" alignItems="center">
                  <Caption>
                    {!!errors.descricao && errosMensagem.descricao}
                  </Caption>
                  <Caption>
                    {" "}
                    {watch("descricao")?.length} /{" "}
                    {tamanhoCampos?.addProjDescricao}
                  </Caption>
                </Stack>
              }
              error={!!errors.descricao}
            />
          )}
        />

        <Paper sx={{ p: 1 }}>
          <Controller
            name="expansao"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <Stack>
                <Typography align="center" variant="subtitle2">
                  {t("cadastro_projetos.labelExpansionProject")}
                </Typography>
                <RadioGroup aria-label="expansao" {...field}>
                  {[
                    ["S", t("cadastro_projetos.optYes")],
                    ["N", t("cadastro_projetos.optNo")],
                  ].map((ele, idx) => (
                    <FormControlLabel
                      key={idx}
                      value={ele[0]}
                      label={
                        <Typography variant="caption">{ele[1]}</Typography>
                      }
                      control={<Radio size="small" />}
                    />
                  ))}
                </RadioGroup>
                {!!errors.expansao && (
                  <Typography align="center" color="error" variant="caption">
                    {errosMensagem.expansao}
                  </Typography>
                )}
              </Stack>
            )}
          />
        </Paper>
        <Paper sx={{ p: 1 }}>
          <Controller
            control={control}
            name="id_area"
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <Stack sx={{ mb: 1 }}>
                <Typography variant="subtitle2" align="center">
                  {t("cadastro_projetos.labelArea")}
                </Typography>
                <Select
                  {...field}
                  options={formatarParaSelect(area)}
                  onChange={onChangeArea}
                  styles={isDarkMode ? selectStylesDark : selectStyles}
                />
                {!!errors.id_area && (
                  <Typography align="center" color="error" variant="caption">
                    {errosMensagem.id_area}
                  </Typography>
                )}
              </Stack>
            )}
          />
          {watch("id_area") && (
            <Controller
              control={control}
              name="id_modelos"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Stack sx={{ mb: 1 }}>
                  <Typography variant="subtitle2" align="center">
                    {t("cadastro_projetos.labelModel")}
                  </Typography>
                  <Select
                    isMulti
                    {...field}
                    styles={isDarkMode ? selectStylesDark : selectStyles}
                    options={formatarParaSelect(
                      area_modelo[watch("id_area").value]
                    )}
                  />
                  {!!errors.id_modelos && (
                    <Typography align="center" color="error" variant="caption">
                      {errosMensagem.id_modelos}
                    </Typography>
                  )}
                </Stack>
              )}
            />
          )}
        </Paper>
        <Controller
          control={control}
          name="operacao"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Stack sx={{ mb: 1 }}>
              <Typography variant="subtitle2" align="center">
                {t("cadastro_projetos.labelOperation")}
              </Typography>
              <Select
                {...field}
                styles={isDarkMode ? selectStylesDark : selectStyles}
                options={formatarParaSelect(operacoes)}
              />
              {!!errors.operacao && (
                <Typography align="center" color="error" variant="caption">
                  {errosMensagem.operacao}
                </Typography>
              )}
            </Stack>
          )}
        />
        <Paper sx={{ p: 1 }}>
          <Controller
            control={control}
            name="id_perda"
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <Stack sx={{ mb: 1 }}>
                <Typography variant="subtitle2" align="center">
                  {t("cadastro_projetos.labelLose")}
                </Typography>
                <Select
                  {...field}
                  options={formatarParaSelect(perda)}
                  styles={isDarkMode ? selectStylesDark : selectStyles}
                  onChange={onChangePerda}
                />
                {!!errors.id_perda && (
                  <Typography align="center" color="error" variant="caption">
                    {errosMensagem.id_perda}
                  </Typography>
                )}
              </Stack>
            )}
          />
        </Paper>
        <Paper sx={{ p: 1 }}>
          <Controller
            name="tipo_perda"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <Stack sx={{ mb: 1 }}>
                <Typography align="center" variant="subtitle2">
                  {t("cadastro_projetos.labelTypeLose")}
                </Typography>
                <RadioGroup aria-label="tipo_perda" {...field}>
                  {[
                    ["C", t("cadastro_projetos.optCronic")],
                    ["E", t("cadastro_projetos.optExpordic")],
                  ].map((ele, idx) => (
                    <FormControlLabel
                      key={idx}
                      value={ele[0]}
                      label={
                        <Typography variant="caption">{ele[1]}</Typography>
                      }
                      control={<Radio size="small" />}
                    />
                  ))}
                </RadioGroup>
                {!!errors.tipo_perda && (
                  <Typography align="center" color="error" variant="caption">
                    {errosMensagem.tipo_perda}
                  </Typography>
                )}
              </Stack>
            )}
          />
        </Paper>

        {watch("id_perda") && (
          <Stack spacing={1}>
            <Paper sx={{ p: 1 }}>
              <Controller
                control={control}
                name="id_tipo_projeto"
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack sx={{ mb: 1 }}>
                    <Typography variant="subtitle2" align="center">
                      {t("cadastro_projetos.labelTypeOfProject")}
                    </Typography>
                    <Select
                      {...field}
                      styles={isDarkMode ? selectStylesDark : selectStyles}
                      options={formatarParaSelect(
                        perda_tipo_projeto[watch("id_perda").value]
                      )}
                    />
                    {!!errors.id_tipo_projeto && (
                      <Typography
                        align="center"
                        color="error"
                        variant="caption"
                      >
                        {errosMensagem.id_tipo_projeto}
                      </Typography>
                    )}
                  </Stack>
                )}
              />
            </Paper>
            <Paper sx={{ p: 1 }}>
              <Controller
                control={control}
                name="cod_pilar"
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Stack sx={{ mb: 1 }}>
                    <Typography variant="subtitle2" align="center">
                      {t("cadastro_projetos.labelPilar")}
                    </Typography>
                    <Select
                      {...field}
                      styles={isDarkMode ? selectStylesDark : selectStyles}
                      options={formatarParaSelect(
                        perda_pilar[watch("id_perda").value]
                      )}
                    />
                    {!!errors.cod_pilar && (
                      <Typography
                        align="center"
                        color="error"
                        variant="caption"
                      >
                        {errosMensagem.cod_pilar}
                      </Typography>
                    )}
                  </Stack>
                )}
              />
            </Paper>
          </Stack>
        )}
        <Controller
          control={control}
          name="idmatrizd"
          defaultValue={0}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              maxWidth
              {...field}
              label={t("cadastro_projetos.labelMatriz")}
              type="number"
              size="small"
              placeholder="Digite aqui..."
              InputLabelProps={{ shrink: true }}
              helperText={!!errors.idmatrizd && errosMensagem.idmatrizd}
              error={!!errors.idmatrizd}
            />
          )}
        />
        <Controller
          control={control}
          name="saving"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Stack>
              <Typography align="center" variant="subtitle2">
                {t("cadastro_projetos.labelSavingAdd")}
              </Typography>
              <Typography align="center" variant="caption">
                {t("cadastro_projetos.labelSavingInfo")}
              </Typography>

              <MaskedInput
                mask={maskToMoney}
                {...field}
                inputMode="numeric"
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    InputProps={{ startAdornment: "R$" }}
                    inputProps={{ autoComplete: "off" }}
                    size="small"
                    {...props}
                    inputRef={ref}
                    error={!!errors.saving}
                    helperText={!!errors.saving && errosMensagem.saving}
                  />
                )}
              />
            </Stack>
          )}
        />

        <Paper sx={{ p: 1 }}>
          <Controller
            control={control}
            name="mat_lider"
            defaultValue={
              formatarParaSelect([[LIDER.matricula, LIDER.nome]])[0]
            }
            rules={{ required: true }}
            render={({ field }) => (
              <Stack sx={{ mb: 1 }}>
                <Typography variant="subtitle2" align="center">
                  {t("cadastro_projetos.labelLeaderAdd")}
                </Typography>
                <Select
                  {...field}
                  styles={isDarkMode ? selectStylesDark : selectStyles}
                  options={formatarParaSelect(colaboradores)}
                />
                {!!errors.mat_lider && (
                  <Typography align="center" color="error" variant="caption">
                    {errosMensagem.mat_lider}
                  </Typography>
                )}
              </Stack>
            )}
          />
        </Paper>
        {(!membros || Object.keys(membros).length < 6) && (
          <Button
            fullWidth
            startIcon={<Icone icone="PersonAdd" />}
            onClick={addMembros}
          >
            {t("cadastro_projetos.btnNewMember")}
          </Button>
        )}
        <Stack spacing={1}>
          {membros &&
            Object.keys(membros).map((key, idx) => (
              <Paper key={key} sx={{ p: 1 }}>
                <Controller
                  control={control}
                  name={`membro_${key}`}
                  defaultValue={
                    membros[key].membro
                      ? formatarParaSelect(
                          colaboradores.filter(
                            (ele) =>
                              String(ele[0]) === String(membros[key].membro)
                          )
                        )[0]
                      : ""
                  }
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Stack sx={{ mb: 1 }}>
                      <Typography variant="subtitle2" align="center">
                        {`${t("cadastro_projetos.labelMemberNumber")} ${
                          idx + 1
                        }`}
                      </Typography>
                      <Stack direction="row">
                        <Box sx={{ mr: 1, flex: 1 }}>
                          <Select
                            {...field}
                            styles={
                              isDarkMode ? selectStylesDark : selectStyles
                            }
                            isDisabled={membros[key].membro ? true : false}
                            options={formatarParaSelect(
                              colaboradores.filter(
                                (ele) =>
                                  !MEMBROS_ESCOLHIDOS.includes(String(ele[0]))
                              )
                            )}
                          />
                        </Box>
                        <IconButton
                          onClick={() => removerMembro(key)}
                          title={t("cadastro_projetos.titleRemoveMemberAdd")}
                          color="error"
                          aria-label="delete"
                        >
                          <Icone icone="PersonRemove" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  )}
                />
                {/* Percentual do membro */}
                <Controller
                  control={control}
                  name={`percent_m_${key}`}
                  defaultValue={100 - TOT_PERCENT}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Stack>
                      <Typography align="center" variant="subtitle2">
                        {t("cadastro_projetos.labelPercentMember")} {idx + 1}
                      </Typography>
                      <Stack direction="row" alignItems="center">
                        <Slider
                          {...field}
                          sx={{ flex: 1 }}
                          disabled={membros[key].membro ? true : false}
                          max={
                            idx === Object.keys(membros).length - 1
                              ? 100 - TOT_PERCENT
                              : watch(`percent_m_${key}`)
                          }
                          valueLabelDisplay="on"
                        />
                        <Typography sx={{ ml: 1 }} variant="body2">
                          &nbsp;% {watch(`percent_m_${key}`)}
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                />
              </Paper>
            ))}
        </Stack>
        <Grid container>
          <Hidden mdDown>
            <Grid item md={4} />
          </Hidden>
          <Grid item xs={12} md={4}>
            <Button
              disabled={aguardar}
              sx={{ my: 1 }}
              fullWidth
              onClick={handleSubmit(fn)}
              variant="contained"
              startIcon={<Icone icone="PostAdd" />}
            >
              {t("cadastro_projetos.btnSave")}
            </Button>
            <Hidden mdDown>
              <Grid item md={4} />
            </Hidden>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

export default FormAddProjeto;
