import { useDispatch, useSelector } from "react-redux";
import { fnSeletorTamanho } from "../selectors";
import { useCallback } from "react";
import { useToggle } from "react-use";
import { EntradaForm } from "../../../../components";
import * as yup from "yup";
import { VALIDADOR_TIPO, obterValidador } from "../../../../utils/validadores";
import axios from "axios";
import { ToastErro } from "../../../../utils/utils";
import { feedRHAddUpdFeed } from "../feed-actions";
import { useTranslation } from "react-i18next";

const PLANTS = ["TAPE", "TAMG", "TAA"];

const names = {
  title: "titulo",
  subject: "assunto",
  observation: "observacao",
  file: "arquivo",
  plant: "planta",
};

const FormAddUpdFeed = ({ feed }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tamanhoCampoFeedAdd = useSelector(fnSeletorTamanho);
  const maxLengthTitle = tamanhoCampoFeedAdd?.addFeedTitulo;
  const maxLengthSubject = tamanhoCampoFeedAdd?.addFeedAssunto;

  const [wait, setWait] = useToggle();

  const schema = [
    {
      name: names.title,
      type: "text",
      defaultValue: feed?.titulo,
      label: t("feed_rh.formAddUpdLabelTitle"),
      maxLength: maxLengthTitle,
      placeholder: t("feed_rh.formAddUpdPlaceholderTitle"),
    },
    {
      name: names.subject,
      type: "text",
      defaultValue: feed?.assunto,
      maxLength: maxLengthSubject,
      label: t("feed_rh.formAddUpdLabelSubject"),
      placeholder: t("feed_rh.formAddUpdPlaceholderSubject"),
    },
    {
      name: names.observation,
      type: "textarea",
      defaultValue: feed?.observacao,
      minRows: 3,
      multiline: true,
      label: t("feed_rh.formAddUpdLabelObservation"),
      placeholder: t("feed_rh.formAddUpdPlaceholderObservation"),
    },
    {
      name: names.file,
      type: "file",
      defaultValue: "",
      label: t("feed_rh.formAddUpdLabelFile"),
      placeholder: t("feed_rh.formAddUpdPlaceholderFile"),
    },
    {
      name: names.plant,
      type: "select",
      autoFormat: true,
      isMulti: true,
      itens: PLANTS,
      defaultValue: feed?.visivel_para,
      label: t("feed_rh.formAddUpdLabelPlant"),
      placeholder: t("feed_rh.formAddUpdPlaceholderPlant"),
    },
  ];
  const schemaMessageError = {
    [names.title]: t("feed_rh.formAddUpdErrorTitle"),
    [names.subject]: t("feed_rh.formAddUpdErrorSubject"),
    [names.file]: t("feed_rh.formAddUpdErrorFile"),
    [names.plant]: t("feed_rh.formAddUpdErrorPlant"),
  };
  const schemaValidator = yup.object().shape({
    [names.title]: obterValidador(VALIDADOR_TIPO.texto, 3),
    [names.subject]: obterValidador(VALIDADOR_TIPO.texto, 3),
    [names.file]: obterValidador(VALIDADOR_TIPO.arquivo, 1),
    [names.plant]: obterValidador(VALIDADOR_TIPO.selectMultiplo),
  });

  const onSubmit = useCallback(
    async (val) => {
      console.log(val);
      const obj = {
        [names.title]: val[names.title],
        [names.subject]: val[names.subject],
        [names.observation]: val[names.observation] || "",
        [names.plant]: val[names.plant].map((ele) => ele.value),
      };
      // Enviar arquivo
      const formData = new FormData();
      formData.append("arquivo", val[names.file][0]);
      try {
        const response = await axios.post("/bucket", formData);
        obj.url_post = response.data[names.file][0].url;
      } catch (error) {
        if (error?.message) {
          ToastErro(error.message);
        } else {
          ToastErro(error);
        }
        return false;
      }

      if (feed) obj["id_feed"] = feed.id_feed;
      // Submetendo os dados para insercao de um novo feed
      dispatch(feedRHAddUpdFeed(obj, setWait, Boolean(feed)));
    },
    [setWait, feed, dispatch]
  );

  return (
    <EntradaForm
      wait={wait}
      schemaValidator={schemaValidator}
      schemaMessageError={schemaMessageError}
      schema={schema}
      onSubmit={onSubmit}
    />
  );
};

export default FormAddUpdFeed;
