import { useSelector } from "react-redux";
import { selectPlantaUsuario } from "./twttp-selectors";
import { Divider, Paper, Stack } from "@mui/material";
import { Body1, Body2, Caption, H6, Subtitle1 } from "../../../components";
import { format, parseISO } from "date-fns";
import AvatarEntrevistado from "./twttp-avatar-to-interview";
import { useTranslation } from "react-i18next";

// Funcao que retorna um objeto, verifica se a resposta é negativa para destaque
const getSxNegativo = (resposta) => {
  if (resposta.toLowerCase() === "não") {
    return {
      border: "1px solid #b71c1c",
      borderRadius: "8px",
      //color: 'white !important',
      my: 1,
      p: 1,
    };
  }
  return {};
};

// Componente que exibe as respostas referentes ao questionario
const RespostasQuestionario = ({ resp }) => {
  const planta = useSelector(selectPlantaUsuario);
  const {
    id_twttp,
    entrevistado,
    entrevistador,
    nota_explicativa,
    dt_entrevista,
    respostas,
  } = resp;
  const { t } = useTranslation();

  return (
    <Stack sx={{ p: 1 }} spacing={1}>
      <Paper
        elevation={0}
        //</Stack>sx={{ p: 1, zIndex: 1, position: "sticky", top: 0 }}
      >
        <H6>
          {t("twttp.titleTwttpResponseQuestion")} - {id_twttp}
        </H6>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "flex-start", md: "space-around" }}
          sx={{ mb: 1 }}
        >
          <Paper sx={{ p: 1 }}>
            <AvatarEntrevistado
              titulo={t("twttp.titleAvatarToInterview")}
              nome_entrevistado={entrevistado.split("-")[1]}
              mat_entrevistado={entrevistado.split("-")[0]}
              planta={planta}
            />
          </Paper>
          {entrevistador && (
            <Paper sx={{ p: 1 }}>
              <AvatarEntrevistado
                titulo={t("twttp.titleAvatarToInterviewer")}
                nome_entrevistado={entrevistador?.split("-")[1]}
                mat_entrevistado={entrevistador?.split("-")[0]}
                planta={planta}
              />
            </Paper>
          )}
        </Stack>

        <Body2 fontWeight="bold">
          {t("twttp.titleTwttpResponseDateOpen")}:{" "}
          {format(parseISO(dt_entrevista), "dd/MM/yy")}
        </Body2>
      </Paper>
      {/* <Subtitle2>Respostas ao questionário</Subtitle2> */}
      <Paper elevation={8} sx={{ p: 1, m: 1 }}>
        <H6>{respostas?.length > 0 && respostas[0].titulo}</H6>
        <Divider sx={{ my: 2 }} />
        {respostas.map((ele, dix) => (
          <Stack sx={getSxNegativo(ele.resposta)} spacing={1} key={dix}>
            <Caption color="info.main">
              {dix + 1}° {t("twttp.labelTwttpResponseQuestion")}
            </Caption>
            <Subtitle1>{ele.descricao}</Subtitle1>

            <Stack spacing={1} direction="row" alignItems="flex-end">
              <Caption color="info.main" align="left">
                {t("twttp.labelTwttpResponse")}:{" "}
              </Caption>
              <Body1 fontWeight="bold" align="left">
                {ele.resposta}
              </Body1>
            </Stack>
          </Stack>
        ))}
        {nota_explicativa && (
          <>
            <Divider sx={{ my: 2 }} />
            <Subtitle1 fontWeight="bold">
              {t("twttp.labelTwttpResponseNote")}
            </Subtitle1>
            <Body1>{nota_explicativa}</Body1>
          </>
        )}
      </Paper>
    </Stack>
  );
};

export default RespostasQuestionario;
